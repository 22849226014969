/* <style data-styled="" data-styled-version="5.3.6"> */
.bSqfQs {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 27px;
} /*!sc*/
data-styled.g2[id="sc-gswNZR"] {
  content: "bSqfQs,";
} /*!sc*/
.bGEcWz {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
} /*!sc*/
.fqWTSa {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
} /*!sc*/
.dmSjGB {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
} /*!sc*/
.cOngau {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
} /*!sc*/
.imxwPD {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
} /*!sc*/
.leKMnH {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
} /*!sc*/
.gPVkTy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
} /*!sc*/
.ieMbRd {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
} /*!sc*/
.dnXFIz {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
} /*!sc*/
.kgqXNr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
} /*!sc*/
.fTibjT {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
} /*!sc*/
.bCEohi {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
} /*!sc*/
.gJGGqN {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
} /*!sc*/
.kpwTVZ {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
} /*!sc*/
data-styled.g3[id="sc-dkrFOg"] {
  content: "bGEcWz,fqWTSa,dmSjGB,cOngau,imxwPD,leKMnH,gPVkTy,ieMbRd,dnXFIz,kgqXNr,fTibjT,bCEohi,gJGGqN,kpwTVZ,";
} /*!sc*/
.biGtBB {
  padding: 0 24px;
  margin-top: 0;
} /*!sc*/
data-styled.g4[id="sc-hLBbgP"] {
  content: "biGtBB,";
} /*!sc*/
.kBmkal {
  /* font-family: aeonik; */
  font-weight: 400;
  -webkit-letter-spacing: -0.02em;
  -moz-letter-spacing: -0.02em;
  -ms-letter-spacing: -0.02em;
  letter-spacing: -0.02em;
  font-size: 2.5rem;
  line-height: 3rem;
} /*!sc*/
.jtaJGO {
  /* font-family: aeonik; */
  font-weight: 400;
  -webkit-letter-spacing: -0.04em;
  -moz-letter-spacing: -0.04em;
  -ms-letter-spacing: -0.04em;
  font-family: Helveticaneue,sans-serif;
  letter-spacing: -0.04em;
  font-size: 7rem;
  line-height: 8.5rem;
  text-transform: capitalize;
} /*!sc*/


.lhOvMH {
  /* font-family: aeonik; */
  font-weight: 400;
  -webkit-letter-spacing: -0.01em;
  -moz-letter-spacing: -0.01em;
  -ms-letter-spacing: -0.01em;
  letter-spacing: -0.01em;
  font-size: 2rem;
  line-height: 1.5;
  text-transform: capitalize;
} /*!sc*/
data-styled.g5[id="sc-eDvSVe"] {
  content: "kBmkal,jtaJGO,lhOvMH,";
} /*!sc*/
.TPnBF {
  /* font-family: aeonik; */
  font-size: 1.1vw;
  font-weight: 500;
  /* line-height: 2.4; */
  -webkit-letter-spacing: -0.01em;
  -moz-letter-spacing: -0.01em;
  -ms-letter-spacing: -0.01em;
  letter-spacing: -0.01em;
  color: black;
} /*!sc*/
.TXJKR {
  /* font-family: aeonik; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-letter-spacing: -0.01em;
  -moz-letter-spacing: -0.01em;
  -ms-letter-spacing: -0.01em;
  letter-spacing: -0.01em;
} /*!sc*/
data-styled.g6[id="sc-jSUZER"] {
  content: "TPnBF,TXJKR,";
} /*!sc*/
.kKRCRY {
  overflow: hidden;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
} /*!sc*/
.fStySe {
  overflow: hidden;
  padding-right: 0;
  padding-bottom: 0;
} /*!sc*/
.cVshiS {
  overflow: hidden;
  padding-right: 0px;
  padding-bottom: 0;
} /*!sc*/
.dPjOtZ {
  overflow: hidden;
  padding-right: 1rem;
  padding-bottom: 0;
} /*!sc*/
data-styled.g8[id="sc-iBYQkv"] {
  content: "kKRCRY,fStySe,cVshiS,dPjOtZ,";
} /*!sc*/
.kQjXco {
  -webkit-animation: animHeight 1.5s forwards;
  animation: animHeight 1.5s forwards;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  line-height: 1;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
} /*!sc*/
@-webkit-keyframes animHeight {
  from {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
} /*!sc*/
@keyframes animHeight {
  from {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
} /*!sc*/
.cHNgNo {
  -webkit-animation: animHeight 1.5s forwards;
  animation: animHeight 1.5s forwards;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  line-height: 1;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
} /*!sc*/
@-webkit-keyframes animHeight {
  from {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
} /*!sc*/
@keyframes animHeight {
  from {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
} /*!sc*/
.irURQu {
  -webkit-animation: animHeight 1.5s forwards;
  animation: animHeight 1.5s forwards;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
  -webkit-animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  line-height: 1;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
} /*!sc*/
@-webkit-keyframes animHeight {
  from {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
} /*!sc*/
@keyframes animHeight {
  from {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
} /*!sc*/
.cHNgtF {
  -webkit-animation: animHeight 1.5s forwards;
  animation: animHeight 1.5s forwards;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  line-height: 1;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
} /*!sc*/
@-webkit-keyframes animHeight {
  from {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
} /*!sc*/
@keyframes animHeight {
  from {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
} /*!sc*/
data-styled.g9[id="sc-ftTHYK"] {
  content: "kQjXco,cHNgNo,irURQu,cHNgtF,";
} /*!sc*/
.hJDsIQ {
  width: 100%;
} /*!sc*/
data-styled.g10[id="sc-pyfCe"] {
  content: "hJDsIQ,";
} /*!sc*/
.hfzCRf {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 0 12px;
} /*!sc*/
@media only screen and (max-width: 768px) {
  .hfzCRf {
    padding: 0 4px;
  }
} /*!sc*/
data-styled.g12[id="sc-kDvujY"] {
  content: "hfzCRf,";
} /*!sc*/
.fZPLjB {
  margin: 0px 12px;
  min-width: 20px;
  white-space: nowrap;
} /*!sc*/
data-styled.g13[id="sc-ipEyDJ"] {
  content: "fZPLjB,";
} /*!sc*/
.ezVcDf {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: #ffffff;
} /*!sc*/
.ezVcDf > svg {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  fill: #1a1e23;
} /*!sc*/
@media (hover: hover) {
  .ezVcDf:hover {
    background-color: #1a1e23;
  }
  .ezVcDf:hover > svg {
    fill: #ffffff;
  }
} /*!sc*/
data-styled.g14[id="sc-csuSiG"] {
  content: "ezVcDf,";
} /*!sc*/
.hPVNmo.page-enter-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
} /*!sc*/
.hPVNmo.page-exit ~ .wipe {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
} /*!sc*/
.hPVNmo.page-exit-active header {
  background: transparent !important;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  opacity: 0;
} /*!sc*/
.hPVNmo.page-exit-active ~ .wipe {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 500ms ease;
  -webkit-transition: transform 500ms ease;
  transition: transform 500ms ease;
} /*!sc*/
.hPVNmo.page-exit-active main {
  -webkit-transform: translateY(-0px);
  -ms-transform: translateY(-0px);
  transform: translateY(-0px);
} /*!sc*/
.hPVNmo.page-enter-done ~ .wipe {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: -webkit-transform 250ms ease;
  -webkit-transition: transform 250ms ease;
  transition: transform 250ms ease;
} /*!sc*/
data-styled.g15[id="hel-mainp"] {
  content: "hPVNmo,";
} /*!sc*/
.jTHvjj {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000;
  z-index: 1;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
} /*!sc*/
.jTHvjj > div {
  padding: 20px 24px;
} /*!sc*/
@media only screen and (max-width: 600px) {
  .jTHvjj > div {
    padding: 20px 16px;
  }
} /*!sc*/
data-styled.g16[id="sc-bqWxrE"] {
  content: "jTHvjj,";
} /*!sc*/
.gYRMlI {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 1;
} /*!sc*/
data-styled.g19[id="sc-fnGiBr"] {
  content: "gYRMlI,";
} /*!sc*/
.kLLsQO {
  color: #22282f;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-family: Helveticaneue,sans-serif;
} /*!sc*/
data-styled.g27[id="sc-fLcnxK"] {
  content: "kLLsQO,";
} /*!sc*/
.kvGHMY {
  background-color: #ffffff;
  color: #1a1e23;
  border: none;
  border-radius: 8px;
  padding: 11px 16px 13px 16px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border:  1px solid;
} /*!sc*/
.kvGHMY:hover {
  background-color: #1a1e23;
  color: #ffffff;
  cursor: pointer;
} /*!sc*/
.kvGHMY:hover a {
  color: #ffffff;
} /*!sc*/
data-styled.g28[id="sc-bBABsx"] {
  content: "kvGHMY,";
} /*!sc*/
.cXTLvr {
  cursor: pointer;
} /*!sc*/
.cXTLvr:hover > div > div {
  background-color: #1a1e23;
} /*!sc*/
.cXTLvr:hover > div > div > svg {
  fill: #ffffff;
} /*!sc*/
data-styled.g33[id="sc-gikAfH"] {
  content: "cXTLvr,";
} /*!sc*/
.fqWuVY {
  margin-left: 8px;
} /*!sc*/
data-styled.g34[id="sc-ezOQGI"] {
  content: "fqWuVY,";
} /*!sc*/
.kJWMur {
  cursor: pointer;
  width: 170px;
  padding: 2px 0;
} /*!sc*/
@media only screen and (max-width: 1920px) {
  .kJWMur {
    width: 130px;
  }
} /*!sc*/
.kJWMur > svg {
  fill: #22282f;
} /*!sc*/
data-styled.g35[id="sc-bYMpWt"] {
  content: "kJWMur,";
} /*!sc*/
.iwagXM .react-modal-sheet-container {
  height: 100% !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  background-color: #111417 !important;
  overflow-x: hidden;
} /*!sc*/
.iwagXM .react-modal-sheet-content {
  padding: 20px 24px;
  color: #eaeaea;
} /*!sc*/
.iwagXM .react-modal-sheet-content > div {
  height: 100%;
} /*!sc*/
data-styled.g36[id="sc-kMjNwy"] {
  content: "iwagXM,";
} /*!sc*/
.hQOsvn {
  cursor: pointer;
  border: 2px solid #f76567;
  border-radius: 56px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.5s;
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-delay: 0;
  transition-delay: 0;
} /*!sc*/
.hQOsvn:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
} /*!sc*/
.ehAIsa {
  cursor: pointer;
  border: 2px solid #f7f629;
  border-radius: 56px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.5s;
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-delay: 0;
  transition-delay: 0;
} /*!sc*/
.ehAIsa:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
} /*!sc*/
.lnbWJV {
  cursor: pointer;
  border: 2px solid #5950ff;
  border-radius: 56px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.5s;
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-delay: 0;
  transition-delay: 0;
} /*!sc*/
.lnbWJV:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
} /*!sc*/
.aWySW {
  cursor: pointer;
  border: 2px solid #000938;
  border-radius: 56px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.5s;
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-delay: 0;
  transition-delay: 0;
} /*!sc*/
.aWySW:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
} /*!sc*/
.lgLhyY {
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 56px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.5s;
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-delay: 0;
  transition-delay: 0;
} /*!sc*/
.lgLhyY:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
} /*!sc*/
.emUOB {
  cursor: pointer;
  border: 2px solid #2196f3;
  border-radius: 56px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.5s;
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-delay: 0;
  transition-delay: 0;
} /*!sc*/
.emUOB:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
} /*!sc*/
.hxkAOc {
  cursor: pointer;
  border: 2px solid #000000;
  border-radius: 56px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.5s;
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-delay: 0;
  transition-delay: 0;
} /*!sc*/
.hxkAOc:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
} /*!sc*/
.jtyvVe {
  cursor: pointer;
  border: 2px solid #ff31bb;
  border-radius: 56px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.5s;
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
  -webkit-transition-delay: 0;
  transition-delay: 0;
} /*!sc*/
.jtyvVe:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
} /*!sc*/
data-styled.g43[id="sc-jcMfQk"] {
  content: "hQOsvn,ehAIsa,lnbWJV,aWySW,lgLhyY,emUOB,hxkAOc,jtyvVe,";
} /*!sc*/
.OUdfV {
  width: 56px;
  height: 56px;
  border-radius: 56px;
 
  background-size: 100% 100%;
  box-shadow: inset 0 0 0 2000px #08090b80;
  margin: 2px;
  padding-left: 2px;
} /*!sc*/
.hqFLYc {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  
  background-size: 100% 100%;
  box-shadow: inset 0 0 0 2000px #08090b80;
  margin: 2px;
  padding-left: 2px;
} /*!sc*/
.kRkBdY {
  width: 56px;
  height: 56px;
  border-radius: 56px;

  background-size: 100% 100%;
  box-shadow: inset 0 0 0 2000px #08090b80;
  margin: 2px;
  padding-left: 2px;
} /*!sc*/
.bWcGnu {
  width: 56px;
  height: 56px;
  border-radius: 56px;

  background-size: 100% 100%;
  box-shadow: inset 0 0 0 2000px #08090b80;
  margin: 2px;
  padding-left: 2px;
} /*!sc*/
.hPlHZV {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  
  background-size: 100% 100%;
  box-shadow: inset 0 0 0 2000px #08090b80;
  margin: 2px;
  padding-left: 2px;
} /*!sc*/
.nisVx {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  
  background-size: 100% 100%;
  box-shadow: inset 0 0 0 2000px #08090b80;
  margin: 2px;
  padding-left: 2px;
} /*!sc*/
.jjkosQ {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  
  background-size: 100% 100%;
  box-shadow: inset 0 0 0 2000px #08090b80;
  margin: 2px;
  padding-left: 2px;
} /*!sc*/
.gXjiqL {
  width: 56px;
  height: 56px;
  border-radius: 56px;

  background-size: 100% 100%;
  box-shadow: inset 0 0 0 2000px #08090b80;
  margin: 2px;
  padding-left: 2px;
} /*!sc*/
.dJLlbT {
  width: 56px;
  height: 56px;
  border-radius: 56px;

  background-size: 100% 100%;
  box-shadow: inset 0 0 0 2000px #08090b80;
  margin: 2px;
  padding-left: 2px;
} /*!sc*/
.jqgIFn {
  width: 56px;
  height: 56px;
  border-radius: 56px;
 
  background-size: 100% 100%;
  box-shadow: inset 0 0 0 2000px #08090b80;
  margin: 2px;
  padding-left: 2px;
} /*!sc*/
data-styled.g44[id="sc-cabOPr"] {
  content: "OUdfV,hqFLYc,kRkBdY,bWcGnu,hPlHZV,nisVx,jjkosQ,gXjiqL,dJLlbT,jqgIFn,";
} /*!sc*/
.duQrUb {
  margin-top: 0;
  margin-bottom: 6vw;
  opacity: 1;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.16, 1, 0.6, 1);
  transition-timing-function: cubic-bezier(0.16, 1, 0.6, 1);
  -webkit-transform: translateY(15%);
  -ms-transform: translateY(15%);
  transform: translateY(15%);
} /*!sc*/
.duQrUb:nth-child(even) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
} /*!sc*/
.hDkvno {
  margin-top: 120px;
  margin-bottom: 6vw;
  opacity: 1;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.16, 1, 0.6, 1);
  transition-timing-function: cubic-bezier(0.16, 1, 0.6, 1);
  -webkit-transform: translateY(15%);
  -ms-transform: translateY(15%);
  transform: translateY(15%);
} /*!sc*/
.hDkvno:nth-child(even) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
} /*!sc*/
data-styled.g45[id="sc-iTFTee"] {
  content: "duQrUb,hDkvno,";
} /*!sc*/
.iLneeU {
  margin-bottom: 16px;
  overflow: hidden;
  width: 100%;
  border-radius: 8px 8px 36px 8px;
  position: relative;
  aspect-ratio: 6/3;
} /*!sc*/
.iLneeU:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  transition: transform 0.9s ease
} /*!sc*/
data-styled.g46[id="sc-iAEawV"] {
  content: "iLneeU,";
} /*!sc*/
.gEEGsZ {
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
} /*!sc*/
data-styled.g47[id="sc-eeMvmM"] {
  content: "gEEGsZ,";
} /*!sc*/
.iceamZ {
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 0.5s ease;
  -webkit-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  border-radius: 8px 8px 36px 8px;
  object-fit: cover;
} /*!sc*/
data-styled.g48[id="sc-cUEOzv"] {
  content: "iceamZ,";
} /*!sc*/
.hrfGHw {
  position: absolute;
  bottom: 16px;
  right: 16px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
} /*!sc*/
data-styled.g49[id="sc-jOiSOi"] {
  content: "hrfGHw,";
} /*!sc*/
.jGWXwK {
  color: #757f8a;
} /*!sc*/
data-styled.g50[id="sc-dPWrhe"] {
  content: "jGWXwK,";
} /*!sc*/
.hBILsM {
  margin-top: 16px;
  width: max-content;
  
} /*!sc*/
data-styled.g51[id="sc-bCfvAP"] {
  content: "hBILsM,";
} /*!sc*/
.bgIZJh {
  height: 22px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 2px;
} /*!sc*/
.bgIZJh > :after {
  content: " ";
  display: block;
  height: 1px;
  left: 0;
  top: 100%;
  position: absolute;
  background: currentColor;
  opacity: 0.6;
  -webkit-transition: width 0.25s ease-out 0.25s, opacity 0.3s ease 0s;
  transition: width 0.25s ease-out 0.25s, opacity 0.3s ease 0s;
  width: 0;
} /*!sc*/
@media only screen and (min-width: 1921px) {
  .bgIZJh > :after {
    height: 2px;
  }
} /*!sc*/
.bgIZJh > :hover:after {
  width: 100%;
  opacity: 0.9;
} /*!sc*/
.bgIZJh > :hover:after > span {
  display: block;
  font-size: 16px;
} /*!sc*/
data-styled.g58[id="sc-lbVpMG"] {
  content: "bgIZJh,";
} /*!sc*/
.bjDBkP {
  color: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
} /*!sc*/
data-styled.g59[id="sc-iOeugr"] {
  content: "bjDBkP,";
} /*!sc*/
.iIGJoJ {
  position: relative;
  height: 44px;
  top: 0;
} /*!sc*/
.iIGJoJ > span {
  display: block;
  height: 22px;
} /*!sc*/
data-styled.g60[id="sc-jfvxQR"] {
  content: "iIGJoJ,";
} /*!sc*/
.dlaXZt {
  width: 30%;
} /*!sc*/
data-styled.g61[id="sc-eJDSGI"] {
  content: "dlaXZt,";
} /*!sc*/
.koDlF {
  margin: 0 24px;
} /*!sc*/
data-styled.g62[id="sc-oZIhv"] {
  content: "koDlF,";
} /*!sc*/
.cNQWLO {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
} /*!sc*/
data-styled.g63[id="sc-hiDMwi"] {
  content: "cNQWLO,";
} /*!sc*/
.oenBp {
  height: calc(100vh - 40px);
} /*!sc*/
data-styled.g64[id="sc-ehvNnt"] {
  content: "oenBp,";
} /*!sc*/
.covWjZ {
  width: 100%;
  height: 10px;
} /*!sc*/
data-styled.g65[id="sc-laZRCg"] {
  content: "covWjZ,";
} /*!sc*/
.loBZyd {
  white-space: nowrap;
} /*!sc*/
@media only screen and (max-width: 380px) {
  .loBZyd {
    font-size: 15.5vw;
  }
} /*!sc*/
data-styled.g66[id="sc-gGvHcT"] {
  content: "loBZyd,";
} /*!sc*/
.izgbBu {
  white-space: nowrap;
  margin: 8px 0 12px 0;
  color: #38c3e3;
  cursor: pointer;
} /*!sc*/
.izgbBu:hover {
  color: #1fbce0;
} /*!sc*/
@media only screen and (max-width: 380px) {
  .izgbBu {
    margin: 0;
    font-size: 15.5vw;
  }
} /*!sc*/
data-styled.g67[id="sc-ckEbSK"] {
  content: "izgbBu,";
} /*!sc*/
.eqEWFd {
  padding-top: 4px;
  width: 100%;
} /*!sc*/
@media only screen and (max-width: 768px) {
  .eqEWFd > div {
    margin: 0;
  }
} /*!sc*/
data-styled.g68[id="sc-fbYMXx"] {
  content: "eqEWFd,";
} /*!sc*/
.hpmDzw {
  margin-top: -12px;
  width: 100%;
} /*!sc*/
@media only screen and (max-width: 768px) {
  .hpmDzw {
    margin-top: -8px;
    margin-bottom: 16px;
  }
} /*!sc*/
data-styled.g69[id="sc-GhhNo"] {
  content: "hpmDzw,";
} /*!sc*/
.egLmKv {
  margin-top: 20px;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  width: 100%;
} /*!sc*/
.egLmKv > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
} /*!sc*/
.egLmKv > span {
  margin: 0 35px;
} /*!sc*/
@media only screen and (max-width: 768px) {
  .egLmKv {
    margin-top: 44px;
  }
  .egLmKv > span {
    margin: 0 12px;
  }
} /*!sc*/
@media only screen and (max-width: 369px) {
  .egLmKv {
    display: block;
  }
  .egLmKv > span {
    display: block;
    opacity: 0;
    height: 5px;
  }
} /*!sc*/
data-styled.g70[id="sc-fXqpFg"] {
  content: "egLmKv,";
} /*!sc*/
body {
  background-color: #f5f5f5;
  color: #22282f;
} /*!sc*/
data-styled.g93[id="sc-global-kDkfFO1"] {
  content: "sc-global-kDkfFO1,";
} /*!sc*/
.hel-pbhd {
  height: 100%;
  background: #111417;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.35s
    cubic-bezier(0.16, 1, 0.3, 1);
  -webkit-transition: transform 0.35s cubic-bezier(0.16, 1, 0.3, 1);
  transition: transform 0.35s cubic-bezier(0.16, 1, 0.3, 1);
  -webkit-transition-delay: 0;
  transition-delay: 0;
} /*!sc*/
data-styled.g94[id="hel-brepn"] {
  content: "hel-pbhd,";
} /*!sc*/
.YzgUw {
  padding: 22px 16px;
  color: #eaeaea;
  height: 95%;
} /*!sc*/
data-styled.g95[id="sc-gJqSRm"] {
  content: "YzgUw,";
} /*!sc*/
.hYvGLd {
  cursor: pointer;
} /*!sc*/
.hYvGLd > svg {
  fill: #fff;
} /*!sc*/
data-styled.g96[id="sc-evzXkX"] {
  content: "hYvGLd,";
} /*!sc*/
.djUVYy {
  color: #eaeaea;
} /*!sc*/
.kKFAb {
  color: #38c3e3;
} /*!sc*/
data-styled.g97[id="sc-fHSyak"] {
  content: "djUVYy,kKFAb,";
} /*!sc*/
.zFlCt {
  cursor: pointer;
  width: 100%;
} /*!sc*/
data-styled.g98[id="sc-dubCtV"] {
  content: "zFlCt,";
} /*!sc*/
.bEBKpL {
  background-color: #22282f;
  margin: 15px 0;
} /*!sc*/
data-styled.g99[id="sc-dkBdza"] {
  content: "bEBKpL,";
} /*!sc*/
.ZYovv {
  white-space: nowrap;
} /*!sc*/
data-styled.g100[id="sc-WKhSL"] {
  content: "ZYovv,";
} /*!sc*/
.evRiOY {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: #38c3e3;
  margin-right: 4px;
  cursor: pointer;
} /*!sc*/
.cpkiMz {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: #22282f;
  margin-right: 4px;
  cursor: default;
} /*!sc*/
data-styled.g101[id="sc-ikHGee"] {
  content: "evRiOY,cpkiMz,";
} /*!sc*/
.gGHLyZ {
  width: 125px;
  height: 20px;
  position: relative;
  overflow: hidden;
} /*!sc*/
data-styled.g102[id="sc-uhnfH"] {
  content: "gGHLyZ,";
} /*!sc*/
.jwTppz {
  position: absolute;
  top: 0;
} /*!sc*/
data-styled.g103[id="sc-bhNKFk"] {
  content: "jwTppz,";
} /*!sc*/
.kmzijn {
  padding: 20px 0;
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: #f5f5f5;
  color: #22282f;
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
} /*!sc*/
data-styled.g104[id="sc-eGJbfJ"] {
  content: "kmzijn,";
} /*!sc*/
.efbQbE {
  width: 100%;
  height: auto;
} /*!sc*/
.efbQbE > div {
  margin-right: 24px;
} /*!sc*/
.efbQbE > div:last-child {
  margin-right: 0;
} /*!sc*/
.efbQbE > div > :after {
  background: #22282f;
} /*!sc*/
data-styled.g105[id="sc-hAQmFe"] {
  content: "efbQbE,";
} /*!sc*/
.iiAUMl {
  cursor: pointer;
} /*!sc*/
data-styled.g106[id="sc-kZLDfD"] {
  content: "iiAUMl,";
} /*!sc*/
.fHwkth {
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 0 12px;
} /*!sc*/
@media only screen and (max-width: 768px) {
  .fHwkth {
    padding: 0 4px;
  }
} /*!sc*/
data-styled.g108[id="sc-dWRHGJ"] {
  content: "fHwkth,";
} /*!sc*/
.ecPFXA {
  margin: 0 12px;
} /*!sc*/
data-styled.g109[id="sc-jGNhvO"] {
  content: "ecPFXA,";
} /*!sc*/
.dtQRfw {
  margin-left: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  width: 32px;
  height: 24px;
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.1s;
  -webkit-transition: transform 0.1s;
  transition: transform 0.1s;
  margin-right: -11px;
} /*!sc*/
.dtQRfw > * {
  pointer-events: none;
} /*!sc*/
.dtQRfw:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
} /*!sc*/
data-styled.g110[id="sc-bQmOxr"] {
  content: "dtQRfw,";
} /*!sc*/
.kxFcfd {
  /* padding: 247px 0 43px 0; */
} /*!sc*/
@media only screen and (max-width: 768px) {
  .kxFcfd {
    /* padding-top: 170px; */
    /* padding-bottom: 150px; */
  }
} /*!sc*/
data-styled.g135[id="sc-elAWhN"] {
  content: "kxFcfd,";
} /*!sc*/
.ciArwO {
  /* margin: 10px 10px 0px; */
} /*!sc*/
@media only screen and (max-width: 768px) {
  .ciArwO {
    /* margin: 10px 4px 0px -5px; */
  }
  .kBmkal{
    font-size: 1rem;
  }
} /*!sc*/
data-styled.g136[id="sc-kiPvrU"] {
  content: "ciArwO,";
} /*!sc*/
@media only screen and (min-width: 768px) {
.jyhgZP {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
} /*!sc*/
}
data-styled.g137[id="sc-cLNonn"] {
  content: "jyhgZP,";
} /*!sc*/
.eIWLtu {
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
} /*!sc*/
@media only screen and (max-width: 768px) {
  .eIWLtu {
    grid-template-columns: 100%;
  }
  .jyhgZP {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
}
} /*!sc*/
data-styled.g138[id="sc-iJbNxu"] {
  content: "eIWLtu,";
} /*!sc*/
.htoTsn {
  margin: 20px 12px 120px 12px;
} /*!sc*/
@media only screen and (max-width: 768px) {
  .htoTsn {
    margin: 16px 0 80px 0;
  }
  .jtaJGO{
/* font-family: aeonik; */
font-weight: 400;
letter-spacing: -0.04em;
font-size: 4.2rem;
line-height: 5.25rem;
/* text-transform: uppercase; */
}
.hDkvno {
  margin-top: 0px;
}
} /*!sc*/
data-styled.g139[id="sc-grxQYx"] {
  content: "htoTsn,";
} /*!sc*/
.iBILaZ {
width: 100%;
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 24px;
}
.biGtBB {
padding: 0px 24px;
/* margin-top: 0px; */
}
.jhFbfb {
width: 100%;
height: 100%;
transition: transform 0.5s ease 0s;
border-radius: 8px 8px 36px;
object-fit: cover;
}
@media only screen and (max-width: 768px) {
.iBILaW {
width: 100%;
display: grid;
grid-template-columns: repeat(1, 1fr);
gap: 24px;
}
.TPnBF{
  font-size: 20px;
  color: black;
}
.text-size-small.text-colour-grey {
  color: black;
  font-size: 18px;
}
}
/* </style> */
.sc-eDvSVe .lhOvMH{
  font-size: 25px;
}

.hDkvno span{
  font-size: 18px;
}
.duQrUb span{
  font-size: 18px;
}