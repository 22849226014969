.biGtBB{
    margin-top: 25rem;
}
.bgPDcl {
    height: calc(var(--vh,1vh) * 100 - 72px);
}


.jjGFiK {
    width: 100%;
    display: flex;
    min-width: 0px;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;
}
.dPjOtZ {
    overflow: hidden;
    padding-right: 1rem;
    padding-bottom: 0px;
}
.cHNgNo {
    animation: 1.5s cubic-bezier(0.16, 1, 0.3, 1) 0.2s 1 normal forwards running animHeight;
    line-height: 1;
    transform: translateY(100%);
}
.ebVANy {
    color: rgb(56, 195, 227);
    line-height: 1;
    white-space: nowrap;
}
/* 
<style> */
.eLIbDb {
    /* font-family: aeonik; */
    font-weight: 400;
    letter-spacing: -0.04em;
    font-size: 3.75rem;
    line-height: 4.25rem;
}
@media only screen and (max-width: 768px){
/* <style> */
.eOyxzV {
    padding: 68px 0px 87px;
}
}
/* <style> */
.eOyxzV {
    padding: 104px 0px 167px;
}
.hhm-p01 .biGtBB{
    margin-top: 1rem;
}
.hhm-p01 .kxFcfd{
    /* padding: 87px 0 43px 0 */
}

 
  
  .faq-drawer {
    margin-bottom: 30px;
    transition: all 0.9s ease;
    border-bottom: 0.5px solid rgb(224, 224, 224);
  }
  
  .faq-drawer__content-wrapper {
    font-size: 0.65em;
    line-height: 1.4em;
    max-height: 0px;
    overflow: hidden;
    transition: 0.25s ease-in-out;
    color: white;
  }
  
  .faq-drawer__title {
    border-top: #000 1px solid;
    cursor: pointer;
    display: block;
    font-size: 0.85em;
    font-weight: 100;
    padding: 30px 0 0 0;
    position: relative;
    margin-bottom: 0;
    transition: all 0.25s ease-out;
    text-transform: capitalize;
  }
  
  .faq-drawer__title::after {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: " ";
    display: inline-block;
    float: right;
    height: 10px;
    left: 2px;
    position: relative;
    right: 20px;
    top: 2px;
    transform: rotate(135deg);
    transition: 0.35s ease-in-out;
    vertical-align: top;
    width: 10px;
    transition: all 0.9s ease;
  }
  
  /* OPTIONAL HOVER STATE */
  .faq-drawer__title:hover { 
    color: #4E4B52  ;
  }
  
  .faq-drawer__trigger:checked
    + .faq-drawer__title
    + .faq-drawer__content-wrapper {
    max-height: 350px;
    transition: all 0.9s ease;
  }
  
  .faq-drawer__trigger:checked + .faq-drawer__title::after {
    transform: rotate(-45deg);
    transition: all 0.9s ease;
    transition: 0.25s ease-in-out;
  }
  
  input[type="checkbox"] {
    display: none;
  }
  
  /* @media only screen and (max-width: 600px) {
    .container {
      padding: 80px;
    }
  } */
  .faq-drawer__title{
    color: white;
  }
  .hhm-p01 .kBmkal{
    font-size: 1.5rem;
  }
  .section_process .ht-hser-01{
    position: inherit;
    top: 0;
  }
  .hm-01center-container {
  text-align: center;
}

.hm-01centered-button {
  padding: 10px 45vw;
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .hm-01centered-button {
    padding: 10px 30vw;
    font-size: 16px;
  }
}
/*  */



.tiles {
	--tiles-height: 42vw;
	/* height: var(--tiles-height); */
	height: 20vw;
	position: relative;
	overflow: hidden;
}

.tiles--columns-rotated {
	--tiles-height: 180vmax;
}

.tiles--columns {
	--tiles-height: 140vh;
}

.tiles--oneline {
	--tiles-height: 50vh;
	min-height: 400px;
	margin: 10vh 0;
}

.tiles--small {
	--tiles-height: 100px;
	margin: 10vh 0 0;
}

.tiles--fixed {
	--tiles-height: 65vw;
	margin-top: 25vh;
}

.tiles--perspective {
	overflow: visible;
	perspective: 1000px;
	--tiles-height: 500px;
}

.tiles__wrap {
	width: 150%;
	--tileswrap-height: var(--tiles-height);
	/* height: var(--tileswrap-height); */
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate3d(-35%,-50%, 0);
	/* transform: translate3d(-50%,-50%, 0); */
}

.tiles--rotated .tiles__wrap {
	--tileswrap-height: calc(var(--tiles-height) * 1.8);
	transform: translate3d(-50%,-50%, 0) rotate(22.5deg);
}

.tiles--columns-rotated .tiles__wrap {
	width: 150%;
	display: flex;
	transform: translate3d(-50%,-50%,0) rotate(-22.5deg);
	left: 50%;
	top: 50%;
	align-items: center;
	justify-content: center;
}

.tiles--columns .tiles__wrap {
	width: 100%;
	display: flex;
	--tileswrap-height: calc(var(--tiles-height) * 1.4);
	transform: translate3d(0,-50%,0);
	left: 0;
	top: 50%;
}

.tiles--perspective .tiles__wrap {
	width: 200%;
    transform: translate3d(-50%,-50%,0) translateX(-25%) translateZ(-1200px) rotateX(75.5deg) rotateZ(12deg);
}

.tiles--fixed .tiles__wrap {
	width: 100%;
}

.tiles__line {
	display: flex;
}

.tiles--columns .tiles__line {
	width: 25%;
	padding: 0 1rem;
	display: block;
}

.tiles--columns-rotated .tiles__line {
	width: 320px;
	flex: none;
	padding: 0 1.5vw;
	display: block;
}

.tiles--columns-rotated .tiles__line:nth-child(even) {
	margin-top: -160px;
}

.tiles--oneline .tiles__line {
	height: 100%;
}

.tiles--small .tiles__line {
	height: 100%;
}

.tiles__line-img {
	--tile-margin: 1vw;
	flex: none;
	width: calc(15.0666% - var(--tile-margin) * 2);
	/* width: vw; */
	height: calc( var(--tileswrap-height) / 3 - (3 * var(--tile-margin) / 2));
	/* margin: var(--tile-margin); */
	background-size: cover;
	background-position: 50% 50%;
	opacity: 0.6;
}

.tiles--perspective .tiles__line-img {
	backface-visibility: hidden;
	outline: 1px solid transparent;
	--tile-margin: 1vw;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc(16.666vw * 1.3);
}

.tiles--oneline .tiles__line-img {
	--tile-margin: 1vw;
	margin: 0 var(--tile-margin);
	width: calc(25% - var(--tile-margin) * 2);
	height: 100%;
}

.tiles--small .tiles__line-img {
	--tile-margin: 5px;
	margin: 0 var(--tile-margin);
	width: calc(12.5% - var(--tile-margin) * 2);
	height: 100%;
}

.tiles--rotated .tiles__line-img {
	--tile-margin: 1vw;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc( var(--tileswrap-height) / 5 - (4 * var(--tile-margin) / 2));
}

.tiles--columns-rotated .tiles__line-img {
	--tile-margin: 3vw 0;
	width: 100%;
	height: 400px;
}

.tiles--columns .tiles__line-img {
	--tile-margin: 2rem 0;
	width: 100%;
	height: calc(25vw * 1.3);
}

.tiles--fixed .tiles__line-img {
	--tile-margin: 10px;
	-webkit-filter: brightness(0.8);
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc( var(--tileswrap-height) / 3 - (3 * var(--tile-margin) / 2));
}

.tiles--darker .tiles__line-img {
	opacity: 0.7;
}

.tiles__title {
	position: absolute;
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10vw;
	padding: 3rem;
	margin: 0;
	line-height: 0.8;
	/* font-family: span, serif; */
	font-weight: 700;
	font-style: normal;
}

.tiles__title--alt {
	font-size: 9vw;
	font-size: clamp(2rem, 9vw, 7.25rem);
}

.tiles__title--intro {
	padding-top: 10vh;
	align-items: flex-start;
	justify-content: flex-start;
}

.tiles__title--right {
	justify-content: flex-end;
}

.tiles__title--left {
	justify-content: flex-start;
}

.tiles__title--full {
	height: 100%;
}

  

  


@media screen and (min-width: 53em) {
	.frame {
		display: grid;
		align-content: space-between;
		width: 100%;
		grid-gap: 5vw;
		grid-template-columns: auto auto auto 1fr;
		grid-template-areas: 'title title links demos';
	}
	.frame__title {
		margin: 0;
		grid-area: title;
	}
	.frame__demos {
		margin: 0;
		grid-area: demos;
		justify-self: end;
	}
	.frame__links {
		grid-area: links;
		padding: 0;
		justify-self: end;
	}
	.tiles--columns-rotated .tiles__line {
		width: 33vmax;
	}
	.tiles--columns-rotated .tiles__line:nth-child(even) {
		margin-top: -20vmax;
	}
	.tiles--columns-rotated .tiles__line-img {
		height: 40vmax;
	}
	.content--numbered::after {
		counter-increment: contentSection;                   
		content: counter(contentSection,decimal-leading-zero);
		position: absolute;
		top: 50%;
		right: 3rem;
		border: 1px solid;
		color: var(--color-alt);
		width: 4rem;
		height: 4rem;
		margin-top: -2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}
	.content__text {
		max-width: 800px;
		width: 50vw;
		min-width: calc(300px - 6rem);
	}
	.content__text--wide {
		max-width: 1000px;
		width: 65vw;
	}
	.tiles--small {
		--tiles-height: 240px;
	}
}


/*  */


:root {
	/* font-size: 14px; */
	/* --color-text: #131313;
	--color-bg: #fff;
	--color-link: #000;
	--color-link-hover: #131313;
	--padding-sides: 2rem;
	--padding-row: 1rem;
	--color-row-border: #f0f0f0;
	--color-bg-row-hover: #f0f0f0;
	--color-text-alt: #000; */
	--image-gap: 1vw;
	/* --image-gap-large: 2vw; */
	--img-size: 5vw;
	/* --img-size-large: 14vw; */
}

body {
	margin: 0;
	color: var(--color-text);
	background-color: var(--color-bg);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	/* font-family: auger-mono, monospace; */
	font-weight: 300;
	/* text-transform: uppercase; */
	/* position: relative; */
}

.oh {
	position: relative;
    overflow: hidden;
}

.oh__inner {
	will-change: transform;
	display: inline-block;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
	cursor: pointer;
}

.hover-line {
	overflow: hidden;
	position: relative;
}

.hover-line::before {
	content: '';
	height: 1px;
	width: 100%;
	background: currentColor;
	position: absolute;
	top: 92%;
	transition: transform 0.3s;
	transform-origin: 0% 50%;
	transition: all 10.9s ease;
}

.hover-line:hover::before {
	transform: scaleX(0);
	transform-origin: 100% 50%;
	transition: all 10.9s ease;
}

a:hover {
	text-decoration: none;
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
	cursor: pointer;
}

.unbutton:focus {
	outline: none;
}

.frame {
	width: 100%;
	padding: 3rem var(--padding-sides);
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto auto;
	grid-template-areas: 'title' 'prevdemo' 'sponsor';
	justify-content: start;
	grid-row-gap: 1rem;
	align-items: end;
	justify-items: start;
}

.frame__title {
	grid-area: title;
	display: flex;
	line-height: 1;
}

.frame__title-main {
	font-size: 1.953rem;
	margin: 0;
	font-weight: 400;
	/* font-family: neue-haas-grotesk-display, sans-serif; */
	text-transform: none;
}

.frame__title-back {
	position: relative;
	display: flex;
	align-items: flex-end;
}

.frame__title-back span {
	display: none;
}

.frame__title-back svg {
	fill: currentColor;
}

.frame__prevdemo {
	grid-area: prevdemo;
}

.intro {
	padding: 1rem var(--padding-sides) 3rem;
}

.intro p {
	max-width: 860px;
}

.cover {
	background: var(--color-bg-row-hover);
	width: 100%;
	height: 0;
	opacity: 1;
	pointer-events: none;
	z-index: 10;
	position: fixed;
	left: 0;
	will-change: height, top;
}

.content {
	position: relative;
	/* z-index: 100; */
	border-bottom: 1px solid var(--color-row-border);
}

.row {
	z-index: 1;
	color: var(--color-text);
	display: grid;
	position: relative;
	cursor: pointer;
	grid-template-rows: var(--img-size);
	grid-template-columns: auto 1fr;
	grid-column-gap: 5vw;
	align-items: center;
	padding: var(--padding-row) var(--padding-sides);
	border-top: 1px solid var(--color-row-border);
	transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
}

.row:hover {
  
	background-color: var(--color-bg-row-hover);
  /* background-color: red; */
  opacity: 1;
}
.row:hover{
  .cell__img {
    opacity: 1;
  }
}

.row--current {
	z-index: 11;
	transition: border-color 0.3s ease-out;
}

.cell {
	position: relative;
}

.cell__title,
.preview__item-title {
	margin: 0;
	font-size: clamp(1.953rem,4vw,3.052rem);
	position: relative;
	font-weight: 400;
	line-height: 1;
	/* font-family: neue-haas-grotesk-display, sans-serif; */
	white-space: nowrap;
}

.cell__title--switch {
	/* font-family: lores-22-serif, sans-serif; */
	font-weight: 700;
}

.cell__title {
	display: grid;
	grid-template-rows: 100% 100%;
}

.cell--images {
	display: grid;
	align-content: center;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	grid-gap: var(--image-gap);
	justify-content: end;
	margin-left: auto;
}

.cell__img {
	width: var(--img-size);
	display: grid;
	position: relative;
	grid-template-columns: 100%;
	grid-template-rows: auto auto;
	will-change: transform, opacity;
	opacity: 0;
}

.cell__img-inner {
	background-size: cover;
	background-position: 50% 50%;
	aspect-ratio: 1;
	width: 100%;
	border-radius: calc(var(--image-gap) / 3);
}

.cell__img-title {
	max-width: 100%;
	font-size: 1rem;
	font-weight: 300;
	color: var(--color-text-alt);
	margin: 0;
	height: 0;
	opacity: 0;
	white-space: nowrap;
	will-change: opacity, transform;

	display: none;
}

.page-footer {
	padding: 10vw 10vw 5vw;
}

.page-footer__text {
	max-width: 860px;
	margin: 1.5rem auto;
	line-height: 1.5;
}

.page-footer__credits {
	padding-top: 10vh;
	text-align: center;
}

.preview {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 200;
}

.preview__close {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 2rem;
	font-weight: 400;
	line-height: 1;
	padding: 2rem;
	cursor: pointer;
	opacity: 0;
	/* font-family: sans-serif; */
}

.preview__close--show {
	pointer-events: auto;
}

.preview__item {
	width: 100%;
	height: 100%;
	display: grid;
	align-items: center;
    justify-items: center;
	align-content: center;
	grid-gap: 10vh;
	height: 0;
	opacity: 0;
	overflow: hidden;
}

.preview__item--current {
	pointer-events: auto;
	height: 100vh;
	opacity: 1;
}

.preview__item-title {
	/* font-family: lores-22-serif, sans-serif; */
	font-weight: 700;
	font-size: clamp(1.563rem, 6vw, 3.815rem);
}

.grid {
	/* position: relative;
	display: grid;
	max-width: 1200px;
	width: 100%;
    grid-gap: var(--image-gap-large);
	justify-content: center;
	justify-items: center;
	--img-size: var(--img-size-large);
	grid-template-columns: repeat(4,var(--img-size));
	grid-template-rows: repeat(2,var(--img-size)); */
}

.grid .cell__img {
	width: var(--img-size-large);
}

@media screen and (min-width: 61em) {
	.frame {
		grid-template-rows: auto;
		grid-template-areas: 'title prevdemo sponsor';
		grid-template-columns: auto auto 1fr;
		grid-column-gap: 2rem;
	}
	:root {
		--padding-sides: 4rem;
		--padding-row: 2rem;
	}
}
@media screen and (max-width: 900px) {
  .content{
  display: none;
  }
  .homemainani{
    display: block;
  }
  .biGtBB{
	margin-top: 18rem;
  }
  }
  @media screen and (min-width: 900px) {
    .content{
    display: block;
  
    }
    .hoesani{
      margin-top: 30rem;
	  text-transform: uppercase;
    }
    .homemainani{
      display: none;
      opacity: 0;
     
    }
    }


	.hm-pro01 .hhm-p01 .kxFcfd{
		padding: 7px 0 43px 0;
	}
.hm-ourwork{
	font-size: 1vw;
	/* margin-top: 2rem; */
	padding-top: 2rem;
}


.laptop{
	width: 100%;
	max-width: 1200px;
	position: relative;
}
.laptop .img1{
	width: 100%;

}
.laptop .img2{
	position: absolute;
	width: 67%;
	height: 55.3%;
	background-color: #000;
	top: 5.1%;
	object-fit: fill;
	left:16.5% ;
}
.laptop .mimg2{
	position: absolute;
	width: 39%;
	height: 85.3%;
	background-color: #000;
	top: 14.1%;
	object-fit: fill;
	left:30.5% ;
	/* border-radius: 25px ; */
}

html{
	scroll-behavior: smooth;
}
.banner {
	/* z-index: 100; */
	position: relative;
  }
  
  .banner .banner-row {
	overflow: hidden;
	display: flex;
	align-items: center;
  }
  
  @media (max-width: layout-breakpoint-medium) {
	.banner .banner-row:first-child {
	  margin-top: 48px;
	}
  }
  
  .banner .banner-row.center {
	justify-content: center;
	text-align: center;
  }
  
  .banner .banner-row.center .row-letter {
	left: 0;
  }
  
  .banner .banner-row.right {
	justify-content: flex-end;
  }
  
  .banner .banner-row .row-title,
  .banner .banner-row .row-letter {
	font-size: 7rem;
	font-weight: 500;
	position: relative;
	/* letter-spacing: -0.8rem; */
	display: inline-block;
	/* white-space: nowrap; */
	/* line-height:1px; */
	font-family: Helveticaneue,sans-serif;
  }
  
  @media (max-width: layout-breakpoint-xsmall) {
	.banner .banner-row .row-title,
	.banner .banner-row .row-letter {
	  font-size: 13rem;
	}
  }
  
  .banner .banner-row .row-col {
	/* width: 50%;
	display: flex;
	align-content: center;
	justify-content: center; */
  }
  
  @media (max-width: layout-breakpoint-medium) {
	.banner .banner-row .row-col {
	  /* width: 70%; */
	}
  }
  
  @media (max-width: 1240px) {
	.banner .banner-row .row-col:last-child {
	  /* display: none; */
	}
  }
  
  @media (max-width: layout-breakpoint-xsmall) {
	.banner .banner-row .row-col {
	  width: 100%;
	  justify-content: flex-start;
	}
  }
  
  .banner .banner-row .row-col .row-message {
	font-weight: 500;
	font-size: 1.8rem;
	line-height: 2.6rem;
	width: 320px;
  }
  
  .banner .banner-row .scroll {
	/* height: 160px;
	width: 160px;
	border-radius: 100%;
	background: white;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	left: 160px; */
  }
  
  @media (max-width: layout-breakpoint-medium) {
	.banner .banner-row .scroll {
	  left: 60px;
	}
  }
  
  @media (max-width: 1240px) {
	.banner .banner-row .scroll {
	  display: none;
	}
  }
  
  .banner .banner-row .scroll span {
	font-size: 18px;
	font-weight: 600;
	margin: 4px 0;
  }
  
  @media (max-width: layout-breakpoint-xsmall) {
	.banner .banner-row .scroll span {
	  font-size: 10px;
	  margin: 2px 0;
	}
  }
  
  .transition-image {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .transition-image img {
	width: 800px;
	display: flex;
  }
  
  .transition-image.final {
	display: block;
	top: -128px;
	position: relative;
	width: 90%;
	margin: 0 auto;
	z-index: -100;
  }
  
  @media (max-width: layout-breakpoint-xsmall) {
	.transition-image.final {
	  top: -56px;
	}
  }
  
  .transition-image.final img {
	width: 100%;
	max-width: 100%;
  }
  /* <style> */
  .spinner_spinner__NwsMu {
	display: block;
	position: relative;
	width: 2rem;
	height: 2rem;
	font-size: 0.4rem;
	pointer-events: none;
  }
  @-webkit-keyframes spinner_load8__R3w8S {
	0% {
	  -webkit-transform: rotate(0deg) translateZ(0);
	  transform: rotate(0deg) translateZ(0);
	}
	to {
	  -webkit-transform: rotate(1turn) translateZ(0);
	  transform: rotate(1turn) translateZ(0);
	}
  }
  @keyframes spinner_load8__R3w8S {
	0% {
	  -webkit-transform: rotate(0deg) translateZ(0);
	  transform: rotate(0deg) translateZ(0);
	}
	to {
	  -webkit-transform: rotate(1turn) translateZ(0);
	  transform: rotate(1turn) translateZ(0);
	}
  }
  .spinner_spinner__NwsMu:after,
  .spinner_spinner__NwsMu:before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	text-indent: -9999em;
  }
  .spinner_spinner__NwsMu:before {
	opacity: 0.2;
	border: 0.4em solid;
  }
  .spinner_spinner__NwsMu:after {
	border: 0.4em solid transparent;
	border-left-color: currentcolor;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: spinner_load8__R3w8S 0.7s linear infinite;
	animation: spinner_load8__R3w8S 0.7s linear infinite;
  }
  .button_btn__EuPxb {
	display: inline-flex;
	position: relative;
	z-index: 0;
	align-items: center;
	justify-content: center;
	padding: 0;
	background: none;
	border: none;
	outline: none;
	/* font-family: NeueHaasGroteskDisplay, Helvetica Neue, helvetica, arial,
	  sans-serif; */
	color: inherit;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	white-space: nowrap;
	text-decoration: none;
	text-transform: inherit;
	-webkit-appearance: none;
	appearance: none;
  }
  .button_btn__EuPxb:disabled {
	pointer-events: none;
  }
  .button_btn__EuPxb .button_spinner__BybWR {
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: 0;
	-webkit-transform: translate3d(-50%, calc(-50% + 4rem), 0);
	transform: translate3d(-50%, calc(-50% + 4rem), 0);
  }
  .button_btn__EuPxb .button_children__CAXMx,
  .button_btn__EuPxb .button_spinner__BybWR {
	transition: border 1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
	  opacity 0.9s cubic-bezier(0.77, 0, 0.175, 1),
	  -webkit-transform 0.9s cubic-bezier(0.77, 0, 0.175, 1);
	transition: border 1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
	  transform 0.9s cubic-bezier(0.77, 0, 0.175, 1),
	  opacity 0.9s cubic-bezier(0.77, 0, 0.175, 1);
	transition: border 1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
	  transform 0.9s cubic-bezier(0.77, 0, 0.175, 1),
	  opacity 0.9s cubic-bezier(0.77, 0, 0.175, 1),
	  -webkit-transform 0.9s cubic-bezier(0.77, 0, 0.175, 1);
  }
  .button_btn__EuPxb.button_hasLoading___jJLT {
	overflow: hidden;
	overflow: clip;
  }
  .button_btn__EuPxb.button_underlined__5j51U {
	display: inline-block;
  }
  @media (hover: hover) {
	.button_btn__EuPxb.button_underlined__5j51U {
	  padding: 0.1em 0;
	}
	.button_btn__EuPxb.button_underlined__5j51U:hover:after {
	  -webkit-transform: scaleX(1) translateZ(0);
	  transform: scaleX(1) translateZ(0);
	  transition-timing-function: cubic-bezier(0.16, 1.08, 0.38, 0.98);
	}
  }
  .button_btn__EuPxb.button_underlined__5j51U:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background: currentcolor;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: left;
	transform-origin: left;
	transition: -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
	transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
	transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
	  -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
	pointer-events: none;
  }
  .button_btn__EuPxb.button_underlined__5j51U.button_isActive__tn6U_:after {
	-webkit-transform: none;
	transform: none;
	transition-timing-function: cubic-bezier(0.16, 1.08, 0.38, 0.98);
  }
  .button_btn__EuPxb.button_circle__0x73m,
  .button_btn__EuPxb.button_rounded__Aad7d {
	--background: #fff;
	--color: #262626;
	position: relative;
	overflow: hidden;
	overflow: clip;
	background: var(--background);
	color: var(--color);
	transition: all 0.6s cubic-bezier(0.26, 1.04, 0.54, 1);
	transition-property: border, color;
  }
  .button_btn__EuPxb.button_circle__0x73m:before,
  .button_btn__EuPxb.button_rounded__Aad7d:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 200%;
	height: 100%;
	opacity: 0;
	background: var(--color);
	transition: opacity 1s cubic-bezier(0.26, 1.04, 0.54, 1);
  }
  .button_btn__EuPxb.button_circle__0x73m svg,
  .button_btn__EuPxb.button_rounded__Aad7d svg {
	position: relative;
	z-index: 1;
  }
  .button_btn__EuPxb.button_circle__0x73m:active,
  .button_btn__EuPxb.button_rounded__Aad7d:active {
	color: #fff;
	transition-duration: 0s;
  }
  .button_btn__EuPxb.button_circle__0x73m:active:before,
  .button_btn__EuPxb.button_rounded__Aad7d:active:before {
	opacity: 1;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
  }
  @media (hover: hover) {
	.button_btn__EuPxb.button_circle__0x73m:hover,
	.button_btn__EuPxb.button_rounded__Aad7d:hover {
	  background: transparent;
	  color: var(--background);
	  transition-duration: 0.2s;
	}
	.button_btn__EuPxb.button_circle__0x73m:hover:before,
	.button_btn__EuPxb.button_rounded__Aad7d:hover:before {
	  opacity: 1;
	  -webkit-transform: translate3d(-50%, 0, 0);
	  transform: translate3d(-50%, 0, 0);
	  transition: opacity 0.1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
		-webkit-transform 0.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	  transition: opacity 0.1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
		transform 0.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	  transition: opacity 0.1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
		transform 0.2s cubic-bezier(0.16, 1.08, 0.38, 0.98),
		-webkit-transform 0.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	}
  }
  .button_btn__EuPxb.button_circle__0x73m {
	width: clamp(36px, 4rem, 48px);
	height: clamp(36px, 4rem, 48px);
	border-radius: 50%;
	transition: all 0.6s cubic-bezier(0.26, 1.04, 0.54, 1);
	transition-property: color, background;
  }
  @media (min-width: 1024px) {
	.button_btn__EuPxb.button_circle__0x73m {
	  width: clamp(45px, 5rem, 60px);
	  height: clamp(45px, 5rem, 60px);
	}
  }
  .button_btn__EuPxb.button_circle__0x73m svg {
	transition: opacity 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  }
  .button_btn__EuPxb.button_circle__0x73m:disabled svg {
	opacity: 0.2;
  }
  .button_btn__EuPxb.button_rounded__Aad7d {
	height: clamp(36px, 4rem, 48px);
	padding: 0.2em clamp(18px, 2rem, 24px) 0;
	border-radius: clamp(36px, 4rem, 48px);
  }
  @media (min-width: 1024px) {
	.button_btn__EuPxb.button_rounded__Aad7d {
	  height: clamp(45px, 5rem, 60px);
	  padding: 0.2em clamp(22.5px, 2.5rem, 30px) 0;
	  border-radius: clamp(45px, 5rem, 60px);
	  font-size: clamp(10.8px, 1.2rem, 14.4px);
	}
  }
  .button_btn__EuPxb.button_rounded__Aad7d svg {
	margin: -0.1em 0 0 clamp(9px, 1rem, 12px);
	transition: -webkit-transform 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	transition: transform 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	transition: transform 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98),
	  -webkit-transform 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  }
  @media (min-width: 1024px) {
	.button_btn__EuPxb.button_rounded__Aad7d svg {
	  width: clamp(9.9px, 1.1rem, 13.2px);
	  height: clamp(9px, 1rem, 12px);
	}
  }
  .button_btn__EuPxb.button_rounded__Aad7d:disabled svg {
	opacity: 0.2;
  }
  @media (hover: hover) {
	.button_btn__EuPxb.button_rounded__Aad7d:hover svg {
	  -webkit-transform: translate3d(0.4rem, 0, 0);
	  transform: translate3d(0.4rem, 0, 0);
	}
  }
  .button_btn__EuPxb.button_rounded__Aad7d:active svg {
	-webkit-transform: translate3d(0.4rem, 0, 0);
	transform: translate3d(0.4rem, 0, 0);
  }
  .button_btn__EuPxb.button_inverted__V4YPC {
	background: var(--color);
	color: var(--background);
  }
  .button_btn__EuPxb.button_inverted__V4YPC:before {
	background: var(
	  --gradient,
	  var(--hover-background-color),
	  var(--color)
	);
	transition: opacity 3.5s cubic-bezier(0.26, 1.04, 0.54, 1),
	  -webkit-transform 4s cubic-bezier(0.26, 1.04, 0.54, 1);
	transition: opacity 3.5s cubic-bezier(0.26, 1.04, 0.54, 1),
	  transform 4s cubic-bezier(0.26, 1.04, 0.54, 1);
	transition: opacity 3.5s cubic-bezier(0.26, 1.04, 0.54, 1),
	  transform 4s cubic-bezier(0.26, 1.04, 0.54, 1),
	  -webkit-transform 4s cubic-bezier(0.26, 1.04, 0.54, 1);
  }
  @media (hover: hover) {
	.button_btn__EuPxb.button_inverted__V4YPC:hover {
	  background: var(--color);
	}
  }
  .button_btn__EuPxb.button_inverted__V4YPC:active {
	background: var(--color);
  }
  .nav_nav__eVb5m {
	display: flex;
	position: absolute;
	z-index: 2;
	top: 3rem;
	left: 0;
	width: 100%;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0 2.1rem;
	color: #0e0e0e;
	transition: opacity 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98),
	  visibility 0.5s;
	pointer-events: none;
	will-change: opacity;
  }
  @media (min-width: 600px) and (max-width: 1023px) {
	.nav_nav__eVb5m {
	  padding: 0 3.36rem;
	}
  }
  @media (min-width: 1024px) {
	.nav_nav__eVb5m {
	  top: 4rem;
	  padding: 0 6rem;
	}
  }
  .nav_nav__eVb5m.nav_isHidden__V_n_K {
	opacity: 0;
  }
  .nav_nav__eVb5m.nav_isHidden__V_n_K .nav_element__qZw4E {
	pointer-events: none;
  }
  .nav_element__qZw4E {
	-webkit-transform: translate3d(0, -7.5rem, 0);
	transform: translate3d(0, -7.5rem, 0);
	transition: -webkit-transform 1.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	transition: transform 1.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	transition: transform 1.2s cubic-bezier(0.16, 1.08, 0.38, 0.98),
	  -webkit-transform 1.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	transition-delay: var(--delay-mobile);
	pointer-events: all;
  }
  @media (min-width: 1024px) {
	.nav_element__qZw4E {
	  -webkit-transform: translate3d(0, -9.5rem, 0);
	  transform: translate3d(0, -9.5rem, 0);
	  transition-delay: var(--delay);
	}
	.nav_link__Eyu1_ {
	  margin-right: 4rem;
	}
	.nav_link__Eyu1_:last-child {
	  margin-right: 0;
	}
	.nav_cta___ySE0 {
	  margin-left: 6rem;
	}
  }
  @media (min-width: 0px) and (max-width: 1023px) {
	.nav_left__EDL6H {
	  display: flex;
	  align-items: center;
	}
  }
  .nav_right__ps6E0 {
	display: flex;
	align-items: center;
	justify-content: flex-end;
  }
  .nav_logo__iUEYR {
	display: block;
	position: relative;
	z-index: 3;
	width: 10.2rem;
	height: 2rem;
	transition: color 0.6s cubic-bezier(0.26, 1.04, 0.54, 1);
	pointer-events: all;
  }
  @media (min-width: 1024px) {
	.nav_logo__iUEYR {
	  width: 12.8rem;
	  height: 2.5rem;
	}
  }
  .nav_logo__iUEYR svg {
	width: 100%;
	height: 100%;
	height: auto;
  }
  .nav_burger__lNtRg {
	position: relative;
	flex-direction: column;
	justify-content: center;
	pointer-events: all;
  }
  .nav_burger__lNtRg .nav_close__mLKcz {
	position: absolute !important;
	top: 50%;
	left: 50%;
	width: 0.8rem !important;
	height: 0.8rem !important;
	opacity: 0;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	transition: opacity 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  }
  .nav_burger__lNtRg .nav_line__ZivxI {
	width: 1.6rem;
	height: 1px;
	margin-bottom: 2px;
	background-color: currentcolor;
	border-radius: 1px;
	transition: opacity 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98) 0.1s;
  }
  .nav_burger__lNtRg .nav_line__ZivxI:last-child {
	margin-bottom: 0;
  }
  .nav_burger__lNtRg.nav_isOpened__eYL8i .nav_close__mLKcz {
	opacity: 1;
	transition-delay: 0.25s;
  }
  .nav_burger__lNtRg.nav_isOpened__eYL8i .nav_line__ZivxI {
	opacity: 0;
	transition-delay: 0.15s;
  }
  .nav_light__ty_Ep {
	color: #fff;
  }
  .nav_light__ty_Ep .nav_burger__lNtRg {
	background: #fff;
	color: #000;
  }
  .image_imageWrapper__G8xW8 {
	opacity: 0;
	transition: opacity 0.5s cubic-bezier(0.26, 1.04, 0.54, 1);
  }
  .image_image__lootM {
	object-fit: cover;
  }
  @media (min-width: 0px) and (max-width: 1023px) {
	.image_isPriority__ZFJDO {
	  opacity: 1;
	}
  }
  .navPanel_navPanel__7WkJj {
	position: fixed;
	z-index: 1001;
	top: 100%;
	left: 0;
	width: 100%;
	height: 100%;
	visibility: hidden;
	background: #000;
	color: #fff;
	transition: visibility 1s,
	  -webkit-transform 1s cubic-bezier(0.77, 0, 0.175, 1);
	transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1), visibility 1s;
	transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1), visibility 1s,
	  -webkit-transform 1s cubic-bezier(0.77, 0, 0.175, 1);
  }
  .navPanel_navPanel__7WkJj .navPanel_item__DYvoM {
	padding: 1.5rem 0;
	border-top: 1px solid hsla(0, 0%, 100%, 0.15);
  }
  .navPanel_scrollWrapper__wtBWb {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
  }
  .navPanel_wrapper__tIHNH {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: calc(100% - 7rem);
	padding: 10rem 5.55vw 4rem;
  }
  .navPanel_ctas__byqp_ {
	display: flex;
	position: relative;
	z-index: 1;
	justify-content: space-between;
	margin-bottom: 2.1rem;
  }
  .navPanel_cta__lPjd7 {
	padding-right: 1.7rem !important;
	padding-left: 1.7rem !important;
  }
  .navPanel_background__Zfs9l {
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
  }
  .header_header__3o0p0 {
	position: fixed;
	z-index: 1000;
	right: 0;
	left: 0;
  }
  .SmoothScroll_container__L1r_8 {
	width: 100%;
  }
  .SmoothScroll_size__CSf_l {
	width: 1px;
	display: none;
  }
  .input_wrapper__NP9x7 {
	position: relative;
  }
  .input_input__JVjrL {
	width: 100%;
	border: none;
	-webkit-appearance: none;
	appearance: none;
	transition: color 5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  }
  .input_input__JVjrL:focus {
	outline: none;
  }
  .input_input__JVjrL::-webkit-input-placeholder {
	vertical-align: middle;
	opacity: 0.2;
	color: currentColor;
  }
  .input_input__JVjrL::placeholder {
	vertical-align: middle;
	opacity: 0.2;
	color: currentColor;
  }
  .input_input__JVjrL::-webkit-inner-spin-button,
  .input_input__JVjrL::-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
  }
  .input_regular__JTDF4 .input_input__JVjrL {
	height: 4rem;
	padding: 0;
	background-color: transparent;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
	font-weight: 400;
	line-height: 1.8;
	transition: color 1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
	  border-color 1s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  }
  .input_regular__JTDF4 .input_input__JVjrL:focus {
	border-color: #fff;
  }
  .input_regular__JTDF4 .input_input__JVjrL:not(:placeholder-shown) {
	border-color: #fff;
  }
  .input_required__0NfQx {
	position: absolute;
	top: 50%;
	right: 2rem;
	margin-top: -0.6em;
	color: #fff;
	transition: color 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	pointer-events: none;
  }
  .input_errorMessage__u1XlM {
	display: block;
	position: absolute;
	top: 100%;
	right: 0;
	margin-top: 0.5rem;
	opacity: 0;
	visibility: hidden;
	color: #fe3514;
	transition: opacity 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98),
	  visibility 0.5s;
  }
  @media (min-width: 1024px) {
	.input_errorMessage__u1XlM {
	  margin-top: 1rem;
	}
  }
  .checkbox_checkbox__5rU5l {
	position: relative;
	color: var(--color-mid, #9c9c9c);
	text-align: left;
  }
  .checkbox_box__H7hXn {
	display: inline-flex;
	position: relative;
	width: 16px;
	height: 16px;
	align-items: center;
	flex-shrink: 0;
	justify-content: center;
	margin-top: 0.3rem;
	margin-right: 0.8rem;
	border: 1px solid;
	border-radius: 1px;
	transition: border 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  }
  .checkbox_box__H7hXn i {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 4px;
	height: 4px;
	background: currentcolor;
	border-radius: 50%;
  }
  .checkbox_box__H7hXn i {
	opacity: 0;
	color: currentcolor;
	-webkit-transform: translate(-50%, -50%) scale(0.3);
	transform: translate(-50%, -50%) scale(0.3);
	transition: opacity 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98),
	  -webkit-transform 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	transition: opacity 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98),
	  transform 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	transition: opacity 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98),
	  transform 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98),
	  -webkit-transform 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  }
  .checkbox_label__v2pFv {
	display: inline-flex;
	align-items: flex-start;
	/* font-family: NeueHaasGroteskDisplay, Helvetica Neue, helvetica, arial,
	  sans-serif; */
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	transition: color 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	white-space: pre-wrap;
  }
  .checkbox_label__v2pFv a {
	font-size: inherit;
	color: var(--color, #fff) !important;
	text-decoration: underline;
  }
  .checkbox_input__PzERz {
	position: absolute;
	top: 50%;
	left: 0;
	opacity: 0;
	pointer-events: none;
  }
  .checkbox_input__PzERz:disabled + .checkbox_label__v2pFv {
	opacity: 0.6;
  }
  .checkbox_input__PzERz:focus
	+ .checkbox_label__v2pFv
	.checkbox_box__H7hXn {
	outline: 1px solid currentcolor;
  }
  .checkbox_input__PzERz:checked
	+ .checkbox_label__v2pFv
	.checkbox_box__H7hXn
	i {
	opacity: 1;
	-webkit-transform: translate(-50%, -50%) scale(1) translateZ(0);
	transform: translate(-50%, -50%) scale(1) translateZ(0);
  }
  .form_form__2cZM3 {
	position: relative;
  }
  @media (min-width: 1024px) {
	.form_form__2cZM3 {
	  width: 64.5067873303rem;
	}
  }
  .form_form__2cZM3 > * {
	margin-bottom: 1.5rem;
  }
  .form_form__2cZM3 > :last-child {
	margin-bottom: 0;
  }
  .form_formError__Fu4C6,
  .form_formSuccess__i1I10 {
	position: absolute;
	bottom: 100%;
	left: 0;
	width: 100%;
  }
  .form_formError__Fu4C6 {
	color: #fe3514;
  }
  .customPortableText_p__b90LB {
	margin-bottom: 3rem;
  }
  .customPortableText_a___Y_Ak {
	transition: color 0.5s cubic-bezier(0.26, 1.04, 0.54, 1);
	text-decoration: underline;
  }
  @media (hover: hover) {
	.customPortableText_a___Y_Ak:hover {
	  color: var(--color);
	}
  }
  .customPortableText_a___Y_Ak:active {
	color: var(--color);
  }
  .customPortableText_p__b90LB:last-child {
	margin-bottom: 0;
  }
  .FormNewsletter_formNewsletter__WJtsZ {
	margin: 6rem 0;
  }
  @media (min-width: 1024px) {
	.FormNewsletter_formNewsletter__WJtsZ {
	  margin: 12rem 0;
	}
  }
  .FormNewsletter_title___vdsi {
	margin-bottom: 4rem;
  }
  @media (min-width: 1024px) {
	.FormNewsletter_title___vdsi {
	  margin-bottom: 6rem;
	}
  }
  .FormNewsletter_inputWrapper__cD1Sg {
	position: relative;
  }
  .FormNewsletter_input__s0COe {
	width: 100%;
	height: 5rem !important;
	padding-right: 5rem !important;
	font-size: 3rem !important;
	color: #fff;
  }
  @media (min-width: 1024px) {
	.FormNewsletter_input__s0COe {
	  height: 9.2rem !important;
	  padding-top: 3.2rem !important;
	  padding-right: 5rem !important;
	  padding-bottom: 2.2rem !important;
	}
  }
  .FormNewsletter_input__s0COe::-webkit-input-placeholder {
	/* font-family: Hellix, helvetica, arial, sans-serif; */
	font-size: 2.4rem;
	font-weight: 500;
	letter-spacing: -0.04em;
  }
  .FormNewsletter_input__s0COe::placeholder {
	/* font-family: Hellix, helvetica, arial, sans-serif; */
	font-size: 2.4rem;
	font-weight: 500;
	letter-spacing: -0.04em;
  }
  @media (min-width: 1024px) {
	.FormNewsletter_input__s0COe::-webkit-input-placeholder {
	  font-size: 8rem;
	}
	.FormNewsletter_input__s0COe::placeholder {
	  font-size: 8rem;
	}
  }
  .FormNewsletter_cta__pJrWz {
	position: absolute !important;
	top: 50%;
	right: 0;
	flex-shrink: 0;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
  }
  .FormNewsletter_optin__7EvmZ {
	margin-top: 3rem;
  }
  .FormNewsletter_error___Z9nA {
	position: absolute;
	right: 0;
	bottom: 1rem;
	left: auto;
	width: auto;
  }
  @media (min-width: 1024px) {
	.FormNewsletter_error___Z9nA {
	  bottom: 0.5rem;
	}
  }
  .footer_footer__ZK5Q_ {
	position: relative;
	width: 100%;
	background: #000;
	border-top: 1px solid hsla(0, 0%, 100%, 0.15);
	color: #fff;
	overflow-x: hidden;
  }
  .footer_footerWrapper__8ZwIU {
	padding: 6rem 0 4rem;
	/* border-top: 1px solid hsla(0, 0%, 100%, 0.15); */
  }
  @media (min-width: 1024px) {
	.footer_footerWrapper__8ZwIU {
	  /* padding: 10rem 0 8rem; */
	}
  }
  .footer_top__Upalj {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: space-between;
  }
  .footer_logo__bA1ZD {
	width: 8.2rem;
	height: 1.6rem;
  }
  @media (min-width: 1024px) {
	.footer_logo__bA1ZD {
	  width: 12.8rem;
	  height: 2rem;
	}
  }
  .footer_list__LZhug {
	width: 44.4vw;
  }
  @media (min-width: 1024px) {
	.footer_list__LZhug {
	  width: 30.7601809955rem;
	  margin-left: 2.9864253394rem;
	}
	.footer_list__LZhug:last-child {
	  width: 19.6113122172rem;
	}
  }
  .footer_title__UAn4g {
	margin-bottom: 2rem;
  }
  .footer_address__kPUn_ {
	display: block;
	margin-bottom: 2rem;
  }
  .footer_nav__XNGc9 {
	display: flex;
	line-height: 1.1 !important;
  }
  @media (min-width: 1024px) {
	.footer_nav__XNGc9 {
	  margin-left: auto;
	}
  }
  @media (min-width: 0px) and (max-width: 1023px) {
	.footer_socialLinks__6g32K {
	  flex: 0 0 16.65vw;
	  padding-right: 0;
	}
  }
  .footer_bottom__BZmGy {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 4rem;
	color: #fff;
  }
  @media (min-width: 1024px) {
	.footer_bottom__BZmGy {
	  justify-content: flex-start;
	  margin-top: 6rem;
	  padding-bottom: 0.4rem;
	}
  }
  .footer_bottom__BZmGy .footer_link__j_gJ3 {
	transition: color 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
	text-decoration: underline;
  }
  @media (hover: hover) {
	.footer_bottom__BZmGy .footer_link__j_gJ3:hover {
	  color: #fff;
	  transition-duration: 0s;
	}
  }
  .footer_bottom__BZmGy .footer_link__j_gJ3:active {
	color: #fff;
	transition-duration: 0s;
  }
  .footer_ctas__RyKKY {
	margin-right: auto;
  }
  @media (min-width: 0px) and (max-width: 1023px) {
	.footer_ctas__RyKKY {
	  margin-bottom: 5rem;
	}
	.footer_cta__LC3m2 {
	  width: 100%;
	  margin-bottom: 1rem;
	}
  }
  @media (min-width: 1024px) {
	.footer_cta__LC3m2 {
	  margin-right: 1rem;
	}
  }
  .footer_legalsWrapper__cIBFX {
	color: #9c9c9c;
  }
  @media (min-width: 1024px) {
	.footer_legalsWrapper__cIBFX {
	  width: 30.7601809955rem;
	}
  }
  .footer_credits__0Tj7b {
	color: #9c9c9c;
  }
  @media (min-width: 1024px) {
	.footer_credits__0Tj7b {
	  width: 19.5113122172rem;
	  margin-left: 2.9864253394rem;
	}
  }
  .layout_transition__K5Kvb {
	display: flex;
	flex-direction: column;
	min-height: var(--vh);
  }
  a,
  article,
  body,
  div,
  footer,
  form,
  h1,
  h2,
  h3,
  header,
  html,
  i,
  img,
  label,
  li,
  nav,
  p,
  section,
  span,
  ul {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  }
  article,
  footer,
  header,
  nav,
  section {
	display: block;
  }
  body {
	line-height: 1;
  }
  ul {
	list-style: none;
  }
  *,
  :after,
  :before {
	box-sizing: inherit;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .container {
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
	/* width: 88.8vw; */
  }
  @media (min-width: 600px) and (max-width: 1023px) {
	.container {
	  width: 88.8vw;
	}
  }
  @media (min-width: 1024px) {
	.container {
	  width: 132rem;
	}
  }
  html {
	--sbw: 0px;
	font-size: 2.6666666667vw;
	font-size: calc(2.6666666667vw - var(--sbw) / 37.5);
  }
  @media (min-width: 0px) and (max-width: 1023px) {
	html {
	  scroll-behavior: smooth;
	}
  }
  @media (min-width: 600px) and (max-width: 1023px) {
	html {
	  font-size: 1.6666666667vw;
	  font-size: calc(1.6666666667vw - var(--sbw) / 60);
	}
  }
  @media (min-width: 1024px) {
	html {
	  font-size: 0.6944444444vw;
	  font-size: calc(0.6944444444vw - var(--sbw) / 144);
	  font-size: min(
		calc(0.6944444444vw - var(--sbw) / 144),
		13.3333333333px
	  );
	}
  }
  body {
	overflow-x: hidden;
	overflow-y: scroll;
	background: #000;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  * {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  @media (min-width: 1024px) {
	.mobile-only {
	  display: none !important;
	}
  }
  @media (min-width: 0px) and (max-width: 1023px) {
	.desktop-only {
	  display: none !important;
	}
  }
  .hd-140,
  .hd-240,
  .hd-30,
  .hd-80,
  .hm-100,
  .hm-24,
  .hm-36,
  .hm-50,
  .hm-70 {
	/* font-family: Hellix, helvetica, arial, sans-serif; */
	line-height: 0.9;
	white-space: pre-wrap;
	letter-spacing: -0.04em;
  }
  .hm-100 {
	font-size: 10rem;
  }
  .hm-70 {
	font-size: 7rem;
	line-height: 0.77;
  }
  .hm-50 {
	font-size: 5rem;
  }
  .hm-36 {
	font-size: 3.6rem;
  }
  .hm-24 {
	font-size: 2.4rem;
  }
  @media (min-width: 1024px) {
	.hd-240 {
	  font-size: 24rem;
	}
  }
  .hd-140 {
	line-height: 0.8;
  }
  @media (min-width: 1024px) {
	.hd-140 {
	  font-size: 14rem;
	}
  }
  .hd-80 {
	line-height: 0.8;
	
  }
  @media (min-width: 1024px) {
	.hd-80 {
	  font-size: 8rem;
	  position: absolute;
	left: -45rem;
	}
	.hd-30 {
	  font-size: 3rem;
	}
  }
  .pd-20,
  .pm-14,
  .pm-16,
  .pm-20 {
	/* font-family: NeueHaasGroteskDisplay, Helvetica Neue, helvetica, arial,
	  sans-serif; */
	line-height: 1.4;
	white-space: pre-wrap;
  }
  .pm-20 {
	font-size: clamp(18px, 2rem, 24px);
  }
  .pm-16 {
	font-size: clamp(14.4px, 1.6rem, 19.2px);
  }
  .pm-14 {
	font-size: clamp(12.6px, 1.4rem, 16.8px);
  }
  @media (min-width: 1024px) {
	.pd-20 {
	  font-size: clamp(16.2px, 1.8rem, 21.6px);
	}
  }
  .white {
	color: #fff;
  }
  .grey {
	color: #9c9c9c;
  }
  .semibold {
	font-weight: 500;
  }
  .label {
	text-transform: uppercase;
  }
  .label {
	/* font-family: NeueHaasGroteskDisplay, Helvetica Neue, helvetica, arial,
	  sans-serif; */
	font-size: clamp(10.8px, 1.2rem, 14.4px);
	font-weight: 500;
	letter-spacing: 0.05em;
  }
  a {
	text-decoration: none;
	font-family: Helveticaneue,sans-serif;
  }
  a,
  a:visited {
	color: currentcolor;
  }
  .slice_slice__sdE0w {
	display: block;
	margin: -1px 0;
	background: var(--background);
	color: var(--color);
	font-family: Helveticaneue,sans-serif;
  }
  .videoLoop_videoLoop__oVvSy {
	position: relative;
  }
  .videoLoop_posterWrapper__fxkSF {
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
  .videoLoop_poster__KrWTB {
	position: relative;
	width: 100%;
	height: 100%;
  }
  .AnimatedText_wrapper__h46D1 {
	display: inline-block;
	overflow: hidden;
	overflow: clip;
	margin: -0.2em;
	padding: 0.2em;
  }
  .AnimatedText_wrapper__h46D1 span {
	display: inline-block;
	margin: -0.2em;
	padding: 0.2em;
	-webkit-transform: translate(100%, 100%);
	transform: translate(100%, 100%);
  }
  @media (min-width: 0px) and (max-width: 1023px) {
	.AnimatedText_wrapper__h46D1 span {
	  -webkit-transform: none !important;
	  transform: none !important;
	}
  }
  .hero_hero__NbuMp {
	display: flex;
	position: relative;
	height: 88vh;
	overflow: hidden;
	overflow: clip;
	margin: 0 auto;
	padding-top: 10rem;
	padding-bottom: 2rem;
	background: #000;
  }
  @media (min-width: 1024px) {
	.hero_hero__NbuMp {
	  height: 100vh;
	  align-items: flex-end;
	  padding-bottom: 7rem;
	}
  }
  .hero_content__JVx4u {
	position: relative;
	z-index: 1;
  }
  @media (min-width: 1024px) {
	.hero_content__JVx4u {
	  width: 64.5067873303rem;
	  margin-left: 44.9954751131rem;
	}
  }
  @media (min-width: 0px) and (max-width: 1023px) {
	.hero_content__JVx4u br {
	  display: none;
	}
  }
  .hero_content__JVx4u.hero_noCta__0gLq4 {
	margin-bottom: 7rem;
  }
  @media (min-width: 1024px) {
	.hero_content__JVx4u.hero_noCta__0gLq4 {
	  margin-bottom: 0;
	}
  }
  .hero_subtitle__Sjne9 {
	margin-top: 3rem;
  }
  @media (min-width: 1024px) {
	.hero_subtitle__Sjne9 {
	  width: 64.5067873303rem;
	  margin-top: 4rem;
	  opacity: 0;
	  transition: opacity 2s cubic-bezier(0.26, 1.04, 0.54, 1) 0.3s;
	}
  }
  .hero_background__TgRvX {
	position: absolute;
	z-index: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	overflow: clip;
  }
  @media (min-width: 1024px) {
	.hero_background__TgRvX {
	  top: 0;
	}
  }
  .hero_videoLoop__4fbLE {
	width: 100%;
  }
  @media (min-width: 0px) and (max-width: 1023px) {
	.hero_videoLoop__4fbLE {
	  width: 160%;
	  -webkit-transform: scale(-1);
	  transform: scale(-1);
	}
  }
  @media (min-width: 1024px) {
	.hero_videoLoop__4fbLE {
	  height: 100%;
	}
  }
  .hero_isMirroredX__VTkit {
	align-items: flex-end;
  }
  @media (min-width: 1024px) {
	.hero_isMirroredX__VTkit .hero_content__JVx4u {
	  width: 100%;
	  margin-left: 0;
	}
  }
  .hero_isMirroredX__VTkit .hero_background__TgRvX {
	top: 0;
  }
  .hero_isMirroredX__VTkit .hero_videoLoop__4fbLE {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
  }
  .hero_ctaScroll__OBXDN {
	position: absolute !important;
	z-index: 2;
	right: 2.1rem;
	bottom: 2rem;
  }
  @media (min-width: 600px) and (max-width: 1023px) {
	.hero_ctaScroll__OBXDN {
	  right: 3.36rem;
	}
  }
  @media (min-width: 1024px) {
	.hero_ctaScroll__OBXDN {
	  right: 6rem;
	  bottom: 7rem;
	  opacity: 0;
	  transition: opacity 2s cubic-bezier(0.26, 1.04, 0.54, 1) 0.5s;
	}
  }
  .hero_ctaScroll__OBXDN svg {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
  }
  .dots_dots__nGOi0 {
	display: block;
	position: absolute;
	bottom: 100%;
	margin-bottom: 2rem;
	line-height: 0;
  }
  .dots_dots__nGOi0:after,
  .dots_dots__nGOi0:before {
	content: "";
	display: inline-block;
	width: 1rem;
	height: 1rem;
	background: #fff;
	border-radius: 50%;
  }
  .dots_dots__nGOi0:before {
	/* background: #781edc; */
  }
  .dots_dots__nGOi0:after {
	margin-left: 0.4rem;
  }
  .slideshow_slideshow__Ec532 {
	position: relative;
	width: 100%;
  }
  .slideshow_wrapper__TGK9O {
	position: relative;
	z-index: 1;
	-webkit-user-select: none;
	user-select: none;
	touch-action: pan-y;
  }
  .slideshow_list__wWMPN {
	display: inline-flex;
	outline: none;
  }
  .slideshow_list__wWMPN > * {
	flex-shrink: 0;
  }
  .cards_cards__IjwSG {
	overflow: hidden;
	overflow: clip;
	padding: 6rem 0;
  }
  @media (min-width: 1024px) {
	.cards_cards__IjwSG {
	  padding: 12rem 0;
	}
  }
  .cards_title__fA__M {
	position: relative;
	margin: 2rem 0 4rem;
  }
  @media (min-width: 1024px) {
	.cards_title__fA__M {
	  margin: 2rem 0 6rem;
	}
  }
  .cards_entry__RI3ox {
	display: flex;
	position: relative;
	z-index: 0;
	width: 88.8vw;
	overflow: hidden;
	overflow: clip;
	flex-direction: column;
	margin-right: 5.55vw;
	padding: 3rem 5.55vw;
	border: 1px solid hsla(0, 0%, 100%, 0.15);
	border-radius: 0.8rem;
	cursor: pointer;
  }
  @media (min-width: 1024px) {
	.cards_entry__RI3ox {
	  width: 42.0090497738rem;
	  margin-right: 2.9864253394rem;
	  padding: 4rem 3rem;
	}
  }
  .cards_entry__RI3ox:last-child {
	margin-right: 0;
  }
  @media (hover: hover) {
	.cards_entry__RI3ox:hover .cards_entryExcerpt__MIldo,
	.cards_entry__RI3ox:hover .cards_entrySubhead__MAEUt {
	  color: var(--color);
	  transition-duration: 0s;
	}
	.cards_entry__RI3ox:hover .cards_entryCta__2WpyQ {
	  background: #fff;
	  color: #000;
	  transition-duration: 0s;
	}
  }
  .cards_entry__RI3ox:active .cards_entryExcerpt__MIldo,
  .cards_entry__RI3ox:active .cards_entrySubhead__MAEUt {
	color: var(--color);
	transition-duration: 0s;
  }
  .cards_entry__RI3ox:active .cards_entryCta__2WpyQ {
	background: #fff;
	color: #000;
	transition-duration: 0s;
  }
  .cards_entryExcerpt__MIldo,
  .cards_entrySubhead__MAEUt {
	transition: color 0.5s;
  }
  .cards_entrySubhead__MAEUt {
	display: block;
	margin-bottom: 2rem;
	color: var(--color-mid);
  }
  .cards_entryExcerpt__MIldo {
	margin: 5rem 0 10rem;
	color: var(--color-mid);
  }
  @media (min-width: 1024px) {
	.cards_entryExcerpt__MIldo {
	  margin: 5rem 0 13rem;
	}
  }
  .cards_entryFooter___m4K7 {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-top: auto;
  }
  .cards_entryIcon__HWhsG {
	width: 22.2vw;
  }
  @media (min-width: 1024px) {
	.cards_entryIcon__HWhsG {
	  width: 12rem;
	}
  }
  @-webkit-keyframes marquee_loop__q8VZR {
	0% {
	  -webkit-transform: translateZ(0);
	  transform: translateZ(0);
	}
	to {
	  -webkit-transform: translate3d(-100%, 0, 0);
	  transform: translate3d(-100%, 0, 0);
	}
  }
  @keyframes marquee_loop__q8VZR {
	0% {
	  -webkit-transform: translateZ(0);
	  transform: translateZ(0);
	}
	to {
	  -webkit-transform: translate3d(-100%, 0, 0);
	  transform: translate3d(-100%, 0, 0);
	}
  }
  .marquee_marquee__zQP6H {
	position: relative;
	overflow: hidden;
	overflow: clip;
	padding: 4rem 0 5.2rem;
	-webkit-user-select: none;
	user-select: none;
	white-space: nowrap;
  }
  @media (min-width: 1024px) {
	.marquee_marquee__zQP6H {
	  padding: 4rem 0 7.6rem;
	}
  }
  .marquee_wrapper__Mhzwp {
	display: inline-flex;
	align-items: center;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
  }
  .marquee_item__oVQSI {
	display: inline-flex;
	position: relative;
	opacity: 0.2;
  }
  .marquee_item__oVQSI span {
	position: relative;
	padding: 0 0.3em;
	-webkit-animation: marquee_loop__q8VZR 2s linear infinite;
	animation: marquee_loop__q8VZR 2s linear infinite;
	will-change: transform;
	white-space: nowrap;
  }
  .accordion_accordion__Dy6Xk {
	color: var(--color-dark);
  }
  .accordion_accordion__Dy6Xk .accordion__item:before {
	content: "";
	display: block;
	position: relative;
	top: 0;
	width: 100%;
	height: 1px;
	opacity: 0.1;
	background: var(--color);
  }
  .accordion_accordion__Dy6Xk .accordion__button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.5rem 0;
	outline: 0;
	cursor: pointer;
	font-family: Helveticaneue,sans-serif;
  }
  @media (min-width: 1024px) {
	.accordion_accordion__Dy6Xk .accordion__button {
	  padding: 1.8rem 0;
	}
  }
  .accordion_accordion__Dy6Xk .accordion__button button {
	flex-shrink: 0;
  }
  .accordion_accordion__Dy6Xk .accordion__button .chevron {
	width: 1rem;
	height: 0.6rem;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
  }
  .accordion_accordion__Dy6Xk .accordion__heading {
	text-align: left;
  }
  .accordion_accordion__Dy6Xk .accordion__panel-wrapper {
	display: block;
	/* height: 0; */
	overflow: hidden;
	overflow: clip;
	transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
  }
  .accordion_accordion__Dy6Xk .accordion__panel-wrapper[hidden] {
	visibility: hidden;
  }
  .accordion_title__yrPML {
	margin-right: 1rem;
  }
  .accordion_description__ABiU0 {
	padding: 0 0 3rem;
	text-align: left;
  }
  @media (min-width: 1024px) {
	.accordion_description__ABiU0 {
	  padding: 0 0 4.5rem;
	}
  }
  .dots_dots__nGOi0 {
	display: block;
	position: absolute;
	bottom: 100%;
	margin-bottom: 2rem;
	line-height: 0;
  }
  .dots_dots__nGOi0:after,
  .dots_dots__nGOi0:before {
	content: "";
	display: inline-block;
	width: 1rem;
	height: 1rem;
	background: #fff;
	border-radius: 50%;
  }
  .dots_dots__nGOi0:before {
	/* background: #781edc; */
  }
  .dots_dots__nGOi0:after {
	margin-left: 0.4rem;
  }
  .accordion_header__AW1vE {
	margin-bottom: 2rem;
  }
  @media (min-width: 1024px) {
	.accordion_header__AW1vE {
	  margin-bottom: 4rem;
	}
  }
  .accordion_headerTitle__LLMh6 {
	position: relative;
  }
  .accordion_accordionDescription__2xXkh {
	width: 100%;
	color: var(--color-mid);
  }
  @media (min-width: 1024px) {
	.accordion_accordionDescription__2xXkh {
	  width: 64.5067873303rem;
	}
  }
  .accordion_accordionDescription__2xXkh a {
	text-decoration: underline;
  }
  .accordion_horizontal__ogP0s {
	padding: 10rem 0 3rem;
  }
  @media (min-width: 1024px) {
	.accordion_horizontal__ogP0s {
	  padding: 15rem 0 12rem;
	}
	.accordion_horizontal__ogP0s .accordion_header__AW1vE {
	  width: 42.0090497738rem;
	  margin-bottom: 0;
	}
	.accordion_horizontal__ogP0s .accordion_container__knRps {
	  display: flex;
	  justify-content: space-between;
	}
	.accordion_horizontal__ogP0s .accordion_accordion__ckkXK {
	  width: 87.0045248869rem;
	}
  }
  .lottie_lottie__mmQqj {
	opacity: 0;
	transition: all 0.5s cubic-bezier(0.26, 1.04, 0.54, 1);
	transition-property: opacity;
  }
  .dots_dots__nGOi0 {
	display: block;
	position: absolute;
	bottom: 100%;
	margin-bottom: 2rem;
	line-height: 0;
  }
  .dots_dots__nGOi0:after,
  .dots_dots__nGOi0:before {
	content: "";
	display: inline-block;
	width: 1rem;
	height: 1rem;
	background: #fff;
	border-radius: 50%;
  }
  .dots_dots__nGOi0:before {
	/* background: #781edc; */
  }
  .dots_dots__nGOi0:after {
	margin-left: 0.4rem;
  }
  .textAnimation_textAnimation__pxWgX {
	overflow: hidden;
	overflow: clip;
	padding-bottom: 4rem;
  }
  @media (min-width: 1024px) {
	.textAnimation_textAnimation__pxWgX {
	  padding: 8rem 0;
	}
  }
  .textAnimation_text__pUfgJ {
	padding: 5.8rem 0 2rem;
  }
  @media (min-width: 1024px) {
	.textAnimation_text__pUfgJ {
	  display: flex;
	  align-items: flex-start;
	  padding: 9rem 0 6rem;
	}
  }
  @media (min-width: 0px) and (max-width: 1023px) {
	.textAnimation_lottie__h0_i3 {
	  margin-bottom: 2rem;
	}
  }
  .textAnimation_horizontal__qZ_3o .textAnimation_lottie__h0_i3 {
	position: relative;
	top: -33.3vw;
	width: 77.7vw;
	height: 77.7vw;
	margin: 0 auto -33.3vw;
  }
  @media (min-width: 1024px) {
	.textAnimation_horizontal__qZ_3o .textAnimation_lottie__h0_i3 {
	  top: 0;
	  width: 59.2307692308rem;
	  height: 59.2307692308rem;
	  margin: -37.8778280543rem auto 3rem;
	}
  }
  @media (min-width: 1024px) {
	.textAnimation_content__Coh_a {
	  width: 42.0090497738rem;
	}
	.textAnimation_horizontal__qZ_3o .textAnimation_content__Coh_a {
	  margin-left: 14.2352941176rem;
	}
  }
  .textAnimation_title__OmZSo {
	position: relative;
  }
  @media (min-width: 1024px) {
	.textAnimation_horizontal__qZ_3o .textAnimation_title__OmZSo {
	  width: 64.5067873303rem;
	}
  }
  .textAnimation_description__O86UP {
	display: block;
	margin-top: 3rem;
	color: var(--color-mid);
  }
  @media (min-width: 1024px) {
	.textAnimation_horizontal__qZ_3o .textAnimation_description__O86UP {
	  margin-top: 0;
	}
  }
  .textAnimation_description__O86UP p {
	margin-bottom: 1em;
  }
  .textAnimation_description__O86UP p:last-child {
	margin-bottom: 0;
  }
  .textAnimation_cta__2fSHv {
	margin-top: 3rem;
  }
  @media (min-width: 1024px) {
	.textAnimation_cta__2fSHv {
	  margin-top: 4rem;
	}
  }
  .banner_banner__iqojY {
	display: flex;
	position: relative;
	align-items: center;
	min-height: 80vh;
	margin: 0 auto;
	padding: 2rem 0;
	background: #000;
  }
  @media (min-width: 1024px) {
	.banner_banner__iqojY {
	  min-height: 100vh;
	  padding: 6rem 0 7rem;
	}
  }
  .banner_content__K70EO {
	position: relative;
	z-index: 1;
  }
  .banner_cta__X7tTd {
	margin-top: 3rem;
  }
  @media (min-width: 1024px) {
	.banner_cta__X7tTd {
	  margin-top: 4rem;
	}
  }
  .banner_background__kty8z {
	position: absolute !important;
	z-index: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
  }
  .banner_isCentered__ZQ0Ua {
	text-align: center;
  }
/* </style> */


.homeinrooop{
	position: absolute;
	left: 52%;
	font-size: 1vw;
}
@media (max-width: 524px) {
	.homeinrooop{
		position: absolute;
		left: 5%;
	}
	.banner .banner-row .row-title,
	.banner .banner-row .row-letter {
		font-size: 4.5rem;
	}
}
/* Add this CSS code to your stylesheet */
.chevron {
	transition: transform 0.9s ease;
  }
  
  .accordion__panel-wrapper {
	/* max-height: 0; */
	overflow: hidden;
	transition: max-height 0.9s ease;
  }
  
  .accordion__panel-wrapper.hidden {
	transition: transform 0.9s ease;
	/* max-height: 0; */
  }
  *{
	font-family: Helveticaneue,sans-serif;
  }
  @media (max-width: 364px) {
	
	.banner .banner-row .row-title,
	.banner .banner-row .row-letter {
		font-size: 4rem;
	}
}
@media (max-width: 260px) {
	
	.banner .banner-row .row-title,
	.banner .banner-row .row-letter {
		font-size: 8vw;
	}
}

/* Apply transition to panel-wrapper and opacity property */
.accordion__panel-wrapper {
  opacity: 1;
  height: auto;
  overflow: hidden;
  transition: opacity 0.9s ease, height 0.9s ease;
}

/* Hide panel when not open */
.accordion__panel-wrapper.hidden {
  opacity: 0;
  height: 0;
}

.accordion__item{
	opacity: 1;
	height: auto;
	overflow: hidden;
	transition: opacity 0.9s ease, height 0.9s ease;
}
.accordion__button{
	opacity: 1;
	height: auto;
	overflow: hidden;
	transition: opacity 0.9s ease, height 0.9s ease;
}
.accordion__panel{
	opacity: 1;
	height: auto;
	overflow: hidden;
	transition: opacity 0.9s ease, height 0.9s ease;
}






/* body:not(.no-js) .image-wrap {
  transition: 1s ease-out;
  transition-delay: 0.2s;
  position: relative;
  width: auto;
  height: 80vh;
  overflow: hidden;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  visibility: hidden;
}

body:not(.no-js) .image-wrap img {
  transform: scale(1.3);
  transition: 2s ease-out;
}

body:not(.no-js) .animating .image-wrap {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  transform: skewY(0);
}

body:not(.no-js) .animating img {
  transform: scale(1);
  transition: 4s ease-out;
}

body:not(.no-js) .fadeup {
  opacity: 0;
  transition: 0.4s ease-out;
  transform: translateY(40px);
}

body:not(.no-js) .fading-up {
  opacity: 1;
  transition: 1s ease-out;
  transform: translateY(0px);
  transition-delay: 0.7s;
} */

/* </style> */

/* CSS for the grid container */
.grid-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
  }
  
  .grid-item {
	background-color: #3498db;
	color: white;
	text-align: center;
	padding: 20px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
  }
  
  .grid-item:hover {
	transform: scale(6.5) skew(-10deg);
	transition: transform 10.5s ease-in-out;

	/* transition: transform 0.5s ease-in-out;  */
  }
  
  .grid-item.selected {
	transform: skew(-80deg);
	background-color: #e74c3c;
  }
  

  
  .fullscreen-item.hidden {
	display: none;
  }
  
  .close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 20px;
	cursor: pointer;
	color: white;
  }
  .image-container {
	width: 100px;
	transition: width 0.3s ease-in-out;
	overflow: hidden;
  }
  
  .image-container.expanded {
	width: 5000px;
  }
  
  .laptop {
	transition: transform 0.3s ease-in-out;
	transform-origin: left center;
  }
  
  .img1.expanded {
	animation: moveRight 0.3s ease-in-out;
	width: 5000px;
  }
  
  @keyframes moveRight {
	0% {
	  transform: translateX(0);
	}
	100% {
	  transform: translateX(4900px); /* Adjust the value based on your needs */
	}
  }
 /* new  */

 

 /* hhahahahhahaaaaaaaaaaaaaaaaaaaaaaaaaaaaa */
.kaaa{
	text-transform: lowercase;
}

/* .dKCrEi {
    background-color: rgb(17, 20, 23);
    color: rgb(234, 234, 234);
    padding-top: 21px;
    padding-bottom: 100px;
}


.jDnXzM {
    width: 100%;
    display: flex;
    min-width: 0px;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    align-items: flex-end;
} */

/* CSS transitions for page elements */
.hm-pro01 {
	transition: transform 0.3s ease-in-out;
  }
  
  /* Apply transformations when the element is transformed */
  .hhm-p01.transformed .hm-pro01 {
	transform: translateX(-100%); /* Example transformation */
	opacity: 0; /* Optionally fade out the element */
  }
  /* Default styling for the image container */
.image-container {
	/* Add your default styles here */
  }
  
  /* Styling for fullscreen mode */
  .image-container.fullscreen {
	/* Define styles to make the image fullscreen here */
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.9); /* Add a semi-transparent background */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999; /* Make sure it appears on top of other content */
  }
  
  /* Style for the image */
  .img1 {
	/* Define your image styles here */
  }
  
  /* Style for the image description */
  .image-description {
	/* Define styles for the image description here */
  }
  @media screen and  (max-width:900px) {
	.homeinrooop{
		
		font-size: 18px;
		color: black;
	}
  }