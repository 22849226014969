
.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 0.72727273em;
  margin: 0;
  padding: 0 4px 5px;
}



.projectdetailgvk {
  font-size: 62.5%;
  text-size-adjust: 100%;
}

body {
  font-size: 18px;
  font-size: 1.8rem;
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  line-height: 1.46666667;
  /* font-family: Satoshi,sans-serif; */
  /* font-family: 'Urbanist', sans-serif; */
  /* font-family: SuisseIntl,sans-serif; */
  color: #222222;
  font-weight: 200;
}

@media (min-width: 992px) {
  body {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

html,
body {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: #633a86;
  color: #ffffff;
  text-shadow: none;
}

p {
  margin: 0 0 1.46666667em;
}

ul {
  list-style: disc;
}

ul,
ol {
  margin: 1.46666667em 0;
}

ul li,
ol li {
  margin-bottom: 0.5em;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin: 0.5em 0 0;
}

a {
  text-decoration: underline;
  outline: 0;
  /* transition: color 0.2s ease-in-out; */
  /* color: #f05644; */
  text-decoration-thickness: 1px;
}

a:hover,
a:focus {
  /* color: #633a86; */
}

button {
  transition: color 0.2s ease-in-out;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 calc(1em - 13px);
  line-height: 1.47727273;
}

h1,
.h1 {
  font-weight: 600;
  font-size: 2.72727273em;
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
}

h2,
.h2 {
  font-weight: 200;
  font-size: 2em;
}

h4,
.h4 {
  font-weight: 600;
  font-size: 0.88888889em;
}

.paragraph--lead {
  font-size: 1.11111111em;
  line-height: 1.46666667;
  font-weight: 400;
}

@media (min-width: 768px) {
  .paragraph--lead {
    font-size: 1.36363636em;
  }
}

.link--email {
  padding-left: 1.55em;
  display: inline-block;
  position: relative;
}

.link--email .link__icon {
  position: absolute;
  top: calc(50% - (0.70833333em / 2));
  left: 0;
}

.link--email .link__icon svg {
  display: block;
  font-size: 0.92307692em;
  height: 0.70833333em;
  width: 1em;
}

.post-edit-link {
  padding: 0 0.25em;
  display: inline-block;
  font-size: 1em;
}

.post-edit-link svg {
  height: 0.8em;
  width: 0.8em;
  display: block;
}

.post-edit-link svg path {
  fill: currentColor;
}

.text--underline {
  text-decoration: underline;
}

.text--strikethrough {
  text-decoration: line-through;
}

.no-focus-outline button:focus,
.no-focus-outline
a:focus,
.no-focus-outline
.btn:focus {
  outline: none;
}

body:not(.no-focus-outline) button:focus,
body:not(.no-focus-outline)
a:focus,
body:not(.no-focus-outline)
.btn:focus {
  /* outline: 0.2rem solid #633a86; */
  outline-offset: -0.1rem;
}

.panel--dark body:not(.no-focus-outline) button:focus,
.panel--dark body:not(.no-focus-outline)
a:focus,
.panel--dark body:not(.no-focus-outline)
.btn:focus {
  outline: 0.2rem solid white;
  outline-offset: -0.1rem;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.alert p:last-child {
  margin-bottom: 0;
}

.alert--no-js {
  background: maroon;
  padding: 10px 20px;
  position: relative;
  color: #ffffff;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  z-index: 10;
}

.alert--error {
  background: maroon;
  padding: 10px 20px;
  color: #ffffff;
  margin-bottom: 1.07142857em;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 3px;
}

.alert--success {
  background: #633a86;
  padding: 10px 20px;
  color: #ffffff;
  margin-bottom: 1.07142857em;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 3px;
}

.banner--about {
  position: relative;
  padding-bottom: 3.66666667%;
  background: #fff;
  color: #222222;
}

.no-js .banner--about {
  background-color: var(--banner-background-color);
}

.banner--about .banner__image {
  text-align: center;
  margin-top: 38px;
}

@media (min-width: 768px) {
  .banner--about .banner__image {
    margin-top: 0;
  }
}

.banner--about .banner__caption {
  font-weight: 400;
  line-height: 1.46666667;
  transition: ease-in-out 0.2s color;
  position: relative;
  text-align: center;
}

@media (min-width: 768px) {
  .banner--about .banner__caption {
    max-width: 660px;
    margin: 0 auto;
    font-size: 1.36363636em;
  }
}

.banner--about .banner__caption .banner__title {
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  font-weight: 400;
  font-size: 1.11111111em;
}

@media (min-width: 768px) {
  .banner--about .banner__caption .banner__title {
    font-size: 1em;
  }
}

.banner--about .section--stats {
  position: relative;
  z-index: 0;
}

.banner--about .section--stats .banner__image {
  z-index: -1;
}

@media (min-width: 768px) {
  .banner--about .section--stats .banner__image {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - (25.74626866% * 2));
    height: 100%;
  }
}

@media (min-width: 992px) {
  .banner--about .section--stats .banner__image {
    height: auto;
  }
}

.banner--about .section--stats .banner__image img {
  max-width: 79.36507937%;
}

@media (min-width: 768px) and (max-width: 992px) {
  .banner--about .section--stats .banner__image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    max-width: none;
  }
}

@media (min-width: 992px) {
  .banner--about .section--stats .banner__image img {
    max-width: 55.38461538%;
  }
}

.banner--case-study {
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  position: relative;
  color: #222222;
  background-color: #ffffff;
}

@media (max-width: 1199px) {
  .banner--case-study {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .banner--case-study:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 59.77777778%;
  }
}

@media (min-width: 1200px) {
  .banner--case-study {
    height: 100vh;
  }
}

.banner--case-study.banner--overlay .banner__video:after,
.banner--case-study.banner--overlay .banner__image:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  opacity: 0;
  /* animation: fadeIn 0.5s linear 0s forwards; */
}

@media (max-width: 767px) {
  .banner--case-study .banner__image {
    margin-bottom: 21px;
    position: relative;
  }

  .banner--case-study .banner__image:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 76%;
  }
}

.banner--case-study .banner__image img {
  width: 100%;
  height: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@supports (object-fit: cover) {
  .banner--case-study .banner__image img {
    object-fit: cover;
    height: 100%;
  }
}

.banner--case-study .banner__video {
  display: block;
  overflow: hidden;
  position: relative;
}

@media (max-width: 767px) {
  .banner--case-study .banner__video {
    margin-bottom: 21px;
  }

  .banner--case-study .banner__video:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 76%;
  }
}

@media (min-width: 768px) {
  .banner--case-study .banner__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.banner--case-study .banner__video video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.banner--case-study .banner__title {
  font-size: 1.44444444em;
  font-weight: 600;
  line-height: 1.34545455;
  width: 100%;
  max-width: 800px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 768px) {
  .banner--case-study .banner__title {
    font-size: 1.63636364em;
    display: block;
  }
}

@media (min-width: 992px) {
  .banner--case-study .banner__title {
    font-size: 2.5em;
  }
}

@media (max-width: 767px) {
  .banner--case-study .banner__title[data-waay]:not(.waay-animate) *[data-waay-step] {
    opacity: 1;
    transform: translateY(0);
  }
}

.banner--case-study .banner__title span {
  display: block;
  color: black;
}

.banner--case-study .banner__title .banner__subtitle {
  display: block;
  font-weight: 400;
  opacity: 0.75;
  font-size: 0.61538462em;
  line-height: 2;
  margin-top: 0.6875em;
}

@media (min-width: 768px) {
  .banner--case-study .banner__title .banner__subtitle {
    font-size: 0.55555556em;
    margin-top: 0.85em;
  }
}

@media (min-width: 992px) {
  .banner--case-study .banner__title .banner__subtitle {
    font-size: 0.36363636em;
    margin-bottom: 0.85em;
    margin-top: 0;
  }
}

.banner--case-study.banner--case-study--white {
  color: #ffffff;
  background-color: #222222;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.banner--contact {
  position: relative;
  padding-bottom: 65px;
}

@media (min-width: 768px) {
  .banner--contact {
    padding-bottom: 3.66666667%;
  }
}

.no-js .banner--contact {
  background-color: var(--banner-background-color);
}

.banner--contact .banner__image {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.banner--contact .banner__image svg {
  display: block;
  width: 26.44444444vw;
  height: 27.66666667vw;
}

.banner--contact .banner__title {
  color: #231f20;
  margin-bottom: 0.93333333em;
  font-size: 1.66666667em;
  line-height: 1.33333333;
}

@media (min-width: 768px) {
  .banner--contact .banner__title {
    font-size: 2.02727273em;
    margin-bottom: 0.7em;
  }
}

.banner--contact .banner__caption {
  font-weight: 400;
  line-height: 1.46666667;
  transition: ease-in-out 0.2s color;
  position: relative;
  text-align: center;
}

.banner--contact .banner__caption .banner__content {
  margin: 0 auto;
  font-size: 1.11111111em;
  text-transform: initial;
}

@media (min-width: 768px) {
  .banner--contact .banner__caption .banner__content {
    font-size: 0.78181818em;
  }
}

.banner--contact .banner__caption .banner__content .banner__link {
  margin-top: 49px;
}

@media (min-width: 768px) {
  .banner--contact .banner__caption .banner__content .banner__link {
    margin-top: 5.375%;
  }
}

.no-js .banner--team {
  background-color: var(--banner-background-color);
}

.banner--team.banner--has-link {
  margin-top: 2.8rem;
}

@media (min-width: 768px) {
  .banner--team.banner--has-link {
    margin-top: -1.31944444vw;
  }
}

@media (min-width: 1800px) {
  .banner--team.banner--has-link {
    margin-top: -1.9rem;
  }
}

.banner--team .banner__inner {
  display: flex;
  flex-direction: column;
}

.banner--team .banner__inner > div {
  width: 100%;
}

.banner--team .banner__link {
  display: block;
  position: absolute;
  top: var(--header-height, 7.3rem);
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0 1rem;
}

@media (min-width: 1200px) {
  .banner--team .banner__link {
    top: calc(var(--header-height, 12.4rem) + 1.3rem);
  }
}

.banner--team .banner__link a {
  background: white;
  border-radius: 0.74074074vw;
  padding: 0.5em 1.3125em;
  line-height: 1.625;
  font-weight: 600;
  letter-spacing: 0;
  text-decoration: none;
  font-size: 1.1rem;
  color: #2f3234;
  text-align: center;
  display: inline-block;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
}

@media (min-width: 1080px) {
  .banner--team .banner__link a {
    font-size: 1.11111111vw;
    border-radius: 0.8rem;
  }
}

@media (min-width: 1440px) {
  .banner--team .banner__link a {
    font-size: 1.6rem;
  }
}

@media (min-width: 768px) {
  .banner--team .banner__link a .btn__inner {
    display: flex;
    align-items: center;
    gap: 0 2.1rem;
  }
}

.banner--team .banner__link a .btn__icon {
  color: #2081ff;
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
}

.banner--team .banner__link a:hover,
.banner--team .banner__link a:focus,
.banner--team .banner__link a.on-press {
  color: white;
  background: #2081ff;
}

.banner--team .banner__link a:hover .btn__icon,
.banner--team .banner__link a:focus .btn__icon,
.banner--team .banner__link a.on-press .btn__icon {
  color: white;
}

.banner--team .banner__image {
  position: relative;
  margin: 0 auto;
  max-width: 180rem;
  order: -1;
  transition: ease-in-out 0.2s opacity;
}

.banner--team .banner__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 55.55555556%;
}

@media (min-width: 1800px) {
  .banner--team .banner__image:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100rem;
  }
}

.banner--team .banner__image img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-position: bottom center;
  object-fit: scale-down;
}

.banner--team .banner__caption {
  font-weight: 400;
  font-size: 1.36363636em;
  line-height: 1.46666667;
  transition: ease-in-out 0.2s color;
  position: relative;
  text-align: center;
}

@media (min-width: 768px) {
  .banner--team .banner__caption {
    min-height: 80vh;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: no-preference) {
  .banner--team .banner__caption {
    display: block;
  }
}

.banner--team .banner__caption .banner__title {
  font-size: 0.8em;
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  font-weight: 400;
}

@media (min-width: 768px) {
  .banner--team .banner__caption .banner__title {
    font-size: 1em;
  }
}

.banner--team .banner__caption-inner {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  will-change: transform;
}

.banner--team .banner__caption-inner p:last-child {
  margin-bottom: 0;
}

.banner--team.light .banner__caption {
  color: rgba(255, 255, 255, 0.75);
}

.banner--page {
  position: relative;
  padding-top: 10.83333333%;
  padding-bottom: 3.66666667%;
  background: #fff;
  text-align: center;
  color: #222222;
}

@media (min-width: 1800px) {
  .banner--page {
    padding-top: 195px;
  }
}

.search-results .banner--page {
  padding-bottom: 0;
}

.banner--landing {
  text-align: center;
  padding-bottom: 4.38888889%;
}

@media (min-width: 1800px) {
  .banner--landing {
    padding-bottom: 79px;
  }
}

.banner--landing .container {
  max-width: 593px;
}

.banner--landing .banner__title {
  font-weight: 400;
  color: #1a1b1d;
  letter-spacing: 0;
  line-height: 1.28571429;
  font-size: 2.4rem;
}

@media (min-width: 768px) {
  .banner--landing .banner__title {
    font-size: 3.5rem;
  }
}

.banner--landing-sticky-banner .banner__inner {
  display: flex;
  flex-direction: column;
  margin-left: -10.05555556vw;
  margin-bottom: -40px;
}

@media (min-width: 992px) {
  .banner--landing-sticky-banner .banner__inner {
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 1800px) {
  .banner--landing-sticky-banner .banner__inner {
    margin-left: -181px;
  }
}

.banner--landing-sticky-banner .banner__inner > div {
  padding-left: 10.05555556vw;
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .banner--landing-sticky-banner .banner__inner > div {
    width: 50%;
  }
}

@media (min-width: 1800px) {
  .banner--landing-sticky-banner .banner__inner > div {
    padding-left: 181px;
  }
}

@media (min-width: 1200px) {
  .banner--landing-sticky-banner .banner__inner > div:nth-child(2n + 1) {
    width: 38.56502242%;
  }
}

@media (min-width: 1200px) {
  .banner--landing-sticky-banner .banner__inner > div:nth-child(2n + 2) {
    width: 61.43497758%;
  }
}

.banner--landing-sticky-banner .banner__title {
  font-weight: 600;
  color: #1a1b1d;
  letter-spacing: 0;
  line-height: 1.08;
  font-size: 2.27272727em;
}

.banner--landing-sticky-banner .banner__description {
  font-weight: 400;
  font-size: 2rem;
  color: #404347;
  letter-spacing: 0;
  line-height: 1.5;
}

.banner--landing-sticky-banner .banner__video {
  width: 100%;
  position: relative;
}

.banner--landing-sticky-banner .banner__video:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 70.95115681%;
}

.banner--landing-sticky-banner .banner__video video {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-position: center center;
  object-fit: cover;
  border-radius: 8px;
}

.banner--landing-sticky-banner .banner__video .btn--video-play-modaal {
  position: absolute;
  bottom: 1.38888889em;
  right: 1.77777778em;
  z-index: 2;
  font-weight: 400;
  font-size: 0.81818182em;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 1.55555556;
  display: flex;
  align-items: center;
}

.banner--landing-sticky-banner .banner__video .btn--video-play-modaal img {
  width: 1.88888889em;
  height: auto;
  display: block;
  margin-right: 0.77777778em;
}

.banner--landing-sticky-banner .banner__buttons {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.05555556vw;
  margin-bottom: -19px;
  margin-top: 49px;
}

@media (min-width: 1800px) {
  .banner--landing-sticky-banner .banner__buttons {
    margin-left: -19px;
  }
}

.banner--landing-sticky-banner .banner__buttons > div {
  padding-left: 1.05555556vw;
  margin-bottom: 19px;
  width: 100%;
}

@media (min-width: 768px) {
  .banner--landing-sticky-banner .banner__buttons > div {
    width: 50%;
  }
}

@media (min-width: 1800px) {
  .banner--landing-sticky-banner .banner__buttons > div {
    padding-left: 19px;
  }
}

.banner--landing-sticky-banner .banner__buttons > div .btn {
  display: block;
  width: 100%;
  border-radius: 3px;
  padding: 1.23529412em 1.05882353em 1.11764706em;
  font-size: 0.77272727em;
  line-height: 1.11764706;
}

.banner--landing-sticky-banner .banner__sticky {
  max-width: 1366px;
  width: calc(100% - 20px);
  margin: 0 auto;
  position: fixed;
  bottom: 10px;
  background: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(34, 34, 34, 0.15);
  border-radius: 3px;
  overflow: hidden;
  font-weight: 400;
  font-size: 1.6rem;
  color: #1a1b1d;
  letter-spacing: 0;
  line-height: 1.875;
  z-index: 20;
  left: 10px;
  padding: 14px 20px;
  transition: ease-in-out 0.2s opacity, ease-in-out 0.2s transform;
}

@media (min-width: 768px) {
  .banner--landing-sticky-banner .banner__sticky {
    padding: 0;
    width: calc(100% - 60px);
    font-size: 1.4rem;
    left: 30px;
    bottom: 20px;
  }
}

body:not(.js-scrolled-100) .banner--landing-sticky-banner .banner__sticky {
  opacity: 0;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  body:not(.js-scrolled-100) .banner--landing-sticky-banner .banner__sticky {
    transform: translateY(30px);
  }
}

@media (min-width: 1200px) {
  .banner--landing-sticky-banner .banner__sticky {
    font-size: 1.6rem;
  }
}

@media (min-width: 1426px) {
  .banner--landing-sticky-banner .banner__sticky {
    left: calc(((100vw - 1366px) / 2));
  }
}

.banner--landing-sticky-banner .banner__sticky-inner {
  display: flex;
  align-items: center;
  margin-left: -1.94444444vw;
}

@media (min-width: 1800px) {
  .banner--landing-sticky-banner .banner__sticky-inner {
    margin-left: -35px;
  }
}

.banner--landing-sticky-banner .banner__sticky-inner p {
  margin: 0;
}

.banner--landing-sticky-banner .banner__sticky-inner a {
  font-weight: 600;
  color: inherit;
}

.banner--landing-sticky-banner .banner__sticky-inner a:hover,
.banner--landing-sticky-banner .banner__sticky-inner a:focus,
.banner--landing-sticky-banner .banner__sticky-inner a.on-press {
  color: #f05644;
}

.banner--landing-sticky-banner .banner__sticky-inner svg {
  display: block;
}

.banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-face {
  background: var(--face-bg, #633a86);
}

@media (max-width: 767px) {
  .banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-face {
    display: none;
  }
}

.banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-face-inner {
  padding: 24px 22px 20px;
  min-height: 89px;
  min-width: 89px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-face .face-flick:not(.active) {
  display: none;
}

.banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-cta,
.banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-phone {
  white-space: nowrap;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-phone {
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-cta {
    padding-right: 23px;
  }
}

.banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-cta .btn {
  font-weight: 600;
  font-size: 1.4rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.1875;
  padding: 1em 2.125em 0.9375em;
}

@media (min-width: 768px) {
  .banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-cta .btn {
    font-size: 1.6rem;
  }
}

.banner--landing-sticky-banner .banner__sticky-inner .link--phone {
  display: flex;
  align-items: center;
  text-decoration: none;
}

@media (max-width: 767px) {
  .banner--landing-sticky-banner .banner__sticky-inner .link--phone {
    font-size: 1.3rem;
  }
}

.banner--landing-sticky-banner .banner__sticky-inner .link--phone .link__icon {
  font-size: 1.25em;
  margin-right: 0.6em;
}

.banner--landing-sticky-banner .banner__sticky-inner .link--phone .link__icon svg {
  display: block;
  height: 1em;
  width: 1em;
}

.banner--landing-sticky-banner .banner__sticky-inner > div {
  padding-left: 1.94444444vw;
}

@media (min-width: 1800px) {
  .banner--landing-sticky-banner .banner__sticky-inner > div {
    padding-left: 35px;
  }
}

.banner--landing-sticky-banner .banner__sticky-inner > div:first-child {
  align-self: stretch;
}

.banner--landing-sticky-banner .banner__sticky-inner > div.banner__sticky-body {
  flex-grow: 1;
  padding-top: 4px;
  padding-bottom: 4px;
}

@media (max-width: 767px) {
  .banner--landing-sticky-banner .banner__sticky-inner > div.banner__sticky-body {
    display: none;
  }
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 0;
}

.btn {
  display: inline-block;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
}

.btn--border {
  border: 1px solid #a4a4a4;
  transition: ease-in-out 0.2s border-color, ease-in-out 0.2s color;
  color: rgba(34, 34, 34, 0.75);
  font-size: 0.81818182em;
}

.btn--border:hover,
.btn--border:focus,
.btn--border.on-press {
  /* border-color: #633a86; */
  color: #222222;
}

.btn--link,
.btn--link-arrow {
  font-weight: 500;
  font-size: 0.81818182em;
  padding: 0;
  text-align: left;
  color: inherit;
}

.btn--link .btn__icon,
.btn--link-arrow .btn__icon {
  display: inline-block;
  margin-left: 30px;
  transition: ease-in-out 0.2s transform;
}

.btn--link .btn__icon svg,
.btn--link-arrow .btn__icon svg {
  display: block;
}

.btn--link .btn__icon svg path,
.btn--link-arrow .btn__icon svg path {
  fill: currentColor;
}

.btn--link .btn__label,
.btn--link-arrow .btn__label {
  transition: ease-in-out 0.2s opacity;
}

.btn--link:hover .btn__label,
.btn--link-arrow:hover .btn__label,
.btn--link:focus .btn__label,
.btn--link-arrow:focus .btn__label,
.btn--link.on-press .btn__label,
.on-press.btn--link-arrow .btn__label {
  opacity: 0.75;
}

.btn--link-arrow {
  position: relative;
  padding-right: 4.27777778em;
}

.btn--link-arrow .btn__icon {
  position: absolute;
  right: 0;
  top: calc(50% - (0.94444444em / 2));
}

.btn--link-arrow .btn__icon svg {
  display: block;
  width: 1.38888889em;
  height: 0.88888889em;
  transform: rotate(-45deg);
}

@media (prefers-reduced-motion: no-preference) {
  .btn--link-arrow:hover .btn__icon,
  .btn--link-arrow:focus .btn__icon,
  .btn--link-arrow.on-press .btn__icon {
    /* transform: translateX(6px); */
  }
}

.section--floating-cta .btn--link-arrow,
.case-study__footer--alt .btn--link-arrow {
  font-size: 1.6rem;
  padding: 1.4375em 1.5625em;
  text-align: center;
  width: 100%;
  max-width: 31.5rem;
}

.case-study__introduction-link .btn--link-arrow {
  font-size: 1.8rem;
  padding-right: 2.77777778em;
}

@media (min-width: 768px) {
  .case-study__introduction-link .btn--link-arrow {
    margin-bottom: 1.83333333em;
  }
}

.case-study__introduction-link .btn--link-arrow svg {
  transform: rotate(-45deg);
}

.case-study__introduction-link .btn--link-arrow:hover,
.case-study__introduction-link .btn--link-arrow:focus,
.case-study__introduction-link .btn--link-arrow.on-press {
  color: inherit;
}

@media (prefers-reduced-motion: no-preference) {
  .case-study__introduction-link .btn--link-arrow:hover .btn__icon,
  .case-study__introduction-link .btn--link-arrow:focus .btn__icon,
  .case-study__introduction-link .btn--link-arrow.on-press .btn__icon {
    /* transform: translateX(6px) translateY(-6px); */
  }
}

.btn--video-play,
.btn--video-play-modaal {
  text-decoration: none;
  transition: ease-in-out 0.2s opacity;
  padding: 0;
}

.btn--video-play:hover,
.btn--video-play:focus,
.btn--video-play.on-press,
.btn--video-play-modaal:hover,
.btn--video-play-modaal:focus,
.btn--video-play-modaal.on-press {
  opacity: 0.75;
}

.btn--video-play img,
.btn--video-play-modaal img {
  display: block;
  width: 100%;
  height: auto;
}

.btn--planner {
  background: black;
  border-radius: 45px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.90909091em;
  letter-spacing: 0;
  text-align: center;
  padding: 6px;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  display: block;
  position: relative;
  padding-left: calc(2em + 6px + (10px * 2));
}

@media (min-width: 480px) {
  .btn--planner {
    display: inline-block;
  }
}

.banner--contact .btn--planner {
  font-size: 1em;
}

@media (min-width: 768px) {
  .banner--contact .btn--planner {
    font-size: 0.76923077em;
  }
}

.btn--planner .flex {
  display: flex;
  align-items: center;
  /* min-height: calc(2em + (10px * 2)); */
  height: inherit;
}

.btn--planner .btn__face {
  background: #99daf2;
  display: block;
  border-radius: 45px;
  padding: 10px;
  width: calc(2em + (10px * 2));
  transition: background 0.2s ease-in-out;
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 2;
}

.btn--planner .btn__face svg {
  width: 2em;
  height: 2em;
  display: block;
  position: relative;
  left: -0.1em;
  top: 0.1em;
  margin-left: auto;
  transform: rotate(0deg);
  transform-origin: center center;
  transition: none;
}

.btn--planner .btn__label {
  flex-grow: 1;
  padding: 0em 1em 0.5em;
  white-space: nowrap;
  font-size: 11px;
  color: white;
}

@media (min-width: 768px) {
  .btn--planner .btn__label {
    padding: 0em 1em 0.5em;
    font-size: 19px;
    color: white;
  }
}

.btn--planner .btn__arrow {
  padding: 0.3em 1.2em 0.3em 0.5em;
}

.btn--planner .btn__arrow svg {
  display: block;
  width: 1.4em;
  height: 1.4em;
  transition: transform 0.2s ease-in-out;
}

.btn--planner .btn__arrow svg path {
  transition: fill 0.2s ease-in-out;
}

.btn--planner:hover,
.btn--planner:focus,
.btn--planner.on-press {
  background: black;
  color: white;
}

.btn--planner:hover .btn__face,
.btn--planner:focus .btn__face,
.btn--planner.on-press .btn__face {
  background: #ffc938;
}

.btn--planner:hover .btn__arrow svg,
.btn--planner:focus .btn__arrow svg,
.btn--planner.on-press .btn__arrow svg {
  /* transform: translateX(6px); */
}

@media (prefers-reduced-motion: reduce) {
  .btn--planner:hover .btn__arrow svg,
  .btn--planner:focus .btn__arrow svg,
  .btn--planner.on-press .btn__arrow svg {
    transform: none;
  }
}

.btn--planner:hover .btn__arrow svg path,
.btn--planner:focus .btn__arrow svg path,
.btn--planner.on-press .btn__arrow svg path {
  fill: #633a86;
}

.is-transitioning .btn--planner .btn__face,
.btn--planner.is-animating .btn__face {
  width: calc(100% - 12px);
  transition: background 0.2s ease-in-out, 0.5s width cubic-bezier(0.54, 0, 0.41, 1);
}

@media (prefers-reduced-motion) {
  .is-transitioning .btn--planner .btn__face,
  .btn--planner.is-animating .btn__face {
    width: calc(2em + (10px * 2));
  }
}

.is-transitioning .btn--planner .btn__face svg,
.btn--planner.is-animating .btn__face svg {
  transform: rotate(360deg);
  transition: 0.5s transform cubic-bezier(0.54, 0, 0.41, 1);
}

@media (prefers-reduced-motion) {
  .is-transitioning .btn--planner .btn__face svg,
  .btn--planner.is-animating .btn__face svg {
    transform: none;
  }
}

.btn--purple,
.btn--blue,
.btn--yellow,
.btn--red,
.btn--pink,
.btn--lavender {
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.90909091em;
  transition: ease-in-out 0.2s background, ease-in-out 0.2s color;
  line-height: 1.46666667;
  padding: 0.7em 2.4em;
}

.btn--purple {
  background: #633a86;
  color: #ffffff;
}

.btn--purple:not([disabled]):hover,
.btn--purple:not([disabled]):focus,
.btn--purple:not([disabled]).on-press {
  background: #99daf2;
  color: #633a86;
}

.btn--purple.btn--border {
  background: transparent;
  color: #633a86;
  border: none;
  /* box-shadow: inset 0 0 0 2px #633a86; */
}

.btn--purple.btn--border:hover,
.btn--purple.btn--border:focus,
.btn--purple.btn--border.on-press {
  background: #633a86;
  color: #ffffff;
}

.btn--red {
  background: #f05644;
  color: #ffffff;
}

.btn--red:hover,
.btn--red:focus,
.btn--red.on-press {
  background: #ffc938;
  color: #f05644;
}

.btn--blue {
  background: #99daf2;
  color: #222222;
}

.btn--blue:hover,
.btn--blue:focus,
.btn--blue.on-press {
  background: #633a86;
  color: #99daf2;
}

.btn--yellow {
  background: #ffc938;
  color: #222222;
}

.btn--yellow:hover,
.btn--yellow:focus,
.btn--yellow.on-press {
  background: #99daf2;
  color: #633a86;
}

.btn--pink {
  background: #ffd1d1;
  color: #222222;
}

.btn--pink:hover,
.btn--pink:focus,
.btn--pink.on-press {
  background: #99daf2;
  color: #633a86;
}

.btn--lavender {
  background: #e2d1ff;
  color: #222222;
}

.btn--lavender:hover,
.btn--lavender:focus,
.btn--lavender.on-press {
  background: #99daf2;
  color: #633a86;
}

.btn--solid {
  font-size: 1.6rem;
  line-height: 1.875;
  letter-spacing: 0;
  font-weight: 500;
  border-radius: 3px;
}

.btn--solid-white {
  background: #ffffff;
  color: #633a86;
}

.btn--full {
  display: block;
  width: 100%;
}

.btn--header-cta {
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.11764706;
  padding: 0.94117647em 1.41176471em;
  border-radius: 3px;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .btn--header-cta {
    font-size: 1.7rem;
    padding: 0.94117647em 2.35294118em;
  }
}

.skip-to-link {
  position: fixed;
  top: 10px;
  left: 10px;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  color: #ffffff;
  background: #222222;
  opacity: 0;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  z-index: 999999;
  outline: none;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  padding: 10px 16px;
}

.skip-to-link:focus {
  overflow: visible;
  width: auto;
  height: auto;
  color: #ffffff;
  opacity: 1;
  clip: auto;
  white-space: normal;
  outline: none;
}

.skip-to-link:hover {
  background: #090909;
}

.block--award {
  font-size: 0.72727273em;
  line-height: 1.625;
}

.block--award .block__image {
  margin-bottom: 1.6875em;
  position: relative;
  max-width: 144px;
  width: auto;
}

.block--award .block__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.block--award .block__image img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.block--logo {
  background: #F9F9F9;
  width: 100%;
  padding: 9.375%;
}

.block--logo .block__image {
  position: relative;
}

.block--logo .block__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.block--logo .block__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.block--service {
  line-height: 2;
  font-size: 0.81818182em;
}

@media (min-width: 768px) {
  .block--service {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -5.72222222vw;
  }

  .block--service > div {
    padding-left: 5.72222222vw;
    width: 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 1800px) {
  .block--service {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -103px;
  }

  .block--service > div {
    padding-left: 103px;
    width: 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .block--service > div:nth-child(2n + 1) {
    width: 46.01611459%;
  }
}

@media (min-width: 1800px) {
  .block--service > div:nth-child(2n + 1) {
    width: 46.01611459%;
  }
}

@media (min-width: 768px) {
  .block--service > div:nth-child(2n + 2) {
    width: 53.98388541%;
  }
}

@media (min-width: 1800px) {
  .block--service > div:nth-child(2n + 2) {
    width: 53.98388541%;
  }
}

.block--service ul {
  opacity: 0.6;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 3em;
}

.block--service ul li {
  margin-bottom: 0.44444444em;
}

.block--service .slide__title {
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  line-height: 1.25;
  font-weight: 300;
  margin-bottom: 1.11363636em;
  font-size: 2.44444444em;
}

.block--service .block__cta {
  display: block;
  margin-top: 3em;
}

@media (min-width: 768px) {
  .block--service .block__cta {
    margin-top: 0;
  }
}

.block--service .block__cta .block__cta-content {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.block--service .block__cta .block__cta-content:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 117.6%;
}

.block--service .block__cta .block__cta-title {
  font-weight: 700;
  font-size: 1.44444444em;
  line-height: 1.15384615;
  margin-bottom: 0;
  transition: ease-in-out 0.2s transform;
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  padding: 34px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.block--service .block__cta .block__cta-title .block__cta-subtitle {
  display: block;
  opacity: 0.75;
  font-weight: 400;
  font-size: 0.76923077em;
  line-height: 1.5;
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  margin-bottom: 8px;
}

.block--testimonial {
  box-sizing: border-box;
  font-size: 0.88888889em;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .block--testimonial {
    font-size: 1.09090909em;
  }
}

.block--testimonial .block__cite {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -22px;
  flex-wrap: nowrap;
  font-size: 0.83333333em;
  line-height: 1.5;
  font-weight: 500;
  padding-top: 0.35em;
}

.block--testimonial .block__cite > div {
  padding-left: 22px;
  width: 50%;
  margin-bottom: 0;
}

.block--testimonial .block__cite > div {
  flex-grow: 1;
  width: auto;
}

.block--testimonial .block__cite > div:first-child {
  width: 72px;
  flex-grow: 0;
}

.block--testimonial .block__image {
  border-radius: 100%;
  width: 100%;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.block--testimonial .block__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.block--testimonial .block__icon {
  position: relative;
}

.block--testimonial .block__icon:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 101.35746606%;
}

.block--testimonial .block__icon svg {
  width: 100%;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.grid--work .block--testimonial {
  max-width: 641px;
  /* background-image: url(/wp-content/themes/humaan5/dist/images/testimonial-bg.svg?cee19ad502de9dac3492291e84629bee); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 25.11700468% 30px 15.1326053%;
}

@media (min-width: 768px) {
  .grid--work .block--testimonial {
    padding: 25.11700468% 5.92823713% 15.1326053% 21.68486739%;
  }
}

@media (min-width: 1800px) {
  .grid--work .block--testimonial {
    padding: 161px 38px 97px 139px;
  }
}

.block--what-we-do {
  padding-top: 8.57632933%;
}

.block--what-we-do h2 {
  font-weight: 400;
  font-size: 1.36363636em;
  line-height: 1.46666667;
  margin-bottom: 2.16666667em;
}

.block--what-we-do .block__inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -8.48958333vw;
  margin-left: 0;
}

.block--what-we-do .block__inner > div {
  padding-left: 8.48958333vw;
  width: 100%;
  margin-bottom: 47px;
}

.block--what-we-do .block__inner > div {
  padding-left: 0;
}

@media (min-width: 768px) {
  .block--what-we-do .block__inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -8.48958333vw;
  }

  .block--what-we-do .block__inner > div {
    padding-left: 8.48958333vw;
    width: 50%;
    margin-bottom: 47px;
  }
}

.block--what-we-do .block__inner > div:nth-child(2) {
  color: #A1A1A1;
}

.block--what-we-do .block__inner ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.36363636em;
  line-height: 2;
}

.card--blog-post-hover .blog-read-more,
.card--blog-post .blog-item:hover .blog-read-more,
.card--blog-post .blog-item.focussed .blog-read-more,
.card--blog-post-hover .card__link,
.card--blog-post .blog-item:hover .card__link,
.card--blog-post .blog-item.focussed .card__link {
  color: #633a86;
}

.card--blog-post .blog-item {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.card--blog-post .blog-read-more {
  font-size: 0.81818182em;
  margin: 25px 0 0;
  text-decoration: none;
  color: #222222;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.card--blog-post .blog-meta,
.card--blog-post .post-edit-link {
  position: relative;
  z-index: 3;
}

.card--blog-post .card__link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.card--recommended-article {
  height: 100%;
}

.card--recommended-article .blog-item__inner {
  position: relative;
  display: flex;
  flex-direction: column;
}

.card--recommended-article .blog-item__inner .blog-hero-thumb {
  order: -1;
}

.card--recommended-article .blog-item__inner:hover .blog-read-more,
.card--recommended-article .blog-item__inner:hover .card__link,
.card--recommended-article .blog-item__inner:focus .blog-read-more,
.card--recommended-article .blog-item__inner:focus .card__link,
.card--recommended-article .blog-item__inner.on-press .blog-read-more,
.card--recommended-article .blog-item__inner.on-press .card__link {
  color: #99daf2;
}

.card--recommended-article .blog-meta {
  position: relative;
  z-index: 3;
}

.card--recommended-article .blog-read-more {
  font-size: 0.81818182em;
  margin: 25px 0 0;
  text-decoration: none;
  color: #222222;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.card--recommended-article .card__link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* .card--humaan .card__image {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
}

.card--humaan .card__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 138.55721393%;
}

.card--humaan .card__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: block;
}

@supports (object-fit: cover) {
  .card--humaan .card__image img {
    object-fit: cover;
    height: 100%;
    object-position: top center;
  }
}

.card--humaan .card__image video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: block;
}

@supports (object-fit: cover) {
  .card--humaan .card__image video {
    object-fit: cover;
    height: 100%;
    object-position: center center;
  }
} */

.card--humaan .card__caption {
  font-weight: 400;
  font-size: 0.72727273em;
  line-height: 1.66666667;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -20px;
  align-items: center;
}

.card--humaan .card__caption > div {
  padding-left: 20px;
  width: 50%;
  margin-bottom: 0;
}

.card--humaan .card__caption strong {
  font-weight: 600;
}

.card--humaan .card__caption .card__icon {
  font-size: 3.4375em;
  margin-top: 0.07272727em;
}

.card--humaan .card__caption .card__icon svg {
  display: block;
  width: 1em;
  height: 1em;
}

.card--humaan .card__caption > div:nth-child(2n + 1) {
  width: 70px;
}

.card--humaan .card__caption > div:nth-child(2n + 2) {
  width: calc(100% - 70px);
}

.card--stat {
  font-weight: 400;
  font-size: 0.90909091em;
  line-height: 1.5;
  text-align: center;
  color: #80868c;
}

.card--stat p {
  margin: 0;
}

.card--stat .card__number {
  font-size: 2.7em;
  line-height: 1.31578947;
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  font-weight: 600;
  color: #1d1d1d;
}

@media (min-width: 1200px) {
  .card--stat .card__number {
    font-size: 3.8em;
  }
}

.card--stat .card__symbol {
  font-size: 2.7em;
  letter-spacing: -0.01776316em;
  font-weight: 600;
  color: #1d1d1d;
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
}

@media (min-width: 1200px) {
  .card--stat .card__symbol {
    font-size: 3.8em;
  }
}

.card--stat .card__description {
  display: block;
  margin-top: 0.4em;
}

.card--work {
  position: relative;
  color: #222222;
}

.slider--services .card--work {
  color: inherit;
  cursor: none;
}

.slider--services .card--work a {
  cursor: none;
}

/* .card--work .card__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
} */

/* .card--work .card__caption {
  opacity: 1;
  transition: opacity ease-in-out 0.2s;
  padding-top: 1.5rem;
}

.card--work .card__title {
  font-weight: 500;
  line-height: 1.34545455;
  margin: 0;
 
  font-size: 1em;
} */
/* 
@media (min-width: 768px) {
  .card--work .card__title {
    font-size: 1.09090909em;
  }
}

.card--work .card__title .card__title-wrap {
  display: block;
  transition: transform ease-in-out 0.2s;
  will-change: transform;
  -webkit-transform-style: preserve-3d;
  display: flex;
  flex-direction: column-reverse;
}

.card--work .card__title .card__subtitle {
  display: block;
  opacity: 0.75;
  font-weight: 400;
  margin-top: 0.05em;
  line-height: 1.5;
  font-size: 0.88888889em;
  -webkit-backface-visibility: hidden;
}

@media (min-width: 768px) {
  .card--work .card__title .card__subtitle {
    font-size: 0.66666667em;
  }
}

.card--work .card__link {
  color: inherit;
  text-decoration: none;
  display: block;
}

.card--work .card__link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
} */

/* .card--work .card__image {
  position: relative;
  overflow: hidden;
  order: -1;
}

.card--work .card__image .card__object {
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  transition: ease-in-out 0.5s transform, ease-in-out 0.5s object-position;
}

@supports (object-fit: cover) {
  .card--work .card__image .card__object {
    height: 100%;
    object-fit: cover;
  }
} */

@media (prefers-reduced-motion: no-preference) {
  .card--work-hover .card__title .card__title-wrap,
  .card--work.card--has-link:hover .card__title .card__title-wrap,
  .card--work.card--has-link:focus .card__title .card__title-wrap,
  .card--work.card--has-link.on-press .card__title .card__title-wrap {
    /* transform: translateY(0.4rem); */
  }
}

@media (prefers-reduced-motion: no-preference) {
  .card--work-hover .card__image .card__object,
  .card--work.card--has-link:hover .card__image .card__object,
  .card--work.card--has-link:focus .card__image .card__object,
  .card--work.card--has-link.on-press .card__image .card__object {
    /* transition: ease-in-out 3s transform; */
    /* transform: scale(1.05, 1.05); */
  }
}

html.is-transitioning .card--work-hover .card__image .card__object,
html.is-transitioning .card--work.card--has-link:hover .card__image .card__object,
html.is-transitioning .card--work.card--has-link:focus .card__image .card__object,
html.is-transitioning .card--work.card--has-link.on-press .card__image .card__object {
  /* transition: ease-in-out 0.2s transform; */
  /* transform: scale(1, 1); */
}

.card--careers {
  font-size: 0.90909091em;
  line-height: 1.6;
}

@media (min-width: 600px) and (max-width: 1199px) {
  .grid--humaans .card--careers:nth-child(2n + 2) .card__inner {
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .grid--humaans .card--careers:nth-child(3n + 3) .card__inner {
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .grid--humaans .card--careers:nth-child(3n + 3) .card__inner > div {
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 1199px) {
  .grid--humaans .card--careers:nth-child(2n + 2) .card__inner {
    flex-direction: column;
  }
}

@media (min-width: 600px) and (max-width: 1199px) {
  .grid--humaans .card--careers:nth-child(2n + 2) .card__inner > div {
    width: 100%;
  }
}

.card--careers .card__inner {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3rem 3rem;
}

@media (min-width: 600px) {
  .card--careers .card__inner {
    align-items: center;
    flex-direction: row;
  }
}

.card--careers .card__inner > div {
  width: 100%;
}

@media (min-width: 1200px) {
  .card--careers .card__inner > div.card__image {
    width: 44.67005076%;
    max-width: 44rem;
  }
}

@media (min-width: 1200px) {
  .card--careers .card__inner > div.card__content {
    width: 55.32994924%;
    flex-grow: 1;
  }
}

.card--careers .card__title {
  font-size: 1.2em;
  margin-bottom: 1.33333333em;
  font-weight: 500;
}

.card--careers .card__content a {
  color: #fff;
}

.card--careers .card__content a:hover,
.card--careers .card__content a:focus,
.card--careers .card__content a.on-press {
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 1200px) {
  .card--careers .card__content-inner {
    max-width: 400px;
  }
}

/* .card--careers .card__image-bg {
  position: relative;
  width: 100%;
}

.card--careers .card__image-bg:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.card--careers .card__image-bg img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-position: center center;
  object-fit: scale-down;
} */

.drawer--enquiry {
  color: #ffffff;
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.625;
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.drawer--enquiry .drawer__mask {
  position: fixed;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(34, 34, 34, 0.5);
  z-index: -1;
  transition: ease-in-out 0.2s background;
}

.drawer--enquiry .drawer__wrap {
  position: fixed;
  background: #111111;
  top: 0;
  width: calc(100% - 30px);
  right: 0;
  max-width: 450px;
  bottom: 0;
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .drawer--enquiry .drawer__wrap {
    transition: ease-in-out 0.5s transform;
  }
}

.drawer--enquiry .drawer__wrap .drawer__inner {
  padding: 40px 30px;
  overflow: auto;
  height: 100%;
}

@media (min-width: 768px) {
  .drawer--enquiry .drawer__wrap .drawer__inner {
    padding: 77px 55px;
  }
}

.drawer--enquiry .drawer__title {
  font-weight: 600;
  font-size: 3.2rem;
  letter-spacing: 0;
  line-height: 1.375;
  margin-top: 0.4375em;
  margin-bottom: 0.46875em;
}

.drawer--enquiry .btn--close {
  position: absolute;
  top: 2.8rem;
  right: 2.8rem;
  text-align: center;
  height: 2rem;
  width: 2rem;
  padding: 0;
}

.drawer--enquiry .btn--close:before,
.drawer--enquiry .btn--close:after {
  content: "";
  display: inline-block;
  width: 2px;
  height: 2.5rem;
  background: #979797;
  transform: rotate(-45deg);
  /* transition: ease-in-out 0.2s background; */
  transform-origin: center center;
}

.drawer--enquiry .btn--close:after {
  margin-left: -2px;
  /* transform: rotate(45deg); */
}

.drawer--enquiry .btn--close:hover:before,
.drawer--enquiry .btn--close:hover:after,
.drawer--enquiry .btn--close:focus:before,
.drawer--enquiry .btn--close:focus:after,
.drawer--enquiry .btn--close.on-press:before,
.drawer--enquiry .btn--close.on-press:after {
  background: #ffffff;
}

.drawer--enquiry a {
  color: inherit;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
}

.drawer--enquiry a:hover,
.drawer--enquiry a:focus,
.drawer--enquiry a.on-press {
  color: #99daf2;
}

.drawer--enquiry:not(.drawer--opened) .drawer__mask {
  background: rgba(34, 34, 34, 0);
}

.drawer--enquiry:not(.drawer--opened) .drawer__wrap {
  transform: translateX(100%);
}

.form--enquiry .btn--submit {
  margin-top: 0.6875em;
  padding: 1.125em 1.5em;
  font-weight: 500;
  font-size: 1.6rem;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 1.5;
}

.form--enquiry .btn--submit[disabled] {
  opacity: 0.4;
}

.template-landing--hero-sticky-banner .footer-main .footer__country {
  padding-bottom: 120px;
}

input:not([type="radio"]):not([type="checkbox"]):not([type="file"]),
textarea {
  -webkit-appearance: none;
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  font-size: 1.2rem;
  text-transform: capitalize;
  padding: 1rem;
}

input:not([type="radio"]):not([type="checkbox"]):not([type="file"])::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.form-control {
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="file"] {
  cursor: pointer;
}

textarea.form-control {
  width: 100%;
  min-height: 180px;
  border: 2px solid #c7c7c7;
  border-radius: 3px;
  color: #222222;
  resize: none;
  padding: 0.8125em 1.25em;
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
  font-size: 0.88888889em;
}

@media (min-width: 992px) {
  textarea.form-control {
    font-size: 1.18181818em;
  }
}

textarea.form-control.error {
  border-color: #d03838;
}

textarea.form-control::placeholder {
  color: #bbb;
  transition: color 0.2s ease-in-out;
}

textarea.form-control:focus {
  border-color: #99daf2;
}

textarea.form-control:focus::placeholder {
  color: #ccc;
}

.form-field {
  margin-bottom: 42px;
}

.form-field__row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -30px;
}

.form-field__row > .form-field-col {
  padding-left: 30px;
  width: 50%;
  margin-bottom: 42px;
}

.form-field__row label {
  width: 100%;
  padding-left: 30px;
}

.form-label--dark {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 6px;
}

.form-label--dark .label {
  display: block;
  font-weight: 400;
  font-size: 1.4rem;
  color: #e1e1e1;
  letter-spacing: 0;
  line-height: 1.71428571;
  position: absolute;
  top: 1.14285714em;
  left: 1em;
  transform-origin: center left;
}

@media (prefers-reduced-motion: no-preference) {
  .form-label--dark .label {
    transition: ease-in-out 0.2s transform;
  }
}

.form-label--dark .label--required:after {
  content: " *";
}

.form-label--dark.form-label--filled .label {
  transform: scale(0.8, 0.8) translateY(-1.14285714em);
}

.form-control--dark {
  font-weight: 400;
  font-size: 1.4rem;
  color: #e1e1e1;
  letter-spacing: 0;
  line-height: 1.71428571;
  padding: 1.28571429em 0.85714286em 0.42857143em;
  background: #2c2c2c;
  display: block;
  width: 100%;
  border: transparent 2px solid;
  outline: none;
  transition: ease-in-out 0.2s border-color;
}

.form-control--dark:focus {
  border-color: #e1e1e1;
}

.form-control--dark.error {
  border-color: #d03838;
}

textarea.form-control--dark {
  min-height: 155px;
  max-width: 100%;
  width: 100%;
  min-width: 100%;
}

.form--subscribe {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.63636364em;
  width: 100%;
  text-align: center;
  max-width: 845px;
  margin: 0 auto 25px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .form--subscribe {
    margin: 0;
    text-align: left;
  }
}

.form--subscribe p {
  margin: 10px 0 0;
}

.form--subscribe input {
  display: block;
  width: 100%;
  padding: 0;
  background: transparent;
  border: 2px solid #f05644;
  border-width: 0 0 2px;
  color: #fff;
  font-size: 1.28571429em;
  outline: none;
  padding: 0.77777778em 0;
  padding-right: 34px;
  border-radius: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.form--subscribe input::placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.form--subscribe input:required {
  box-shadow: none;
}

.form--subscribe input:invalid {
  box-shadow: none;
}

.form--subscribe input:focus::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form--subscribe button {
  position: relative;
  float: right;
  width: 34px;
  height: 45px;
  background: transparent;
  margin: -47px 0 0 -34px;
  padding: 0;
  z-index: 1;
  padding-top: 7px;
}

@media (min-width: 768px) {
  .form--subscribe button {
    padding-top: 0;
  }
}

.form--subscribe button:before {
  content: "";
  background: linear-gradient(to right, rgba(34, 34, 34, 0) 0%, #222222 50%, #222222 100%);
  width: calc(200%);
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  z-index: -1;
}

.form--subscribe button svg path {
  transition: ease-in-out 0.2s fill;
}

.form--subscribe button:hover svg path,
.form--subscribe button:focus svg path,
.form--subscribe button.on-press svg path {
  fill: #fff;
}

.grid--awards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -20px;
  margin-top: 68px;
}

.grid--awards > div {
  padding-left: 20px;
  width: 50%;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .grid--awards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -52px;
  }

  .grid--awards > div {
    padding-left: 52px;
    width: 33.33333333%;
    margin-bottom: 48px;
  }
}

@media (min-width: 992px) {
  .grid--awards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -52px;
  }

  .grid--awards > div {
    padding-left: 52px;
    width: 25%;
    margin-bottom: 48px;
  }
}

.grid--humaans {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -8.33333333vw;
  margin-left: 0;
}

.grid--humaans > div {
  padding-left: 8.33333333vw;
  width: 100%;
  margin-bottom: 45px;
}

.grid--humaans > div {
  padding-left: 0;
}

@media (min-width: 600px) {
  .grid--humaans {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -8.33333333vw;
  }

  .grid--humaans > div {
    padding-left: 8.33333333vw;
    width: 50%;
    margin-bottom: 6.5vw;
  }
}

@media (min-width: 1200px) {
  .grid--humaans {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -8.33333333vw;
  }

  .grid--humaans > div {
    padding-left: 8.33333333vw;
    width: 33.33333333%;
    margin-bottom: 6.5vw;
  }
}

@media (min-width: 600px) and (max-width: 1199px) {
  .grid--humaans > div:not(:nth-child(2n + 2)) {
    width: 50%;
    flex-grow: 1;
  }
}

@media (min-width: 1200px) {
  .grid--humaans > div:not(:nth-child(3n + 3)).card--careers {
    width: 66.66%;
    flex-grow: 1;
  }
}

@media (min-width: 1800px) {
  .grid--humaans {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -150px;
  }

  .grid--humaans > div {
    padding-left: 150px;
    width: 33.33333333%;
    margin-bottom: 117px;
  }
}

.grid--gallery > .grid__block {
  margin-bottom: 3.33333333vw;
}

.grid--gallery .grid__block {
  width: 100%;
}

.grid--gallery .grid__block img {
  display: block;
  width: 100%;
  height: auto;
}

.grid--gallery .grid__block-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -3.33333333vw;
}

.grid--gallery .grid__block-row > .grid__block {
  padding-left: 3.33333333vw;
  width: 50%;
  margin-bottom: 3.33333333vw;
}

.grid--gallery .grid__block-row > .grid__block .block__image {
  position: relative;
}

.grid--gallery .grid__block-row > .grid__block .block__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid--gallery .grid__block-row > .grid__block:nth-child(3n+1) {
  width: 57.5%;
}

.grid--gallery .grid__block-row > .grid__block:nth-child(3n+1) .block__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 134.88372093%;
}

.grid--gallery .grid__block-row > .grid__block:nth-child(3n+2) {
  width: 42.5%;
}

.grid--gallery .grid__block-row > .grid__block:nth-child(3n+2) .block__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 77.41935484%;
}

.grid--gallery .grid__block-row > .grid__block:nth-child(3n+3) {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 42.5%;
}

.grid--gallery .grid__block-row > .grid__block:nth-child(3n+3) .block__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.grid--gallery .grid__block-row--alt {
  flex-direction: row-reverse;
}

.grid--gallery .grid__block-row--alt > .grid__block:nth-child(3n+3) {
  right: auto;
  left: 0;
}

.grid--logos {
  display: inline-block;
}

.grid--logos .grid__row {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -10px;
}

@media (min-width: 768px) {
  .grid--logos .grid__row {
    margin-left: -20px;
  }
}

.grid--logos .grid__row > div {
  width: 33.33vw;
  flex-shrink: 0;
  display: block;
  padding-left: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .grid--logos .grid__row > div {
    width: 17.77777778vw;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}

.grid--stats {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -4.22222222vw;
  justify-content: space-between;
  position: relative;
  margin-top: 47px;
}

.grid--stats > div {
  padding-left: 4.22222222vw;
  width: 50%;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .grid--stats {
    margin-top: 4.16666667vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -76px;
    justify-content: space-between;
  }

  .grid--stats > div {
    padding-left: 76px;
    width: 50%;
    margin-bottom: 5.44444444vw;
  }
}

@media (min-width: 1800px) {
  .grid--stats {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -76px;
    justify-content: space-between;
    margin-top: 75px;
  }

  .grid--stats > div {
    padding-left: 76px;
    width: 50%;
    margin-bottom: 98px;
  }
}

@media (min-width: 768px) {
  .grid--stats > div > p {
    max-width: 57.3089701%;
  }
}

@media (min-width: 768px) {
  .grid--stats > div:nth-child(2n + 2) > p {
    margin-left: auto;
  }
}

.grid--work {
  justify-content: space-between;
  margin-top: 27px;
  margin-bottom: -30px;
}

@media (min-width: 768px) {
  .grid--work {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -5.33333333vw;
    margin-bottom: -3.88888889vw;
    margin-top: 6.95443645%;
  }

  .grid--work > div {
    padding-left: 5.33333333vw;
    width: 50%;
    margin-bottom: 3.88888889vw;
  }
}

@media (min-width: 1800px) {
  .grid--work {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -96px;
    margin-bottom: -70px;
  }

  .grid--work > div {
    padding-left: 96px;
    width: 50%;
    margin-bottom: 70px;
  }
}

.grid--work > div {
  width: auto;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .grid--work > div {
    width: 100% !important;
    margin-top: 0 !important;
  }
}

@media (min-width: 768px) {
  .grid--work > div {
    margin-bottom: 3.88888889vw;
  }
}

@media (min-width: 1800px) {
  .grid--work > div {
    margin-bottom: 70px;
  }
}

.grid--work > div.v-align--top {
  align-self: flex-start;
}

.grid--work > div.v-align--center {
  align-self: center;
}

.grid--work > div.v-align--bottom {
  align-self: flex-end;
}

@media (min-width: 768px) {
  .home .grid--work {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -5.33333333vw;
  }

  .home .grid--work > div {
    padding-left: 5.33333333vw;
    width: 50%;
    margin-bottom: 34px;
  }
}

@media (min-width: 1800px) {
  .home .grid--work {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -96px;
  }

  .home .grid--work > div {
    padding-left: 96px;
    width: 50%;
    margin-bottom: 34px;
  }
}

.grid--logo-showcase {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  align-items: center;
  row-gap: 15px;
  justify-content: center;
  column-gap: 15px;
}

.grid--logo-showcase .grid__logo .grid__logo-img {
  position: relative;
  margin: 0 auto;
  display: block;
  max-width: 200px;
  width: 100%;
}

.grid--logo-showcase .grid__logo .grid__logo-img:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 60%;
}

.grid--logo-showcase .grid__logo .grid__logo-img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: scale-down;
}

.grid--face-cols {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.grid--face-cols > div {
  width: 100%;
}

@media (min-width: 550px) {
  .grid--face-cols > div {
    width: 50%;
  }
}

@media (min-width: 825px) {
  .grid--face-cols > div {
    width: 33.33%;
  }
}

@media (min-width: 1100px) {
  .grid--face-cols > div {
    width: 25%;
  }
}

@supports (display: grid) {
  .grid--face-cols {
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr;
  }

@media (min-width: 550px) {
    .grid--face-cols {
      grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 1200px) {
    .grid--face-cols {
      grid-template-columns: 1fr 1fr 1fr auto;
    }
}

  .grid--face-cols > div {
    width: auto;
  }
}

.block--face-col {
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .block--face-col {
    font-size: 2rem;
  }
}

@media (min-width: 1200px) {
  .block--face-col {
    max-width: 296px;
  }
}

.block--face-col .face-col__title {
  font-weight: 600;
  font-size: 1.25em;
  letter-spacing: 0;
  line-height: 1.2;
  margin-bottom: 1.72em;
}

.section--showcase-faces {
  margin-bottom: 8.125vw;
  margin-top: 22.75vw;
  color: var(--inactive-color, #222222);
}

@media (min-width: 1600px) {
  .section--showcase-faces {
    margin-top: 364px;
    margin-bottom: 130px;
  }
}

.section--showcase-faces .container {
  max-width: 1620px;
}

.section--showcase-faces .section--faces {
  padding: 4.4375vw 0 6.875vw;
}

@media (min-width: 1600px) {
  .section--showcase-faces .section--faces {
    padding: 71px 0 110px;
  }
}

.section--showcase-faces .section__content {
  max-width: 697px;
  margin: 0 auto;
}

.section--showcase-faces .section__content .section__title {
  font-weight: 400;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.46666667;
  font-size: 2.1rem;
  transition: ease-in-out 0.2s color;
}

@media (min-width: 768px) {
  .section--showcase-faces .section__content .section__title {
    font-size: 3rem;
  }
}

.landing-cta-get-started {
  font-size: 2rem;
  height: 1em;
  width: 5.38888889em;
  display: block;
  margin-top: 0.19444444em;
}

@media (min-width: 768px) {
  .landing-cta-get-started {
    font-size: 3.6rem;
    margin-right: 0.5em;
  }
}

.header-main--white .landing-cta-get-started .text {
  fill: #ffffff;
}

.svg-footer-start-a-project {
  position: absolute;
  bottom: calc(100% - 0.5em);
  right: -15px;
  font-size: 6.5rem;
  width: 2.64814815em;
  height: 1em;
  display: block;
}

@media (min-width: 768px) {
  .svg-footer-start-a-project {
    font-size: 6vw;
    top: 0.41666667em;
    left: calc(100% + 0.21296296em);
    right: auto;
    bottom: auto;
  }
}

@media (min-width: 1800px) {
  .svg-footer-start-a-project {
    font-size: 10.8rem;
  }
}

.header-aside,
.header-aside-trigger {
  position: fixed;
  top: 0;
  right: 0;
  padding: 30px;
  cursor: pointer;
  /* display: none; */
}

@media (min-width: 768px) {
  .header-aside,
  .header-aside-trigger {
    display: block;
  }
}

.header-aside {
  /* z-index: 10;
  transform: translateX(100%);
  transition: transform 0.2s ease-in-out; */
}

@media (prefers-reduced-motion) {
  .header-aside {
    /* transition: none; */
  }
}

.main-header--scrolling:not(.main-header--peek) .header-aside {
  /* transform: translateX(0); */
}

.header-aside svg {
  font-size: 6rem;
  width: 1em;
  height: 0.99280576em;
  display: block;
}

.header-aside-trigger {
  /* display: none; */
  z-index: 100;
  width: 120px;
  height: 120px;
}

.section--awards-list {
  background: #212022;
  color: #fff;
}

.list--awards {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.72727273em;
}

.list--awards h3 {
  margin: 0;
  position: absolute;
  font-size: 1em;
  top: 1em;
  left: 0;
  width: 30%;
  padding-left: 15px;
}

@media (min-width: 768px) {
  .list--awards h3 {
    width: 200px;
    padding-left: 0;
  }
}

.list--awards li {
  position: relative;
  margin-bottom: 0;
}

.list--awards ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list--awards .award-link {
  font-weight: 600;
  position: relative;
}

.list--awards .award-link:before {
  content: "";
  width: 0.6875em;
  height: 0.6875em;
  display: block;
  position: absolute;
  top: 0;
  left: -10px;
}

.list--awards a.award-link[target="_blank"]:before {
  /* content: url(/wp-content/themes/humaan5/dist/images/external-link.svg?f58a974ec7f1f2ee66de196ea2612247); */
}

.list--awards a.award-link:hover,
.list--awards a.award-link:focus {
  color: #99daf2;
}

.list--awards .award-row {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1em 0;
  transition: background ease-in-out 0.2s;
  margin-left: -15px;
}

@media (min-width: 768px) {
  .list--awards .award-row {
    display: flex;
  }
}

.list--awards .award-row a {
  color: #fff;
  text-decoration: none;
}

.list--awards .award-row:hover {
  background: rgba(255, 255, 255, 0.03);
}

.list--awards .award-row > * {
  width: 70%;
  padding-left: 15px;
  margin-left: auto;
  display: block;
}

@media (min-width: 768px) {
  .list--awards .award-row > * {
    width: calc(30% - (150px / 3));
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .list--awards .award-row > * {
    width: calc(30% - (200px / 3));
  }
}

@media (min-width: 768px) {
  .list--awards .award-row > *:first-child {
    margin-left: 150px;
    width: calc(40% - (150px / 3));
  }
}

@media (min-width: 992px) {
  .list--awards .award-row > *:first-child {
    margin-left: 200px;
    width: calc(40% - (200px / 3));
  }
}

.list--horizontal {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  position: relative;
  scrollbar-width: none;
  white-space: nowrap;
  max-width: 100vw;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.list--horizontal::-webkit-scrollbar {
  display: none;
}

.list--horizontal::-webkit-scrollbar-track,
.list--horizontal::-webkit-scrollbar,
.list--horizontal::-webkit-scrollbar-thumb {
  background-color: transparent;
}



.logo a {
  display: block;
  position: relative;
  /* transition: all 0.9s ease; */
}

.logo a:before {
  /* content: ""; */
  display: block;
  width: 100%;
  padding-top: 12.8%;
  /* transition: all 0.9s ease; */
}

.logo svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* transition: all 0.9s ease; */
  height: 100%;
}

body:not(.main-header--scrolling) .header-main--white .logo svg path {
  fill: #ffffff;
}

.section--logos .marquee {
  margin-right: -33.33vw;
}

@media (min-width: 768px) {
  .section--logos .marquee {
    margin-right: -17.77777778vw;
  }
}

.section--logos .marquee .marquee__inner {
  width: calc(100vw + 33.33vw);
}

@media (min-width: 768px) {
  .section--logos .marquee .marquee__inner {
    width: calc(100vw + 17.77777778vw);
  }
}

@media (prefers-reduced-motion: reduce) {
  .section--logos .marquee:nth-child(even) .marquee__inner {
    justify-content: flex-end;
  }
}

.section--logos .marquee:nth-child(odd) {
  margin-left: -33.33vw;
}

@media (min-width: 768px) {
  .section--logos .marquee:nth-child(odd) {
    margin-left: -17.77777778vw;
  }
}

/* .nav--primary {
  line-height: 1.41666667em;
  font-weight: 400;
  transition: all 0.9s ease;
} */
/* 
.header-main .nav--primary {
  font-size: 1.33333333em;
  flex-grow: 1;
  transition: all 0.9s ease;
} */

/* @media (min-width: 768px) {
  .header-main .nav--primary {
    font-size: 0.77272727em;
    flex-grow: 0;
  }
}

@media (min-width: 768px) {
  .nav--primary ul {
    display: flex;
    align-items: center;
  }
} */

/* .nav--primary ul li {
  margin-right: 2.38888889vw;
  display: block;
  margin-bottom: 0.66666667em;
  transition: ease-in-out 0.3s opacity, ease-in-out 0.3s transform;
}

@media (min-width: 768px) {
  .nav--primary ul li {
    margin-bottom: 0;
  }
}

@media (min-width: 1800px) {
  .nav--primary ul li {
    margin-right: 43px;
  }
}

.nav--primary ul li:last-child {
  margin-right: 0;
} */

@media (max-width: 767px) {
  body:not(.js-nav-open) .header-main .nav--primary ul li {
    opacity: 0;
    /* transform: translateX(-15px); */
    /* transition-delay: 0.5s !important; */
  }
}

@media (max-width: 767px) and (prefers-reduced-motion) {
  body:not(.js-nav-open) .header-main .nav--primary ul li {
    transform: none;
  }
}

.nav--primary ul li a {
  color: #464b50;
  text-decoration: none;
  /* transition: ease-in-out 0.2s opacity; */
}

@media (min-width: 768px) {
  .nav--primary ul li a {
    color: #464b50;
  }
}

.header-main .nav--primary ul li a {
  color: #fff;
}

@media (min-width: 768px) {
  .header-main .nav--primary ul li a {
    color: #464b50;
  }
}

body:not(.main-header--scrolling) .header-main--white .nav--primary ul li a {
  color: #ffffff;
}

.nav--primary ul li a:hover,
.nav--primary ul li a:focus,
.nav--primary ul li a.on-press {
  /* opacity: 0.5; */
}

.nav--primary ul li.current-menu-item > a,
.nav--primary ul li.current-menu-parent > a,
.nav--primary ul li.current-page-ancestor > a {
  /* font-weight: 600; */
}

@media (min-width: 768px) {
  .nav--primary ul li.current-menu-item > a,
  .nav--primary ul li.current-menu-parent > a,
  .nav--primary ul li.current-page-ancestor > a {
    /* color: #1f2123; */
  }
}

@media (min-width: 768px) {
  body:not(.main-header--scrolling) .header-main--white .nav--primary ul li.current-menu-item > a,
  body:not(.main-header--scrolling) .header-main--white
  .nav--primary ul li.current-menu-parent > a,
  body:not(.main-header--scrolling) .header-main--white
  .nav--primary ul li.current-page-ancestor > a {
    color: #ffffff;
  }
}

@media (max-width: 767px) {
  .nav--primary li:nth-child(1) {
    /* transition-delay: 0.15s !important; */
  }
}

@media (max-width: 767px) {
  .nav--primary li:nth-child(2) {
    /* transition-delay: 0.3s !important; */
  }
}

@media (max-width: 767px) {
  .nav--primary li:nth-child(3) {
    /* transition-delay: 0.45s !important; */
  }
}

@media (max-width: 767px) {
  .nav--primary li:nth-child(4) {
    /* transition-delay: 0.6s !important; */
  }
}

@media (max-width: 767px) {
  .nav--primary li:nth-child(5) {
    /* transition-delay: 0.75s !important; */
  }
}

@media (max-width: 767px) {
  .nav--primary li:nth-child(6) {
    /* transition-delay: 0.9s !important; */
  }
}

@media (max-width: 767px) {
  .nav--primary li:nth-child(7) {
    /* transition-delay: 1.05s !important; */
  }
}

@media (max-width: 767px) {
  .nav--primary li:nth-child(8) {
    /* transition-delay: 1.2s !important; */
  }
}

@media (max-width: 767px) {
  .nav--primary li:nth-child(9) {
    /* transition-delay: 1.35s !important; */
  }
}

@media (max-width: 767px) {
  .nav--primary li:nth-child(10) {
    /* transition-delay: 1.5s !important; */
  }
}

.nav--quicklinks {
  line-height: 1.41666667em;
  font-weight: 400;
  padding: 0 0 20px;
}

.nav--quicklinks ul {
  display: flex;
  justify-content: space-between;
  font-size: 0.88888889em;
  width: 90%;
  margin: 0 auto;
  max-width: 375px;
}

.nav--quicklinks ul li {
  margin-right: 2.38888889vw;
  display: block;
  margin-bottom: 0.66666667em;
  /* transition: ease-in-out 0.3s opacity, ease-in-out 0.3s transform; */
  flex-grow: 1;
  text-align: center;
  margin: 0;
}

.nav--quicklinks ul li a {
  color: #464b50;
  text-decoration: none;
  transition: ease-in-out 0.2s opacity;
  padding: 10px 2px;
  display: block;
}

.nav--quicklinks ul li a:hover,
.nav--quicklinks ul li a:focus,
.nav--quicklinks ul li a.on-press {
  opacity: 0.5;
}

.nav--work {
  position: relative;
  margin: 0 -30px;
  margin-top: 21px;
  font-size: 0.83333333em;
}

@media (min-width: 768px) {
  .nav--work {
    font-size: 1em;
  }
}

@media (min-width: 1200px) {
  .nav--work {
    font-size: 1.09090909em;
  }
}

.nav--work::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 20rem;
  background: transparent;
  background: linear-gradient(270deg, white 0%, rgba(255, 255, 255, 0) 50%);
  pointer-events: none;
}

.nav--work ul {
  padding: 0 30px;
  margin: 0;
}

.nav--work ul li + li {
  margin-left: 30px;
}

@media (min-width: 768px) {
  .nav--work ul li + li {
    margin-left: 5.078125%;
  }
}

.nav--work ul li button,
.nav--work ul li a {
  padding: 10px 0;
  font-weight: 400;
  transition: ease-in-out 0.2s opacity;
  text-decoration: none;
  color: #464b50;
}

.nav--work ul li button:hover,
.nav--work ul li button:focus,
.nav--work ul li button.on-press,
.nav--work ul li a:hover,
.nav--work ul li a:focus,
.nav--work ul li a.on-press {
  opacity: 0.5;
}

.nav--work ul li.current-menu-item a {
  font-weight: 600;
  color: #1f2123;
}

.nav--work ul li:last-child {
  padding-right: 30px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0 0;
}

.pagination .pagination__button {
  display: block;
  cursor: pointer;
}

.pagination .pagination__button svg {
  transition: ease-in-out 0.2s transform;
  display: block;
}

.pagination .pagination__button svg path {
  transition: ease-in-out 0.2s fill;
  fill: #222222;
}

.pagination .pagination__button--next:hover svg,
.pagination .pagination__button--next:focus svg {
  /* transform: translateX(6px); */
}

@media (prefers-reduced-motion) {
  .pagination .pagination__button--next:hover svg,
  .pagination .pagination__button--next:focus svg {
    transform: none;
  }
}

.pagination .pagination__button--prev:hover svg,
.pagination .pagination__button--prev:focus svg {
  /* transform: translateX(-6px); */
}

@media (prefers-reduced-motion) {
  .pagination .pagination__button--prev:hover svg,
  .pagination .pagination__button--prev:focus svg {
    transform: none;
  }
}

.pagination .pagination__button:hover svg path,
.pagination .pagination__button:focus svg path {
  fill: #99daf2;
}

.pagination .pagination__list {
  list-style: none;
  margin: 0;
  padding: 0 5px;
  flex-grow: 1;
  text-align: center;
  display: flex;
  justify-content: space-around;
}

@media (min-width: 768px) {
  .pagination .pagination__list {
    padding: 0 15px;
    display: block;
    flex-grow: 0;
  }
}

.pagination .pagination__list .pagination__list-item {
  display: inline-block;
  margin: 0;
}

.pagination .pagination__list .pagination__list-item a,
.pagination .pagination__list .pagination__list-item span {
  padding: 10px 5px;
  display: block;
  text-decoration: none;
}

@media (min-width: 768px) {
  .pagination .pagination__list .pagination__list-item a,
  .pagination .pagination__list .pagination__list-item span {
    padding: 10px 15px;
  }
}

.pagination .pagination__list .pagination__list-item a {
  color: rgba(34, 34, 34, 0.5);
}

.pagination .pagination__list .pagination__list-item a:hover,
.pagination .pagination__list .pagination__list-item a:focus {
  color: #222222;
}

.parallaax {
  overflow: hidden;
}

.parallaax .parallaax__item {
  transform-origin: bottom center;
}

.planner-wrapper {
  position: relative;
  padding-top: 73px;
  font-weight: 400;
}

@media (min-height: 1023px) {
  .planner-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 768px) {
  .planner-wrapper {
    padding-top: 184px;
    padding-bottom: 184px;
  }
}

.planner-wrapper .planner-wrapper-inner {
  position: relative;
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .planner-wrapper .form--planner {
    min-height: 680px;
    display: flex;
    flex-direction: column;
  }
}

.planner-wrapper .form--planner .planner-steps-wrap {
  flex-grow: 1;
}

.planner-contact {
  position: relative;
  text-align: center;
  font-size: 0.72727273em;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .planner-contact {
    text-align: right;
  }
}

.planner-contact p {
  margin: 0 0 20px;
}

.planner-contact a {
  color: #2e2e2e;
  padding: 0 20px;
  text-decoration: none;
}

@media (min-width: 768px) {
  .planner-contact a {
    padding: 0 15px;
  }
}

.planner-contact a:hover,
.planner-contact a:focus,
.planner-contact a.on-press {
  color: #633a86;
}

.planner-contact a + a {
  margin: 8px 0 0;
}

@media (min-width: 768px) {
  .planner-contact a + a {
    margin: 0;
  }
}

.planner-close {
  position: fixed;
  top: 85px;
  right: 25px;
  z-index: 150;
  color: #2e2e2e;
  padding: 0 25px 0 0;
  z-index: 10;
  font-size: 0.72727273em;
  text-decoration: none;
}

@media (min-width: 768px) {
  .planner-close {
    position: absolute;
    top: 25px;
  }
}

.planner-close:hover,
.planner-close:focus,
.planner-close.on-press {
  /* color: #633a86; */
}

.planner-close:hover .planner-close-icon:after,
.planner-close:hover .planner-close-icon:before,
.planner-close:focus .planner-close-icon:after,
.planner-close:focus .planner-close-icon:before,
.planner-close.on-press .planner-close-icon:after,
.planner-close.on-press .planner-close-icon:before {
  /* background: #633a86; */
}

.planner-close:hover .planner-close-icon:after,
.planner-close:focus .planner-close-icon:after,
.planner-close.on-press .planner-close-icon:after {
  /* top: -2px; */
  height: 18px;
}

@media (prefers-reduced-motion: reduce) {
  .planner-close:hover .planner-close-icon:after,
  .planner-close:focus .planner-close-icon:after,
  .planner-close.on-press .planner-close-icon:after {
    /* top: 0; */
    /* height: 14px; */
  }
}

.planner-close:hover .planner-close-icon:before,
.planner-close:focus .planner-close-icon:before,
.planner-close.on-press .planner-close-icon:before {
  left: 0px;
  width: 18px;
}

@media (prefers-reduced-motion: reduce) {
  .planner-close:hover .planner-close-icon:before,
  .planner-close:focus .planner-close-icon:before,
  .planner-close.on-press .planner-close-icon:before {
    left: 2px;
    width: 14px;
  }
}

.planner-close-icon {
  position: absolute;
  top: 4px;
  right: 0;
  display: block;
  width: 15px;
  height: 15px;
  /* transform: rotate(-45deg); */
  /* transform-origin: 50% 50%; */
}

.planner-close-icon:after,
.planner-close-icon:before {
  content: "";
  position: absolute;
  display: block;
  background: #2e2e2e;
  /* transition: all 0.2s ease-in-out; */
}

.planner-close-icon:after {
  top: 0;
  left: 8px;
  width: 2px;
  height: 14px;
}

.planner-close-icon:before {
  top: 6px;
  left: 2px;
  width: 14px;
  height: 2px;
}

.planner-indicators {
  position: absolute;
  left: -1px;
  margin: 0;
  padding: 0;
  list-style: none;
  top: 15px;
  display: flex;
  left: 0;
  width: 100%;
}

.planner-indicators li {
  opacity: 1;
  height: 3px;
  margin: 0 0 0 1px;
  background: #d9d9d9;
  transition: background 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  flex-grow: 1;
}

@media (min-width: 768px) {
  .planner-indicators li {
    max-width: 80px;
  }
}

.planner-indicators li.is-active {
  background: #633a86;
}

.planner-step {
  display: none;
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 48px;
  transition: transform cubic-bezier(0.86, 0, 0.07, 1) 1s;
}

.planner-step.is-active {
  display: block;
  z-index: 10;
}

.step-instructions {
  max-width: 700px;
  margin: 0 0 2.5em;
  font-size: 0.90909091em;
}

.step-instructions br {
  display: none;
}

@media (min-width: 768px) {
  .step-instructions br {
    display: block;
  }
}

.step-controls .trigger-analogy,
.step-controls .trigger-dollar {
  margin: 50px 0;
  display: block;
  text-align: center;
}

@media (min-width: 768px) {
  .step-controls .trigger-analogy,
  .step-controls .trigger-dollar {
    display: inline-block;
  }
}

.step-controls .planner-step__title {
  color: #231f20;
}

.form-field {
  margin: 0 0 25px 0;
}

@media (min-width: 768px) {
  .form-field {
    margin: 0 0 42px 0;
  }
}

.form-field label {
  display: block;
  color: #4d4d4d;
  line-height: 1;
  margin: 0 0 2px;
  position: relative;
  font-size: 0.70909091em;
  z-index: 1;
  font-weight: 500;
  font-family: Helveticaneue,sans-serif;
}

.form-field label span {
  color: #adadad;
}

.form-field .form-control {
  width: 100%;
  border: 2px solid #c7c7c7;
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
  color: #633a86;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  padding: 0.6em 0.6em;
  -webkit-appearance: none;
  border-radius: 0;
  font-family: Helveticaneue,sans-serif;
}

@media (min-width: 768px) {
  .form-field .form-control {
    font-size: 1.36363636em;
  }
}

.form-field .form-control[type="text"],
.form-field .form-control[type="email"],
.form-field .form-control[type="tel"] {
  border-width: 0 0 2px 0;
  padding: 0.6em 0;
}

.form-field .form-control.full-field {
  border-width: 2px;
  border-radius: 3px;
}

.form-field .form-control.error {
  border-color: #d03838;
}

.form-field .form-control[type="tel"] {
  letter-spacing: 0.06em;
}

.form-field .form-control[type="tel"]::placeholder {
  letter-spacing: 0;
}

.form-field .form-control::placeholder {
  color: #bbb;
  transition: color 0.2s ease-in-out;
}

.form-field .form-control:focus {
  border-color: #633a86;
  outline: none;
}

.form-field .form-control:focus::placeholder {
  color: #ccc;
}

.form-field .form-control::-ms-clear {
  display: none;
}

.form-field textarea {
  margin: 20px 0 0;
  width: 100%;
  border: 2px solid #c7c7c7;
  border-radius: 3px;
  color: #633a86;
  resize: none;
  padding: 13px 20px;
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
  font-size: 0.88888889em;
  height: 100px;
}

@media (min-width: 768px) {
  .form-field textarea {
    font-size: 1.18181818em;
    height: 180px;
  }
}

.form-field textarea.error {
  border-color: maroon;
}

.form-field textarea::placeholder {
  color: #bbb;
  transition: color 0.2s ease-in-out;
}

.form-field textarea:focus {
  border-color: #633a86;
}

.form-field textarea:focus::placeholder {
  color: #ccc;
}

.form-field .form-field-col {
  margin: 0 0 25px;
}

@media (min-width: 768px) {
  .form-field .form-field-col {
    float: left;
    width: 50%;
    margin: 0 0 45px;
  }
}

.form-field .form-field-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -30px;
  margin-bottom: -30px;
}

.form-field .form-field-row > .form-field-col {
  padding-left: 30px;
  width: 50%;
  margin-bottom: 30px;
}

.form-field .form-field-row > .form-field-col {
  width: 100%;
}

@media (min-width: 768px) {
  .form-field .form-field-row > .form-field-col--half {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .form-field .form-field-row > .form-field-col--third {
    width: 33.33%;
  }
}

.custom-upload {
  position: relative;
  cursor: pointer;
  max-width: 555px;
  margin: 20px 0 0;
}

.custom-upload .inp-file {
  position: absolute;
  background: transparent;
  opacity: 0;
  z-index: 2;
}

.custom-upload .inp-file:focus + .inp-fake-file input {
  color: #ccc;
  background-color: #ffffff;
}

.custom-upload .inp-file input {
  border-color: transparent;
  font-size: 2rem;
}

.custom-upload .inp-fake-file {
  position: relative;
  z-index: 1;
}

.custom-upload .inp-fake-file:after {
  display: block;
  position: absolute;
  top: 23px;
  left: 27px;
  content: "\E00B";
  /* font-family: "Icons"; */
  line-height: 1;
  color: #633a86;
  font-size: 2.2rem;
}

.custom-upload .inp-fake-file input {
  padding-left: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #ededed;
  color: #818181;
  font-size: 2rem;
}

.custom-upload .inp-fake-file input::placeholder {
  color: #818181;
  transition: color 0.2s ease-in-out;
}

.custom-upload .inp-fake-file input:focus {
  border-color: #633a86;
}

.custom-upload .inp-fake-file input:focus::placeholder {
  color: #bbb;
}

.custom-upload .inp-fake-file input:disabled {
  opacity: 1;
}

.planner-step__title {
  font-weight: 600;
  color: #231f20;
}

.contextual-response {
  display: none;
}

.planner-spinner {
  display: none;
  z-index: 100;
  top: 50%;
  left: 50%;
  border: 3px solid #633a86;
  border-right-color: #ebebeb;
  border-left-color: #ebebeb;
  border-radius: 50%;
  animation: rotate 0.8s infinite linear;
  position: fixed;
  margin: -40px 0 0 -40px;
  height: 80px;
  width: 80px;
}

@media (prefers-reduced-motion: reduce) {
  .planner-spinner {
    animation: rotate steps(8) 5s infinite;
  }
}

.planner-thankyou-wrap {
  display: none;
  width: 100%;
}

.planner-thankyou-wrap-inner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.planner-thankyou-content h2 {
  margin: 0 0 40px;
  font-size: 1.11111111em;
}

@media (min-width: 768px) {
  .planner-thankyou-content h2 {
    font-size: 1.45454545em;
  }
}

.planner-thankyou-content h2 em {
  display: block;
  line-height: 1.1;
  font-style: normal;
  font-size: 1.94444444em;
  margin: 0 0 8px;
}

@media (min-width: 768px) {
  .planner-thankyou-content h2 em {
    font-size: 3.63636364em;
    margin: 0 0 5px;
  }
}

.planner-thankyou-content p {
  width: 100%;
  max-width: 550px;
}

.planner-thankyou-content .return-btn {
  border-radius: 2px;
  height: 50px;
  position: relative;
  display: inline-block;
  line-height: 49px;
  padding: 0 25px 0 120px;
  margin: 40px 0 0 -28px;
  text-align: right;
  background: transparent;
  color: #222222;
  /* transition: color 0.2s ease-in-out; */
  font-size: 0.72727273em;
  text-decoration: none;
}

.planner-thankyou-content .return-btn:before,
.planner-thankyou-content .return-btn:after {
  display: block;
  content: " ";
  position: absolute;
}

.planner-thankyou-content .return-btn:before {
  top: 50%;
  left: 5px;
  width: 56px;
  height: 2px;
  margin: -1px 0 0;
  background: #ccc;
  transition: left 0.2s linear, width 0.2s linear, background 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .planner-thankyou-content .return-btn:before {
    transition: left 0s linear, width 0s linear, background 0.2s linear;
  }
}

.planner-thankyou-content .return-btn:after {
  left: 0;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(208, 208, 208, 0);
  border-right-color: #ccc;
  border-width: 6px;
  margin: -6px 0 0 -7px;
  /* transition: left 0.2s linear, border-color 0.2s linear; */
}

@media (prefers-reduced-motion: reduce) {
  .planner-thankyou-content .return-btn:after {
    /* transition: left 0s linear, border-color 0.2s linear; */
  }
}

.planner-thankyou-content .return-btn:hover,
.planner-thankyou-content .return-btn:focus,
.planner-thankyou-content .return-btn.on-press {
  color: #2e2e2e;
}

.planner-thankyou-content .return-btn:hover:before,
.planner-thankyou-content .return-btn:focus:before,
.planner-thankyou-content .return-btn.on-press:before {
  right: 0;
  width: 66px;
  background-color: #2e2e2e;
}

.planner-thankyou-content .return-btn:hover:after,
.planner-thankyou-content .return-btn:focus:after,
.planner-thankyou-content .return-btn.on-press:after {
  right: -5px;
  border-right-color: #2e2e2e;
}

.planner-thankyou-content .return-btn:after {
  left: 30px;
}

.planner-thankyou-content .return-btn:before {
  left: 35px;
}

.planner-thankyou-content .return-btn:hover,
.planner-thankyou-content .return-btn:focus,
.planner-thankyou-content .return-btn.on-press {
  color: #2e2e2e;
}

.planner-thankyou-content .return-btn:hover:after,
.planner-thankyou-content .return-btn:focus:after,
.planner-thankyou-content .return-btn.on-press:after {
  left: 25px;
}

.planner-thankyou-content .return-btn:hover:before,
.planner-thankyou-content .return-btn:focus:before,
.planner-thankyou-content .return-btn.on-press:before {
  left: 30px;
}

.planner-controls {
  display: block;
  bottom: 0;
  margin: 0 0 30px;
  z-index: 20;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 1200px) {
  .planner-controls {
    position: relative;
  }
}

.planner-controls button {
  background: transparent;
  border-radius: 2px;
  padding: 0;
  height: 50px;
  position: relative;
  text-align: left;
  color: #ffffff;
}

.planner-controls .step-back {
  width: 120px;
  transition: background 0.2s linear;
  font-size: 0.72727273em;
}

.planner-controls .step-back:before,
.planner-controls .step-back:after {
  display: block;
  content: " ";
  position: absolute;
}

.planner-controls .step-back:before {
  top: 50%;
  left: 5px;
  width: 56px;
  height: 2px;
  margin: -1px 0 0;
  background: #ccc;
  /* transition: left 0.2s linear, width 0.2s linear, background 0.2s linear; */
}

@media (prefers-reduced-motion: reduce) {
  .planner-controls .step-back:before {
    transition: left 0s linear, width 0s linear, background 0.2s linear;
  }
}

.planner-controls .step-back:after {
  left: 0;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(208, 208, 208, 0);
  border-right-color: #ccc;
  border-width: 6px;
  margin: -6px 0 0 -7px;
  /* transition: left 0.2s linear, border-color 0.2s linear; */
}

@media (prefers-reduced-motion: reduce) {
  .planner-controls .step-back:after {
    /* transition: left 0s linear, border-color 0.2s linear; */
  }
}

.planner-controls .step-back:hover,
.planner-controls .step-back:focus,
.planner-controls .step-back.on-press {
  color: #2e2e2e;
}

.planner-controls .step-back:hover:before,
.planner-controls .step-back:focus:before,
.planner-controls .step-back.on-press:before {
  right: 0;
  width: 66px;
  background-color: #2e2e2e;
}

.planner-controls .step-back:hover:after,
.planner-controls .step-back:focus:after,
.planner-controls .step-back.on-press:after {
  right: -5px;
  /* border-right-color: #2e2e2e; */
}

@media (min-width: 1200px) {
  .planner-controls .step-back {
    position: absolute;
    top: 0;
    right: calc(100% + 10px);
  }
}

@media (max-width: 1600px) {
  .planner-controls .step-back {
    margin: 0 10px 0 0;
  }
}

@media (max-width: 400px) {
  .planner-controls .step-back {
    width: 82px;
    margin: 0;
  }
}

.planner-controls .step-back:after {
  left: 30px;
}

@media (max-width: 400px) {
  .planner-controls .step-back:after {
    left: 23px;
  }
}

.planner-controls .step-back:before {
  left: 35px;
}

@media (max-width: 400px) {
  .planner-controls .step-back:before {
    left: 25px;
    width: 35px;
  }
}

.planner-controls .step-back:hover,
.planner-controls .step-back:focus,
.planner-controls .step-back.on-press {
  background: #e7e7e7;
}

@media (max-width: 400px) {
  .planner-controls .step-back:hover,
  .planner-controls .step-back:focus,
  .planner-controls .step-back.on-press {
    background: #e7e7e7;
  }
}

.planner-controls .step-back:hover:after,
.planner-controls .step-back:focus:after,
.planner-controls .step-back.on-press:after {
  left: 25px;
}

@media (max-width: 400px) {
  .planner-controls .step-back:hover:after,
  .planner-controls .step-back:focus:after,
  .planner-controls .step-back.on-press:after {
    left: 20px;
  }
}

.planner-controls .step-back:hover:before,
.planner-controls .step-back:focus:before,
.planner-controls .step-back.on-press:before {
  left: 30px;
}

@media (max-width: 400px) {
  .planner-controls .step-back:hover:before,
  .planner-controls .step-back:focus:before,
  .planner-controls .step-back.on-press:before {
    left: 22px;
    width: 40px;
  }
}

.planner-controls .step-forward {
  padding: 0 20px;
  background: #633a86;
  transition: background 0.2s ease-in-out;
  padding-right: 109px;
  align-self: flex-end;
  margin-left: auto;
  font-size: 0.72727273em;
}

.planner-controls .step-forward:before,
.planner-controls .step-forward:after {
  display: block;
  content: " ";
  position: absolute;
}

.planner-controls .step-forward:before {
  top: 50%;
  right: 5px;
  width: 56px;
  height: 2px;
  margin: -1px 0 0;
  background: #ffffff;
  transition: right 0.2s linear, width 0.2s linear, background 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .planner-controls .step-forward:before {
    transition: right 0s linear, width 0s linear, background 0.2s linear;
  }
}

.planner-controls .step-forward:after {
  right: 0;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(208, 208, 208, 0);
  border-left-color: #ffffff;
  border-width: 6px;
  margin: -6px -7px 0 0;
  /* transition: right 0.2s linear, border-color 0.2s linear; */
}

@media (prefers-reduced-motion: reduce) {
  .planner-controls .step-forward:after {
    /* transition: right 0s linear, border-color 0.2s linear; */
  }
}

.planner-controls .step-forward:hover,
.planner-controls .step-forward:focus,
.planner-controls .step-forward.on-press {
  color: #ffffff;
}

.planner-controls .step-forward:hover:before,
.planner-controls .step-forward:focus:before,
.planner-controls .step-forward.on-press:before {
  right: 0;
  width: 66px;
  background-color: #ffffff;
}

.planner-controls .step-forward:hover:after,
.planner-controls .step-forward:focus:after,
.planner-controls .step-forward.on-press:after {
  right: -5px;
  border-left-color: #ffffff;
}

@media (min-width: 1200px) {
  .planner-controls .step-forward {
    float: none;
    padding: 0 25px;
    padding-right: 109px;
    margin-left: 0;
  }
}

.planner-controls .step-forward:after {
  right: 30px;
}

.planner-controls .step-forward:before {
  right: 35px;
}

.planner-controls .step-forward:hover:after,
.planner-controls .step-forward:focus:after,
.planner-controls .step-forward.on-press:after {
  right: 25px;
}

.planner-controls .step-forward:hover:before,
.planner-controls .step-forward:focus:before,
.planner-controls .step-forward.on-press:before {
  right: 30px;
}

.planner-controls .step-forward:focus:after {
  right: 25px;
}

.planner-controls .step-forward:focus:before {
  right: 30px;
}

.planner-controls .step-forward[disabled] {
  background: #ccc;
  cursor: pointer;
}

.planner-controls .step-forward.has-errors {
  background: #ccc;
}

.planner-controls .step-submit {
  background: #633a86;
  transition: background 0.2s ease-in-out;
  padding: 0 20px;
  float: right;
  padding-right: 109px;
  font-size: 0.72727273em;
  margin-left: auto;
}

.planner-controls .step-submit:before,
.planner-controls .step-submit:after {
  display: block;
  content: " ";
  position: absolute;
}

.planner-controls .step-submit:before {
  top: 50%;
  right: 5px;
  width: 56px;
  height: 2px;
  margin: -1px 0 0;
  background: #ffffff;
  /* transition: right 0.2s linear, width 0.2s linear, background 0.2s linear; */
}

@media (prefers-reduced-motion: reduce) {
  .planner-controls .step-submit:before {
    /* transition: right 0s linear, width 0s linear, background 0.2s linear; */
  }
}

.planner-controls .step-submit:after {
  right: 0;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(208, 208, 208, 0);
  border-left-color: #ffffff;
  border-width: 6px;
  margin: -6px -7px 0 0;
  /* transition: right 0.2s linear, border-color 0.2s linear; */
}

@media (prefers-reduced-motion: reduce) {
  .planner-controls .step-submit:after {
    /* transition: right 0s linear, border-color 0.2s linear; */
  }
}

.planner-controls .step-submit:hover,
.planner-controls .step-submit:focus,
.planner-controls .step-submit.on-press {
  color: #ffffff;
}

.planner-controls .step-submit:hover:before,
.planner-controls .step-submit:focus:before,
.planner-controls .step-submit.on-press:before {
  right: 0;
  width: 66px;
  background-color: #ffffff;
}

.planner-controls .step-submit:hover:after,
.planner-controls .step-submit:focus:after,
.planner-controls .step-submit.on-press:after {
  right: -5px;
  border-left-color: #ffffff;
}

@media (min-width: 768px) {
  .planner-controls .step-submit {
    padding: 0 25px;
    padding-right: 109px;
    margin-left: 0;
  }
}

.planner-controls .step-submit:after {
  right: 30px;
}

.planner-controls .step-submit:before {
  right: 35px;
}

.planner-controls .step-submit:hover,
.planner-controls .step-submit:focus,
.planner-controls .step-submit.on-press {
  background: #ffc938;
}

.planner-controls .step-submit:hover:after,
.planner-controls .step-submit:focus:after,
.planner-controls .step-submit.on-press:after {
  right: 25px;
}

.planner-controls .step-submit:hover:before,
.planner-controls .step-submit:focus:before,
.planner-controls .step-submit.on-press:before {
  right: 30px;
}

.planner-controls .step-submit[disabled] {
  background: #ccc;
  cursor: default;
}

.planner-controls .step-submit.has-errors {
  background: #ccc;
}

.error-msg {
  margin: 0 0 0 30px;
  display: none;
  color: #d03838;
  top: auto;
  left: auto;
  order: 4;
  width: 100%;
  padding: 20px 0 0;
  text-align: center;
}

@media (min-width: 1200px) {
  .error-msg {
    width: auto;
    padding: 0;
  }
}

.type-select-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -4px;
  margin-bottom: 26px;
  justify-content: center;
}

.type-select-group > div {
  padding-left: 4px;
  width: 50%;
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  .type-select-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -0;
    justify-content: flex-start;
  }

  .type-select-group > div {
    padding-left: 0;
    width: 20%;
    margin-bottom: 30px;
  }
}

.type-selection {
  position: relative;
}

.type-selection label {
  cursor: pointer;
  display: block;
  width: 100%;
  border-radius: 2px;
  transition: background 0.2s linear, color 0.2s linear;
  text-align: center;
  position: relative;
  height: 100%;
  padding: 30px 30px 13px;
  line-height: 1;
}

@media (min-width: 768px) {
  .type-selection label {
    padding: 43px 30px 30px;
  }
}

.type-selection label:hover,
.type-selection label:focus,
.type-selection label.on-press {
  background-color: #ebebeb;
}

.type-selection input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  margin: 0;
}

.type-selection input:checked + label {
  background-color: #633a86;
  color: #ffffff;
}

.type-selection input:checked + label:hover,
.type-selection input:checked + label:focus,
.type-selection input:checked + label.on-press {
  background-color: #633a86;
  color: #ffffff;
}

.type-selection input:checked + label .checkbox__icon svg path {
  fill: #ffffff;
}

.type-selection input:checked + label .checkbox__check {
  opacity: 1;
}

.type-selection .checkbox__label {
  display: block;
  margin-bottom: 18px;
}

.type-selection .checkbox__check {
  opacity: 0;
  margin: 0 auto;
  font-size: 1.09090909em;
  transition: opacity 0.2s linear;
  display: none;
}

@media (min-width: 768px) {
  .type-selection .checkbox__check {
    display: block;
  }
}

.type-selection .checkbox__check svg {
  width: 1em;
  height: 1em;
}

.type-selection .checkbox__check svg path {
  fill: #ffffff;
}

.type-selection .icon {
  display: flex;
  flex-direction: column;
}

.type-selection .icon .checkbox__icon {
  line-height: 1;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 27px;
}

@media (min-width: 768px) {
  .type-selection .icon .checkbox__icon {
    margin-bottom: 44px;
  }
}

.type-selection .icon .checkbox__icon svg {
  display: block;
}

.type-selection .icon .checkbox__icon svg path {
  fill: #633a86;
  transition: fill 0.2s linear;
}

.type-selection .icon-web .checkbox__icon {
  font-size: 2.77777778em;
}

@media (min-width: 768px) {
  .type-selection .icon-web .checkbox__icon {
    font-size: 3.81818182em;
  }
}

.type-selection .icon-web .checkbox__icon svg {
  height: 1em;
  width: 1.14634em;
}

.type-selection .icon-mobile .checkbox__icon {
  font-size: 3.05555556em;
}

@media (min-width: 768px) {
  .type-selection .icon-mobile .checkbox__icon {
    font-size: 4.27272727em;
  }
}

.type-selection .icon-mobile .checkbox__icon svg {
  height: 1em;
  width: 0.57446667em;
}

.type-selection .icon-ecomm .checkbox__icon {
  font-size: 2.77777778em;
}

@media (min-width: 768px) {
  .type-selection .icon-ecomm .checkbox__icon {
    font-size: 3.90909091em;
  }
}

.type-selection .icon-ecomm .checkbox__icon svg {
  height: 1em;
  width: 1.10905333em;
}

.type-selection .icon-other .checkbox__icon {
  font-size: 2.94444444em;
}

@media (min-width: 768px) {
  .type-selection .icon-other .checkbox__icon {
    font-size: 4.22727273em;
  }
}

.type-selection .icon-other .checkbox__icon svg {
  height: 1em;
  width: 1.01393333em;
}

#planner-files-dropzone {
  margin: 20px 0 0 0;
  position: relative;
}

@media (min-width: 768px) {
  #planner-files-dropzone {
    display: flex;
  }
}

.dropzone-foo {
  font-size: 0.77777778em;
  border: 2px solid #c7c7c7;
  border-radius: 3px;
  transition: border 0.2s linear, color 0.2s linear;
  background-color: #ededed;
  color: #818181;
  margin: 0 0 10px;
  padding: 0.9em 1.5em;
  padding-left: 4.28571429em;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .dropzone-foo {
    font-size: 0.72727273em;
    padding-left: 4.375em;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .dropzone-foo {
    font-size: 0.90909091em;
  }
}

.dropzone-foo .dropzone__icon {
  font-size: 1.1em;
  position: absolute;
  top: calc(50% - 0.5em);
  left: 0.90909091em;
  line-height: 1;
}

@media (min-width: 768px) {
  .dropzone-foo .dropzone__icon {
    left: 1.22727273em;
  }
}

.dropzone-foo .dropzone__icon svg {
  height: 1em;
  width: 1.09892667em;
}

.dropzone-foo .dropzone__icon svg path {
  fill: #633a86;
}

.dropzone-foo .instructions {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone-foo .instructions span {
  display: none;
}

@media (min-width: 768px) {
  .dropzone-foo .instructions span {
    display: inline;
  }
}

.dz-drag-hover .dropzone-foo {
  /* border-color: #633a86; */
  background: #f7f7f7;
}

.total-uploaded {
  padding: 18px 25px;
  padding: 18px 61px 16px 25px;
  border-radius: 3px;
  transition: border 0.2s linear, color 0.2s linear, background 0.2s linear;
  color: #2e2e2e;
  cursor: pointer;
  margin-left: 20px;
  display: none;
}

@media (min-width: 768px) {
  .total-uploaded {
    display: block;
  }
}

.total-uploaded:before,
.total-uploaded:after {
  display: block;
  content: "";
  position: absolute;
}

.total-uploaded:before {
  top: 27px;
  right: 35px;
  width: 2px;
  height: 19px;
  margin: 0;
  background: #633a86;
  transition: margin 0.2s linear, height 0.2s linear, background 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .total-uploaded:before {
    transition: margin 0s linear, height 0s linear, background 0.2s linear;
  }
}

.total-uploaded:after {
  content: "";
  right: 37px;
  top: 22px;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(208, 208, 208, 0);
  /* border-bottom-color: #633a86; */
  border-width: 6px;
  /* margin: -6px -7px 0 0; */
  /* transition: margin 0.2s linear, border-color 0.2s linear; */
}

@media (prefers-reduced-motion: reduce) {
  .total-uploaded:after {
    /* transition: margin 0s linear, border-color 0.2s linear; */
  }
}

.total-uploaded.is-open:before {
  top: 20px;
}

.total-uploaded.is-open:after {
  top: 44px;
  /* transform: rotate(-180deg); */
}

@media (min-width: 768px) {
  .dropzone-previews {
    bottom: 90px;
    right: 0;
    position: absolute;
    width: 50%;
    display: none;
  }
}

@media (max-width: 767px) {
  .dropzone-previews {
    display: block !important;
    opacity: 1 !important;
  }
}

.dropzone-previews .dz-preview {
  position: relative;
  display: block;
  right: 0;
  border: 2px solid #ebebeb;
  background: #ffffff;
  overflow: hidden;
  z-index: 50;
  border-radius: 3px 3px 0 0;
  font-size: 0.63636364em;
  margin: 0;
  box-shadow: none;
  padding: 0;
}

@media (min-width: 768px) {
  .dropzone-previews .dz-preview {
    border-radius: 0 0 3px 3px;
  }
}

.dropzone-previews .dz-preview:last-child {
  border-radius: 0 0 3px 3px;
}

@media (min-width: 768px) {
  .dropzone-previews .dz-preview:last-child {
    border-radius: 3px 3px 0 0;
  }
}

.dropzone-previews .dz-preview .dz-details {
  width: 100%;
  height: auto;
  position: relative;
  background: #ffffff;
  padding: 0;
  margin-bottom: 0;
}

.dropzone-previews .dz-preview .dz-details img {
  display: none !important;
}

.dropzone-previews .dz-preview .dz-filename {
  width: 100%;
  padding: 0 40px 0 0;
  line-height: 46px;
}

.dropzone-previews .dz-preview .dz-filename span {
  display: block;
  padding: 0 0 0 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone-previews .dz-preview .dz-remove {
  position: absolute;
  right: 11px;
  top: 13px;
  display: block;
  width: 20px;
  height: 20px;
  overflow: hidden;
  text-indent: -99999em;
  transform: rotate(-45deg);
  cursor: pointer;
  background: transparent;
  border: 0;
  margin: 0;
}

.dropzone-previews .dz-preview .dz-remove:before,
.dropzone-previews .dz-preview .dz-remove:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 3px;
  background: #d03838;
  /* transition: background 0.2s linear; */
}

.dropzone-previews .dz-preview .dz-remove:before {
  top: 2px;
  left: 9px;
  width: 2px;
  height: 16px;
}

.dropzone-previews .dz-preview .dz-remove:after {
  top: 9px;
  left: 2px;
  width: 16px;
  height: 2px;
}

.dropzone-previews .dz-preview .dz-remove:hover:before,
.dropzone-previews .dz-preview .dz-remove:hover:after,
.dropzone-previews .dz-preview .dz-remove:focus:before,
.dropzone-previews .dz-preview .dz-remove:focus:after,
.dropzone-previews .dz-preview .dz-remove.on-press:before,
.dropzone-previews .dz-preview .dz-remove.on-press:after {
  background: #ffc938;
}

.dropzone-previews .dz-preview + .dz-preview {
  border-radius: 0;
  border-top: 0;
}

@media (min-width: 768px) {
  .dropzone-previews .dz-preview + .dz-preview {
    border-radius: 0;
  }
}

.dz-progress,
.dz-success-mark,
.dz-error-mark,
.dz-image,
.dz-size {
  display: none;
}

.sliders-wrap {
  position: relative;
  margin: 90px 0 0;
  padding: 0 50px;
}

@media (min-width: 768px) {
  .sliders-wrap {
    padding: 0 90px 0 60px;
    margin: 120px 0 0;
  }
}

.sliders-wrap .range-labels {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
  margin: 0 0 12px;
  text-align: center;
  font-size: 1.11111111em;
}

@media (min-width: 768px) {
  .sliders-wrap .range-labels {
    font-size: 1.36363636em;
  }
}

.sliders-wrap .fixed-label {
  position: absolute;
  top: 0;
  line-height: 32px;
  font-size: 0.66666667em;
}

@media (min-width: 768px) {
  .sliders-wrap .fixed-label {
    font-size: 0.72727273em;
  }
}

@media (min-width: 992px) {
  .sliders-wrap .fixed-label {
    font-size: 0.90909091em;
  }
}

.sliders-wrap .label-min {
  left: 0;
}

.sliders-wrap .label-max {
  right: 0;
}

.individual-slide h3 {
  margin-bottom: 0;
  font-weight: 500;
}

.individual-slide .sliders-wrap {
  margin: 10px 0 0;
  padding: 0;
}

@media (min-width: 768px) {
  .individual-slide .sliders-wrap {
    padding: 0 220px 0 140px;
  }
}

.individual-slide .sliders-wrap .range-labels {
  display: none;
}

.individual-slide .sliders-wrap .label-max {
  right: 0;
}

@media (min-width: 768px) {
  .individual-slide .sliders-wrap .label-max {
    right: 0;
    width: 200px;
  }
}

.individual-slide + .individual-slide {
  margin: 50px 0 0;
}

@media (min-width: 768px) {
  .individual-slide + .individual-slide {
    margin: 40px 0 0;
  }
}

@media (max-width: 767px) {
  .individual-slide .fixed-label {
    top: 100%;
    margin: 7px 0 0;
    font-size: 0.66666667em;
    text-transform: uppercase;
  }
}

.range-slider {
  background: #eee;
  box-shadow: none;
  border: none;
  border-radius: 15px;
  height: 30px;
  padding: 4px 15px;
}

.range-slider .noUi-connect {
  background: #99daf2;
}

.range-slider .noUi-handle {
  width: 22px;
  height: 22px;
  right: -11px;
  top: 0;
  border-radius: 100%;
  box-shadow: none;
  border: none;
  background: #99daf2;
  outline: none;
  cursor: grab;
  cursor: -webkit-grab;
}

.range-slider .noUi-handle.noUi-active {
  cursor: pointer;
  cursor: -webkit-grabbing;
}

.range-slider .noUi-handle:after {
  content: none;
}

.range-slider .noUi-handle:before {
  width: 12px;
  border-radius: 100%;
  height: 12px;
  background: #fff;
  left: 5px;
  top: 5px;
}

.range-slider[data-single="true"] {
  padding: 4px 12px 4px 0;
}

@media (min-width: 768px) {
  .range-slider[data-single="true"] {
    padding: 4px 14px 4px 0;
  }
}

.range-slider[data-single="true"] .noUi-handle {
  background: #222222;
  border-radius: 14px;
  height: 38px;
  width: 12px;
  right: -12px;
  top: -8px;
}

@media (min-width: 768px) {
  .range-slider[data-single="true"] .noUi-handle {
    height: 44px;
    top: -11px;
    width: 14px;
    right: -14px;
  }
}

.range-slider[data-single="true"] .noUi-handle:before {
  content: none;
}

.slider--case-study {
  cursor: none;
}

.slider--case-study .slide__image {
  position: relative;
}

.slider--case-study .slide__image .slide__image-spacer {
  display: block;
  width: 100%;
}

.slider--case-study .slide__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.slider--case-study .swiper-wrapper {
  align-items: center;
}

.slider--case-study .slider__slide {
  outline: none;
  width: calc(100% - 3.125vw);
  max-width: 1255px;
  margin-right: 3.125vw;
}

.slider--case-study .slider__slide:last-child {
  margin-right: 0;
}

.slider--case-study .arrow-cursor {
  font-size: 4.27272727em;
  width: 1em;
  height: 0.91489362em;
  position: fixed;
  left: -0.5em;
  top: -0.5em;
  z-index: 4;
  pointer-events: none;
}

.slider--case-study .arrow-cursor__icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-135deg) scale(0.5, 0.5);
  opacity: 0;
}

.slider--case-study .swiper-button-prev,
.slider--case-study .swiper-button-next {
  pointer-events: none;
  display: none;
  padding: 10px;
  font-size: 0.88888889em;
  transition: ease-in-out 0.2s opacity;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3;
  width: 50%;
}

@media (min-width: 768px) {
  .slider--case-study .swiper-button-prev,
  .slider--case-study .swiper-button-next {
    cursor: none;
    font-size: 0.72727273em;
  }
}

.slider--case-study .swiper-button-prev {
  left: 0;
}

.slider--case-study .swiper-button-next {
  right: 0;
}

.slider--case-study .slider__pagination {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.slider__wrapper {
  position: relative;
  overflow: hidden;
}

.slider--gallery {
  padding-bottom: 60px;
  position: relative;
}

@media (min-width: 768px) {
  .slider--gallery {
    padding-bottom: 8.88888889%;
  }
}

.slider--gallery:not(.slick-initialized) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -40px;
}

.slider--gallery:not(.slick-initialized) > div {
  padding-left: 40px;
  width: 33.33333333%;
  margin-bottom: 40px;
}

.slider--gallery:not(.slick-initialized) .slider__pagination {
  display: none;
}

.slider--gallery .slick-track {
  display: flex;
}

.slider--gallery .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  padding-left: 4.125vw;
}

@media (min-width: 1600px) {
  .slider--gallery .slick-track .slick-slide {
    padding-left: 66px;
  }
}

.slider--gallery .slide__image {
  position: relative;
  width: 100%;
}

.slider--gallery .slide__image img {
  display: block;
  width: 100%;
  height: auto;
}

.slider--gallery .slick-list {
  overflow: visible;
  margin-left: -4.125vw;
}

@media (min-width: 1600px) {
  .slider--gallery .slick-list {
    margin-left: -66px;
  }
}

.slider--gallery .slider__slide {
  outline: none;
}

.slider--gallery .slider__pagination {
  position: absolute;
  bottom: 0;
  right: 0;
}

.slider--gallery .slider__pagination .slick-control {
  padding: 10px;
  font-size: 0.88888889em;
  transition: ease-in-out 0.2s opacity;
}

@media (min-width: 768px) {
  .slider--gallery .slider__pagination .slick-control {
    font-size: 0.72727273em;
  }
}

.slider--gallery .slider__pagination .slick-control svg {
  display: block;
  height: 1em;
  width: 2.875em;
}

.slider--gallery .slider__pagination .slick-control--prev {
  margin-right: 3.44444444vw;
}

.slider--gallery .slider__pagination .slick-control:hover,
.slider--gallery .slider__pagination .slick-control:focus,
.slider--gallery .slider__pagination .slick-control.on-press {
  opacity: 0.75;
}

@media (min-width: 768px) {
  .slider--services .swiper-wrapper {
    cursor: none;
  }
}

.slider--services__container {
  position: relative;
  margin-top: 6.42857143vw;
}

@media (min-width: 1680px) {
  .slider--services__container {
    margin-top: 108px;
  }
}

.slider--services .slider__slide {
  width: 100%;
  outline: none;
  max-width: 500px;
}

@media (min-width: 768px) {
  .slider--services .slider__slide {
    width: calc(100vw - 60px - 10vw);
  }
}

@media (min-width: 768px) {
  .slider--services .slider__slide {
    width: calc(90vw - 60px - 10vw);
    max-width: 1014px;
  }
}

@media (min-width: 768px) {
  .slider--services .slider__slide .block--service {
    margin-right: 10vw;
  }
}

.slider--services .arrow-cursor {
  font-size: 4.27272727em;
  width: 1em;
  height: 0.91489362em;
  position: fixed;
  left: -0.5em;
  top: -0.5em;
  z-index: 4;
  pointer-events: none;
}

@media (max-width: 767px) {
  .slider--services .arrow-cursor {
    display: none;
  }
}

.slider--services .arrow-cursor__icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-135deg) scale(0.5, 0.5);
  opacity: 0;
}

.slider--services .swiper-button-prev,
.slider--services .swiper-button-next {
  pointer-events: none;
  display: none;
  padding: 10px;
  font-size: 0.88888889em;
  transition: ease-in-out 0.2s opacity;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3;
  width: 50%;
  outline: none;
}

@media (min-width: 768px) {
  .slider--services .swiper-button-prev,
  .slider--services .swiper-button-next {
    cursor: none;
    font-size: 0.72727273em;
  }
}

.slider--services .swiper-button-prev.swiper-button-disabled,
.slider--services .swiper-button-next.swiper-button-disabled {
  pointer-events: initial;
}

.slider__pagination--services {
  text-align: left;
  position: relative;
  overflow-y: hidden;
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  margin: 0 -30px;
  padding: 0 30px;
  margin-bottom: 9.0625%;
  margin-top: 8.828125%;
}

.slider__pagination--services::-webkit-scrollbar-track,
.slider__pagination--services::-webkit-scrollbar,
.slider__pagination--services::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.slider__pagination--services .swiper-pagination-bullet {
  height: auto;
  width: auto;
  border-radius: 0;
  background: transparent;
  color: inherit;
  opacity: 0.5;
  padding: 0;
  transition: ease-in-out 0.2s opacity;
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  font-weight: 300;
  font-size: 1.09090909em;
  letter-spacing: 0;
  line-height: 1.41666667;
}

.slider__pagination--services .swiper-pagination-bullet + .swiper-pagination-bullet {
  margin-left: 30px;
}

.slider__pagination--services .swiper-pagination-bullet:hover,
.slider__pagination--services .swiper-pagination-bullet:focus {
  opacity: 0.75;
}

.slider__pagination--services .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}

.slider--testimonial,
.slider--services {
  cursor: none;
  width: 100%;
  height: var(--swiper-height);
}

.slider--testimonial .swiper-slide,
.slider--services .swiper-slide {
  display: flex;
  position: relative;
}

.slider--testimonial .swiper-slide .block--testimonial,
.slider--services .swiper-slide .block--testimonial {
  padding-left: calc(var(--swiper-height) / 2 * 3);
}

.slider--testimonial .swiper-slide__img,
.slider--services .swiper-slide__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider--testimonial {
  position: relative;
  padding-top: 30px;
}

@media (min-width: 768px) {
  .slider--testimonial {
    padding-top: 0;
  }
}

.slider--testimonial .slider__slide {
  outline: none;
  width: 100%;
  max-width: 720px;
  padding: 0;
}

@media (min-width: 768px) {
  .slider--testimonial .slider__slide {
    width: calc(50% + 6.94444444vw);
    padding: 0 3.47222222vw;
  }
}

@media (min-width: 1800px) {
  .slider--testimonial .slider__slide {
    width: calc(50% + 125px);
    padding: 0 62.5px;
  }
}

.slider--testimonial .slider__pagination {
  position: absolute;
  top: 3px;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .slider--testimonial .slider__pagination {
    display: none;
  }
}

.slider--testimonial .slider__pagination .swiper-pagination-bullet {
  margin: 0 3px;
  flex-grow: 1;
  background: #222222;
  opacity: 0.15;
  transition: ease-in-out 0.2s opacity;
  max-width: 40px;
  height: 2px;
  display: block;
  border-radius: 0;
}

.light .slider--testimonial .slider__pagination .swiper-pagination-bullet {
  background: #fff;
}

.slider--testimonial .slider__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}

.arrow-cursor {
  font-size: 4.27272727em;
  width: 1em;
  height: 0.91489362em;
  position: fixed;
  left: -0.5em;
  top: -0.5em;
  z-index: 4;
  pointer-events: none;
}

.arrow-cursor__icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-135deg) scale(0.5, 0.5);
  opacity: 0;
}

.sound-byte {
  font-weight: 400;
  font-size: 0.54545455em;
  color: #848484;
  text-align: center;
  line-height: 2;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  padding: 10px;
}

.sound-byte:hover,
.sound-byte:focus {
  color: #404347;
}

.sound-byte span {
  display: block;
}

.sound-byte .title {
  font-size: 1.33333333em;
  color: #404347;
  line-height: 1.5;
  display: flex;
  align-items: center;
  margin-bottom: 0.25em;
}

.sound-byte .title svg {
  margin-right: 0.625em;
  width: 1.40625em;
  height: 1.03125em;
  display: inline-block;
}

.video-inline__container {
  position: relative;
  width: 100%;
  height: 56.40625vw;
  max-height: 100vh;
}

@media (min-width: 768px) {
  .video-inline__container {
    height: calc( 56.40625vw + ((100vh - 56.40625vw) * var(--scroll-fraction)));
  }
}

@media (max-width: 767px) {
  .video-inline__container iframe {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: center center;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 768px) {
  .video-inline__container iframe {
    width: 100vw;
    height: 56.40625vw;
    min-height: 100vh;
    min-width: 177.28531856vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.video-inline__container video {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center center;
  width: 100%;
  height: 100%;
}

.video-inline__container video#showreel-video-full {
  object-fit: contain;
}

@media (min-width: 768px) {
  .video-inline__container video#showreel-video-full {
    object-fit: cover;
  }
}

.video-inline__container .video-inline__full {
  opacity: 0;
}

.video-inline__container .video-inline__preview {
  opacity: 1;
}

.video-inline__container.video-inline--playing #showreel-video-full {
  z-index: 1;
}

.video-inline__container.video-inline--playing .video-inline__full {
  opacity: 1;
}

.video-inline__container.video-inline--playing .video-inline__preview {
  opacity: 0;
}

.case-study__footer-link {
  display: flex;
  align-items: center;
  font-size: 1em;
  color: #404347;
  line-height: 1.71428571;
  padding: 20px 0;
}

@media (min-width: 768px) {
  .case-study__footer-link {
    font-size: 0.81818182em;
    height: 5.55555556vw;
    padding: 0;
  }
}

@media (min-width: 1800px) {
  .case-study__footer-link {
    font-size: 1.59090909em;
    height: 100px;
  }
}

.case-study__footer-link span + span {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.case-study__footer-link span + span:before {
  content: "";
  height: 1px;
  background: #979797;
  display: block;
  flex-grow: 1;
  transform: scaleX(0.6);
  transition: ease-in-out 0.2s transform;
}

@media (prefers-reduced-motion: no-preference) {
  .case-study__footer-link:hover span + span:before,
  .case-study__footer-link:focus span + span:before,
  .case-study__footer-link.on-press span + span:before {
    /* transform: scaleX(0.7); */
  }
}

.case-study__footer--alt {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.33333333% 0;
}

@media (min-width: 1800px) {
  .case-study__footer--alt {
    padding: 60px 0;
  }
}

.case-study__footer--alt .block__content {
  text-align: center;
}

.case-study__footer--alt .block__content h2 {
  margin-bottom: 1.47222222em;
}

.case-study__footer--alt .block__content .block__description {
  font-size: 1.09090909em;
  line-height: 1.66666667;
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
}

.case-study__footer--alt .block__link-container {
  text-align: center;
  margin-top: 8.54166667%;
}

.case-study__footer--alt .block__link {
  width: 100%;
  max-width: 315px;
  margin: 0 auto;
  margin-bottom: 2.3125em;
  font-size: 0.72727273em;
  padding: 1.4375em 1.5625em;
  text-align: center;
}

.case-study__block {
  padding: 40px 0;
  color: #222222;
}

@media (min-width: 768px) {
  .case-study__block {
    padding: 5.625% 0;
  }
}

@media (min-width: 1600px) {
  .case-study__block {
    padding: 90px 0;
  }
}

.case-study__block.case-study__image.case-study__image--full-width,
.case-study__block.case-study__50-50,
.case-study__block.case-study__video--full {
  padding: 0;
}

@media (min-width: 768px) {
  .case-study__block.case-study__slider {
    padding: 13.25% 0 8.8125% 0;
  }
}

@media (min-width: 1600px) {
  .case-study__block.case-study__slider {
    padding: 212px 0 141px;
  }
}

@media (min-width: 768px) {
  .case-study__block.case-study__wysiwyg {
    padding: 12.5% 0;
  }
}

@media (min-width: 1600px) {
  .case-study__block.case-study__wysiwyg {
    padding: 200px 0;
  }
}

@media (min-width: 768px) {
  .case-study__image:not(.case-study__image--full-width) + .case-study__block.case-study__wysiwyg,
  .case-study__block.case-study__slider + .case-study__block.case-study__wysiwyg {
    padding-top: 10%;
  }
}

@media (min-width: 1600px) {
  .case-study__image:not(.case-study__image--full-width) + .case-study__block.case-study__wysiwyg,
  .case-study__block.case-study__slider + .case-study__block.case-study__wysiwyg {
    padding-top: 160px;
  }
}

.case-study__block.case-study__aside-image--full-width {
  padding-top: 0;
}

@media (min-width: 768px) {
  .case-study__block.case-study__aside-image--full-width {
    padding-top: 5.625%;
  }
}

@media (min-width: 1600px) {
  .case-study__block.case-study__aside-image--full-width {
    padding-top: 90px;
  }
}

@media (min-width: 768px) {
  .case-study__block.case-study__aside-image--sticky {
    padding: 12.5% 0;
  }
}

@media (min-width: 1600px) {
  .case-study__block.case-study__aside-image--sticky {
    padding: 200px 0;
  }
}

.case-study__cta {
  color: var(--block-color);
}

.case-study__cta:before {
  display: block;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: var(--block-background-color);
  pointer-events: none;
  transition: ease-in-out 0.2s 0s opacity, ease-in-out 0s height 0.2s, ease-in-out 0s width 0.2s;
}

@media (min-width: 768px) {
  .case-study__cta:before {
    content: "";
  }
}

.case-study__cta:not(:hover):before {
  width: 0;
  height: 0;
}

.case-study__cta:hover:before,
.case-study__cta:focus:before,
.case-study__cta.on-press:before {
  opacity: 0.4;
  /* transition: ease-in-out 0.2s 0s opacity, ease-in-out 0s height 0s, ease-in-out 0s width 0s; */
}

.case-study__cta .block__inner {
  display: block;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 44.44444444vw;
  height: 100px;
}

@media (min-width: 1800px) {
  .case-study__cta .block__inner {
    min-height: 800px;
  }
}

.case-study__cta .block__inner .block__caption {
  position: relative;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.33333333;
  transform: translateY(2.05555556vw);
}

@media (min-width: 768px) {
  .case-study__cta .block__inner .block__caption {
    transition: ease-in-out 0.2s transform;
    font-size: 1.36363636em;
  }
}

@media (min-width: 1800px) {
  .case-study__cta .block__inner .block__caption {
    transform: translateY(37px);
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: no-preference) {
  .case-study__cta .block__inner:hover .block__image,
  .case-study__cta .block__inner:focus .block__image,
  .case-study__cta .block__inner.on-press .block__image {
    /* top: -5.55555556vw; */
  }
}

@media (min-width: 1800px) and (prefers-reduced-motion: no-preference) {
  .case-study__cta .block__inner:hover .block__image,
  .case-study__cta .block__inner:focus .block__image,
  .case-study__cta .block__inner.on-press .block__image {
    /* top: -100px; */
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: no-preference) {
  .case-study__cta .block__inner:hover .block__caption,
  .case-study__cta .block__inner:focus .block__caption,
  .case-study__cta .block__inner.on-press .block__caption {
    transform: none;
  }
}

.case-study__cta .block__title {
  font-weight: 200;
  font-size: 1.44444444em;
  line-height: 1;
  margin-bottom: 0.66666667em;
}

@media (min-width: 768px) {
  .case-study__cta .block__title {
    font-size: 2em;
  }
}

.case-study__cta .block__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  overflow: hidden;
  transition: ease-in-out 0.2s top;
}

.case-study__cta .block__image .block__image-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-image: inherit;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.case-study__cta .block__image .block__image-bg:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 50%;
}

@media (min-width: 1800px) {
  .case-study__cta .block__image .block__image-bg:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 900px;
  }
}

.single-work h2 {
  font-size: 1.63636364em;
  line-height: 1.30555556;
  margin-bottom: 1.19444444em;
  font-weight: 500;
}

.case-study__modular-content {
  color: #222222;
}

.case-study__modular-content a,
.case-study__introduction a,
.case-study__footer a {
  color: inherit;
  transition: ease-in-out 0.2s opacity;
}

.case-study__modular-content a:hover,
.case-study__modular-content a:focus,
.case-study__modular-content a.on-press,
.case-study__introduction a:hover,
.case-study__introduction a:focus,
.case-study__introduction a.on-press,
.case-study__footer a:hover,
.case-study__footer a:focus,
.case-study__footer a.on-press {
  opacity: 0.7;
}

.case-study__introduction {
  padding: 8.5% 0 4.25% 0;
}

@media (min-width: 1600px) {
  .case-study__introduction {
    padding: 136px 0 68px;
  }
}

.case-study__introduction a {
  color: inherit;
  transition: ease-in-out 0.2s opacity;
}

.case-study__introduction a:hover,
.case-study__introduction a:focus,
.case-study__introduction a.on-press {
  opacity: 0.7;
}

@media (min-width: 768px) {
  .case-study__introduction .case-study__introduction-inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -6.77083333vw;
  }

  .case-study__introduction .case-study__introduction-inner > div {
    padding-left: 6.77083333vw;
    width: 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .case-study__introduction .case-study__introduction-inner > div.case-study__introduction-features {
    width: 31.97452229%;
  }
}

@media (min-width: 768px) {
  .case-study__introduction .case-study__introduction-inner > div.case-study__introduction-description {
    width: 68.02547771%;
  }
}

@media (min-width: 768px) {
  .case-study__introduction .case-study__introduction-features {
    padding-top: 11px;
  }
}

.case-study__introduction .case-study__introduction-features ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 200;
  font-size: 0.88888889em;
  margin-bottom: 3.88888889em;
}

@media (min-width: 768px) {
  .case-study__introduction .case-study__introduction-features ul {
    font-size: 0.81818182em;
  }
}

.case-study__introduction .case-study__introduction-features ul li {
  margin-bottom: 1.0625em;
}

@media (min-width: 768px) {
  .case-study__introduction .case-study__introduction-features ul li {
    margin-bottom: 1.61111111em;
  }
}

@media (max-width: 767px) {
  .case-study__introduction .case-study__introduction-features ul[data-waay="step-up"] > [data-waay-step] {
    opacity: 1;
    transform: translateY(0);
  }
}

.case-study__introduction .case-study__introduction-features .case-study__feature-blurb {
  font-size: 1em;
}

@media (min-width: 768px) {
  .case-study__introduction .case-study__introduction-features .case-study__feature-blurb {
    font-size: 0.81818182em;
  }
}

@media (max-width: 767px) {
  .case-study__introduction .case-study__introduction-features .case-study__feature-blurb[data-waay="slide-up"] {
    opacity: 1;
    transform: none;
  }
}

.case-study__introduction .case-study__introduction-features .case-study__feature-blurb p {
  line-height: 1.94444444;
}

@media (min-width: 768px) {
  .case-study__introduction .case-study__introduction-features .case-study__feature-blurb {
    max-width: 250px;
  }
}

.case-study__introduction .case-study__introduction-features .case-study__feature-blurb h2 {
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 1.66666667em;
}

.case-study__introduction .case-study__introduction-description {
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .case-study__introduction .case-study__introduction-description[data-waay]:not(.waay-animate) *[data-waay-step] {
    opacity: 1;
    transform: translateY(0);
  }
}

.case-study__introduction .case-study__introduction-description .case-study__introduction-copy {
  font-size: 1.22222222em;
  line-height: 1.81818182;
  flex-grow: 1;
}

@media (min-width: 768px) {
  .case-study__introduction .case-study__introduction-description .case-study__introduction-copy {
    font-size: 1.05454545em;
    line-height: 1.71875;
  }
}

.case-study__introduction--alt {
  padding: 27px 0 11.25%;
}

@media (min-width: 768px) {
  .case-study__introduction--alt {
    padding: 11.25% 0;
  }
}

@media (min-width: 1600px) {
  .case-study__introduction--alt {
    padding: 180px 0;
  }
}

@media (min-width: 768px) {
  .case-study__introduction--alt .case-study__introduction-features ul li {
    margin-bottom: 0.77777778em;
  }
}

@media (min-width: 768px) {
  .case-study__introduction--alt .case-study__introduction-description {
    margin-bottom: 3.88888889em;
  }
}

.case-study__slider {
  background-color: var(--block-background-color);
  color: var(--block-color);
}

.case-study__slider .slick-control svg path {
  fill: var(--block-color);
}

.case-study__slider .slider__slide img {
  width: 100%;
  height: auto;
}

.case-study__wysiwyg {
  background-color: var(--block-background-color);
  color: var(--block-color);
}

@media (min-width: 768px) {
  .case-study__wysiwyg .block__inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -5.41666667vw;
  }

  .case-study__wysiwyg .block__inner > div {
    padding-left: 5.41666667vw;
    width: 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .case-study__wysiwyg .block__inner > div:nth-child(2n+1) {
    width: 38.52691218%;
  }
}

@media (min-width: 768px) {
  .case-study__wysiwyg .block__inner > div:nth-child(2n+2) {
    width: 61.47308782%;
    max-width: 860px;
  }
}

.case-study__wysiwyg .block__content {
  opacity: 0.75;
  font-size: 1.09090909em;
  line-height: 1.66666667;
}

.case-study__blockquote {
  background-color: var(--block-background-color);
  color: var(--block-color);
}

@media (min-width: 768px) {
  .case-study__blockquote {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.case-study__blockquote blockquote {
  line-height: 1.66666667;
  margin: 0;
  max-width: 1065px;
  font-size: 0.83333333em;
}

@media (min-width: 768px) {
  .case-study__blockquote blockquote {
    font-size: 1.36363636em;
  }
}

.case-study__blockquote blockquote small {
  opacity: 0.8;
  line-height: 1.5;
  display: block;
  font-size: 1em;
  margin-top: 1.93333333em;
}

@media (min-width: 768px) {
  .case-study__blockquote blockquote small {
    font-size: 0.66666667em;
  }
}

.case-study__aside-image {
  background-color: var(--block-background-color);
  color: var(--block-color);
}

@media (min-width: 768px) {
  .case-study__aside-image .block__inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -5.20833333vw;
    align-items: center;
  }

  .case-study__aside-image .block__inner > div {
    padding-left: 5.20833333vw;
    width: 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .case-study__aside-image .block__inner > div:nth-child(2n + 1) {
    align-self: center;
    width: 55.52631579%;
  }
}

@media (min-width: 768px) {
  .case-study__aside-image .block__inner > div:nth-child(2n + 2) {
    width: 44.47368421%;
  }
}

/* .case-study__aside-image .block__image {
  position: relative;
}

.case-study__aside-image .block__image img {
  width: 100%;
  height: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@supports (object-fit: contain) {
  .case-study__aside-image .block__image img {
    object-fit: contain;
    height: 100%;
  }
} */

.case-study__aside-image .block__content {
  font-size: 1.09090909em;
  line-height: 1.66666667;
}

.case-study__aside-image .block__content .block__title {
  font-size: 1.5em;
}

.case-study__aside-image .block__content a {
  text-decoration: underline;
  transition: ease-in-out 0.2s opacity;
}

.case-study__aside-image .block__content a:hover,
.case-study__aside-image .block__content a:focus,
.case-study__aside-image .block__content a.on-press {
  opacity: 0.75;
}

.case-study__aside-image .block__content-inner {
  padding: 30px 0 0;
}

@media (min-width: 768px) {
  .case-study__aside-image.case-study__aside-image--flipped .block__inner {
    flex-direction: row-reverse;
  }
}

.case-study__aside-image.case-study__aside-image--full-width .block__image {
  margin: 0 0 30px;
}

@media (min-width: 768px) {
  .case-study__aside-image.case-study__aside-image--full-width .block__image {
    margin: 0;
  }
}

.case-study__aside-image.case-study__aside-image--full-width .block__content {
  padding: 0 30px;
}

@media (min-width: 768px) {
  .case-study__aside-image.case-study__aside-image--full-width .block__content {
    padding: 0 30px 0 0;
  }
}

@media (min-width: 768px) {
  .case-study__aside-image.case-study__aside-image--full-width .block__content-inner {
    max-width: 565px;
  }
}

@media (min-width: 768px) {
  .case-study__aside-image.case-study__aside-image--full-width.case-study__aside-image--flipped .block__content {
    padding: 0 0 0 30px;
  }
}

@media (min-width: 768px) {
  .case-study__aside-image.case-study__aside-image--full-width.case-study__aside-image--flipped .block__content-inner {
    margin-left: auto;
  }
}

.case-study__aside-image.case-study__aside-image--sticky .block--sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.case-study__aside-image.case-study__aside-image--sticky .block__content-inner {
  padding-top: 30px;
}

@media (min-width: 768px) {
  .case-study__aside-image.case-study__aside-image--sticky .block__content-inner {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .case-study__aside-image.case-study__aside-image--sticky .block__inner {
    align-items: stretch;
  }
}

.case-study__aside-image.case-study__aside-image--sticky .block__inner .block__content {
  font-size: 1em;
}

@media (min-width: 768px) {
  .case-study__aside-image.case-study__aside-image--sticky .block__inner .block__content {
    min-height: 100vh;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0 30px;
  }
}

.case-study__aside-image.case-study__aside-image--sticky .block__inner .block__content .block__title,
.case-study__aside-image.case-study__aside-image--sticky .block__inner .block__content h2 {
  font-size: 1.18181818em;
  line-height: 1.69230769;
  margin-bottom: 0.65384615em;
}

.case-study__image {
  background-color: var(--block-background-color);
}

/* .case-study__image .block__image {
  position: relative;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}

.case-study__image .block__image img {
  width: 100%;
  height: auto;
  display: block;
}

.case-study__image .block__image .block__image-spacer {
  display: none;
}

.case-study__image .block__image .block__image-spacer {
  display: block;
}

.case-study__image .block__image img {
  position: absolute;
  top: 0;
  left: 0;
}

@supports (object-fit: contain) {
  .case-study__image .block__image img {
    height: 100%;
    object-fit: cover;
  }
} */

.case-study__video {
  background-color: var(--block-background-color);
  color: var(--block-color);
}

.case-study__video .block__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.case-study__video .block__image img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

@supports (object-fit: cover) {
  .case-study__video .block__image img {
    height: 100%;
    object-fit: cover;
  }
}

.case-study__video .container .block__video:not(.block__video--ipad) {
  margin: 0 -30px 30px;
}

@media (min-width: 768px) {
  .case-study__video .container .block__video:not(.block__video--ipad) {
    margin: 0;
  }
}

.case-study__video .block__video {
  position: relative;
  margin: 0 0 30px;
}

@media (min-width: 768px) {
  .case-study__video .block__video {
    margin: 0;
  }
}

.case-study__video .block__video .block__video-controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  transition: ease-in-out 0.2s opacity;
  width: 100%;
}

.case-study__video .block__video .block__video-controls .btn--video-play,
.case-study__video .block__video .block__video-controls .btn--video-play-modaal {
  width: 45px;
}

@media (min-width: 768px) {
  .case-study__video .block__video .block__video-controls .btn--video-play,
  .case-study__video .block__video .block__video-controls .btn--video-play-modaal {
    width: 8.81944444%;
  }
}

.case-study__video .block__video.js-video-playing .block__video-controls {
  opacity: 0;
}

.video-container video {
  display: block;
  position: relative;
  width: 100%;
  outline: none;
}

.block__video--ipad .video-container {
  position: relative;
  margin: 0 auto;
  /* background-image: url(/wp-content/themes/humaan5/dist/images/ipad.png?093a0530c3f53d1123014c5a6c1d190b); */
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.block__video--ipad .video-container video {
  position: absolute;
  object-fit: cover;
  border-radius: 1.21212121vw;
  z-index: 1;
}

@media (min-width: 660px) {
  .block__video--ipad .video-container video {
    border-radius: 8px;
  }
}

.block__video--ipad.block__video--ipad-portrait .video-container {
  max-width: 600px;
}

.block__video--ipad.block__video--ipad-portrait .video-container:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 130.90909091%;
}

.block__video--ipad.block__video--ipad-portrait .video-container video {
  top: 3.33333333%;
  left: 4.27272727%;
  width: 91.27272727%;
  height: 93.47222222%;
}

.block__video--ipad.block__video--ipad-landscape .video-container {
  max-width: 1280px;
  /* background-image: url(/wp-content/themes/humaan5/dist/images/ipad--landscape.png?769f0a87621463f6f8ba45f8d61e9c12); */
}

.block__video--ipad.block__video--ipad-landscape .video-container:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 76.38888889%;
}

.block__video--ipad.block__video--ipad-landscape .video-container video {
  position: absolute;
  top: 4.39672802%;
  left: 3.359375%;
  height: 91.30879346%;
  width: 93.4375%;
  object-fit: cover;
  border-radius: 1.09375vw;
  z-index: 1;
}

@media (min-width: 1340px) {
  .block__video--ipad.block__video--ipad-landscape .video-container video {
    border-radius: 14px;
  }
}

.case-study__50-50 {
  background-color: var(--block-background-color);
  color: var(--block-color);
}

@media (min-width: 768px) {
  .case-study__50-50 .block__inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -0;
  }

  .case-study__50-50 .block__inner > div {
    padding-left: 0;
    width: 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .case-study__50-50 .block__inner > div:nth-child(2n + 2) {
    align-self: center;
  }
}

.case-study__50-50 .block__image {
  position: relative;
  min-height: 100%;
}

.case-study__50-50 .block__image img {
  width: 100%;
  height: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@supports (object-fit: cover) {
  .case-study__50-50 .block__image img {
    object-fit: cover;
    height: 100%;
  }
}

.case-study__50-50 .block__content {
  font-size: 1.09090909em;
}

.case-study__50-50 .block__content .block__title {
  font-size: 1.5em;
}

.case-study__50-50 .block__content p {
  opacity: 0.75;
}

.case-study__50-50 .block__content-inner {
  padding: 30px 0;
}

@media (min-width: 768px) {
  .case-study__50-50 .block__content-inner {
    padding: 30px 0 30px 5.20833333vw;
  }
}

@media (min-width: 1920px) {
  .case-study__50-50 .block__content-inner {
    padding: 30px 0 30px 100px;
  }
}

.case-study__50-50 .block__content-inner p:last-child {
  margin: 0;
}

@media (min-width: 768px) {
  .case-study__50-50.case-study__50-50--flipped .block__inner {
    flex-direction: row-reverse;
  }
}

@media (min-width: 768px) {
  .case-study__50-50.case-study__50-50--flipped .block__content {
    padding: 30px 5.20833333vw 30px 0;
  }
}

@media (min-width: 768px) {
  .case-study__50-50.case-study__50-50--flipped .block__content-inner {
    margin-left: auto;
  }
}

.case-study__50-50 .block__content {
  padding: 0 30px;
}

@media (min-width: 768px) {
  .case-study__50-50 .block__content {
    padding: 0 30px 0 0;
  }
}

@media (min-width: 768px) {
  .case-study__50-50 .block__content-inner {
    max-width: 698px;
  }
}

.button-group {
  display: flex;
  margin-left: -20px;
  flex-wrap: wrap;
}

.button-group > div {
  padding-left: 20px;
}

.case-study--related {
  padding: 6.25% 0 0;
}

@media (min-width: 1600px) {
  .case-study--related {
    padding: 100px 0 0;
  }
}

.case-study--related .section__title {
  text-align: center;
  font-size: 3rem;
}

.section--floating-cta {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 30;
  transition-property: opacity, transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

@media (min-width: 768px) {
  .section--floating-cta .section__inner {
    padding: 2rem;
  }
}

.section--floating-cta:not(.active) {
  opacity: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .section--floating-cta:not(.active) {
    /* transform: translateY(100%); */
  }
}

.section--floating-cta .btn {
  margin-left: auto;
  display: block;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .section--floating-cta .btn {
    max-width: 100%;
    width: 100%;
  }
}

.is-editable:after {
  content: "";
  clear: both;
  display: table;
}

.is-editable img {
  width: auto;
  max-width: 100%;
  display: block;
}

.is-editable .iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.is-editable .iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer-main {
  font-size: 1.18181818em;
  padding: 0;
  background: #ffffff;
}

.single-work .footer-main {
  background: #ffffff;
  position: relative;
  padding-top: 9.375%;
}

@media (min-width: 1600px) {
  .single-work .footer-main {
    padding-top: 150px;
  }
}

.page-template-template-logos .footer-main,
.template-about .footer-main,
.template-team .footer-main,
.template-careers .footer-main,
.category .footer-main,
.blog .footer-main {
  padding-top: 9.375%;
}

@media (min-width: 1600px) {
  .page-template-template-logos .footer-main,
  .template-about .footer-main,
  .template-team .footer-main,
  .template-careers .footer-main,
  .category .footer-main,
  .blog .footer-main {
    padding-top: 150px;
  }
}

.template-landing .footer-main,
.template-careers .footer-main {
  background: transparent;
}

.template-planner .footer-main {
  padding-bottom: 1.25%;
}

@media (min-width: 1600px) {
  .template-planner .footer-main {
    padding-bottom: 20px;
  }
}

.footer-main .footer-main__social ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.53846154em;
  line-height: 2.85714286;
}

.footer-main .footer-main__social ul li {
  display: inline-block;
}

.footer-main .footer-main__social ul li + li {
  position: relative;
}

.footer-main .footer-main__social ul li + li:before {
  content: "-";
  padding: 0 4px;
}

@media (min-width: 768px) {
  .footer-main .footer-main__social ul li + li:before {
    padding: 0 27px;
  }
}

.footer-main .footer-main__logo {
  margin: 0 auto;
  max-width: 413px;
  position: relative;
}

.footer-main .footer-main__logo .footer-main__logo-link {
  display: block;
}

.footer-main .footer-main__logo .footer-main__logo-link:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.footer-main .footer-main__logo .footer-logo {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer-main .footer-main__logo .footer-logo .footer-logo__text {
  transform-origin: center center;
}

.footer-main .footer-main__logo .footer-logo .footer-logo__faces > path {
  opacity: 0;
}

.footer-main .footer-main__logo .footer-logo .footer-logo__faces > path.active {
  opacity: 1;
}

.footer-main .footer-main__bottom p {
  margin: 0;
}

.footer-main .footer-main__bottom a {
  text-decoration: none;
  color: #222222;
}

.footer-main .footer-main__bottom a:hover,
.footer-main .footer-main__bottom a:focus,
.footer-main .footer-main__bottom a.on-press {
  color: #633a86;
}

.footer-main .footer-main__bottom-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -5px;
  justify-content: space-between;
}

.footer-main .footer-main__bottom-inner > div {
  padding-left: 5px;
  width: 50%;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .footer-main .footer-main__bottom-inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -15px;
    justify-content: space-between;
  }

  .footer-main .footer-main__bottom-inner > div {
    padding-left: 15px;
    width: 50%;
    margin-bottom: 0;
  }
}

.footer-main .footer-main__bottom-inner > div {
  width: auto;
}

.footer-main .footer-main__copyright {
  font-weight: 400;
  font-size: 0.53846154em;
  line-height: 2.85714286;
}

.footer-main .footer-main__copyright .link--privacy {
  padding-left: 6px;
}

@media (min-width: 768px) {
  .footer-main .footer-main__copyright .link--privacy {
    padding-left: 14px;
  }
}

.footer-main.js-reveal-footer--stuck {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.footer__country {
  font-weight: 400;
  font-size: 1.4rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.42857143;
  background: #232323;
  padding: 20px 0;
  margin-top: 9.22619048vw;
}

@media (min-width: 768px) {
  .footer__country {
    text-align: left;
  }
}

@media (min-width: 1680px) {
  .footer__country {
    margin-top: 155px;
  }
}

.footer__country a {
  color: #ffffff;
}

.footer__country a:hover,
.footer__country a:focus,
.footer__country a.on-press {
  color: rgba(255, 255, 255, 0.75);
}

.footer__country p {
  margin: 0;
}

.footer__country-inner {
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .footer__country-inner {
    flex-direction: column;
    align-items: center;
  }
}

.footer__country .footer__country-text {
  align-self: center;
}

.footer__country .footer__country-flag {
  font-size: 1.8rem;
  padding-right: 1.16666667em;
  display: block;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .footer__country .footer__country-flag {
    margin-bottom: 0;
  }
}

.footer__country .footer__country-flag svg {
  width: 1.66666667em;
  height: 1em;
  display: block;
}




@media (min-width: 768px) {
  .main-header--peek .header-main {
    position: fixed;
    /* transform: translateY(0); */
    padding: 20px 0;
    top: 90px;
    background-color: red;
    /* transition: transform 0.2s ease-in-out; */
  }
}

@media (min-width: 768px) and (prefers-reduced-motion) {
  .main-header--peek .header-main {
    /* transition: none; */
  }
}

@media (min-width: 768px) {
  .main-header--peek-in.main-header--peek .header-main {
    /* transform: translateY(-100%); */
    transition: transform 0.2s ease-in-out;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion) {
  .main-header--peek-in.main-header--peek .header-main {
    /* transition: none; */
  }
}


.sticky-face svg {
  display: block;
  width: 1em;
  height: 1em;
}

.sticky-face--blue svg {
  width: 0.91304348em;
  height: 1em;
}

.sticky-face--yellow svg {
  width: 1.00362319em;
  height: 1em;
}

.sticky-face--red svg {
  width: 1.00724638em;
  height: 1em;
}

.sticky-face--lavender svg {
  width: 0.99275362em;
  height: 1em;
}

.sticky-face--pink svg {
  width: 0.91304348em;
  height: 1em;
}

.sticky-face--purple svg {
  width: 1.10358566em;
  height: 1em;
}

.header__mobile-logo .sticky-face--purple svg path {
  fill: #fff;
}











@keyframes flash {
  0% {
    opacity: 1;
  }

  49% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.hero-home {
  width: 100%;
  min-height: 100vh;
  min-height: calc((var(--vh, 1vh) * 100));
  display: flex;
  flex-direction: column;
  /* transition: min-height ease-in-out 0.2s; */
}

@media (min-width: 768px) {
  .hero-home {
    min-height: 0;
    display: block;
    height: calc( 100vh + (56.25vw + ((100vh - 56.25vw) * var(--scroll-fraction))));
  }
}

.hero-home__controls {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px 0;
  z-index: 100;
  width: 100%;
}

.hero-home__screen {
  width: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.35);
  position: relative;
  flex-grow: 1;
  justify-content: center;
}

@media (max-width: 767px) {
  .hero-home__screen {
    transition: min-height 0.3s ease-in-out;
  }
}

@media (min-width: 768px) {
  .hero-home__screen {
    flex-direction: row;
    min-height: 0;
    height: 92vh;
    min-height: 92vh;
    min-height: calc((var(--vh, 1vh) * 100) - 8vh);
    position: absolute;
    flex-grow: 0;
    top: 0;
    left: 0;
  }
}

@media (min-width: 1380px) and (max-width: 1499px) {
  .hero-home__screen:after {
    content: "";
    display: block;
    background-color: #fff;
    width: 12.5%;
  }
}

.hero-home__screen > div {
  width: 100%;
}

@media (min-width: 768px) {
  .hero-home__screen > div {
    width: 50%;
  }
}

@media (min-width: 1380px) {
  .hero-home__screen > div.hero-home__animation {
    width: 37.5%;
  }
}

@media (min-width: 1500px) {
  .hero-home__screen > div.hero-home__animation {
    width: 50%;
  }
}

.hero-home__overlay {
  background: #fff;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 90px;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .hero-home__overlay {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .hero-home__overlay {
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: calc(70px + 6.66666667vw);
  }
}

@media (min-width: 1500px) {
  .hero-home__overlay {
    padding-left: calc(170px + ((100vw - 1500px) / 2));
    flex-grow: 1;
  }
}

.hero-home__overlay .text {
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  font-weight: 200;
  margin-bottom: 0;
  text-align: center;
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .hero-home__overlay .text {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.hero-home__animation {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media (min-width: 768px) {
  .hero-home__animation {
    height: 100%;
    flex-direction: row;
    flex-grow: 1;
  }
}

.hero-home__animation .inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: none;
}

@media (min-width: 768px) {
  .hero-home__animation .inner {
    display: block;
  }
}

.hero-home__animation .svg-wrap {
  position: relative;
  height: 100%;
  display: flex;
}

.hero-home__animation .svg-wrap:after {
  content: "";
  background: #fff;
  flex-grow: 1;
  padding: 0;
}

.hero-home__animation .svg-wrap .svg-wrap__inner {
  position: relative;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  width: 100%;
  max-width: 710px;
  justify-content: center;
}

@media (min-width: 768px) and (max-height: 950px) {
  .hero-home__animation .svg-wrap .svg-wrap__inner {
    max-width: 74.94736842vh;
  }
}

@media (min-width: 768px) {
  .hero-home__animation .svg-wrap .svg-wrap__inner {
    flex-direction: column;
  }
}

.hero-home__animation .svg-wrap .svg-wrap__inner:before,
.hero-home__animation .svg-wrap .svg-wrap__inner:after {
  content: "";
  background: #fff;
  width: 100%;
  flex-grow: 1;
  padding: 0;
}

.hero-home__animation .svg-wrap .svg-wrap__inner .svg-home-face {
  width: 71.46666667%;
  position: relative;
  flex-shrink: 0;
}

.hero-home__animation .svg-wrap .svg-wrap__inner .svg-home-face:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

@media (min-width: 768px) {
  .hero-home__animation .svg-wrap .svg-wrap__inner .svg-home-face {
    max-width: 710px;
    width: 100%;
  }
}

.hero-home__animation .svg-wrap .svg-wrap__inner .svg-home-face svg {
  display: block;
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
}

.hero-home__animation .svg-animate mask > *:not(rect) {
  fill: none;
  stroke: black;
  stroke-width: 40;
}

.hero-home__mobile-nav {
  background: #fff;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .hero-home__mobile-nav {
    display: none;
  }
}

.hero-home__mobile-nav:before {
  flex-grow: 1;
}

@media (min-width: 768px) {
  .hero-home__mobile-nav:before {
    content: "";
  }
}

.hero-home__video {
  width: 100%;
  overflow: hidden;
  position: relative;
}

@media (min-width: 768px) {
  .hero-home__video {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

.hero-home__video:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: black;
  opacity: 0.5;
  transition: opacity 1s ease-in-out;
}

@media (min-width: 768px) {
  .hero-home__video:before {
    content: "";
  }
}

body.is-heroScroll .hero-home__video:before {
  opacity: 0;
}

.hero-home__video .btn--video-play,
.hero-home__video .btn--video-play-modaal {
  position: absolute;
  top: auto;
  left: auto;
  right: 3vw;
  bottom: 2.16666667vw;
  transition: ease-in-out 0.2s opacity 0s;
  opacity: 1;
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 0.81818182em;
}

@media (min-width: 768px) {
  .hero-home__video .btn--video-play,
  .hero-home__video .btn--video-play-modaal {
    opacity: 0;
  }
}

.is-heroScroll .hero-home__video .btn--video-play,
.is-heroScroll
.hero-home__video .btn--video-play-modaal {
  opacity: 1;
}

@media (min-width: 1800px) {
  .hero-home__video .btn--video-play,
  .hero-home__video .btn--video-play-modaal {
    right: 54px;
    bottom: 39px;
  }
}

.hero-home__video .btn--video-play img,
.hero-home__video .btn--video-play-modaal img {
  width: 1.88888889em;
  height: 2.05555556em;
  display: block;
  margin-right: 0.83333333em;
  margin-top: 0.27777778em;
}

@media (max-width: 767px) {
  .hero-home__video .btn--video-play .btn__label,
  .hero-home__video .btn--video-play-modaal .btn__label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px !important;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}

.hero-home__video .hero-home__video-fallback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  z-index: -1;
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  .hero-home__video .hero-home__video-fallback {
    display: block !important;
  }
}

.hero-home__video #showreel-video,
.hero-home__video #showreel-video-full {
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  background: #000;
}

.hero-home__video.js-video-playing .btn--video-play,
.hero-home__video.js-video-playing .btn--video-play-modaal {
  opacity: 0;
  transition: ease-in-out 0.2s opacity;
}

.hero-home__video.js-video-playing #video {
  object-fit: contain;
}

body > div:last-child:not(.modaal-overlay) {
  z-index: 10;
}

svg {
  min-height: 0;
  min-width: 0;
}

html {
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  background: #ffffff;
}

body.scrolling-down {
  transition: ease-in-out 0.5s background-color;
}

body.scrolling-up {
  transition: ease-in-out 0.3s background-color;
}

.container {
  max-width: var(--max-width, 1800px);
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (min-width: 768px) {
  .container {
    /* width: 100%; */
  }
}

.container--std {
  max-width: 1440px;
}


.container--page {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.container--page .content-main {
  flex-grow: 1;
}

.page-template-template-planner .container--page .content-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container--planner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.container--sm {
  max-width: var(--max-width, 870px);
}

.section--humaans .container--sm.container {
  max-width: 1226px;
}

.case-study__wysiwyg .container--sm.container {
  max-width: 974px;
}

.container--md {
  max-width: 1340px;
}

.container--990 {
  max-width: 99rem;
}

.container--962 {
  max-width: 102.2rem;
}

.container--1800 {
  max-width: 1800px;
}

.container--100 {
  width: 100%;
}

.footer-main .container--full {
  max-width: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .footer-main .container--full {
    padding: 0 70px;
  }
}

.banner--team .container {
  max-width: 958px;
}

.section--humaans .container {
  max-width: 1568px;
}

.banner--about .container {
  position: relative;
}

.case-study__blockquote .container {
  max-width: 1120px;
}

@media (min-width: 768px) {
  .case-study__blockquote .container {
    width: 90%;
  }
}

.case-study__wysiwyg .container {
  max-width: 1375px;
}

.section--awards-list > .container {
  padding: 40px 0;
}

@media (min-width: 768px) {
  .section--awards-list > .container {
    padding: 5.55555556vw 0;
  }
}

@media (min-width: 1800px) {
  .section--awards-list > .container {
    padding: 100px 0;
  }
}

.banner--case-study .container {
  z-index: 1;
}

@media (min-width: 768px) {
  .banner--case-study .container {
    width: 90%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 6.4375vw;
  }
}

@media (min-width: 1600px) {
  .banner--case-study .container {
    bottom: 103px;
  }
}

.container--1040 {
  max-width: 110rem;
}

.container.container--contact-details {
  max-width: 1572px;
}

@media (min-width: 768px) {
  .container.container--full {
    width: 100%;
    max-width: 100%;
  }
}

.single-work .content-main {
  background: #ffffff;
}

@media (min-width: 768px) {
  .section__intro {
    max-width: 725px;
  }
}

.section__intro h1 {
  font-size: 1.11111111em;
  font-weight: 400;
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  text-align: center;
}

@media (min-width: 768px) {
  .section__intro h1 {
    font-size: 1em;
    text-align: left;
  }
}

.section__intro--center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

img.lazy:not([src]),
img.lazy[src=""] {
  visibility: hidden;
}

.gallery__container {
  max-width: 1600px;
}

.common-blog-listing {
  position: relative;
}

.common-blog-listing > h2 {
  position: absolute;
  left: 55px;
  top: 25px;
  color: rgba(34, 34, 34, 0.5);
  font-size: 1em;
  font-weight: 500;
}

@media (max-width: 768px) {
  .common-blog-listing > h2 {
    position: relative;
    left: auto;
    top: auto;
    padding: 20px 30px;
    border-bottom: 1px solid #ebebeb;
  }
}

.common-blog-listing .grid--recommended-articles {
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .common-blog-listing .grid--recommended-articles {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -0;
    margin-bottom: 130px;
  }

  .common-blog-listing .grid--recommended-articles > .grid__item {
    padding-left: 0;
    width: 33.33333333%;
    margin-bottom: 0;
  }
}

.common-blog-listing .grid__item {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #ebebeb;
}

.common-blog-listing .grid__item .blog-item__inner {
  padding: 30px;
  border-left: 1px solid #ebebeb;
  height: 100%;
}

@media (min-width: 768px) {
  .common-blog-listing .grid__item .blog-item__inner {
    padding: 4.375vw 3.4375vw;
  }
}

@media (min-width: 1600px) {
  .common-blog-listing .grid__item .blog-item__inner {
    padding: 70px 55px;
  }
}

.common-blog-listing .grid__item:first-child .blog-item__inner {
  border-left: 0;
}

.common-blog-listing .grid__item h3 {
  font-weight: 600;
}

.common-blog-listing .grid__item h3 a {
  text-decoration: none;
  color: #222222;
}

.common-blog-listing .grid__item h3 a:hover,
.common-blog-listing .grid__item h3 a:focus {
  color: #99daf2;
}

.common-blog-listing .grid__item .blog-meta {
  font-weight: 600;
  font-size: 0.5em;
}

.common-blog-listing .grid__item .blog-meta a {
  margin: 0 0 0 10px;
  color: rgba(34, 34, 34, 0.5);
  text-decoration: none;
}

.common-blog-listing .grid__item .blog-meta a:hover,
.common-blog-listing .grid__item .blog-meta a:focus {
  color: #99daf2;
}

.common-blog-listing .blog-hero-thumb {
  margin: 0 0 30px;
}

.common-blog-listing .blog-hero-thumb a,
.common-blog-listing .blog-hero-thumb img {
  display: block;
}

.common-blog-listing .blog-teaser {
  margin: 25px 0 0;
}

.common-blog-listing .slick-dots {
  bottom: auto;
  top: -50px;
  text-align: right;
  padding: 0 25px;
}

.common-blog-listing .slick-dots li {
  border-color: rgba(34, 34, 34, 0.5);
  transition: border-color 0.2s ease-in-out, background 0.2s ease-in-out;
}

.common-blog-listing .slick-dots li.slick-active {
  border-color: #99daf2;
  background: #99daf2;
}

@media (max-width: 768px) {
  .common-blog-listing:before,
  .common-blog-listing:after {
    display: none;
  }
}

.blog-more-container {
  float: right;
  width: 50%;
  text-align: center;
  padding: 0 0 0 162px;
}

@media (max-width: 800px) {
  .blog-more-container {
    float: none;
    width: auto;
    padding: 0;
    text-align: center;
  }
}

.blog-more-container a {
  position: relative;
  color: #818080;
  padding: 0 93px 0 0;
  font-size: 1em;
}

.section--contact-details.section--contact-details.section--contact-details.section--contact-details {
  font-size: 16px;
}

.section--contact-details {
  font-weight: 400;
  line-height: 1.5;
  padding: 11.44179894% 0 7.47354497%;
}

@media (min-width: 768px) {
  .section--contact-details {
    line-height: 1.84615385;
  }
}

.container--contact-details .section--contact-details {
  padding-top: 0;
}

@media (min-width: 768px) {
  .container--contact-details .section--contact-details {
    padding-top: 5.75396825%;
  }
}

.footer-main .section--contact-details {
  font-size: 1.375em;
}

.section--contact-details-inner {
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
}

.section--contact-details-inner > div {
  padding-right: 3.125em;
}

@media (min-width: 992px) {
  .section--contact-details-inner > div {
    min-width: 15.625em;
    width: 25%;
  }
}

@media (min-width: 680px) and (max-width: 1199px) {
  .section--contact-details-inner > div:nth-child(1) {
    width: 100%;
  }
}

@media (max-width: 530px) {
  .section--contact-details-inner > div {
    width: 100%;
  }
}

.section--contact-details-inner a {
  text-decoration: none;
  color: #222222;
}

.section--contact-details-inner a:hover,
.section--contact-details-inner a:focus,
.section--contact-details-inner a.on-press {
  color: #633a86;
}

.section--contact-details h2 {
  font-weight: 600;
  font-size: 1.125em;
  color: #0b0b0b;
  line-height: 2;
  margin-bottom: 0.25em;
}

@media (min-width: 768px) {
  .section--contact-details h2 {
    font-size: 1.375em;
  }
}

.section--contact-details a {
  opacity: 0.75;
}

.section--contact-details a svg path {
  fill: currentColor;
}

.section--contact-details .link--email,
.section--contact-details .link--call {
  display: inline-block;
  position: relative;
  padding-left: 35px;
}

.section--contact-details .link--email .link__icon,
.section--contact-details .link--call .link__icon {
  position: absolute;
  top: 50%;
  left: 0;
  width: 22px;
  height: 21px;
  transform: translateY(-50%);
}

.section--contact-details .link--email .link__text,
.section--contact-details .link--call .link__text {
  padding-top: 2px;
}

.section--contact-details .link--email svg,
.section--contact-details .link--call svg {
  width: inherit;
  height: inherit;
}

.section--contact-details .link--call .link__icon {
  transform: translateY(-55%);
}

.section--faces {
  overflow: hidden;
  padding-bottom: 60px;
}

@media (min-width: 768px) {
  .section--faces {
    padding-bottom: 7.4375%;
  }
}

@media (min-width: 1600px) {
  .section--faces {
    padding-bottom: 119px;
  }
}

.section--faces .row .row__inner {
  display: flex;
  width: calc(100vw + 25vw);
  margin-left: -12.5vw;
  margin-right: -12.5vw;
}

@media (min-width: 768px) {
  .section--faces .row .row__inner {
    width: calc(100vw + 16.666666666666667vw);
    margin-left: -8.33333333vw;
    margin-right: -8.33333333vw;
  }
}

.section--faces .row .row__inner .row__item {
  width: 20vw;
  text-align: center;
  padding: 0 8px;
  flex-grow: 1;
}

@media (min-width: 768px) {
  .section--faces .row .row__inner .row__item {
    padding: 0 30px;
    width: 20vw;
  }
}

.section--faces .row .row__inner .row__item svg {
  display: block;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .section--faces .row .row__inner .row__item:not(:nth-child(-n + 4)) {
    display: none;
  }
}

.section--humaans {
  color: #ffffff;
  padding: 8.75% 0 10.75%;
}

.no-js .section--humaans {
  background-color: #212022;
}

@media (min-width: 1600px) {
  .section--humaans {
    padding: 140px 0 172px;
  }
}

.section--logos {
  overflow: hidden;
  padding: 3.5625% 0 0;
}

@media (min-width: 1600px) {
  .section--logos {
    padding: 57px 0 0;
  }
}

.section--logos .section__intro {
  margin: 0 auto 8.11111111vw;
  text-align: center;
}

@media (min-width: 1800px) {
  .section--logos .section__intro {
    margin-bottom: 146px;
  }
}

.section--recognition {
  padding-top: 13.1875%;
  padding-bottom: 8.4375%;
}

@media (min-width: 1600px) {
  .section--recognition {
    padding-top: 211px;
    padding-bottom: 135px;
  }
}

.section--recognition .section__link {
  font-size: 0.90909091em;
  color: #80868c;
  text-decoration: none;
  padding: 0;
}

.section--recognition .section__link:hover,
.section--recognition .section__link:focus {
  color: #373a3c;
}

.section--recognition.light {
  color: #ffffff;
}

.section--recognition.light .block__image img {
  filter: invert(1);
}

.section--page-intro {
  position: relative;
  padding-bottom: 19px;
}

@media (min-width: 768px) {
  .section--page-intro {
    font-size: 1.36363636em;
  }
}

.section--services {
  color: #ffffff;
  transition: ease-in-out 0.5s color;
  padding: 9.375% 0;
  overflow: hidden;
}

@media (min-width: 1600px) {
  .section--services {
    padding: 150px 0;
  }
}

.section--services.dark {
  color: inherit;
}

* {
  box-sizing: border-box;
}

.section--subscribe {
  background: #222222;
  color: #fff;
  padding: 35px 0;
}

@media (min-width: 768px) {
  .section--subscribe {
    padding: 30px 0 37px;
  }
}

@media (min-width: 768px) {
  .section--subscribe .container.container--full {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .section--subscribe .subscribe__flex-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -30px;
  }

  .section--subscribe .subscribe__flex-container > div {
    padding-left: 30px;
    width: 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .section--subscribe .subscribe__flex-container > div {
    width: auto;
  }
}

@media (min-width: 768px) {
  .section--subscribe .subscribe__flex-container > div:first-child {
    flex-grow: 1;
  }
}

.section--subscribe .subscribe-social-links {
  margin: 11px 0 0;
  font-size: 0.77272727em;
  text-align: center;
}

@media (min-width: 768px) {
  .section--subscribe .subscribe-social-links {
    text-align: right;
  }
}

.section--subscribe .subscribe-social-links a {
  display: inline-block;
  padding: 0 0.75vw;
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}

@media (min-width: 1600px) {
  .section--subscribe .subscribe-social-links a {
    padding: 0 12px;
  }
}

.section--subscribe .subscribe-social-links a:hover,
.section--subscribe .subscribe-social-links a:focus,
.section--subscribe .subscribe-social-links a.on-press {
  color: #fff;
}

.section--team-gallery {
  padding: 7.3125% 0 0;
}

@media (min-width: 1600px) {
  .section--team-gallery {
    padding: 117px 0 0;
  }
}

.section--team-gallery .row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.section--team-gallery .row .col {
  width: 100%;
}

.section--team-gallery .row .col img {
  width: 100%;
  height: auto;
  display: block;
}

.section--team-gallery .row .col.col--aspect-full {
  position: relative;
}

.section--team-gallery .row .col.col--aspect-full img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section--team-gallery .row .col.col--aspect-100 {
  position: relative;
}

.section--team-gallery .row .col.col--aspect-100:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.section--team-gallery .row .col.col--aspect-100 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section--team-gallery .row .col.col-50 {
  width: 50%;
}

.section--team-gallery .row .col.col-33 {
  width: 33.33%;
}

.section--testimonials {
  overflow: hidden;
  padding: 10.5625% 0 9.8125% 0;
  transition: ease-in-out 0.5s color;
}

.section--testimonials.light {
  color: #ffffff;
}

.section--testimonials.light .decoration--quote path {
  fill: #fff;
  opacity: 0.5;
}

@media (min-width: 1600px) {
  .section--testimonials {
    padding: 169px 0 157px;
  }
}

.section--testimonials .decoration--quote {
  transition: ease-in-out 0.5s fill, ease-in-out 0.5s opacity;
  display: block;
  margin: 0 auto 6.953125%;
  width: 1.36363636em;
  height: 1.02136364em;
}

.section--testimonials .slider__container {
  position: relative;
}

.section--support-content {
  text-align: center;
  margin: 10rem 0 6rem;
}

@media (min-width: 768px) {
  .section--support-content {
    margin: 10rem 0;
  }
}

.section--support-content .title {
  color: #1a1b1d;
  line-height: 1.08;
  font-size: 2.27272727em;
  font-weight: 600;
}

.section--support-content .description {
  font-size: 0.90909091em;
  font-weight: 400;
  color: #404347;
}

@media (max-width: 767px) {
  .section--support-columns {
    margin-bottom: 2rem;
  }
}

.section--support-columns__inner {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
}

@media (min-width: 768px) {
  .section--support-columns__inner {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .section--support-columns__inner {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1535px) {
  .section--support-columns__inner {
    column-gap: 13rem;
  }
}

.section--support-columns .column {
  display: grid;
  grid-template-areas: "icon" "title" "content";
  grid-template-rows: 80px auto 1fr;
  color: #1a1b1d;
  font-size: 0.90909091em;
  font-weight: 400;
}

@media (max-width: 767px) {
  .section--support-columns .column {
    grid-template-areas: "icon title" "content content";
    grid-template-columns: 60px 1fr;
    grid-template-rows: auto 1fr;
    align-items: center;
    gap: 2rem 1rem;
  }
}

.section--support-columns .title {
  grid-area: title;
  font-size: 1.13636364em;
  font-weight: 500;
  margin: 0;
}

@media (min-width: 768px) {
  .section--support-columns .title {
    margin-bottom: 4.3rem;
  }
}

.section--support-columns .icon {
  grid-area: icon;
  position: relative;
}

.section--support-columns .icon:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 73.17073171%;
}

@media (min-width: 768px) {
  .section--support-columns .icon {
    margin-bottom: 2rem;
  }
}

.section--support-columns .icon img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: auto;
  object-position: center center;
  object-fit: contain;
}

.section--support-columns .content {
  grid-area: content;
}

.section--logo-showcase {
  text-align: center;
  padding: 5% 0 1.94444444%;
  font-weight: 400;
  color: #404347;
  transition: ease-in-out 0.2s color;
  letter-spacing: 0;
  line-height: 1.53846154;
}

@media (min-width: 768px) {
  .section--logo-showcase {
    font-size: 2.6rem;
  }
}

@media (min-width: 1800px) {
  .section--logo-showcase {
    padding: 185px 0 35px;
  }
}

.section--logo-showcase p:last-child {
  margin: 0;
}

.section--logo-showcase .grid--logo-showcase {
  margin: 7.56302521% 0;
}

.section--logo-showcase-sm {
  line-height: 1.5;
}

@media (min-width: 768px) {
  .section--logo-showcase-sm {
    font-size: 0.90909091em;
  }
}

.section--logo-showcase-sm .container--sm {
  max-width: 627px;
}

.section--logo-showcase-sm .grid--logo-showcase {
  margin: 18px 0;
  margin-bottom: 0;
}

.section--showcase {
  margin: 33.125vw 0;
}

@media (min-width: 1600px) {
  .section--showcase {
    margin: 530px 0;
  }
}

.section--showcase-spacing-sm {
  margin: 12.5vw 0;
}

@media (min-width: 1600px) {
  .section--showcase-spacing-sm {
    margin: 200px 0;
  }
}

.section--showcase-spacing-sm.section--showcase-last {
  margin-bottom: 12.5vw;
}

@media (min-width: 1600px) {
  .section--showcase-spacing-sm.section--showcase-last {
    margin-bottom: 200px;
  }
}

.section--showcase-spacing-sm:not(.js-waypoint-active) {
  color: var(--inactive-color) !important;
}

.section--showcase-spacing-sm:not(.js-waypoint-active) .case-study__50-50 {
  color: var(--inactive-color) !important;
}

.section--showcase-1 {
  margin-top: 9.375vw;
}

@media (min-width: 1600px) {
  .section--showcase-1 {
    margin-top: 150px;
  }
}

.section--showcase-last {
  margin-bottom: 22.75vw;
}

@media (min-width: 1600px) {
  .section--showcase-last {
    margin-bottom: 364px;
  }
}

.section--showcase .case-study__50-50 .block__content {
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .section--showcase .case-study__50-50 .block__content {
    font-size: 2rem;
  }
}

.section--showcase .case-study__50-50 .block__content-inner {
  max-width: 600px;
}

.section--showcase .case-study__50-50 .block__content p {
  margin-bottom: 1.5em;
}

.section--showcase .case-study__50-50 .block__content .block__buttons {
  margin-top: 2.25em;
}

.section--showcase .case-study__50-50 .block__content .block__title {
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.29411765;
  margin-bottom: 0.76470588em;
  font-size: 2.3rem;
}

@media (min-width: 768px) {
  .section--showcase .case-study__50-50 .block__content .block__title {
    font-size: 3.4rem;
  }
}

.section--showcase .showcase__quote {
  margin-top: 12.94444444vw;
}

@media (min-width: 768px) {
  .section--showcase .showcase__quote {
    display: flex;
  }
}

@media (min-width: 1800px) {
  .section--showcase .showcase__quote {
    margin-top: 233px;
  }
}

.section--showcase .showcase__quote blockquote {
  font-weight: 400;
  color: inherit;
  line-height: 1.35;
  margin: 20px 0 0 0;
  font-size: 2.1rem;
}

@media (min-width: 768px) {
  .section--showcase .showcase__quote blockquote {
    font-size: 3rem;
    margin: 0 0 0 1.6em;
  }
}

.section--showcase .showcase__quote blockquote p {
  margin-bottom: 0.86666667em;
}

.section--showcase .showcase__quote blockquote p:first-child:before {
  content: "\201C";
}

.section--showcase .showcase__quote blockquote p:last-child:not(:first-child) {
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.25;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .section--showcase .showcase__quote blockquote p:last-child:not(:first-child) {
    font-size: 0.53333333em;
  }
}

.section--showcase .showcase__quote--alt {
  margin-top: 11.11111111vw;
  text-align: center;
}

@media (min-width: 768px) {
  .section--showcase .showcase__quote--alt {
    display: flex;
  }
}

@media (min-width: 1800px) {
  .section--showcase .showcase__quote--alt {
    margin-top: 200px;
  }
}

.section--showcase .showcase__quote--alt blockquote {
  letter-spacing: 0;
  line-height: 1.35;
  margin: 0;
}

@media (min-width: 768px) {
  .section--showcase .showcase__quote--alt blockquote {
    font-size: 3rem;
  }
}

.section--showcase .showcase__quote--alt blockquote:before {
  content: "\201C";
  font-size: 2.66666667em;
  line-height: 0.50625;
  display: block;
  margin-bottom: 0.05em;
}

.section--showcase .showcase__quote--alt blockquote .blockquote__cite {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.25;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.8125em;
}

@media (min-width: 768px) {
  .section--showcase .showcase__quote--alt blockquote .blockquote__cite {
    font-size: 0.53333333em;
  }
}

.section--showcase .showcase__quote--alt blockquote .blockquote__cite .quote__face-wrap {
  margin-right: 1.3125em;
  max-width: 3.75em;
}

.section--showcase .showcase__quote--alt blockquote .blockquote__cite .quote__face-wrap .quote__face {
  margin-top: 0;
}

.section--showcase .showcase__quote--alt blockquote .blockquote__cite .quote__image {
  width: 3.75em;
  position: relative;
  margin-right: 1.3125em;
}

.section--showcase .showcase__quote--alt blockquote .blockquote__cite .quote__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.section--showcase .showcase__quote--alt blockquote .blockquote__cite .quote__image img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-position: center center;
  object-fit: cover;
  border-radius: 100%;
}

.quote__face {
  padding: 18%;
  width: 100%;
  margin-top: 5px;
  display: block;
}

.quote__face-wrap {
  max-width: 100px;
  flex-shrink: 0;
  width: 20%;
  display: block;
}

.quote__face svg {
  display: block;
  width: 100%;
  height: auto;
}

.quote__face.quote__face--color-purple path {
  fill: #633a86;
}

.quote__face.quote__face--color-pink path {
  fill: #ffd1d1;
}

.quote__face.quote__face--color-red path {
  fill: #f05644;
}

.quote__face.quote__face--color-blue path {
  fill: #99daf2;
}

.quote__face.quote__face--color-lavender path {
  fill: #e2d1ff;
}

.quote__face.quote__face--color-yellow path {
  fill: #ffc938;
}

.quote__face.quote__face--bg-purple {
  background: #633a86;
}

.quote__face.quote__face--bg-pink {
  background: #ffd1d1;
}

.quote__face.quote__face--bg-red {
  background: #f05644;
}

.quote__face.quote__face--bg-blue {
  background: #99daf2;
}

.quote__face.quote__face--bg-lavender {
  background: #e2d1ff;
}

.quote__face.quote__face--bg-yellow {
  background: #ffc938;
}

.svg-awarded {
  display: block;
  font-size: 3.8rem;
  height: 1em;
  width: 8.03571429em;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .svg-awarded {
    font-size: 5.6rem;
  }
}

.section--work {
  padding-bottom: 12.5%;
}

@media (min-width: 1600px) {
  .section--work {
    padding-bottom: 200px;
  }
}

.section--work .work__panel {
  outline: none;
}

.section--work .work__panel:not(.work__panel--active) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px !important;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

.blog-hero-thumb {
  background: #f7f7f7;
  position: relative;
}

.blog-hero-thumb img {
  width: 100%;
  display: block;
  height: 100%;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
}

.blog-item-content {
  max-width: 760px;
  padding: 0 30px;
  margin: 68px auto 0;
}

.blog-item-content h1 {
  font-size: 1.72727273em;
  line-height: 1.2;
  color: #222222;
  margin: 0 0 22px;
}

.feature-blog-post h2 {
  margin: 0 0 22px;
  line-height: 1.2;
  color: #222222;
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  font-weight: 600;
  font-size: 1.72727273em;
}

h2 a {
  color: #222222;
  text-decoration: none;
}

.blog-meta {
  font-weight: 600;
}

.blog-meta-item {
  display: inline;
  margin: 0 15px 0 0;
  line-height: 3.18181818em;
  font-size: 0.63636364em;
}

.blog-meta-item a {
  color: #222222;
  text-decoration: none;
}

.blog-meta-item a:hover,
.blog-meta-item a:focus,
.blog-meta-item a.on-press {
  color: #633a86;
}

.blog-teaser {
  font-size: 0.81818182em;
  margin: 25px 0 0;
}

.blog-teaser a {
  text-decoration: none;
  color: #222222;
  font-weight: 500;
}

.blog-teaser a:hover,
.blog-teaser a:focus,
.blog-teaser a.on-press {
  color: #99daf2;
}

.blog-aux a {
  color: #a4a4a4;
}

.blog-profile {
  float: left;
  background: #a4a4a4;
  width: 44px;
  height: 44px;
  margin: 0 15px 0 0;
  border-radius: 100%;
  background-size: contain;
}

.blog-content {
  margin: 30px 0 0;
  font-size: 0.90909091em;
  position: relative;
}

.blog-content h2 {
  font-size: 1.36363636em;
}

.blog-content-cap {
  font-size: 0.81818182em;
  font-weight: 500;
}

.panel-pre-blog-list {
  padding: 130px 0 40px;
  margin: 0 0 -40px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 830px) {
  .panel-pre-blog-list {
    margin-bottom: -70px;
  }
}

@media screen and (max-width: 1000px) {
  .panel-pre-blog-list {
    margin-bottom: -100px;
  }
}

@media screen and (max-width: 600px) {
  .panel-pre-blog-list {
    padding: 100px 0 30px;
  }
}

.panel-pre-blog-list .feature-blog-post {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.panel-pre-blog-list h1 {
  text-align: center;
  font-size: 1.11111111em;
}

@media (min-width: 768px) {
  .panel-pre-blog-list h1 {
    font-size: 1.81818182em;
  }
}

.blog-welcome {
  position: absolute;
  top: -4px;
  left: 155px;
  color: #a4a4a4;
  z-index: 150;
  font-size: 0.81818182em;
}

@media (max-width: 1100px) {
  .blog-welcome {
    display: none;
  }
}

.blog-filters {
  flex-grow: 1;
  font-size: 0.94444444em;
  line-height: 1.41666667em;
}

@media (min-width: 768px) {
  .blog-filters {
    text-align: center;
    font-size: 0.77272727em;
  }
}

.blog-filter-item {
  position: relative;
  display: inline-block;
  padding: 0 25px;
}

@media (max-width: 1100px) {
  .blog-filter-item {
    padding: 0 25px 0 0;
  }
}

.blog-filter-item + .blog-filter-item {
  margin-left: 30px;
}

@media (max-width: 750px) {
  .filter-categories {
    display: none;
  }
}

.filter-categories .categories-label {
  display: block;
  position: relative;
  z-index: 100;
  cursor: pointer;
  transition: color 0.2s linear;
  padding-right: 23px;
  color: #222222;
}

.filter-categories .categories-label:after {
  content: "";
  display: block;
  position: absolute;
  right: 6px;
  top: 4px;
  width: 7px;
  height: 7px;
  border: 1px solid #000;
  border-width: 0 0 1px 1px;
  transform: rotate(-45deg);
  /* transition: transform 0.2s linear, top 0.2s linear; */
}

@media (prefers-reduced-motion) {
  .filter-categories .categories-label:after {
    /* transition: none; */
  }
}

.filter-categories .categories-label.is-open:after {
  top: 8px;
  /* transform: rotate(135deg); */
}

.categories-options {
  display: none;
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 0%;
  width: 160px;
  text-align: center;
  left: 50%;
  margin: -16px 0 0 -80px;
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1100px) {
  .categories-options {
    width: 150px;
    margin-left: -75px;
    font-size: 0.63636364em;
  }
}

.categories-options ul {
  margin: 0;
  padding: 50px 0 10px;
  list-style: none;
}

.categories-options li.category--active > a {
  background: #f05644;
  color: #fff;
}

.categories-options a {
  display: block;
  text-decoration: none;
  color: #222222;
  padding: 4px 0;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
  cursor: pointer;
  border-top: 1px solid rgba(34, 34, 34, 0.1);
}

.categories-options a:hover,
.categories-options a:focus,
.categories-options a.on-press {
  background: #f05644;
  color: #fff;
}

.blog-item {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .blog-item {
    padding-bottom: 100px;
  }
}

.panel--first-post + .section--recommended-articles {
  margin-top: clamp(4rem, 9.02777778vw, 13rem);
}

.feature-blog-post {
  margin-bottom: clamp(4rem, 9.02777778vw, 13rem);
}

@media (min-width: 768px) {
  .feature-blog-post {
    border-bottom: 1px solid #ebebeb;
  }
}

.panel--first-post .feature-blog-post,
.section--recommended-articles .feature-blog-post:last-child {
  margin-bottom: 0;
}

.feature-blog-post.individual-blog-post {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .feature-blog-post.individual-blog-post {
    margin-bottom: 0;
    border-bottom: none;
  }
}

@media (max-width: 900px) {
  .filter-search {
    display: none;
  }
}

.filter-search input {
  width: 90px;
  margin: 0 -24px 0 0;
  padding: 0 0 5px;
  padding-right: 24px;
  border: none;
  border-bottom: 2px solid transparent;
  background: transparent;
  outline: none;
  color: #222222;
  transition: border-color 0.2s ease-in-out;
}

.filter-search input:focus {
  border-color: #f05644;
}

.filter-search button[type="submit"] {
  padding: 0;
}

.filter-search button[type="submit"] svg {
  width: 19px;
  height: 19px;
  display: block;
}

.filter-search button[type="submit"] svg path {
  transition: fill 0.2s ease-in-out;
}

.filter-search button[type="submit"]:hover svg path,
.filter-search button[type="submit"]:focus svg path,
.filter-search button[type="submit"].on-press svg path {
  fill: #f05644;
}

.blog-content-inner iframe,
.blog-content-inner twitter-widget {
  margin: 40px auto !important;
}

.blog-content-inner iframe {
  max-width: 100%;
}

.blog-content-inner p iframe {
  margin-bottom: 0 !important;
}

iframe .blog-content-inner h2,
iframe .blog-content-inner h3,
iframe .blog-content-inner h4,
iframe .blog-content-inner h5 {
  margin-top: 20px !important;
}

.blog-content-inner strong {
  font-weight: 600;
}

.blog-content-inner blockquote {
  margin: 50px 0;
  padding: 0 0 0 20px;
  border-left: 8px solid #e6e5e5;
}

@media (min-width: 1800px) {
  .blog-content-inner blockquote {
    padding-left: 40px;
  }
}

.entry-content-asset--video {
  position: relative;
  margin: 40px 0;
  width: 100%;
}

.entry-content-asset--video:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 56.25%;
}

.entry-content-asset--video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 !important;
}

.error404 .panel {
  display: flex;
  align-items: center;
  height: 100vh;
  text-align: center;
  overflow: auto;
}

.panel-copy {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.panel-copy::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  .panel-copy {
    padding-right: 50%;
  }

  .panel-copy::after {
    display: none;
  }
}

.panel-copy .inner {
  max-width: 80%;
  z-index: 1;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .panel-copy .inner {
    max-width: 500px;
    padding: 120px 40px 100px;
  }
}

.panel-copy .inner h2 {
  /* font-family: "MaisonNeue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; */
  font-weight: 900;
  margin: 60px 0 30px;
}

.panel-copy .inner p {
  font-size: 0.88888889em;
}

.panel-copy .inner svg {
  margin: 0 0 30px 0;
}

.panel-copy .inner svg path {
  fill: #633a86;
}

.panel-video {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .panel-video {
    width: 50%;
  }
}

.panel-video video {
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 1;
}

.template-christmas .panel-christmas .christmas-card {
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .template-christmas .panel-christmas .christmas-card {
    display: flex;
    padding-bottom: 18.88111888%;
  }
}

@media (min-width: 1001px) {
  .template-christmas .panel-christmas .christmas-card {
    padding-bottom: 189px;
  }
}

.template-christmas .panel-christmas .christmas-card .card__title {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .template-christmas .panel-christmas .christmas-card .card__title {
    width: 67px;
    padding-right: 10px;
    margin-bottom: 0;
  }
}

.template-christmas .panel-christmas .christmas-card .card__title .card__title-inner {
  position: relative;
}

.template-christmas .panel-christmas .christmas-card .card__title .card__title-inner:after {
  display: block;
  padding-bottom: 100%;
}

@media (min-width: 768px) {
  .template-christmas .panel-christmas .christmas-card .card__title .card__title-inner:after {
    content: "";
  }
}

.template-christmas .panel-christmas .christmas-card .card__title .card__title-inner h2 {
  font-size: 24px;
}

@media screen and (min-width: 768px) {
  .template-christmas .panel-christmas .christmas-card .card__title .card__title-inner h2 {
    font-size: calc( 24px + 16 * (100vw - 768px) / 432);
  }
}

@media screen and (min-width: 1200px) {
  .template-christmas .panel-christmas .christmas-card .card__title .card__title-inner h2 {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .template-christmas .panel-christmas .christmas-card .card__title .card__title-inner h2 {
    position: absolute;
    transform: rotate(-90deg) translateY(-50%) translateX(-50%);
    transform-origin: 8px 9px;
    top: 50%;
    left: 50%;
  }
}

@media (min-width: 768px) {
  .template-christmas .panel-christmas .christmas-card .card__body {
    width: calc(100% - 67px);
  }
}

.template-christmas .panel-christmas .christmas-card .card__body .card__content {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  .template-christmas .panel-christmas .christmas-card .card__body .card__content {
    max-width: 75%;
  }
}

.template-christmas .panel-christmas .christmas-card .card__body .card__content a {
  text-decoration: underline;
}

.template-christmas .panel-christmas .card__gallery {
  margin-left: -10px;
  margin-bottom: 3.7962038%;
}

.template-christmas .panel-christmas .card__gallery:after {
  content: "";
  clear: both;
  display: table;
}

@media (min-width: 768px) {
  .template-christmas .panel-christmas .card__gallery {
    margin-left: -25px;
  }
}

.template-christmas .panel-christmas .card__gallery .gallery__image {
  float: left;
  width: 33.33%;
  padding-left: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .template-christmas .panel-christmas .card__gallery .gallery__image {
    padding-left: 25px;
    margin-bottom: 25px;
  }
}

.template-christmas .panel-christmas .card__gallery .gallery__image img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
}

.template-christmas .panel-christmas .card__gallery .gallery__image .gallery__image-bg {
  display: block;
  background-image: inherit;
  background-size: 120%;
  background-position: center center;
  background-repeat: no-repeat;
}

.template-christmas .panel-christmas .card__gallery .gallery__image .gallery__image-bg:after {
  content: "";
  display: block;
  /* padding-bottom: 78.16455696%; */
}

.template-christmas .panel-christmas .card__gallery .gallery__image:first-child {
  width: 100%;
}

.template-christmas .panel-christmas .card__gallery .gallery__image:first-child .gallery__image-bg {
  background-size: 105%;
}

.template-christmas .panel-christmas .card__gallery .gallery__image:first-child .gallery__image-bg:after {
  /* padding-bottom: 70.12987013%; */
}

.template-christmas .panel-christmas .card__gallery.card__gallery--portrait .gallery__image:first-child {
  width: 66.66%;
}

.template-christmas .panel-christmas .card__gallery.card__gallery--portrait .gallery__image:first-child .gallery__image-bg:after {
  /* padding-bottom: 142.72588055%; */
}

.template-christmas .panel-christmas .card__gallery.card__gallery--portrait .gallery__image .gallery__image-bg:after {
  /* padding-bottom: 93.67088608%; */
}

.page-template-template-logos .logo-grid {
  display: none;
  align-items: center;
}

@media (min-width: 768px) {
  .page-template-template-logos .logo-grid {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -30px;
  }

  .page-template-template-logos .logo-grid > div {
    padding-left: 30px;
    width: 33.33333333%;
    margin-bottom: 132px;
  }
}

.page-template-template-logos .logo-grid .logo-grid__item {
  text-align: center;
}

.page-template-template-logos .logo-grid .logo-grid__item img {
  max-width: 170px;
}

.page-template-template-logos .panels {
  border-top: 1px solid #eee;
}

.page-template-template-logos .panels .panels__item {
  padding-top: 75px;
  padding-bottom: 75px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 0.72727273em;
  background-size: cover;
  background-position: center center;
}

.page-template-template-logos .panels .panels__item .logo-image {
  margin: 0 auto 30px;
}

@media (min-width: 768px) {
  .page-template-template-logos .panels .panels__item .logo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translateY(-50%) translateX(-50%); */
    width: 100%;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .page-template-template-logos .panels .panels__item {
    min-height: 90vh;
  }
}

.page-template-template-logos .panels .container {
  text-align: center;
}

@media (min-width: 768px) {
  .page-template-template-logos .panels .container {
    text-align: left;
  }
}

.page-template-template-logos .panels .container--light {
  color: #ffffff;
}

.page-template-template-logos .panels .panels__item--background-right {
  background-repeat: no-repeat;
  background-position: right;
  background-size: 18.14286% auto;
}

@media (min-width: 768px) {
  .page-template-template-logos .panels .panels__item--background-right {
    background-size: contain;
  }
}




.header-aside {
    /* Your default styles here */
    position: fixed;
    top: 0;
    right: -600px; /* Start off-screen */
    width: 300px;
    height: 100%;
    /* background-color: #633A86; */
    /* transition: right 0.3s ease-in-out; */
  }
  
  .header-aside.show-aside {
    right: -200px;
     /* Show the aside */
  }
  .logo {
    position: relative;
    text-decoration: none;
  }
  
  .logo .hovered {
    display: none;
    position: absolute;
    /* top: 0;
    left: 0; */
    opacity: 0;
   
  }
  
  .logo:hover .initial {
    opacity: 0;
  }
  
  .logo:hover .hovered {
    display: block;
    opacity: 1;
  }
  .nailoo{
    width: 6rem;
  }

  /* completd almoost except alignmet */


  .menu-item:focus{

  }
  .nailoo:hover{
    /* background-color: red; */
  }

  .show-nav{
    background-color: red;
    /* position: absolute; */
    top: 50px;
  }


  /* .header-aside:hover{
    .header-main{
      background-color: yellow;
      top:900px

    }
  } */

  .header-hovered {
    background-color: yellow;
    position: fixed;
    top:0;
    height: 3rem;
  }
  