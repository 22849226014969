@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Oswald:wght@100;200;300;400;500;600;700;800;900&display=swap");






.newloaderrr .loader-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  padding-right: 50px;
  padding-bottom: 50px;
  justify-content: flex-end;
  align-items: flex-end;
  /* background-color: var(--loader-color); */
  background-color: black;
}

.newloaderrr .loader-container .counter-container {
  overflow: hidden;
  height: 30vw;
  min-height: 30vw;
}

.newloaderrr .loader-container h3 {
  z-index: 9999;
  margin-top: 0px;
  margin-bottom: 1vw;
  color: var(--secondary-color);
  font-size: 30vw;
  line-height: 30vw;
  font-weight: 300;
}

.newloaderrr .hero-container {
  position: relative;
  height: 100vh;
}

.newloaderrr .hero-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newloaderrr .hero-container .hero-title {
  position: absolute;
  bottom: 5vw;
  left: 5vw;
  font-size: clamp(72px, 15vw, 15vw);
  color: white;
  font-family: "Oswald", sans-serif;
  text-transform: lowercase;
  font-weight: 100;
}

