html {
    /* font-family: sans-serif; */
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden],
template {
    display: none
}

a:active,
a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
optgroup,
strong {
    font-weight: 700
}

dfn {
    font-style: italic
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

img {
    border: 0;
    vertical-align: middle;
    display: inline-block;
    max-width: 100%
}

svg:not(:root) {
    overflow: hidden
}

hr {
    box-sizing: content-box;
    height: 0
}

pre,
textarea {
    overflow: auto
}

code,
kbd,
pre,
samp {
    /* font-family: monospace, monospace; */
    font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
html input[type=button],
input[type=reset] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],
html input[disabled] {
    cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input {
    line-height: normal
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    height: auto
}

input[type=search] {
    -webkit-appearance: none
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

legend {
    border: 0;
    padding: 0
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td,
th {
    padding: 0
}



[class*=" w-icon-"],
[class^=w-icon-] {
    /* font-family: webflow-icons !important; */
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.w-icon-slider-right:before {
    content: "\e600"
}

.w-icon-slider-left:before {
    content: "\e601"
}

.w-icon-nav-menu:before {
    content: "\e602"
}

.w-icon-arrow-down:before,
.w-icon-dropdown-toggle:before {
    content: "\e603"
}

.w-icon-file-upload-remove:before {
    content: "\e900"
}

.w-icon-file-upload-icon:before {
    content: "\e903"
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

body {
    margin: 0;
    min-height: 100%;
    background-color: #fff;
    /* font-family: Arial, sans-serif;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    color: #1d1d1d;
    font-size: 1rem;
    line-height: 1.5
}

html.w-mod-touch * {
    background-attachment: scroll !important
}

.w-block {
    display: block
}

.w-inline-block {
    max-width: 100%;
    display: inline-block
}

.w-clearfix:after,
.w-clearfix:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-clearfix:after {
    clear: both
}

.w-hidden {
    display: none
}

.w-button {
    display: inline-block;
    padding: 9px 15px;
    background-color: #3898ec;
    color: #fff;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0
}

input.w-button {
    -webkit-appearance: button
}

html[data-w-dynpage] [data-w-cloak] {
    color: transparent !important
}

.w-webflow-badge,
.w-webflow-badge * {
    position: static;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    z-index: auto;
    display: block;
    visibility: visible;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    box-sizing: border-box;
    width: auto;
    height: auto;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    margin: 0;
    padding: 0;
    float: none;
    clear: none;
    border: 0 transparent;
    border-radius: 0;
    background: 0 0;
    box-shadow: none;
    opacity: 1;
    transform: none;
    transition: none;
    direction: ltr;
    /* font-family: inherit; */
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
    font-variant: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    text-decoration: inherit;
    text-indent: 0;
    text-transform: inherit;
    list-style-type: disc;
    text-shadow: none;
    font-smoothing: auto;
    vertical-align: baseline;
    cursor: inherit;
    white-space: inherit;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal
}

.w-webflow-badge {
    position: fixed !important;
    display: inline-block !important;
    visibility: visible !important;
    z-index: 2147483647 !important;
    top: auto !important;
    right: 12px !important;
    bottom: 12px !important;
    left: auto !important;
    color: #aaadb0 !important;
    background-color: #fff !important;
    border-radius: 3px !important;
    padding: 6px 8px 6px 6px !important;
    font-size: 12px !important;
    opacity: 1 !important;
    line-height: 14px !important;
    text-decoration: none !important;
    transform: none !important;
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    white-space: nowrap;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .1);
    cursor: pointer
}

.w-webflow-badge>img {
    display: inline-block !important;
    visibility: visible !important;
    opacity: 1 !important;
    vertical-align: middle !important
}

figure {
    margin: 3rem 0
}

.w-list-unstyled {
    padding-left: 0;
    list-style: none
}

.w-embed:after,
.w-embed:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-embed:after {
    clear: both
}

.w-video {
    width: 100%;
    position: relative;
    padding: 0
}

.w-video embed,
.w-video iframe,
.w-video object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0
}

[type=button],
[type=reset],
button {
    border: 0;
    cursor: pointer;
    -webkit-appearance: button
}

.w-form {
    margin: 0 0 15px
}

.w-form-done {
    display: none;
    padding: 20px;
    text-align: center;
    background-color: #ddd
}

.w-form-fail {
    display: none;
    margin-top: 10px;
    padding: 10px;
    background-color: #ffdede
}

.w-input,
.w-select {
    display: block;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc
}

.w-input:-moz-placeholder,
.w-select:-moz-placeholder {
    color: #999
}

.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
    color: #999;
    opacity: 1
}

.w-input:-ms-input-placeholder,
.w-select:-ms-input-placeholder {
    color: #999
}

.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
    color: #999
}

.w-input:focus,
.w-select:focus {
    border-color: #3898ec;
    outline: 0
}

.w-input[disabled],
.w-input[readonly],
.w-select[disabled],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
    cursor: not-allowed
}

.w-input[disabled]:not(.w-input-disabled),
.w-input[readonly],
.w-select[disabled]:not(.w-input-disabled),
.w-select[readonly],
fieldset[disabled]:not(.w-input-disabled) .w-input,
fieldset[disabled]:not(.w-input-disabled) .w-select {
    background-color: #eee
}

textarea.w-input,
textarea.w-select {
    height: auto
}

.w-select {
    background-color: #f3f3f3
}

.w-select[multiple] {
    height: auto
}

.w-form-label {
    display: inline-block;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0
}

.w-radio {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px
}

.w-radio:after,
.w-radio:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-radio:after {
    clear: both
}

.w-radio-input {
    margin: 3px 0 0 -20px;
    line-height: normal;
    float: left
}

.w-file-upload {
    display: block;
    margin-bottom: 10px
}

.w-file-upload-input {
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -100
}

.w-file-upload-default,
.w-file-upload-success,
.w-file-upload-uploading {
    display: inline-block;
    color: #333
}

.w-file-upload-error {
    display: block;
    margin-top: 10px
}

.w-file-upload-default.w-hidden,
.w-file-upload-error.w-hidden,
.w-file-upload-success.w-hidden,
.w-file-upload-uploading.w-hidden {
    display: none
}

.w-file-upload-uploading-btn {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    margin: 0;
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: #fafafa
}

.w-file-upload-file {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    margin: 0;
    padding: 8px 9px 8px 11px;
    border: 1px solid #ccc;
    background-color: #fafafa
}

.w-file-upload-file-name {
    font-size: 14px;
    font-weight: 400;
    display: block
}

.w-file-remove-link {
    margin-top: 3px;
    margin-left: 10px;
    width: auto;
    height: auto;
    padding: 3px;
    display: block;
    cursor: pointer
}

.w-icon-file-upload-remove {
    margin: auto;
    font-size: 10px
}

.w-file-upload-error-msg {
    display: inline-block;
    color: #ea384c;
    padding: 2px 0
}

.w-file-upload-info {
    display: inline-block;
    line-height: 38px;
    padding: 0 12px
}

.w-file-upload-label {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    margin: 0;
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: #fafafa
}

.w-icon-file-upload-icon,
.w-icon-file-upload-uploading {
    display: inline-block;
    margin-right: 8px;
    width: 20px
}

.w-icon-file-upload-uploading {
    height: 20px
}

.w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px
}

.w-container:after,
.w-container:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-container:after {
    clear: both
}

.w-container .w-row {
    margin-left: -10px;
    margin-right: -10px
}

.w-row:after,
.w-row:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-row:after {
    clear: both
}

.w-row .w-row {
    margin-left: 0;
    margin-right: 0
}

.w-col {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px
}

.w-col .w-col {
    padding-left: 0;
    padding-right: 0
}

.w-col-1 {
    width: 8.33333333%
}

.w-col-2 {
    width: 16.66666667%
}

.w-col-3 {
    width: 25%
}

.w-col-4 {
    width: 33.33333333%
}

.w-col-5 {
    width: 41.66666667%
}

.w-col-6 {
    width: 50%
}

.w-col-7 {
    width: 58.33333333%
}

.w-col-8 {
    width: 66.66666667%
}

.w-col-9 {
    width: 75%
}

.w-col-10 {
    width: 83.33333333%
}

.w-col-11 {
    width: 91.66666667%
}

.w-col-12 {
    width: 100%
}

.w-hidden-main {
    display: none !important
}

@media screen and (max-width:991px) {
    .w-container {
        max-width: 728px
    }
    .w-hidden-main {
        display: inherit !important
    }
    .w-hidden-medium {
        display: none !important
    }
    .w-col-medium-1 {
        width: 8.33333333%
    }
    .w-col-medium-2 {
        width: 16.66666667%
    }
    .w-col-medium-3 {
        width: 25%
    }
    .w-col-medium-4 {
        width: 33.33333333%
    }
    .w-col-medium-5 {
        width: 41.66666667%
    }
    .w-col-medium-6 {
        width: 50%
    }
    .w-col-medium-7 {
        width: 58.33333333%
    }
    .w-col-medium-8 {
        width: 66.66666667%
    }
    .w-col-medium-9 {
        width: 75%
    }
    .w-col-medium-10 {
        width: 83.33333333%
    }
    .w-col-medium-11 {
        width: 91.66666667%
    }
    .w-col-medium-12 {
        width: 100%
    }
    .w-col-stack {
        width: 100%;
        left: auto;
        right: auto
    }
}

@media screen and (max-width:767px) {
    .w-hidden-main,
    .w-hidden-medium {
        display: inherit !important
    }
    .w-hidden-small {
        display: none !important
    }
    .w-container .w-row,
    .w-row {
        margin-left: 0;
        margin-right: 0
    }
    .w-col {
        width: 100%;
        left: auto;
        right: auto
    }
    .w-col-small-1 {
        width: 8.33333333%
    }
    .w-col-small-2 {
        width: 16.66666667%
    }
    .w-col-small-3 {
        width: 25%
    }
    .w-col-small-4 {
        width: 33.33333333%
    }
    .w-col-small-5 {
        width: 41.66666667%
    }
    .w-col-small-6 {
        width: 50%
    }
    .w-col-small-7 {
        width: 58.33333333%
    }
    .w-col-small-8 {
        width: 66.66666667%
    }
    .w-col-small-9 {
        width: 75%
    }
    .w-col-small-10 {
        width: 83.33333333%
    }
    .w-col-small-11 {
        width: 91.66666667%
    }
    .w-col-small-12 {
        width: 100%
    }
}

@media screen and (max-width:479px) {
    .w-container {
        max-width: none
    }
    .w-hidden-main,
    .w-hidden-medium,
    .w-hidden-small {
        display: inherit !important
    }
    .w-hidden-tiny {
        display: none !important
    }
    .w-col {
        width: 100%
    }
    .w-col-tiny-1 {
        width: 8.33333333%
    }
    .w-col-tiny-2 {
        width: 16.66666667%
    }
    .w-col-tiny-3 {
        width: 25%
    }
    .w-col-tiny-4 {
        width: 33.33333333%
    }
    .w-col-tiny-5 {
        width: 41.66666667%
    }
    .w-col-tiny-6 {
        width: 50%
    }
    .w-col-tiny-7 {
        width: 58.33333333%
    }
    .w-col-tiny-8 {
        width: 66.66666667%
    }
    .w-col-tiny-9 {
        width: 75%
    }
    .w-col-tiny-10 {
        width: 83.33333333%
    }
    .w-col-tiny-11 {
        width: 91.66666667%
    }
    .w-col-tiny-12 {
        width: 100%
    }
}

.w-widget {
    position: relative
}

.w-widget-map {
    width: 100%;
    height: 400px
}

.w-widget-map label {
    width: auto;
    display: inline
}

.w-widget-map img {
    max-width: inherit
}

.w-widget-map .gm-style-iw {
    text-align: center
}

.w-widget-map .gm-style-iw>button {
    display: none !important
}

.w-widget-twitter {
    overflow: hidden
}

.w-widget-twitter-count-shim {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 28px;
    height: 20px;
    text-align: center;
    background: #fff;
    border: 1px solid #758696;
    border-radius: 3px
}

.w-widget-twitter-count-shim * {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.w-widget-twitter-count-shim .w-widget-twitter-count-inner {
    position: relative;
    font-size: 15px;
    line-height: 12px;
    text-align: center;
    color: #999;
    /* font-family: serif */
}

.w-widget-twitter-count-shim .w-widget-twitter-count-clear {
    position: relative;
    display: block
}

.w-widget-twitter-count-shim.w--large {
    width: 36px;
    height: 28px
}

.w-widget-twitter-count-shim.w--large .w-widget-twitter-count-inner {
    font-size: 18px;
    line-height: 18px
}

.w-widget-twitter-count-shim:not(.w--vertical) {
    margin-left: 5px;
    margin-right: 8px
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large {
    margin-left: 6px
}

.w-widget-twitter-count-shim:not(.w--vertical):after,
.w-widget-twitter-count-shim:not(.w--vertical):before {
    top: 50%;
    left: 0;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.w-widget-twitter-count-shim:not(.w--vertical):before {
    border-color: rgba(117, 134, 150, 0);
    border-right-color: #5d6c7b;
    border-width: 4px;
    margin-left: -9px;
    margin-top: -4px
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:before {
    border-width: 5px;
    margin-left: -10px;
    margin-top: -5px
}

.w-widget-twitter-count-shim:not(.w--vertical):after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 4px;
    margin-left: -8px;
    margin-top: -4px
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:after {
    border-width: 5px;
    margin-left: -9px;
    margin-top: -5px
}

.w-widget-twitter-count-shim.w--vertical {
    width: 61px;
    height: 33px;
    margin-bottom: 8px
}

.w-widget-twitter-count-shim.w--vertical:after,
.w-widget-twitter-count-shim.w--vertical:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.w-widget-twitter-count-shim.w--vertical:before {
    border-color: rgba(117, 134, 150, 0);
    border-top-color: #5d6c7b;
    border-width: 5px;
    margin-left: -5px
}

.w-widget-twitter-count-shim.w--vertical:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 4px;
    margin-left: -4px
}

.w-widget-twitter-count-shim.w--vertical .w-widget-twitter-count-inner {
    font-size: 18px;
    line-height: 22px
}

.w-widget-twitter-count-shim.w--vertical.w--large {
    width: 76px
}

.w-background-video {
    position: relative;
    overflow: hidden;
    height: 500px;
    color: #fff
}

.w-background-video>video {
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
    z-index: -100
}

.w-background-video>video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none
}

.w-background-video--control {
    position: absolute;
    bottom: 1em;
    right: 1em;
    background-color: transparent;
    padding: 0
}

.w-background-video--control>[hidden] {
    display: none !important
}

.w-slider {
    position: relative;
    height: 300px;
    text-align: center;
    background: #ddd;
    clear: both;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: rgba(0, 0, 0, 0)
}

.w-slider-mask {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
    left: 0;
    right: 0;
    height: 100%;
    white-space: nowrap
}

.w-slide {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    white-space: normal;
    text-align: left
}

.w-slider-nav {
    position: absolute;
    z-index: 2;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    padding-top: 10px;
    height: 40px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: rgba(0, 0, 0, 0)
}

.w-slider-nav.w-round>div {
    border-radius: 100%
}

.w-slider-nav.w-num>div {
    width: auto;
    height: auto;
    padding: .2em .5em;
    font-size: inherit;
    line-height: inherit
}

.w-slider-nav.w-shadow>div {
    box-shadow: 0 0 3px rgba(51, 51, 51, .4)
}

.w-slider-nav-invert {
    color: #fff
}

.w-slider-nav-invert>div {
    background-color: rgba(34, 34, 34, .4)
}

.w-slider-nav-invert>div.w-active {
    background-color: #222
}

.w-slider-dot {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: rgba(255, 255, 255, .4);
    cursor: pointer;
    margin: 0 3px .5em;
    transition: background-color .1s, color .1s
}

.w-slider-dot.w-active {
    background-color: #fff
}

.w-slider-dot:focus {
    outline: 0;
    box-shadow: 0 0 0 2px #fff
}

.w-slider-dot:focus.w-active {
    box-shadow: none
}

.w-slider-arrow-left,
.w-slider-arrow-right {
    position: absolute;
    width: 80px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    cursor: pointer;
    overflow: hidden;
    color: #fff;
    font-size: 40px;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.w-slider-arrow-left [class*=' w-icon-'],
.w-slider-arrow-left [class^=w-icon-],
.w-slider-arrow-right [class*=' w-icon-'],
.w-slider-arrow-right [class^=w-icon-] {
    position: absolute
}

.w-slider-arrow-left:focus,
.w-slider-arrow-right:focus {
    outline: 0
}

.w-slider-arrow-left {
    z-index: 3;
    right: auto
}

.w-slider-arrow-right {
    z-index: 4;
    left: auto
}

.w-icon-slider-left,
.w-icon-slider-right {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1em;
    height: 1em
}

.w-slider-aria-label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.w-slider-force-show {
    display: block !important
}

.w-dropdown {
    display: inline-block;
    position: relative;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    z-index: 900
}

.w-dropdown-btn,
.w-dropdown-link,
.w-dropdown-toggle {
    position: relative;
    vertical-align: top;
    text-decoration: none;
    color: #222;
    padding: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap
}

.w-dropdown-toggle {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    cursor: pointer;
    padding-right: 40px
}

.w-dropdown-toggle:focus {
    outline: 0
}

.w-icon-dropdown-toggle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto 20px auto auto;
    width: 1em;
    height: 1em
}

.w-dropdown-list {
    position: absolute;
    background: #ddd;
    display: none;
    min-width: 100%
}

.w-dropdown-list.w--open {
    display: block
}

.w-dropdown-link {
    padding: 10px 20px;
    display: block;
    color: #222
}

.w-dropdown-link.w--current {
    color: #0082f3
}

.w-dropdown-link:focus {
    outline: 0
}

@media screen and (max-width:767px) {
    .w-nav-brand {
        padding-left: 10px
    }
}

.w-lightbox-backdrop {
    cursor: auto;
    font-style: normal;
    font-variant: normal;
    letter-spacing: normal;
    list-style: disc;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    visibility: visible;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    /* font-family: "Helvetica Neue", Helvetica, Ubuntu, "Segoe UI", Verdana, sans-serif; */
    font-size: 17px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    background: rgba(0, 0, 0, .9);
    z-index: 2000;
    outline: 0;
    opacity: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-transform: translate(0, 0)
}

.w-lightbox-backdrop,
.w-lightbox-container {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch
}

.w-lightbox-content {
    position: relative;
    height: 100vh;
    overflow: hidden
}

.w-lightbox-view {
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0
}

.w-lightbox-view:before {
    content: "";
    height: 100vh
}

.w-lightbox-group,
.w-lightbox-group .w-lightbox-view,
.w-lightbox-group .w-lightbox-view:before {
    height: 86vh
}

.w-lightbox-frame,
.w-lightbox-view:before {
    display: inline-block;
    vertical-align: middle
}

.w-lightbox-figure {
    position: relative;
    margin: 0
}

.w-lightbox-group .w-lightbox-figure {
    cursor: pointer
}

.w-lightbox-img {
    width: auto;
    height: auto;
    max-width: none
}

.w-lightbox-image {
    display: block;
    float: none;
    max-width: 100vw;
    max-height: 100vh
}

.w-lightbox-group .w-lightbox-image {
    max-height: 86vh
}

.w-lightbox-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: .5em 1em;
    background: rgba(0, 0, 0, .4);
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.w-lightbox-embed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.w-lightbox-control {
    position: absolute;
    top: 0;
    width: 4em;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s
}

.w-lightbox-left {
    display: none;
    bottom: 0;
    left: 0;

}

.w-lightbox-right {
    display: none;
    right: 0;
    bottom: 0;
  
}

.w-lightbox-close {
    right: 0;
    height: 2.6em;
    background-size: 18px
}

.w-lightbox-strip {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 1vh;
    line-height: 0;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden
}

.w-lightbox-item {
    display: inline-block;
    width: 10vh;
    padding: 2vh 1vh;
    box-sizing: content-box;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0)
}

.w-lightbox-active {
    opacity: .3
}

.w-lightbox-thumbnail {
    position: relative;
    height: 10vh;
    background: #222;
    overflow: hidden
}

.w-lightbox-thumbnail-image {
    position: absolute;
    top: 0;
    left: 0
}

.w-lightbox-thumbnail .w-lightbox-tall {
    top: 50%;
    width: 100%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.w-lightbox-thumbnail .w-lightbox-wide {
    left: 50%;
    height: 100%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
}

.w-lightbox-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    border: 5px solid rgba(0, 0, 0, .4);
    border-radius: 50%;
    -webkit-animation: .8s linear infinite spin;
    animation: .8s linear infinite spin
}

.w-lightbox-spinner:after {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 3px solid transparent;
    border-bottom-color: #fff;
    border-radius: 50%
}

.w-lightbox-hide {
    display: none
}

.w-lightbox-noscroll {
    overflow: hidden
}

@media (min-width:768px) {
    .w-lightbox-content {
        height: 96vh;
        margin-top: 2vh
    }
    .w-lightbox-view,
    .w-lightbox-view:before {
        height: 96vh
    }
    .w-lightbox-group,
    .w-lightbox-group .w-lightbox-view,
    .w-lightbox-group .w-lightbox-view:before {
        height: 84vh
    }
    .w-lightbox-image {
        max-width: 96vw;
        max-height: 96vh
    }
    .w-lightbox-group .w-lightbox-image {
        max-width: 82.3vw;
        max-height: 84vh
    }
    .w-lightbox-left,
    .w-lightbox-right {
        display: block;
        opacity: .5
    }
    .w-lightbox-close {
        opacity: .8
    }
    .w-lightbox-control:hover {
        opacity: 1
    }
}

.w-lightbox-inactive,
.w-lightbox-inactive:hover {
    opacity: 0
}

.w-richtext:after,
.w-richtext:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-richtext:after {
    clear: both
}

.w-richtext[contenteditable=true]:after,
.w-richtext[contenteditable=true]:before {
    white-space: initial
}

.w-richtext ol,
.w-richtext ul {
    overflow: hidden
}

.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div,
.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:after,
.w-richtext .w-richtext-figure-selected[data-rt-type=image] div,
.w-richtext .w-richtext-figure-selected[data-rt-type=video] div:after {
    outline: #2895f7 solid 2px
}

.w-richtext figure.w-richtext-figure-type-video>div:after,
.w-richtext figure[data-rt-type=video]>div:after {
    content: '';
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
}

.w-richtext figure {
    position: relative;
    max-width: 60%
}

.w-richtext figure>div:before {
    cursor: default !important
}

.w-richtext figure img {
    width: 100%
}

.w-richtext figure figcaption.w-richtext-figcaption-placeholder {
    opacity: .6
}

.w-richtext figure div {
    font-size: 0px;
    color: transparent
}

.w-richtext figure.w-richtext-figure-type-image,
.w-richtext figure[data-rt-type=image] {
    display: table
}

.w-richtext figure.w-richtext-figure-type-image>div,
.w-richtext figure[data-rt-type=image]>div {
    display: inline-block
}

.w-richtext figure.w-richtext-figure-type-image>figcaption,
.w-richtext figure[data-rt-type=image]>figcaption {
    display: table-caption;
    caption-side: bottom
}

.w-richtext figure.w-richtext-figure-type-video,
.w-richtext figure[data-rt-type=video] {
    width: 60%;
    height: 0
}

.w-richtext figure.w-richtext-figure-type-video iframe,
.w-richtext figure[data-rt-type=video] iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.w-richtext figure.w-richtext-figure-type-video>div,
.w-richtext figure[data-rt-type=video]>div {
    width: 100%
}

.w-richtext figure.w-richtext-align-center {
    margin-right: auto;
    margin-left: auto;
    clear: both
}

.w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image>div,
.w-richtext figure.w-richtext-align-center[data-rt-type=image]>div {
    max-width: 100%
}

.w-richtext figure.w-richtext-align-normal {
    clear: both
}

.w-richtext figure.w-richtext-align-fullwidth {
    width: 100%;
    max-width: 100%;
    text-align: center;
    clear: both;
    display: block;
    margin-right: auto;
    margin-left: auto
}

.w-richtext figure.w-richtext-align-fullwidth>div {
    display: inline-block;
    padding-bottom: inherit
}

.w-richtext figure.w-richtext-align-fullwidth>figcaption {
    display: block
}

.w-richtext figure.w-richtext-align-floatleft {
    float: left;
    margin-right: 15px;
    clear: none
}

.w-richtext figure.w-richtext-align-floatright {
    float: right;
    margin-left: 15px;
    clear: none
}

.w-nav {
    position: relative;
    background: #ddd;
    z-index: 1000
}

.w-nav:after,
.w-nav:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-nav:after {
    clear: both
}

.w-nav-brand {
    position: relative;
    float: left;
    text-decoration: none;
    color: #333
}

.w-nav-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: #222;
    padding: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto
}

.w-nav-link.w--current {
    color: #0082f3
}

.w-nav-menu {
    position: relative;
    float: right
}

[data-nav-menu-open] {
    display: block !important;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #c8c8c8;
    text-align: center;
    overflow: visible;
    min-width: 200px
}

.w--nav-link-open {
    display: block;
    position: relative
}

.w-nav-overlay {
    position: absolute;
    overflow: hidden;
    display: none;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%
}

.w-nav-overlay [data-nav-menu-open] {
    top: 0
}

.w-nav[data-animation=over-left] .w-nav-overlay {
    width: auto
}

.w-nav[data-animation=over-left] .w-nav-overlay,
.w-nav[data-animation=over-left] [data-nav-menu-open] {
    right: auto;
    z-index: 1;
    top: 0
}

.w-nav[data-animation=over-right] .w-nav-overlay {
    width: auto
}

.w-nav[data-animation=over-right] .w-nav-overlay,
.w-nav[data-animation=over-right] [data-nav-menu-open] {
    left: auto;
    z-index: 1;
    top: 0
}

.w-nav-button {
    position: relative;
    float: right;
    padding: 18px;
    font-size: 24px;
    display: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.w-nav-button:focus {
    outline: 0
}

.w-nav-button.w--open {
    background-color: #c8c8c8;
    color: #fff
}

.w-nav[data-collapse=all] .w-nav-menu {
    display: none
}

.w--nav-dropdown-open,
.w--nav-dropdown-toggle-open,
.w-nav[data-collapse=all] .w-nav-button {
    display: block
}

.w--nav-dropdown-list-open {
    position: static
}

@media screen and (max-width:991px) {
    .w-nav[data-collapse=medium] .w-nav-menu {
        display: none
    }
    .w-nav[data-collapse=medium] .w-nav-button {
        display: block
    }
}

@media screen and (max-width:767px) {
    .w-nav[data-collapse=small] .w-nav-menu {
        display: none
    }
    .w-nav[data-collapse=small] .w-nav-button {
        display: block
    }
    .w-nav-brand {
        padding-left: 10px
    }
}

.w-tabs {
    position: relative
}

.w-tabs:after,
.w-tabs:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-tabs:after {
    clear: both
}

.w-tab-menu {
    position: relative
}

.w-tab-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 9px 30px;
    text-align: left;
    cursor: pointer;
    color: #222;
    background-color: #ddd
}

.w-tab-link.w--current {
    background-color: #c8c8c8
}

.w-tab-link:focus {
    outline: 0
}

.w-tab-content {
    position: relative;
    display: block;
    overflow: hidden
}

.w-tab-pane {
    position: relative;
    display: none
}

.w--tab-active {
    display: block
}

@media screen and (max-width:479px) {
    .w-nav[data-collapse=tiny] .w-nav-menu {
        display: none
    }
    .w-nav[data-collapse=tiny] .w-nav-button,
    .w-tab-link {
        display: block
    }
}

.w-ix-emptyfix:after {
    content: ""
}

@keyframes spin {
    0% {
        transform: rotate(0)
    }
    100% {
        transform: rotate(360deg)
    }
}

.w-dyn-empty {
    padding: 10px;
    background-color: #ddd
}

.w-condition-invisible,
.w-dyn-bind-empty,
.w-dyn-hide {
    display: none !important
}

.w-layout-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px
}

.w-checkbox {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px
}

.w-checkbox::before {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1
}

.w-checkbox::after {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    clear: both
}

.w-checkbox-input {
    float: left;
    margin: 4px 0 0 -20px;
    line-height: normal
}

.w-checkbox-input--inputType-custom {
    border-width: 1px;
    border-color: #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-style: solid;
    width: 12px;
    height: 12px;
    border-radius: 2px
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
    background-color: #3898ec;
    border-color: #3898ec;
    
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
    box-shadow: 0 0 3px 1px #3898ec
}

h1 {
    margin: 0;
    
    font-size: 3.5rem;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase
}

h2 {
    margin-top: 0;
    margin-bottom: 0;
        /* font-family: Satoshi, sans-serif; */
    font-size: 3rem;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase
}

h3 {
    margin-top: 0;
    margin-bottom: 0;
        /* font-family: Satoshi, sans-serif; */
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 500
}

h4 {
    margin-top: 0;
    margin-bottom: 0;
        /* font-family: Satoshi, sans-serif; */
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 400
}

h5 {
    margin-top: 0;
    margin-bottom: 0;
        /* font-family: Satoshi, sans-serif; */
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 400
}

h6 {
    margin-top: 0;
    margin-bottom: 0;
        /* font-family: Satoshi, sans-serif; */
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 400
}

p {
    margin-top: 0;
    margin-bottom: 0;
        /* font-family: Satoshi, sans-serif; */
    font-weight: 400
}

a {
    background-color: transparent;
    border-color: #1d1d1d;
        /* font-family: Satoshi, sans-serif; */
    color: #1d1d1d;
    /* text-decoration: underline */
}

ul {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1.25rem
}

ol {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1.5rem
}

li {
    margin-top: .25rem;
    margin-bottom: .25rem;
    padding-left: .5rem
}

label {
    display: block;
    margin-bottom: .25rem;
    font-weight: 500
}

blockquote {
    margin: 0;
    padding: .75rem 1.25rem;
    border-left: .1875rem solid #1d1d1d;
        /* font-family: Satoshi, sans-serif; */
    font-size: 1.25rem;
    line-height: 1.5;
    font-weight: 500
}

figcaption {
    margin-top: .25rem;
    text-align: center
}

.z-index-2 {
    position: relative;
    z-index: 2
}

.max-width-full {
    width: 100%;
    max-width: none
}

.max-width-full.text-align-left.horizontal-align {
    display: flex;
    justify-content: space-between;
    align-items: flex-end
}

.max-width-full.text-align-left.horizontal-align.align-top {
    align-items: flex-start
}

.max-width-full.text-align-left.horizontal-align.align-top.grid-project-intro {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 0.75fr 1.25fr;
    grid-template-columns: 0.75fr 1.25fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.max-width-full.text-align-left.vertical-align {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    grid-row-gap: 2rem
}

.layer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center
}

.overflow-hidden {
    overflow: hidden
}

.global-styles {
    position: fixed;
    left: 0;
    top: 0;
    right: auto;
    bottom: auto;
    display: block
}

.z-index-1 {
    position: relative;
    z-index: 1
}

.hide {
    display: none
}

.text-style-link {
        /* font-family: Satoshi, sans-serif; */
    text-decoration: underline
}

.max-width-xlarge {
    width: 100%;
    max-width: 64rem
}

.max-width-medium {
    width: 100%;
    max-width: 35rem
}

.max-width-medium.text-weight-light {
    /* font-family: Generalsans, sans-serif */
    font-family: Helveticaneue,sans-serif;
}

.max-width-xsmall {
    width: 100%;
    max-width: 25rem
}

.max-width-xxsmall {
    /* width: 30%; */
    /* max-width: 20rem */
    max-width: 40rem;
}

.container-large {
    width: 100%;
    /* max-width: 90vw; */
    margin-right: auto;
    margin-left: auto
}

.container-large._90vw {
    /* max-width: 96vw */
}

.container-large._100--vw {
    max-width: none
}

.max-width-xxlarge {
    width: 100%;
    max-width: 80rem
}

.align-center {
    margin-right: auto;
    margin-left: auto
}

.spacing-clean {
    margin: 0;
    padding: 0
}

.max-width-large {
    width: 100%;
    max-width: 48rem
}

.max-width-small {
    width: 100%;
    max-width: 30rem
}

.form-message-error {
    margin-top: .75rem;
    padding: .75rem
}

.utility_component {
    display: flex;
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    max-width: 100%;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    justify-content: center;
    align-items: center
}

.utility_form-block {
    display: flex;
    max-width: 20rem;
    flex-direction: column;
    text-align: center
}

.utility_form {
    display: flex;
    flex-direction: column;
    align-items: stretch
}

.utility_image {
    margin-right: auto;
    margin-bottom: .5rem;
    margin-left: auto
}

.container-medium {
    width: 100%;
    max-width: 64rem;
    margin-right: auto;
    margin-left: auto
}

.container-small {
    width: 100%;
    max-width: 48rem;
    margin-right: auto;
    margin-left: auto
}

.margin-tiny {
    margin: .25rem
}

.margin-xxsmall {
    margin: .5rem
}

.margin-xsmall {
    margin: 1rem
}

.margin-small {
    margin: 1.5rem
}

.margin-medium {
    margin: 2rem
}

.margin-large {
    margin: 3rem
}

.margin-xlarge {
    margin: 4rem
}

.margin-xxlarge {
    margin: 5rem
}

.margin-huge {
    margin: 6rem
}

.margin-xhuge {
    margin: 7rem
}

.margin-xxhuge {
    margin: 10rem
}

.margin-0 {
    margin: 0
}

.padding-0 {
    padding: 0
}

.padding-tiny {
    padding: .25rem
}

.padding-xxsmall {
    padding: .5rem
}

.padding-xsmall {
    padding: 1rem
}

.padding-small {
    padding: 1.5rem
}

.padding-medium {
    padding: 2rem
}

.padding-large {
    padding: 3rem
}

.padding-xlarge {
    padding: 4rem
}

.padding-xxlarge {
    padding: 5rem
}

.padding-huge {
    padding: 6rem
}

.padding-xhuge {
    padding: 7rem
}

.padding-xxhuge {
    padding: 10rem
}

.margin-top,
.margin-top.margin-0,
.margin-top.margin-custom1,
.margin-top.margin-custom2,
.margin-top.margin-custom3,
.margin-top.margin-huge,
.margin-top.margin-large,
.margin-top.margin-medium,
.margin-top.margin-small,
.margin-top.margin-tiny,
.margin-top.margin-xhuge,
.margin-top.margin-xlarge,
.margin-top.margin-xsmall,
.margin-top.margin-xxhuge,
.margin-top.margin-xxlarge,
.margin-top.margin-xxsmall {
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0
}

.margin-bottom {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0
}

.margin-bottom.margin-small.z {
    position: relative;
    /* z-index: 10 */
}

.margin-bottom.margin-xsmall.horizontal-align {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.margin-bottom.margin-xsmall.horizontal-align.mobile-vertical {
    margin-bottom: 0;
    padding-top: 1rem
}

.margin-bottom.margin-0,
.margin-bottom.margin-custom1,
.margin-bottom.margin-custom2,
.margin-bottom.margin-custom3,
.margin-bottom.margin-huge,
.margin-bottom.margin-large,
.margin-bottom.margin-medium,
.margin-bottom.margin-small,
.margin-bottom.margin-tiny,
.margin-bottom.margin-xhuge,
.margin-bottom.margin-xlarge,
.margin-bottom.margin-xsmall,
.margin-bottom.margin-xxhuge,
.margin-bottom.margin-xxlarge,
.margin-bottom.margin-xxsmall {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0
}

.margin-bottom.align-horizontal {
    display: flex;
    justify-content: space-between
}

.margin-left,
.margin-left.margin-0,
.margin-left.margin-custom1,
.margin-left.margin-custom2,
.margin-left.margin-custom3,
.margin-left.margin-huge,
.margin-left.margin-large,
.margin-left.margin-medium,
.margin-left.margin-small,
.margin-left.margin-tiny,
.margin-left.margin-xhuge,
.margin-left.margin-xlarge,
.margin-left.margin-xsmall,
.margin-left.margin-xxhuge,
.margin-left.margin-xxlarge,
.margin-left.margin-xxsmall {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0
}

.margin-right,
.margin-right.margin-huge {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0
}

.margin-right.margin-huge.responsive-max-width {
    position: relative;
    z-index: 5
}

.margin-right.margin-0,
.margin-right.margin-custom1,
.margin-right.margin-custom2,
.margin-right.margin-custom3,
.margin-right.margin-large,
.margin-right.margin-medium,
.margin-right.margin-small,
.margin-right.margin-tiny,
.margin-right.margin-xhuge,
.margin-right.margin-xlarge,
.margin-right.margin-xsmall,
.margin-right.margin-xxhuge,
.margin-right.margin-xxlarge,
.margin-right.margin-xxsmall {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0
}

.margin-vertical,
.margin-vertical.margin-medium,
.margin-vertical.margin-xlarge,
.margin-vertical.margin-xxlarge,
.margin-vertical.margin-xxsmall {
    margin-right: 0;
    margin-left: 0
}

.margin-vertical.margin-xxlarge.less-margin-bottom {
    margin-top: 1rem;
    margin-bottom: 3rem
}

.margin-vertical.margin-0,
.margin-vertical.margin-custom1,
.margin-vertical.margin-custom2,
.margin-vertical.margin-custom3,
.margin-vertical.margin-huge,
.margin-vertical.margin-large,
.margin-vertical.margin-small,
.margin-vertical.margin-tiny,
.margin-vertical.margin-xhuge,
.margin-vertical.margin-xsmall,
.margin-vertical.margin-xxhuge {
    margin-right: 0;
    margin-left: 0
}

.margin-horizontal,
.margin-horizontal.margin-0,
.margin-horizontal.margin-custom1,
.margin-horizontal.margin-custom2,
.margin-horizontal.margin-custom3,
.margin-horizontal.margin-huge,
.margin-horizontal.margin-large,
.margin-horizontal.margin-medium,
.margin-horizontal.margin-small,
.margin-horizontal.margin-tiny,
.margin-horizontal.margin-xhuge,
.margin-horizontal.margin-xlarge,
.margin-horizontal.margin-xsmall,
.margin-horizontal.margin-xxhuge,
.margin-horizontal.margin-xxlarge,
.margin-horizontal.margin-xxsmall {
    margin-top: 0;
    margin-bottom: 0
}

.padding-top,
.padding-top.padding-0,
.padding-top.padding-custom1,
.padding-top.padding-custom2,
.padding-top.padding-custom3,
.padding-top.padding-huge,
.padding-top.padding-large,
.padding-top.padding-medium,
.padding-top.padding-small,
.padding-top.padding-tiny,
.padding-top.padding-xhuge,
.padding-top.padding-xlarge,
.padding-top.padding-xsmall,
.padding-top.padding-xxhuge,
.padding-top.padding-xxlarge,
.padding-top.padding-xxsmall {
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0
}

.padding-bottom,
.padding-bottom.padding-0,
.padding-bottom.padding-custom1,
.padding-bottom.padding-custom2,
.padding-bottom.padding-custom3,
.padding-bottom.padding-huge,
.padding-bottom.padding-large,
.padding-bottom.padding-medium,
.padding-bottom.padding-small,
.padding-bottom.padding-tiny,
.padding-bottom.padding-xhuge,
.padding-bottom.padding-xlarge,
.padding-bottom.padding-xsmall,
.padding-bottom.padding-xxhuge,
.padding-bottom.padding-xxlarge,
.padding-bottom.padding-xxsmall {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0
}

.padding-left,
.padding-left.padding-0,
.padding-left.padding-custom1,
.padding-left.padding-custom2,
.padding-left.padding-custom3,
.padding-left.padding-huge,
.padding-left.padding-large,
.padding-left.padding-medium,
.padding-left.padding-small,
.padding-left.padding-tiny,
.padding-left.padding-xhuge,
.padding-left.padding-xlarge,
.padding-left.padding-xsmall,
.padding-left.padding-xxhuge,
.padding-left.padding-xxlarge,
.padding-left.padding-xxsmall {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0
}

.padding-right,
.padding-right.padding-0,
.padding-right.padding-custom1,
.padding-right.padding-custom2,
.padding-right.padding-custom3,
.padding-right.padding-huge,
.padding-right.padding-large,
.padding-right.padding-medium,
.padding-right.padding-small,
.padding-right.padding-tiny,
.padding-right.padding-xhuge,
.padding-right.padding-xlarge,
.padding-right.padding-xsmall,
.padding-right.padding-xxhuge,
.padding-right.padding-xxlarge,
.padding-right.padding-xxsmall {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0
}

.padding-vertical,
.padding-vertical.padding-0,
.padding-vertical.padding-custom1,
.padding-vertical.padding-custom2,
.padding-vertical.padding-custom3,
.padding-vertical.padding-huge,
.padding-vertical.padding-large,
.padding-vertical.padding-medium,
.padding-vertical.padding-small,
.padding-vertical.padding-tiny,
.padding-vertical.padding-xhuge,
.padding-vertical.padding-xlarge,
.padding-vertical.padding-xsmall,
.padding-vertical.padding-xxhuge,
.padding-vertical.padding-xxlarge,
.padding-vertical.padding-xxsmall {
    padding-right: 0;
    padding-left: 0
}

.padding-horizontal,
.padding-horizontal.padding-0,
.padding-horizontal.padding-custom1,
.padding-horizontal.padding-custom2,
.padding-horizontal.padding-custom3,
.padding-horizontal.padding-huge,
.padding-horizontal.padding-large,
.padding-horizontal.padding-medium,
.padding-horizontal.padding-small,
.padding-horizontal.padding-tiny,
.padding-horizontal.padding-xhuge,
.padding-horizontal.padding-xlarge,
.padding-horizontal.padding-xsmall,
.padding-horizontal.padding-xxhuge,
.padding-horizontal.padding-xxlarge,
.padding-horizontal.padding-xxsmall {
    padding-top: 0;
    padding-bottom: 0
}

.overflow-scroll {
    overflow: scroll
}

.overflow-auto {
    overflow: auto
}

.text-weight-medium {
        font-family: Satoshi, sans-serif;
        line-height: 1.4;
    font-weight: 500
}

.text-size-medium {
     /* font-family: 'Urbanist'; */
    font-size: 1.525rem;
}
.whatdo .text-size-medium{
    font-size: 0.955rem;
}

.text-size-medium.max-width-medium.margin-right.margin-medium.text-weight-light.max-width-37rem {
    max-width: 37rem
}

.text-size-medium.max-width-medium.text-weight-light {
    font-weight: 300;
     /* margin-left: 0.5rem; */
     font-size: 1.4rem;
     /* align-items: center; */
}

.text-size-medium.max-width-medium.text-weight-light.full-width-respomsive.width-36rem {
    max-width: 36rem;
    /* margin-left: 2rem; */
    
}

.text-size-medium.max-width-medium.text-style-allcaps {
    /* font-family: Generalsans, sans-serif */
}

.text-size-medium.max-width-medium.text-style-allcaps.text-colour-grey {
    color: #ababab
}

.text-size-medium.max-width-medium.text-style-allcaps.text-colour-grey.project-detail-width {
    max-width: 38rem
}

.text-size-medium.margin-top.margin-medium.max-width-large.text-weight-light,
.text-size-medium.max-width-large.text-weight-light {
     /* font-family: 'Urbanist'; */
    font-weight: 300
}

.text-size-medium.max-width-xxsmall.text-style-allcaps.text-colour-grey {
    /* color: #ababab; */
    text-transform: capitalize;
}

.margin-top-auto {
    margin-top: auto
}

.text-style-quote {
    margin-bottom: 0;
    padding: .75rem 1.25rem;
    border-left: .1875rem solid #000;
        /* font-family: Satoshi, sans-serif; */
    font-size: 1.25rem;
    line-height: 1.5;
    font-weight: 500
}

.icon-embed-small {
    display: flex;
    width: 1.8rem;
    height: 1.8rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #1d1d1d
}

.heading-style-h2 {
    overflow: hidden;
    font-size: 4rem;
    line-height: 1.2;
    font-weight: 500;
    /* margin-left: -0.5rem; */
    font-family: Helveticaneue,sans-serif;
}

.heading-style-h2._8vw-title {
    line-height: 1
}

.heading-style-h2._8vw-title.project-intro-title {
    font-size: 3.2rem
}

.heading-style-h2._8vw-title.project-title {
    font-size: 6.15vw
}

.heading-style-h2.about-title {
    font-size: 5.6vw;
    line-height: 1;
    text-transform: capitalize;
}

.rl-styleguide_tutorial-callout {
    display: flex;
    padding: .75rem .75rem .75rem 1rem;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 2rem;
    border: 1px solid #000;
    background-color: #fff
}

.rl-styleguide_callout-link-wrapper {
    display: flex;
    width: 100%;
    padding: .75rem 1rem;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(135deg, rgba(255, 116, 72, .15), rgba(255, 72, 72, .15) 50%, rgba(98, 72, 255, .15)), linear-gradient(180deg, #fff, #fff)
}

.rl-styleguide_heading {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 4;
    padding: .25rem .5rem;
    background-color: #000;
    color: #fff
}

.styleguide_grid-colours {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.background-color-gray {
    background-color: #f4f4f4
}

.heading-style-h6 {
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 400;
    text-transform: none
}

.rl-styleguide_item-row {
    align-items: center;
    grid-column-gap: 1.5rem;
    grid-row-gap: 0rem;
    -ms-grid-columns: 15rem 1fr;
    grid-template-columns: 15rem 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.icon-1x1-xsmall {
    width: 1.5rem;
    height: 1.5rem
}

.rl-styleguide_empty-space {
    position: relative;
    z-index: -1;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start
}

.shadow-xxlarge {
    box-shadow: 0 32px 64px -12px rgba(0, 0, 0, .14)
}

.rl-styleguide_icons-list {
    display: -ms-grid;
    display: grid;
    flex-wrap: wrap;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.icon-embed-medium {
    display: flex;
    width: 5rem;
    height: 5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ababab
}

.form-radio {
    display: flex;
    margin-bottom: 0;
    padding-left: 1.125rem;
    align-items: center
}

.heading-style-h5 {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 400;
    text-transform: none
}

.heading-style-h5.text-style-allcaps.text-weight-medium {
    font-weight: 600;
    color: #000;
}

.text-style-strikethrough {
     /* font-family: 'Urbanist'; */
    text-decoration: line-through
}

.heading-style-h1 {
    font-size: 3.5rem;
    line-height: 1.2;
    font-weight: 500
}

.form-checkbox-icon {
    width: 1.125rem;
    height: 1.125rem;
    min-height: 1.125rem;
    min-width: 1.125rem;
    margin-top: 0;
    margin-right: .5rem;
    margin-left: -1.25rem;
    border: 1px solid #ababab;
    border-radius: 100px;
    transition: .2s;
    cursor: pointer
}

.form-checkbox-icon.w--redirected-checked {
    border-width: 1px;
    border-color: #fff8f1;
    background-color: #fff8f1;
    background-image: none;
    box-shadow: none
}

.form-checkbox-icon.w--redirected-focus {
    border-color: #fff8f1;
    box-shadow: none
}

.form-checkbox-label {
    margin-bottom: 0
}

.form-checkbox-label.text-size-small {
    text-transform: uppercase
}

.text-size-tiny {
     /* font-family: 'Urbanist'; */
    font-size: .75rem
}

.field-label {
    margin-bottom: .5rem;
     /* font-family: 'Urbanist'; */
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase
}

.button {
    padding: .75rem 1.5rem;
    /* border: 1px solid #1d1d1d; */
    border-radius: 30rem;
    background-color: black;
     /* font-family: 'Urbanist'; */
    /* color: #1d1d1d; */
    color: white;
    text-align: center;
    text-transform: uppercase
}

.button.is-link {
    padding: .25rem 0;
    border-style: none;
    background-color: transparent;
    color: #1d1d1d;
    line-height: 1;
    text-decoration: none
}

.button.is-link.is-alternate {
    background-color: transparent;
    color: #fff8f1
}

.button.is-link.is-icon {
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem
}

.button.is-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.75rem
}

.button.is-secondary {
    background-color: transparent;
    color: #1d1d1d
}

.button.is-secondary.is-alternate {
    border-color: #fff8f1;
    background-color: transparent;
    color: #fff8f1
}

.button.is-secondary.is-small.is-icon {
    font-family: Generalsans, sans-serif
}

.button.is-small {
    padding: .5rem 1.25rem
}

.button.is-small.is-icon {
     /* font-family: 'Urbanist'; */
    text-transform: uppercase
}

.button.is-small.is-icon.is-alternate {
    font-size: .8rem;
    font-weight: 400
}

.button.is-small.is-icon.is-alternate.is-secondary {
    border-color: #545454;
    transition: border-color .3s
}

.button.is-small.is-icon.is-alternate.is-secondary:hover {
    border-color: #fff8f1
}

.button.is-small.is-icon.is-alternate.button-smaller {
    width: 5rem;
    height: 1.5rem;
    text-transform: none
}

.button.is-small.is-icon.is-alternate.button-smaller.ro-button {
    width: 6.5rem
}

.button.is-small.is-icon.is-alternate.button-smaller-footer {
    width: auto;
    height: 2rem;
    padding: .7rem 1rem;
    border-color: #535353;
    background-color: transparent;
    /* color: #ababab; */
    color: black;
    text-transform: none
}

.button.is-alternate {
    background-color: black;
    color: white;
    text-decoration: none;
}

.text-weight-normal {
        /* font-family: Satoshi, sans-serif; */
    font-weight: 400
}

.heading-style-h4 {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 400;
    text-transform: none
}

.text-style-italic {
     /* font-family: 'Urbanist'; */
    font-style: italic
}

.icon-1x1-xxsmall {
    width: 1rem;
    height: 1rem
}

.form-radio-label {
    margin-bottom: 0
}

.rl-styleguide_item {
    display: -ms-grid;
    display: grid;
    padding-bottom: 1rem;
    flex-direction: column;
    justify-content: start;
    justify-items: start;
    align-items: start;
    align-content: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.rl-styleguide_item.is-stretch {
    justify-items: stretch;
    grid-row-gap: 1rem
}

.icon-1x1-xlarge {
    width: 6.5rem;
    height: 6.5rem
}

.shadow-xlarge {
    box-shadow: 0 24px 48px -12px rgba(0, 0, 0, .18)
}

.text-weight-light {
        /* font-family: Satoshi, sans-serif; */
    font-weight: 400;
    line-height: 1.4;
}

.rl-styleguide_label {
    display: inline-block;
    padding: .25rem .5rem;
    background-color: #0073e6;
    color: #fff8f1;
    font-size: .75rem;
    white-space: nowrap;
    cursor: context-menu
}

.rl-styleguide_label.is-html-tag {
    background-color: #be4aa5
}

.text-size-regular {
     /* font-family: 'Urbanist'; */
     font-family: sans-serif;
     line-height: 1.4;
    font-size: 1.5rem;
    color: #000;
}

.text-size-regular.text-colour-grey {
    /* color: #ababab */
    color: black;
}

.text-size-regular.text-colour-grey.align-text-center {
    -ms-grid-row-align: center;
    align-self: center
}

.text-weight-xbold {
        /* font-family: Satoshi, sans-serif; */
    font-weight: 700
}

.text-align-right {
    text-align: right
}

.text-weight-bold {
        /* font-family: Satoshi, sans-serif; */
    font-weight: 700
}

.icon-height-xlarge {
    height: 6.5rem
}

.heading-style-h3 {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 500;
    text-transform: none
}

.icon-1x1-large {
    width: 5rem;
    height: 5rem
}

.form-input {
    height: auto;
    min-height: 2.75rem;
    margin-bottom: 0;
    padding: .5rem .75rem .5rem .5rem;
    border: 1px #545454;
    border-radius: 0;
    background-color: transparent;
    transition: border-color 250ms;
     /* font-family: 'Urbanist'; */
    color: #fff8f1;
    font-size: 16px;
    line-height: 1.6;
    font-weight: 300
}

.form-input:focus {
    border-style: solid solid none;
    border-color: #545454 #545454 #000;
    border-radius: 0
}

.form-input::-moz-placeholder {
    color: #ababab;
    font-size: 14px
}

.form-input::-ms-input-placeholder {
    color: #ababab;
    font-size: 14px
}

.form-input::placeholder {
    color: #ababab;
    font-size: 14px
}

.form-input.is-text-area {
    overflow: auto;
    height: auto;
    min-height: 11.25rem;
    padding-top: .75rem;
    padding-bottom: .75rem
}

.form-input.is-select-input {
    padding-left: .5rem;
    background-image: none;
    /* color: #fff8f1 */
    color: black;
}

.rl-styleguide_paste-text {
    padding: 1rem;
    border: 1px dashed rgba(0, 0, 0, .15);
    color: rgba(0, 0, 0, .5);
    text-align: center
}

.rl-styleguide_button-list {
    display: -ms-grid;
    display: grid;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    justify-items: start;
    align-items: center;
    grid-auto-flow: row;
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
    grid-column-gap: 1rem;
    grid-row-gap: 1.5rem;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    white-space: normal
}

.rl-styleguide_list {
    padding-bottom: 4rem;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.icon-embed-custom1 {
    display: flex;
    width: 1.25rem;
    height: 1.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.rl-styleguide_color {
    padding-bottom: 8rem
}

.rl-styleguide_color.is-black {
    background-color: #1d1d1d
}

.rl-styleguide_color.is-gray {
    background-color: #f4f4f4
}

.rl-styleguide_color.is-white {
    background-color: #fff8f1
}

.button-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem
}

.button-group.is-center {
    justify-content: center
}

.button-group.margin-top.margin-large.absolute-button {
    position: absolute;
    left: 0;
    top: auto;
    right: 0;
    bottom: 0
}

.text-color-white {
    color: #fff8f1
}

.background-color-white {
    background-color: #fff8f1
}

.text-weight-semibold {
        /* font-family: Satoshi, sans-serif; */
    font-weight: 600
}

.text-style-muted {
    opacity: .6;
    /* font-family: Satoshi, sans-serif */
}

.text-style-nowrap {
        /* font-family: Satoshi, sans-serif; */
    white-space: nowrap
}

.text-align-left {
    text-align: left
}

.icon-embed-large {
    display: flex;
    width: 5rem;
    height: 5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.shadow-xxsmall {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05)
}

.text-color-black {
    color: #1d1d1d
}

.text-color-black.text-size-regular.font-satoshi {
        /* font-family: Satoshi, sans-serif; */
    font-weight: 500
}

.icon-embed-xxsmall {
    display: flex;
    width: 1rem;
    height: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #1d1d1d
}

.icon-embed-xsmall {
    display: flex;
    width: 1.4rem;
    height: 1.4rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
}

.icon-embed-xsmall.icon-dark {
    /* color: #1d1d1d */
    color: white;
}

.icon-embed-xsmall.icon-grey {
    color: #ababab
}

.icon-embed-xsmall.is-white {
    color: #fff8f1
}

.icon-height-xxsmall {
    height: 1rem
}

.background-color-black {
    background-color: #1d1d1d;
    color: #fff8f1
}

.shadow-xsmall {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06)
}

.icon-1x1-custom1 {
    width: 1.25rem;
    height: 1.25rem
}

.rl-styleguide_subheading {
    position: -webkit-sticky;
    position: sticky;
    top: 2rem;
    z-index: 3;
    padding: .25rem .5rem;
    background-color: #d3d3d3;
    color: #000
}

.form-radio-icon {
    width: 1.125rem;
    height: 1.125rem;
    min-height: 1.125rem;
    min-width: 1.125rem;
    margin-top: 0;
    margin-right: .5rem;
    margin-left: -1.125rem;
    border: 1px solid #1d1d1d;
    border-radius: 100px;
    cursor: pointer
}

.form-radio-icon.w--redirected-checked {
    border-width: 6px;
    border-color: #000;
    background-color: #fff;
    background-image: none
}

.form-radio-icon.w--redirected-focus {
    margin-top: 0;
    border-color: #000;
    box-shadow: none
}

.rl-styleguide_shadows-list {
    align-items: start;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.form {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.text-style-allcaps {
        /* font-family: Satoshi, sans-serif; */
    text-transform: uppercase
}

.rl-styleguide_callout-link {
    display: flex;
    padding: 1px;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    background-image: linear-gradient(135deg, #ff7448, #ff4848 50%, #6248ff)
}

.icon-height-custom1 {
    height: 1.25rem
}

.text-align-center {
    text-align: center
}

.text-size-small {
     /* font-family: 'Urbanist'; */
    font-size: 1.875rem
}

.text-size-small.text-style-allcaps {
    color: #fff8f1
}

.text-size-small.text-style-allcaps.text-colour-grey {
    color: #ababab
}

.text-size-small.text-style-allcaps._0-9rem-text {
    font-size: .9rem
}

.text-size-small.text-colour-grey {
   





text-align: center;
text-transform: inherit;
color: #ababab;
font-size: 1vw;
margin-left: -1rem;
font-family: Helveticaneue,sans-serif;
}

.class-label-column {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.form-field-wrapper {
    position: relative
}

.icon-height-small {
    height: 2rem
}

.icon-height-large {
    height: 5rem
}

.shadow-large {
    box-shadow: 0 20px 24px -4px rgba(0, 0, 0, .08), 0 8px 8px -4px rgba(0, 0, 0, .03)
}

.icon-embed-xlarge {
    display: flex;
    width: 6.5rem;
    height: 6.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.icon-1x1-small {
    width: 2rem;
    height: 2rem
}

.class-label-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-column-gap: 0.25rem;
    grid-row-gap: 0.25rem
}

.text-rich-text h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 500
}

.text-rich-text blockquote {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}

.text-rich-text h2 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 500
}

.text-rich-text h3,
.text-rich-text h4 {
    margin-top: 1.5rem;
    margin-bottom: 1rem
}

.text-rich-text h5,
.text-rich-text h6 {
    margin-top: 1.25rem;
    margin-bottom: 1rem
}

.text-rich-text p {
    margin-bottom: 1rem;
    /* font-family: Generalsans, sans-serif */
}

.text-rich-text a {
     /* font-family: 'Urbanist'; */
    text-decoration: underline
}

.text-rich-text figcaption {
    margin-top: .5rem;
    padding-left: .5rem;
    border-left: 2px solid #1d1d1d;
    font-size: .875rem;
    text-align: left
}

.text-style-2lines {
    /* font-family: Generalsans, sans-serif */
}

.icon-height-medium {
    height: 3rem
}

.form-checkbox {
    display: flex;
    margin-bottom: 0;
    padding-left: 1.25rem;
    align-items: center
}

.shadow-small {
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .06)
}

.icon-1x1-medium {
    width: 3rem;
    height: 3rem
}

.shadow-medium {
    box-shadow: 0 12px 16px -4px rgba(0, 0, 0, .08), 0 4px 6px -2px rgba(0, 0, 0, .03)
}

.text-style-3lines {
    /* font-family: Generalsans, sans-serif */
}

.text-size-large {
     /* font-family: 'Urbanist'; */
    font-size: 1.25rem
}

.icon-height-xsmall {
    height: 1.5rem
}

.rl-styleguide_spacing-all {
    display: none
}

.padding-section-small {
    padding-top: 3rem;
    padding-bottom: 3rem
}

.rl-styleguide_empty-box {
    position: relative;
    z-index: -1;
    height: 3rem;
    min-width: 3rem;
    background-color: #eee
}

.rl-styleguide_spacing {
    border: 1px dashed #d3d3d3
}

.padding-global {
    padding-right: 2%;
    padding-left: 2%
}

.padding-section-medium {
    padding-top: 5rem;
    padding-bottom: 5rem
}

.padding-section-large {
    padding-top: 7rem;
    padding-bottom: 7rem
}

.padding-section-large.no-padding-bottom {
    padding-bottom: 0
}

.navbar1_container {
    display: flex;
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-between;
    align-items: center
}

.navbar1_dropdown-link {
    padding: .5rem 1rem
}

.navbar1_dropdown-link.w--current {
    color: #1d1d1d
}

.navbar1_menu {
    position: static;
    display: flex;
    align-items: center
}

.navbar1_menu-button {
    padding: 0
}

.navbar1_dropdown-list.w--open {
    padding: .5rem;
    border: 1px solid #1d1d1d;
    background-color: #fff8f1
}

.navbar1_dropdown-toggle {
    padding: .5rem 2.5rem .5rem 1rem
}

.navbar1_component {
    position: fixed;
    display: flex;
    width: 100%;
    height: auto;
    min-height: 4.5rem;
    padding-right: 2%;
    padding-left: 2%;
    align-items: center;
    border-bottom: .1px solid #545454;
    background-color: #1d1d1d;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    color: #fff8f1
}

.dropdown-icon {
    position: absolute;
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-top: auto;
    margin-right: 1rem;
    margin-bottom: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.navbar1_logo-link {
    padding-left: 0
}

.navbar1_link {
    margin-right: .25rem;
    padding: .5rem 1rem;
    border-radius: 20rem;
    transition: background-color .3s;
     /* font-family: 'Urbanist'; */
    color: #fff8f1;
    font-size: .9rem;
    text-transform: uppercase
}

.navbar1_link:hover {
    background-color: #2d2d2d
}

.navbar1_link.w--current {
    border: .1px solid #545454;
    border-radius: 20rem;
    color: #fff8f1;
    text-decoration: none
}

.navbar1_link.text-colour-xx {
    display: flex;
    height: 1.9rem;
    margin-right: 0;
    justify-content: center;
    align-items: center;
    border-radius: 20rem;
    background-color: #fff8f1;
    color: #1d1d1d;
    font-style: normal;
    font-weight: 400
}

.navbar1_link.text-colour-xx.transparent {
    background-color: transparent;
    color: #fff8f1
}

.navbar1_link.text-colour-xx.transparent:hover {
    background-color: #3a3a3a
}

.navbar1_link.language-ro {
    display: flex;
    height: 1.9rem;
    margin-right: 0;
    justify-content: center;
    align-items: center;
    border-radius: 20rem;
    transition: 350ms
}

.navbar1_link.language-ro:hover {
    background-color: #3a3a3a;
    color: #fff8f1;
    font-weight: 400
}

.navbar1_link.language-ro.ro-page,
.navbar1_link.language-ro.ro-page.w--current {
    background-color: #fff8f1;
    color: #1d1d1d
}

.navbar1_link.all-caps-link {
    margin-right: .5rem;
        /* font-family: Satoshi, sans-serif; */
    text-transform: uppercase
}

.navbar1_link.all-caps-link._9--margin {
    margin-left: 0
}

.navbar1_link.all-caps-link._10--margin {
    margin-left: 9%
}

.navbar1_logo {
    width: 11.5625rem;
    height: 1.5625rem
}

.header_content-bottom {
    position: -webkit-sticky;
    position: sticky;
    top: 10vh;
    z-index: 1;
    display: flex;
    overflow: hidden;
    width: 96vw;
    height: 80vh;
    margin-bottom: -10vh;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px
}

.header_content {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 0;
    display: flex;
    width: 96vw;
    max-width: 96vw;
    min-height: 80vh;
    margin-right: auto;
    margin-left: auto;
    padding-top: 15rem;
    padding-bottom: 5rem;
    justify-content: flex-start;
    align-items: center
}

.header_ix-trigger {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
    margin-top: 100vh
}

.header_lightbox-image {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover
}

.header_component {
    position: relative;
    display: flex;
    height: 300vh;
    flex-direction: column;
    align-items: center
}

.custom-h1 {
    overflow: visible;
    font-size: 7.19vw;
    line-height: 1;
    font-weight: 500
}

.hero-info-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.hero-info-wrapper.vertical-align-bottom {
    align-items: flex-end;
    grid-column-gap: 1rem
}

.button-icon {
    display: flex;
    overflow: hidden;
    width: 1.5rem;
    height: 1.4rem;
    margin: 0 .125rem 0 0;
    padding: 0;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 0 auto;
    -o-object-fit: fill;
    object-fit: fill
}

.white-line {
    position: relative;
    /* z-index: 999; */
    width: 100%;
    height: 1px;
    background-color: #3a3a3a;
    color: #fff
}

.white-line.scroll-into {
    height: 1px;
    background-color: #545454
}

.white-line.scroll-into._0-opacity {
    opacity: 0
}

.white-line.scroll-into.hide-mobile-portrait._1px {
    height: 1.3px
}

.white-line.not-visible-mobile {
    margin-right: auto;
    margin-left: auto
}

.white-line.not-visible-mobile.about-line {
    width: 95vw
}

.layout_card-content {
    display: flex;
    padding: 2rem 0 0;
    flex-direction: column;
    justify-content: space-between;
    flex: 1
}

.layout_image-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 50vh;
    padding: 1px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 10px
}

.layout_image-wrapper._100--height {
    height: 100%
}

.layout_image {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover
}

.layout_row {
    display: -ms-grid;
    display: grid;
    align-items: stretch;
    grid-auto-columns: 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1rem;
    -ms-grid-columns: 1fr 1px 1fr;
    grid-template-columns: 1fr 1px 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.layout_card {
    display: flex;
    overflow: visible;
    flex-direction: column;
    align-items: stretch;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border: 1px solid #1d1d1d;
    border-radius: 10px
}

.layout_component {
    grid-column-gap: 2rem;
    grid-row-gap: 1.5rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.vertical-line {
    display: flex;
    width: 1px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #545454
}

.image-cover {
    position: absolute;
    left: 0;
    top: auto;
    right: 0;
    bottom: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 0%;
    align-items: flex-start;
    flex: 0 auto;
    background-color: #1d1d1d
}

.text-size-xtiny {
    font-size: .9rem
}

.language-wrapper {
    display: flex;
    height: 2.5rem;
    margin-left: 2rem;
    padding: 0 .2rem;
    justify-content: center;
    align-items: center;
    grid-column-gap: 0.5rem;
    border: 1px solid #595959;
    border-radius: 20rem
}

.language-wrapper.no-margin-responsive {
    height: 2.5rem;
    padding-right: .25rem;
    padding-left: .25rem;
    border-color: #545454
}

.language-wrapper.no-margin-responsive.mobile-language {
    display: none
}

.about-info-wrapper {
    position: relative;
    padding-right: 2rem;
    border-radius: 10px;
    background-color: transparent
}

.parallax-image {
    overflow: visible;
    width: 50%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.link,
.paragraph,
.paragraph-2,
.paragraph-3,
.paragraph-4,
.paragraph-5,
.paragraph-6,
.paragraph-7,
.paragraph-8 {
    /* font-family: Generalsans, sans-serif */
}

.partner-details {
    position: relative;
    display: flex;
    overflow: hidden;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: space-between;
    align-items: center
}

.partner-logo {
    width: 8rem;
    height: 4.0625rem;
    border-radius: 10px
}

.section-number-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-column-gap: 1rem
}

.section-number-wrapper.margin-bottom.margin-medium {
    grid-column-gap: 0.75rem
}

.number-circle {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    justify-content: center;
    align-items: center;
    border: 1px #000;
    border-radius: 20rem;
    background-color: #fff8f1
}

.section-name-wrapper {
    display: flex;
    height: 1.5rem;
    padding-right: 1rem;
    padding-bottom: 1px;
    padding-left: 1rem;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #545454; */
    border-radius: 20rem
}

.text-span-7,
.text-span-8 {
    color: #fff
}

.footer-wrapper {
    padding-bottom: 0
}

.footer-bottom-links {
    position: relative;
    display: flex;
    overflow: hidden;
    height: 3rem;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: space-between;
    align-items: center
}

.footer-legal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 0.5rem
}

.footer-links-full-width {
    display: flex;
    width: 100%;
    padding-left: 1.5rem;
    justify-content: space-between;
    align-items: center
}

.footer-mid-details {
    position: relative;
    display: flex;
    overflow: hidden;
    height: 30rem;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: space-between;
    align-items: center
}

.footer-details-full-width {
    display: flex;
    width: 100%;
    height: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    justify-content: space-between;
    align-items: center
}

.footer-left-wrapper {
    display: flex;
    width: 44vw;
    height: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    flex-direction: column;
    justify-content: space-between
}

.footer-right-wrapper {
    display: flex;
    width: 47vw;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: space-between;
    align-items: flex-start;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.quick-links {
    display: flex;
    width: 50%;
    padding-left: 1rem;
    flex-direction: column;
    justify-content: flex-start
}

.social-links {
    display: flex;
    width: 50%;
    padding-right: 0;
    padding-left: 1rem;
    flex-direction: column;
    justify-content: flex-start
}

.footer-logo {
    width: 18.75rem;
    height: 2.1875rem;
    margin-bottom: 3rem
}

.button-group-footer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem
}

.text-span-10 {
    color: #5a5a5a
}

.text-span-13 {
    color: #ababab
}

.about-hero-info-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start
}

.about-hero-info-wrapper.margin-top.margin-large {
    justify-content: space-between
}


.about-hero-info-wrapper.margin-top.margin-large.max-width-custom {
    /* max-width: 93vw */
    /* width: 500px; */
}

.about-image-wrapper {
    overflow: hidden;
    width: 100%;
    /* height: 44vh; */
    margin-top: -20vh;
    margin-right: auto;
    margin-left: auto;
    /* border-radius: 10px */
}

.header_content-copy {
    position: static;
    top: 0;
    z-index: 0;
    display: flex;
    width: 96vw;
    max-width: 96vw;
    min-height: 80vh;
    margin-right: auto;
    margin-left: auto;
    /* padding-top: 9rem; */
    padding-bottom: 5rem;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.about-page-image {
    /* width: 40%; */
    height: 80%;
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover
}

.services-layout_component {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 60vh;
    border-top: 1px solid #545454;
    border-bottom: 1px solid #545454;
    border-left: 1px solid #545454;
    border-radius: 10px
}

.services-layout_accordion {
    display: flex;
    overflow: hidden;
    width: 5rem;
    height: 60vh;
    min-width: 5rem;
    flex-direction: row;
    justify-content: flex-start;
    border-right: 1px solid #545454;
    background: #EDEDED;
}

.services-layout_accordion.active {
    width: 100%
}

.services-layout_column {
    position: relative;
    display: flex;
    width: 5rem;
    height: 60vh;
    min-width: 5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid #545454;
    cursor: pointer
}

.services-layout_column-text {
        /* font-family: Satoshi, sans-serif; */
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 700;
    white-space: nowrap
}

.layout351_column-vertical-text {
    margin-right: auto;
    margin-left: auto;
    transform: rotate(180deg);
     /* font-family: 'Urbanist'; */
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 300;
    text-transform: uppercase;
   
}

.layout351_column-horiztonal-text {
    display: none;
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 700
}

.layout351_column-content {
    overflow: hidden;
    height: 100%;
    min-width: 200vw
}

.layout351_content-wrapper {
    position: relative;
    display: flex;
    width: 37%;
    height: 100%;
    padding: 4rem 3rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 0 0 auto
}

.layout351_image-wrapper {
    overflow: hidden;
    width: 30vw;
    height: 45vh;
    margin-bottom: 0;
    border-radius: 10px
}

.layout351_image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.services-content-wrapper {
    position: static;
    display: flex
}

.process-layout_component {
    display: -ms-grid;
    display: grid;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 5rem;
    grid-row-gap: 4rem;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    /* grid-template-rows: auto */
}

.process-layout_content-left {
    position: -webkit-sticky;
    position: sticky;
    top: 30%
}

.process-layout_content-item {
    position: -webkit-sticky;
    position: sticky;
    margin-bottom: 2rem;
    padding: 2rem;
    border: 1px solid #545454;
    color: #000;
    border-radius: 10px;
    background-color: #EDEDED
}

.process-layout_content-item.content-item-1 {
    top: 30%;
    border-color: #545454;
    border-radius: 10px;
    background-color: #EDEDED
    /* background: #EDEDED */
}

.process-layout_content-item.content-item-2 {
    top: 32%;
    background-color: #EDEDED
}

.process-layout_content-item.content-item-3 {
    top: 34%
}

.process-layout_content-item.content-item-4 {
    top: 36%
}

.about-section-info-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start
}

.about-section-info-wrapper.margin-top.margin-large {
    justify-content: space-between
}

.about-section-copy {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    grid-column-gap: 4rem
}

.arrow-svg {
    position: static;
    left: 0;
    top: 0;
    right: auto;
    bottom: auto;
    width: 6.25rem;
    height: 6.25rem;
    opacity: .5
}

.about-intro {
    display: flex;
    grid-column-gap: 4rem
}

.projects-layout_component {
    grid-column-gap: 2rem;
    grid-row-gap: 1.5rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.projects-layout_row {
    display: -ms-grid;
    display: grid;
    align-items: stretch;
    grid-auto-columns: 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1rem;
    -ms-grid-columns: 1fr 1px 1fr 1px 1fr;
    grid-template-columns: 1fr 1px 1fr 1px 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.projects-layout_card {
    display: flex;
    overflow: visible;
    flex-direction: column;
    align-items: stretch;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border: 1px solid #1d1d1d;
    border-radius: 10px
}

.projects-layout_card.half-top {
    height: 65%
}

.projects-layout_card.half-bottom {
    height: 65%;
    justify-content: flex-end
}

.project-layout_image-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 65vh;
    padding: 1px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 10px
}

.content_component {
    display: -ms-grid;
    display: grid;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 4rem;
    -ms-grid-columns: 1fr 1px 1fr;
    grid-template-columns: 1fr 1px 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.contact_content {
    height: 100%
}

.contact_contact-list {
    display: -ms-grid;
    display: grid;
    max-width: 35rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    grid-auto-columns: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.contact_item {
    display: flex
}

.contact_icon-wrapper {
    margin-right: 1rem;
    align-self: flex-start;
    flex: 0 0 auto
}

.contact_form-block {
    margin-bottom: 0;
    flex-direction: column;
    align-items: stretch
}

.contact_form {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.form-field-col {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 1.5rem;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.success-message {
    padding: 1.5rem;
    background-color: #f4f4f4
}

.success-text {
    color: #1d1d1d;
    font-weight: 600
}

.error-message {
    margin-top: 1.5rem;
    padding: .875rem 1rem
}

.error-text {
    color: #e23939
}

.contact-details-icon {
    display: flex;
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    border: 1px #000;
    border-radius: 20rem;
    background-color: black
}

.text-span-14,
.text-span-15,
.text-span-16,
.text-span-17,
.text-span-18 {
    color: #ababab
}

.contact-layout_row {
    display: -ms-grid;
    display: grid;
    align-items: stretch;
    grid-auto-columns: 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1rem;
    -ms-grid-columns: 1fr 1px 1fr;
    grid-template-columns: 1fr 1px 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.contact-layout_row.row-mobile {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr
}

.contact-layout_card {
    display: flex;
    overflow: visible;
    flex-direction: column;
    align-items: stretch;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    /* border: 1px solid #1d1d1d; */
    border-radius: 10px
}

.contact-layout_image-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 70vh;
    padding: 1px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 10px
}

.text-span-19 {
        /* font-family: Satoshi, sans-serif; */
    font-weight: 500;
    text-decoration: underline
}

.section_header-project {
    overflow: hidden;
    padding-top: 5.5rem
}

.project_header_component {
    display: flex;
    flex-direction: column
}

.project-header_image-wrapper {
    position: relative;
    overflow: hidden;
    width: 96vw;
    margin-right: auto;
    margin-left: auto;
    flex: 1
}

.project-header_background-image-wrapper {
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
    width: 96vw;
    height: 100vh;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid #1d1d1d;
    border-radius: 10px
}

.project-header_background-image {
    position: static;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.project-header_background-image.parallax-image {
    overflow: hidden;
    border-radius: 10px
}

.project-header_content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 5rem;
    grid-row-gap: 1.5rem;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.project-small-details {
    display: flex;
    height: 2rem;
    padding-right: 1rem;
    padding-bottom: 1px;
    padding-left: 1rem;
    justify-content: center;
    align-items: center;
    border: 1px solid #545454;
    border-radius: 20rem
}

.project-layout-component {
    height: 75vh;
    grid-column-gap: 2rem;
    grid-row-gap: 1.5rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.project-layout_row {
    display: -ms-grid;
    display: grid;
    align-items: stretch;
    grid-auto-columns: 1fr;
    grid-column-gap: 7rem;
    grid-row-gap: 1rem;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.project-layout_card {
    display: flex;
    overflow: visible;
    height: 75vh;
    flex-direction: column;
    align-items: stretch;
    grid-auto-columns: 1fr;
    grid-row-gap: 7rem;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border: 1px solid #1d1d1d;
    border-radius: 10px
}

.layout_project-image-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 75vh;
    padding: 1px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 10px
}

.layout_project-image-wrapper.layout-left {
    width: 65%
}

.layout_project-image-wrapper.layout-right {
    width: 50%;
    align-self: flex-end
}

.project-layout_image {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover
}

.gallery_component {
    height: 400vh
}

.gallery_horizontal-scroll-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 2rem;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    padding-right: 5%;
    padding-left: 2%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start
}

.gallery_horizontal-scroll-content {
    display: flex;
    width: 400vh;
    align-items: center;
    grid-column-gap: 2rem
}

.gallery_image-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-width: 100%
}

.gallery_image {
    width: 80vw;
    height: 80vh;
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover
}

.project-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-row-gap: 2rem
}

.footer-links-full-width-ro {
    display: flex;
    width: 100%;
    padding-left: 1.5rem;
    justify-content: space-between;
    align-items: center
}

.footer-legal-ro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 0.5rem
}

.cookies-wrapper {
    display: flex
}

.fs-cc-banner2_component {
    position: fixed;
    left: 2.5%;
    top: auto;
    right: 2.5%;
    bottom: 1rem;
    z-index: 1500;
    display: none;
    height: auto;
    max-width: none;
    margin-right: auto;
    margin-left: auto;
    padding: .5rem .5rem .5rem 1rem;
    justify-content: space-between;
    border: 1px #909090;
    border-radius: 100rem;
    background-color: #fff;
    color: #fff
}

.fs-cc-banner2_container {
    display: flex;
    width: 100%;
    max-width: none;
    margin-right: auto;
    margin-left: auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 2rem;
    grid-row-gap: 5px
}

.fs-cc-banner2_text {
     /* font-family: 'Urbanist'; */
    color: #1d1d1d;
    font-size: .875rem
}

.fs-cc-banner2_buttons-wrapper {
    display: flex;
    margin-top: 0;
    align-items: center;
    flex: 0 0 auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 1.25rem;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.fs-cc-banner_text-link {
    margin-right: 0;
    border-bottom: 1px #e7e7e7;
     /* font-family: 'Urbanist'; */
    color: #000;
    font-size: .875rem;
    font-weight: 300;
    text-decoration: none
}

.fs-cc-banner2_button {
    min-width: 6rem;
    margin-right: 0;
    margin-left: 0;
    padding: .125rem 1.25rem;
    flex: 1;
    border-radius: 100px;
    background-color: #1d1d1d;
     /* font-family: 'Urbanist'; */
    color: #fff;
    font-size: .875rem;
    font-weight: 400;
    text-align: center;
    text-transform: none
}

.fs-cc-banner2_button.fs-cc-button-alt {
    min-width: 6rem;
    margin-right: 0;
    background-color: #ababab;
     /* font-family: 'Urbanist'; */
    color: #fff;
    font-weight: 400
}

.fs-cc-prefs_component {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 997;
    display: none;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.fs-cc-prefs_form {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 70vh;
    max-width: 36rem;
    margin-bottom: 0;
    border-radius: .625rem;
    background-color: #fff;
        /* font-family: Satoshi, sans-serif; */
    color: #121212
}

.fs-cc-prefs_close {
    position: absolute;
    left: auto;
    top: -.75rem;
    right: -.75rem;
    bottom: auto;
    z-index: 1;
    padding: .625rem;
    border-radius: 100%;
    background-color: #ababab;
    box-shadow: -1px 1px 12px 0 rgba(51, 51, 51, .1);
    color: #333;
    font-size: 1.25rem;
    text-decoration: none;
    cursor: pointer
}

.fs-cc-prefs_close-icon {
    width: 1rem;
    height: 1rem;
    color: #fff
}

.fs-cc-prefs_content {
    overflow: visible;
    height: 100%;
    padding: 2.5rem 2rem
}

.fs-cc-prefs_space-small {
    margin-bottom: .75rem
}

.fs-cc-prefs_title {
    color: #121212;
    font-size: 1.5rem;
    line-height: 1.2em;
    font-weight: 700
}

.fs-cc-prefs_text {
    color: #656565;
    font-size: .75rem
}

.fs-cc-prefs_space-medium {
    margin-bottom: 2.5rem
}

.fs-cc-prefs_button {
    margin-right: .5rem;
    padding: .625rem 1.5rem;
    border: 1px solid #121212;
    border-radius: 999rem;
    background-color: #1d1d1d;
        /* font-family: Satoshi, sans-serif; */
    color: #fff;
    font-size: .875rem;
    font-weight: 700
}

.fs-cc-prefs_button.fs-cc-button-alt {
    border: 1px #cacaca;
    background-color: #e7e7e7;
        /* font-family: Satoshi, sans-serif; */
    color: #121212
}

.fs-cc-prefs_option {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(51, 51, 51, .15)
}

.fs-cc-prefs_toggle-wrapper {
    display: flex;
    margin-bottom: .5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}

.fs-cc-prefs_label {
    margin-top: 0;
    margin-right: 2rem;
    margin-bottom: 0;
        /* font-family: Satoshi, sans-serif; */
    color: #121212;
    font-size: 1rem;
    font-weight: 700
}

.bold-text {
    /* font-family: Satoshi, sans-serif */
}

.fs-cc-prefs_checkbox-field {
    position: relative;
    display: flex;
    width: 2.75rem;
    height: 1.5rem;
    margin-bottom: 0;
    padding: .125rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    border-radius: 999rem;
    background-color: #ccc
}

.fs-cc-prefs_checkbox {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    opacity: 0;
    cursor: pointer
}

.fs-cc-prefs_checkbox-label {
    display: none
}

.fs-cc-prefs_toggle {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 999px;
    background-color: #fff
}

.fs-cc-prefs_buttons-wrapper {
    display: flex;
    margin-top: 2rem;
    margin-right: -.5rem;
    justify-content: flex-end;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.fs-cc-prefs_submit-hide {
    display: none
}

.fs-cc-prefs_overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(3, 5, 36, .6)
}

.fs-cc-prefs_trigger {
    display: none
}

@media screen and (max-width:991px) {
    h1 {
        font-size: 3.25rem
    }
    h2 {
        font-size: 2.75rem
    }
    h3 {
        font-size: 2.25rem
    }
    h4 {
        font-size: 1.75rem
    }
    .max-width-full.text-align-left.horizontal-align {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 1.5rem
    }
    .max-width-full.text-align-left.horizontal-align.align-top.grid-project-intro {
        display: flex;
        grid-row-gap: 2.5rem
    }
    .max-width-full.text-align-left.horizontal-align.horizontal-responsive {
        flex-direction: row
    }
    .max-width-full.text-align-left.vertical-align {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 1.5rem
    }
    .hide-tablet {
        display: none
    }
    .max-width-full-tablet {
        width: 100%;
        max-width: none
    }
    .margin-large {
        margin: 2.5rem
    }
    .margin-xlarge {
        margin: 3.5rem
    }
    .margin-xxlarge {
        margin: 4.5rem
    }
    .margin-huge {
        margin: 5rem
    }
    .margin-xhuge {
        margin: 6rem
    }
    .margin-xxhuge {
        margin: 7.5rem
    }
    .padding-large {
        padding: 2.5rem
    }
    .padding-xlarge {
        padding: 3.5rem
    }
    .padding-xxlarge {
        padding: 4.5rem
    }
    .padding-huge {
        padding: 5rem
    }
    .padding-xhuge {
        padding: 6rem
    }
    .padding-xxhuge {
        padding: 7.5rem
    }
    .margin-top {
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0
    }
    .margin-bottom {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0
    }
    .margin-bottom.align-horizontal {
        flex-direction: column;
        grid-row-gap: 1.5rem
    }
    .margin-left {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0
    }
    .margin-right {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0
    }
    .margin-right.margin-huge.responsive-max-width {
        width: 100%
    }
    .margin-vertical {
        margin-right: 0;
        margin-left: 0
    }
    .margin-horizontal {
        margin-top: 0;
        margin-bottom: 0
    }
    .padding-top {
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0
    }
    .padding-bottom {
        padding-top: 0;
        padding-right: 0;
        padding-left: 0
    }
    .padding-left {
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0
    }
    .padding-right {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0
    }
    .padding-vertical {
        padding-right: 0;
        padding-left: 0
    }
    .padding-horizontal {
        padding-top: 0;
        padding-bottom: 0
    }
    .text-size-medium.max-width-medium.text-weight-light.full-width-respomsive,
    .text-size-medium.max-width-xxsmall.text-style-allcaps.text-colour-grey.text-weight-medium.responsive-full-width {
        max-width: none;
        font-weight: 100;
    }
    .heading-style-h2,
    .heading-style-h2._8vw-title.project-intro-title {
        font-size: 2.75rem
    }
    .heading-style-h2.about-title {
        font-size: 6.4vw
    }
    .rl-styleguide_icons-list {
        grid-auto-flow: row
    }
    .heading-style-h1 {
        font-size: 3.25rem
    }
    .heading-style-h4 {
        font-size: 1.75rem
    }
    .heading-style-h3 {
        font-size: 2.25rem
    }
    .form-input::-moz-placeholder {
        font-size: 16px
    }
    .form-input::-ms-input-placeholder {
        font-size: 16px
    }
    .form-input::placeholder {
        font-size: 16px
    }
    .button-group.margin-left.margin-xsmall.no-margin-responsive {
        margin-left: 0;
        justify-content: center
    }
    .button-group.margin-top.margin-large.absolute-button {
        position: static
    }
    .text-size-small.text-style-allcaps.text-align-right-mobile {
        text-align: right
    }
    .padding-global {
        padding-right: 5%;
        padding-left: 5%
    }
    .padding-section-medium {
        padding-top: 4rem;
        padding-bottom: 4rem
    }
    .padding-section-large {
        padding-top: 6rem;
        padding-bottom: 6rem
    }
    .menu-icon1_line-middle {
        display: flex;
        width: 24px;
        height: 2px;
        margin-top: 6px;
        margin-bottom: 6px;
        padding-right: 0;
        padding-bottom: 0;
        justify-content: center;
        align-items: center;
        background-color: #fff8f1
    }
    .navbar1_dropdown-link {
        width: auto;
        padding: .75rem 0 .75rem 5%
    }
    .navbar1_menu {
        position: absolute;
        overflow: auto;
        padding: 1rem 5% 2rem;
        border-bottom: 1px solid #000;
        background-color: #fff8f1;
        background-clip: border-box;
        -webkit-text-fill-color: inherit
    }
    .navbar1_menu.is-page-height-tablet {
        overflow: hidden;
        height: 20rem;
        margin-top: 5%;
        margin-right: 5%;
        margin-left: 5%;
        padding-top: 3.5rem;
        padding-bottom: 15rem;
        border-radius: 10px
    }
    .menu-icon1_line-bottom {
        width: 24px;
        height: 2px;
        padding-right: 0;
        padding-bottom: 0;
        background-color: #fff8f1
    }
    .navbar1_menu-dropdown {
        width: 100%;
        font-size: 1.125rem
    }
    .navbar1_menu-button.w--open {
        background-color: transparent
    }
    .menu-icon1 {
        display: flex;
        width: 48px;
        height: 48px;
        margin-right: -.5rem;
        padding-right: 0;
        padding-bottom: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }
    .navbar1_dropdown-list {
        position: static;
        overflow: hidden
    }
    .navbar1_dropdown-list.w--open {
        padding: 0;
        border-style: none
    }
    .navbar1_dropdown-toggle {
        display: flex;
        padding-top: .75rem;
        padding-bottom: .75rem;
        padding-left: 0;
        align-items: center
    }
    .menu-icon1_line-top {
        width: 24px;
        height: 2px;
        padding-right: 0;
        padding-bottom: 0;
        background-color: #fff8f1
    }
    .navbar1_component {
        padding-right: 5%;
        padding-left: 5%
    }
    .dropdown-icon {
        left: auto;
        top: auto;
        right: 0;
        bottom: auto;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0
    }
    .navbar1_link {
        width: auto;
        padding: .75rem 0;
        color: #1d1d1d;
        font-size: 1.125rem
    }
    .navbar1_link.text-colour-xx {
        width: 5.5rem;
        height: 1.5rem;
        margin-left: 0;
        background-color: #fff8f1;
        color: #1d1d1d
    }
    .navbar1_link.language-ro {
        width: 5.5rem;
        height: 1.5rem;
        color: #fff8f1
    }
    .navbar1_link.language-ro:hover {
        background-color: transparent
    }
    .navbar1_link.link-mobile {
        margin-bottom: 1rem
    }
    .navbar1_link.all-caps-link {
        padding-top: 0;
        padding-bottom: 0;
        color: #fff8f1;
        font-size: .9rem
    }
    .navbar1_link.all-caps-link._10--margin,
    .navbar1_link.all-caps-link._9--margin {
        margin-right: 0;
        margin-left: 0;
        padding-top: 0;
        padding-bottom: 0;
        order: 1;
        font-size: .9rem
    }
    .navbar1_link.responsive-nav-links {
        background-color: #fff8f1;
        text-align: center
    }
    .navbar1_link.responsive-nav-links.w--current {
        border-style: none;
        color: #1d1d1d
    }
    .menu-icon_line-middle-inner {
        width: 4px;
        height: 0;
        padding-right: 0;
        padding-bottom: 0
    }
    .header_content-bottom {
        justify-content: center
    }
    .header_content {
        width: 90vw;
        min-height: auto;
        padding-top: 10rem;
        padding-bottom: 6rem
    }
    .custom-h1 {
        font-size: 6.7vw
    }
    .hero-info-wrapper {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 2rem
    }
    .hero-info-wrapper.vertical-align-bottom {
        align-items: flex-start
    }
    .white-line.scroll-into.hide-mobile-portrait {
        display: none
    }
    .layout_card-content {
        padding: 2rem 0
    }
    .layout_image-wrapper {
        height: 100%
    }
    .layout_row {
        -ms-grid-columns: 1fr 1px 1fr;
        grid-template-columns: 1fr 1px 1fr
    }
    .layout_component {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .vertical-line {
        flex-direction: column
    }
    .vertical-line.horizontal-line-responsive {
        width: 100%;
        height: 1px;
        flex-direction: row
    }
    .vertical-line.horizontal-line-responsive.hide-mobile-portrait {
        display: none
    }
    .vertical-line.hide-mobile-portrait {
        display: block
    }
    .language-wrapper {
        border-color: #1d1d1d
    }
    .language-wrapper.no-margin-responsive {
        width: 11.5rem;
        margin-top: 2rem;
        margin-left: 0
    }
    .language-wrapper.no-margin-responsive.mobile-language {
        display: flex;
        width: 9rem;
        height: 2rem;
        margin-top: 0
    }
    .footer-bottom-links {
        height: auto;
        padding-top: 0;
        padding-bottom: 0
    }
    .footer-legal {
        grid-column-gap: 0.5rem
    }
    .footer-links-full-width {
        display: -ms-grid;
        display: grid;
        padding: 2rem 0;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        grid-auto-columns: 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 1.5rem;
        -ms-grid-columns: 1fr 0.75fr;
        grid-template-columns: 1fr 0.75fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
        border-bottom: 1px solid #545454
    }
    .footer-mid-details {
        height: auto
    }
    .footer-details-full-width {
        padding-right: 0;
        padding-left: 0;
        flex-direction: column
    }
    .footer-left-wrapper {
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-top: 1px solid #545454;
        border-bottom: 1px solid #545454
    }
    .footer-right-wrapper {
        width: 100%;
        flex-direction: column;
        grid-row-gap: 0px
    }
    .quick-links {
        width: 100%;
        padding-bottom: 2rem;
        padding-left: 0;
        flex-direction: column
    }
    .social-links {
        width: 100%;
        padding: .5rem 0 .7rem;
        flex-direction: column;
        justify-content: space-between
    }
    .button-group-footer.margin-top.margin-small {
        flex-direction: row
    }
    .button-group-footer.margin-top.margin-small.button-group-horizontal {
        grid-column-gap: 1rem
    }
    .about-hero-info-wrapper {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 2rem
    }
    .about-image-wrapper {
        /* height: 40vh */
    }
    .header_content-copy {
        width: 90vw;
        min-height: auto;
        padding-top: 10rem;
        padding-bottom: 6rem
    }
    .services-layout_component {
        height: auto;
        flex-direction: column;
        border-top: 0 #545454;
        border-right: 1px solid #545454
    }
    .services-layout_accordion {
        width: 100%;
        height: 5rem;
        min-width: auto;
        flex-direction: column;
        border-right-style: none
    }
    .services-layout_accordion.active {
        height: 45rem
    }
    .services-layout_column {
        width: 100%;
        height: 5rem;
        min-width: 100%;
        flex-direction: row;
        justify-content: center;
        border-top: 1px solid #545454;
        border-bottom: 1px solid #545454
    }
    .services-layout_column-text {
        position: absolute;
        left: 2.5rem
    }
    .layout351_column-vertical-text {
        display: none
    }
    .layout351_column-horiztonal-text {
        display: block;
         /* font-family: 'Urbanist'; */
        font-size: 1.5rem;
        font-weight: 300;
        /* margin-top: 2rem; */
        text-transform: uppercase
    }
    .layout351_column-content {
        width: 100%;
        min-width: auto
    }
    .layout351_content-wrapper {
        width: auto;
        padding: 3rem 2.5rem
    }
    .layout351_image-wrapper {
        width: 100%;
        height: 40vh;
        margin-bottom: 0
    }
    .services-content-wrapper {
        flex-direction: column;
        grid-row-gap: 2rem
    }
    .process-layout_component {
        min-height: auto;
        grid-auto-flow: row;
        grid-column-gap: 3rem;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }
    .about-section-info-wrapper {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 2rem
    }
    .about-section-copy {
        flex-direction: column;
        grid-row-gap: 2rem
    }
    .arrow-svg {
        display: none;
        order: 1
    }
    .projects-layout_component {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .projects-layout_row {
        -ms-grid-columns: 1fr 1px 1fr;
        grid-template-columns: 1fr 1px 1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto
    }
    .projects-layout_card.half-bottom,
    .projects-layout_card.half-top {
        height: auto
    }
    .project-layout_image-wrapper {
        height: 100%
    }
    .content_component {
        min-height: auto;
        grid-auto-flow: row;
        grid-column-gap: 3rem
    }
    .content_component.margin-vertical.margin-medium {
        grid-row-gap: 2rem;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .contact-layout_row,
    .contact-layout_row.row-mobile {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .contact-layout_image-wrapper {
        height: 100%
    }
    .project-header_background-image-wrapper {
        height: 50vh
    }
    .project-header_content-wrapper {
        grid-column-gap: 3rem;
        grid-row-gap: 2rem
    }
    .project-layout-component {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .project-layout-component.margin-top.margin-xhuge {
        height: auto
    }
    .project-layout_row {
        grid-row-gap: 3rem;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .project-layout_card {
        height: 50vh
    }
    .project-layout_card.mobile-80vh {
        height: 90vh;
        grid-row-gap: 3rem
    }
    .layout_project-image-wrapper {
        height: 100%
    }
    .layout_project-image-wrapper.layout-left,
    .layout_project-image-wrapper.layout-right {
        width: 70%;
        height: 45vh
    }
    .gallery_horizontal-scroll-content {
        width: 300vh
    }
    .gallery_image {
        max-height: 40rem
    }
    .footer-links-full-width-ro {
        display: -ms-grid;
        display: grid;
        padding: 2rem 0;
        flex-direction: row;
        justify-content: start;
        align-items: flex-start;
        grid-auto-columns: 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 1.5rem;
        -ms-grid-columns: 0.75fr;
        grid-template-columns: 0.75fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
        border-bottom: 1px solid #545454
    }
    .footer-legal-ro {
        grid-column-gap: 0.5rem
    }
    .cookies-wrapper {
        display: block
    }
    .fs-cc-banner2_component {
        z-index: 1001;
        display: none;
        width: auto;
        padding: 1rem;
        border-radius: .7rem
    }
    .fs-cc-banner2_container {
        align-items: center
    }
    .fs-cc-banner2_text {
        text-align: left
    }
    .fs-cc-prefs_component {
        z-index: 1000;
        display: none
    }
    .fs-cc-prefs_buttons-wrapper {
        justify-content: flex-start
    }
}

@media screen and (max-width:767px) {
    h1 {
        font-size: 2.5rem
    }
    h2 {
        font-size: 2.25rem
    }
    h3 {
        font-size: 2rem
    }
    h4 {
        font-size: 1.5rem;
        line-height: 1.4
    }
    h5 {
        font-size: 1.25rem
    }
    h6 {
        font-size: 1.125rem
    }
    .hide-mobile-landscape {
        display: none
    }
    .max-width-full-mobile-landscape {
        width: 100%;
        max-width: none
    }
    .margin-xsmall {
        margin: .75rem
    }
    .margin-small {
        margin: 1.25rem
    }
    .margin-medium {
        margin: 1.5rem
    }
    .margin-large {
        margin: 2rem
    }
    .margin-xlarge {
        margin: 2.5rem
    }
    .margin-xxlarge {
        margin: 3rem
    }
    .margin-huge {
        margin: 3.5rem
    }
    .margin-xhuge {
        margin: 4rem
    }
    .margin-xxhuge {
        margin: 5rem
    }
    .padding-xsmall {
        padding: .75rem
    }
    .padding-small {
        padding: 1.25rem
    }
    .padding-medium {
        padding: 1.5rem
    }
    .padding-large {
        padding: 2rem
    }
    .padding-xlarge {
        padding: 2.5rem
    }
    .padding-xxlarge {
        padding: 3rem
    }
    .padding-huge {
        padding: 3.5rem
    }
    .padding-xhuge {
        padding: 4rem
    }
    .padding-xxhuge {
        padding: 5rem
    }
    .margin-top {
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0
    }
    .margin-bottom {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0
    }
    .margin-left {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0
    }
    .margin-right {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0
    }
    .margin-vertical {
        margin-right: 0;
        margin-left: 0
    }
    .margin-horizontal {
        margin-top: 0;
        margin-bottom: 0
    }
    .padding-top {
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0
    }
    .padding-bottom {
        padding-top: 0;
        padding-right: 0;
        padding-left: 0
    }
    .padding-left {
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0
    }
    .padding-right {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0
    }
    .padding-vertical {
        padding-right: 0;
        padding-left: 0
    }
    .padding-horizontal {
        padding-top: 0;
        padding-bottom: 0
    }
    .text-size-medium {
        font-size: 1.5rem
    }
    .text-style-quote {
        font-size: 1.125rem
    }
    .heading-style-h2,
    .heading-style-h2._8vw-title.project-intro-title {
        font-size: 2.25rem
    }
    .heading-style-h6 {
        font-size: 1.125rem
    }
    .heading-style-h5 {
        font-size: 1.25rem
    }
    .heading-style-h1 {
        font-size: 2.5rem
    }
    .heading-style-h4 {
        font-size: 1.5rem;
        line-height: 1.4
    }
    .heading-style-h3 {
        font-size: 2rem
    }
    .text-style-nowrap {
        white-space: normal
    }
    .text-size-large {
        font-size: 1.125rem
    }
    .padding-section-small {
        padding-top: 2rem;
        padding-bottom: 2rem
    }
    .padding-section-medium {
        padding-top: 3rem;
        padding-bottom: 3rem
    }
    .padding-section-large {
        padding-top: 4rem;
        padding-bottom: 4rem
    }
    .navbar1_component {
        height: auto;
        min-height: 4rem
    }
    .navbar1_logo-link {
        padding-left: 0
    }
    .header_content {
        padding-top: 7rem;
        padding-bottom: 4rem
    }
    .layout_card-content {
        padding: 1.5rem
    }
    .layout_row {
        grid-column-gap: 1.5rem;
        grid-row-gap: 2.5rem;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .layout_card {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .layout_component {
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem
    }
    .vertical-line {
        width: 100%;
        height: 1px;
        flex-direction: row
    }
    .vertical-line.hide-mobile-portrait {
        display: none
    }
    .about-info-wrapper {
        padding-right: 0
    }
    .footer-links-full-width {
        display: flex;
        flex-direction: column;
        grid-row-gap: 1rem
    }
    .footer-mid-details {
        flex-direction: column
    }
    .social-links {
        padding-bottom: 2rem
    }
    .header_content-copy {
        padding-top: 7rem;
        padding-bottom: 4rem
    }
    .services-layout_accordion,
    .services-layout_column {
        height: 4rem
    }
    .services-layout_column-text {
        left: 1.5rem;
        font-size: 1.25rem
    }
    .layout351_column-horiztonal-text,
    .layout351_column-vertical-text {
        /* font-size: 1.25rem */
        /* margin-top: 2rem; */
    }
    .layout351_content-wrapper {
        padding: 1rem 1.5rem 2rem
    }
    .layout351_image {
        height: 20rem
    }
    .process-layout_component {
        grid-row-gap: 3rem;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .process-layout_content-left {
        position: static
    }
    .about-intro {
        flex-direction: column;
        grid-row-gap: 2rem
    }
    .projects-layout_component {
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem
    }
    .projects-layout_row {
        grid-column-gap: 1.5rem;
        grid-row-gap: 2.5rem;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .projects-layout_card {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .content_component {
        grid-row-gap: 3rem;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .content_component.margin-vertical.margin-medium {
        grid-row-gap: 4rem
    }
    .form-field-col.is-mobile-1col {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .contact-layout_row {
        grid-column-gap: 1.5rem;
        grid-row-gap: 2.5rem;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .contact-layout_card {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .project_header_component {
        height: auto;
        max-height: none
    }
    .project-header_content-wrapper {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .project-layout-component {
        height: auto;
        grid-column-gap: 1.5rem;
        grid-row-gap: 1.5rem
    }
    .project-layout_row {
        grid-column-gap: 1.5rem;
        grid-row-gap: 2.5rem;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .project-layout_card {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .gallery_horizontal-scroll-wrapper {
        margin-top: -10vh
    }
    .gallery_horizontal-scroll-content {
        width: 200vh;
        grid-column-gap: 1.5rem
    }
    .gallery_image {
        width: 90vw;
        max-height: 30rem
    }
    .footer-links-full-width-ro {
        display: flex;
        flex-direction: column;
        grid-row-gap: 1rem
    }
    .cookies-wrapper {
        display: block
    }
    .fs-cc-banner2_component {
        display: none;
        width: auto
    }
    .fs-cc-banner2_container {
        flex-direction: column;
        align-items: center;
        grid-row-gap: 1.5rem
    }
    .fs-cc-banner2_text {
        text-align: center
    }
    .fs-cc-prefs_title {
        font-size: 1.25rem
    }
}

@media screen and (max-width:479px) {
    h1 {
        font-size: 2.5rem
    }
    .max-width-full {
        width: 100%
    }
    .max-width-full.text-align-left.horizontal-align {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 1rem
    }
    .max-width-full.text-align-left.horizontal-align.align-top.grid-project-intro {
        grid-row-gap: 1.5rem
    }
    .max-width-full.text-align-left.vertical-align {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 1rem
    }
    .hide-mobile-portrait {
        display: none
    }
    .margin-top {
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0
    }
    .margin-bottom {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0
    }
    .margin-bottom.margin-xsmall.horizontal-align.mobile-vertical {
        padding-top: 0;
        flex-direction: column;
        grid-row-gap: 2rem
    }
    .margin-bottom.align-horizontal {
        flex-direction: column;
        grid-row-gap: 1.25rem
    }
    .margin-left {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0
    }
    .margin-right {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0
    }
    .margin-vertical {
        margin-right: 0;
        margin-left: 0
    }
    .margin-horizontal {
        margin-top: 0;
        margin-bottom: 0
    }
    .padding-top {
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0
    }
    .padding-bottom {
        padding-top: 0;
        padding-right: 0;
        padding-left: 0
    }
    .padding-left {
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0
    }
    .padding-right {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0
    }
    .padding-vertical {
        padding-right: 0;
        padding-left: 0
    }
    .padding-horizontal {
        padding-top: 0;
        padding-bottom: 0
    }
    .max-width-full-mobile-portrait {
        width: 100%;
        max-width: none
    }
    .heading-style-h2._8vw-title,
    .heading-style-h2._8vw-title.project-intro-title {
        font-size: 2.62rem
    }
    .heading-style-h2._8vw-title.project-title {
        padding-right: 1rem;
        padding-left: 1rem;
        font-size: 7.35vw
    }
    .heading-style-h2.about-title {
        font-size: 1.62rem;
        margin-left: 0rem;
    }
    .text-size-regular.text-style-allcaps._0-875rem-text {
        font-size: .875rem
    }
    .heading-style-h3.text-style-allcaps.margin-bottom.margin-medium.mobile-title-8vw {
        font-size: 1.5rem
    }
    .form-input.is-text-area {
        min-height: 17rem;
        font-size: 16px
    }
    .button-group.margin-left.margin-xsmall.no-margin-responsive {
        margin-left: 0
    }
    .button-group.margin-left.margin-xsmall.no-margin-responsive.nav-button-responsive {
        padding-top: .8rem
    }
    .text-size-small.text-style-allcaps {
        order: 1;
        font-size: .8rem
    }
    .text-size-small.text-style-allcaps.text-align-right-mobile {
        text-align: right
    }
    .text-size-small.text-style-allcaps._0-9rem-text {
        order: -1;
        font-size: .8rem
    }
    .padding-global {
        padding-right: 5%;
        padding-left: 5%
    }
    .menu-icon1_line-middle {
        background-color: #fff8f1
    }
    .navbar1_menu.is-page-height-tablet {
        overflow: hidden;
        height: 20.3rem;
        margin-top: 5%;
        padding-top: 2.3rem;
        border-radius: 10px
    }
    .menu-icon1_line-bottom,
    .menu-icon1_line-top {
        background-color: #fff8f1
    }
    .navbar1_component {
        padding-right: 5%;
        padding-left: 5%
    }
    .navbar1_link.text-colour-xx {
        width: 5.5rem;
        height: 1rem;
        margin-left: 0;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #fff8f1;
        color: #1d1d1d;
        font-size: .9rem
    }
    .navbar1_link.language-ro {
        width: 5.5rem;
        height: 1rem;
        padding-top: 0;
        padding-bottom: 0;
        font-size: .9rem
    }
    .navbar1_link.link-mobile {
        margin-bottom: 1rem
    }
    .navbar1_link.all-caps-link {
        padding-top: 0;
        padding-bottom: 0;
        font-size: .8rem
    }
    .navbar1_link.all-caps-link._10--margin,
    .navbar1_link.all-caps-link._9--margin {
        order: 1;
        color: #ababab;
        font-size: .8rem
    }
    .navbar1_link.responsive-nav-links {
        margin-bottom: .5rem
    }
    .navbar1_logo {
        max-width: 85%;
        margin-bottom: 2px
    }
    .header_content {
        width: 90vw;
        padding-top: 7rem
    }
    .custom-h1 {
        font-size: 8.35vw
    }
    .hero-info-wrapper {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 2rem
    }
    .hero-info-wrapper.vertical-align-bottom {
        align-items: flex-start
    }
    .white-line.scroll-into {
        height: 1px
    }
    .white-line.not-visible-mobile {
        display: none
    }
    .white-line.not-visible-mobile.about-line {
        display: block;
        width: 90vw
    }
    .layout_card-content {
        padding-right: 0;
        padding-left: 0
    }
    .layout_image-wrapper {
        height: 40vh
    }
    .layout_row {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto 1px auto;
        grid-template-rows: auto 1px auto
    }
    .layout_row.row-mobile {
        -ms-grid-rows: auto;
        grid-template-rows: auto
    }
    .vertical-line {
        width: 100%;
        height: 1px;
        flex-direction: column
    }
    .vertical-line.hide-mobile-portrait {
        display: none
    }
    .language-wrapper.no-margin-responsive {
        width: 11.5rem;
        margin-top: 2rem;
        margin-left: 0
    }
    .language-wrapper.no-margin-responsive.mobile-language {
        width: 6rem;
        height: 1.5rem
    }
    .about-info-wrapper {
        padding-right: 0
    }
    .footer-bottom-links {
        padding-top: 0;
        padding-bottom: 0
    }
    .footer-legal {
        order: -1;
        grid-column-gap: 1.5rem
    }
    .footer-links-full-width {
        display: flex;
        padding: 2rem 0;
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 1rem;
        border: 1px #545454
    }
    .footer-mid-details {
        height: auto
    }
    .footer-details-full-width {
        padding-right: .1rem;
        padding-left: .1rem
    }
    .footer-left-wrapper {
        padding: 2rem 0;
        border-style: solid none;
        border-width: 1px;
        border-color: #545454
    }
    .footer-right-wrapper {
        grid-row-gap: 0px
    }
    .quick-links {
        padding: 0 0 2rem;
        border: 1px #545454
    }
    .social-links {
        padding: .5rem 0 .7rem;
        flex-direction: column;
        border: 1px #545454
    }
    .footer-logo {
        max-width: 100%
    }
    .button-group-footer.margin-top.margin-small {
        flex-direction: column
    }
    .button-group-footer.margin-top.margin-small.button-group-horizontal {
        flex-direction: row;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem
    }
    .text-span-12 {
        color: #fff8f1
    }
    .about-hero-info-wrapper {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 2rem
    }
    .about-hero-info-wrapper.margin-top.margin-large.max-width-custom {
        max-width: none
    }
    .header_content-copy {
        width: 90vw;
        padding-top: 7rem
    }
    .services-layout_accordion,
    .services-layout_accordion.active {
        height: 4rem
    }
    .services-layout_accordion.active {
        height: 53rem;
    }
    .services-layout_column {
        padding-left: 1rem;
        justify-content: flex-start
    }
    .services-layout_column-text {
        position: static;
        margin-right: 1rem
    }
    .layout351_column-horiztonal-text {
        font-size: 1rem;
        margin-top: -0.5rem;
    }
    .layout351_image {
        height: 40vh
    }
    .process-layout_component {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .process-layout_content-item.content-item-1,
    .process-layout_content-item.content-item-2,
    .process-layout_content-item.content-item-3,
    .process-layout_content-item.content-item-4 {
        padding-right: 1rem;
        padding-left: 1rem
    }
    .about-section-info-wrapper {
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 2rem
    }
    .about-section-copy {
        flex-direction: column;
        grid-row-gap: 2rem
    }
    .arrow-svg {
        display: block;
        width: 3rem;
        height: 3rem;
        order: -1
    }
    .projects-layout_row {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto;
        grid-template-rows: auto
    }
    .project-layout_image-wrapper {
        height: 40vh
    }
    .section_contact {
        padding-top: 2rem
    }
    .content_component {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .contact_content {
        width: 90vw
    }
    .contact_form {
        grid-row-gap: 2rem
    }
    .form-field-col {
        grid-row-gap: 2rem;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .contact-layout_row {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto 1px auto;
        grid-template-rows: auto 1px auto
    }
    .contact-layout_row.row-mobile {
        -ms-grid-rows: auto;
        grid-template-rows: auto
    }
    .contact-layout_image-wrapper {
        height: 40vh
    }
    .section_header-project {
        padding-top: 5rem
    }
    .project-header_background-image-wrapper {
        width: 90vw;
        height: 60vh
    }
    .project-header_content-wrapper {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
    .project-small-details {
        height: 1.5rem
    }
    .project-layout_row {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto;
        grid-template-rows: auto
    }
    .project-layout_card {
        height: 40vh
    }
    .project-layout_card.mobile-80vh {
        height: 85vh;
        grid-row-gap: 2.5rem
    }
    .layout_project-image-wrapper {
        height: 40vh
    }
    .layout_project-image-wrapper.layout-left {
        width: 100%;
        height: 40vh
    }
    .layout_project-image-wrapper.layout-right {
        width: 100%
    }
    .gallery_horizontal-scroll-content {
        width: 150vh
    }
    .gallery_image {
        height: 70vh;
        max-height: 70vh
    }
    .footer-links-full-width-ro {
        display: flex;
        padding: 2rem 0;
        flex-direction: column;
        align-items: flex-start;
        grid-row-gap: 1rem;
        border: 1px #545454
    }
    .footer-legal-ro {
        flex-direction: column;
        align-items: flex-start;
        order: -1;
        grid-column-gap: 1.5rem;
        grid-row-gap: 1rem
    }
    .cookies-wrapper {
        display: block
    }
    .fs-cc-banner2_component {
        left: 3%;
        right: 3%;
        z-index: 1001;
        display: none;
        width: auto;
        height: auto
    }
    .fs-cc-banner2_container {
        grid-row-gap: 1.5rem
    }
    .fs-cc-banner2_text {
        font-size: 12px
    }
    .fs-cc-banner2_buttons-wrapper {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        flex-wrap: nowrap;
        grid-row-gap: 2rem
    }
    .fs-cc-banner_text-link {
        font-size: 14px
    }
    .fs-cc-banner2_button {
        min-width: 4.5rem;
        margin-right: auto;
        margin-left: auto;
        padding-right: .5rem;
        padding-left: .5rem;
        font-size: 14px
    }
    .fs-cc-banner2_button.fs-cc-button-alt {
        min-width: 4rem;
        padding-right: .5rem;
        padding-left: .5rem
    }
    .fs-cc-prefs_component {
        z-index: 1000;
        display: none;
        padding: 1.5rem
    }
    .fs-cc-prefs_content {
        padding: 1.5rem
    }
    .fs-cc-prefs_title {
        font-weight: 500
    }
    .fs-cc-prefs_space-medium {
        display: flex;
        flex-direction: column;
        grid-row-gap: 0.5rem
    }
    .fs-cc-prefs_button {
        width: 100%;
        margin-right: 0;
        margin-bottom: .5rem;
        padding-right: 1rem;
        padding-left: 1rem;
         /* font-family: 'Urbanist'; */
        font-weight: 400;
        text-align: center
    }
    .fs-cc-prefs_buttons-wrapper {
        width: 100%;
        margin-right: 0;
        flex-direction: row;
        justify-content: flex-start
    }
    .text-span-20,
    .text-span-21,
    .text-span-22,
    .text-span-23 {
        color: #fff
    }
}

#w-node-_00f3c34e-c30d-fd2d-b161-4dd5ec7c2da6-ec7c2d89,
#w-node-_00f3c34e-c30d-fd2d-b161-4dd5ec7c2df8-ec7c2d89,
#w-node-_04174c97-f3bb-5f13-90f9-6694665c0df3-e6a291aa,
#w-node-_0c5dacbd-1938-b526-c3eb-9b7b1846f00b-1846efee,
#w-node-_0c5dacbd-1938-b526-c3eb-9b7b1846f060-1846efee,
#w-node-_10cbc9bf-4275-2a57-ce5a-e368d1173725-4b0c909b,
#w-node-_10f87619-fd25-058c-e270-a1f41fe96a2d-1ed1d9af,
#w-node-_10f87619-fd25-058c-e270-a1f41fe96a2d-5bed08fc,
#w-node-_17779785-c3c9-ba66-b694-d4156dcbd9af-49f77b30,
#w-node-_17779785-c3c9-ba66-b694-d4156dcbda04-49f77b30,
#w-node-_3c572920-1fb2-9823-0ae6-d824453f82a0-1ed1d9af,
#w-node-_3c572920-1fb2-9823-0ae6-d824453f82a0-5bed08fc,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcf5d-45a291a3,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcf5d-5e275613,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcf8e-45a291a3,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcf8e-5e275613,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcfbf-45a291a3,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcfbf-5e275613,
#w-node-_797d4d05-4e30-6ba5-167a-ddc5ec5b0234-16ae02d1,
#w-node-_7c3cee37-3fbd-0c7f-1f14-de1b1c3123e2-45a291a3,
#w-node-_7c3cee37-3fbd-0c7f-1f14-de1b1c3123e2-5e275613,
#w-node-a8f04089-aafe-0feb-6173-2b5b071dd98f-16ae02d1,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-0a745c56,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-1f6964f4,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-3a40589d,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-44e6322c,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-79aa73fb,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-79d23788,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-8154dc5a,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-8a7761f1,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-9fcb4fcc,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-bed23826,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-cf7ad0a4,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-d12993f7,
#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5c-1ed1d9af,
#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5c-5bed08fc,
#w-node-fc472468-3e1d-6df9-e2a0-778fc09b5b44-45a291a3,
#w-node-fc472468-3e1d-6df9-e2a0-778fc09b5b44-5e275613,
#w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ac0-4b0c909b,
#w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ac0-e6a291aa {
    -ms-grid-column-align: center;
    justify-self: center
}

#w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e9-4b0c909b,
#w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e9-e6a291aa,
#w-node-_10f87619-fd25-058c-e270-a1f41fe96a30-1ed1d9af,
#w-node-_10f87619-fd25-058c-e270-a1f41fe96a30-5bed08fc,
#w-node-_10f87619-fd25-058c-e270-a1f41fe96a47-1ed1d9af,
#w-node-_10f87619-fd25-058c-e270-a1f41fe96a47-5bed08fc,
#w-node-_23d99870-a857-978e-fc3a-c4f947a2bba0-45a291a3,
#w-node-_23d99870-a857-978e-fc3a-c4f947a2bba0-5e275613,
#w-node-_3c572920-1fb2-9823-0ae6-d824453f82a2-1ed1d9af,
#w-node-_3c572920-1fb2-9823-0ae6-d824453f82a2-5bed08fc,
#w-node-_3c572920-1fb2-9823-0ae6-d824453f82a3-1ed1d9af,
#w-node-_3c572920-1fb2-9823-0ae6-d824453f82a3-5bed08fc,
#w-node-_3c572920-1fb2-9823-0ae6-d824453f82ba-1ed1d9af,
#w-node-_3c572920-1fb2-9823-0ae6-d824453f82ba-5bed08fc,
#w-node-_43695764-dd34-d59c-664c-98b7907e26a9-1ed1d9af,
#w-node-_43695764-dd34-d59c-664c-98b7907e26a9-5bed08fc,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcf60-45a291a3,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcf60-5e275613,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcf77-45a291a3,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcf77-5e275613,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcf91-45a291a3,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcf91-5e275613,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcfa8-45a291a3,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcfa8-5e275613,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcfc2-45a291a3,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcfc2-5e275613,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcfd9-45a291a3,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcfd9-5e275613,
#w-node-_930ec677-7835-f475-540b-45fa5d59a922-0a745c56,
#w-node-_930ec677-7835-f475-540b-45fa5d59a922-1f6964f4,
#w-node-_930ec677-7835-f475-540b-45fa5d59a922-3a40589d,
#w-node-_930ec677-7835-f475-540b-45fa5d59a922-44e6322c,
#w-node-_930ec677-7835-f475-540b-45fa5d59a922-79aa73fb,
#w-node-_930ec677-7835-f475-540b-45fa5d59a922-79d23788,
#w-node-_930ec677-7835-f475-540b-45fa5d59a922-8154dc5a,
#w-node-_930ec677-7835-f475-540b-45fa5d59a922-8a7761f1,
#w-node-_930ec677-7835-f475-540b-45fa5d59a922-9fcb4fcc,
#w-node-_930ec677-7835-f475-540b-45fa5d59a922-bed23826,
#w-node-_930ec677-7835-f475-540b-45fa5d59a922-cf7ad0a4,
#w-node-_930ec677-7835-f475-540b-45fa5d59a922-d12993f7,
#w-node-_9c03905e-1adc-2186-a37a-72d88463df3a-1ed1d9af,
#w-node-_9c03905e-1adc-2186-a37a-72d88463df3a-5bed08fc,
#w-node-_9c03905e-1adc-2186-a37a-72d88463df3b-1ed1d9af,
#w-node-_9c03905e-1adc-2186-a37a-72d88463df3b-5bed08fc,
#w-node-b6c2e90a-5a85-24d3-1e23-4e4ca924999f-16ae02d1,
#w-node-b6c2e90a-5a85-24d3-1e23-4e4ca924999f-49f77b30,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-0a745c56,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-1f6964f4,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-3a40589d,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-44e6322c,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-79aa73fb,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-79d23788,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-8154dc5a,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-8a7761f1,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-9fcb4fcc,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-bed23826,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-cf7ad0a4,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28d-d12993f7,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-0a745c56,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-1f6964f4,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-3a40589d,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-44e6322c,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-79aa73fb,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-79d23788,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-8154dc5a,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-8a7761f1,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-9fcb4fcc,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-bed23826,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-cf7ad0a4,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a4-d12993f7,
#w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfe-1ed1d9af,
#w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfe-5bed08fc,
#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-0a745c56,
#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-1f6964f4,
#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-3a40589d,
#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-44e6322c,
#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-79aa73fb,
#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-79d23788,
#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-8154dc5a,
#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-8a7761f1,
#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-9fcb4fcc,
#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-bed23826,
#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-cf7ad0a4,
#w-node-c30f9c2a-266f-3c38-7398-b5eeed4cd5c7-d12993f7,
#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5f-1ed1d9af,
#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5f-5bed08fc,
#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf76-1ed1d9af,
#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf76-5bed08fc,
#w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298de-16ae02d1,
#w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298de-49f77b30,
#w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e3-16ae02d1,
#w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e3-49f77b30,
#w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad7-4b0c909b,
#w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad7-e6a291aa {
    -ms-grid-row-align: start;
    align-self: start
}

#w-node-_10f87619-fd25-058c-e270-a1f41fe96a45-1ed1d9af,
#w-node-_10f87619-fd25-058c-e270-a1f41fe96a45-5bed08fc,
#w-node-_2af0ce0a-2dc8-baa8-2666-097e02299c12-4b0c909b,
#w-node-_2af0ce0a-2dc8-baa8-2666-097e02299c12-e6a291aa,
#w-node-_3c572920-1fb2-9823-0ae6-d824453f82b8-1ed1d9af,
#w-node-_3c572920-1fb2-9823-0ae6-d824453f82b8-5bed08fc,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcf75-45a291a3,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcf75-5e275613,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcfa6-45a291a3,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcfa6-5e275613,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcfd7-45a291a3,
#w-node-_77fdf264-8876-fedd-9845-82c69dedcfd7-5e275613,
#w-node-_86c80847-090f-be2a-7bc4-567e1c087dad-45a291a3,
#w-node-_86c80847-090f-be2a-7bc4-567e1c087dad-5e275613,
#w-node-_94a94724-1dd7-d8a6-98fe-989f236e8ec3-1ed1d9af,
#w-node-_94a94724-1dd7-d8a6-98fe-989f236e8ec3-5bed08fc,
#w-node-b189ff12-f737-b5a0-6b4b-71d4b9416ac7-1ed1d9af,
#w-node-b189ff12-f737-b5a0-6b4b-71d4b9416ac7-5bed08fc,
#w-node-b42db442-665a-795f-6980-e0fd8d3c4714-0a745c56,
#w-node-b42db442-665a-795f-6980-e0fd8d3c4714-1f6964f4,
#w-node-b42db442-665a-795f-6980-e0fd8d3c4714-3a40589d,
#w-node-b42db442-665a-795f-6980-e0fd8d3c4714-44e6322c,
#w-node-b42db442-665a-795f-6980-e0fd8d3c4714-79aa73fb,
#w-node-b42db442-665a-795f-6980-e0fd8d3c4714-79d23788,
#w-node-b42db442-665a-795f-6980-e0fd8d3c4714-8154dc5a,
#w-node-b42db442-665a-795f-6980-e0fd8d3c4714-8a7761f1,
#w-node-b42db442-665a-795f-6980-e0fd8d3c4714-9fcb4fcc,
#w-node-b42db442-665a-795f-6980-e0fd8d3c4714-bed23826,
#w-node-b42db442-665a-795f-6980-e0fd8d3c4714-cf7ad0a4,
#w-node-b42db442-665a-795f-6980-e0fd8d3c4714-d12993f7,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-0a745c56,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-1f6964f4,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-3a40589d,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-44e6322c,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-79aa73fb,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-79d23788,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-8154dc5a,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-8a7761f1,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-9fcb4fcc,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-bed23826,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-cf7ad0a4,
#w-node-b714c474-4cce-e22c-8ec6-3952ed4cb2a2-d12993f7,
#w-node-c27ea7cf-1259-352e-a4aa-b1d6bb21348e-16ae02d1,
#w-node-c27ea7cf-1259-352e-a4aa-b1d6bb21348e-49f77b30,
#w-node-c41528ae-e7a8-b02a-3181-20bcb705bbd1-45a291a3,
#w-node-c41528ae-e7a8-b02a-3181-20bcb705bbd1-5e275613,
#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf74-1ed1d9af,
#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf74-5bed08fc,
#w-node-f6a61413-acc0-3ba8-1a77-cd8c5171031b-1ed1d9af,
#w-node-f6a61413-acc0-3ba8-1a77-cd8c5171031b-5bed08fc,
#w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad5-4b0c909b,
#w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad5-e6a291aa {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1
}

#w-node-_10f87619-fd25-058c-e270-a1f41fe96a46-1ed1d9af,
#w-node-_10f87619-fd25-058c-e270-a1f41fe96a46-5bed08fc,
#w-node-_3c572920-1fb2-9823-0ae6-d824453f82b9-1ed1d9af,
#w-node-_3c572920-1fb2-9823-0ae6-d824453f82b9-5bed08fc,
#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf75-1ed1d9af,
#w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf75-5bed08fc {
    -ms-grid-row-align: center;
    align-self: center
}

#w-node-_10f87619-fd25-058c-e270-a1f41fe96a2f-1ed1d9af,
#w-node-_10f87619-fd25-058c-e270-a1f41fe96a2f-5bed08fc,
#w-node-_43695764-dd34-d59c-664c-98b7907e26a8-1ed1d9af,
#w-node-_43695764-dd34-d59c-664c-98b7907e26a8-5bed08fc,
#w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfd-1ed1d9af,
#w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfd-5bed08fc {
    -ms-grid-row-align: end;
    align-self: end
}

#w-node-_930ec677-7835-f475-540b-45fa5d59a921-0a745c56,
#w-node-_930ec677-7835-f475-540b-45fa5d59a921-1f6964f4,
#w-node-_930ec677-7835-f475-540b-45fa5d59a921-3a40589d,
#w-node-_930ec677-7835-f475-540b-45fa5d59a921-44e6322c,
#w-node-_930ec677-7835-f475-540b-45fa5d59a921-79aa73fb,
#w-node-_930ec677-7835-f475-540b-45fa5d59a921-79d23788,
#w-node-_930ec677-7835-f475-540b-45fa5d59a921-8154dc5a,
#w-node-_930ec677-7835-f475-540b-45fa5d59a921-8a7761f1,
#w-node-_930ec677-7835-f475-540b-45fa5d59a921-9fcb4fcc,
#w-node-_930ec677-7835-f475-540b-45fa5d59a921-bed23826,
#w-node-_930ec677-7835-f475-540b-45fa5d59a921-cf7ad0a4,
#w-node-_930ec677-7835-f475-540b-45fa5d59a921-d12993f7,
#w-node-_960af0f3-9022-9f53-e1c2-f9f50aa1b849-16ae02d1,
#w-node-_960af0f3-9022-9f53-e1c2-f9f50aa1b849-49f77b30 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1
}

@media screen and (max-width:991px) {
    #w-node-_23d99870-a857-978e-fc3a-c4f947a2bb9f-45a291a3,
    #w-node-_23d99870-a857-978e-fc3a-c4f947a2bb9f-5e275613,
    #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad6-4b0c909b,
    #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad6-e6a291aa {
        -ms-grid-column-span: 1;
        grid-column-end: 4;
        -ms-grid-column: 3;
        grid-column-start: 3;
        -ms-grid-row-span: 1;
        grid-row-end: 2;
        -ms-grid-row: 1;
        grid-row-start: 1
    }
    #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e8-4b0c909b,
    #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e8-e6a291aa,
    #w-node-c41528ae-e7a8-b02a-3181-20bcb705bbd1-45a291a3,
    #w-node-c41528ae-e7a8-b02a-3181-20bcb705bbd1-5e275613,
    #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e2-16ae02d1,
    #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e2-49f77b30 {
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-row-span: 1;
        grid-row-end: 2;
        -ms-grid-row: 1;
        grid-row-start: 1
    }
    #w-node-_00f3c34e-c30d-fd2d-b161-4dd5ec7c2dfe-ec7c2d89,
    #w-node-_0c5dacbd-1938-b526-c3eb-9b7b1846f066-1846efee,
    #w-node-_17779785-c3c9-ba66-b694-d4156dcbda0a-49f77b30,
    #w-node-_797d4d05-4e30-6ba5-167a-ddc5ec5b023a-16ae02d1 {
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-column-align: auto;
        justify-self: auto
    }
    #w-node-_10f87619-fd25-058c-e270-a1f41fe96a2f-1ed1d9af,
    #w-node-_10f87619-fd25-058c-e270-a1f41fe96a2f-5bed08fc,
    #w-node-_3c572920-1fb2-9823-0ae6-d824453f82a2-1ed1d9af,
    #w-node-_3c572920-1fb2-9823-0ae6-d824453f82a2-5bed08fc,
    #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5e-1ed1d9af,
    #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5e-5bed08fc {
        -ms-grid-column-span: 3;
        grid-column-end: 4;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-row-span: 1;
        grid-row-end: 2;
        -ms-grid-row: 1;
        grid-row-start: 1
    }
    #w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfd-1ed1d9af,
    #w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfd-5bed08fc {
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-row-span: 1;
        grid-row-end: 3;
        -ms-grid-row: 2;
        grid-row-start: 2
    }
    #w-node-_10f87619-fd25-058c-e270-a1f41fe96a46-1ed1d9af,
    #w-node-_10f87619-fd25-058c-e270-a1f41fe96a46-5bed08fc,
    #w-node-_43695764-dd34-d59c-664c-98b7907e26a8-1ed1d9af,
    #w-node-_43695764-dd34-d59c-664c-98b7907e26a8-5bed08fc {
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-row-span: 1;
        grid-row-end: 3;
        -ms-grid-row: 2;
        grid-row-start: 2;
        -ms-grid-row-align: auto;
        align-self: auto
    }
    #w-node-_3c572920-1fb2-9823-0ae6-d824453f82b9-1ed1d9af,
    #w-node-_3c572920-1fb2-9823-0ae6-d824453f82b9-5bed08fc,
    #w-node-_9c03905e-1adc-2186-a37a-72d88463df3a-1ed1d9af,
    #w-node-_9c03905e-1adc-2186-a37a-72d88463df3a-5bed08fc {
        -ms-grid-column-span: 1;
        grid-column-end: 4;
        -ms-grid-column: 3;
        grid-column-start: 3;
        -ms-grid-row-span: 1;
        grid-row-end: 3;
        -ms-grid-row: 2;
        grid-row-start: 2
    }
    #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298dd-16ae02d1,
    #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298dd-49f77b30 {
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-row: span 1;
        grid-row-start: span 1
    }
}

@media screen and (max-width:767px) {
    #w-node-_23d99870-a857-978e-fc3a-c4f947a2bb9f-45a291a3,
    #w-node-_23d99870-a857-978e-fc3a-c4f947a2bb9f-5e275613,
    #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad6-4b0c909b,
    #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad6-e6a291aa {
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-row-span: 1;
        grid-row-end: 3;
        -ms-grid-row: 2;
        grid-row-start: 2
    }
    #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e8-4b0c909b,
    #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e8-e6a291aa,
    #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e2-16ae02d1,
    #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e2-49f77b30 {
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-row-span: 1;
        grid-row-end: 2;
        -ms-grid-row: 1;
        grid-row-start: 1
    }
    #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf75-1ed1d9af,
    #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf75-5bed08fc {
        -ms-grid-column-span: 3;
        grid-column-end: 4;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-row-span: 1;
        grid-row-end: 3;
        -ms-grid-row: 2;
        grid-row-start: 2
    }
    #w-node-_43695764-dd34-d59c-664c-98b7907e26a8-1ed1d9af,
    #w-node-_43695764-dd34-d59c-664c-98b7907e26a8-5bed08fc,
    #w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfd-1ed1d9af,
    #w-node-bb29d59d-79ee-4308-b155-3023bdb0fdfd-5bed08fc {
        -ms-grid-row-span: 1;
        grid-row-end: 4;
        -ms-grid-row: 3;
        grid-row-start: 3;
        -ms-grid-column-span: 4;
        grid-column-end: 4
    }
    #w-node-_10f87619-fd25-058c-e270-a1f41fe96a46-1ed1d9af,
    #w-node-_10f87619-fd25-058c-e270-a1f41fe96a46-5bed08fc {
        -ms-grid-column-span: 4;
        grid-column-end: 4
    }
    #w-node-_9c03905e-1adc-2186-a37a-72d88463df3a-1ed1d9af,
    #w-node-_9c03905e-1adc-2186-a37a-72d88463df3a-5bed08fc {
        -ms-grid-row-span: 1;
        grid-row-end: 4;
        -ms-grid-row: 3;
        grid-row-start: 3;
        -ms-grid-column: 1;
        grid-column-start: 1
    }
    #w-node-_3c572920-1fb2-9823-0ae6-d824453f82b9-1ed1d9af,
    #w-node-_3c572920-1fb2-9823-0ae6-d824453f82b9-5bed08fc {
        -ms-grid-column: 1;
        grid-column-start: 1
    }
    #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298dd-16ae02d1,
    #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298dd-49f77b30 {
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-row: span 1;
        grid-row-start: span 1
    }
}

@media screen and (max-width:479px) {
    #w-node-_00f3c34e-c30d-fd2d-b161-4dd5ec7c2da6-ec7c2d89,
    #w-node-_00f3c34e-c30d-fd2d-b161-4dd5ec7c2df8-ec7c2d89,
    #w-node-_04174c97-f3bb-5f13-90f9-6694665c0df3-e6a291aa,
    #w-node-_0c5dacbd-1938-b526-c3eb-9b7b1846f00b-1846efee,
    #w-node-_0c5dacbd-1938-b526-c3eb-9b7b1846f060-1846efee,
    #w-node-_10cbc9bf-4275-2a57-ce5a-e368d1173725-4b0c909b,
    #w-node-_10f87619-fd25-058c-e270-a1f41fe96a2d-1ed1d9af,
    #w-node-_10f87619-fd25-058c-e270-a1f41fe96a2d-5bed08fc,
    #w-node-_17779785-c3c9-ba66-b694-d4156dcbd9af-49f77b30,
    #w-node-_17779785-c3c9-ba66-b694-d4156dcbda04-49f77b30,
    #w-node-_3c572920-1fb2-9823-0ae6-d824453f82a0-1ed1d9af,
    #w-node-_3c572920-1fb2-9823-0ae6-d824453f82a0-5bed08fc,
    #w-node-_77fdf264-8876-fedd-9845-82c69dedcf5d-45a291a3,
    #w-node-_77fdf264-8876-fedd-9845-82c69dedcf5d-5e275613,
    #w-node-_797d4d05-4e30-6ba5-167a-ddc5ec5b0234-16ae02d1,
    #w-node-_7c3cee37-3fbd-0c7f-1f14-de1b1c3123e2-45a291a3,
    #w-node-_7c3cee37-3fbd-0c7f-1f14-de1b1c3123e2-5e275613,
    #w-node-a8f04089-aafe-0feb-6173-2b5b071dd98f-16ae02d1,
    #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-0a745c56,
    #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-1f6964f4,
    #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-3a40589d,
    #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-44e6322c,
    #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-79aa73fb,
    #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-79d23788,
    #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-8154dc5a,
    #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-8a7761f1,
    #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-9fcb4fcc,
    #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-bed23826,
    #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-cf7ad0a4,
    #w-node-b714c474-4cce-e22c-8ec6-3952ed4cb28a-d12993f7,
    #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5c-1ed1d9af,
    #w-node-d94fe5ba-9186-cb3b-ebb5-b7c442f3cf5c-5bed08fc,
    #w-node-fc472468-3e1d-6df9-e2a0-778fc09b5b44-45a291a3,
    #w-node-fc472468-3e1d-6df9-e2a0-778fc09b5b44-5e275613,
    #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ac0-4b0c909b,
    #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ac0-e6a291aa {
        -ms-grid-column-align: auto;
        justify-self: auto
    }
    #w-node-_23d99870-a857-978e-fc3a-c4f947a2bb9f-45a291a3,
    #w-node-_23d99870-a857-978e-fc3a-c4f947a2bb9f-5e275613,
    #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad6-4b0c909b,
    #w-node-feed3f58-455f-1671-61fb-dc2c0a9f0ad6-e6a291aa {
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-row-span: 1;
        grid-row-end: 3;
        -ms-grid-row: 2;
        grid-row-start: 2
    }
    #w-node-_77fdf264-8876-fedd-9845-82c69dedcfa6-45a291a3,
    #w-node-_77fdf264-8876-fedd-9845-82c69dedcfa6-5e275613 {
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-row-span: 1;
        grid-row-end: 3;
        -ms-grid-row: 2;
        grid-row-start: 2
    }
    #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e8-4b0c909b,
    #w-node-_0242e89a-d197-60f9-7df1-ac4ac06355e8-e6a291aa,
    #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e2-16ae02d1,
    #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298e2-49f77b30 {
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-row-span: 1;
        grid-row-end: 2;
        -ms-grid-row: 1;
        grid-row-start: 1
    }
    #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298dd-16ae02d1,
    #w-node-e0c46a3e-34d0-7d8f-6c47-b1dddcd298dd-49f77b30 {
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-row: span 1;
        grid-row-start: span 1
    }
}






.mainaboutbody{
    /* margin-top: 0rem; */
}
.layout351_column-vertical-text {
    writing-mode: vertical-rl;
}
.button{
will-change: transform;
transform: translate3d(-3.108%, 1.75264%, 0px) scale3d(1, 1, 1) rotateX(3deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
transform-style: preserve-3d;
}
.client-logo-slider {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* overflow-x: auto; */
  /* width: 130rem; */
}

.logo-container {
  display: flex;
  flex-wrap: wrap;
}

.logo-item {
  position: relative;
  margin: 10px;
  width: calc(33.33% - 20px); /* Show 3 logos per line */
  max-width: 250px; /* Limit maximum logo width */
  cursor: pointer;
}

.logo-image {
  width: 100%;
  height: auto;
  transition: filter 0.3s ease;
}

.logo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.logo-name {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.logo-item:hover {
  z-index: 1;
}

.logo-item:hover .logo-image {
  filter: grayscale(100%);
  transform: translateY(-10px);
}

.logo-item:hover .logo-overlay {
  opacity: 1;
}

.logo-item:hover .logo-name {
  opacity: 1;
}

.about-image-wrappera{
    position: absolute;
width: 14vw;
height: 700px;
right: 0;
top: 13rem;
}
.about-image-wrappera img{
    width: 10%;
}
.about-image-wrapperab{
    position: absolute;
    width: 2vw;
    height: 260px;
    /* right: 0; */
    left: 45rem;
    top: 9rem;
}.about-image-wrapperab img{
    width: 10px;
}

.button.variant-navigation:hover{
    /* transition: opacity .2s ease-in-out; */
    /* color: salmon; */
    opacity: .5;
    padding: 10px 0;
    font-weight: 400;
 
    color: #464b50;
}

.nav--work ul li a, .nav--work ul li button {
   
}.about-image-wrapper {
    position: relative;
    display: inline-block;
  }

  .top-right-image {
   position: absolute;
   width: 40vw;
   height: 40vw;
   top: 18rem;
   /* image-rendering: 0; */
   right: 0;
  }.services-layout_accordion {
    /* Initial styles */
    transition: all 0.9s ease;
  }

  @media screen and (max-width:991px) {
  .about-image-wrappera{
    position: absolute;
    /* width: 1vw; */
    width: 14rem;
    height: 160px;
    right: 10rem;
    top: 9;
  }
  .about-image-wrappera img{
    width: 100%;
  }
  .max-width-xxsmall{
    max-width: 20rem;
  }
  .about-page-image{
    display: none;
  }
  .top-right-image{
    display: none;
  }
  .about-page-resimage{
width:89vw;
margin-top: 14rem;
  }
  .about-image-wrapper{
    /* height: 90vh; */
  }
}

.about-page-resimage{
    /* display: none; */
      }

      @media screen and (min-width:541px) and (max-width:990px) {
        .about-image-wrapper{
            /* height:150vw; */
            width: 100%;
          }
      }
      @media screen and  (min-width:900px) {
        .about-page-resimage{
            display: none;
        }
      }

      @media screen and  (max-width:400px) {
        .about-image-wrapper{
            /* height:160vw; */
          }
      
      }
      .iconify{
        /* width: 15rem; */
      }

      @media screen and  (max-width:900px) {
        .about-image-wrapper{
            /* height:160vw; */
            width: 100%;
          }
          .text-size-small.text-colour-grey {
            color: black;
            font-size: 18px;
          }
          .icon-embed-medium{
            color: black;
            font-size: 18px;
          }
          .icon-embed-medium.home{
            height: 0;
          }
      }
      .rise-up {
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 1.5s ease, transform 1.5s ease;
      }
      
      .rise-up.active {
        opacity: 1;
        transform: translateY(0);
      }
      /* Letter-by-letter animation */
.letter-slide {
    /* display: inline-block; */
    opacity: 0;
    transform: translateX(20px);
    transition: opacity 3.5s ease, transform 0.5s ease;
  }
  
  .letter-slide.active {
    opacity: 1;
    transform: translateX(0);
  }
  /* CSS animation for text */
.text-slide-up {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .text-slide-up.active {
    opacity: 1;
    transform: translateY(0);
  }
  
/* .mainaboutbody{
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    perspective: 100px;
} */