

 
  .btn-gradient-2 {
    background: linear-gradient(white, white) padding-box,
                linear-gradient(to right, darkblue, darkorchid) border-box;
                

    border-radius
    : 50em;
    background: black;
    border: 4px solid transparent;
    color: white;
  }
  
  /* demo stuff */
  .contparent {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 3rem;
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */
  }
  
 
  .btn-gradient-2 {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    padding: 0.5rem 4rem;
    cursor: pointer;
    /* font-size: 12px; */
    font-weight: 100;
    letter-spacing: 1.2px;
  }