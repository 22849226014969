.hamburger-menu .line {
    width: 30px;
    height: 2px;
    background-color: black;
     
    margin-bottom: 5px;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .hamburger-menu .line.active:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
    transition: transform 0.5s ease
  }
  
  .hamburger-menu .line.active:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger-menu .line.active:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
    transition: transform 0.5s ease;
  }
  
  .hamburger-menu .menu {
    display: none;
    cursor: pointer;
  }
  
  .hamburger-menu .menu.active {
    display: block;
    position: absolute;
    cursor: pointer;
    left: 0;
  }
  .hamburger-menu {
    cursor: pointer;
  }
  .hamburger-menu a{
text-decoration: none;
  }
  .closeimg{
    width: 5rem;
  }





 



 
  blockquote,
  dd,
  dl,
  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  p,
  pre {
      margin: 0
  }
  
  fieldset {
      margin: 0
  }
  
  fieldset,
  legend {
      padding: 0
  }
  
  menu,
  ol,
  ul {
      list-style: none;
      margin: 0;
      padding: 0
  }
  
  textarea {
      resize: vertical
  }
  
  input::-moz-placeholder,
  textarea::-moz-placeholder {
      color: #9ca3af;
      opacity: 1
  }
  
  input::placeholder,
  textarea::placeholder {
      color: #9ca3af;
      opacity: 1
  }
  
  [role=button],
  button {
      cursor: pointer
  }
  
  :disabled {
      cursor: default
  }
  
  audio,
  canvas,
  embed,
  iframe,
  img,
  object,
  svg,
  video {
      display: block;
      vertical-align: middle
  }
  
  img,
  video {
      height: auto;
      max-width: 100%
  }
  
  [hidden] {
      display: block
  }
  
  html {
      font-size: 10px;
      
      scroll-behavior: auto
  }
  
  @media (min-width:1440px) {
      html {
          font-size: .6944444444444444vw
      }
  }
  
  button:focus {
      outline: 0
  }
  
  .fix-text-indend {
      margin-left: -.05em
  }
  
  .post-title .line-w.is-animated .line {
      display: inline-block !important;
      vertical-align: top
  }
  
  .post-title .line-w.is-animated .line:after {
      background: currentColor;
      bottom: 0;
      content: "";
      height: .1em;
      left: 0;
      position: absolute;
      transform: scaleX(0);
      transform-origin: 100% 100%;
      transition: transform .3s ease-out;
      width: 100%
  }
  
  .group:hover .post-title .line-w.is-animated .line:after {
      transform: none;
      transform-origin: 0 0;
      transition: transform .55s ease-in-out
  }
  
  .no-touchevents .lg:overflow-y-auto,
  .no-touchevents .md:overflow-y-auto,
  .no-touchevents .overflow-y-auto {
      -webkit-overflow-scrolling: touch
  }
  
  .no-touchevents .lg:overflow-y-auto::-webkit-scrollbar,
  .no-touchevents .md:overflow-y-auto::-webkit-scrollbar,
  .no-touchevents .overflow-y-auto::-webkit-scrollbar {
      background: 0 0;
      width: 4px
  }
  
  .no-touchevents .lg:overflow-y-auto::-webkit-scrollbar-thumb,
  .no-touchevents .md:overflow-y-auto::-webkit-scrollbar-thumb,
  .no-touchevents .overflow-y-auto::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, .3)
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  
      font-weight: 700;
      letter-spacing: -.02em;
      line-height: 1;
      margin-bottom: 2.5rem
  }
  
  h1 strong,
  h2 strong,
  h3 strong,
  h4 strong,
  h5 strong,
  h6 strong {
      color: #f387c8
  }
  
  .h1,
  h1 {
      font-size: 4rem;
      line-height: .9
  }
  
  @media (min-width:1024px) {
      .h1,
      h1 {
          font-size: 8.2rem
      }
  }
  
  .h2,
  h2 {
      font-size: 3.4rem;
      line-height: 1.1
  }
  
  @media (min-width:1024px) {
      .h2,
      h2 {
          font-size: 5rem
      }
  }
  
  .h3,
  h3 {
      font-size: 2.2rem;
      line-height: 1.1
  }
  
  @media (min-width:1024px) {
      .h3,
      h3 {
          font-size: 2.8rem
      }
  }
  
  @media (min-width:1280px) {
      .h3,
      h3 {
          font-size: 3.4rem
      }
  }
  
  .h4,
  h4 {
      font-size: 2.2rem
  }
  
  .h5,
  h5 {
      font-size: 2rem
  }
  
  .h6,
  h6 {
      font-size: 1.8rem
  }
  
  .custom-headline-cell:empty {
      display: block
  }
  
  .arrow-before-text {
      flex-shrink: 0;
      position: relative;
      top: .15em
  }
  
  .arrow-before-text svg {
      height: 100%;
      width: 100%
  }
  
  p {
      letter-spacing: -.02em;
      margin-bottom: 2rem
  }
  
  .reset-last>:last-child {
      margin-bottom: 0
  }
  
  .content-block {
      margin: 4rem 0
  }
  
  .content-block:first-child:not(.content-block--featured) {
      padding-top: 0 !important
  }
  
  .content-block h1 h2,
  .content-block h3,
  .content-block h4,
  .content-block h5,
  .content-block h6 {
      text-transform: uppercase
  }
  
  .content-block--featured {
      border-bottom: .8rem solid;
      border-top: .8rem solid;
      margin: 5rem 0;
      padding: 3rem 0
  }
  
  @media (min-width:1280px) {
      .content-block--featured {
          margin: 7rem 0;
          padding: 4rem 0
      }
  }
  
  .content-block--with-sidebar {
      border-top: .8rem solid;
      margin: 0 0 4rem;
      padding: 3rem 0 0
  }
  
  @media (min-width:1280px) {
      .content-block--with-sidebar {
          padding: 1.5rem 0 0
      }
  }
  
  .wysiwyg h1,
  .wysiwyg h2,
  .wysiwyg h3 {
      margin-bottom: 4rem;
      margin-top: 4rem;
      text-transform: uppercase
  }
  
  .wysiwyg mark {
      background-color: #f387c8
  }
  
  .wysiwyg h4,
  .wysiwyg h5,
  .wysiwyg h6 {
      line-height: 1.2;
      margin-bottom: 3rem;
      margin-top: 3rem;
      text-transform: none
  }
  
  .wysiwyg h1:first-child,
  .wysiwyg h2:first-child,
  .wysiwyg h3:first-child,
  .wysiwyg h4:first-child {
      margin-top: 0
  }
  
  .wysiwyg ol,
  .wysiwyg p,
  .wysiwyg ul {
      margin-bottom: 1.125em
  }
  
  .wysiwyg img {
      height: auto;
      margin: 3rem 0;
      width: 100%
  }
  
  .wysiwyg .media {
      margin: 3rem 0
  }
  
  .wysiwyg .media:first-child {
      margin-top: 0
  }
  
  .wysiwyg .media:last-child {
      margin-bottom: 0
  }
  
  .wysiwyg ol {
      counter-reset: ol-counter;
      list-style: none
  }
  
  .wysiwyg ol li {
      counter-increment: ol-counter;
      display: block
  }
  
  .wysiwyg ol li:before {
      content: "0" counter(ol-counter) ".";
      display: inline-block;
      font-size: 1.4rem;
      margin-right: .7rem;
      position: relative;
      top: .2em;
      vertical-align: top
  }
  
  .wysiwyg ul {
      list-style: disc;
      list-style-position: inside
  }
  
  .wysiwyg ol ol,
  .wysiwyg ol ul,
  .wysiwyg ul ol,
  .wysiwyg ul ul {
      padding-left: 3rem;
      padding-top: 1.25rem
  }
  
  .wysiwyg ol li,
  .wysiwyg ul li {
      margin-bottom: 1.25rem
  }
  
  .wysiwyg ol li:last-child,
  .wysiwyg ul li:last-child {
      margin-bottom: 0
  }
  
  .wysiwyg hr {
      margin: 4rem 0;
      opacity: .1
  }
  
  @media (min-width:1024px) {
      .wysiwyg hr {
          margin: 6rem 0
      }
  }
  
  .wysiwyg strong {
      color: #f387c8
  }
  
  .custom-outline-text {
      background-image: linear-gradient(180deg, transparent, transparent calc(1.5em - .1rem), rgba(31, 31, 31, .3) 0, rgba(31, 31, 31, .3) 1.5em);
      background-repeat: repeat-y;
      background-size: 100% 1.5em;
      line-height: 1.5em;
      padding-bottom: .4rem
  }
  
  .custom-outline-text>* {
      margin: 0;
      padding-bottom: 1.5em
  }
  
  .custom-outline-text>:last-child {
      padding-bottom: 0
  }
  
  .custom-outline-text--top {
      background-image: linear-gradient(0deg, transparent, transparent calc(1.5em - .1rem), rgba(31, 31, 31, .3) 0, rgba(31, 31, 31, .3) 1.5em);
      background-repeat: repeat-y;
      background-size: 100% 1.5em;
      line-height: 1.5em;
      padding-bottom: .4rem
  }
  
  .custom-outline-text--top>* {
      margin: 0;
      padding-bottom: 1.5em
  }
  
  .custom-outline-text--top>:last-child {
      padding-bottom: 0
  }
  
  .custom-outline-text--small {
      background-image: linear-gradient(0deg, transparent, transparent calc(1.1em - .1rem), rgba(31, 31, 31, .3) 0, rgba(31, 31, 31, .3) 1.1em);
      background-repeat: repeat-y;
      background-size: 100% 1.1em;
      line-height: 1.1em;
      padding-bottom: .4rem
  }
  
  .custom-outline-text--small>* {
      margin: 0;
      padding-bottom: 1.1em
  }
  
  .custom-outline-text--small>:last-child {
      padding-bottom: 0
  }
  
  .custom-outline-text--title {
      background-image: linear-gradient(180deg, transparent, transparent calc(1.1em - .1rem), rgba(31, 31, 31, .3) 0, rgba(31, 31, 31, .3) 1.1em);
      background-repeat: repeat-y;
      background-size: 100% 1.1em;
      line-height: 1.1em;
      padding-bottom: .4rem
  }
  
  .custom-outline-text--title>* {
      margin: 0;
      padding-bottom: 1.1em
  }
  
  .custom-outline-text--title>:last-child {
      padding-bottom: 0
  }
  
  .custom-outline-text--large {
      background-image: linear-gradient(0deg, transparent, transparent calc(2em - .1rem), rgba(31, 31, 31, .3) 0, rgba(31, 31, 31, .3) 2em);
      background-repeat: repeat-y;
      background-size: 100% 2em;
      line-height: 2em;
      padding-bottom: .4rem
  }
  
  .custom-outline-text--large>* {
      margin: 0;
      padding-bottom: 2em
  }
  
  .custom-outline-text--large>:last-child {
      padding-bottom: 0
  }
  
  .text-arrow-first p:before {
      content: "";
      display: inline-block;
      height: 1em;
      margin-right: 1em;
      vertical-align: -.2em;
      width: 1em
  }
  
  .-translate-x-15,
  .group-hover\:scale-0,
  .group-hover\:scale-105,
  .transform {
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1
  }
  
  html {
      box-sizing: border-box
  }
  
  *,
  :after,
  :before {
      box-sizing: inherit
  }
  
  
  input:-webkit-autofill,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:hover,
  select:-webkit-autofill,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:hover,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:hover {
      -webkit-box-shadow: inset 0 0 0 1000px #d8d9cf;
      -webkit-transition: background-color 5000s ease-in-out 0s;
      transition: background-color 5000s ease-in-out 0s
  }
  
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0
  }
  
  form[name=contact] .input-group:before {
      background: currentColor;
      border-radius: 50%;
      content: "";
      height: 1rem;
      margin-top: -.5rem;
      opacity: .2;
      position: absolute;
      right: 0;
      top: 2.5rem;
      transition: opacity .35s cubic-bezier(.3, .86, .36, .95);
      width: 1rem
  }
  
  .group:hover form[name=contact] .input-group:before,
  form[name=contact] .input-group:focus-within:before,
  form[name=contact] .input-group:hover:before {
      opacity: 1
  }
  
  form[name=contact] .input-group:first-child {
      border-top: 1px solid rgba(31, 31, 31, .2)
  }
  
  form[name=career] .form-message-success,
  form[name=contact] .form-message-success {
      padding-top: 3rem
  }
  
  form[name=newsletter] {
      position: relative
  }
  
  form[name=newsletter] .form-message-success {
      font-size: 1.8rem;
      max-width: none
  }
  
  form[name=newsletter] [data-error-field] {
      bottom: 100%;
      color: #fff;
      left: 2rem;
      padding: .5rem 0;
      right: 0;
      top: auto
  }
  
  .form-message-success {
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 1.1;
      text-transform: uppercase
  }
  
  * {
      -webkit-tap-highlight-color: transparent
  }
  
  div[style="width: 0; height: 0;"] {
      position: fixed;
      z-index: -9999
  }
  
  .cursor-none * {
      cursor: none !important
  }
  
  [style^="--aspect"] {
      aspect-ratio: var(--aspect)
  }
  
  
  
  .lenis-smooth {
      overscroll-behavior-y: none;
      scroll-behavior: auto
  }
  
  .lenis-smooth [data-lenis-prevent] {
      overscroll-behavior: contain
  }
  
  .is-animating,
  .is-changing,
  .is-transitioning {
      pointer-events: none !important
  }
  
  .is-loading body {
      background: #6f7275
  }
  
  .is-loading * {
      transition: none !important
  }
  
  .is-loading .page-holder {
      opacity: 1
  }
  
  [data-page-scroller] {
      height: calc(var(--vh)*100);
      overflow-y: auto
  }
  
  [data-page-scroller]::-webkit-scrollbar {
      display: block
  }
  
  [data-page-scroller].swup-clone {
      height: auto;
      overflow: visible
  }
  
  [data-component=lazyload] {
      opacity: 1;
      transition: opacity .35s cubic-bezier(.3, .86, .36, .95)
  }
  
  [data-component=lazyload][data-ll-status=loaded] {
      opacity: 1
  }
  
  [data-page-cursor] {
      --size: 20px;
      background-color: #fff;
      border-radius: 50%;
      height: var(--size);
      left: 0;
      mix-blend-mode: difference;
      pointer-events: none;
      position: fixed;
      top: 0;
      /* transform: translate3d(calc(100vw*var(--cursor-x) - var(--size)/ 2), calc(100vh*var(--cursor-y) - var(--size)/ 2), 0); */
      width: var(--size);
      z-index: 100
  }
  
  .touchevents [data-page-cursor] {
      display: block
  }
  
  .main {
      transform-origin: top
  }
  
  [data-animation^=split] {
      font-kerning: none
  }
  
  [data-pages-modals] [data-page-content] {
      height: calc(var(--vh)*100);
      left: 100%;
      max-width: 95rem;
      overflow: auto;
      overscroll-behavior-y: none;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 50
  }
  
  [data-pages-modals] [data-page-content]::-webkit-scrollbar {
      display: block
  }
  
  [data-pages-modals] [data-page-content]>div {
      overflow: hidden
  }
  
  [id=__lpform_name] {
      display: block !important
  }
  
  .page-holder:before {
      /* background: #1f1f1f; */
      /* content: ""; */
      height: 100%;
      left: 0;
      opacity: 1;
      pointer-events: none;
      position: fixed;
      top: 0;
      transition: opacity .6s cubic-bezier(.3, .86, .36, .95);
      width: 100%;
      z-index: 35
  }
  
  .modal-is-active .page-holder:before {
      opacity: .9;
      pointer-events: all
  }
  
  .text-arrow-first p:before {
   
      background-repeat: no-repeat;
      background-size: contain
  }
  
  [data-add-movement-value] {
      margin-bottom: var(--mv)
  }
  
  .footer-info-row {
      position: relative
  }
  
  .footer-info-row:after {
      background: currentColor;
      border-radius: 50%;
      content: "";
      height: 1rem;
      margin-top: -.5rem;
      position: absolute;
      right: 0;
      top: 1.1em;
      width: 1rem
  }
  
  .post-title .line-w {
      transition-timing-function: cubic-bezier(.65, .05, .36, 1)
  }
  
  .group:hover .post-title .line-w:first-child .line:after {
      transition-delay: .05s;
      transition-duration: .35s
  }
  
  .group:hover .post-title .line-w:nth-child(2) .line:after {
      transition-delay: .1s;
      transition-duration: .35s
  }
  
  .group:hover .post-title .line-w:nth-child(3) .line:after {
      transition-delay: .15s;
      transition-duration: .35s
  }
  
  .group:hover .post-title .line-w:nth-child(4) .line:after {
      transition-delay: .2s;
      transition-duration: .35s
  }
  
  .group:hover .post-title .line-w:nth-child(5) .line:after {
      transition-delay: .25s;
      transition-duration: .35s
  }
  
  .group:hover .post-title .line-w:nth-child(6) .line:after {
      transition-delay: .3s;
      transition-duration: .35s
  }
  
  .group:hover .post-title .line-w:nth-child(7) .line:after {
      transition-delay: .35s;
      transition-duration: .35s
  }
  
  .group:hover .post-title .line-w:nth-child(8) .line:after {
      transition-delay: .4s;
      transition-duration: .35s
  }
  
  .group:hover .post-title .line-w:nth-child(9) .line:after {
      transition-delay: .45s;
      transition-duration: .35s
  }
  
  .group:hover .post-title .line-w:nth-child(10) .line:after {
      transition-delay: .5s;
      transition-duration: .35s
  }
  
  @keyframes button-icon-movement {
      0%,
      0.1% {
          transform: none
      }
      49.9% {
          transform: translateX(110%)
      }
      50%,
      50.1% {
          transform: translateX(-110%)
      }
      to {
          transform: none
      }
  }
  
  @keyframes button-icon-movement-reverse {
      0%,
      0.1% {
          transform: none
      }
      49.9% {
          transform: translateX(-110%)
      }
      50%,
      50.1% {
          transform: translateX(110%)
      }
      to {
          transform: none
      }
  }
  
  .svgi {
      fill: currentColor;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
      width: 100%
  }
  
  [data-page-modal-pagination-link] .svgi {
      height: 1.4rem;
      width: 1.4rem
  }
  
  .btn-arrow-prev:hover .svgi,
  .group:hover .btn-arrow-prev .svgi {
      animation: button-icon-movement-reverse .75s cubic-bezier(.3, .86, .36, .95) forwards
  }
  
  .btn-arrow-next:hover .svgi,
  .group:hover .btn-arrow-next .svgi {
      animation: button-icon-movement .75s cubic-bezier(.3, .86, .36, .95) forwards
  }
  
  [data-coverflow-overlay] {
      background: #1f1f1f;
      bottom: 0;
      left: 0;
      opacity: 1;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 35
  }
  
  .line,
  .line-w {
      vertical-align: top
  }
  
  [data-stack-card] {
      backface-visibility: hidden;
      will-change: transform
  }
  
  .client-row-image {
      -webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
      clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
      transition: -webkit-clip-path .5s cubic-bezier(.3, .86, .36, .95);
      transition: clip-path .5s cubic-bezier(.3, .86, .36, .95);
      transition: clip-path .5s cubic-bezier(.3, .86, .36, .95), -webkit-clip-path .5s cubic-bezier(.3, .86, .36, .95)
  }
  
  .accordion-icon:after,
  .accordion-icon:before {
      background: currentColor;
      content: "";
      display: block;
      position: absolute;
      transition: transform .5s cubic-bezier(.3, .86, .36, .95)
  }
  
  .accordion-icon:before {
      height: 1rem;
      left: 50%;
      margin-left: -.1rem;
      top: 0;
      width: .2rem
  }
  
  .is-active .accordion-icon:before {
      transform: scaleY(0)
  }
  
  .accordion-icon:after {
      height: .2rem;
      left: 0;
      margin-top: -.1rem;
      top: 50%;
      width: 1rem
  }
  
  [data-accordion-content] {
      overflow: hidden
  }
  
  .client-row-element .client-row-circle {
      transform: scale(0);
      transition: transform .5s cubic-bezier(.3, .86, .36, .95)
  }
  
  .client-row-element .client-row-title {
      transition: transform .5s cubic-bezier(.3, .86, .36, .95);
      transition-delay: .2s
  }
  
  .client-row-element.is-active .client-row-image {
      -webkit-clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
      clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
      transition-delay: .3s
  }
  
  .client-row-element.is-active .client-row-circle {
      transform: scale(1);
      transition-delay: .2s
  }
  
  .client-row-element.is-active .client-row-title {
      transform: translateX(3rem)
  }
  
  .case-content__left {
      display: block
  }
  
  .case-content__right .wysiwyg {
      padding-right: 3rem
  }
  
  .case-page .case-content__left {
      display: block
  }
  
  .case-page .case-content__right .wysiwyg {
      padding-right: 0
  }
  
  .oembed {
      position: relative
  }
  
  .oembed:before {
      bottom: 0;
      content: "";
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2
  }
  
  .lenis-scrolling .oembed:before {
      pointer-events: all
  }
  
  .lenis-scrolling .oembed iframe {
      pointer-events: none
  }
  
  .swup-clone [data-hero-overlay] {
      display: block
  }
  
  .link[data-text] {
      overflow: hidden
  }
  
  .link[data-text] .link__inner {
      grid-gap: .5em;
      display: grid;
      height: 1.3em;
      transition: transform .6s cubic-bezier(.3, .86, .36, .95)
  }
  
  .link[data-text] .link__inner>span {
      display: flex
  }
  
  .link[data-text]:after {
      bottom: 0
  }
  
  .group:not(.is-active):hover .link[data-text] .link__inner {
      transform: translateY(-1.8em)
  }
  
  .underline-title span {
      background-image: linear-gradient(transparent calc(100% - .1em), currentColor .1em);
      background-position-y: -.1em;
      background-repeat: no-repeat;
      background-size: 0;
      display: inline;
      transition: background-size 1s cubic-bezier(.3, .86, .36, .95)
  }
  
  .group:hover .underline-title span,
  .underline-title:hover span {
      background-size: 100%
  }
  
  body .tf-v1-slider .tf-v1-iframe-wrapper iframe {
      border-radius: 0 !important
  }
  
  
  
  .sr-only {
      clip: rect(0, 0, 0, 0);
      border-width: 0;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px
  }
  
  .pointer-events-none {
      pointer-events: none
  }
  
  .visible {
      visibility: visible
  }
  
  .fixed {
      position: fixed
  }
  
  .absolute {
      position: absolute
  }
  
  .relative {
      position: relative
  }
  
  .bottom-0 {
      bottom: 0
  }
  
  .bottom-180 {
      bottom: 18rem
  }
  
  .left-0 {
      left: 0
  }
  
  
  
  .right-0 {
      right: 0
  }
  
  .right-10 {
      right: 1rem
  }
  
  .right-25 {
      right: 2.5rem
  }
  
  .top-0 {
      top: 0
  }
  
  
  
  .top-10 {
      top: 1rem
  }
  
  .top-25 {
      top: 2.5rem
  }
  
  .top-30 {
      top: 3rem
  }
  
  .z-2 {
      z-index: 2
  }
  
  .z-30 {
      z-index: 30
  }
  
  .z-4 {
      z-index: 4
  }
  
  .z-5 {
      z-index: 5
  }
  
  .z-50 {
      z-index: 50
  }
  
  .col-span-4 {
      grid-column: span 4/span 4
  }
  
  .col-span-8 {
      grid-column: span 8/span 8
  }
  
  .col-start-1 {
      grid-column-start: 1
  }
  
  .col-end-3 {
      grid-column-end: 3
  }
  
  .m-0 {
      margin: 0
  }
  
  .-mx-25 {
      margin-left: -2.5rem;
      margin-right: -2.5rem
  }
  
  .mx-auto {
      margin-left: auto;
      margin-right: auto
  }
  
  .-mb-1 {
      margin-bottom: -1px
  }
  
  .-mb-20 {
      margin-bottom: -2rem
  }
  
  .-mt-10 {
      margin-top: -1rem
  }
  
  .-mt-5 {
      margin-top: -.5rem
  }
  
  .-mt-7 {
      margin-top: -.7rem
  }
  
  .mb-0 {
      margin-bottom: 0
  }
  
  .mb-10 {
      margin-bottom: 1rem
  }
  
  .mb-100 {
      margin-bottom: 10rem
  }
  
  .mb-110 {
      margin-bottom: 11rem
  }
  
  .mb-15 {
      margin-bottom: 1.5rem
  }
  
  .mb-20 {
      margin-bottom: 2rem
  }
  
  .mb-30 {
      margin-bottom: 3rem
  }
  
  .mb-40 {
      margin-bottom: 4rem
  }
  
  .mb-45 {
      margin-bottom: 4.5rem
  }
  
  .mb-5 {
      margin-bottom: .5rem
  }
  
  .mb-50 {
      margin-bottom: 5rem
  }
  
  .mb-60 {
      margin-bottom: 6rem
  }
  
  .mb-70 {
      margin-bottom: 7rem
  }
  
  .mb-80 {
      margin-bottom: 8rem
  }
  
  .mb-90 {
      margin-bottom: 9rem
  }
  
  .ml-auto {
      margin-left: auto
  }
  
  .mr-10 {
      margin-right: 1rem
  }
  
  .mr-20 {
      margin-right: 2rem
  }
  
  .mr-25 {
      margin-right: 2.5rem
  }
  
  .mr-8 {
      margin-right: .8rem
  }
  
  .mt-30 {
      margin-top: 3rem
  }
  
  .mt-60 {
      margin-top: 6rem
  }
  
  .mt-auto {
      margin-top: auto;
      height: 75%;
  }
  
  .block {
      display: block
  }
  
  .inline-block {
      display: inline-block
  }
  
  .inline {
      display: inline
  }
  
  /* .\ !flex {
      display: flex !important
  } */
  
  .flex {
      display: flex
  }
  
  .inline-flex {
      display: inline-flex
  }
  
  .grid {
      display: grid
  }
  
  /* .hidden {
      display: block
  }
  
  .aspect-video {
      aspect-ratio: 16/9
  }
  
  /* .\ !h-40 {
      height: 4rem !important
  } */
  
  .h-1 {
      height: 1px
  }
  
  .h-1\/2 {
      height: 50%
  }
  
  .h-10 {
      height: 1rem
  }
  
  .h-120 {
      height: 12rem
  }
  
  .h-14 {
      height: 1.4rem
  }
  
  .h-180 {
      height: 18rem
  }
  
  .h-20 {
      height: 2rem
  }
  
  .h-50 {
      height: 5rem
  }
  
  .h-600 {
      height: 60rem
  }
  
  
  
  .h-auto {
      height: auto
  }
  
  .h-full {
      height: 100%
  }
  
  .h-screen {
      height: 100vh
  }
  
  .min-h-300 {
      min-height: 30rem
  }
  
  .min-h-40 {
      min-height: 4rem
  }
  
  .min-h-460 {
      min-height: 46rem
  }
  
  .min-h-500 {
      min-height: 50rem
  }
  
  .min-h-70 {
      min-height: 7rem
  }
  
  .min-h-90 {
      min-height: 9rem
  }
  
  .min-h-screen {
      min-height: 100vh
  } 
  
  /* .\ !w-40 {
      width: 4rem !important
  } */
  
  .w-1 {
      width: 1px
  }
  
  .w-1\/2 {
      width: 50%
  }
  
  .w-1\/3 {
      width: 33.333333%
  }
  
  .w-10 {
      width: 1rem
  }
  
  .w-120 {
      width: 12rem
  }
  
  .w-14 {
      width: 1.4rem
  }
  
  .w-160 {
      width: 16rem
  }
  
  .w-20 {
      width: 2rem
  }
  
  .w-25 {
      width: 2.5rem
  }
  
  .w-50 {
      width: 5rem
  }
  
  .w-60 {
      width: 6rem
  }
  
  .w-70 {
      width: 7rem
  }
  
  .w-auto {
      width: auto
  }
  
  .w-full {
      width: 100%
  }
  
  .max-w-250 {
      max-width: 25rem
  }
  
  .max-w-300 {
      max-width: 30rem
  }
  
  .max-w-320 {
      max-width: 32rem
  }
  
  .max-w-360 {
      max-width: 36rem
  }
  
  .max-w-370 {
      max-width: 37rem
  }
  
  .max-w-400 {
      max-width: 40rem
  }
  
  .max-w-430 {
      max-width: 43rem
  }
  
  .max-w-460 {
      max-width: 46rem
  }
  
  .max-w-500 {
      max-width: 50rem
  }
  
  .max-w-520 {
      max-width: 52rem
  }
  
  .max-w-600 {
      max-width: 60rem
  }
  
  .max-w-850 {
      max-width: 85rem
  }
  
  .max-w-900 {
      max-width: 90rem
  }
  
  .max-w-screen-lg {
      max-width: 1024px
  }
  
  .flex-shrink {
      flex-shrink: 1
  }
  
  .flex-shrink-0 {
      flex-shrink: 0
  }
  
  .flex-grow,
  .grow {
      flex-grow: 1
  }
  
  .-translate-x-15 {
      --tw-translate-x: -1.5rem
  }
  
  .-translate-x-15,
  .transform {
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  
  .cursor-none {
      cursor: none
  }
  
  .cursor-pointer {
      cursor: pointer
  }
  
  .resize {
      resize: both
  }
  
  .grid-flow-col {
      grid-auto-flow: column
  }
  
  .grid-cols-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr))
  }
  
  .grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }
  
  .grid-rows-3 {
      grid-template-rows: repeat(3, minmax(0, 1fr))
  }
  
  .flex-col {
      flex-direction: column
  }
  
  .items-start {
      align-items: flex-start
  }
  
  .items-center {
      align-items: center
  }
  
  .items-baseline {
      align-items: baseline
  }
  
  .justify-start {
      justify-content: flex-start
  }
  
  .justify-end {
      justify-content: flex-end
  }
  
  .justify-center {
      justify-content: center
  }
  
  .justify-between {
      justify-content: space-between;
      text-decoration: none;
      font-family: Helveticaneue,sans-serif;
  }
  .justify-between span .flex{
    text-decoration: none;
  }
  .gap-16 {
      gap: 1.6rem
  }
  
  .gap-20 {
      gap: 2rem
  }
  
  .gap-30 {
      gap: 3rem
  }
  
  .gap-x-10 {
      -moz-column-gap: 1rem;
      column-gap: 1rem
  }
  
  .gap-x-15 {
      -moz-column-gap: 1.5rem;
      column-gap: 1.5rem
  }
  
  .gap-x-20 {
      -moz-column-gap: 2rem;
      column-gap: 2rem
  }
  
  .gap-x-25 {
      -moz-column-gap: 2.5rem;
      column-gap: 2.5rem
  }
  
  .gap-x-40 {
      -moz-column-gap: 4rem;
      column-gap: 4rem
  }
  
  .gap-x-50 {
      -moz-column-gap: 5rem;
      column-gap: 5rem
  }
  
  .gap-x-6 {
      -moz-column-gap: .6rem;
      column-gap: .6rem
  }
  
  .gap-x-60 {
      -moz-column-gap: 6rem;
      column-gap: 6rem
  }
  
  .gap-y-35 {
      row-gap: 3.5rem
  }
  
  .gap-y-40 {
      row-gap: 4rem
  }
  
  .gap-y-50 {
      row-gap: 5rem
  }
  
  .overflow-hidden {
      overflow: hidden
  }
  
  .whitespace-nowrap {
      white-space: nowrap
  }
  
  .rounded-full {
      border-radius: 9999px;
      background-color: red;
  }
  
  .border {
      border-width: .1rem
  }
  
  .border-0 {
      border-width: 0
  }
  
  .border-2 {
      border-width: .2rem
  }
  
  .border-b {
      border-bottom-width: .1rem
  }
  
  .border-b-8 {
      border-bottom-width: .8rem
  }
  
  .border-l {
      border-left-width: .1rem
  }
  
  .border-t {
      border-top-width: .1rem
  }
  
  .border-t-8 {
      border-top-width: .8rem
  }
  
  .border-current {
      border-color: currentColor
  }
  
  .border-gray-900 {
      --tw-border-opacity: 1;
      border-color: rgb(31 31 31/var(--tw-border-opacity));
      transition: transform 0.9s ease;
  }
  
  .border-opacity-20 {
    
      --tw-border-opacity: 1.2;
      transition: transform 0.9s ease;
  }
  
  .bg-black {
      --tw-bg-opacity: 1;
      transition: transform 0.9s ease;
      /* background-color: rgb(0 0 0/var(--tw-bg-opacity)) */
  }
  
  .bg-current {
      background-color: currentColor
  }
  
  .bg-gray-100 {
      --tw-bg-opacity: 1;
      background-color: rgb(235 234 228/var(--tw-bg-opacity));
      transition: transform 0.9s ease;
  }
  
  .bg-gray-200 {
      --tw-bg-opacity: 1;
      background-color: rgb(145 149 152/var(--tw-bg-opacity));
      transition: transform 0.9s ease;
  }
  
  .bg-gray-300 {
      --tw-bg-opacity: 1;
      background-color: rgb(111 114 117/var(--tw-bg-opacity));
      transition: transform 0.9s ease;
  }
  
  .bg-gray-900 {
      --tw-bg-opacity: 1;
      background-color: rgb(31 31 31/var(--tw-bg-opacity));
      transition: transform 0.9s ease;
  }
   .nav-email{
       position: absolute;
       bottom: 10rem;
       /* background-color: red; */
       left: 3.5rem;
       color: white;
       width: 100%;
       font-size: 1.5rem;
   }
  .navsocial-icons-container{
      display: flex;
       /* Use flexbox to align icons in a row */
      /* margin-top: 5rem; */
      bottom: 30rem;
      left: 59rem;
      position: absolute;
      z-index: 100;
  }
  .navsocial-icons-container img{
      width: 32px;
      height: 32px;
      /* position: relative; */
      /* position: fixed; */
      right: 0;
      /* padding: rem; */
      position: sticky;
      margin-left: 1rem;
  }
  .bg-pink {
      --tw-bg-opacity: 1;
      background-color: rgb(243 135 200/var(--tw-bg-opacity))
  }
  
  .bg-white {
      --tw-bg-opacity: 1;
      background-color: rgb(255 255 255/var(--tw-bg-opacity))
  }
  
  .object-contain {
      -o-object-fit: contain;
      object-fit: contain
  }
  
  .object-cover {
      -o-object-fit: cover;
      object-fit: cover
  }
  
  /* .\ !p-5 {
      padding: .5rem !important
  } */
  
  .p-20 {
      padding: 2rem
  }
  
  .p-25 {
      padding: 2.5rem
  }
  
  .p-3 {
      padding: 3px
  }
  
  .p-30 {
      padding: 3rem
  }
  
  .p-60 {
      padding: 6rem
  }
  
  .px-10 {
      padding-left: 1rem;
      padding-right: 1rem
  }
  
  .px-20 {
      padding-left: 2rem;
      padding-right: 2rem
  }
  
  .px-25 {
      padding-left: 2.5rem;
      padding-right: 2.5rem
  }
  
  .px-30 {
      padding-left: 3rem;
      padding-right: 3rem
  }
  
  .py-10 {
      padding-bottom: 1rem;
      padding-top: 1rem
  }
  
  .py-100 {
      padding-bottom: 10rem;
      padding-top: 10rem
  }
  
  .py-12 {
      padding-bottom: 1.2rem;
      padding-top: 1.2rem
  }
  
  .py-15 {
      padding-bottom: 1.5rem;
      padding-top: 1.5rem
  }
  
  .py-20 {
      padding-bottom: 2rem;
      padding-top: 2rem
  }
  
  .py-30 {
      padding-bottom: 3rem;
      padding-top: 3rem
  }
  
  .py-40 {
      padding-bottom: 4rem;
      padding-top: 4rem
  }
  
  .py-6 {
      padding-bottom: .6rem;
      padding-top: .6rem
  }
  
  .py-70 {
      padding-bottom: 7rem;
      padding-top: 7rem
  }
  
  .py-8 {
      padding-bottom: .8rem;
      padding-top: .8rem
  }
  
  .pb-0 {
      padding-bottom: 0
  }
  
  .pb-100 {
      padding-bottom: 10rem
  }
  
  .pb-15 {
      padding-bottom: 1.5rem
  }
  
  .pb-20 {
      padding-bottom: 2rem
  }
  
  .pb-25 {
      padding-bottom: 2.5rem
  }
  
  .pb-30 {
      padding-bottom: 3rem
  }
  
  .pb-40 {
      padding-bottom: 4rem
  }
  
  .pb-60 {
      padding-bottom: 6rem
  }
  
  .pb-80 {
      padding-bottom: 8rem
  }
  
  .pl-20 {
      padding-left: 2rem
  }
  
  .pl-60 {
      padding-left: 6rem
  }
  
  .pr-10 {
      padding-right: 1rem
  }
  
  .pr-15 {
      padding-right: 1.5rem
  }
  
  .pr-20 {
      padding-right: 2rem
  }
  
  .pr-30 {
      padding-right: 3rem
  }
  
  .pr-35 {
      padding-right: 3.5rem
  }
  
  .pr-50 {
      padding-right: 5rem
  }
  
  .pr-60 {
      padding-right: 6rem
  }
  
  .pr-90 {
      padding-right: 9rem
  }
  
  .pt-100 {
      padding-top: 10rem
  }
  
  .pt-15 {
      padding-top: 1.5rem
  }
  
  .pt-20 {
      padding-top: 2rem
  }
  
  .pt-25 {
      padding-top: 2.5rem
  }
  
  .pt-30 {
      padding-top: 3rem
  }
  
  .pt-35 {
      padding-top: 3.5rem
  }
  
  .pt-40 {
      padding-top: 4rem
  }
  
  .pt-50 {
      padding-top: 5rem
  }
  
  .pt-60 {
      padding-top: 6rem
  }
  
  .pt-70 {
      padding-top: 7rem
  }
  
  .pt-80 {
      padding-top: 8rem
  }
  
  .pt-95 {
      padding-top: 9.5rem
  }
  
  .text-left {
      text-align: left
  }
  
  .text-center {
      text-align: center
  }
  
  .text-right {
      text-align: right
  }
  
  .text-4xl {
      font-size: 4rem
  }
  
  .text-5xl {
      font-size: 5rem
  }
  
  .text-7xl {
      font-size: 7rem
  }
  
  .text-8xl {
      font-size: 8.2rem
  }
  
  .text-base {
      font-size: 1.6rem
  }
  
  .text-hero-title-mobile {
      font-size: 18.5vw
  }
  
  .text-xl {
      /* font-size: 2.2rem */
  }
  
  .text-xs {
      font-size: 1.4rem
  }
  
  .font-bold {
      font-weight: 700
  }
  
  .font-medium {
      font-weight: 500
  }
  
  .uppercase {
      text-transform: uppercase;
      text-decoration: none;
  }
  
  .italic {
      font-style: italic
  }
  
  .leading-base {
      line-height: 1.3
  }
  
  .leading-none {
      line-height: 1
  }
  
  .leading-small {
      line-height: 1.2
  }
  
  .leading-tight {
      line-height: 1.1
  }
  
  .leading-xs {
      line-height: .9
  }
  
  .leading-xxs {
      line-height: .75
  }
  
  .tracking-tightest {
      letter-spacing: -.05em
  }
  
  .text-black {
      --tw-text-opacity: 1;
      color: rgb(0 0 0/var(--tw-text-opacity))
  }
  
  .text-gray-900 {
      --tw-text-opacity: 1;
      color: rgb(31 31 31/var(--tw-text-opacity))
  }
  
  .text-pink {
      --tw-text-opacity: 1;
      color: rgb(243 135 200/var(--tw-text-opacity))
  }
  
  .text-white {
      --tw-text-opacity: 1;
      color: rgb(255 255 255/var(--tw-text-opacity))
  }
  
  .antialiased {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale
  }
  
  .opacity-0 {
      opacity: 1
  }
  
  .opacity-20 {
      opacity: .2
  }
  
  .opacity-40 {
      opacity: .4
  }
  
  .outline {
      outline-style: solid
  }
  
  .grayscale {
      --tw-grayscale: grayscale(100%)
  }
  
  .filter,
  .grayscale {
      filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
  }
  
  .transition {
      transition-duration: .15s;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
      transition-timing-function: cubic-bezier(.4, 0, .2, 1)
  }
  
  .transition-colors {
      transition-duration: .15s;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(.4, 0, .2, 1)
  }
  
  .transition-transform {
      transition-duration: .15s;
      transition-property: transform;
      transition-timing-function: cubic-bezier(.4, 0, .2, 1)
  }
  
  .duration-300 {
      transition-duration: .3s
  }
  
  .duration-500 {
      transition-duration: .5s
  }
  
  .duration-700 {
      transition-duration: .7s
  }
  
  .ease-in-out {
      transition-timing-function: cubic-bezier(.19, 1, .22, 1)
  }
  
  .container {
      margin-left: auto;
      margin-right: auto;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      width: 100%
  }
  
  @media (min-width:1024px) {
      .container {
          /* padding-left: 3rem; */
          padding-right: 3rem
      }
  }
  
  .btn {
      align-items: center;
      -webkit-appearance: none;
      border-radius: 9999px;
      box-shadow: inset 0 0 0 1px transparent;
      cursor: pointer;
      display: inline-flex;
      /* font-family: Neue Haas Grotesk Text Pro, Roboto, sans-serif; */
      font-size: 1.4rem;
      font-weight: 700;
      height: 4rem;
      justify-content: center;
      line-height: 1.2;
      padding: 1.2rem 2.8rem;
      position: relative;
      text-transform: uppercase;
      transition-duration: .5s;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1);
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      white-space: nowrap
  }
  
  .btn:before {
      border-radius: inherit;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: inherit;
      width: 100%
  }
  
  .no-touchevents .btn:hover,
  .no-touchevents .group:not(.group--no-events):hover .btn {
      color: #fff;
      background-color: red;
  }
  
  .no-touchevents .btn:hover:before,
  .no-touchevents .group:not(.group--no-events):hover .btn:before {
      transform: scale(1.1);
      background-color: red;
  }
  
  .no-touchevents .btn:hover .btn__text,
  .no-touchevents .group:not(.group--no-events):hover .btn .btn__text {
      transform: translateY(-1.7em)
  }
  
  .btn:disabled {
      opacity: .5;
      pointer-events: none
  }
  
  .btn:focus {
      outline: 0
  }
  
  .btn--primary {
      color: #919598
  }
  
  .btn--primary:before {
      background: #1f1f1f
  }
  
  .btn--default {
      color: #fff
  }
  
  .btn--default:before {
      background: #1f1f1f
  }
  
  .btn--outline {
      background: 0 0;
      color: currentColor
  }
  
  .btn--outline:before {
      box-shadow: inset 0 0 0 1px rgba(31, 31, 31, .3)
  }
  
  .no-touchevents .btn--outline:hover,
  .no-touchevents .group:not(.group--no-events):hover .btn--outline {
      color: #fff
  }
  
  .no-touchevents .btn--outline:hover:before,
  .no-touchevents .group:not(.group--no-events):hover .btn--outline:before {
      background: #1f1f1f;
      box-shadow: inset 0 0 0 1px #1f1f1f
  }
  
  .no-touchevents .btn--outline.is-active,
  .no-touchevents .btn--outline:active,
  .no-touchevents .is-checked .btn--outline {
      color: #fff
  }
  
  .no-touchevents .btn--outline.is-active:before,
  .no-touchevents .btn--outline:active:before,
  .no-touchevents .is-checked .btn--outline:before {
      background: #1f1f1f;
      box-shadow: inset 0 0 0 1px #1f1f1f
  }
  
  .btn__text .btn__icon,
  .btn__text__container {
      position: relative;
      z-index: 1
  }
  
  .btn__text__container {
      height: 1.2em;
      overflow: hidden
  }
  
  .btn__text {
      grid-gap: .5em;
      display: grid;
      transition-duration: .5s;
      transition-property: transform;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1)
  }
  
  .btn__text:after {
      content: attr(data-text)
  }
  
  .btn__icon {
      align-items: center;
      border-radius: 50%;
      display: flex;
      height: 2.4rem;
      justify-content: center;
      overflow: hidden;
      width: 2.4rem
  }
  
  .btn__icon--prepend {
      margin-right: 4rem
  }
  
  .btn__icon--append {
      margin-left: 4rem
  }
  
  .link {
      cursor: pointer;
      display: inline-flex;
      position: relative
  }
  
  .link:after {
      background: currentColor;
      bottom: -.1rem;
      content: "";
      height: .1rem;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      transform: scaleX(0);
      transform-origin: 100% 100%;
      transition-duration: .5s;
      transition-property: transform;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1);
      will-change: transform
  }
  
  .active .link:after,
  .group.is-active .link:after,
  .link--underline:after,
  .link.is-active:after,
  .no-touchevents .group.is-active .link:after,
  .no-touchevents .group:hover .link:after,
  .no-touchevents .link.is-active:after,
  .no-touchevents .link:hover:after {
      transform: scaleX(1);
      transform-origin: 0 0
  }
  
  .link--underline:after {
      animation: none
  }
  
  .group.is-active .link--underline:after,
  .link--underline.is-active:after,
  .no-touchevents .group.is-active .link--underline:after,
  .no-touchevents .group:hover .link--underline:after,
  .no-touchevents .link--underline.is-active:after,
  .no-touchevents .link--underline:hover:after {
      animation: link .75s forwards
  }
  
  .touchevents .link--underline {
      text-decoration: underline
  }
  
  .touchevents .link--underline:after {
      display: block
  }
  
  .group.is-active .link--underline-default,
  .group:hover .link--underline-default,
  .link--underline-default.is-active,
  .link--underline-default:hover {
      text-decoration: none
  }
  
  .link--custom .link__inner {
      display: block;
      overflow: hidden;
      position: relative
  }
  
  .no-touchevents .group.is-active .link--custom .link__hover-text,
  .no-touchevents .group:hover .link--custom .link__hover-text,
  .no-touchevents .link--custom.is-active .link__hover-text,
  .no-touchevents .link--custom:hover .link__hover-text {
      transform: none
  }
  
  .no-touchevents .group.is-active .link--custom .link__default-text,
  .no-touchevents .group:hover .link--custom .link__default-text,
  .no-touchevents .link--custom.is-active .link__default-text,
  .no-touchevents .link--custom:hover .link__default-text {
      transform: translateY(-125%)
  }
  
  .group.is-active .link--custom .link__hover-text,
  .link--custom.is-active .link__hover-text {
      transform: none
  }
  
  .group.is-active .link--custom .link__default-text,
  .link--custom.is-active .link__default-text {
      transform: translateY(-125%)
  }
  
  .link--innactive .splitter-row .link {
      display: inline-block !important;
      vertical-align: top
  }
  
  .multiline-text-outline .line-w {
      position: relative
  }
  
  .multiline-text-outline .line-w:after {
      background: currentColor;
      bottom: 0;
      content: "";
      height: 1px;
      left: 0;
      opacity: .2;
      position: absolute;
      transform: scaleX(0);
      transform-origin: 0 0;
      transition: transform .8s ease-in-out;
      width: 200%
  }
  
  .multiline-text-outline .line-w.is-animated:after {
      transform: none;
      transition-delay: .15s
  }
  
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0
  }
  
  input::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none
  }
  
  input[type=number] {
      -moz-appearance: textfield
  }
  
  select {
  
      background-position: calc(100% - 2rem) 50%;
      background-repeat: no-repeat;
      background-size: 1rem
  }
  
  input[type=submit] {
     
      border: 1px solid #1f1f1f;
      color: #fff;
      cursor: pointer;
      
      font-size: 1.4rem;
      font-weight: 500;
      height: 4rem;
      line-height: 1;
      padding: 1rem 1.5rem;
      text-transform: uppercase;
      transition-duration: .3s;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(.19, 1, .22, 1)
  }
  
  input[type=submit]:hover {
      background-color: transparent;
      color: #1f1f1f
  }
  
  .woocommerce-input-wrapper .choices,
  input[type=email],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=text],
  select,
  textarea {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-color: transparent;
      border-bottom: 1px solid rgba(31, 31, 31, .2);
      border-radius: 0;
      color: currentColor;
      /* font-family: Neue Haas Grotesk Text Pro, Roboto, sans-serif; */
      font-size: 1.6rem;
      font-weight: 700;
      height: 5rem;
      line-height: 1;
      padding: 1rem 0;
      text-transform: uppercase;
      transition-duration: .3s;
      transition: transform 0.5s ease;
      transition-property: all;
      transition-timing-function: cubic-bezier(.19, 1, .22, 1);
      width: 100%
  }
  
  .woocommerce-input-wrapper .choices:focus,
  .woocommerce-input-wrapper .choices:hover,
  input[type=email]:focus,
  input[type=email]:hover,
  input[type=password]:focus,
  input[type=password]:hover,
  input[type=search]:focus,
  input[type=search]:hover,
  input[type=tel]:focus,
  input[type=tel]:hover,
  input[type=text]:focus,
  input[type=text]:hover,
  select:focus,
  select:hover,
  textarea:focus,
  textarea:hover {
      opacity: 1;
      outline: 0
  }
  
  .is-invalid .woocommerce-input-wrapper .choices,
  .is-invalid input[type=email],
  .is-invalid input[type=password],
  .is-invalid input[type=search],
  .is-invalid input[type=tel],
  .is-invalid input[type=text],
  .is-invalid select,
  .is-invalid textarea,
  .woocommerce-input-wrapper .choices.is-invalid,
  .woocommerce-invalid .woocommerce-input-wrapper .choices,
  .woocommerce-invalid input[type=email],
  .woocommerce-invalid input[type=password],
  .woocommerce-invalid input[type=search],
  .woocommerce-invalid input[type=tel],
  .woocommerce-invalid input[type=text],
  .woocommerce-invalid select,
  .woocommerce-invalid textarea,
  input[type=email].is-invalid,
  input[type=password].is-invalid,
  input[type=search].is-invalid,
  input[type=tel].is-invalid,
  input[type=text].is-invalid,
  select.is-invalid,
  textarea.is-invalid {
      border-color: #f387c8
  }
  
  .woocommerce-input-wrapper .choices.form-control--simple,
  input[type=email].form-control--simple,
  input[type=password].form-control--simple,
  input[type=search].form-control--simple,
  input[type=tel].form-control--simple,
  input[type=text].form-control--simple,
  select.form-control--simple,
  textarea.form-control--simple {
      background: #fff;
      border: 0;
      border-radius: 9999px;
      height: 4rem;
      padding: 1rem 2rem
  }
  
  input::-moz-placeholder,
  textarea::-moz-placeholder {
      color: currentColor;
      font-size: 1.4rem;
      opacity: .3;
      transition-duration: .3s;
      -moz-transition-property: opacity;
      transition-property: opacity;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1);
      transition: transform 0.5s ease;
  }
  
  input::placeholder,
  textarea::placeholder {
      color: currentColor;
      font-size: 1.4rem;
      opacity: .3;
      transition-duration: .3s;
      transition-property: opacity;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1);
      transition: transform 0.5s ease;
  }
  
  input:hover::-moz-placeholder,
  textarea:hover::-moz-placeholder {
      opacity: 1
  }
  
  input:hover::placeholder,
  textarea:hover::placeholder {
      opacity: 1
  }
  
  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
      opacity: 1
  }
  
  input:focus::placeholder,
  textarea:focus::placeholder {
      opacity: 1
  }
  
  input:not(:-moz-placeholder-shown),
  textarea:not(:-moz-placeholder-shown) {
      opacity: 1
  }
  
  input:not(:placeholder-shown),
  textarea:not(:placeholder-shown) {
      opacity: 1
  }
  
  .input-group {
      align-items: center;
      display: flex;
      position: relative
  }
  
  .input-group--simple:after {
      display: block
  }
  
  .input-group:after {
      background: #1f1f1f;
      bottom: 0;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      transform: scaleX(0);
      transform-origin: 100% 100%;
      transition-duration: .3s;
      transition-property: transform;
      transition-timing-function: cubic-bezier(.215, .61, .355, 1);
      transition: transform 0.5s ease;
      width: 100%
  }
  
  .input-group:focus-within:after,
  .input-group:hover:after {
      transform: scaleX(1);
      transform-origin: 0 0
  }
  
  .input-group:focus-within .input-group__input:placeholder,
  .input-group:hover .input-group__input:placeholder {
      opacity: 1
  }
  
  .input-group.has-error:after,
  .input-group.invalid:after {
      opacity: 1
  }
  
  blockquote {
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 1.1;
      text-transform: uppercase
  }
  
  @media (min-width:1024px) {
      blockquote {
          font-size: 2.5rem
      }
  }
  
  @media (min-width:1280px) {
      blockquote {
          font-size: 3.5rem
      }
  }
  
  blockquote strong {
      color: #f387c8
  }
  
  blockquote p {
      margin-bottom: 3rem
  }
  
  blockquote p:first-of-type:before {
      content: "“";
      display: flex;
      line-height: 1;
      position: relative;
      top: .3em
  }
  
  blockquote p:not(data-animation="split")blockquote p:last-of-type:after {
      content: "”";
      display: inline-block
  }
  
  blockquote p:last-child {
      margin-bottom: 0
  }
  
  blockquote cite {
      align-items: center;
      display: grid;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700
  }
  
  @media (min-width:1024px) {
      blockquote cite {
          -moz-column-gap: 3rem;
          column-gap: 3rem;
          grid-auto-flow: column;
          justify-content: flex-start
      }
  }
  
  textarea {
      display: flex;
      min-height: 13rem;
      padding: 1.7rem 0;
      resize: none
  }
  
  @media (min-width:1024px) {
      textarea {
          min-height: 5rem;
          padding: 1.6rem 0
      }
  }
  
  [data-error-field] {
      color: #f387c8;
      font-size: .8rem;
      font-weight: 700;
      left: 0;
      line-height: 1;
      padding: .2rem 0;
      position: absolute;
      text-transform: uppercase;
      top: 100%
  }
  
  hr {
      border-color: currentColor;
      opacity: .15
  }
  
  .nav--inline {
      align-items: center;
      display: flex
  }
  
  .nav--inline li:after {
      content: ",";
      margin-right: .2em
  }
  
  .nav--inline li:last-child:after {
      display: block
  }
  
  .list-inline li.is-active {
      opacity: 1;
      pointer-events: none
  }
  
  .list-inline li.is-active~li {
      opacity: .5;
      pointer-events: none
  }
  
  .social-list {
      align-items: center;
      display: inline-flex
  }
  
  .social-list li:after {
      content: "/";
      display: inline-block;
      margin: 0 .5em;
      position: relative;
      top: -.05em;
      vertical-align: middle
  }
  
  .social-list li:last-child:after {
      display: block
  }
  
  .social-list__item {
      align-items: center;
      display: flex
  }
  
  /* .social-list__item:after {
      content: /;
      display: inline-block;
      margin: 0 .5em;
      vertical-align: middle
  } */
  
  .social-list__item:last-child:after {
      display: block
  }
  
  @media (min-width:768px) {
      .double-columns-text {
          position: relative
      }
      .double-columns-text:before {
          background: #fff;
          bottom: -.1rem;
          content: "";
          left: 50%;
          margin-left: -1.25rem;
          position: absolute;
          top: -.1rem;
          width: 2.5rem
      }
  }
  
  [data-component=preloader] [data-preloader-part=left] {
      left: 200%
  }
  
  [data-component=preloader] [data-preloader-part=right] {
      left: 100%
  }
  
  [data-component=preloader] .ch,
  [data-component=preloader] [data-move-up] {
      display: inline-block;
      transform: translateY(150%);
      vertical-align: top
  }
  
  [data-header] {
      position: fixed
  }
  
  [data-header] [data-menu-item]:first-child {
      width: calc(100% - 25rem)
  }
  
  [data-header] [data-menu-item]:nth-child(2) {
      width: calc(100% - 16rem)
  }
  
  [data-header] [data-menu-item]:nth-child(3) {
      width: 80%
  }
  
  [data-header] [data-menu-item]:nth-child(4) {
      width: 100%
  }
  
  [data-page-scroller] {
      position: relative
  }
  
  .header-holder:before {
      background: #fff;
      bottom: 100%;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      transition: transform .35s cubic-bezier(.3, .86, .36, .95);
      transition: transform 0.5s ease;
      z-index: -1
  }
  
  .theme-gray-dark .header-holder:before {
      background: #919598
  }
  
  .theme-gray-light .header-holder:before {
      background: #ebeae4
  }
  
  [data-not-top=true] .header-holder:before {
      transform: translateY(100%)
  }
  
  .header-nav-bar {
      transform: translateY(-10rem)
  }
  
  .header-nav-bar ul {
      transition: padding-left 0s .8s
  }
  
  [data-menu] {
      /* height: calc(var(--vh)*100); */
      opacity: 1;
      /* pointer-events: none */
  }
  
  .menu--opened [data-menu] {
      pointer-events: all
  }
  
  [data-menu] .is-active .header-item-dot {
      transform: scale(0)
  }
  
  .form-tab-title {
      align-items: center;
      display: block;
      opacity: .3;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: opacity .35s cubic-bezier(.3, .86, .36, .95), background-color .35s cubic-bezier(.3, .86, .36, .95);
      white-space: nowrap
  }
  
  .form-tab-title:hover {
      opacity: .7
  }
  
  .form-tab-title:before {
      border-radius: 50%;
      box-shadow: inset 0 0 0 .15em currentColor;
      content: "";
      display: inline-block;
      flex-shrink: 0;
      height: .75em;
      margin-right: 1rem;
      transition: box-shadow .5s cubic-bezier(.3, .86, .36, .95);
      width: .75em
  }
  
  .form-tab-title.is-active {
      opacity: 1
  }
  
  .form-tab-title.is-active:before {
      box-shadow: inset 0 0 0 .5em currentColor
  }
  
  [data-tabs-headline] {
      position: relative;
      z-index: 1
  }
  
  [data-tabs-headline]:before {
      background: #fff;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: transform .5s cubic-bezier(.3, .86, .36, .95);
      width: 50%;
      /* z-index: -1 */
  }
  
  [data-direction=left] [data-tabs-headline]:before {
      transform: none
  }
  
  [data-direction=right] [data-tabs-headline]:before {
      transform: translateX(100%)
  }
  
  [data-component=tabs] {
      position: relative
  }
  
  @media screen and (min-width:768px) {
      form[name=contact] .input-group:nth-child(2) {
          border-top: 1px solid rgba(31, 31, 31, .2)
      }
      .case-page .case-content {
          align-items: flex-start;
          display: grid
      }
      [data-header] [data-menu-item]:first-child {
          width: 33.333%
      }
      [data-header] [data-menu-item]:nth-child(2) {
          width: 50.666%
      }
  }
  
  @media screen and (min-width:1024px) {
      .form-message-success {
          font-size: 3.4rem
      }
      .case-content__right .wysiwyg {
          padding-right: 9rem
      }
  }
  
  @media screen and (min-width:1280px) {
      [data-stack-parent] {
          height: 800vh
      }
      [data-stack-parent=services] {
          height: 400vh
      }
      .client-row-element:hover .client-row-image {
          -webkit-clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
          clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%)
      }
      .client-row-element:hover .client-row-circle {
          transform: scale(1);
          transition-delay: .2s;
          transition: transform 0.5s ease
      }
      .client-row-element:hover .client-row-title {
          transform: translateX(3rem)
      }
      .client-row-element:nth-last-child(5)~.client-row-element .client-row-image {
          bottom: 0;
          top: auto
      }
      .home [data-header] {
          position: absolute
      }
      .home[data-reveal-header=true] [data-header] {
          position: fixed
      }
      .header-burger-bar,
      .header-nav-bar {
          transition: transform .35s cubic-bezier(.3, .86, .36, .95)
      }
      .header-nav-bar {
          transform: none
      }
      .header-burger-bar,
      .is-loading .header-nav-bar,
      [data-not-top=true] .header-nav-bar {
          transform: translateY(-10rem)
      }
      [data-not-top=true] .header-burger-bar {
          transform: none
      }
      .error-page .header-nav-bar ul,
      .homepage .header-nav-bar ul {
          padding-left: 2rem
      }
  }
  
  @media screen and (max-width:767px) {
      .wrapper {
          overflow-x: hidden
      }
  }
  
  @media (min-width:768px) {
      .md\:custom-outline-text {
          background-image: linear-gradient(180deg, transparent, transparent calc(1.5em - .1rem), rgba(31, 31, 31, .3) 0, rgba(31, 31, 31, .3) 1.5em);
          background-repeat: repeat-y;
          background-size: 100% 1.5em;
          line-height: 1.5em;
          padding-bottom: .4rem
      }
      .md\:custom-outline-text>* {
          margin: 0;
          padding-bottom: 1.5em
      }
      .md\:custom-outline-text>:last-child {
          padding-bottom: 0
      }
  }
  
  .group:hover .group-hover\:scale-0 {
      --tw-scale-x: 0;
      --tw-scale-y: 0
  }
  
  .group:hover .group-hover\:scale-0,
  .group:hover .group-hover\:scale-105 {
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  
  .group:hover .group-hover\:scale-105 {
      --tw-scale-x: 1.05;
      --tw-scale-y: 1.05
  }
  
  .group:hover .group-hover\:transform-none {
      transform: none
  }
  
  .group:hover .group-hover\:opacity-100 {
      opacity: 1
  }
  
  @media (min-width:768px) {
      .md\:static {
          position: static
      }
      .md\:absolute {
          position: absolute
      }
      .md\:sticky {
          position: sticky
      }
      .md\:bottom-0 {
          bottom: 0
      }
      .md\:left-0 {
          left: 0
      }
      .md\:left-25 {
          left: 2.5rem
      }
      .md\:left-30 {
          left: 3rem
      }
      .md\:right-0 {
          right: 0
      }
      .md\:right-25 {
          right: 2.5rem
      }
      .md\:top-0 {
          top: 0
      }
      .md\:top-50 {
          top: 5rem
      }
      .md\:top-60 {
          top: 6rem
      }
      .md\:top-80 {
          top: 8rem
      }
      .md\:top-hero-subtitle-tablet {
          top: 20vw
      }
      .md\:col-span-2 {
          grid-column: span 2/span 2
      }
      .md\:col-span-4 {
          grid-column: span 4/span 4
      }
      .md\:col-span-6 {
          /* grid-column: span 6/span 6 */
      }
      .md\:col-span-8 {
          grid-column: span 8/span 8
      }
      .md\:col-start-2 {
          grid-column-start: 2
      }
      .md\:col-start-3 {
          grid-column-start: 3
      }
      .md\:col-end-2 {
          grid-column-end: 2
      }
      .md\:mx-0 {
          margin-left: 0;
          margin-right: 0
      }
      .md\:-mt-15 {
          margin-top: -1.5rem
      }
      .md\:-mt-50 {
          margin-top: -5rem
      }
      .md\:mb-0 {
          margin-bottom: 0
      }
      .md\:mb-160 {
          margin-bottom: 16rem
      }
      .md\:mb-30 {
          margin-bottom: 3rem
      }
      .md\:mb-50 {
          margin-bottom: 5rem
      }
      .md\:mb-60 {
          margin-bottom: 6rem
      }
      .md\:mb-70 {
          margin-bottom: 7rem
      }
      .md\:ml-auto {
          margin-left: auto
      }
      .md\:mr-1\/6 {
          margin-right: 16.666667%
      }
      .md\:mt-110 {
          margin-top: 11rem
      }
      .md\:mt-auto {
          margin-top: auto
      }
      .md\:block {
          display: block
      }
      .md\:flex {
          display: flex
      }
      .md\:grid {
          display: grid
      }
      .md\:hidden {
          display: block
      }
      .md\:h-25 {
          height: 2.5rem
      }
      .md\:h-300 {
          height: 30rem
      }
      .md\:h-auto {
          height: auto
      }
      .md\:h-full {
          height: 100%
      }
      .md\:h-screen {
          height: 100vh
      }
      .md\:min-h-360 {
          min-height: 36rem
      }
      .md\:min-h-70 {
          min-height: 7rem
      }
      .md\:min-h-700 {
          min-height: 70rem
      }
      .md\:w-1\/2 {
          width: 50%
      }
      .md\:w-100 {
          width: 10rem
      }
      .md\:w-2\/3 {
          width: 66.666667%
      }
      .md\:w-25 {
          width: 2.5rem
      }
      .md\:w-auto {
          width: auto
      }
      .md\:flex-grow {
          flex-grow: 1
      }
      .md\:columns-2 {
          -moz-columns: 2;
          column-count: 2
      }
      .md\:grid-flow-col {
          grid-auto-flow: column
      }
      .md\:grid-cols-12 {
          grid-template-columns: repeat(12, minmax(0, 1fr))
      }
      .md\:grid-cols-2 {
          grid-template-columns: repeat(2, minmax(0, 1fr))
      }
      .md\:grid-cols-6 {
          grid-template-columns: repeat(6, minmax(0, 1fr))
      }
      .md\:items-end {
          align-items: flex-end
      }
      .md\:justify-end {
          justify-content: flex-end
      }
      .md\:justify-center {
          justify-content: center
      }
      .md\:gap-40 {
          gap: 4rem
      }
      .md\:gap-x-20 {
          -moz-column-gap: 2rem;
          column-gap: 2rem
      }
      .md\:whitespace-nowrap {
          white-space: nowrap
      }
      .md\:border {
          border-width: .1rem
      }
      .md\:border-0 {
          border-width: 0
      }
      .md\:border-l {
          border-left-width: .1rem
      }
      .md\:border-r {
          border-right-width: .1rem
      }
      .md\:border-t {
          border-top-width: .1rem
      }
      .md\:border-t-0 {
          border-top-width: 0
      }
      .md\:border-gray-900 {
          --tw-border-opacity: 1;
          border-color: rgb(31 31 31/var(--tw-border-opacity))
      }
      .md\:border-opacity-20 {
          --tw-border-opacity: 1.2
      }
      .md\:p-0 {
          padding: 0
      }
      .md\:p-20 {
          padding: 2rem
      }
      .md\:p-25 {
          padding: 2.5rem
      }
      .md\:p-30 {
          padding: 3rem
      }
      .md\:p-5 {
          padding: .5rem
      }
      .md\:px-0 {
          padding-left: 0;
          padding-right: 0
      }
      .md\:px-20 {
          padding-left: 2rem;
          padding-right: 2rem
      }
      .md\:px-30 {
          padding-left: 3rem;
          padding-right: 3rem
      }
      .md\:pl-0 {
          padding-left: 0
      }
      .md\:pl-30 {
          padding-left: 3rem
      }
      .md\:pr-0 {
          padding-right: 0
      }
      .md\:pr-90 {
          padding-right: 9rem
      }
      .md\:pt-0 {
          padding-top: 0
      }
      .md\:pt-10 {
          padding-top: 1rem
      }
      .md\:pt-50 {
          padding-top: 5rem
      }
      .md\:text-15xl {
          font-size: 15.4rem
      }
      .md\:text-19xl {
          font-size: 15.5rem
      }
      .md\:text-2xl {
          font-size: 2.8rem
      }
      .md\:text-3xl {
          font-size: 2.4rem
      }
      .md\:text-hero-title-tablet {
          font-size: 12.67vw
      }
  }
  
  @media (min-width:1024px) {
      .lg\:left-30 {
          left: 3rem
      }
      .lg\:right-30 {
          right: 3rem
      }
      .lg\:top-30 {
          top: 3rem
      }
      .lg\:top-hero-subtitle {
          top: 18vw
      }
      .lg\:col-span-2 {
          grid-column: span 2/span 2
      }
      .lg\:col-span-3 {
          grid-column: span 3/span 3
      }
      .lg\:col-span-4 {
          grid-column: span 4/span 4
      }
      .lg\:mb-0 {
          margin-bottom: 0
      }
      .lg\:mb-100 {
          margin-bottom: 10rem
      }
      .lg\:mb-150 {
          margin-bottom: 15rem
      }
      .lg\:mb-180 {
          margin-bottom: 18rem
      }
      .lg\:mb-30 {
          margin-bottom: 3rem
      }
      .lg\:mb-40 {
          margin-bottom: 4rem
      }
      .lg\:mb-45 {
          margin-bottom: 4.5rem
      }
      .lg\:mb-50 {
          margin-bottom: 5rem
      }
      .lg\:mb-60 {
          margin-bottom: 6rem
      }
      .lg\:mb-70 {
          margin-bottom: 7rem
      }
      .lg\:mb-85 {
          margin-bottom: 8.5rem
      }
      .lg\:ml-auto {
          margin-left: auto
      }
      .lg\:mr-0 {
          margin-right: 0
      }
      .lg\:h-160 {
          height: 16rem
      }
      .lg\:h-25 {
          height: 2.5rem
      }
      .lg\:h-full {
          height: 100%
      }
      .lg\:w-160 {
          width: 16rem
      }
      .lg\:w-25 {
          width: 2.5rem
      }
      .lg\:grid-flow-col {
          grid-auto-flow: column
      }
      .lg\:grid-cols-6 {
          grid-template-columns: repeat(6, minmax(0, 1fr))
      }
      .lg\:justify-start {
          justify-content: flex-start
      }
      .lg\:gap-x-20 {
          -moz-column-gap: 2rem;
          column-gap: 2rem
      }
      .lg\:border-t-8 {
          border-top-width: .8rem
      }
      .lg\:p-30 {
          padding: 3rem
      }
      .lg\:px-25 {
          padding-left: 2.5rem;
          padding-right: 2.5rem
      }
      .lg\:px-30 {
          padding-left: 3rem;
          padding-right: 3rem
      }
      .lg\:py-0 {
          padding-bottom: 0;
          padding-top: 0
      }
      .lg\:py-40 {
          padding-bottom: 4rem;
          padding-top: 4rem
      }
      .lg\:pb-0 {
          padding-bottom: 0
      }
      .lg\:pb-100 {
          padding-bottom: 10rem
      }
      .lg\:pb-180 {
          padding-bottom: 18rem
      }
      .lg\:pb-25 {
          padding-bottom: 2.5rem
      }
      .lg\:pb-30 {
          padding-bottom: 3rem
      }
      .lg\:pb-40 {
          padding-bottom: 4rem
      }
      .lg\:pb-50 {
          padding-bottom: 5rem
      }
      .lg\:pl-120 {
          padding-left: 12rem
      }
      .lg\:pl-30 {
          padding-left: 3rem
      }
      .lg\:pr-120 {
          padding-right: 12rem
      }
      .lg\:pr-25 {
          padding-right: 2.5rem
      }
      .lg\:pr-30 {
          padding-right: 3rem
      }
      .lg\:pr-70 {
          padding-right: 7rem
      }
      .lg\:pt-100 {
          padding-top: 10rem
      }
      .lg\:pt-150 {
          padding-top: 15rem
      }
      .lg\:pt-20 {
          padding-top: 2rem
      }
      .lg\:pt-30 {
          padding-top: 3rem
      }
      .lg\:pt-40 {
          padding-top: 4rem
      }
      .lg\:pt-45 {
          padding-top: 4.5rem
      }
      .lg\:pt-50 {
          padding-top: 5rem
      }
      .lg\:text-left {
          text-align: left
      }
      .lg\:text-10xl {
          font-size: 10rem
      }
      .lg\:text-3xl {
          font-size: 3.4rem
      }
      .lg\:text-5xl {
          font-size: 5rem
      }
      .lg\:text-8xl {
          font-size: 8.2rem
      }
  }
  
  @media (min-width:1280px) {
      .laptop\:fixed {
          position: fixed
      }
      .laptop\:absolute {
          position: absolute
      }
      .laptop\:sticky {
          position: sticky
      }
      .laptop\:bottom-30 {
          bottom: 3rem
      }
      .laptop\:left-0 {
          left: 0
      }
      .laptop\:right-0 {
          right: 0
      }
      .laptop\:right-30 {
          right: 3rem
      }
      .laptop\:right-full {
          right: 100%
      }
      .laptop\:top-0 {
          top: 0
      }
      .laptop\:top-150 {
          top: 15rem
      }
      .laptop\:top-30 {
          top: 3rem
      }
      .laptop\:top-50 {
          top: 5rem
      }
      .laptop\:top-55 {
          top: 5.5rem
      }
      .laptop\:col-span-4 {
          grid-column: span 4/span 4
      }
      .laptop\:col-span-6 {
          grid-column: span 6/span 6
      }
      .laptop\:col-start-3 {
          grid-column-start: 3
      }
      .laptop\:-mb-100 {
          margin-bottom: -10rem
      }
      .laptop\:-mb-30 {
          margin-bottom: -3rem
      }
      .laptop\:-mb-70 {
          margin-bottom: -7rem
      }
      .laptop\:-mt-25 {
          margin-top: -2.5rem
      }
      .laptop\:mb-0 {
          margin-bottom: 0
      }
      .laptop\:mb-100 {
          margin-bottom: 10rem
      }
      .laptop\:mb-120 {
          margin-bottom: 12rem
      }
      .laptop\:mb-130 {
          margin-bottom: 13rem
      }
      .laptop\:mb-180 {
          margin-bottom: 18rem
      }
      .laptop\:mb-50 {
          margin-bottom: 5rem
      }
      .laptop\:mr-20 {
          margin-right: 2rem
      }
      .laptop\:block {
          display: block
      }
      .laptop\:flex {
          display: flex
      }
      .laptop\:grid {
          display: grid
      }
      .laptop\:hidden {
          display: block
      }
      .laptop\:h-25 {
          height: 2.5rem
      }
      .laptop\:h-460 {
          height: 46rem
      }
      .laptop\:h-full {
          height: 100%
      }
      .laptop\:h-screen {
          height: 1000vh
      }
      .laptop\:min-h-360 {
          min-height: 36rem
      }
      .laptop\:w-210 {
          width: 21rem
      }
      .laptop\:w-25 {
          width: 2.5rem
      }
      .laptop\:w-690 {
          width: 69rem
      }
      .laptop\:flex-shrink-0 {
          flex-shrink: 0
      }
      .laptop\:grid-flow-col {
          grid-auto-flow: column
      }
      .laptop\:grid-cols-6 {
          grid-template-columns: repeat(6, minmax(0, 1fr))
      }
      .laptop\:grid-cols-none {
          grid-template-columns: none
      }
      .laptop\:flex-col {
          flex-direction: column
      }
      .laptop\:justify-start {
          justify-content: flex-start
      }
      .laptop\:gap-0 {
          gap: 0
      }
      .laptop\:gap-x-160 {
          -moz-column-gap: 16rem;
          column-gap: 16rem
      }
      .laptop\:gap-x-60 {
          -moz-column-gap: 6rem;
          column-gap: 6rem
      }
      .laptop\:border-l {
          border-left-width: .1rem
      }
      .laptop\:border-l-0 {
          border-left-width: 0
      }
      .laptop\:border-r-0 {
          border-right-width: 0
      }
      .laptop\:border-gray-900 {
          --tw-border-opacity: 1;
          border-color: rgb(31 31 31/var(--tw-border-opacity))
      }
      .laptop\:border-opacity-20 {
          --tw-border-opacity: 1.2
      }
      .laptop\:bg-transparent {
       
      }
      .laptop\:p-0 {
          padding: 0
      }
      .laptop\:p-30 {
          padding: 3rem
      }
      .laptop\:px-0 {
          padding-left: 0;
          padding-right: 0
      }
      .laptop\:px-30 {
          padding-left: 3rem;
          padding-right: 3rem
      }
      .laptop\:py-100 {
          padding-bottom: 10rem;
          padding-top: 10rem
      }
      .laptop\:pl-0 {
          padding-left: 0
      }
      .laptop\:pr-0 {
          padding-right: 0
      }
      .laptop\:pr-40 {
          padding-right: 4rem
      }
      .laptop\:pr-50 {
          padding-right: 5rem
      }
      .laptop\:pr-60 {
          padding-right: 6rem
      }
      .laptop\:pt-0 {
          padding-top: 0
      }
      .laptop\:text-15xl {
          font-size: 15.4rem
      }
      .laptop\:text-19xl {
          font-size: 19.5rem
      }
      .laptop\:text-21xl {
          font-size: 21rem
      }
      .laptop\:text-8xl {
          font-size: 8.2rem
      }
      .laptop\:text-hero-title {
          font-size: 12.96vw
      }
      .group:hover .laptop\:group-hover\:bg-gray {
          --tw-bg-opacity: 1;
          background-color: rgb(180 180 180/var(--tw-bg-opacity))
      }
  }
  /* </style> */
  @media (min-width:900px) {
  .nav-emaile{
      display: none;
  }
  }
  @media (max-width:900px) {
      .navsocial-icons-container{
          display: none;
      }
      .nav-email{
  
          display: none;
     
      }
      .nav-emaile{
          color: black;
          font-size: 2rem;
          position: absolute;
          bottom: 0;
      }
      .flex{
      
          text-decoration: none;
      }
  }
  Link{
      text-decoration: none;
  }
  .navopenlogo{
      margin-top: 2rem;
      transition: all 0.9s ease;
  }

  .logo .hover-text {
    display: none;
  }
  
  .logo:hover .initial {
    display: none;
    opacity: 0;
  }
  
  .logo:hover .hover-text {
    display: inline;
    /* transition: opacity 0.2s ease-in-out; */
    transition: opacity 5.46s cubic-bezier(.58,0,.01,1),height 1.46s cubic-bezier(.58,0,.01,1),opacity 1.46s cubic-bezier(.58,0,.01,1)
  }
  a{
    text-decoration: none;
  }
 
  .navsocial-icons-container {
    
    position: absolute;
    bottom: 30rem;
    left: 35.3vw;
    transform: translateX(-50%);
    z-index: 100;
    
}


.hamburger-menu ul{
    /* transition: all 50.9s ease; */
}
.hamburger-menu ul {
    transition: all 0.9s ease; /* Adjust the duration (0.9s) to make the transition slower */
  }
  

  .logo:focus{
background-color: red;
  }

  .animated-link {
    opacity: 0;
    transform: translateX(100px); /* Start offscreen to the right */
    animation: fadeInTranslate 0.5s ease forwards;
    transition: all 0.9s ease;
  }
  
  @keyframes fadeInTranslate {
    from {
      opacity: 0;
      transform: translateX(100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  