.helicaerphaed{
    /* background-color: red; */
    color: black;
    font-size: 2vw;
    font-family: Helveticaneue,sans-serif;
}
.helicaerphaed span{
    font-family: Helveticaneue,sans-serif; 
    text-transform: capitalize;
}
.with-line::before {
    content: "";
    display: block;
    border-bottom: 2px solid black; /* You can adjust the line style here */
    margin-bottom: 10px; /* Adjust the spacing between the line and the heading as needed */
  }
  
  .span-text.intro-one.newhelicaerp{
    
    font-size: 3rem;
  }
.span-text.intro-one.newgetnotified{
    font-size: 4rem;
    text-transform: capitalize;
    font-weight: 600;
    margin-top: 2rem;
    line-height: 4.5rem;
}
.newerpbody{
    margin-bottom: 2rem;
    
    
}
.primary-btn.border.newforerp{
    height:3em
}

@media (max-width: 860px) {
.s-intro__content{
    margin-top: 1rem;
}
.grid-home-item.blurerp{
    margin-top: 4rem;
}

}
.text-pretitle{
 left: 6rem;
 position: absolute;
 width: 200px;
 font-size: 12px;
 margin-top: -0.4rem;

}

:root {
    --color-background: #EEEEEE;
    --color-design:     rgba(72, 85, 124, 0.80) ;
    --color-research:   rgba(0, 37, 143, 0.80);
    --radius:           240px;

  }
  
 
  @media (min-width: 640px) {
    
  .canvas,
  .overlay {
    position: fixed;
    top: 2rem;
    right: 0;
    left: 13%;
    /* bottom: 0; */
    width: 100%;
    /* top: 5rem; */
    /* height: 100%; */
    /* overflow: hidden; */
  }
}
  
  .canvas {
    /* background: var(--color-background); */
  }
  
  .circles {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    perspective: 1000;
  }
  
  .circle {
    position: relative;
    width: 1px;
    height: 1px;
    margin: calc( var(--radius) / 2 );
    border-radius: 50%;
    backface-visibility: hidden;
    perspective: 1000;
    opacity: .9;
  }
  
  .circle.design {
    background: var(--color-design);
    box-shadow: 0 0 var(--radius) var(--radius) var(--color-design);
   
  }
  
  .circle.research {
    background: var(--color-research);
    box-shadow: 0 0 var(--radius) var(--radius) var(--color-research);
 
  }
  

  @media (max-width: 640px) {
    
    :root {
      --radius: 125px;
    }
    
    .overlay {
      background-size: 50%;
    }
  
  }
  
  .overlay {
    background-image: url()
  }
 
  .afterformsub{

  }
  .aftersubbutton {
    background-color: black;
    color: white;
    width: 15rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* Add this to make it look clickable */
  }
  
  .afterformsub {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Use 100vh to make it cover the entire viewport height */
  }
  