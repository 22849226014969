/* body {
    margin: 0;
    padding: 0;
  }
   */
  button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  img {
    display: block;
    width: 100%;
  }
  .abt-01{
    width: 100%;
    overflow: hidden;
  }
  /* section {
    width: 100%;
    overflow: hidden;
  }
   */
  article {
    display: flex;
    width: 200%;
    animation: bannermove 20s linear infinite;
  }
  
  article.paused {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  

  
  .abt-01 ul {
    display: flex;
    /* background: black; */
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
  
  
  .aboutheic ul li{
    list-style: none;
    /* border: 1px solid; */
  }
 
  
  @keyframes bannermove {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
 
  
  .aboutheic img:hover{
    opacity: 1;
    /* background-color: red; */
  }
  .aboutheic img{
    margin-top: 3rem;
    margin-bottom: -3rem;
    /* padding-top: 2rem; */
  }
  @media screen and (min-width: 770px) {
    .aboutheic img{
      width: 400px;
      opacity: 0.7;
      cursor: pointer;
      height: 350px;
      /* background-color: gainsboro; */
    }
  }

  