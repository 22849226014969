

@media(max-width: 47.99em) {
    .ScaleElement_wrapper__EsFga {
        padding:16vw 4vw;
        
    }
}

@media(min-width: 48em) {
    .ScaleElement_wrapper__EsFga {
        padding:8.25vw 0;
    }
}

@media(max-width: 47.99em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_titleWrapper__KkyZl {
        width:88vw;
        margin-bottom: 32vw
    }
}

@media(min-width: 48em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_titleWrapper__KkyZl {
        width:50.9375vw;
        margin-left: 3.125vw;
        margin-bottom: 9.375vw
    }
}

.ScaleElement_wrapper__EsFga .ScaleElement_titleWrapper__KkyZl .ScaleElement_title__IbSz1 {
    /* font-family: Borna */
}

@media(max-width: 47.99em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_titleWrapper__KkyZl .ScaleElement_title__IbSz1 {
        font-size:11.7333333333vw;
        letter-spacing: -.05em;
        line-height: 90%;
        font-weight: 400;
        margin-top: 5rem;
        
    }
}

@media(min-width: 48em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_titleWrapper__KkyZl .ScaleElement_title__IbSz1 {
        font-size:2.7291666667vw;
        letter-spacing: -.05em;
        line-height: 90%;
        font-weight: 400;
        
    }
}

.ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb {
    display: flex;
    width: 100%
}

@media(max-width: 47.99em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb {
        flex-direction:column
    }
}

@media(min-width: 48em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb {
        flex-direction:row;
        height: 33.5416666667vw;
        gap: 3.125vw
    }
}

.ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImageWrapper__iexTS,.ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImageWrapper__0Op_G {
    display: flex;
    transition: width 1.46s cubic-bezier(.58,0,.01,1),height 1.46s cubic-bezier(.58,0,.01,1)
}

@media(max-width: 47.99em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImageWrapper__iexTS,.ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImageWrapper__0Op_G {
        width:100%
    }
}

@media(min-width: 48em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImageWrapper__iexTS,.ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImageWrapper__0Op_G {
        width:50%
    }
}

.ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_caption__ofkVO {
    /* font-family: Borna; */
    transition: opacity 1.46s cubic-bezier(.58,0,.01,1)
}

@media(max-width: 47.99em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_caption__ofkVO {
        font-size:5.3333333333vw;
        letter-spacing: 0;
        line-height: 120%;
        font-weight: 400
    }
}

@media(min-width: 48em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_caption__ofkVO {
        font-size:1.6666666667vw;
        letter-spacing: 0;
        line-height: 120%;
        font-weight: 400
    }
}

@media(max-width: 47.99em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_caption__ofkVO {
        margin-top:8vw
    }
}

@media(min-width: 48em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_caption__ofkVO {
        margin-top:1.5625vw
    }
}

@media(max-width: 47.99em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImageWrapper__iexTS {
        align-items:flex-end;
        margin-bottom: 16vw;
        flex-direction: column
    }
}

@media(min-width: 48em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImageWrapper__iexTS {
        justify-content:flex-end
    }

    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImageWrapper__iexTS .ScaleElement_caption__ofkVO {
        float: left
    }

    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImageWrapper__iexTS:hover .ScaleElement_firstImage__USLLE {
        width: 48.4375vw;
        height: 48.4375vw
    }

    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImageWrapper__iexTS:hover .ScaleElement_firstImage__USLLE .ScaleElement_caption__ofkVO {
        opacity: 1
    }

    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImageWrapper__iexTS:hover~.ScaleElement_secondImageWrapper__0Op_G .ScaleElement_secondImage__qvRKd {
        width: 23.4375vw;
        height: 23.4375vw
    }

    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImageWrapper__iexTS:hover~.ScaleElement_secondImageWrapper__0Op_G .ScaleElement_secondImage__qvRKd .ScaleElement_caption__ofkVO {
        opacity: 0
    }
}

@media(max-width: 47.99em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImageWrapper__0Op_G {
        flex-direction:column
    }
}

@media(min-width: 48em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImageWrapper__0Op_G {
        justify-content:flex-start
    }

    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImageWrapper__0Op_G:hover .ScaleElement_secondImage__qvRKd {
        width: 48.4375vw;
        height: 48.4375vw
    }

    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImageWrapper__0Op_G:hover .ScaleElement_secondImage__qvRKd .ScaleElement_caption__ofkVO {
        opacity: 1
    }

    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImageWrapper__0Op_G:hover~.ScaleElement_firstImageWrapper__iexTS .ScaleElement_firstImage__USLLE {
        width: 23.4375vw;
        height: 23.4375vw
    }

    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImageWrapper__0Op_G:hover~.ScaleElement_firstImageWrapper__iexTS .ScaleElement_firstImage__USLLE .ScaleElement_caption__ofkVO {
        opacity: 0
    }
}

@media(max-width: 47.99em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImage__USLLE img {
        width:44.2666666667vw;
        height: 44.2666666667vw;
        object-fit: cover;
        transition: width 1.46s cubic-bezier(.58,0,.01,1),height 1.46s cubic-bezier(.58,0,.01,1),opacity 1.46s cubic-bezier(.58,0,.01,1);
        border-radius: 7px;
    }
}

@media(min-width: 48em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImage__USLLE {
        transition:width 1.46s cubic-bezier(.58,0,.01,1),height 1.46s cubic-bezier(.58,0,.01,1),opacity 1.46s cubic-bezier(.58,0,.01,1);
        width: 23.4375vw;
        height: 23.4375vw
    }

    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImage__USLLE .ScaleElement_caption__ofkVO {
        opacity: 0
    }

    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_firstImage__USLLE img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 7px;
    }
}

.ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImage__qvRKd {
    position: relative;
    transition: width 1.46s cubic-bezier(.58,0,.01,1),height 1.46s cubic-bezier(.58,0,.01,1),opacity 1.46s cubic-bezier(.58,0,.01,1)
}

@media(max-width: 47.99em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImage__qvRKd {
        width:92vw;
        /* height: 94.9333333333vw */
    }
}

@media(min-width: 48em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImage__qvRKd {
        width:48.4375vw;
        /* height: 48.4375vw */
    }
}

.ScaleElement_wrapper__EsFga .ScaleElement_images__dCVcb .ScaleElement_secondImage__qvRKd img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
}

@media(max-width: 47.99em) {
    .ScaleElement_wrapper__EsFga .ScaleElement_animate__szW0K img {
        width:92vw!important;
        height: 94.9333333333vw!important;
        object-fit: cover;
        transition: width 1.46s cubic-bezier(.58,0,.01,1),height 1.46s cubic-bezier(.58,0,.01,1),opacity 1.46s cubic-bezier(.58,0,.01,1)
    }
}

.ht-mlando1{
    margin-top: 5rem;
    
}