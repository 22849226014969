.spinner_spinner__NwsMu {
  display: block;
  position: relative;
  width: 2rem;
  height: 2rem;
  font-size: 0.4rem;
  pointer-events: none;
}
@-webkit-keyframes spinner_load8__R3w8S {
  0% {
    -webkit-transform: rotate(0deg) translateZ(0);
    transform: rotate(0deg) translateZ(0);
  }
  to {
    -webkit-transform: rotate(1turn) translateZ(0);
    transform: rotate(1turn) translateZ(0);
  }
}
@keyframes spinner_load8__R3w8S {
  0% {
    -webkit-transform: rotate(0deg) translateZ(0);
    transform: rotate(0deg) translateZ(0);
  }
  to {
    -webkit-transform: rotate(1turn) translateZ(0);
    transform: rotate(1turn) translateZ(0);
  }
}
.spinner_spinner__NwsMu:after,
.spinner_spinner__NwsMu:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  text-indent: -9999em;
}
.spinner_spinner__NwsMu:before {
  opacity: 0.2;
  border: 0.4em solid;
}
.spinner_spinner__NwsMu:after {
  border: 0.4em solid transparent;
  border-left-color: currentcolor;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spinner_load8__R3w8S 0.7s linear infinite;
  animation: spinner_load8__R3w8S 0.7s linear infinite;
}
.button_btn__EuPxb {
  display: inline-flex;
  position: relative;
  z-index: 0;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  /* font-family: NeueHaasGroteskDisplay, Helvetica Neue, helvetica, arial,
    sans-serif; */
  color: inherit;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  /* white-space: nowrap; */
  text-decoration: none;
  text-transform: inherit;
  -webkit-appearance: none;
  appearance: none;
}
.button_btn__EuPxb:disabled {
  pointer-events: none;
}
.button_btn__EuPxb .button_spinner__BybWR {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate3d(-50%, calc(-50% + 4rem), 0);
  transform: translate3d(-50%, calc(-50% + 4rem), 0);
}
.button_btn__EuPxb .button_children__CAXMx,
.button_btn__EuPxb .button_spinner__BybWR {
  transition: border 1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
    opacity 0.9s cubic-bezier(0.77, 0, 0.175, 1),
    -webkit-transform 0.9s cubic-bezier(0.77, 0, 0.175, 1);
  transition: border 1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
    transform 0.9s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.9s cubic-bezier(0.77, 0, 0.175, 1);
  transition: border 1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
    transform 0.9s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.9s cubic-bezier(0.77, 0, 0.175, 1),
    -webkit-transform 0.9s cubic-bezier(0.77, 0, 0.175, 1);
}
.button_btn__EuPxb.button_hasLoading___jJLT {
  overflow: hidden;
  overflow: clip;
}
.button_btn__EuPxb.button_underlined__5j51U {
  display: inline-block;
  padding: 0.3em 0;
}
@media (hover: hover) {
  .button_btn__EuPxb.button_underlined__5j51U {
    padding: 0.1em 0;
  }
  .button_btn__EuPxb.button_underlined__5j51U:hover:after {
    -webkit-transform: scaleX(1) translateZ(0);
    transform: scaleX(1) translateZ(0);
    transition-timing-function: cubic-bezier(0.16, 1.08, 0.38, 0.98);
  }
}
.button_btn__EuPxb.button_underlined__5j51U:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: currentcolor;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left;
  transform-origin: left;
  transition: -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  pointer-events: none;
}
.button_btn__EuPxb.button_circle__0x73m,
.button_btn__EuPxb.button_rounded__Aad7d {
  --background: black;
  --color: #262626;
  position: relative;
  overflow: hidden;
  overflow: clip;
  background: var(--background);
  /* color: var(--color); */
  color: white;
  transition: all 0.6s cubic-bezier(0.26, 1.04, 0.54, 1);
  transition-property: border, color;
}
.button_btn__EuPxb.button_circle__0x73m:before,
.button_btn__EuPxb.button_rounded__Aad7d:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  opacity: 0;
  background: var(--color);
  transition: opacity 1s cubic-bezier(0.26, 1.04, 0.54, 1);
}
.button_btn__EuPxb.button_circle__0x73m svg,
.button_btn__EuPxb.button_rounded__Aad7d svg {
  position: relative;
  z-index: 1;
}
.button_btn__EuPxb.button_circle__0x73m:active,
.button_btn__EuPxb.button_rounded__Aad7d:active {
  color: #fff;
  transition-duration: 0s;
}
.button_btn__EuPxb.button_circle__0x73m:active:before,
.button_btn__EuPxb.button_rounded__Aad7d:active:before {
  opacity: 1;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}
@media (hover: hover) {
  .button_btn__EuPxb.button_circle__0x73m:hover,
  .button_btn__EuPxb.button_rounded__Aad7d:hover {
    /* background: transparent; */
    color: var(--background);
    transition-duration: 0.2s;
  }
  .button_btn__EuPxb.button_circle__0x73m:hover:before,
  .button_btn__EuPxb.button_rounded__Aad7d:hover:before {
    opacity: 1;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    transition: opacity 0.1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
      -webkit-transform 0.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
    transition: opacity 0.1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
      transform 0.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
    transition: opacity 0.1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
      transform 0.2s cubic-bezier(0.16, 1.08, 0.38, 0.98),
      -webkit-transform 0.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  }
}
.button_btn__EuPxb.button_circle__0x73m {
  width: clamp(36px, 4rem, 48px);
  height: clamp(36px, 4rem, 48px);
  border-radius: 50%;
  transition: all 0.6s cubic-bezier(0.26, 1.04, 0.54, 1);
  transition-property: color, background;
}
@media (min-width: 1024px) {
  .button_btn__EuPxb.button_circle__0x73m {
    width: clamp(45px, 5rem, 60px);
    height: clamp(45px, 5rem, 60px);
  }
}
.button_btn__EuPxb.button_circle__0x73m svg {
  transition: opacity 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
}
.button_btn__EuPxb.button_circle__0x73m:disabled svg {
  opacity: 0.2;
}
.button_btn__EuPxb.button_rounded__Aad7d {
  height: clamp(36px, 4rem, 48px);
  padding: 0.2em clamp(18px, 2rem, 24px) 0;
  border-radius: clamp(36px, 4rem, 48px);
}
@media (min-width: 1024px) {
  .button_btn__EuPxb.button_rounded__Aad7d {
    height: clamp(45px, 5rem, 60px);
    padding: 0.2em clamp(22.5px, 2.5rem, 30px) 0;
    border-radius: clamp(45px, 5rem, 60px);
    font-size: clamp(10.8px, 1.2rem, 14.4px);
  }
}
.button_btn__EuPxb.button_rounded__Aad7d svg {
  margin: -0.1em 0 0 clamp(9px, 1rem, 12px);
  transition: -webkit-transform 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  transition: transform 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  transition: transform 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98),
    -webkit-transform 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
}
@media (min-width: 1024px) {
  .button_btn__EuPxb.button_rounded__Aad7d svg {
    width: clamp(9.9px, 1.1rem, 13.2px);
    height: clamp(9px, 1rem, 12px);
  }
}
.button_btn__EuPxb.button_rounded__Aad7d:disabled svg {
  opacity: 0.2;
}
@media (hover: hover) {
  .button_btn__EuPxb.button_rounded__Aad7d:hover svg {
    -webkit-transform: translate3d(0.4rem, 0, 0);
    transform: translate3d(0.4rem, 0, 0);
  }
}
.button_btn__EuPxb.button_rounded__Aad7d:active svg {
  -webkit-transform: translate3d(0.4rem, 0, 0);
  transform: translate3d(0.4rem, 0, 0);
}
.button_btn__EuPxb.button_inverted__V4YPC {
  background: var(--color);
  color: var(--background);
}
.button_btn__EuPxb.button_inverted__V4YPC:before {
  /* background: var(--gradient, var(--hover-background-color), var(--color)); */
  transition: opacity 3.5s cubic-bezier(0.26, 1.04, 0.54, 1),
    -webkit-transform 4s cubic-bezier(0.26, 1.04, 0.54, 1);
  transition: opacity 3.5s cubic-bezier(0.26, 1.04, 0.54, 1),
    transform 4s cubic-bezier(0.26, 1.04, 0.54, 1);
  transition: opacity 3.5s cubic-bezier(0.26, 1.04, 0.54, 1),
    transform 4s cubic-bezier(0.26, 1.04, 0.54, 1),
    -webkit-transform 4s cubic-bezier(0.26, 1.04, 0.54, 1);
}
@media (hover: hover) {
  .button_btn__EuPxb.button_inverted__V4YPC:hover {
    background: var(--color);
  }
}
.button_btn__EuPxb.button_inverted__V4YPC:active {
  background: var(--color);
}
.nav_nav__eVb5m {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 3rem;
  left: 0;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 2.1rem;
  color: #0e0e0e;
  transition: opacity 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98), visibility 0.5s;
  pointer-events: none;
  will-change: opacity;
}
@media (min-width: 600px) and (max-width: 1023px) {
  .nav_nav__eVb5m {
    padding: 0 3.36rem;
  }
}
@media (min-width: 1024px) {
  .nav_nav__eVb5m {
    top: 4rem;
    padding: 0 6rem;
  }
}
.nav_nav__eVb5m.nav_isHidden__V_n_K {
  opacity: 0;
}
.nav_nav__eVb5m.nav_isHidden__V_n_K .nav_element__qZw4E {
  pointer-events: none;
}
.nav_element__qZw4E {
  -webkit-transform: translate3d(0, -7.5rem, 0);
  transform: translate3d(0, -7.5rem, 0);
  transition: -webkit-transform 1.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  transition: transform 1.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  transition: transform 1.2s cubic-bezier(0.16, 1.08, 0.38, 0.98),
    -webkit-transform 1.2s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  transition-delay: var(--delay-mobile);
  pointer-events: all;
}
@media (min-width: 1024px) {
  .nav_element__qZw4E {
    -webkit-transform: translate3d(0, -9.5rem, 0);
    transform: translate3d(0, -9.5rem, 0);
    transition-delay: var(--delay);
  }
  .nav_link__Eyu1_ {
    margin-right: 4rem;
  }
  .nav_link__Eyu1_:last-child {
    margin-right: 0;
  }
  .nav_cta___ySE0 {
    margin-left: 6rem;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  .nav_left__EDL6H {
    display: flex;
    align-items: center;
  }
}
.nav_right__ps6E0 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.nav_logo__iUEYR {
  display: block;
  position: relative;
  z-index: 3;
  width: 10.2rem;
  height: 2rem;
  transition: color 0.6s cubic-bezier(0.26, 1.04, 0.54, 1);
  pointer-events: all;
}
@media (min-width: 1024px) {
  .nav_logo__iUEYR {
    width: 12.8rem;
    height: 2.5rem;
  }
}
.nav_logo__iUEYR svg {
  width: 100%;
  height: 100%;
  height: auto;
}
.nav_burger__lNtRg {
  position: relative;
  flex-direction: column;
  justify-content: center;
  pointer-events: all;
}
.nav_burger__lNtRg .nav_close__mLKcz {
  position: absolute !important;
  top: 50%;
  left: 50%;
  width: 0.8rem !important;
  height: 0.8rem !important;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: opacity 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
.nav_burger__lNtRg .nav_line__ZivxI {
  width: 1.6rem;
  height: 1px;
  margin-bottom: 2px;
  background-color: currentcolor;
  border-radius: 1px;
  transition: opacity 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98) 0.1s;
}
.nav_burger__lNtRg .nav_line__ZivxI:last-child {
  margin-bottom: 0;
}
.nav_burger__lNtRg.nav_isOpened__eYL8i .nav_close__mLKcz {
  opacity: 1;
  transition-delay: 0.25s;
}
.nav_burger__lNtRg.nav_isOpened__eYL8i .nav_line__ZivxI {
  opacity: 0;
  transition-delay: 0.15s;
}
.nav_light__ty_Ep {
  color: #fff;
}
.nav_light__ty_Ep .nav_burger__lNtRg {
  background: #fff;
  color: #000;
}
.image_imageWrapper__G8xW8 {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.26, 1.04, 0.54, 1);
}
.image_image__lootM {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .image_isPriority__ZFJDO {
    opacity: 1;
  }
}
.navPanel_navPanel__7WkJj {
  position: fixed;
  z-index: 1001;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background: #000;
  color: #fff;
  transition: visibility 1s,
    -webkit-transform 1s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1), visibility 1s;
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1), visibility 1s,
    -webkit-transform 1s cubic-bezier(0.77, 0, 0.175, 1);
}
.navPanel_navPanel__7WkJj .navPanel_item__DYvoM {
  padding: 1.5rem 0;
  border-top: 1px solid hsla(0, 0%, 100%, 0.15);
}
.navPanel_scrollWrapper__wtBWb {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.navPanel_wrapper__tIHNH {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100% - 7rem);
  padding: 10rem 5.55vw 4rem;
}
.navPanel_ctas__byqp_ {
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: space-between;
  margin-bottom: 2.1rem;
}
.navPanel_cta__lPjd7 {
  padding-right: 1.7rem !important;
  padding-left: 1.7rem !important;
}
.navPanel_background__Zfs9l {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.header_header__3o0p0 {
  position: fixed;
  z-index: 1000;
  right: 0;
  left: 0;
}
.SmoothScroll_container__L1r_8 {
  width: 100%;
}
.SmoothScroll_size__CSf_l {
  width: 1px;
  display: none;
}
.input_wrapper__NP9x7 {
  position: relative;
}
.input_input__JVjrL {
  width: 100%;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  transition: color 5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
}
.input_input__JVjrL:focus {
  outline: none;
}
.input_input__JVjrL::-webkit-input-placeholder {
  vertical-align: middle;
  opacity: 0.2;
  color: currentColor;
}
.input_input__JVjrL::placeholder {
  vertical-align: middle;
  opacity: 0.2;
  color: currentColor;
}
.input_input__JVjrL::-webkit-inner-spin-button,
.input_input__JVjrL::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.input_regular__JTDF4 .input_input__JVjrL {
  height: 4rem;
  padding: 0;
  background-color: transparent;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
  font-weight: 400;
  line-height: 1.8;
  transition: color 1s cubic-bezier(0.16, 1.08, 0.38, 0.98),
    border-color 1s cubic-bezier(0.16, 1.08, 0.38, 0.98);
}
.input_regular__JTDF4 .input_input__JVjrL:focus {
  border-color: #fff;
}
.input_regular__JTDF4 .input_input__JVjrL:not(:placeholder-shown) {
  border-color: #fff;
}
.input_required__0NfQx {
  position: absolute;
  top: 50%;
  right: 2rem;
  margin-top: -0.6em;
  color: #fff;
  transition: color 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  pointer-events: none;
}
.input_errorMessage__u1XlM {
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  opacity: 0;
  visibility: hidden;
  color: #fe3514;
  transition: opacity 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98), visibility 0.5s;
}
@media (min-width: 1024px) {
  .input_errorMessage__u1XlM {
    margin-top: 1rem;
  }
}
.checkbox_checkbox__5rU5l {
  position: relative;
  color: var(--color-mid, #9c9c9c);
  text-align: left;
}
.checkbox_box__H7hXn {
  display: inline-flex;
  position: relative;
  width: 16px;
  height: 16px;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  margin-top: 0.3rem;
  margin-right: 0.8rem;
  border: 1px solid;
  border-radius: 1px;
  transition: border 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
}
.checkbox_box__H7hXn i {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  background: currentcolor;
  border-radius: 50%;
}
.checkbox_box__H7hXn i {
  opacity: 0;
  color: currentcolor;
  -webkit-transform: translate(-50%, -50%) scale(0.3);
  transform: translate(-50%, -50%) scale(0.3);
  transition: opacity 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98),
    -webkit-transform 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  transition: opacity 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98),
    transform 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  transition: opacity 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98),
    transform 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98),
    -webkit-transform 0.3s cubic-bezier(0.16, 1.08, 0.38, 0.98);
}
.checkbox_label__v2pFv {
  display: inline-flex;
  align-items: flex-start;
  /* font-family: NeueHaasGroteskDisplay, Helvetica Neue, helvetica, arial,
    sans-serif; */
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: color 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  white-space: pre-wrap;
}
.checkbox_label__v2pFv a {
  font-size: inherit;
  color: var(--color, #fff) !important;
  text-decoration: underline;
}
.checkbox_input__PzERz {
  position: absolute;
  top: 50%;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
.checkbox_input__PzERz:disabled + .checkbox_label__v2pFv {
  opacity: 0.6;
}
.checkbox_input__PzERz:focus + .checkbox_label__v2pFv .checkbox_box__H7hXn {
  outline: 1px solid currentcolor;
}
.checkbox_input__PzERz:checked + .checkbox_label__v2pFv .checkbox_box__H7hXn i {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1) translateZ(0);
  transform: translate(-50%, -50%) scale(1) translateZ(0);
}
.form_form__2cZM3 {
  position: relative;
}
@media (min-width: 1024px) {
  .form_form__2cZM3 {
    width: 64.5067873303rem;
  }
}
.form_form__2cZM3 > * {
  margin-bottom: 1.5rem;
}
.form_form__2cZM3 > :last-child {
  margin-bottom: 0;
}
.form_formError__Fu4C6,
.form_formSuccess__i1I10 {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
}
.form_formError__Fu4C6 {
  color: #fe3514;
}
.customPortableText_a___Y_Ak {
  transition: color 0.5s cubic-bezier(0.26, 1.04, 0.54, 1);
  text-decoration: underline;
}
@media (hover: hover) {
  .customPortableText_a___Y_Ak:hover {
    color: var(--color);
  }
}
.customPortableText_a___Y_Ak:active {
  color: var(--color);
}
.FormNewsletter_formNewsletter__WJtsZ {
  margin: 6rem 0;
}
@media (min-width: 1024px) {
  .FormNewsletter_formNewsletter__WJtsZ {
    margin: 12rem 0;
  }
}
.FormNewsletter_title___vdsi {
  margin-bottom: 4rem;
}
@media (min-width: 1024px) {
  .FormNewsletter_title___vdsi {
    margin-bottom: 6rem;
  }
}
.FormNewsletter_inputWrapper__cD1Sg {
  position: relative;
}
.FormNewsletter_input__s0COe {
  width: 100%;
  height: 5rem !important;
  padding-right: 5rem !important;
  font-size: 3rem !important;
  color: #fff;
}
@media (min-width: 1024px) {
  .FormNewsletter_input__s0COe {
    height: 9.2rem !important;
    padding-top: 3.2rem !important;
    padding-right: 5rem !important;
    padding-bottom: 2.2rem !important;
  }
}
.FormNewsletter_input__s0COe::-webkit-input-placeholder {
  /* font-family: Hellix, helvetica, arial, sans-serif; */
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: -0.04em;
}
.FormNewsletter_input__s0COe::placeholder {
  /* font-family: Hellix, helvetica, arial, sans-serif; */
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: -0.04em;
}
@media (min-width: 1024px) {
  .FormNewsletter_input__s0COe::-webkit-input-placeholder {
    font-size: 8rem;
  }
  .FormNewsletter_input__s0COe::placeholder {
    font-size: 8rem;
  }
}
.FormNewsletter_cta__pJrWz {
  position: absolute !important;
  top: 50%;
  right: 0;
  flex-shrink: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.FormNewsletter_optin__7EvmZ {
  margin-top: 3rem;
}
.FormNewsletter_error___Z9nA {
  position: absolute;
  right: 0;
  bottom: 1rem;
  left: auto;
  width: auto;
}
@media (min-width: 1024px) {
  .FormNewsletter_error___Z9nA {
    bottom: 0.5rem;
  }
}
.footer_footer__ZK5Q_ {
  position: relative;
  width: 100%;
  /* background: #000; */
  /* border-top: 1px solid hsla(0, 0%, 100%, 0.15); */
  color: #000;
  margin-top: 2rem;
}
.footer_footerWrapper__8ZwIU {
  /* padding: 6rem 0 4rem; */
  /* border-top: 1px solid hsla(0, 0%, 100%, 0.15); */
}
/* @media (min-width: 1024px) {
  .footer_footerWrapper__8ZwIU {
    padding: 10rem 0 2rem;
  }
} */
.footer_top__Upalj {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer_logo__bA1ZD {
  width: 8.2rem;
  height: 1.6rem;
}
@media (min-width: 1024px) {
  .footer_logo__bA1ZD {
    width: 12.8rem;
    height: 2rem;
  }
}
.footer_list__LZhug {
  width: 44.4vw;
}
@media (min-width: 1024px) {
  .footer_list__LZhug {
    width: 30.7601809955rem;
    margin-left: 2.9864253394rem;
  }
  .footer_list__LZhug:last-child {
    width: 19.6113122172rem;
  }
}
.footer_title__UAn4g {
  margin-bottom: 2rem;
}
.footer_address__kPUn_ {
  display: block;
  margin-bottom: 2rem;
}
.footer_nav__XNGc9 {
  display: flex;
  line-height: 1.1 !important;
}
@media (min-width: 1024px) {
  .footer_nav__XNGc9 {
    margin-left: auto;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  .footer_socialLinks__6g32K {
    flex: 0 0 16.65vw;
    padding-right: 0;
  }
}
.footer_bottom__BZmGy {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4rem;
  color: #fff;
}
@media (min-width: 1024px) {
  .footer_bottom__BZmGy {
    justify-content: flex-start;
    margin-top: 6rem;
    padding-bottom: 0.4rem;
  }
}
.footer_bottom__BZmGy .footer_link__j_gJ3 {
  transition: color 0.5s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  text-decoration: underline;
}
@media (hover: hover) {
  .footer_bottom__BZmGy .footer_link__j_gJ3:hover {
    color: #fff;
    transition-duration: 0s;
  }
}
.footer_bottom__BZmGy .footer_link__j_gJ3:active {
  color: #fff;
  transition-duration: 0s;
}
.footer_ctas__RyKKY {
  margin-right: auto;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .footer_ctas__RyKKY {
    margin-bottom: 5rem;
  }
  .footer_cta__LC3m2 {
    width: 100%;
    margin-bottom: 1rem;
  }
}
@media (min-width: 1024px) {
  .footer_cta__LC3m2 {
    margin-right: 1rem;
  }
}
.footer_legalsWrapper__cIBFX {
  color: #9c9c9c;
}
@media (min-width: 1024px) {
  .footer_legalsWrapper__cIBFX {
    width: 30.7601809955rem;
  }
}
.footer_credits__0Tj7b {
  color: #9c9c9c;
}
@media (min-width: 1024px) {
  .footer_credits__0Tj7b {
    width: 19.5113122172rem;
    margin-left: 2.9864253394rem;
  }
}
.layout_transition__K5Kvb {
  display: flex;
  flex-direction: column;
  min-height: var(--vh);
}
a,
body,
div,
footer,
form,
h1,
h2,
h3,
header,
html,
i,
img,
label,
li,
nav,
ol,
p,
section,
span,
ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
footer,
header,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
*,
:after,
:before {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.container {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  width: 88.8vw;
}
@media (min-width: 600px) and (max-width: 1023px) {
  .container {
    width: 88.8vw;
  }
}
@media (min-width: 1024px) {
  .container {
    width: 132rem;
  }
}
html {
  --sbw: 0px;
  font-size: 2.6666666667vw;
  font-size: calc(2.6666666667vw - var(--sbw) / 37.5);
}
@media (min-width: 0px) and (max-width: 1023px) {
  html {
    scroll-behavior: smooth;
  }
}
@media (min-width: 600px) and (max-width: 1023px) {
  html {
    font-size: 1.6666666667vw;
    font-size: calc(1.6666666667vw - var(--sbw) / 60);
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 0.6944444444vw;
    font-size: calc(0.6944444444vw - var(--sbw) / 144);
    font-size: min(calc(0.6944444444vw - var(--sbw) / 144), 13.3333333333px);
  }
}
body {
  overflow-x: hidden;
  overflow-y: scroll;
  background: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media (min-width: 1024px) {
  .mobile-only {
    display: none !important;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  .desktop-only {
    display: none !important;
  }
}
.hd-140,
.hd-30,
.hd-80,
.hm-24,
.hm-36,
.hm-50,
.hm-70 {
  /* font-family: Hellix, helvetica, arial, sans-serif; */
  line-height: 0.9;
  white-space: pre-wrap;
  letter-spacing: -0.04em;
}
.hm-70 {
  font-size: 7rem;
  line-height: 0.77;
}
.hm-50 {
  font-size: 5rem;
}
.hm-36 {
  font-size: 3.6rem;
}
.hm-24 {
  font-size: 2.4rem;
}
.hd-140 {
  line-height: 0.8;
}
@media (min-width: 1024px) {
  .hd-140 {
    font-size: 14rem;
  }
}
.hd-80 {
  line-height: 0.8;
}
@media (min-width: 1024px) {
  .hd-80 {
    font-size: 2rem;
  }
  .hd-30 {
    font-size: 3rem;
  }
}
.pd-20,
.pm-14,
.pm-16 {
  /* font-family: NeueHaasGroteskDisplay, Helvetica Neue, helvetica, arial,
    sans-serif; */
  line-height: 1.4;
  white-space: pre-wrap;
}
.pm-16 {
  font-size: clamp(14.4px, 1.6rem, 19.2px);
}
.pm-14 {
  font-size: clamp(12.6px, 1.4rem, 16.8px);
}
@media (min-width: 1024px) {
  .pd-20 {
    font-size: clamp(16.2px, 1.8rem, 21.6px);
  }
}
.white {
  color: #fff;
}
.grey {
  color: #9c9c9c;
}
.label {
  text-transform: uppercase;
}
.label {
  /* font-family: NeueHaasGroteskDisplay, Helvetica Neue, helvetica, arial,
    sans-serif; */
  font-size: clamp(10.8px, 1.2rem, 14.4px);
  font-weight: 500;
  letter-spacing: 0.05em;
}
a {
  text-decoration: none;
}
a,
a:visited {
  /* color: currentcolor; */
}
.slice_slice__sdE0w {
  display: block;
  margin: -1px 0;
  background: var(--background);
  color: var(--color);
}
.videoLoop_videoLoop__oVvSy {
  position: relative;
}
.videoLoop_posterWrapper__fxkSF {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.videoLoop_poster__KrWTB {
  position: relative;
  width: 100%;
  height: 100%;
}
.AnimatedText_wrapper__h46D1 {
  display: inline-block;
  overflow: hidden;
  overflow: clip;
  margin: -0.2em;
  padding: 0.2em;
}
.AnimatedText_wrapper__h46D1 span {
  display: inline-block;
  margin: -0.2em;
  padding: 0.2em;
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
}
@media (min-width: 0px) and (max-width: 1023px) {
  .AnimatedText_wrapper__h46D1 span {
    -webkit-transform: none !important;
    transform: none !important;
  }
}
.hero_hero__NbuMp {
  display: flex;
  position: relative;
  height: 88vh;
  overflow: hidden;
  overflow: clip;
  margin: 0 auto;
  padding-top: 10rem;
  padding-bottom: 2rem;
  background: #000;
}
@media (min-width: 1024px) {
  .hero_hero__NbuMp {
    height: 100vh;
    align-items: flex-end;
    padding-bottom: 7rem;
  }
}
.hero_content__JVx4u {
  position: relative;
  z-index: 1;
}
@media (min-width: 1024px) {
  .hero_content__JVx4u {
    width: 64.5067873303rem;
    margin-left: 44.9954751131rem;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  .hero_content__JVx4u br {
    display: none;
  }
}
.hero_subtitle__Sjne9 {
  margin-top: 3rem;
}
@media (min-width: 1024px) {
  .hero_subtitle__Sjne9 {
    width: 64.5067873303rem;
    margin-top: 4rem;
    opacity: 0;
    transition: opacity 2s cubic-bezier(0.26, 1.04, 0.54, 1) 0.3s;
  }
}
.hero_cta__4GZwJ {
  margin-top: 3rem;
}
@media (min-width: 1024px) {
  .hero_cta__4GZwJ {
    margin-top: 4rem;
    opacity: 0;
    transition: opacity 2s cubic-bezier(0.26, 1.04, 0.54, 1) 0.4s;
  }
}
.hero_background__TgRvX {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  overflow: clip;
}
@media (min-width: 1024px) {
  .hero_background__TgRvX {
    top: 0;
  }
}
.hero_videoLoop__4fbLE {
  width: 100%;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .hero_videoLoop__4fbLE {
    width: 160%;
    -webkit-transform: scale(-1);
    transform: scale(-1);
  }
}
@media (min-width: 1024px) {
  .hero_videoLoop__4fbLE {
    height: 100%;
  }
}
.hero_isMirroredX__VTkit {
  align-items: flex-end;
}
@media (min-width: 1024px) {
  .hero_isMirroredX__VTkit .hero_content__JVx4u {
    width: 100%;
    margin-left: 0;
  }
}
.hero_isMirroredX__VTkit .hero_background__TgRvX {
  top: 0;
}
.hero_isMirroredX__VTkit .hero_videoLoop__4fbLE {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.hero_ctaScroll__OBXDN {
  position: absolute !important;
  z-index: 2;
  right: 2.1rem;
  bottom: 2rem;
}
@media (min-width: 600px) and (max-width: 1023px) {
  .hero_ctaScroll__OBXDN {
    right: 3.36rem;
  }
}
@media (min-width: 1024px) {
  .hero_ctaScroll__OBXDN {
    right: 6rem;
    bottom: 7rem;
    opacity: 0;
    transition: opacity 2s cubic-bezier(0.26, 1.04, 0.54, 1) 0.5s;
  }
}
.hero_ctaScroll__OBXDN svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.lottie_lottie__mmQqj {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.26, 1.04, 0.54, 1);
  transition-property: opacity;
}
.dots_dots__nGOi0 {
  display: block;
  position: absolute;
  bottom: 100%;
  margin-bottom: 2rem;
  line-height: 0;
}
.dots_dots__nGOi0:after,
.dots_dots__nGOi0:before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: #fff;
  border-radius: 50%;
}
.dots_dots__nGOi0:before {
  background: #781edc;
}
.dots_dots__nGOi0:after {
  margin-left: 0.4rem;
}
.textAnimation_textAnimation__pxWgX {
  overflow: hidden;
  overflow: clip;
  padding-bottom: 4rem;
}
@media (min-width: 1024px) {
  .textAnimation_textAnimation__pxWgX {
    padding: 8rem 0;
  }
}
.textAnimation_text__pUfgJ {
  padding: 5.8rem 0 2rem;
}
@media (min-width: 1024px) {
  .textAnimation_text__pUfgJ {
    display: flex;
    align-items: flex-start;
    padding: 9rem 0 6rem;
  }
}
.textAnimation_vertical__1CnsZ .textAnimation_text__pUfgJ {
  padding: 2rem 0;
}
@media (min-width: 1024px) {
  .textAnimation_vertical__1CnsZ .textAnimation_text__pUfgJ {
    width: 53.257918552rem;
    flex-direction: column;
    margin-left: 14.2352941176rem;
  }
  .textAnimation_verticalMirrored__sph8h .textAnimation_text__pUfgJ {
    margin: 0 11.2488687783rem 0 8.2624434389rem;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  .textAnimation_lottie__h0_i3 {
    margin-bottom: 2rem;
  }
}
.textAnimation_vertical__1CnsZ .textAnimation_lottie__h0_i3 {
  width: 77.7vw;
  height: 77.7vw;
  margin: 0 auto;
  padding: 2rem;
}
@media (min-width: 1024px) {
  .textAnimation_vertical__1CnsZ .textAnimation_lottie__h0_i3 {
    width: 53.257918552rem;
    height: 53.257918552rem;
    margin-top: 2rem;
    padding: 2.9864253394rem;
  }
  .textAnimation_content__Coh_a {
    width: 42.0090497738rem;
  }
  .textAnimation_horizontal__qZ_3o .textAnimation_content__Coh_a {
    margin-left: 14.2352941176rem;
  }
}
.textAnimation_title__OmZSo {
  position: relative;
}
@media (min-width: 1024px) {
  .textAnimation_horizontal__qZ_3o .textAnimation_title__OmZSo {
    width: 64.5067873303rem;
  }
}
.textAnimation_description__O86UP {
  display: block;
  margin-top: 3rem;
  color: var(--color-mid);
}
@media (min-width: 1024px) {
  .textAnimation_vertical__1CnsZ .textAnimation_description__O86UP {
    margin-top: 3rem;
  }
  .textAnimation_horizontal__qZ_3o .textAnimation_description__O86UP {
    margin-top: 0;
  }
}
.textAnimation_description__O86UP p {
  margin-bottom: 1em;
}
.textAnimation_description__O86UP p:last-child {
  margin-bottom: 0;
}
.textAnimation_cta__2fSHv {
  margin-top: 3rem;
}
@media (min-width: 1024px) {
  .textAnimation_cta__2fSHv {
    margin-top: 4rem;
  }
  .textAnimation_vertical__1CnsZ .textAnimation_container__4JKKL {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .textAnimation_verticalMirrored__sph8h .textAnimation_container__4JKKL {
    flex-direction: row-reverse;
  }
}
.lottie_lottie__mmQqj {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.26, 1.04, 0.54, 1);
  transition-property: opacity;
}
.dots_dots__nGOi0 {
  display: block;
  position: absolute;
  bottom: 100%;
  margin-bottom: 2rem;
  line-height: 0;
}
.dots_dots__nGOi0:after,
.dots_dots__nGOi0:before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: #fff;
  border-radius: 50%;
}
.dots_dots__nGOi0:before {
  background: #781edc;
}
.dots_dots__nGOi0:after {
  margin-left: 0.4rem;
}
.textAnimation_textAnimation__pxWgX {
  overflow: hidden;
  overflow: clip;
  padding-bottom: 4rem;
}
@media (min-width: 1024px) {
  .textAnimation_textAnimation__pxWgX {
    padding: 8rem 0;
  }
}
.textAnimation_text__pUfgJ {
  padding: 5.8rem 0 2rem;
}
@media (min-width: 1024px) {
  .textAnimation_text__pUfgJ {
    display: flex;
    align-items: flex-start;
    padding: 9rem 0 6rem;
  }
}
.textAnimation_vertical__1CnsZ .textAnimation_text__pUfgJ {
  padding: 2rem 0;
}
@media (min-width: 1024px) {
  .textAnimation_vertical__1CnsZ .textAnimation_text__pUfgJ {
    width: 53.257918552rem;
    flex-direction: column;
    margin-left: 14.2352941176rem;
  }
  .textAnimation_verticalMirrored__sph8h .textAnimation_text__pUfgJ {
    margin: 0 11.2488687783rem 0 8.2624434389rem;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  .textAnimation_lottie__h0_i3 {
    margin-bottom: 2rem;
  }
}
.textAnimation_vertical__1CnsZ .textAnimation_lottie__h0_i3 {
  width: 77.7vw;
  height: 77.7vw;
  margin: 0 auto;
  padding: 2rem;
}
@media (min-width: 1024px) {
  .textAnimation_vertical__1CnsZ .textAnimation_lottie__h0_i3 {
    width: 53.257918552rem;
    height: 53.257918552rem;
    margin-top: 2rem;
    padding: 2.9864253394rem;
  }
  .textAnimation_content__Coh_a {
    width: 42.0090497738rem;
  }
  .textAnimation_horizontal__qZ_3o .textAnimation_content__Coh_a {
    margin-left: 14.2352941176rem;
  }
}
.textAnimation_title__OmZSo {
  position: relative;
}
@media (min-width: 1024px) {
  .textAnimation_horizontal__qZ_3o .textAnimation_title__OmZSo {
    width: 64.5067873303rem;
  }
}
.textAnimation_description__O86UP {
  display: block;
  margin-top: 3rem;
  color: var(--color-mid);
}
@media (min-width: 1024px) {
  .textAnimation_vertical__1CnsZ .textAnimation_description__O86UP {
    margin-top: 3rem;
  }
  .textAnimation_horizontal__qZ_3o .textAnimation_description__O86UP {
    margin-top: 0;
  }
}
.textAnimation_description__O86UP p {
  margin-bottom: 1em;
}
.textAnimation_description__O86UP p:last-child {
  margin-bottom: 0;
}
.textAnimation_cta__2fSHv {
  margin-top: 3rem;
}
@media (min-width: 1024px) {
  .textAnimation_cta__2fSHv {
    margin-top: 4rem;
  }
  .textAnimation_vertical__1CnsZ .textAnimation_container__4JKKL {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .textAnimation_verticalMirrored__sph8h .textAnimation_container__4JKKL {
    flex-direction: row-reverse;
  }
}
.definitionList_definitions__dfmcY {
  margin-top: 4rem;
  padding-bottom: 6rem;
}
@media (min-width: 1024px) {
  .definitionList_definitions__dfmcY {
    margin-top: 12rem;
    padding-bottom: 8rem;
  }
}
.definitionList_col1__F_qO3 {
  flex-shrink: 0;
}
@media (min-width: 1024px) {
  .definitionList_col1__F_qO3 {
    width: 64.5067873303rem;
    margin-right: auto;
  }
}
.definitionList_definition__a_b8_ {
  position: relative;
  padding: 3rem 0;
}
@media (min-width: 1024px) {
  .definitionList_definition__a_b8_ {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.definitionList_definition__a_b8_:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  opacity: 0.1;
  background: var(--color);
}
.definitionList_definition__a_b8_:last-child {
  padding-bottom: 0;
}
.definitionList_contentWrapper__IqCAj {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
}
.definitionList_definitionSubtitle___UIGo {
  display: block;
  margin-top: 2rem;
  color: var(--color-mid);
}
@media (min-width: 0px) and (max-width: 1023px) {
  .definitionList_definitionCta__glBoi {
    margin-top: 2rem;
  }
}
.banner_banner__iqojY {
  display: flex;
  position: relative;
  align-items: center;
  min-height: 80vh;
  margin: 0 auto;
  padding: 2rem 0;
  background: #000;
}
@media (min-width: 1024px) {
  .banner_banner__iqojY {
    min-height: 100vh;
    padding: 6rem 0 7rem;
  }
}
.banner_content__K70EO {
  position: relative;
  z-index: 1;
}
.banner_description__c2HOj {
  margin-top: 3rem;
}
@media (min-width: 1024px) {
  .banner_description__c2HOj {
    width: 42.0090497738rem;
    margin-top: 0;
  }
}
.banner_cta__X7tTd {
  margin-top: 3rem;
}
@media (min-width: 1024px) {
  .banner_cta__X7tTd {
    margin-top: 4rem;
  }
}
.banner_background__kty8z {
  position: absolute !important;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (min-width: 1024px) {
  .banner_isLeftAligned__AQ6Q0 .banner_title__VZ4WV {
    width: 109.5022624434rem;
  }
  .banner_isLeftAligned__AQ6Q0 .banner_cta__X7tTd,
  .banner_isLeftAligned__AQ6Q0 .banner_description__c2HOj {
    margin-left: 75.7556561086rem;
  }
}
/*  */
/*  */
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  -moz-tab-size: 4;
  tab-size: 4;
}

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
}

hr {
  height: 0;
}

abbr[title] {
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  /* font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;  */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

button,
input,
optgroup,
select,
textarea {
  /* font-family: inherit;  */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;

  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

summary {
  display: list-item;
}

@keyframes passing-through {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30%,
  70% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1);
  }
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px !important;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

.hide {
  display: none;
}

.ctf-clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 35%;
}

html {
  /* font-size: 62.5%; */
  text-size-adjust: 100%;
}

@media (min-width: 992px) {
  body {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

html,
body {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: black;
  color: #ffffff;
  text-shadow: none;
}

p {
  margin: 0 0 1.46666667em;
}

ul {
  list-style: disc;
}

ul,
ol {
  margin: 1.46666667em 0;
}

ul li,
ol li {
  margin-bottom: 0.5em;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin: 0.5em 0 0;
}

a {
  /* text-decoration: underline;
  outline: 0;
  transition: color 0.2s ease-in-out;
  color: #f05644;
  text-decoration-thickness: 1px; */
}

a:hover,
a:focus {
  /* color: black; */
}

button {
  transition: color 0.2s ease-in-out;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 calc(1em - 13px);
  line-height: 1.47727273;
}

h1,
.h1 {
  font-weight: 600;
  font-size: 2.72727273em;
  /* */
}

h2,
.h2 {
  font-weight: 200;
  font-size: 2em;
}

h4,
.h4 {
  font-weight: 600;
  font-size: 0.88888889em;
}

.paragraph--lead {
  font-size: 1.11111111em;
  line-height: 1.46666667;
  font-weight: 400;
}

@media (min-width: 768px) {
  .paragraph--lead {
    font-size: 1.36363636em;
  }
}

.text--underline {
  text-decoration: underline;
}

.text--strikethrough {
  text-decoration: line-through;
}

.no-focus-outline button:focus,
.no-focus-outline a:focus,
.no-focus-outline .btn:focus {
  outline: none;
}

body:not(.no-focus-outline) button:focus,
body:not(.no-focus-outline) a:focus,
body:not(.no-focus-outline) .btn:focus {
  
}

.panel--dark body:not(.no-focus-outline) button:focus,
.panel--dark body:not(.no-focus-outline) a:focus,
.panel--dark body:not(.no-focus-outline) .btn:focus {
  outline: 0.2rem solid white;
  outline-offset: -0.1rem;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.alert p:last-child {
  margin-bottom: 0;
}

.alert--no-js {
  background: maroon;
  padding: 10px 20px;
  position: relative;
  color: #ffffff;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  z-index: 10;
}

.alert--error {
  background: maroon;
  padding: 10px 20px;
  color: #ffffff;
  margin-bottom: 1.07142857em;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 3px;
}

.alert--success {
  background: black;
  padding: 10px 20px;
  color: #ffffff;
  margin-bottom: 1.07142857em;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 3px;
}

.banner--about {
  position: relative;
  padding-bottom: 3.66666667%;
  background: #fff;
  color: #222222;
}

.no-js .banner--about {
  background-color: var(--banner-background-color);
}

.banner--about .banner__image {
  text-align: center;
  margin-top: 38px;
}

@media (min-width: 768px) {
  .banner--about .banner__image {
    margin-top: 0;
  }
}

.banner--about .banner__caption {
  font-weight: 400;
  line-height: 1.46666667;
  transition: ease-in-out 0.2s color;
  position: relative;
  text-align: center;
}

@media (min-width: 768px) {
  .banner--about .banner__caption {
    max-width: 660px;
    margin: 0 auto;
    font-size: 1.36363636em;
  }
}

.banner--about .banner__caption .banner__title {
 
  font-weight: 400;
  font-size: 1.11111111em;
}

@media (min-width: 768px) {
  .banner--about .banner__caption .banner__title {
    font-size: 1em;
  }
}

.banner--about .section--stats {
  position: relative;
  z-index: 0;
}

.banner--about .section--stats .banner__image {
  z-index: -1;
}

@media (min-width: 768px) {
  .banner--about .section--stats .banner__image {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - (25.74626866% * 2));
    height: 100%;
  }
}

@media (min-width: 992px) {
  .banner--about .section--stats .banner__image {
    height: auto;
  }
}

.banner--about .section--stats .banner__image img {
  max-width: 79.36507937%;
}

@media (min-width: 768px) and (max-width: 992px) {
  .banner--about .section--stats .banner__image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    max-width: none;
  }
}

@media (min-width: 992px) {
  .banner--about .section--stats .banner__image img {
    max-width: 55.38461538%;
  }
}

.banner--case-study {
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  position: relative;
  color: #222222;
  background-color: #ffffff;
}

@media (max-width: 1199px) {
  .banner--case-study {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .banner--case-study:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 59.77777778%;
  }
}

@media (min-width: 1200px) {
  .banner--case-study {
    height: 100vh;
  }
}

.banner--case-study.banner--overlay .banner__video:after,
.banner--case-study.banner--overlay .banner__image:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  opacity: 0;
  animation: fadeIn 0.5s linear 0s forwards;
}

@media (max-width: 767px) {
  .banner--case-study .banner__image {
    margin-bottom: 21px;
    position: relative;
  }

  .banner--case-study .banner__image:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 76%;
  }
}

.banner--case-study .banner__image img {
  width: 100%;
  height: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@supports (object-fit: cover) {
  .banner--case-study .banner__image img {
    object-fit: cover;
    height: 100%;
  }
}

.banner--case-study .banner__video {
  display: block;
  overflow: hidden;
  position: relative;
}

@media (max-width: 767px) {
  .banner--case-study .banner__video {
    margin-bottom: 21px;
  }

  .banner--case-study .banner__video:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 76%;
  }
}

@media (min-width: 768px) {
  .banner--case-study .banner__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.banner--case-study .banner__video video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.banner--case-study .banner__title {
  font-size: 1.44444444em;
  font-weight: 600;
  line-height: 1.34545455;
  width: 100%;
  max-width: 800px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 768px) {
  .banner--case-study .banner__title {
    font-size: 1.63636364em;
    display: block;
  }
}

@media (min-width: 992px) {
  .banner--case-study .banner__title {
    font-size: 2.5em;
  }
}

@media (max-width: 767px) {
  .banner--case-study
    .banner__title[data-waay]:not(.waay-animate)
    *[data-waay-step] {
    opacity: 1;
    transform: translateY(0);
  }
}

.banner--case-study .banner__title span {
  display: block;
}

.banner--case-study .banner__title .banner__subtitle {
  display: block;
  font-weight: 400;
  opacity: 0.75;
  font-size: 0.61538462em;
  line-height: 2;
  margin-top: 0.6875em;
}

@media (min-width: 768px) {
  .banner--case-study .banner__title .banner__subtitle {
    font-size: 0.55555556em;
    margin-top: 0.85em;
  }
}

@media (min-width: 992px) {
  .banner--case-study .banner__title .banner__subtitle {
    font-size: 0.36363636em;
    margin-bottom: 0.85em;
    margin-top: 0;
  }
}

.banner--case-study.banner--case-study--white {
  color: #ffffff;
  background-color: #222222;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.banner--contact {
  position: relative;
  padding-bottom: 65px;
}

@media (min-width: 768px) {
  .banner--contact {
    padding-bottom: 3.66666667%;
  }
}

.no-js .banner--contact {
  background-color: var(--banner-background-color);
}

.banner--contact .banner__image {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.banner--contact .banner__image svg {
  display: block;
  width: 26.44444444vw;
  height: 27.66666667vw;
}

.banner--contact .banner__title {
  color: #231f20;
  margin-bottom: 0.93333333em;
  font-size: 1.66666667em;
  line-height: 1.33333333;
  text-transform: capitalize;
}

@media (min-width: 768px) {
  .banner--contact .banner__title {
    font-size: 2.02727273em;
    margin-bottom: 0.7em;
  }
}

.banner--contact .banner__caption {
  font-weight: 400;
  line-height: 1.46666667;
  transition: ease-in-out 0.2s color;
  position: relative;
  text-align: center;
}

.banner--contact .banner__caption .banner__content {
  margin: 0 auto;
  font-size: 1.11111111em;
}

@media (min-width: 768px) {
  .banner--contact .banner__caption .banner__content {
    font-size: 1.18181818em;
  }
}

.banner--contact .banner__caption .banner__content .banner__link {
  margin-top: 49px;
}

@media (min-width: 768px) {
  .banner--contact .banner__caption .banner__content .banner__link {
    margin-top: 9.375%;
  }
}

.no-js .banner--team {
  background-color: var(--banner-background-color);
}

.banner--team.banner--has-link {
  margin-top: 2.8rem;
}

@media (min-width: 768px) {
  .banner--team.banner--has-link {
    margin-top: -1.31944444vw;
  }
}

@media (min-width: 1800px) {
  .banner--team.banner--has-link {
    margin-top: -1.9rem;
  }
}

.banner--team .banner__inner {
  display: flex;
  flex-direction: column;
}

.banner--team .banner__inner > div {
  width: 100%;
}

.banner--team .banner__link {
  display: block;
  position: absolute;
  top: var(--header-height, 7.3rem);
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0 1rem;
}

@media (min-width: 1200px) {
  .banner--team .banner__link {
    top: calc(var(--header-height, 12.4rem) + 1.3rem);
  }
}

.banner--team .banner__link a {
  background: white;
  border-radius: 0.74074074vw;
  padding: 0.5em 1.3125em;
  line-height: 1.625;
  font-weight: 600;
  letter-spacing: 0;
  text-decoration: none;
  font-size: 1.1rem;
  color: #2f3234;
  text-align: center;
  display: inline-block;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
}

@media (min-width: 1080px) {
  .banner--team .banner__link a {
    font-size: 1.11111111vw;
    border-radius: 0.8rem;
  }
}

@media (min-width: 1440px) {
  .banner--team .banner__link a {
    font-size: 1.6rem;
  }
}

@media (min-width: 768px) {
  .banner--team .banner__link a .btn__inner {
    display: flex;
    align-items: center;
    gap: 0 2.1rem;
  }
}

.banner--team .banner__link a .btn__icon {
  color: #2081ff;
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
}

.banner--team .banner__link a:hover,
.banner--team .banner__link a:focus,
.banner--team .banner__link a.on-press {
  color: white;
  background: #2081ff;
}

.banner--team .banner__link a:hover .btn__icon,
.banner--team .banner__link a:focus .btn__icon,
.banner--team .banner__link a.on-press .btn__icon {
  color: white;
}

.banner--team .banner__image {
  position: relative;
  margin: 0 auto;
  max-width: 180rem;
  order: -1;
  transition: ease-in-out 0.2s opacity;
}

.banner--team .banner__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 55.55555556%;
}

@media (min-width: 1800px) {
  .banner--team .banner__image:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100rem;
  }
}

.banner--team .banner__image img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-position: bottom center;
  object-fit: scale-down;
}

.banner--team .banner__caption {
  font-weight: 400;
  font-size: 1.36363636em;
  line-height: 1.46666667;
  transition: ease-in-out 0.2s color;
  position: relative;
  text-align: center;
}

@media (min-width: 768px) {
  .banner--team .banner__caption {
    min-height: 80vh;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: no-preference) {
  .banner--team .banner__caption {
    display: block;
  }
}

.banner--team .banner__caption .banner__title {
  font-size: 0.8em;
 
  font-weight: 400;
}

@media (min-width: 768px) {
  .banner--team .banner__caption .banner__title {
    font-size: 1em;
  }
}

.banner--team .banner__caption-inner {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  will-change: transform;
}

.banner--team .banner__caption-inner p:last-child {
  margin-bottom: 0;
}

.banner--team.light .banner__caption {
  color: rgba(255, 255, 255, 0.75);
}

.banner--page {
  position: relative;
  padding-top: 10.83333333%;
  padding-bottom: 3.66666667%;
  background: #fff;
  text-align: center;
  color: #222222;
}

@media (min-width: 1800px) {
  .banner--page {
    padding-top: 195px;
  }
}

.search-results .banner--page {
  padding-bottom: 0;
}

.banner--landing {
  text-align: center;
  padding-bottom: 4.38888889%;
}

@media (min-width: 1800px) {
  .banner--landing {
    padding-bottom: 79px;
  }
}

.banner--landing .container {
  max-width: 593px;
}

.banner--landing .banner__title {
  font-weight: 400;
  color: #1a1b1d;
  letter-spacing: 0;
  line-height: 1.28571429;
  font-size: 2.4rem;
}

@media (min-width: 768px) {
  .banner--landing .banner__title {
    font-size: 3.5rem;
  }
}

.banner--landing-sticky-banner .banner__inner {
  display: flex;
  flex-direction: column;
  margin-left: -10.05555556vw;
  margin-bottom: -40px;
}

@media (min-width: 992px) {
  .banner--landing-sticky-banner .banner__inner {
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 1800px) {
  .banner--landing-sticky-banner .banner__inner {
    margin-left: -181px;
  }
}

.banner--landing-sticky-banner .banner__inner > div {
  padding-left: 10.05555556vw;
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .banner--landing-sticky-banner .banner__inner > div {
    width: 50%;
  }
}

@media (min-width: 1800px) {
  .banner--landing-sticky-banner .banner__inner > div {
    padding-left: 181px;
  }
}

@media (min-width: 1200px) {
  .banner--landing-sticky-banner .banner__inner > div:nth-child(2n + 1) {
    width: 38.56502242%;
  }
}

@media (min-width: 1200px) {
  .banner--landing-sticky-banner .banner__inner > div:nth-child(2n + 2) {
    width: 61.43497758%;
  }
}

.banner--landing-sticky-banner .banner__title {
  font-weight: 600;
  color: #1a1b1d;
  letter-spacing: 0;
  line-height: 1.08;
  font-size: 2.27272727em;
}

.banner--landing-sticky-banner .banner__description {
  font-weight: 400;
  font-size: 2rem;
  color: #404347;
  letter-spacing: 0;
  line-height: 1.5;
}

.banner--landing-sticky-banner .banner__video {
  width: 100%;
  position: relative;
}

.banner--landing-sticky-banner .banner__video:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 70.95115681%;
}

.banner--landing-sticky-banner .banner__video video {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-position: center center;
  object-fit: cover;
  border-radius: 8px;
}

.banner--landing-sticky-banner .banner__video .btn--video-play-modaal {
  position: absolute;
  bottom: 1.38888889em;
  right: 1.77777778em;
  z-index: 2;
  font-weight: 400;
  font-size: 0.81818182em;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 1.55555556;
  display: flex;
  align-items: center;
}

.banner--landing-sticky-banner .banner__video .btn--video-play-modaal img {
  width: 1.88888889em;
  height: auto;
  display: block;
  margin-right: 0.77777778em;
}

.banner--landing-sticky-banner .banner__buttons {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.05555556vw;
  margin-bottom: -19px;
  margin-top: 49px;
}

@media (min-width: 1800px) {
  .banner--landing-sticky-banner .banner__buttons {
    margin-left: -19px;
  }
}

.banner--landing-sticky-banner .banner__buttons > div {
  padding-left: 1.05555556vw;
  margin-bottom: 19px;
  width: 100%;
}

@media (min-width: 768px) {
  .banner--landing-sticky-banner .banner__buttons > div {
    width: 50%;
  }
}

@media (min-width: 1800px) {
  .banner--landing-sticky-banner .banner__buttons > div {
    padding-left: 19px;
  }
}

.banner--landing-sticky-banner .banner__buttons > div .btn {
  display: block;
  width: 100%;
  border-radius: 3px;
  padding: 1.23529412em 1.05882353em 1.11764706em;
  font-size: 0.77272727em;
  line-height: 1.11764706;
}

.banner--landing-sticky-banner .banner__sticky {
  max-width: 1366px;
  width: calc(100% - 20px);
  margin: 0 auto;
  position: fixed;
  bottom: 10px;
  background: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(34, 34, 34, 0.15);
  border-radius: 3px;
  overflow: hidden;
  font-weight: 400;
  font-size: 1.6rem;
  color: #1a1b1d;
  letter-spacing: 0;
  line-height: 1.875;
  z-index: 20;
  left: 10px;
  padding: 14px 20px;
  transition: ease-in-out 0.2s opacity, ease-in-out 0.2s transform;
}

@media (min-width: 768px) {
  .banner--landing-sticky-banner .banner__sticky {
    padding: 0;
    width: calc(100% - 60px);
    font-size: 1.4rem;
    left: 30px;
    bottom: 20px;
  }
}

body:not(.js-scrolled-100) .banner--landing-sticky-banner .banner__sticky {
  opacity: 0;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  body:not(.js-scrolled-100) .banner--landing-sticky-banner .banner__sticky {
    transform: translateY(30px);
  }
}

@media (min-width: 1200px) {
  .banner--landing-sticky-banner .banner__sticky {
    font-size: 1.6rem;
  }
}

@media (min-width: 1426px) {
  .banner--landing-sticky-banner .banner__sticky {
    left: calc(((100vw - 1366px) / 2));
  }
}

.banner--landing-sticky-banner .banner__sticky-inner {
  display: flex;
  align-items: center;
  margin-left: -1.94444444vw;
}

@media (min-width: 1800px) {
  .banner--landing-sticky-banner .banner__sticky-inner {
    margin-left: -35px;
  }
}

.banner--landing-sticky-banner .banner__sticky-inner p {
  margin: 0;
}

.banner--landing-sticky-banner .banner__sticky-inner a {
  font-weight: 600;
  color: inherit;
}

.banner--landing-sticky-banner .banner__sticky-inner a:hover,
.banner--landing-sticky-banner .banner__sticky-inner a:focus,
.banner--landing-sticky-banner .banner__sticky-inner a.on-press {
  color: #f05644;
}

.banner--landing-sticky-banner .banner__sticky-inner svg {
  display: block;
}

.banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-face {
  background: var(--face-bg, black);
}

@media (max-width: 767px) {
  .banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-face {
    display: none;
  }
}

.banner--landing-sticky-banner
  .banner__sticky-inner
  .banner__sticky-face-inner {
  padding: 24px 22px 20px;
  min-height: 89px;
  min-width: 89px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner--landing-sticky-banner
  .banner__sticky-inner
  .banner__sticky-face
  .face-flick:not(.active) {
  display: none;
}

.banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-cta,
.banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-phone {
  white-space: nowrap;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-phone {
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-cta {
    padding-right: 23px;
  }
}

.banner--landing-sticky-banner .banner__sticky-inner .banner__sticky-cta .btn {
  font-weight: 600;
  font-size: 1.4rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.1875;
  padding: 1em 2.125em 0.9375em;
}

@media (min-width: 768px) {
  .banner--landing-sticky-banner
    .banner__sticky-inner
    .banner__sticky-cta
    .btn {
    font-size: 1.6rem;
  }
}

.banner--landing-sticky-banner .banner__sticky-inner .link--phone {
  display: flex;
  align-items: center;
  text-decoration: none;
}

@media (max-width: 767px) {
  .banner--landing-sticky-banner .banner__sticky-inner .link--phone {
    font-size: 1.3rem;
  }
}

.banner--landing-sticky-banner .banner__sticky-inner .link--phone .link__icon {
  font-size: 1.25em;
  margin-right: 0.6em;
}

.banner--landing-sticky-banner
  .banner__sticky-inner
  .link--phone
  .link__icon
  svg {
  display: block;
  height: 1em;
  width: 1em;
}

.banner--landing-sticky-banner .banner__sticky-inner > div {
  padding-left: 1.94444444vw;
}

@media (min-width: 1800px) {
  .banner--landing-sticky-banner .banner__sticky-inner > div {
    padding-left: 35px;
  }
}

.banner--landing-sticky-banner .banner__sticky-inner > div:first-child {
  align-self: stretch;
}

.banner--landing-sticky-banner .banner__sticky-inner > div.banner__sticky-body {
  flex-grow: 1;
  padding-top: 4px;
  padding-bottom: 4px;
}

@media (max-width: 767px) {
  .banner--landing-sticky-banner
    .banner__sticky-inner
    > div.banner__sticky-body {
    display: none;
  }
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 0;
}

.btn {
  display: inline-block;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
}

.btn--border {
  border: 1px solid #a4a4a4;
  transition: ease-in-out 0.2s border-color, ease-in-out 0.2s color;
  color: rgba(34, 34, 34, 0.75);
  font-size: 0.81818182em;
}

.btn--border:hover,
.btn--border:focus,
.btn--border.on-press {
  border-color: black;
  color: #222222;
}

.btn--link,
.btn--link-arrow {
  font-weight: 500;
  font-size: 0.81818182em;
  padding: 0;
  text-align: left;
  color: inherit;
}

.btn--link .btn__icon,
.btn--link-arrow .btn__icon {
  display: inline-block;
  margin-left: 30px;
  transition: ease-in-out 0.2s transform;
}

.btn--link .btn__icon svg,
.btn--link-arrow .btn__icon svg {
  display: block;
}

.btn--link .btn__icon svg path,
.btn--link-arrow .btn__icon svg path {
  fill: currentColor;
}

.btn--link .btn__label,
.btn--link-arrow .btn__label {
  transition: ease-in-out 0.2s opacity;
}

.btn--link:hover .btn__label,
.btn--link-arrow:hover .btn__label,
.btn--link:focus .btn__label,
.btn--link-arrow:focus .btn__label,
.btn--link.on-press .btn__label,
.on-press.btn--link-arrow .btn__label {
  opacity: 0.75;
}

.btn--link-arrow {
  position: relative;
  padding-right: 4.27777778em;
}

.btn--link-arrow .btn__icon {
  position: absolute;
  right: 0;
  top: calc(50% - (0.94444444em / 2));
}

.btn--link-arrow .btn__icon svg {
  display: block;
  width: 1.38888889em;
  height: 0.88888889em;
  transform: rotate(-45deg);
}

@media (prefers-reduced-motion: no-preference) {
  .btn--link-arrow:hover .btn__icon,
  .btn--link-arrow:focus .btn__icon,
  .btn--link-arrow.on-press .btn__icon {
    transform: translateX(6px);
  }
}

.section--floating-cta .btn--link-arrow,
.case-study__footer--alt .btn--link-arrow {
  font-size: 1.6rem;
  padding: 1.4375em 1.5625em;
  text-align: center;
  width: 100%;
  max-width: 31.5rem;
}

.case-study__introduction-link .btn--link-arrow {
  font-size: 1.8rem;
  padding-right: 2.77777778em;
}

@media (min-width: 768px) {
  .case-study__introduction-link .btn--link-arrow {
    margin-bottom: 1.83333333em;
  }
}

.case-study__introduction-link .btn--link-arrow svg {
  transform: rotate(-45deg);
}

.case-study__introduction-link .btn--link-arrow:hover,
.case-study__introduction-link .btn--link-arrow:focus,
.case-study__introduction-link .btn--link-arrow.on-press {
  color: inherit;
}

@media (prefers-reduced-motion: no-preference) {
  .case-study__introduction-link .btn--link-arrow:hover .btn__icon,
  .case-study__introduction-link .btn--link-arrow:focus .btn__icon,
  .case-study__introduction-link .btn--link-arrow.on-press .btn__icon {
    transform: translateX(6px) translateY(-6px);
  }
}

.btn--video-play,
.btn--video-play-modaal {
  text-decoration: none;
  transition: ease-in-out 0.2s opacity;
  padding: 0;
}

.btn--video-play:hover,
.btn--video-play:focus,
.btn--video-play.on-press,
.btn--video-play-modaal:hover,
.btn--video-play-modaal:focus,
.btn--video-play-modaal.on-press {
  opacity: 0.75;
}

.btn--video-play img,
.btn--video-play-modaal img {
  display: block;
  width: 100%;
  height: auto;
}

.btn--planner {
  background: black;
  border-radius: 45px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.90909091em;
  letter-spacing: 0;
  text-align: center;
  padding: 6px;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
  display: block;
  position: relative;
  padding-left: calc(2em + 6px + (10px * 2));
}

@media (min-width: 480px) {
  .btn--planner {
    display: inline-block;
  }
}

.banner--contact .btn--planner {
  font-size: 1em;
  height: 5.5rem;
}

@media (min-width: 768px) {
  .banner--contact .btn--planner {
    font-size: 0.76923077em;
  }
}

.btn--planner .flex {
  display: flex;
  align-items: center;
  min-height: calc(2em + (-151px * 2));
  /* height: 5rem; */
}
.btn .btn--planner {
}
.btn--planner .btn__face {
  background: #99daf2;
  display: block;
  border-radius: 45px;
  padding: 10px;
  width: calc(2em + (10px * 2));
  transition: background 0.2s ease-in-out;
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 2;
}

.btn--planner .btn__face svg {
  width: 2em;
  height: 2em;
  display: block;
  position: relative;
  left: -0.1em;
  top: 0.1em;
  margin-left: auto;
  transform: rotate(0deg);
  transform-origin: center center;
  transition: none;
}

.btn--planner .btn__label {
  flex-grow: 1;
  padding: 0.3em 0.75em;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .btn--planner .btn__label {
    padding: 0.3em 1.7em;
  }
}

.btn--planner .btn__arrow {
  padding: 0.3em 1.2em 0.3em 0.5em;
}

.btn--planner .btn__arrow svg {
  display: block;
  width: 1.4em;
  height: 1.4em;
  transition: transform 0.2s ease-in-out;
}

.btn--planner .btn__arrow svg path {
  transition: fill 0.2s ease-in-out;
}

.btn--planner:hover,
.btn--planner:focus,
.btn--planner.on-press {
  background: red;
  color: black;
  height: 5.5rem;
}

.btn--planner:hover .btn__face,
.btn--planner:focus .btn__face,
.btn--planner.on-press .btn__face {
  background: #ffc938;
}

.btn--planner:hover .btn__arrow svg,
.btn--planner:focus .btn__arrow svg,
.btn--planner.on-press .btn__arrow svg {
  transform: translateX(6px);
}

@media (prefers-reduced-motion: reduce) {
  .btn--planner:hover .btn__arrow svg,
  .btn--planner:focus .btn__arrow svg,
  .btn--planner.on-press .btn__arrow svg {
    transform: none;
  }
}

.btn--planner:hover .btn__arrow svg path,
.btn--planner:focus .btn__arrow svg path,
.btn--planner.on-press .btn__arrow svg path {
  fill: black;
}

.is-transitioning .btn--planner .btn__face,
.btn--planner.is-animating .btn__face {
  width: calc(100% - 12px);
  transition: background 0.2s ease-in-out,
    0.5s width cubic-bezier(0.54, 0, 0.41, 1);
}

@media (prefers-reduced-motion) {
  .is-transitioning .btn--planner .btn__face,
  .btn--planner.is-animating .btn__face {
    width: calc(2em + (10px * 2));
  }
}

.is-transitioning .btn--planner .btn__face svg,
.btn--planner.is-animating .btn__face svg {
  transform: rotate(360deg);
  transition: 0.5s transform cubic-bezier(0.54, 0, 0.41, 1);
}

@media (prefers-reduced-motion) {
  .is-transitioning .btn--planner .btn__face svg,
  .btn--planner.is-animating .btn__face svg {
    transform: none;
  }
}

.btn--purple,
.btn--blue,
.btn--yellow,
.btn--red,
.btn--pink,
.btn--lavender {
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.90909091em;
  transition: ease-in-out 0.2s background, ease-in-out 0.2s color;
  line-height: 1.46666667;
  padding: 0.7em 2.4em;
}

.btn--purple {
  background: black;
  color: #ffffff;
}

.btn--purple:not([disabled]):hover,
.btn--purple:not([disabled]):focus,
.btn--purple:not([disabled]).on-press {
  background: #99daf2;
  color: black;
}

.btn--purple.btn--border {
  background: transparent;
  color: black;
  border: none;
  box-shadow: inset 0 0 0 2px black;
}

.btn--purple.btn--border:hover,
.btn--purple.btn--border:focus,
.btn--purple.btn--border.on-press {
  background: black;
  color: #ffffff;
}

.btn--red {
  background: #f05644;
  color: #ffffff;
}

.btn--red:hover,
.btn--red:focus,
.btn--red.on-press {
  background: #ffc938;
  color: #f05644;
}

.btn--blue {
  background: #99daf2;
  color: #222222;
}

.btn--blue:hover,
.btn--blue:focus,
.btn--blue.on-press {
  background: black;
  color: #99daf2;
}

.btn--yellow {
  background: #ffc938;
  color: #222222;
}

.btn--yellow:hover,
.btn--yellow:focus,
.btn--yellow.on-press {
  background: #99daf2;
  color: black;
}

.btn--pink {
  background: #ffd1d1;
  color: #222222;
}

.btn--pink:hover,
.btn--pink:focus,
.btn--pink.on-press {
  background: #99daf2;
  color: black;
}

.btn--lavender {
  background: #e2d1ff;
  color: #222222;
}

.btn--lavender:hover,
.btn--lavender:focus,
.btn--lavender.on-press {
  background: #99daf2;
  color: black;
}

.btn--solid {
  font-size: 1.6rem;
  line-height: 1.875;
  letter-spacing: 0;
  font-weight: 500;
  border-radius: 3px;
}

.btn--solid-white {
  background: #ffffff;
  color: black;
}

.btn--full {
  display: block;
  width: 100%;
}

.btn--header-cta {
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.11764706;
  padding: 0.94117647em 1.41176471em;
  border-radius: 3px;
  font-size: 1.4rem;
}

@media (min-width: 768px) {
  .btn--header-cta {
    font-size: 1.7rem;
    padding: 0.94117647em 2.35294118em;
  }
}

.skip-to-link {
  position: fixed;
  top: 10px;
  left: 10px;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  color: #ffffff;
  background: #222222;
  opacity: 0;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  z-index: 999999;
  outline: none;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  padding: 10px 16px;
}

.skip-to-link:focus {
  overflow: visible;
  width: auto;
  height: auto;
  color: #ffffff;
  opacity: 1;
  clip: auto;
  white-space: normal;
  outline: none;
}

.skip-to-link:hover {
  background: #090909;
}

.block--award {
  font-size: 0.72727273em;
  line-height: 1.625;
}

.block--award .block__image {
  margin-bottom: 1.6875em;
  position: relative;
  max-width: 144px;
  width: auto;
}

.block--award .block__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.block--award .block__image img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.block--logo {
  background: #f9f9f9;
  width: 100%;
  padding: 9.375%;
}

.block--logo .block__image {
  position: relative;
}

.block--logo .block__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.block--logo .block__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.block--service {
  line-height: 2;
  font-size: 0.81818182em;
}

@media (min-width: 768px) {
  .block--service {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -5.72222222vw;
  }

  .block--service > div {
    padding-left: 5.72222222vw;
    width: 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 1800px) {
  .block--service {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -103px;
  }

  .block--service > div {
    padding-left: 103px;
    width: 50%;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .block--service > div:nth-child(2n + 1) {
    width: 46.01611459%;
  }
}

@media (min-width: 1800px) {
  .block--service > div:nth-child(2n + 1) {
    width: 46.01611459%;
  }
}

@media (min-width: 768px) {
  .block--service > div:nth-child(2n + 2) {
    width: 53.98388541%;
  }
}

@media (min-width: 1800px) {
  .block--service > div:nth-child(2n + 2) {
    width: 53.98388541%;
  }
}

.block--service ul {
  opacity: 0.6;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 3em;
}

.block--service ul li {
  margin-bottom: 0.44444444em;
}

.block--service .slide__title {
 
  line-height: 1.25;
  font-weight: 300;
  margin-bottom: 1.11363636em;
  font-size: 2.44444444em;
}

.block--service .block__cta {
  display: block;
  margin-top: 3em;
}

@media (min-width: 768px) {
  .block--service .block__cta {
    margin-top: 0;
  }
}

.block--service .block__cta .block__cta-content {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.block--service .block__cta .block__cta-content:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 117.6%;
}

.block--service .block__cta .block__cta-title {
  font-weight: 700;
  font-size: 1.44444444em;
  line-height: 1.15384615;
  margin-bottom: 0;
  transition: ease-in-out 0.2s transform;
 
  padding: 34px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.block--service .block__cta .block__cta-title .block__cta-subtitle {
  display: block;
  opacity: 0.75;
  font-weight: 400;
  font-size: 0.76923077em;
  line-height: 1.5;
 
  margin-bottom: 8px;
}

.block--testimonial {
  box-sizing: border-box;
  font-size: 0.88888889em;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .block--testimonial {
    font-size: 1.09090909em;
  }
}

.block--testimonial .block__cite {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -22px;
  flex-wrap: nowrap;
  font-size: 0.83333333em;
  line-height: 1.5;
  font-weight: 500;
  padding-top: 0.35em;
}

.block--testimonial .block__cite > div {
  padding-left: 22px;
  width: 50%;
  margin-bottom: 0;
}

.block--testimonial .block__cite > div {
  flex-grow: 1;
  width: auto;
}

.block--testimonial .block__cite > div:first-child {
  width: 72px;
  flex-grow: 0;
}

.block--testimonial .block__image {
  border-radius: 100%;
  width: 100%;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.block--testimonial .block__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.block--testimonial .block__icon {
  position: relative;
}

.block--testimonial .block__icon:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 101.35746606%;
}

.block--testimonial .block__icon svg {
  width: 100%;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.grid--work .block--testimonial {
  max-width: 641px;
  /* background-image: url(/wp-content/themes/helica5/dist/images/testimonial-bg.svg?cee19ad502de9dac3492291e84629bee); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 25.11700468% 30px 15.1326053%;
}

@media (min-width: 768px) {
  .grid--work .block--testimonial {
    padding: 25.11700468% 5.92823713% 15.1326053% 21.68486739%;
  }
}

@media (min-width: 1800px) {
  .grid--work .block--testimonial {
    padding: 161px 38px 97px 139px;
  }
}

.block--what-we-do {
  padding-top: 8.57632933%;
}

.block--what-we-do h2 {
  font-weight: 400;
  font-size: 1.36363636em;
  line-height: 1.46666667;
  margin-bottom: 2.16666667em;
}

.block--what-we-do .block__inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -8.48958333vw;
  margin-left: 0;
}

.block--what-we-do .block__inner > div {
  padding-left: 8.48958333vw;
  width: 100%;
  margin-bottom: 47px;
}

.block--what-we-do .block__inner > div {
  padding-left: 0;
}

@media (min-width: 768px) {
  .block--what-we-do .block__inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -8.48958333vw;
  }

  .block--what-we-do .block__inner > div {
    padding-left: 8.48958333vw;
    width: 50%;
    margin-bottom: 47px;
  }
}

.block--what-we-do .block__inner > div:nth-child(2) {
  color: #a1a1a1;
}

.block--what-we-do .block__inner ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.36363636em;
  line-height: 2;
}

.card--blog-post-hover .blog-read-more,
.card--blog-post .blog-item:hover .blog-read-more,
.card--blog-post .blog-item.focussed .blog-read-more,
.card--blog-post-hover .card__link,
.card--blog-post .blog-item:hover .card__link,
.card--blog-post .blog-item.focussed .card__link {
  color: black;
}

.card--blog-post .blog-item {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.card--blog-post .blog-read-more {
  font-size: 0.81818182em;
  margin: 25px 0 0;
  text-decoration: none;
  color: #222222;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.card--blog-post .blog-meta,
.card--blog-post .post-edit-link {
  position: relative;
  z-index: 3;
}

.card--blog-post .card__link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.card--recommended-article {
  height: 100%;
}

.card--recommended-article .blog-item__inner {
  position: relative;
  display: flex;
  flex-direction: column;
}

.card--recommended-article .blog-item__inner .blog-hero-thumb {
  order: -1;
}

.card--recommended-article .blog-item__inner:hover .blog-read-more,
.card--recommended-article .blog-item__inner:hover .card__link,
.card--recommended-article .blog-item__inner:focus .blog-read-more,
.card--recommended-article .blog-item__inner:focus .card__link,
.card--recommended-article .blog-item__inner.on-press .blog-read-more,
.card--recommended-article .blog-item__inner.on-press .card__link {
  color: #99daf2;
}

.card--recommended-article .blog-meta {
  position: relative;
  z-index: 3;
}

.card--recommended-article .blog-read-more {
  font-size: 0.81818182em;
  margin: 25px 0 0;
  text-decoration: none;
  color: #222222;
  font-weight: 500;
  transition: color 0.2s ease-in-out;
}

.card--recommended-article .card__link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.card--helica .card__image {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
}

.card--helica .card__image:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 138.55721393%;
}

.card--helica .card__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: block;
}

@supports (object-fit: cover) {
  .card--helica .card__image img {
    object-fit: cover;
    height: 100%;
    object-position: top center;
  }
}

.card--helica .card__image video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: block;
}

@supports (object-fit: cover) {
  .card--helica .card__image video {
    object-fit: cover;
    height: 100%;
    object-position: center center;
  }
}

.card--helica .card__caption {
  font-weight: 400;
  font-size: 0.72727273em;
  line-height: 1.66666667;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -20px;
  align-items: center;
}

.card--helica .card__caption > div {
  padding-left: 20px;
  width: 50%;
  margin-bottom: 0;
}

.card--helica .card__caption strong {
  font-weight: 600;
}

.card--helica .card__caption .card__icon {
  font-size: 3.4375em;
  margin-top: 0.07272727em;
}

.card--helica .card__caption .card__icon svg {
  display: block;
  width: 1em;
  height: 1em;
}

.card--helica .card__caption > div:nth-child(2n + 1) {
  width: 70px;
}

.card--helica .card__caption > div:nth-child(2n + 2) {
  width: calc(100% - 70px);
}

.card--stat {
  font-weight: 400;
  font-size: 0.90909091em;
  line-height: 1.5;
  text-align: center;
  color: #80868c;
}

.card--stat p {
  margin: 0;
}

.card--stat .card__number {
  font-size: 2.7em;
  line-height: 1.31578947;
 
  font-weight: 600;
  color: #1d1d1d;
}

@media (min-width: 1200px) {
  .card--stat .card__number {
    font-size: 3.8em;
  }
}

.card--stat .card__symbol {
  font-size: 2.7em;
  letter-spacing: -0.01776316em;
  font-weight: 600;
  color: #1d1d1d;
 
}

@media (min-width: 1200px) {
  .card--stat .card__symbol {
    font-size: 3.8em;
  }
}

.card--stat .card__description {
  display: block;
  margin-top: 0.4em;
}

.card--work {
  position: relative;
  color: #222222;
}

.slider--services .card--work {
  color: inherit;
  cursor: none;
}

.slider--services .card--work a {
  cursor: none;
}

.card--work .card__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card--work .card__caption {
  opacity: 1;
  transition: opacity ease-in-out 0.2s;
  padding-top: 1.5rem;
}

.card--work .card__title {
  font-weight: 500;
  line-height: 1.34545455;
  margin: 0;
 
  font-size: 1em;
}

@media (min-width: 768px) {
  .card--work .card__title {
    font-size: 1.09090909em;
  }
}

.card--work .card__title .card__title-wrap {
  display: block;
  transition: transform ease-in-out 0.2s;
  will-change: transform;
  -webkit-transform-style: preserve-3d;
  display: flex;
  flex-direction: column-reverse;
}

.card--work .card__title .card__subtitle {
  display: block;
  opacity: 0.75;
  font-weight: 400;
  margin-top: 0.05em;
  line-height: 1.5;
  font-size: 0.88888889em;
  -webkit-backface-visibility: hidden;
}

@media (min-width: 768px) {
  .card--work .card__title .card__subtitle {
    font-size: 0.66666667em;
  }
}

.card--work .card__link {
  color: inherit;
  text-decoration: none;
  display: block;
}

.card--work .card__link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.card--work .card__image {
  position: relative;
  overflow: hidden;
  order: -1;
}

.card--work .card__image .card__object {
  display: block;
  width: 100%;
  height: auto;
  /* position: absolute; */
  top: 0;
  left: 0;
  transition: ease-in-out 0.5s transform, ease-in-out 0.5s object-position;
}

@supports (object-fit: cover) {
  .card--work .card__image .card__object {
    height: 100%;
    object-fit: cover;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .card--work-hover .card__title .card__title-wrap,
  .card--work.card--has-link:hover .card__title .card__title-wrap,
  .card--work.card--has-link:focus .card__title .card__title-wrap,
  .card--work.card--has-link.on-press .card__title .card__title-wrap {
    transform: translateY(0.4rem);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .card--work-hover .card__image .card__object,
  .card--work.card--has-link:hover .card__image .card__object,
  .card--work.card--has-link:focus .card__image .card__object,
  .card--work.card--has-link.on-press .card__image .card__object {
    transition: ease-in-out 3s transform;
    transform: scale(1.05, 1.05);
  }
}

html.is-transitioning .card--work-hover .card__image .card__object,
html.is-transitioning
  .card--work.card--has-link:hover
  .card__image
  .card__object,
html.is-transitioning
  .card--work.card--has-link:focus
  .card__image
  .card__object,
html.is-transitioning
  .card--work.card--has-link.on-press
  .card__image
  .card__object {
  transition: ease-in-out 0.2s transform;
  transform: scale(1, 1);
}

.card--careers {
  font-size: 0.90909091em;
  line-height: 1.6;
}

@media (min-width: 600px) and (max-width: 1199px) {
  .grid--helicas .card--careers:nth-child(2n + 2) .card__inner {
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .grid--helicas .card--careers:nth-child(3n + 3) .card__inner {
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .grid--helicas .card--careers:nth-child(3n + 3) .card__inner > div {
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 1199px) {
  .grid--helicas .card--careers:nth-child(2n + 2) .card__inner {
    flex-direction: column;
  }
}

@media (min-width: 600px) and (max-width: 1199px) {
  .grid--helicas .card--careers:nth-child(2n + 2) .card__inner > div {
    width: 100%;
  }
}

.card--careers .card__inner {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3rem 3rem;
}

@media (min-width: 600px) {
  .card--careers .card__inner {
    align-items: center;
    flex-direction: row;
  }
}

.card--careers .card__inner > div {
  width: 100%;
}

@media (min-width: 1200px) {
  .card--careers .card__inner > div.card__image {
    width: 44.67005076%;
    max-width: 44rem;
  }
}

@media (min-width: 1200px) {
  .card--careers .card__inner > div.card__content {
    width: 55.32994924%;
    flex-grow: 1;
  }
}

.card--careers .card__title {
  font-size: 1.2em;
  margin-bottom: 1.33333333em;
  font-weight: 500;
}

.card--careers .card__content a {
  color: #fff;
}

.card--careers .card__content a:hover,
.card--careers .card__content a:focus,
.card--careers .card__content a.on-press {
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 1200px) {
  .card--careers .card__content-inner {
    max-width: 400px;
  }
}

.card--careers .card__image-bg {
  position: relative;
  width: 100%;
}

.card--careers .card__image-bg:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}

.card--careers .card__image-bg img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-position: center center;
  object-fit: scale-down;
}

.drawer--enquiry {
  color: #ffffff;
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.625;
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.drawer--enquiry .drawer__mask {
  position: fixed;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(34, 34, 34, 0.5);
  z-index: -1;
  transition: ease-in-out 0.2s background;
}

.drawer--enquiry .drawer__wrap {
  position: fixed;
  background: #111111;
  top: 0;
  width: calc(100% - 30px);
  right: 0;
  max-width: 450px;
  bottom: 0;
  top: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .drawer--enquiry .drawer__wrap {
    transition: ease-in-out 0.5s transform;
  }
}

.drawer--enquiry .drawer__wrap .drawer__inner {
  padding: 40px 30px;
  overflow: auto;
  height: 100%;
}

@media (min-width: 768px) {
  .drawer--enquiry .drawer__wrap .drawer__inner {
    padding: 77px 55px;
  }
}

.drawer--enquiry .drawer__title {
  font-weight: 600;
  font-size: 3.2rem;
  letter-spacing: 0;
  line-height: 1.375;
  margin-top: 0.4375em;
  margin-bottom: 0.46875em;
}

.drawer--enquiry .btn--close {
  position: absolute;
  top: 2.8rem;
  right: 2.8rem;
  text-align: center;
  height: 2rem;
  width: 2rem;
  padding: 0;
}

.drawer--enquiry .btn--close:before,
.drawer--enquiry .btn--close:after {
  content: "";
  display: inline-block;
  width: 2px;
  height: 2.5rem;
  background: #979797;
  transform: rotate(-45deg);
  transition: ease-in-out 0.2s background;
  transform-origin: center center;
}

.drawer--enquiry .btn--close:after {
  margin-left: -2px;
  transform: rotate(45deg);
}

.drawer--enquiry .btn--close:hover:before,
.drawer--enquiry .btn--close:hover:after,
.drawer--enquiry .btn--close:focus:before,
.drawer--enquiry .btn--close:focus:after,
.drawer--enquiry .btn--close.on-press:before,
.drawer--enquiry .btn--close.on-press:after {
  background: #ffffff;
}

.drawer--enquiry a {
  color: inherit;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
}

.drawer--enquiry a:hover,
.drawer--enquiry a:focus,
.drawer--enquiry a.on-press {
  color: #99daf2;
}

.drawer--enquiry:not(.drawer--opened) .drawer__mask {
  background: rgba(34, 34, 34, 0);
}

.drawer--enquiry:not(.drawer--opened) .drawer__wrap {
  transform: translateX(100%);
}

.form--enquiry .btn--submit {
  margin-top: 0.6875em;
  padding: 1.125em 1.5em;
  font-weight: 500;
  font-size: 1.6rem;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 1.5;
}

.form--enquiry .btn--submit[disabled] {
  opacity: 0.4;
}

.template-landing--hero-sticky-banner .footer-main .footer__country {
  padding-bottom: 120px;
}

input:not([type="radio"]):not([type="checkbox"]):not([type="file"]),
textarea {
  -webkit-appearance: none;
 
}

input:not([type="radio"]):not([type="checkbox"]):not(
    [type="file"]
  )::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.form-control {
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="file"] {
  cursor: pointer;
}

textarea.form-control {
  width: 100%;
  min-height: 180px;
  border: 2px solid #c7c7c7;
  border-radius: 3px;
  color: #222222;
  resize: none;
  padding: 0.8125em 1.25em;
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
  font-size: 0.88888889em;
}

@media (min-width: 992px) {
  textarea.form-control {
    font-size: 1.18181818em;
  }
}

.ctf-mwrapper {
  position: relative;
  padding-top: 73px;
  font-weight: 400;
}

@media (min-height: 1023px) {
  .ctf-mwrapper {
    /* padding-top: 80px; */
    padding-bottom: 80px;
  }
}

@media (min-width: 768px) {
  .ctf-mwrapper {
    /* padding-top: 184px; */
    padding-bottom: 184px;
  }
}

.ctf-mwrapper .ctf-mwrapper-inner {
  position: relative;
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .ctf-mwrapper .ctf-ctf-form--form {
    min-height: 680px;
    display: flex;
    flex-direction: column;
  }
}

.ctf-mwrapper .ctf-ctf-form--form .ctf-inpstep-wrap {
  flex-grow: 1;
}

.planner-contact {
  position: relative;
  text-align: center;
  font-size: 0.72727273em;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .planner-contact {
    text-align: right;
  }
}

.planner-contact p {
  margin: 0 0 20px;
}

.planner-contact a {
  color: #2e2e2e;
  padding: 0 20px;
  text-decoration: none;
}

@media (min-width: 768px) {
  .planner-contact a {
    padding: 0 15px;
  }
}

.planner-contact a:hover,
.planner-contact a:focus,
.planner-contact a.on-press {
  color: black;
}

.planner-contact a + a {
  margin: 8px 0 0;
}

@media (min-width: 768px) {
  .planner-contact a + a {
    margin: 0;
  }
}

.planner-close {
  position: fixed;
  top: 55px;
  right: 25px;
  z-index: 150;
  color: #2e2e2e;
  padding: 0 25px 0 0;
  z-index: 10;
  font-size: 0.72727273em;
  text-decoration: none;
}

@media (min-width: 768px) {
  .planner-close {
    position: absolute;
  }
}

.planner-close:hover,
.planner-close:focus,
.planner-close.on-press {
  color: black;
}

.planner-close:hover .planner-close-icon:after,
.planner-close:hover .planner-close-icon:before,
.planner-close:focus .planner-close-icon:after,
.planner-close:focus .planner-close-icon:before,
.planner-close.on-press .planner-close-icon:after,
.planner-close.on-press .planner-close-icon:before {
  background: black;
}

.planner-close:hover .planner-close-icon:after,
.planner-close:focus .planner-close-icon:after,
.planner-close.on-press .planner-close-icon:after {
  top: -2px;
  height: 18px;
}

@media (prefers-reduced-motion: reduce) {
  .planner-close:hover .planner-close-icon:after,
  .planner-close:focus .planner-close-icon:after,
  .planner-close.on-press .planner-close-icon:after {
    top: 0;
    height: 14px;
  }
}

.planner-close:hover .planner-close-icon:before,
.planner-close:focus .planner-close-icon:before,
.planner-close.on-press .planner-close-icon:before {
  left: 0px;
  width: 18px;
}

@media (prefers-reduced-motion: reduce) {
  .planner-close:hover .planner-close-icon:before,
  .planner-close:focus .planner-close-icon:before,
  .planner-close.on-press .planner-close-icon:before {
    left: 2px;
    width: 14px;
  }
}

.planner-close-icon {
  position: absolute;
  top: 4px;
  right: 0;
  display: block;
  width: 15px;
  height: 15px;
  transform: rotate(-45deg);
  transform-origin: 50% 50%;
}

.planner-close-icon:after,
.planner-close-icon:before {
  /* content: ""; */
  position: absolute;
  display: block;
  background: #2e2e2e;
  transition: all 0.2s ease-in-out;
}

.planner-close-icon:after {
  top: 0;
  left: 8px;
  width: 2px;
  height: 14px;
}

.planner-close-icon:before {
  top: 6px;
  left: 2px;
  width: 14px;
  height: 2px;
}

.planner-indicators {
  position: absolute;
  left: -1px;
  margin: 0;
  padding: 0;
  list-style: none;
  top: 15px;
  display: flex;
  left: 0;
  width: 100%;
}

.planner-indicators li {
  opacity: 1;
  height: 3px;
  margin: 0 0 0 1px;
  background: #d9d9d9;
  transition: background 0.2s ease-in-out, opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  flex-grow: 1;
}

@media (min-width: 768px) {
  .planner-indicators li {
    max-width: 80px;
  }
}

.planner-indicators li.is-active {
  background: black;
}

.ctf-inpstep {
  display: none;
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 48px;
  transition: transform cubic-bezier(0.86, 0, 0.07, 1) 1s;
}

.ctf-inpstep.is-active {
  display: block;
  z-index: 10;
}

.step-instructions {
  max-width: 700px;
  margin: 0 0 2.5em;
  font-size: 0.90909091em;
  text-transform: initial;
  font-family: Helveticaneue,sans-serif;
}

.step-instructions br {
  display: none;
}

@media (min-width: 768px) {
  .step-instructions br {
    display: block;
  }
}

.ctf-stepm .trigger-analogy,
.ctf-stepm .trigger-dollar {
  margin: 50px 0;
  display: block;
  text-align: center;
}

@media (min-width: 768px) {
  .ctf-stepm .trigger-analogy,
  .ctf-stepm .trigger-dollar {
    display: inline-block;
  }
}

.ctf-stepm .ctf-inpstep__title {
  color: #231f20;
}

.form-field {
  margin: 0 0 25px 0;
}

@media (min-width: 768px) {
  .form-field {
    margin: 0 0 42px 0;
  }
}

.form-field label {
  display: block;
  color: #4d4d4d;
  line-height: 1;
  margin: 0 0 2px;
  position: relative;
  font-size: 0.90909091em;
  z-index: 1;
  font-weight: 500;
}

.form-field label span {
  color: #adadad;
}

.form-field .form-control {
  width: 100%;
  border: 2px solid #c7c7c7;
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  padding: 0.6em 0.6em;
  -webkit-appearance: none;
  border-radius: 0;
}

@media (min-width: 768px) {
  .form-field .form-control {
    font-size: 1.36363636em;
  }
}

.form-field .form-control[type="text"],
.form-field .form-control[type="email"],
.form-field .form-control[type="tel"] {
  border-width: 0 0 2px 0;
  padding: 0.6em 0;
}

.form-field .form-control.full-field {
  border-width: 2px;
  border-radius: 3px;
}

.form-field .form-control.error {
  border-color: #d03838;
}

.form-field .form-control[type="tel"] {
  letter-spacing: 0.06em;
}

.form-field .form-control[type="tel"]::placeholder {
  letter-spacing: 0;
}

.form-field .form-control::placeholder {
  color: #bbb;
  transition: color 0.2s ease-in-out;
}

.form-field .form-control:focus {
  border-color: black;
  outline: none;
}

.form-field .form-control:focus::placeholder {
  color: #ccc;
}

.form-field .form-control::-ms-clear {
  display: none;
}

.form-field textarea {
  margin: 20px 0 0;
  width: 100%;
  border: 2px solid #c7c7c7;
  border-radius: 3px;
  color: black;
  resize: none;
  padding: 13px 20px;
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
  font-size: 0.88888889em;
  height: 100px;
}

@media (min-width: 768px) {
  .form-field textarea {
    font-size: 1.18181818em;
    height: 180px;
  }
}

.form-field textarea.error {
  border-color: maroon;
}

.form-field textarea::placeholder {
  color: #bbb;
  transition: color 0.2s ease-in-out;
}

.form-field textarea:focus {
  border-color: black;
}

.form-field textarea:focus::placeholder {
  color: #ccc;
}

.form-field .form-field-col {
  margin: 0 0 25px;
}

@media (min-width: 768px) {
  .form-field .form-field-col {
    float: left;
    width: 50%;
    margin: 0 0 45px;
  }
}

.form-field .form-field-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -30px;
  margin-bottom: -30px;
}

.form-field .form-field-row > .form-field-col {
  padding-left: 30px;
  width: 50%;
  margin-bottom: 30px;
}

.form-field .form-field-row > .form-field-col {
  width: 100%;
}

@media (min-width: 768px) {
  .form-field .form-field-row > .form-field-col--half {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .form-field .form-field-row > .form-field-col--third {
    width: 33.33%;
  }
}

.custom-upload {
  position: relative;
  cursor: pointer;
  max-width: 555px;
  margin: 20px 0 0;
}

.custom-upload .inp-file {
  position: absolute;
  background: transparent;
  opacity: 0;
  z-index: 2;
}

.custom-upload .inp-file:focus + .inp-fake-file input {
  color: #ccc;
  background-color: #ffffff;
}

.custom-upload .inp-file input {
  border-color: transparent;
  font-size: 2rem;
}

.custom-upload .inp-fake-file {
  position: relative;
  z-index: 1;
}

.custom-upload .inp-fake-file:after {
  display: block;
  position: absolute;
  top: 23px;
  left: 27px;
  content: "\E00B";
  /* font-family: "Icons"; */
  line-height: 1;
  color: black;
  font-size: 2.2rem;
}

.custom-upload .inp-fake-file input {
  padding-left: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #ededed;
  color: #818181;
  font-size: 2rem;
}

.custom-upload .inp-fake-file input::placeholder {
  color: #818181;
  transition: color 0.2s ease-in-out;
}

.custom-upload .inp-fake-file input:focus {
  /* border-color: black; */
}

.custom-upload .inp-fake-file input:focus::placeholder {
  color: #bbb;
}

.custom-upload .inp-fake-file input:disabled {
  opacity: 1;
}

.ctf-inpstep__title {
  font-weight: 600;
  font-size: 3rem;
  text-transform: capitalize;
  color: #231f20;
  font-family: Helveticaneue,sans-serif;
}
.content-main {
  /* margin-top: 15rem; */
}

.contextual-response {
  display: none;
}

.planner-spinner {
  display: none;
  z-index: 100;
  top: 50%;
  left: 50%;
  border: 3px solid black;
  border-right-color: #ebebeb;
  border-left-color: #ebebeb;
  border-radius: 50%;
  animation: rotate 0.8s infinite linear;
  position: fixed;
  margin: -40px 0 0 -40px;
  height: 80px;
  width: 80px;
}

@media (prefers-reduced-motion: reduce) {
  .planner-spinner {
    animation: rotate steps(8) 5s infinite;
  }
}

.planner-thankyou-wrap {
  display: none;
  width: 100%;
}

.planner-thankyou-wrap-inner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.planner-thankyou-content h2 {
  margin: 0 0 40px;
  font-size: 1.11111111em;
}

@media (min-width: 768px) {
  .planner-thankyou-content h2 {
    font-size: 1.45454545em;
  }
}

.planner-thankyou-content h2 em {
  display: block;
  line-height: 1.1;
  font-style: normal;
  font-size: 1.94444444em;
  margin: 0 0 8px;
}

@media (min-width: 768px) {
  .planner-thankyou-content h2 em {
    font-size: 3.63636364em;
    margin: 0 0 5px;
  }
}

.planner-thankyou-content p {
  width: 100%;
  max-width: 550px;
}

.planner-thankyou-content .return-btn {
  border-radius: 2px;
  height: 50px;
  position: relative;
  display: inline-block;
  line-height: 49px;
  padding: 0 25px 0 120px;
  margin: 40px 0 0 -28px;
  text-align: right;
  background: transparent;
  color: #222222;
  transition: color 0.2s ease-in-out;
  font-size: 0.72727273em;
  text-decoration: none;
}

.planner-thankyou-content .return-btn:before,
.planner-thankyou-content .return-btn:after {
  display: block;
  content: " ";
  position: absolute;
}

.planner-thankyou-content .return-btn:before {
  top: 50%;
  left: 5px;
  width: 56px;
  height: 2px;
  margin: -1px 0 0;
  background: #ccc;
  transition: left 0.2s linear, width 0.2s linear, background 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .planner-thankyou-content .return-btn:before {
    transition: left 0s linear, width 0s linear, background 0.2s linear;
  }
}

.planner-thankyou-content .return-btn:after {
  left: 0;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(208, 208, 208, 0);
  border-right-color: #ccc;
  border-width: 6px;
  margin: -6px 0 0 -7px;
  transition: left 0.2s linear, border-color 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .planner-thankyou-content .return-btn:after {
    transition: left 0s linear, border-color 0.2s linear;
  }
}

.planner-thankyou-content .return-btn:hover,
.planner-thankyou-content .return-btn:focus,
.planner-thankyou-content .return-btn.on-press {
  color: #2e2e2e;
}

.planner-thankyou-content .return-btn:hover:before,
.planner-thankyou-content .return-btn:focus:before,
.planner-thankyou-content .return-btn.on-press:before {
  right: 0;
  width: 66px;
  background-color: #2e2e2e;
}

.planner-thankyou-content .return-btn:hover:after,
.planner-thankyou-content .return-btn:focus:after,
.planner-thankyou-content .return-btn.on-press:after {
  right: -5px;
  border-right-color: #2e2e2e;
}

.planner-thankyou-content .return-btn:after {
  left: 30px;
}

.planner-thankyou-content .return-btn:before {
  left: 35px;
}

.planner-thankyou-content .return-btn:hover,
.planner-thankyou-content .return-btn:focus,
.planner-thankyou-content .return-btn.on-press {
  color: #2e2e2e;
}

.planner-thankyou-content .return-btn:hover:after,
.planner-thankyou-content .return-btn:focus:after,
.planner-thankyou-content .return-btn.on-press:after {
  left: 25px;
}

.planner-thankyou-content .return-btn:hover:before,
.planner-thankyou-content .return-btn:focus:before,
.planner-thankyou-content .return-btn.on-press:before {
  left: 30px;
}

.ctf-button {
  display: block;
  bottom: 0;
  margin: 0 0 30px;
  z-index: 20;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 1200px) {
  .ctf-button {
    position: relative;
  }
}

.ctf-button button {
  background: transparent;
  border-radius: 2px;
  padding: 0;
  height: 50px;
  position: relative;
  text-align: left;
  color: #ffffff;
}

.ctf-button .step-back {
  width: 120px;
  transition: background 0.2s linear;
  font-size: 0.72727273em;
}

.ctf-button .step-back:before,
.ctf-button .step-back:after {
  display: block;
  content: " ";
  position: absolute;
}

.ctf-button .step-back:before {
  top: 50%;
  left: 5px;
  width: 56px;
  height: 2px;
  margin: -1px 0 0;
  background: #ccc;
  transition: left 0.2s linear, width 0.2s linear, background 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .ctf-button .step-back:before {
    transition: left 0s linear, width 0s linear, background 0.2s linear;
  }
}

.ctf-button .step-back:after {
  left: 0;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(208, 208, 208, 0);
  border-right-color: #ccc;
  border-width: 6px;
  margin: -6px 0 0 -7px;
  transition: left 0.2s linear, border-color 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .ctf-button .step-back:after {
    transition: left 0s linear, border-color 0.2s linear;
  }
}

.ctf-button .step-back:hover,
.ctf-button .step-back:focus,
.ctf-button .step-back.on-press {
  color: #2e2e2e;
}

.ctf-button .step-back:hover:before,
.ctf-button .step-back:focus:before,
.ctf-button .step-back.on-press:before {
  right: 0;
  width: 66px;
  background-color: #2e2e2e;
}

.ctf-button .step-back:hover:after,
.ctf-button .step-back:focus:after,
.ctf-button .step-back.on-press:after {
  right: -5px;
  border-right-color: #2e2e2e;
}

@media (min-width: 1200px) {
  .ctf-button .step-back {
    position: absolute;
    top: 0;
    right: calc(100% + 10px);
  }
}

@media (max-width: 1600px) {
  .ctf-button .step-back {
    margin: 0 10px 0 0;
  }
}

@media (max-width: 400px) {
  .ctf-button .step-back {
    width: 82px;
    margin: 0;
  }
}

.ctf-button .step-back:after {
  left: 30px;
}

@media (max-width: 400px) {
  .ctf-button .step-back:after {
    left: 23px;
  }
}

.ctf-button .step-back:before {
  left: 35px;
}

@media (max-width: 400px) {
  .ctf-button .step-back:before {
    left: 25px;
    width: 35px;
  }
}

.ctf-button .step-back:hover,
.ctf-button .step-back:focus,
.ctf-button .step-back.on-press {
  background: #e7e7e7;
}

@media (max-width: 400px) {
  .ctf-button .step-back:hover,
  .ctf-button .step-back:focus,
  .ctf-button .step-back.on-press {
    background: #e7e7e7;
  }
}

.ctf-button .step-back:hover:after,
.ctf-button .step-back:focus:after,
.ctf-button .step-back.on-press:after {
  left: 25px;
}

@media (max-width: 400px) {
  .ctf-button .step-back:hover:after,
  .ctf-button .step-back:focus:after,
  .ctf-button .step-back.on-press:after {
    left: 20px;
  }
}

.ctf-button .step-back:hover:before,
.ctf-button .step-back:focus:before,
.ctf-button .step-back.on-press:before {
  left: 30px;
}

@media (max-width: 400px) {
  .ctf-button .step-back:hover:before,
  .ctf-button .step-back:focus:before,
  .ctf-button .step-back.on-press:before {
    left: 22px;
    width: 40px;
  }
}

.ctf-button .ctf-submit {
  padding: 0 20px;
  background: black;
  transition: background 0.2s ease-in-out;
  padding-right: 109px;
  align-self: flex-end;
  margin-left: auto;
  font-size: 0.72727273em;
  font-family: Helveticaneue,sans-serif;
}

.ctf-button .ctf-submit:before,
.ctf-button .ctf-submit:after {
  display: block;
  content: " ";
  position: absolute;
}

.ctf-button .ctf-submit:before {
  top: 50%;
  right: 5px;
  width: 56px;
  height: 2px;
  margin: -1px 0 0;
  background: #ffffff;
  transition: right 0.2s linear, width 0.2s linear, background 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .ctf-button .ctf-submit:before {
    transition: right 0s linear, width 0s linear, background 0.2s linear;
  }
}

.ctf-button .ctf-submit:after {
  right: 0;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(208, 208, 208, 0);
  border-left-color: #ffffff;
  border-width: 6px;
  margin: -6px -7px 0 0;
  transition: right 0.2s linear, border-color 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .ctf-button .ctf-submit:after {
    transition: right 0s linear, border-color 0.2s linear;
  }
}

.ctf-button .ctf-submit:hover,
.ctf-button .ctf-submit:focus,
.ctf-button .ctf-submit.on-press {
  color: #ffffff;
}

.ctf-button .ctf-submit:hover:before,
.ctf-button .ctf-submit:focus:before,
.ctf-button .ctf-submit.on-press:before {
  right: 0;
  width: 66px;
  background-color: #ffffff;
}

.ctf-button .ctf-submit:hover:after,
.ctf-button .ctf-submit:focus:after,
.ctf-button .ctf-submit.on-press:after {
  right: -5px;
  border-left-color: #ffffff;
}

@media (min-width: 1200px) {
  .ctf-button .ctf-submit {
    float: none;
    padding: 0 25px;
    padding-right: 109px;
    margin-left: 0;
  }
}

.ctf-button .ctf-submit:after {
  right: 30px;
}

.ctf-button .ctf-submit:before {
  right: 35px;
}

.ctf-button .ctf-submit:hover:after,
.ctf-button .ctf-submit:focus:after,
.ctf-button .ctf-submit.on-press:after {
  right: 25px;
}

.ctf-button .ctf-submit:hover:before,
.ctf-button .ctf-submit:focus:before,
.ctf-button .ctf-submit.on-press:before {
  right: 30px;
}

.ctf-button .ctf-submit:focus:after {
  right: 25px;
}

.ctf-button .ctf-submit:focus:before {
  right: 30px;
}

.ctf-button .ctf-submit[disabled] {
  background: #ccc;
  cursor: pointer;
}

.ctf-button .ctf-submit.has-errors {
  background: #ccc;
}

.ctf-button .step-submit {
  background: black;
  transition: background 0.2s ease-in-out;
  padding: 0 20px;
  float: right;
  padding-right: 109px;
  font-size: 0.72727273em;
  margin-left: auto;
}

.ctf-button .step-submit:before,
.ctf-button .step-submit:after {
  display: block;
  content: " ";
  position: absolute;
}

.ctf-button .step-submit:before {
  top: 50%;
  right: 5px;
  width: 56px;
  height: 2px;
  margin: -1px 0 0;
  background: #ffffff;
  transition: right 0.2s linear, width 0.2s linear, background 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .ctf-button .step-submit:before {
    transition: right 0s linear, width 0s linear, background 0.2s linear;
  }
}

.ctf-button .step-submit:after {
  right: 0;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(208, 208, 208, 0);
  border-left-color: #ffffff;
  border-width: 6px;
  margin: -6px -7px 0 0;
  transition: right 0.2s linear, border-color 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .ctf-button .step-submit:after {
    transition: right 0s linear, border-color 0.2s linear;
  }
}

.ctf-button .step-submit:hover,
.ctf-button .step-submit:focus,
.ctf-button .step-submit.on-press {
  color: #ffffff;
}

.ctf-button .step-submit:hover:before,
.ctf-button .step-submit:focus:before,
.ctf-button .step-submit.on-press:before {
  right: 0;
  width: 66px;
  background-color: #ffffff;
}

.ctf-button .step-submit:hover:after,
.ctf-button .step-submit:focus:after,
.ctf-button .step-submit.on-press:after {
  right: -5px;
  border-left-color: #ffffff;
}

@media (min-width: 768px) {
  .ctf-button .step-submit {
    padding: 0 25px;
    padding-right: 109px;
    margin-left: 0;
  }
}

.ctf-button .step-submit:after {
  right: 30px;
}

.ctf-button .step-submit:before {
  right: 35px;
}

.ctf-button .step-submit:hover,
.ctf-button .step-submit:focus,
.ctf-button .step-submit.on-press {
  background: #ffc938;
}

.ctf-button .step-submit:hover:after,
.ctf-button .step-submit:focus:after,
.ctf-button .step-submit.on-press:after {
  right: 25px;
}

.ctf-button .step-submit:hover:before,
.ctf-button .step-submit:focus:before,
.ctf-button .step-submit.on-press:before {
  right: 30px;
}

.ctf-button .step-submit[disabled] {
  background: #ccc;
  cursor: default;
}

.ctf-button .step-submit.has-errors {
  background: #ccc;
}

.error-msg {
  margin: 0 0 0 30px;
  display: none;
  color: #d03838;
  top: auto;
  left: auto;
  order: 4;
  width: 100%;
  padding: 20px 0 0;
  text-align: center;
}

@media (min-width: 1200px) {
  .error-msg {
    width: auto;
    padding: 0;
  }
}

.type-select-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -4px;
  margin-bottom: 26px;
  justify-content: center;
}

.type-select-group > div {
  padding-left: 4px;
  width: 50%;
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  .type-select-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -0;
    justify-content: flex-start;
  }

  .type-select-group > div {
    padding-left: 0;
    width: 20%;
    margin-bottom: 30px;
  }
}

.type-selection {
  position: relative;
}

.type-selection label {
  cursor: pointer;
  display: block;
  width: 100%;
  border-radius: 2px;
  transition: background 0.2s linear, color 0.2s linear;
  text-align: center;
  position: relative;
  height: 100%;
  padding: 30px 30px 13px;
  line-height: 1;
}

.section--floating-cta {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 30;
  transition-property: opacity, transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

@media (min-width: 768px) {
  .section--floating-cta .section__inner {
    padding: 2rem;
  }
}

.section--floating-cta:not(.active) {
  opacity: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .section--floating-cta:not(.active) {
    /* transform: translateY(100%); */
  }
}

.section--floating-cta .btn {
  margin-left: auto;
  display: block;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .section--floating-cta .btn {
    max-width: 100%;
    width: 100%;
  }
}

.is-editable:after {
  content: "";
  clear: both;
  display: table;
}

.is-editable img {
  width: auto;
  max-width: 100%;
  display: block;
}

.is-editable .iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.is-editable .iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer-main {
  font-size: 1.18181818em;
  padding: 0;
  background: #ffffff;
}

.single-work .footer-main {
  background: #ffffff;
  position: relative;
  padding-top: 9.375%;
}

@media (min-width: 1600px) {
  .single-work .footer-main {
    padding-top: 150px;
  }
}

.page-template-template-logos .footer-main,
.template-about .footer-main,
.template-team .footer-main,
.template-careers .footer-main,
.category .footer-main,
.blog .footer-main {
  padding-top: 9.375%;
}

@media (min-width: 1600px) {
  .page-template-template-logos .footer-main,
  .template-about .footer-main,
  .template-team .footer-main,
  .template-careers .footer-main,
  .category .footer-main,
  .blog .footer-main {
    padding-top: 150px;
  }
}

.template-landing .footer-main,
.template-careers .footer-main {
  background: transparent;
}

.template-planner .footer-main {
  padding-bottom: 1.25%;
}

@media (min-width: 1600px) {
  .template-planner .footer-main {
    padding-bottom: 20px;
  }
}

.footer-main .footer-main__social ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.53846154em;
  line-height: 2.85714286;
}

.footer-main .footer-main__social ul li {
  display: inline-block;
}

.footer-main .footer-main__social ul li + li {
  position: relative;
}

.footer-main .footer-main__social ul li + li:before {
  content: "-";
  padding: 0 4px;
}

@media (min-width: 768px) {
  .footer-main .footer-main__social ul li + li:before {
    padding: 0 27px;
  }
}

.footer-main .footer-main__logo {
  margin: 0 auto;
  max-width: 413px;
  position: relative;
}

.footer-main .footer-main__logo .footer-main__logo-link {
  display: block;
}

.footer-main .footer-main__logo .footer-main__logo-link:before {
  /* content: ""; */
  display: block;
  width: 100%;
  padding-top: 100%;
}

.footer-main .footer-main__logo .footer-logo {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer-main .footer-main__logo .footer-logo .footer-logo__text {
  transform-origin: center center;
}

.footer-main .footer-main__logo .footer-logo .footer-logo__faces > path {
  opacity: 1;
}

.footer-main .footer-main__logo .footer-logo .footer-logo__faces > path.active {
  opacity: 1;
}

.footer-main .footer-main__bottom p {
  margin: 0;
}

.footer-main .footer-main__bottom a {
  text-decoration: none;
  color: #222222;
}

.footer-main .footer-main__bottom a:hover,
.footer-main .footer-main__bottom a:focus,
.footer-main .footer-main__bottom a.on-press {
  color: black;
}

.footer-main .footer-main__bottom-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -5px;
  justify-content: space-between;
}

.footer-main .footer-main__bottom-inner > div {
  padding-left: 5px;
  width: 50%;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .footer-main .footer-main__bottom-inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -15px;
    justify-content: space-between;
  }

  .footer-main .footer-main__bottom-inner > div {
    padding-left: 15px;
    width: 50%;
    margin-bottom: 0;
  }
}

.footer-main .footer-main__bottom-inner > div {
  width: auto;
}

.footer-main .footer-main__copyright {
  font-weight: 400;
  font-size: 0.53846154em;
  line-height: 2.85714286;
}

.footer-main .footer-main__copyright .link--privacy {
  padding-left: 6px;
}

@media (min-width: 768px) {
  .footer-main .footer-main__copyright .link--privacy {
    padding-left: 14px;
  }
}

.footer-main.js-reveal-footer--stuck {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.footer__country {
  font-weight: 400;
  font-size: 1.4rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.42857143;
  background: #232323;
  padding: 20px 0;
  margin-top: 9.22619048vw;
}

@media (min-width: 768px) {
  .footer__country {
    text-align: left;
  }
}

@media (min-width: 1680px) {
  .footer__country {
    margin-top: 155px;
  }
}

.footer__country a {
  color: #ffffff;
}

.footer__country a:hover,
.footer__country a:focus,
.footer__country a.on-press {
  color: rgba(255, 255, 255, 0.75);
}

.footer__country p {
  margin: 0;
}

.footer__country-inner {
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .footer__country-inner {
    flex-direction: column;
    align-items: center;
  }
}

.footer__country .footer__country-text {
  align-self: center;
}

.footer__country .footer__country-flag {
  font-size: 1.8rem;
  padding-right: 1.16666667em;
  display: block;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .footer__country .footer__country-flag {
    margin-bottom: 0;
  }
}

.footer__country .footer__country-flag svg {
  width: 1.66666667em;
  height: 1em;
  display: block;
}

body > div:last-child:not(.modaal-overlay) {
  z-index: 10;
}

svg {
  min-height: 0;
  min-width: 0;
}

html {
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  background: #ffffff;
}

body.scrolling-down {
  transition: ease-in-out 0.5s background-color;
}

body.scrolling-up {
  transition: ease-in-out 0.3s background-color;
}

.container {
  /* max-width: var(--max-width, 1500px); */
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (min-width: 768px) {
  .container {
    width: 90%;
  }
}

.container--std {
  max-width: 1440px;
}

.container--page {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.container--page .content-main {
  flex-grow: 1;
}

.contact--form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.footer-main .container--full {
  max-width: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .footer-main .container--full {
    padding: 0 70px;
  }
}

.section--contact-details.section--contact-details.section--contact-details.section--contact-details {
  font-size: 16px;
}

.section--contact-details {
  font-weight: 400;
  line-height: 1.5;
  padding: 11.44179894% 0 7.47354497%;
}

@media (min-width: 768px) {
  .section--contact-details {
    line-height: 1.84615385;
  }
}

.container--contact-details .section--contact-details {
  padding-top: 0;
}

@media (min-width: 768px) {
  .container--contact-details .section--contact-details {
    padding-top: 5.75396825%;
  }
}

.footer-main .section--contact-details {
  font-size: 1.375em;
}

.section--contact-details-inner {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.section--contact-details-inner > div {
  padding-right: 3.125em;
}

@media (min-width: 992px) {
  .section--contact-details-inner > div {
    min-width: 15.625em;
    width: 25%;
  }
}

@media (min-width: 680px) and (max-width: 1199px) {
  .section--contact-details-inner > div:nth-child(1) {
    width: 100%;
  }
}

@media (max-width: 530px) {
  .section--contact-details-inner > div {
    width: 100%;
  }
}

.section--contact-details-inner a {
  text-decoration: none;
  color: #222222;
}

.section--contact-details-inner a:hover,
.section--contact-details-inner a:focus,
.section--contact-details-inner a.on-press {
  color: black;
}

.section--contact-details h2 {
  font-weight: 600;
  font-size: 1.125em;
  color: #0b0b0b;
  line-height: 2;
  margin-bottom: 0.25em;
}

@media (min-width: 768px) {
  .section--contact-details h2 {
    font-size: 1.375em;
  }
}

.section--contact-details a {
  opacity: 0.75;
}

.section--contact-details a svg path {
  fill: currentColor;
}

.section--contact-details .link--email,
.section--contact-details .link--call {
  display: inline-block;
  position: relative;
  padding-left: 35px;
}

.section--contact-details .link--email .link__icon,
.section--contact-details .link--call .link__icon {
  position: absolute;
  top: 50%;
  left: 0;
  width: 22px;
  height: 21px;
  transform: translateY(-50%);
}

.section--contact-details .link--email .link__text,
.section--contact-details .link--call .link__text {
  padding-top: 2px;
}

.section--contact-details .link--email svg,
.section--contact-details .link--call svg {
  width: inherit;
  height: inherit;
}
#container { margin: 0%; }

#circle { position: relative; width: 100%; padding-bottom: 100%; overflow: hidden; }

#circle text { font-family: 'Helvetica Neue', Arial; font-size: 16px; font-weight: bold; }
.footer-main__log svg { position: absolute; left: 0; top: 0; width: 100%; height: 540px;

  -webkit-animation-name: rotate;
     -moz-animation-name: rotate;
      -ms-animation-name: rotate;
       -o-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 5s;
     -moz-animation-duration: 5s;
      -ms-animation-duration: 5s;
       -o-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
     -moz-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
       -o-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
     -moz-animation-timing-function: linear;
      -ms-animation-timing-function: linear;
       -o-animation-timing-function: linear;
          animation-timing-function: linear;

}

@-webkit-keyframes rotate {
    from { -webkit-transform: rotate(360deg); }
    to { -webkit-transform: rotate(0); }
}
@-moz-keyframes rotate {
    from { -moz-transform: rotate(360deg); }
    to { -moz-transform: rotate(0); }
}
@-ms-keyframes rotate {
    from { -ms-transform: rotate(360deg); }
    to { -ms-transform: rotate(0); }
}
@-o-keyframes rotate {
    from { -o-transform: rotate(360deg); }
    to { -o-transform: rotate(0); }
}
@keyframes rotate {
    from { transform: rotate(360deg); }
    to { transform: rotate(0); }
}

.footer-logo__faces{
  width: 8rem;
  margin-top: 5rem;
  position: absolute;
  /* top: 15rem; */
  top: 9rem;
  left: 40%;
}

body{
  /* font-family: 'Urbanist', sans-serif; */
}
 
/* @media (max-width:780px) {
  .planner-close{
    position: absolute;
top: 5rem;
  }
  } */
  .spinner_spinner__NwsMu {
    display: block;
    position: relative;
    width: 2rem;
    height: 2rem;
    font-size: .4rem;
    pointer-events: none
}

@-webkit-keyframes spinner_load8__R3w8S {
    0% {
        -webkit-transform: rotate(0deg) translateZ(0);
        transform: rotate(0deg) translateZ(0)
    }

    to {
        -webkit-transform: rotate(1turn) translateZ(0);
        transform: rotate(1turn) translateZ(0)
    }
}

@keyframes spinner_load8__R3w8S {
    0% {
        -webkit-transform: rotate(0deg) translateZ(0);
        transform: rotate(0deg) translateZ(0)
    }

    to {
        -webkit-transform: rotate(1turn) translateZ(0);
        transform: rotate(1turn) translateZ(0)
    }
}

.spinner_spinner__NwsMu:after,.spinner_spinner__NwsMu:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    text-indent: -9999em
}

.spinner_spinner__NwsMu:before {
    opacity: .2;
    border: .4em solid
}

.spinner_spinner__NwsMu:after {
    border: .4em solid transparent;
    border-left-color: currentcolor;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spinner_load8__R3w8S .7s linear infinite;
    animation: spinner_load8__R3w8S .7s linear infinite
}

.button_btn__EuPxb {
    display: inline-flex;
    position: relative;
    z-index: 0;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    /* font-family: NeueHaasGroteskDisplay,Helvetica Neue,helvetica,arial,sans-serif; */
    color: inherit;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    text-decoration: none;
    text-transform: inherit;
    -webkit-appearance: none;
    appearance: none
}

.button_btn__EuPxb:disabled {
    pointer-events: none
}

.button_btn__EuPxb .button_spinner__BybWR {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate3d(-50%,calc(-50% + 4rem),0);
    transform: translate3d(-50%,calc(-50% + 4rem),0)
}

.button_btn__EuPxb .button_children__CAXMx,.button_btn__EuPxb .button_spinner__BybWR {
    transition: border 1s cubic-bezier(.16,1.08,.38,.98),opacity .9s cubic-bezier(.77,0,.175,1),-webkit-transform .9s cubic-bezier(.77,0,.175,1);
    transition: border 1s cubic-bezier(.16,1.08,.38,.98),transform .9s cubic-bezier(.77,0,.175,1),opacity .9s cubic-bezier(.77,0,.175,1);
    transition: border 1s cubic-bezier(.16,1.08,.38,.98),transform .9s cubic-bezier(.77,0,.175,1),opacity .9s cubic-bezier(.77,0,.175,1),-webkit-transform .9s cubic-bezier(.77,0,.175,1)
}

.button_btn__EuPxb.button_hasLoading___jJLT {
    overflow: hidden;
    overflow: clip
}

.button_btn__EuPxb.button_isLoading__IK7NC {
    pointer-events: none
}

.button_btn__EuPxb.button_isLoading__IK7NC .button_children__CAXMx,.button_btn__EuPxb.button_isLoading__IK7NC .button_spinner__BybWR {
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(.16,1.08,.38,.98)
}

.button_btn__EuPxb.button_isLoading__IK7NC .button_children__CAXMx {
    opacity: 0;
    -webkit-transform: translate3d(0,-4rem,0);
    transform: translate3d(0,-4rem,0)
}

.button_btn__EuPxb.button_isLoading__IK7NC .button_spinner__BybWR {
    opacity: 1;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0)
}

.button_btn__EuPxb.button_underlined__5j51U {
    display: inline-block
}

@media(hover: hover) {
    .button_btn__EuPxb.button_underlined__5j51U {
        /* padding:.1em 0; */
        color: white;
    }

    .button_btn__EuPxb.button_underlined__5j51U:hover:after {
        -webkit-transform: scaleX(1) translateZ(0);
        transform: scaleX(1) translateZ(0);
        transition-timing-function: cubic-bezier(.16,1.08,.38,.98)
    }
}

.button_btn__EuPxb.button_underlined__5j51U:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: currentcolor;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
    transition: -webkit-transform .6s cubic-bezier(.77,0,.175,1);
    transition: transform .6s cubic-bezier(.77,0,.175,1);
    transition: transform .6s cubic-bezier(.77,0,.175,1),-webkit-transform .6s cubic-bezier(.77,0,.175,1);
    pointer-events: none
}

.button_btn__EuPxb.button_underlined__5j51U.button_isActive__tn6U_:after {
    -webkit-transform: none;
    transform: none;
    transition-timing-function: cubic-bezier(.16,1.08,.38,.98)
}

.button_btn__EuPxb.button_circle__0x73m,.button_btn__EuPxb.button_rounded__Aad7d {
    --background: #fff;
    --color: #262626;
    position: relative;
    overflow: hidden;
    overflow: clip;
    background: var(--background);
    color: var(--color);
    transition: all .6s cubic-bezier(.26,1.04,.54,1);
    transition-property: border,color
}

.button_btn__EuPxb.button_circle__0x73m:before,.button_btn__EuPxb.button_rounded__Aad7d:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    opacity: 0;
    background: var(--color);
    transition: opacity 1s cubic-bezier(.26,1.04,.54,1)
}

.button_btn__EuPxb.button_circle__0x73m svg,.button_btn__EuPxb.button_rounded__Aad7d svg {
    position: relative;
    z-index: 1
}

.button_btn__EuPxb.button_circle__0x73m:active,.button_btn__EuPxb.button_rounded__Aad7d:active {
    color: #fff;
    transition-duration: 0s
}

.button_btn__EuPxb.button_circle__0x73m:active:before,.button_btn__EuPxb.button_rounded__Aad7d:active:before {
    opacity: 1;
    -webkit-transform: translate3d(-50%,0,0);
    transform: translate3d(-50%,0,0)
}

@media(hover: hover) {
    .button_btn__EuPxb.button_circle__0x73m:hover,.button_btn__EuPxb.button_rounded__Aad7d:hover {
        background:transparent;
        color: var(--background);
        transition-duration: .2s
    }

    .button_btn__EuPxb.button_circle__0x73m:hover:before,.button_btn__EuPxb.button_rounded__Aad7d:hover:before {
        opacity: 1;
        -webkit-transform: translate3d(-50%,0,0);
        transform: translate3d(-50%,0,0);
        transition: opacity .1s cubic-bezier(.16,1.08,.38,.98),-webkit-transform .2s cubic-bezier(.16,1.08,.38,.98);
        transition: opacity .1s cubic-bezier(.16,1.08,.38,.98),transform .2s cubic-bezier(.16,1.08,.38,.98);
        transition: opacity .1s cubic-bezier(.16,1.08,.38,.98),transform .2s cubic-bezier(.16,1.08,.38,.98),-webkit-transform .2s cubic-bezier(.16,1.08,.38,.98)
    }
}

.button_btn__EuPxb.button_circle__0x73m {
    width: clamp(36px,4rem,48px);
    height: clamp(36px,4rem,48px);
    border-radius: 50%;
    transition: all .6s cubic-bezier(.26,1.04,.54,1);
    transition-property: color,background
}

@media(min-width: 1024px) {
    .button_btn__EuPxb.button_circle__0x73m {
        width:clamp(45px,5rem,60px);
        height: clamp(45px,5rem,60px)
    }
}

.button_btn__EuPxb.button_circle__0x73m svg {
    transition: opacity .5s cubic-bezier(.16,1.08,.38,.98)
}

.button_btn__EuPxb.button_circle__0x73m:disabled svg {
    opacity: .2
}

.button_btn__EuPxb.button_rounded__Aad7d {
    height: clamp(36px,4rem,48px);
    padding: .2em clamp(18px,2rem,24px) 0;
    border-radius: clamp(36px,4rem,48px)
}

@media(min-width: 1024px) {
    .button_btn__EuPxb.button_rounded__Aad7d {
        height:clamp(45px,5rem,60px);
        padding: .2em clamp(22.5px,2.5rem,30px) 0;
        border-radius: clamp(45px,5rem,60px);
        font-size: clamp(10.8px,1.2rem,14.4px)
    }
}

.button_btn__EuPxb.button_rounded__Aad7d svg {
    margin: -.1em 0 0 clamp(9px,1rem,12px);
    transition: -webkit-transform .5s cubic-bezier(.16,1.08,.38,.98);
    transition: transform .5s cubic-bezier(.16,1.08,.38,.98);
    transition: transform .5s cubic-bezier(.16,1.08,.38,.98),-webkit-transform .5s cubic-bezier(.16,1.08,.38,.98)
}

@media(min-width: 1024px) {
    .button_btn__EuPxb.button_rounded__Aad7d svg {
        width:clamp(9.9px,1.1rem,13.2px);
        height: clamp(9px,1rem,12px)
    }
}

.button_btn__EuPxb.button_rounded__Aad7d:disabled svg {
    opacity: .2
}

@media(hover: hover) {
    .button_btn__EuPxb.button_rounded__Aad7d:hover svg {
        -webkit-transform:translate3d(.4rem,0,0);
        transform: translate3d(.4rem,0,0)
    }
}

.button_btn__EuPxb.button_rounded__Aad7d:active svg {
    -webkit-transform: translate3d(.4rem,0,0);
    transform: translate3d(.4rem,0,0)
}

.button_btn__EuPxb.button_inverted__V4YPC {
    background: var(--color);
    color: var(--background)
}

.button_btn__EuPxb.button_inverted__V4YPC:before {
    background: var(--gradient,var(--hover-background-color),var(--color));
    transition: opacity 3.5s cubic-bezier(.26,1.04,.54,1),-webkit-transform 4s cubic-bezier(.26,1.04,.54,1);
    transition: opacity 3.5s cubic-bezier(.26,1.04,.54,1),transform 4s cubic-bezier(.26,1.04,.54,1);
    transition: opacity 3.5s cubic-bezier(.26,1.04,.54,1),transform 4s cubic-bezier(.26,1.04,.54,1),-webkit-transform 4s cubic-bezier(.26,1.04,.54,1)
}

@media(hover: hover) {
    .button_btn__EuPxb.button_inverted__V4YPC:hover {
        background:var(--color)
    }
}

.button_btn__EuPxb.button_inverted__V4YPC:active {
    background: var(--color)
}

.button_btn__EuPxb.button_transparent__FM9QF {
    background: transparent;
    border: 1px solid var(--background);
    color: var(--background)
}

@media(hover: hover) {
    .button_btn__EuPxb.button_transparent__FM9QF:hover {
        background:var(--hover-background-color,var(--background));
        border-color: var(--hover-background-color,var(--background));
        color: var(--color)
    }
}

.button_btn__EuPxb.button_transparent__FM9QF:active {
    background: var(--hover-background-color,var(--background));
    border-color: var(--hover-background-color,var(--background));
    color: var(--color)
}

.button_btn__EuPxb.button_hoverTransparent__aHoYi {
    position: relative;
    border: 1px solid var(--background)
}

.button_btn__EuPxb.button_hoverTransparent__aHoYi:after {
    content: "";
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    pointer-events: none
}

.button_btn__EuPxb.button_hoverTransparent__aHoYi.button_rounded__Aad7d:after {
    border-radius: 5rem
}

.button_btn__EuPxb.button_hoverTransparent__aHoYi.button_circle__0x73m:after {
    border-radius: 50%
}

.button_btn__EuPxb.button_hoverTransparent__aHoYi.button_inverted__V4YPC:after {
    border-color: var(--color)
}

.nav_nav__eVb5m {
    display: flex;
    position: absolute;
    z-index: 2;
    top: 3rem;
    left: 0;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 2.1rem;
    color: #0e0e0e;
    transition: opacity .5s cubic-bezier(.16,1.08,.38,.98),visibility .5s;
    pointer-events: none;
    will-change: opacity
}

@media(min-width: 600px)and (max-width:1023px) {
    .nav_nav__eVb5m {
        padding:0 3.36rem
    }
}

@media(min-width: 1024px) {
    .nav_nav__eVb5m {
        top:4rem;
        padding: 0 6rem
    }
}

.nav_nav__eVb5m.nav_isHidden__V_n_K {
    opacity: 0
}

.nav_nav__eVb5m.nav_isHidden__V_n_K .nav_element__qZw4E {
    pointer-events: none
}

.nav_nav__eVb5m.nav_isDisplayed__MNIyt .nav_element__qZw4E {
    -webkit-transform: none;
    transform: none
}

.nav_element__qZw4E {
    -webkit-transform: translate3d(0,-7.5rem,0);
    transform: translate3d(0,-7.5rem,0);
    transition: -webkit-transform 1.2s cubic-bezier(.16,1.08,.38,.98);
    transition: transform 1.2s cubic-bezier(.16,1.08,.38,.98);
    transition: transform 1.2s cubic-bezier(.16,1.08,.38,.98),-webkit-transform 1.2s cubic-bezier(.16,1.08,.38,.98);
    transition-delay: var(--delay-mobile);
    pointer-events: all
}

@media(min-width: 1024px) {
    .nav_element__qZw4E {
        -webkit-transform:translate3d(0,-9.5rem,0);
        transform: translate3d(0,-9.5rem,0);
        transition-delay: var(--delay)
    }

    .nav_link__Eyu1_ {
        margin-right: 4rem
    }

    .nav_link__Eyu1_:last-child {
        margin-right: 0
    }

    .nav_cta___ySE0 {
        margin-left: 6rem
    }
}

@media(min-width: 0px)and (max-width:1023px) {
    .nav_left__EDL6H {
        display:flex;
        align-items: center
    }
}

.nav_right__ps6E0 {
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.nav_logo__iUEYR {
    display: block;
    position: relative;
    z-index: 3;
    width: 10.2rem;
    height: 2rem;
    transition: color .6s cubic-bezier(.26,1.04,.54,1);
    pointer-events: all
}

@media(min-width: 1024px) {
    .nav_logo__iUEYR {
        width:12.8rem;
        height: 2.5rem
    }
}

.nav_logo__iUEYR svg {
    width: 100%;
    height: 100%;
    height: auto
}

.nav_burger__lNtRg {
    position: relative;
    flex-direction: column;
    justify-content: center;
    pointer-events: all
}

.nav_burger__lNtRg .nav_close__mLKcz {
    position: absolute!important;
    top: 50%;
    left: 50%;
    width: .8rem!important;
    height: .8rem!important;
    opacity: 0;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    transition: opacity .3s cubic-bezier(.77,0,.175,1)
}

.nav_burger__lNtRg .nav_line__ZivxI {
    width: 1.6rem;
    height: 1px;
    margin-bottom: 2px;
    background-color: currentcolor;
    border-radius: 1px;
    transition: opacity .3s cubic-bezier(.16,1.08,.38,.98) .1s
}

.nav_burger__lNtRg .nav_line__ZivxI:last-child {
    margin-bottom: 0
}

.nav_burger__lNtRg.nav_isOpened__eYL8i .nav_close__mLKcz {
    opacity: 1;
    transition-delay: .25s
}

.nav_burger__lNtRg.nav_isOpened__eYL8i .nav_line__ZivxI {
    opacity: 0;
    transition-delay: .15s
}

.nav_burger__lNtRg.nav_isHidden__V_n_K {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate3d(-3rem,0,0);
    transform: translate3d(-3rem,0,0)
}

.nav_light__ty_Ep {
    color: #fff
}

.nav_light__ty_Ep .nav_burger__lNtRg {
    background: #fff;
    color: #000
}

.image_imageWrapper__G8xW8 {
    opacity: 0;
    transition: opacity .5s cubic-bezier(.26,1.04,.54,1)
}

.image_image__lootM {
    object-fit: cover
}

@media(min-width: 0px)and (max-width:1023px) {
    .image_isPriority__ZFJDO {
        opacity:1
    }
}

.image_isLoaded__LEH_h {
    opacity: 1
}

.navPanel_navPanel__7WkJj {
    position: fixed;
    z-index: 1001;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    background: #000;
    color: #fff;
    transition: visibility 1s,-webkit-transform 1s cubic-bezier(.77,0,.175,1);
    transition: transform 1s cubic-bezier(.77,0,.175,1),visibility 1s;
    transition: transform 1s cubic-bezier(.77,0,.175,1),visibility 1s,-webkit-transform 1s cubic-bezier(.77,0,.175,1)
}

.navPanel_navPanel__7WkJj.navPanel_isOpened__R5lg8 {
    visibility: inherit;
    -webkit-transform: translateY(-100%) translateZ(0);
    transform: translateY(-100%) translateZ(0);
    transition-timing-function: cubic-bezier(.16,1.08,.38,.98)
}

.navPanel_navPanel__7WkJj .navPanel_item__DYvoM {
    padding: 1.5rem 0;
    border-top: 1px solid hsla(0,0%,100%,.15)
}

.navPanel_scrollWrapper__wtBWb {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch
}

.navPanel_wrapper__tIHNH {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100% - 7rem);
    padding: 10rem 5.55vw 4rem
}

.navPanel_bottom__4iOYC {
    margin-top: 4rem
}

.navPanel_ctas__byqp_ {
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    margin-bottom: 2.1rem
}

.navPanel_cta__lPjd7 {
    padding-right: 1.7rem!important;
    padding-left: 1.7rem!important
}

.navPanel_background__Zfs9l {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.header_header__3o0p0 {
    position: fixed;
    z-index: 1000;
    right: 0;
    left: 0
}

.SmoothScroll_container__L1r_8 {
    width: 100%;
    /* margin-top: 2rem; */
    /* background-color: black; */
}

.SmoothScroll_container__L1r_8.SmoothScroll_isActive__YEn7u {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transform: translateY(200vh);
    transform: translateY(200vh)
}

.SmoothScroll_size__CSf_l {
    width: 1px;
    display: none
}

.SmoothScroll_size__CSf_l.SmoothScroll_isActive__YEn7u {
    display: block
}

.input_wrapper__NP9x7 {
    position: relative
}

.input_input__JVjrL {
    width: 100%;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    transition: color 5s cubic-bezier(.16,1.08,.38,.98)
}

.input_input__JVjrL:focus {
    outline: none
}

.input_input__JVjrL::-webkit-input-placeholder {
    vertical-align: middle;
    opacity: .2;
    color: currentColor
}

.input_input__JVjrL::placeholder {
    vertical-align: middle;
    opacity: .2;
    color: currentColor
}

.input_input__JVjrL::-webkit-inner-spin-button,.input_input__JVjrL::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0
}

.input_input__JVjrL[type=number] {
    -webkit-appearance: textfield;
    appearance: textfield
}

.input_input__JVjrL.input_hasError__Jx_kv {
    border-color: #fe3514!important;
    color: #fe3514!important
}

.input_input__JVjrL.input_hasError__Jx_kv::-webkit-input-placeholder {
    color: #fe3514
}

.input_input__JVjrL.input_hasError__Jx_kv::placeholder {
    color: #fe3514
}

.input_input__JVjrL.input_hasError__Jx_kv+.input_required__0NfQx {
    color: #fe3514
}

.input_input__JVjrL.input_hasError__Jx_kv~.input_errorMessage__u1XlM {
    opacity: 1;
    visibility: inherit
}

.input_regular__JTDF4 .input_input__JVjrL {
    height: 4rem;
    padding: 0;
    background-color: transparent;
    border-bottom: 1px solid hsla(0,0%,100%,.15);
    font-weight: 400;
    line-height: 1.8;
    transition: color 1s cubic-bezier(.16,1.08,.38,.98),border-color 1s cubic-bezier(.16,1.08,.38,.98)
}

.input_regular__JTDF4 .input_input__JVjrL:focus {
    border-color: #fff
}

.input_regular__JTDF4 .input_input__JVjrL:not(:placeholder-shown) {
    border-color: #fff
}

.input_required__0NfQx {
    position: absolute;
    top: 50%;
    right: 2rem;
    margin-top: -.6em;
    color: #fff;
    transition: color .5s cubic-bezier(.16,1.08,.38,.98);
    pointer-events: none
}

.input_errorMessage__u1XlM {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: .5rem;
    opacity: 0;
    visibility: hidden;
    color: #fe3514;
    transition: opacity .5s cubic-bezier(.16,1.08,.38,.98),visibility .5s
}

@media(min-width: 1024px) {
    .input_errorMessage__u1XlM {
        margin-top:1rem
    }
}

.checkbox_checkbox__5rU5l {
    position: relative;
    color: var(--color-mid,#9c9c9c);
    text-align: left
}

.checkbox_box__H7hXn {
    display: inline-flex;
    position: relative;
    width: 16px;
    height: 16px;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    margin-top: .3rem;
    margin-right: .8rem;
    border: 1px solid;
    border-radius: 1px;
    transition: border .5s cubic-bezier(.16,1.08,.38,.98)
}

.checkbox_box__H7hXn i {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    background: currentcolor;
    border-radius: 50%
}

.checkbox_box__H7hXn i,.checkbox_box__H7hXn svg {
    opacity: 0;
    color: currentcolor;
    -webkit-transform: translate(-50%,-50%) scale(.3);
    transform: translate(-50%,-50%) scale(.3);
    transition: opacity .3s cubic-bezier(.16,1.08,.38,.98),-webkit-transform .3s cubic-bezier(.16,1.08,.38,.98);
    transition: opacity .3s cubic-bezier(.16,1.08,.38,.98),transform .3s cubic-bezier(.16,1.08,.38,.98);
    transition: opacity .3s cubic-bezier(.16,1.08,.38,.98),transform .3s cubic-bezier(.16,1.08,.38,.98),-webkit-transform .3s cubic-bezier(.16,1.08,.38,.98)
}

.checkbox_label__v2pFv {
    display: inline-flex;
    align-items: flex-start;
    /* font-family: NeueHaasGroteskDisplay,Helvetica Neue,helvetica,arial,sans-serif; */
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    transition: color .5s cubic-bezier(.16,1.08,.38,.98);
    white-space: pre-wrap
}

.checkbox_label__v2pFv a,.checkbox_label__v2pFv button {
    font-size: inherit;
    color: var(--color,#fff)!important;
    text-decoration: underline
}

.checkbox_input__PzERz {
    position: absolute;
    top: 50%;
    left: 0;
    opacity: 0;
    pointer-events: none
}

.checkbox_input__PzERz.checkbox_hasError___5RcI+.checkbox_label__v2pFv {
    color: #fe3514
}

.checkbox_input__PzERz.checkbox_hasError___5RcI+.checkbox_label__v2pFv.checkbox_box__H7hXn {
    border-color: #fe3514
}

.checkbox_input__PzERz.checkbox_hasError___5RcI:valid+.checkbox_label__v2pFv {
    color: inherit
}

.checkbox_input__PzERz.checkbox_hasError___5RcI:valid+.checkbox_label__v2pFv.checkbox_box__H7hXn {
    border-color: inherit
}

.checkbox_input__PzERz:disabled+.checkbox_label__v2pFv {
    opacity: .6
}

.checkbox_input__PzERz:focus+.checkbox_label__v2pFv .checkbox_box__H7hXn {
    outline: 1px solid currentcolor
}

.checkbox_input__PzERz:checked+.checkbox_label__v2pFv .checkbox_box__H7hXn i,.checkbox_input__PzERz:checked+.checkbox_label__v2pFv .checkbox_box__H7hXn svg {
    opacity: 1;
    -webkit-transform: translate(-50%,-50%) scale(1) translateZ(0);
    transform: translate(-50%,-50%) scale(1) translateZ(0)
}

.form_form__2cZM3 {
    position: relative
}

@media(min-width: 1024px) {
    .form_form__2cZM3 {
        width:64.5067873303rem
    }
}

.form_form__2cZM3>* {
    margin-bottom: 1.5rem
}

.form_form__2cZM3>:last-child {
    margin-bottom: 0
}

.form_form__2cZM3 .form_line__G_Y9Q {
    display: flex
}

.form_form__2cZM3 .form_line__G_Y9Q>* {
    width: 50%;
    margin-right: 1rem
}

.form_form__2cZM3 .form_line__G_Y9Q>:last-child {
    margin-right: 0
}

.form_form__2cZM3 .form_cta__WpO5a {
    width: 100%
}

.form_formError__Fu4C6,.form_formSuccess__i1I10 {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%
}

.form_formError__Fu4C6 {
    color: #fe3514
}

.customPortableText_h1__zTTr6 {
    width: 100%;
    margin-bottom: 3.5rem
}

@media(min-width: 1024px) {
    .customPortableText_h1__zTTr6 {
        margin-bottom:4.5rem
    }
}

.customPortableText_h2__PBbIR,.customPortableText_h3__HEmin,.customPortableText_h4__zoe7a {
    margin-bottom: 2.3rem
}

@media(min-width: 1024px) {
    .customPortableText_h2__PBbIR,.customPortableText_h3__HEmin,.customPortableText_h4__zoe7a {
        margin-bottom:2rem
    }
}

.customPortableText_p__b90LB {
    margin-bottom: 3rem
}

.customPortableText_a___Y_Ak {
    transition: color .5s cubic-bezier(.26,1.04,.54,1);
    text-decoration: underline
}

@media(hover: hover) {
    .customPortableText_a___Y_Ak:hover {
        color:var(--color)
    }
}

.customPortableText_a___Y_Ak:active {
    color: var(--color)
}

.customPortableText_blockquote__7wvlu:last-child,.customPortableText_p__b90LB:last-child {
    margin-bottom: 0
}

.customPortableText_list__KzCTZ {
    margin-bottom: 2em;
    padding-left: 1.2em;
    list-style-type: disc
}

.customPortableText_list__KzCTZ li {
    margin-bottom: 1em
}

.customPortableText_list__KzCTZ li:last-child {
    margin-bottom: 0
}

.FormNewsletter_formNewsletter__WJtsZ {
    margin: 6rem 0
}

@media(min-width: 1024px) {
    .FormNewsletter_formNewsletter__WJtsZ {
        margin:12rem 0
    }
}

.FormNewsletter_title___vdsi {
    margin-bottom: 4rem
}

@media(min-width: 1024px) {
    .FormNewsletter_title___vdsi {
        margin-bottom:6rem
    }
}

.FormNewsletter_inputWrapper__cD1Sg {
    position: relative
}

.FormNewsletter_input__s0COe {
    width: 100%;
    height: 5rem!important;
    padding-right: 5rem!important;
    font-size: 3rem!important;
    color: #fff
}

@media(min-width: 1024px) {
    .FormNewsletter_input__s0COe {
        height:9.2rem!important;
        padding-top: 3.2rem!important;
        padding-right: 5rem!important;
        padding-bottom: 2.2rem!important
    }
}

.FormNewsletter_input__s0COe::-webkit-input-placeholder {
    /* font-family: Hellix,helvetica,arial,sans-serif; */
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: -.04em
}

.FormNewsletter_input__s0COe::placeholder {
    /* font-family: Hellix,helvetica,arial,sans-serif; */
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: -.04em
}

@media(min-width: 1024px) {
    .FormNewsletter_input__s0COe::-webkit-input-placeholder {
        font-size:8rem
    }

    .FormNewsletter_input__s0COe::placeholder {
        font-size: 8rem
    }
}

.FormNewsletter_cta__pJrWz {
    position: absolute!important;
    top: 50%;
    right: 0;
    flex-shrink: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.FormNewsletter_optin__7EvmZ {
    margin-top: 3rem
}

.FormNewsletter_error___Z9nA {
    position: absolute;
    right: 0;
    bottom: 1rem;
    left: auto;
    width: auto
}

@media(min-width: 1024px) {
    .FormNewsletter_error___Z9nA {
        bottom:.5rem
    }
}

.footer_footer__ZK5Q_ {
    position: relative;
    width: 100%;
    background:#000;
    /* border-top: 1px solid hsla(0,0%,100%,.15); */
    color: #fff
}

/* .footer_footerWrapper__8ZwIU {
    padding: 6rem 0 4rem;
    border-top: 1px solid hsla(0,0%,100%,.15)
}

@media(min-width: 1024px) {
    .footer_footerWrapper__8ZwIU {
        padding:15rem 0 8rem
    }
} */

.footer_top__Upalj {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between
}

.footer_logo__bA1ZD {
    width: 8.2rem;
    height: 1.6rem
}

@media(min-width: 1024px) {
    .footer_logo__bA1ZD {
        width:12.8rem;
        height: 2rem
    }
}

.footer_list__LZhug {
    width: 44.4vw
}

@media(min-width: 1024px) {
    .footer_list__LZhug {
        width:30.7601809955rem;
        margin-left: 2.9864253394rem
    }

    .footer_list__LZhug:last-child {
        width: 19.6113122172rem
    }
}

.footer_title__UAn4g {
    margin-bottom: 2rem
}

.footer_address__kPUn_ {
    display: block;
    margin-bottom: 2rem
}

.footer_nav__XNGc9 {
    display: flex;
    line-height: 1.1!important
}

@media(min-width: 1024px) {
    .footer_nav__XNGc9 {
        margin-left:auto
    }
}

@media(min-width: 0px)and (max-width:1023px) {
    .footer_socialLinks__6g32K {
        flex:0 0 16.65vw;
        padding-right: 0
    }
}

.footer_bottom__BZmGy {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4rem;
    color: #fff
}

@media(min-width: 1024px) {
    .footer_bottom__BZmGy {
        justify-content:flex-start;
        margin-top: 6rem;
        padding-bottom: .4rem
    }
}

.footer_bottom__BZmGy .footer_link__j_gJ3 {
    transition: color .5s cubic-bezier(.16,1.08,.38,.98);
    text-decoration: underline
}

@media(hover: hover) {
    .footer_bottom__BZmGy .footer_link__j_gJ3:hover {
        color:#fff;
        transition-duration: 0s
    }
}

.footer_bottom__BZmGy .footer_link__j_gJ3:active {
    color: #fff;
    transition-duration: 0s
}

.footer_ctas__RyKKY {
    margin-right: auto
}

@media(min-width: 0px)and (max-width:1023px) {
    .footer_ctas__RyKKY {
        margin-bottom:5rem
    }

    .footer_cta__LC3m2 {
        width: 100%;
        margin-bottom: 1rem
    }
}

@media(min-width: 1024px) {
    .footer_cta__LC3m2 {
        margin-right:1rem
    }
}

.footer_legalsWrapper__cIBFX {
    color: #9c9c9c
}

@media(min-width: 1024px) {
    .footer_legalsWrapper__cIBFX {
        width:30.7601809955rem
    }
}

.footer_credits__0Tj7b {
    color: #9c9c9c
}

@media(min-width: 1024px) {
    .footer_credits__0Tj7b {
        width:19.5113122172rem;
        margin-left: 2.9864253394rem
    }
}

.alert_wrapper__Kgoj7 {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none
}

.alert_wrapper__Kgoj7::-webkit-scrollbar {
    display: none
}

.alert_alert___c285 {
    display: none;
    position: relative;
    width: 100%;
    padding: 1.5rem 5.55vw;
    background: #262626;
    font-size: 1.2rem;
    line-height: 1.7;
    color: #fff;
    -webkit-transform-origin: -.4rem 2.5rem;
    transform-origin: -.4rem 2.5rem;
    letter-spacing: .1em
}

@media(min-width: 1024px) {
    .alert_alert___c285 {
        display:block;
        position: fixed;
        z-index: 1000;
        top: 4rem;
        left: 6rem;
        width: 30.7601809955rem;
        margin-top: clamp(54px,6rem,72px);
        padding: 3rem 4rem 3rem 3rem;
        border-radius: .8rem
    }
}

@media(min-width: 0px)and (max-width:1023px) {
    .alert_alert___c285:after {
        content:"";
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 8rem;
        height: 100%;
        background: linear-gradient(90deg,rgba(38,38,38,0),#262626 40%,#262626 90%)
    }
}

@media(min-width: 1024px) {
    .alert_alert___c285:before {
        content:"";
        display: block;
        position: absolute;
        top: 0;
        left: 2.4rem;
        width: 1.4rem;
        height: 1.4rem;
        background: #262626;
        border-radius: .2rem;
        -webkit-transform: translateY(-50%) rotate(45deg);
        transform: translateY(-50%) rotate(45deg)
    }
}

.alert_wrapper__Kgoj7 {
    width: 100%
}

.alert_title__q3Yie {
    margin-bottom: 1rem
}

.alert_text__Q1TGx {
    color: #9c9c9c
}

@media(min-width: 0px)and (max-width:1023px) {
    .alert_text__Q1TGx p {
        white-space:nowrap
    }
}

.alert_text__Q1TGx a {
    color: #fff!important
}

.alert_btnClose__CYpZ8 {
    position: absolute;
    z-index: 2;
    top: 1rem;
    right: 2.1rem;
    padding: 1rem;
    opacity: .8;
    transition: opacity .5s cubic-bezier(.26,1.04,.54,1)
}

@media(min-width: 1024px) {
    .alert_btnClose__CYpZ8 {
        top:1rem;
        right: 1rem
    }
}

.alert_btnClose__CYpZ8 svg {
    width: .8rem;
    height: .8rem;
    stroke: currentcolor
}

@media(hover: hover) {
    .alert_btnClose__CYpZ8:hover {
        opacity:1;
        transition-duration: 0s
    }
}

.alert_btnClose__CYpZ8:active {
    opacity: 1;
    transition-duration: 0s
}

.layout_newsletter__ISmg1 {
    position: fixed!important;
    z-index: 1000!important
}

.layout_transition__K5Kvb {
    display: flex;
    flex-direction: column;
    min-height: var(--vh)
}

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
    display: block
}

body {
    line-height: 1
}

ol,ul {
    list-style: none
}

blockquote,q {
    quotes: none
}

blockquote:after,blockquote:before,q:after,q:before {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

*,:after,:before {
    box-sizing: inherit;
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

.container {
    /* max-width:1920px; */
    /* margin-right:auto; */
    /* margin-left:auto; */
    width: 88.8vw;
}

@media(min-width: 600px)and (max-width:1023px) {
    .container {
        width:88.8vw
    }
}

@media(min-width: 1024px) {
    .container {
        width:132rem;
    }
}


::selection {
    background: rgba(38,38,38,.7);
    color: #fff
}

html {
    --sbw: 0px;
    font-size: 2.6666666667vw;
    font-size: calc(2.6666666667vw - var(--sbw) / 37.5)
}

@media(min-width: 0px)and (max-width:1023px) {
    html {
        scroll-behavior:smooth
    }
}

@media(min-width: 600px)and (max-width:1023px) {
    html {
        font-size:1.6666666667vw;
        font-size: calc(1.6666666667vw - var(--sbw) / 60)
    }
}

@media(min-width: 1024px) {
    html {
        font-size:.6944444444vw;
        font-size: calc(.6944444444vw - var(--sbw) / 144);
        font-size: min(calc(.6944444444vw - var(--sbw) / 144),13.3333333333px)
    }
}

body {
    overflow-x: hidden;
    overflow-y: scroll;
    background: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}

@media(min-width: 1024px) {
    .mobile-only {
        display:none!important
    }
}

@media(min-width: 0px)and (max-width:1023px) {
    .desktop-only {
        display:none!important
    }
}

.h,.hd-140,.hd-240,.hd-30,.hd-80,.hm-100,.hm-24,.hm-36,.hm-50,.hm-70 {
    /* font-family: Hellix,helvetica,arial,sans-serif; */
    line-height: .9;
    white-space: pre-wrap;
    letter-spacing: -.04em
}

.hm-100 {
    font-size: 10rem
}

.hm-70 {
    font-size: 7rem;
    line-height: .77
}

.hm-50 {
    font-size: 5rem
}

.hm-36 {
    font-size: 3.6rem
}

.hm-24 {
    font-size: 2.4rem
}

@media(min-width: 1024px) {
    .hd-240 {
        font-size:24rem
    }
}

.hd-140 {
    line-height: .8
}

@media(min-width: 1024px) {
    .hd-140 {
        font-size:14rem
    }
}

.hd-80 {
    line-height: .8
}

@media(min-width: 1024px) {
    .hd-80 {
        font-size:8rem
    }

    .hd-30 {
        font-size: 3rem
    }
}

.p,.pd-14,.pd-20,.pm-12,.pm-14,.pm-16,.pm-20 {
    /* font-family: NeueHaasGroteskDisplay,Helvetica Neue,helvetica,arial,sans-serif; */
    line-height: 1.4;
    white-space: pre-wrap
}

.pm-20 {
    font-size: clamp(18px,2rem,24px)
}

.pm-16 {
    font-size: clamp(14.4px,1.6rem,19.2px)
}

.pm-12 {
    font-size: clamp(10.8px,1.2rem,14.4px)
}

.pm-14 {
    font-size: clamp(12.6px,1.4rem,16.8px)
}

@media(min-width: 1024px) {
    .pd-20 {
        font-size:clamp(16.2px,1.8rem,21.6px)
    }

    .pd-14 {
        font-size: clamp(12.6px,1.4rem,16.8px)
    }
}

.black {
    color: #000
}

.anthracite {
    color: #0e0e0e
}

.white {
    color: #fff
}

.grey {
    color: #9c9c9c
}

.grey-light {
    color: #f4f4f4
}

.grey-dark {
    color: #262626
}

.purple {
    color: #781edc
}

.red {
    color: #fe3514
}

.semibold {
    font-weight: 500
}

.bold {
    font-weight: 700
}

.label,.upper {
    text-transform: uppercase
}

.label {
    /* font-family: NeueHaasGroteskDisplay,Helvetica Neue,helvetica,arial,sans-serif; */
    font-size: clamp(10.8px,1.2rem,14.4px);
    font-weight: 500;
    letter-spacing: .05em
}

a {
    text-decoration: none
}

a,a:visited {
    color: currentcolor
}

.a--underlined {
    display: inline-block;
    position: relative;
    overflow: hidden;
    overflow: clip
}

.a--underlined:after {
    content: "";
    display: block;
    top: 100%;
    height: 1px;
    margin-top: 1px;
    background: currentcolor;
    -webkit-transform: translate3d(calc(-100% - 1px),0,0);
    transform: translate3d(calc(-100% - 1px),0,0);
    transition: -webkit-transform .6s cubic-bezier(.77,0,.175,1);
    transition: transform .6s cubic-bezier(.77,0,.175,1);
    transition: transform .6s cubic-bezier(.77,0,.175,1),-webkit-transform .6s cubic-bezier(.77,0,.175,1);
    pointer-events: none
}

.a--underlined.isActive:after {
    -webkit-transform: none;
    transform: none;
    transition-timing-function: cubic-bezier(.16,1.08,.38,.98)
}

@media(hover: hover) {
    .a--underlined:hover:after {
        -webkit-transform:none;
        transform: none;
        transition-timing-function: cubic-bezier(.16,1.08,.38,.98)
    }
}

.a--underlined:active:after {
    -webkit-transform: none;
    transform: none;
    transition-timing-function: cubic-bezier(.16,1.08,.38,.98)
}

.button_btn__EuPxb .footersosvg{
  width: 5.5vw;
  /* height: 20px; */
  text-align: left;
}
.footersosvga{
  width: 15vw;
}


.button_btn__EuPxb.button_rounded__Aad7d:nth-child(1){
  /* opacity: 0; */
}
@media (max-width: 800.98px) {
.button_btn__EuPxb .footersosvg{
  width: 60px;
}
.footersosvga{
  width: 150px;
}
.button_btn__EuPxb.button_rounded__Aad7d:nth-child(1){
  /* opacity: 0; */
}
}

.hidennformgg h1,button{
  position: absolute;
  margin-top: 10rem;
}
.hiddnfb{
  background-color: black;
  width: 200px;
  color: white;
  height: 50px;
}

.error-message{
color: #e74c3c;
font-size: 14px;
margin-top: 5px;
display: block;
}


/* Default style for the button */
.hiddnfb {
  position: absolute;
  margin-top: 45rem; /* Default margin-top */
}

/* Media query for screens with max-width: 900px */
@media (max-width: 900px) {
  .hiddnfb {
    margin-top: 58rem; /* Margin-top for screens with max-width: 900px or smaller */
  }
}
