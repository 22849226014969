.wrapper {
   
    /* margin-left: rem; */
    align-items: center;
}
.wrapper .text-width-hero{
    margin-left: 2vw;
    margin-top: 15rem;
}
/* Add this CSS code to your stylesheet */
.img-child.scroll-img {
    transition: transform 0.9s ease;
  }
  
  .img-child.scroll-img:hover {
    transform: scale(1.1);
  }
  
/* Add this CSS code to your stylesheet */
.span-text.intro-one {
  display: inline-block;
  overflow: hidden;
}

.span-text.intro-one > span {
  display: inline-block;
  transform-origin: top;
  transform: translateY(100%);
  transition: transform 0.9s ease;
  font-size: 10rem;
  animation: riseAnimation 0.9s ease both;
  font-family: Helveticaneue,sans-serif;
  
}
.span-text.intro-two > span {
  display: inline-block;
  transform-origin: top;
  transform: translateY(100%);
  transition: transform 0.9s ease;
  animation: riseAnimation 0.9s ease both;
  font-size: 6vw;
  font-family: Helveticaneue,sans-serif;
  /* margin-bottom: 5rem; */
}
.span-text.intro-three > span {
  font-size: 10rem;
  font-family: Helveticaneue,sans-serif;
}
/* @keyframes rise {
  to {
    transform: translateY(0);
  }
} */
  /* YourComponent.css */
.word-spacing {
  margin-right: 0.5rem; /* Adjust the margin as needed */
}
@keyframes riseAnimation {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.rise-animation {
  animation: riseAnimation 1.5s ease-in-out forwards;
}
@media screen and (max-width: 991px){

  .wrapper .text-width-hero{
   margin-top: 12rem;
}
.span-text.intro-three > span {
  /* margin-bottom: 5rem; */
  /* margin-top: -8rem; */
  font-size: 9vw;
}
.span-text.intro-one > span {
  font-size: 9vw;
}

}
/* .html{
  animation: growIn 2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  transform: scale(.9);
}
@keyframes growIn {
  to {
      transform: scale(1);
  }
} */