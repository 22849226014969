* {
  border: 0;
  outline: 0;
  padding: 0;
  text-decoration: none
}

*,:after,:before {
  box-sizing: border-box;
  margin: 0
}

body {
  scroll-behavior: smooth
}

body.is-scroll-blocked {
  overflow: hidden
}

button {
  background: none
}

a,button {
  color: inherit
}

img,svg {
  display: inline-block
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  word-wrap: break-word;
  text-rendering: optimizeSpeed;
  font-kerning: normal;
  font-size: 16px
}

.text-left {
  text-align: left
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

.container {
  --o-gutter: 1rem;
  margin-left: auto;
  margin-right: auto;
  min-height: 1px;
  padding-left: var(--o-gutter);
  padding-right: var(--o-gutter);
  position: relative;
  width: 100%;

}

.row {
  --o-gutter: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--o-gutter)*-1)
}

.col {
  min-height: 1px;
  padding: var(--o-gutter);
  position: relative;
  width: 100%
}

.flex {
  display: flex
}

.align-items-start {
  align-items: flex-start
}

.align-items-center {
  align-items: center
}

.align-items-end {
  align-items: flex-end
}

.justify-content-center {
  justify-content: center
}

.justify-content-space-between {
  justify-content: space-between
}

.justify-content-start {
  justify-content: flex-start
}

.justify-content-end {
  justify-content: flex-end
}

.hidden-down {
  display: none!important
}

.col {
  flex: 1 0 0%
}

.hidden-up {
  display: none!important
}

.row-cols-auto>.col {
  flex: 0 0 auto;
  width: auto
}

.row-cols-1>.col {
  flex: 0 0 auto;
  width: 100%
}

.row-cols-2>.col {
  flex: 0 0 auto;
  width: 50%
}

.row-cols-3>.col {
  flex: 0 0 auto;
  width: 33.3333333333%
}

.row-cols-4>.col {
  flex: 0 0 auto;
  width: 25%
}

.row-cols-5>.col {
  flex: 0 0 auto;
  width: 20%
}

.row-cols-6>.col {
  flex: 0 0 auto;
  width: 16.6666666667%
}

.col-auto {
  flex: 0 0 auto;
  width: auto
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%
}

.col-3 {
  flex: 0 0 auto;
  width: 25%
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%
}

.col-6 {
  flex: 0 0 auto;
  width: 50%
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%
}

.col-9 {
  flex: 0 0 auto;
  width: 75%
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%
}

.col-12 {
  flex: 0 0 auto;
  width: 100%
}

.offset-1 {
  margin-left: 8.3333333333%
}

.offset-2 {
  margin-left: 16.6666666667%
}

.offset-3 {
  margin-left: 25%
}

.offset-4 {
  margin-left: 33.3333333333%
}

.offset-5 {
  margin-left: 41.6666666667%
}

.offset-6 {
  margin-left: 50%
}

.offset-7 {
  margin-left: 58.3333333333%
}

.offset-8 {
  margin-left: 66.6666666667%
}

.offset-9 {
  margin-left: 75%
}

.offset-10 {
  margin-left: 83.3333333333%
}

.offset-11 {
  margin-left: 91.6666666667%
}

.g-0,.gx-0 {
  --o-gutter-x: 0
}

.g-0,.gy-0 {
  --o-gutter-y: 0
}

.g-1,.gx-1 {
  --o-gutter-x: .25rem
}

.g-1,.gy-1 {
  --o-gutter-y: .25rem
}

.g-2,.gx-2 {
  --o-gutter-x: .5rem
}

.g-2,.gy-2 {
  --o-gutter-y: .5rem
}

.g-3,.gx-3 {
  --o-gutter-x: 1rem
}

.g-3,.gy-3 {
  --o-gutter-y: 1rem
}

.g-4,.gx-4 {
  --o-gutter-x: 1.5rem
}

.g-4,.gy-4 {
  --o-gutter-y: 1.5rem
}

.g-5,.gx-5 {
  --o-gutter-x: 3rem
}

.g-5,.gy-5 {
  --o-gutter-y: 3rem
}

@media (max-width: 575.98px) {
  .hidden-sm-down {
      display:none!important
  }
}

@media (min-width: 576px) {
  .col-sm {
      flex:1 0 0%
  }

  .hidden-sm-up {
      display: none!important
  }

  .row-cols-sm-auto>.col {
      flex: 0 0 auto;
      width: auto
  }

  .row-cols-sm-1>.col {
      flex: 0 0 auto;
      width: 100%
  }

  .row-cols-sm-2>.col {
      flex: 0 0 auto;
      width: 50%
  }

  .row-cols-sm-3>.col {
      flex: 0 0 auto;
      width: 33.3333333333%
  }

  .row-cols-sm-4>.col {
      flex: 0 0 auto;
      width: 25%
  }

  .row-cols-sm-5>.col {
      flex: 0 0 auto;
      width: 20%
  }

  .row-cols-sm-6>.col {
      flex: 0 0 auto;
      width: 16.6666666667%
  }

  .col-sm-auto {
      flex: 0 0 auto;
      width: auto
  }

  .col-sm-1 {
      flex: 0 0 auto;
      width: 8.3333333333%
  }

  .col-sm-2 {
      flex: 0 0 auto;
      width: 16.6666666667%
  }

  .col-sm-3 {
      flex: 0 0 auto;
      width: 25%
  }

  .col-sm-4 {
      flex: 0 0 auto;
      width: 33.3333333333%
  }

  .col-sm-5 {
      flex: 0 0 auto;
      width: 41.6666666667%
  }

  .col-sm-6 {
      flex: 0 0 auto;
      width: 50%
  }

  .col-sm-7 {
      flex: 0 0 auto;
      width: 58.3333333333%
  }

  .col-sm-8 {
      flex: 0 0 auto;
      width: 66.6666666667%
  }

  .col-sm-9 {
      flex: 0 0 auto;
      width: 75%
  }

  .col-sm-10 {
      flex: 0 0 auto;
      width: 83.3333333333%
  }

  .col-sm-11 {
      flex: 0 0 auto;
      width: 91.6666666667%
  }

  .col-sm-12 {
      flex: 0 0 auto;
      width: 100%
  }

  .offset-sm-0 {
      margin-left: 0
  }

  .offset-sm-1 {
      margin-left: 8.3333333333%
  }

  .offset-sm-2 {
      margin-left: 16.6666666667%
  }

  .offset-sm-3 {
      margin-left: 25%
  }

  .offset-sm-4 {
      margin-left: 33.3333333333%
  }

  .offset-sm-5 {
      margin-left: 41.6666666667%
  }

  .offset-sm-6 {
      margin-left: 50%
  }

  .offset-sm-7 {
      margin-left: 58.3333333333%
  }

  .offset-sm-8 {
      margin-left: 66.6666666667%
  }

  .offset-sm-9 {
      margin-left: 75%
  }

  .offset-sm-10 {
      margin-left: 83.3333333333%
  }

  .offset-sm-11 {
      margin-left: 91.6666666667%
  }

  .g-sm-0,.gx-sm-0 {
      --o-gutter-x: 0
  }

  .g-sm-0,.gy-sm-0 {
      --o-gutter-y: 0
  }

  .g-sm-1,.gx-sm-1 {
      --o-gutter-x: .25rem
  }

  .g-sm-1,.gy-sm-1 {
      --o-gutter-y: .25rem
  }

  .g-sm-2,.gx-sm-2 {
      --o-gutter-x: .5rem
  }

  .g-sm-2,.gy-sm-2 {
      --o-gutter-y: .5rem
  }

  .g-sm-3,.gx-sm-3 {
      --o-gutter-x: 1rem
  }

  .g-sm-3,.gy-sm-3 {
      --o-gutter-y: 1rem
  }

  .g-sm-4,.gx-sm-4 {
      --o-gutter-x: 1.5rem
  }

  .g-sm-4,.gy-sm-4 {
      --o-gutter-y: 1.5rem
  }

  .g-sm-5,.gx-sm-5 {
      --o-gutter-x: 3rem
  }

  .g-sm-5,.gy-sm-5 {
      --o-gutter-y: 3rem
  }
}

@media (max-width: 767.98px) {
  .hidden-md-down {
      display:none!important
  }
}

@media (min-width: 768px) {
  .col-md {
      flex:1 0 0%
  }

  .hidden-md-up {
      display: none!important
  }

  .row-cols-md-auto>.col {
      flex: 0 0 auto;
      width: auto
  }

  .row-cols-md-1>.col {
      flex: 0 0 auto;
      width: 100%
  }

  .row-cols-md-2>.col {
      flex: 0 0 auto;
      width: 50%
  }

  .row-cols-md-3>.col {
      flex: 0 0 auto;
      width: 33.3333333333%
  }

  .row-cols-md-4>.col {
      flex: 0 0 auto;
      width: 25%
  }

  .row-cols-md-5>.col {
      flex: 0 0 auto;
      width: 20%
  }

  .row-cols-md-6>.col {
      flex: 0 0 auto;
      width: 16.6666666667%
  }

  .col-md-auto {
      flex: 0 0 auto;
      width: auto
  }

  .col-md-1 {
      flex: 0 0 auto;
      width: 8.3333333333%
  }

  .col-md-2 {
      flex: 0 0 auto;
      width: 16.6666666667%
  }

  .col-md-3 {
      flex: 0 0 auto;
      width: 25%
  }

  .col-md-4 {
      flex: 0 0 auto;
      width: 33.3333333333%
  }

  .col-md-5 {
      flex: 0 0 auto;
      width: 41.6666666667%
  }

  .col-md-6 {
      flex: 0 0 auto;
      width: 50%
  }

  .col-md-7 {
      flex: 0 0 auto;
      width: 58.3333333333%
  }

  .col-md-8 {
      flex: 0 0 auto;
      width: 66.6666666667%
  }

  .col-md-9 {
      flex: 0 0 auto;
      width: 75%
  }

  .col-md-10 {
      flex: 0 0 auto;
      width: 83.3333333333%
  }

  .col-md-11 {
      flex: 0 0 auto;
      width: 91.6666666667%
  }

  .col-md-12 {
      flex: 0 0 auto;
      width: 100%
  }

  .offset-md-0 {
      margin-left: 0
  }

  .offset-md-1 {
      margin-left: 8.3333333333%
  }

  .offset-md-2 {
      margin-left: 16.6666666667%
  }

  .offset-md-3 {
      margin-left: 25%
  }

  .offset-md-4 {
      margin-left: 33.3333333333%
  }

  .offset-md-5 {
      margin-left: 41.6666666667%
  }

  .offset-md-6 {
      margin-left: 50%
  }

  .offset-md-7 {
      margin-left: 58.3333333333%
  }

  .offset-md-8 {
      margin-left: 66.6666666667%
  }

  .offset-md-9 {
      margin-left: 75%
  }

  .offset-md-10 {
      margin-left: 83.3333333333%
  }

  .offset-md-11 {
      margin-left: 91.6666666667%
  }

  .g-md-0,.gx-md-0 {
      --o-gutter-x: 0
  }

  .g-md-0,.gy-md-0 {
      --o-gutter-y: 0
  }

  .g-md-1,.gx-md-1 {
      --o-gutter-x: .25rem
  }

  .g-md-1,.gy-md-1 {
      --o-gutter-y: .25rem
  }

  .g-md-2,.gx-md-2 {
      --o-gutter-x: .5rem
  }

  .g-md-2,.gy-md-2 {
      --o-gutter-y: .5rem
  }

  .g-md-3,.gx-md-3 {
      --o-gutter-x: 1rem
  }

  .g-md-3,.gy-md-3 {
      --o-gutter-y: 1rem
  }

  .g-md-4,.gx-md-4 {
      --o-gutter-x: 1.5rem
  }

  .g-md-4,.gy-md-4 {
      --o-gutter-y: 1.5rem
  }

  .g-md-5,.gx-md-5 {
      --o-gutter-x: 3rem
  }

  .g-md-5,.gy-md-5 {
      --o-gutter-y: 3rem
  }
}

@media (max-width: 991.98px) {
  .hidden-lg-down {
      display:none!important
  }
}

@media (min-width: 992px) {
  .col-lg {
      flex:1 0 0%
  }

  .hidden-lg-up {
      display: none!important
  }

  .row-cols-lg-auto>.col {
      flex: 0 0 auto;
      width: auto
  }

  .row-cols-lg-1>.col {
      flex: 0 0 auto;
      width: 100%
  }

  .row-cols-lg-2>.col {
      flex: 0 0 auto;
      width: 50%
  }

  .row-cols-lg-3>.col {
      flex: 0 0 auto;
      width: 33.3333333333%
  }

  .row-cols-lg-4>.col {
      flex: 0 0 auto;
      width: 25%
  }

  .row-cols-lg-5>.col {
      flex: 0 0 auto;
      width: 20%
  }

  .row-cols-lg-6>.col {
      flex: 0 0 auto;
      width: 16.6666666667%
  }

  .col-lg-auto {
      flex: 0 0 auto;
      width: auto
  }

  .col-lg-1 {
      flex: 0 0 auto;
      width: 8.3333333333%
  }

  .col-lg-2 {
      flex: 0 0 auto;
      width: 16.6666666667%
  }

  .col-lg-3 {
      flex: 0 0 auto;
      width: 25%
  }

  .col-lg-4 {
      flex: 0 0 auto;
      width: 33.3333333333%
  }

  .col-lg-5 {
      flex: 0 0 auto;
      width: 41.6666666667%
  }

  .col-lg-6 {
      flex: 0 0 auto;
      width: 50%
  }

  .col-lg-7 {
      flex: 0 0 auto;
      width: 58.3333333333%
  }

  .col-lg-8 {
      flex: 0 0 auto;
      width: 66.6666666667%
  }

  .col-lg-9 {
      flex: 0 0 auto;
      width: 75%
  }

  .col-lg-10 {
      flex: 0 0 auto;
      width: 83.3333333333%
  }

  .col-lg-11 {
      flex: 0 0 auto;
      width: 91.6666666667%
  }

  .col-lg-12 {
      flex: 0 0 auto;
      width: 100%
  }

  .offset-lg-0 {
      margin-left: 0
  }

  .offset-lg-1 {
      margin-left: 8.3333333333%
  }

  .offset-lg-2 {
      margin-left: 16.6666666667%
  }

  .offset-lg-3 {
      margin-left: 25%
  }

  .offset-lg-4 {
      margin-left: 33.3333333333%
  }

  .offset-lg-5 {
      margin-left: 41.6666666667%
  }

  .offset-lg-6 {
      margin-left: 50%
  }

  .offset-lg-7 {
      margin-left: 58.3333333333%
  }

  .offset-lg-8 {
      margin-left: 66.6666666667%
  }

  .offset-lg-9 {
      margin-left: 75%
  }

  .offset-lg-10 {
      margin-left: 83.3333333333%
  }

  .offset-lg-11 {
      margin-left: 91.6666666667%
  }

  .g-lg-0,.gx-lg-0 {
      --o-gutter-x: 0
  }

  .g-lg-0,.gy-lg-0 {
      --o-gutter-y: 0
  }

  .g-lg-1,.gx-lg-1 {
      --o-gutter-x: .25rem
  }

  .g-lg-1,.gy-lg-1 {
      --o-gutter-y: .25rem
  }

  .g-lg-2,.gx-lg-2 {
      --o-gutter-x: .5rem
  }

  .g-lg-2,.gy-lg-2 {
      --o-gutter-y: .5rem
  }

  .g-lg-3,.gx-lg-3 {
      --o-gutter-x: 1rem
  }

  .g-lg-3,.gy-lg-3 {
      --o-gutter-y: 1rem
  }

  .g-lg-4,.gx-lg-4 {
      --o-gutter-x: 1.5rem
  }

  .g-lg-4,.gy-lg-4 {
      --o-gutter-y: 1.5rem
  }

  .g-lg-5,.gx-lg-5 {
      --o-gutter-x: 3rem
  }

  .g-lg-5,.gy-lg-5 {
      --o-gutter-y: 3rem
  }
}

@media (max-width: 1199.98px) {
  .hidden-xl-down {
      display:none!important
  }
}

@media (min-width: 1200px) {
  .col-xl {
      flex:1 0 0%
  }

  .hidden-xl-up {
      display: none!important
  }

  .row-cols-xl-auto>.col {
      flex: 0 0 auto;
      width: auto
  }

  .row-cols-xl-1>.col {
      flex: 0 0 auto;
      width: 100%
  }

  .row-cols-xl-2>.col {
      flex: 0 0 auto;
      width: 50%
  }

  .row-cols-xl-3>.col {
      flex: 0 0 auto;
      width: 33.3333333333%
  }

  .row-cols-xl-4>.col {
      flex: 0 0 auto;
      width: 25%
  }

  .row-cols-xl-5>.col {
      flex: 0 0 auto;
      width: 20%
  }

  .row-cols-xl-6>.col {
      flex: 0 0 auto;
      width: 16.6666666667%
  }

  .col-xl-auto {
      flex: 0 0 auto;
      width: auto
  }

  .col-xl-1 {
      flex: 0 0 auto;
      width: 8.3333333333%
  }

  .col-xl-2 {
      flex: 0 0 auto;
      width: 16.6666666667%
  }

  .col-xl-3 {
      flex: 0 0 auto;
      width: 25%
  }

  .col-xl-4 {
      flex: 0 0 auto;
      width: 33.3333333333%
  }

  .col-xl-5 {
      flex: 0 0 auto;
      width: 41.6666666667%
  }

  .col-xl-6 {
      flex: 0 0 auto;
      width: 50%
  }

  .col-xl-7 {
      flex: 0 0 auto;
      width: 58.3333333333%
  }

  .col-xl-8 {
      flex: 0 0 auto;
      width: 66.6666666667%
  }

  .col-xl-9 {
      flex: 0 0 auto;
      width: 75%
  }

  .col-xl-10 {
      flex: 0 0 auto;
      width: 83.3333333333%
  }

  .col-xl-11 {
      flex: 0 0 auto;
      width: 91.6666666667%
  }

  .col-xl-12 {
      flex: 0 0 auto;
      width: 100%
  }

  .offset-xl-0 {
      margin-left: 0
  }

  .offset-xl-1 {
      margin-left: 8.3333333333%
  }

  .offset-xl-2 {
      margin-left: 16.6666666667%
  }

  .offset-xl-3 {
      margin-left: 25%
  }

  .offset-xl-4 {
      margin-left: 33.3333333333%
  }

  .offset-xl-5 {
      margin-left: 41.6666666667%
  }

  .offset-xl-6 {
      margin-left: 50%
  }

  .offset-xl-7 {
      margin-left: 58.3333333333%
  }

  .offset-xl-8 {
      margin-left: 66.6666666667%
  }

  .offset-xl-9 {
      margin-left: 75%
  }

  .offset-xl-10 {
      margin-left: 83.3333333333%
  }

  .offset-xl-11 {
      margin-left: 91.6666666667%
  }

  .g-xl-0,.gx-xl-0 {
      --o-gutter-x: 0
  }

  .g-xl-0,.gy-xl-0 {
      --o-gutter-y: 0
  }

  .g-xl-1,.gx-xl-1 {
      --o-gutter-x: .25rem
  }

  .g-xl-1,.gy-xl-1 {
      --o-gutter-y: .25rem
  }

  .g-xl-2,.gx-xl-2 {
      --o-gutter-x: .5rem
  }

  .g-xl-2,.gy-xl-2 {
      --o-gutter-y: .5rem
  }

  .g-xl-3,.gx-xl-3 {
      --o-gutter-x: 1rem
  }

  .g-xl-3,.gy-xl-3 {
      --o-gutter-y: 1rem
  }

  .g-xl-4,.gx-xl-4 {
      --o-gutter-x: 1.5rem
  }

  .g-xl-4,.gy-xl-4 {
      --o-gutter-y: 1.5rem
  }

  .g-xl-5,.gx-xl-5 {
      --o-gutter-x: 3rem
  }

  .g-xl-5,.gy-xl-5 {
      --o-gutter-y: 3rem
  }
}

@media (max-width: 1399.98px) {
  .hidden-xxl-down {
      display:none!important
  }
}

@media (min-width: 1400px) {
  .col-xxl {
      flex:1 0 0%
  }

  .hidden-xxl-up {
      display: none!important
  }

  .row-cols-xxl-auto>.col {
      flex: 0 0 auto;
      width: auto
  }

  .row-cols-xxl-1>.col {
      flex: 0 0 auto;
      width: 100%
  }

  .row-cols-xxl-2>.col {
      flex: 0 0 auto;
      width: 50%
  }

  .row-cols-xxl-3>.col {
      flex: 0 0 auto;
      width: 33.3333333333%
  }

  .row-cols-xxl-4>.col {
      flex: 0 0 auto;
      width: 25%
  }

  .row-cols-xxl-5>.col {
      flex: 0 0 auto;
      width: 20%
  }

  .row-cols-xxl-6>.col {
      flex: 0 0 auto;
      width: 16.6666666667%
  }

  .col-xxl-auto {
      flex: 0 0 auto;
      width: auto
  }

  .col-xxl-1 {
      flex: 0 0 auto;
      width: 8.3333333333%
  }

  .col-xxl-2 {
      flex: 0 0 auto;
      width: 16.6666666667%
  }

  .col-xxl-3 {
      flex: 0 0 auto;
      width: 25%
  }

  .col-xxl-4 {
      flex: 0 0 auto;
      width: 33.3333333333%
  }

  .col-xxl-5 {
      flex: 0 0 auto;
      width: 41.6666666667%
  }

  .col-xxl-6 {
      flex: 0 0 auto;
      width: 50%
  }

  .col-xxl-7 {
      flex: 0 0 auto;
      width: 58.3333333333%
  }

  .col-xxl-8 {
      flex: 0 0 auto;
      width: 66.6666666667%
  }

  .col-xxl-9 {
      flex: 0 0 auto;
      width: 75%
  }

  .col-xxl-10 {
      flex: 0 0 auto;
      width: 83.3333333333%
  }

  .col-xxl-11 {
      flex: 0 0 auto;
      width: 91.6666666667%
  }

  .col-xxl-12 {
      flex: 0 0 auto;
      width: 100%
  }

  .offset-xxl-0 {
      margin-left: 0
  }

  .offset-xxl-1 {
      margin-left: 8.3333333333%
  }

  .offset-xxl-2 {
      margin-left: 16.6666666667%
  }

  .offset-xxl-3 {
      margin-left: 25%
  }

  .offset-xxl-4 {
      margin-left: 33.3333333333%
  }

  .offset-xxl-5 {
      margin-left: 41.6666666667%
  }

  .offset-xxl-6 {
      margin-left: 50%
  }

  .offset-xxl-7 {
      margin-left: 58.3333333333%
  }

  .offset-xxl-8 {
      margin-left: 66.6666666667%
  }

  .offset-xxl-9 {
      margin-left: 75%
  }

  .offset-xxl-10 {
      margin-left: 83.3333333333%
  }

  .offset-xxl-11 {
      margin-left: 91.6666666667%
  }

  .g-xxl-0,.gx-xxl-0 {
      --o-gutter-x: 0
  }

  .g-xxl-0,.gy-xxl-0 {
      --o-gutter-y: 0
  }

  .g-xxl-1,.gx-xxl-1 {
      --o-gutter-x: .25rem
  }

  .g-xxl-1,.gy-xxl-1 {
      --o-gutter-y: .25rem
  }

  .g-xxl-2,.gx-xxl-2 {
      --o-gutter-x: .5rem
  }

  .g-xxl-2,.gy-xxl-2 {
      --o-gutter-y: .5rem
  }

  .g-xxl-3,.gx-xxl-3 {
      --o-gutter-x: 1rem
  }

  .g-xxl-3,.gy-xxl-3 {
      --o-gutter-y: 1rem
  }

  .g-xxl-4,.gx-xxl-4 {
      --o-gutter-x: 1.5rem
  }

  .g-xxl-4,.gy-xxl-4 {
      --o-gutter-y: 1.5rem
  }

  .g-xxl-5,.gx-xxl-5 {
      --o-gutter-x: 3rem
  }

  .g-xxl-5,.gy-xxl-5 {
      --o-gutter-y: 3rem
  }
}

.pa-0 {
  padding: 0!important
}

.py-0 {
  padding-bottom: 0!important;
  padding-top: 0!important
}

.px-0 {
  padding-right: 0!important
}

.pl-0,.px-0 {
  padding-left: 0!important
}

.pr-0 {
  padding-right: 0!important
}

.pb-0 {
  padding-bottom: 0!important
}

.pt-0 {
  padding-top: 0!important
}

.ma-0 {
  margin: 0!important
}

.my-0 {
  margin-bottom: 0!important;
  margin-top: 0!important
}

.mx-0 {
  margin-left: 0!important;
  margin-right: 0!important
}

.mt-0 {
  margin-top: 0!important
}

.mb-0 {
  margin-bottom: 0!important
}

.ml-0 {
  margin-left: 0!important
}

.mr-0 {
  margin-right: 0!important
}

.pa-1 {
  padding: .0625rem!important
}

.py-1 {
  padding-bottom: .0625rem!important;
  padding-top: .0625rem!important
}

.px-1 {
  padding-right: .0625rem!important
}

.pl-1,.px-1 {
  padding-left: .0625rem!important
}

.pr-1 {
  padding-right: .0625rem!important
}

.pb-1 {
  padding-bottom: .0625rem!important
}

.pt-1 {
  padding-top: .0625rem!important
}

.ma-1 {
  margin: .0625rem!important
}

.my-1 {
  margin-bottom: .0625rem!important;
  margin-top: .0625rem!important
}

.mx-1 {
  margin-left: .0625rem!important;
  margin-right: .0625rem!important
}

.mt-1 {
  margin-top: .0625rem!important
}

.mb-1 {
  margin-bottom: .0625rem!important
}

.ml-1 {
  margin-left: .0625rem!important
}

.mr-1 {
  margin-right: .0625rem!important
}

.pa-2 {
  padding: .125rem!important
}

.py-2 {
  padding-bottom: .125rem!important;
  padding-top: .125rem!important
}

.px-2 {
  padding-right: .125rem!important
}

.pl-2,.px-2 {
  padding-left: .125rem!important
}

.pr-2 {
  padding-right: .125rem!important
}

.pb-2 {
  padding-bottom: .125rem!important
}

.pt-2 {
  padding-top: .125rem!important
}

.ma-2 {
  margin: .125rem!important
}

.my-2 {
  margin-bottom: .125rem!important;
  margin-top: .125rem!important
}

.mx-2 {
  margin-left: .125rem!important;
  margin-right: .125rem!important
}

.mt-2 {
  margin-top: .125rem!important
}

.mb-2 {
  margin-bottom: .125rem!important
}

.ml-2 {
  margin-left: .125rem!important
}

.mr-2 {
  margin-right: .125rem!important
}

.pa-4 {
  padding: .25rem!important
}

.py-4 {
  padding-bottom: .25rem!important;
  padding-top: .25rem!important
}

.px-4 {
  padding-right: .25rem!important
}

.pl-4,.px-4 {
  padding-left: .25rem!important
}

.pr-4 {
  padding-right: .25rem!important
}

.pb-4 {
  padding-bottom: .25rem!important
}

.pt-4 {
  padding-top: .25rem!important
}

.ma-4 {
  margin: .25rem!important
}

.my-4 {
  margin-bottom: .25rem!important;
  margin-top: .25rem!important
}

.mx-4 {
  margin-left: .25rem!important;
  margin-right: .25rem!important
}

.mt-4 {
  margin-top: .25rem!important
}

.mb-4 {
  margin-bottom: .25rem!important
}

.ml-4 {
  margin-left: .25rem!important
}

.mr-4 {
  margin-right: .25rem!important
}

.pa-5 {
  padding: .3125rem!important
}

.py-5 {
  padding-bottom: .3125rem!important;
  padding-top: .3125rem!important
}

.px-5 {
  padding-right: .3125rem!important
}

.pl-5,.px-5 {
  padding-left: .3125rem!important
}

.pr-5 {
  padding-right: .3125rem!important
}

.pb-5 {
  padding-bottom: .3125rem!important
}

.pt-5 {
  padding-top: .3125rem!important
}

.ma-5 {
  margin: .3125rem!important
}

.my-5 {
  margin-bottom: .3125rem!important;
  margin-top: .3125rem!important
}

.mx-5 {
  margin-left: .3125rem!important;
  margin-right: .3125rem!important
}

.mt-5 {
  margin-top: .3125rem!important
}

.mb-5 {
  margin-bottom: .3125rem!important
}

.ml-5 {
  margin-left: .3125rem!important
}

.mr-5 {
  margin-right: .3125rem!important
}

.pa-8 {
  padding: .5rem!important
}

.py-8 {
  padding-bottom: .5rem!important;
  padding-top: .5rem!important
}

.px-8 {
  padding-right: .5rem!important
}

.pl-8,.px-8 {
  padding-left: .5rem!important
}

.pr-8 {
  padding-right: .5rem!important
}

.pb-8 {
  padding-bottom: .5rem!important
}

.pt-8 {
  padding-top: .5rem!important
}

.ma-8 {
  margin: .5rem!important
}

.my-8 {
  margin-bottom: .5rem!important;
  margin-top: .5rem!important
}

.mx-8 {
  margin-left: .5rem!important;
  margin-right: .5rem!important
}

.mt-8 {
  margin-top: .5rem!important
}

.mb-8 {
  margin-bottom: .5rem!important
}

.ml-8 {
  margin-left: .5rem!important
}

.mr-8 {
  margin-right: .5rem!important
}

.pa-10 {
  padding: .625rem!important
}

.py-10 {
  padding-bottom: .625rem!important;
  padding-top: .625rem!important
}

.px-10 {
  padding-right: .625rem!important
}

.pl-10,.px-10 {
  padding-left: .625rem!important
}

.pr-10 {
  padding-right: .625rem!important
}

.pb-10 {
  padding-bottom: .625rem!important
}

.pt-10 {
  padding-top: .625rem!important
}

.ma-10 {
  margin: .625rem!important
}

.my-10 {
  margin-bottom: .625rem!important;
  margin-top: .625rem!important
}

.mx-10 {
  margin-left: .625rem!important;
  margin-right: .625rem!important
}

.mt-10 {
  margin-top: .625rem!important
}

.mb-10 {
  margin-bottom: .625rem!important
}

.ml-10 {
  margin-left: .625rem!important
}

.mr-10 {
  margin-right: .625rem!important
}

.pa-12 {
  padding: .75rem!important
}

.py-12 {
  padding-bottom: .75rem!important;
  padding-top: .75rem!important
}

.px-12 {
  padding-right: .75rem!important
}

.pl-12,.px-12 {
  padding-left: .75rem!important
}

.pr-12 {
  padding-right: .75rem!important
}

.pb-12 {
  padding-bottom: .75rem!important
}

.pt-12 {
  padding-top: .75rem!important
}

.ma-12 {
  margin: .75rem!important
}

.my-12 {
  margin-bottom: .75rem!important;
  margin-top: .75rem!important
}

.mx-12 {
  margin-left: .75rem!important;
  margin-right: .75rem!important
}

.mt-12 {
  margin-top: .75rem!important
}

.mb-12 {
  margin-bottom: .75rem!important
}

.ml-12 {
  margin-left: .75rem!important
}

.mr-12 {
  margin-right: .75rem!important
}

.pa-16 {
  padding: 1rem!important
}

.py-16 {
  padding-bottom: 1rem!important;
  padding-top: 1rem!important
}

.px-16 {
  padding-right: 1rem!important
}

.pl-16,.px-16 {
  padding-left: 1rem!important
}

.pr-16 {
  padding-right: 1rem!important
}

.pb-16 {
  padding-bottom: 1rem!important
}

.pt-16 {
  padding-top: 1rem!important
}

.ma-16 {
  margin: 1rem!important
}

.my-16 {
  margin-bottom: 1rem!important;
  margin-top: 1rem!important
}

.mx-16 {
  margin-left: 1rem!important;
  margin-right: 1rem!important
}

.mt-16 {
  margin-top: 1rem!important
}

.mb-16 {
  margin-bottom: 1rem!important
}

.ml-16 {
  margin-left: 1rem!important
}

.mr-16 {
  margin-right: 1rem!important
}

.pa-20 {
  padding: 1.25rem!important
}

.py-20 {
  padding-bottom: 1.25rem!important;
  padding-top: 1.25rem!important
}

.px-20 {
  padding-right: 1.25rem!important
}

.pl-20,.px-20 {
  padding-left: 1.25rem!important
}

.pr-20 {
  padding-right: 1.25rem!important
}

.pb-20 {
  padding-bottom: 1.25rem!important
}

.pt-20 {
  padding-top: 1.25rem!important
}

.ma-20 {
  margin: 1.25rem!important
}

.my-20 {
  margin-bottom: 1.25rem!important;
  margin-top: 1.25rem!important
}

.mx-20 {
  margin-left: 1.25rem!important;
  margin-right: 1.25rem!important
}

.mt-20 {
  margin-top: 1.25rem!important
}

.mb-20 {
  margin-bottom: 1.25rem!important
}

.ml-20 {
  margin-left: 1.25rem!important
}

.mr-20 {
  margin-right: 1.25rem!important
}

.pa-24 {
  padding: 1.5rem!important
}

.py-24 {
  padding-bottom: 1.5rem!important;
  padding-top: 1.5rem!important
}

.px-24 {
  padding-right: 1.5rem!important
}

.pl-24,.px-24 {
  padding-left: 1.5rem!important
}

.pr-24 {
  padding-right: 1.5rem!important
}

.pb-24 {
  padding-bottom: 1.5rem!important
}

.pt-24 {
  padding-top: 1.5rem!important
}

.ma-24 {
  margin: 1.5rem!important
}

.my-24 {
  margin-bottom: 1.5rem!important;
  margin-top: 1.5rem!important
}

.mx-24 {
  margin-left: 1.5rem!important;
  margin-right: 1.5rem!important
}

.mt-24 {
  margin-top: 1rem!important
}

.mb-24 {
  margin-bottom: 1.5rem!important
}

.ml-24 {
  margin-left: 1.5rem!important
}

.mr-24 {
  margin-right: 1.5rem!important
}

.pa-30 {
  padding: 1.875rem!important
}

.py-30 {
  padding-bottom: 1.875rem!important;
  padding-top: 1.875rem!important
}

.px-30 {
  padding-right: 1.875rem!important
}

.pl-30,.px-30 {
  padding-left: 1.875rem!important
}

.pr-30 {
  padding-right: 1.875rem!important
}

.pb-30 {
  padding-bottom: 1.875rem!important
}

.pt-30 {
  padding-top: 1.875rem!important
}

.ma-30 {
  margin: 1.875rem!important
}

.my-30 {
  margin-bottom: 1.875rem!important;
  margin-top: 1.875rem!important
}

.mx-30 {
  margin-left: 1.875rem!important;
  margin-right: 1.875rem!important
}

.mt-30 {
  margin-top: 1.875rem!important
}

.mb-30 {
  margin-bottom: 1.875rem!important
}

.ml-30 {
  margin-left: 1.875rem!important
}

.mr-30 {
  margin-right: 1.875rem!important
}

.pa-32 {
  padding: 2rem!important
}

.py-32 {
  padding-bottom: 2rem!important;
  padding-top: 2rem!important
}

.px-32 {
  padding-right: 2rem!important
}

.pl-32,.px-32 {
  padding-left: 2rem!important
}

.pr-32 {
  padding-right: 2rem!important
}

.pb-32 {
  padding-bottom: 2rem!important
}

.pt-32 {
  padding-top: 2rem!important
}

.ma-32 {
  margin: 2rem!important
}

.my-32 {
  margin-bottom: 2rem!important;
  margin-top: 2rem!important
}

.mx-32 {
  margin-left: 2rem!important;
  margin-right: 2rem!important
}

.mt-32 {
  margin-top: 2rem!important
}

.mb-32 {
  margin-bottom: 2rem!important
}

.ml-32 {
  margin-left: 2rem!important
}

.mr-32 {
  margin-right: 2rem!important
}

.pa-40 {
  padding: 2.5rem!important
}

.py-40 {
  padding-bottom: 2.5rem!important;
  padding-top: 2.5rem!important
}

.px-40 {
  padding-right: 2.5rem!important
}

.pl-40,.px-40 {
  padding-left: 2.5rem!important
}

.pr-40 {
  padding-right: 2.5rem!important
}

.pb-40 {
  padding-bottom: 2.5rem!important
}

.pt-40 {
  padding-top: 2.5rem!important
}

.ma-40 {
  margin: 2.5rem!important
}

.my-40 {
  margin-bottom: 2.5rem!important;
  margin-top: 2.5rem!important
}

.mx-40 {
  margin-left: 2.5rem!important;
  margin-right: 2.5rem!important
}

.mt-40 {
  margin-top: 2.5rem!important
}

.mb-40 {
  margin-bottom: 2.5rem!important
}

.ml-40 {
  margin-left: 2.5rem!important
}

.mr-40 {
  margin-right: 2.5rem!important
}

.pa-48 {
  padding: 3rem!important
}

.py-48 {
  padding-bottom: 3rem!important;
  padding-top: 3rem!important
}

.px-48 {
  padding-right: 3rem!important
}

.pl-48,.px-48 {
  padding-left: 3rem!important
}

.pr-48 {
  padding-right: 3rem!important
}

.pb-48 {
  padding-bottom: 3rem!important
}

.pt-48 {
  padding-top: 3rem!important
}

.ma-48 {
  margin: 3rem!important
}

.my-48 {
  margin-bottom: 3rem!important;
  margin-top: 3rem!important
}

.mx-48 {
  margin-left: 3rem!important;
  margin-right: 3rem!important
}

.mt-48 {
  margin-top: 3rem!important
}

.mb-48 {
  margin-bottom: 3rem!important
}

.ml-48 {
  margin-left: 3rem!important
}

.mr-48 {
  margin-right: 3rem!important
}

.pa-50 {
  padding: 3.125rem!important
}

.py-50 {
  padding-bottom: 3.125rem!important;
  padding-top: 3.125rem!important
}

.px-50 {
  padding-right: 3.125rem!important
}

.pl-50,.px-50 {
  padding-left: 3.125rem!important
}

.pr-50 {
  padding-right: 3.125rem!important
}

.pb-50 {
  padding-bottom: 3.125rem!important
}

.pt-50 {
  padding-top: 3.125rem!important
}

.ma-50 {
  margin: 3.125rem!important
}

.my-50 {
  margin-bottom: 3.125rem!important;
  margin-top: 3.125rem!important
}

.mx-50 {
  margin-left: 3.125rem!important;
  margin-right: 3.125rem!important
}

.mt-50 {
  margin-top: 3.125rem!important
}

.mb-50 {
  margin-bottom: 3.125rem!important
}

.ml-50 {
  margin-left: 3.125rem!important
}

.mr-50 {
  margin-right: 3.125rem!important
}

.pa-60 {
  padding: 3.75rem!important
}

.py-60 {
  padding-bottom: 3.75rem!important;
  padding-top: 3.75rem!important
}

.px-60 {
  padding-right: 3.75rem!important
}

.pl-60,.px-60 {
  padding-left: 3.75rem!important
}

.pr-60 {
  padding-right: 3.75rem!important
}

.pb-60 {
  padding-bottom: 3.75rem!important
}

.pt-60 {
  padding-top: 3.75rem!important
}

.ma-60 {
  margin: 3.75rem!important
}

.my-60 {
  margin-bottom: 3.75rem!important;
  margin-top: 3.75rem!important
}

.mx-60 {
  margin-left: 3.75rem!important;
  margin-right: 3.75rem!important
}

.mt-60 {
  margin-top: 3.75rem!important
}

.mb-60 {
  margin-bottom: 3.75rem!important
}

.ml-60 {
  margin-left: 3.75rem!important
}

.mr-60 {
  margin-right: 3.75rem!important
}

@media (min-width: 576px) {
  .pa-sm-0 {
      padding:0!important
  }

  .py-sm-0 {
      padding-bottom: 0!important;
      padding-top: 0!important
  }

  .px-sm-0 {
      padding-right: 0!important
  }

  .pl-sm-0,.px-sm-0 {
      padding-left: 0!important
  }

  .pr-sm-0 {
      padding-right: 0!important
  }

  .pb-sm-0 {
      padding-bottom: 0!important
  }

  .pt-sm-0 {
      padding-top: 0!important
  }

  .ma-sm-0 {
      margin: 0!important
  }

  .my-sm-0 {
      margin-bottom: 0!important;
      margin-top: 0!important
  }

  .mx-sm-0 {
      margin-left: 0!important;
      margin-right: 0!important
  }

  .mt-sm-0 {
      margin-top: 0!important
  }

  .mb-sm-0 {
      margin-bottom: 0!important
  }

  .ml-sm-0 {
      margin-left: 0!important
  }

  .mr-sm-0 {
      margin-right: 0!important
  }

  .pa-sm-1 {
      padding: .0625rem!important
  }

  .py-sm-1 {
      padding-bottom: .0625rem!important;
      padding-top: .0625rem!important
  }

  .px-sm-1 {
      padding-right: .0625rem!important
  }

  .pl-sm-1,.px-sm-1 {
      padding-left: .0625rem!important
  }

  .pr-sm-1 {
      padding-right: .0625rem!important
  }

  .pb-sm-1 {
      padding-bottom: .0625rem!important
  }

  .pt-sm-1 {
      padding-top: .0625rem!important
  }

  .ma-sm-1 {
      margin: .0625rem!important
  }

  .my-sm-1 {
      margin-bottom: .0625rem!important;
      margin-top: .0625rem!important
  }

  .mx-sm-1 {
      margin-left: .0625rem!important;
      margin-right: .0625rem!important
  }

  .mt-sm-1 {
      margin-top: .0625rem!important
  }

  .mb-sm-1 {
      margin-bottom: .0625rem!important
  }

  .ml-sm-1 {
      margin-left: .0625rem!important
  }

  .mr-sm-1 {
      margin-right: .0625rem!important
  }

  .pa-sm-2 {
      padding: .125rem!important
  }

  .py-sm-2 {
      padding-bottom: .125rem!important;
      padding-top: .125rem!important
  }

  .px-sm-2 {
      padding-right: .125rem!important
  }

  .pl-sm-2,.px-sm-2 {
      padding-left: .125rem!important
  }

  .pr-sm-2 {
      padding-right: .125rem!important
  }

  .pb-sm-2 {
      padding-bottom: .125rem!important
  }

  .pt-sm-2 {
      padding-top: .125rem!important
  }

  .ma-sm-2 {
      margin: .125rem!important
  }

  .my-sm-2 {
      margin-bottom: .125rem!important;
      margin-top: .125rem!important
  }

  .mx-sm-2 {
      margin-left: .125rem!important;
      margin-right: .125rem!important
  }

  .mt-sm-2 {
      margin-top: .125rem!important
  }

  .mb-sm-2 {
      margin-bottom: .125rem!important
  }

  .ml-sm-2 {
      margin-left: .125rem!important
  }

  .mr-sm-2 {
      margin-right: .125rem!important
  }

  .pa-sm-4 {
      padding: .25rem!important
  }

  .py-sm-4 {
      padding-bottom: .25rem!important;
      padding-top: .25rem!important
  }

  .px-sm-4 {
      padding-right: .25rem!important
  }

  .pl-sm-4,.px-sm-4 {
      padding-left: .25rem!important
  }

  .pr-sm-4 {
      padding-right: .25rem!important
  }

  .pb-sm-4 {
      padding-bottom: .25rem!important
  }

  .pt-sm-4 {
      padding-top: .25rem!important
  }

  .ma-sm-4 {
      margin: .25rem!important
  }

  .my-sm-4 {
      margin-bottom: .25rem!important;
      margin-top: .25rem!important
  }

  .mx-sm-4 {
      margin-left: .25rem!important;
      margin-right: .25rem!important
  }

  .mt-sm-4 {
      margin-top: .25rem!important
  }

  .mb-sm-4 {
      margin-bottom: .25rem!important
  }

  .ml-sm-4 {
      margin-left: .25rem!important
  }

  .mr-sm-4 {
      margin-right: .25rem!important
  }

  .pa-sm-5 {
      padding: .3125rem!important
  }

  .py-sm-5 {
      padding-bottom: .3125rem!important;
      padding-top: .3125rem!important
  }

  .px-sm-5 {
      padding-right: .3125rem!important
  }

  .pl-sm-5,.px-sm-5 {
      padding-left: .3125rem!important
  }

  .pr-sm-5 {
      padding-right: .3125rem!important
  }

  .pb-sm-5 {
      padding-bottom: .3125rem!important
  }

  .pt-sm-5 {
      padding-top: .3125rem!important
  }

  .ma-sm-5 {
      margin: .3125rem!important
  }

  .my-sm-5 {
      margin-bottom: .3125rem!important;
      margin-top: .3125rem!important
  }

  .mx-sm-5 {
      margin-left: .3125rem!important;
      margin-right: .3125rem!important
  }

  .mt-sm-5 {
      margin-top: .3125rem!important
  }

  .mb-sm-5 {
      margin-bottom: .3125rem!important
  }

  .ml-sm-5 {
      margin-left: .3125rem!important
  }

  .mr-sm-5 {
      margin-right: .3125rem!important
  }

  .pa-sm-8 {
      padding: .5rem!important
  }

  .py-sm-8 {
      padding-bottom: .5rem!important;
      padding-top: .5rem!important
  }

  .px-sm-8 {
      padding-right: .5rem!important
  }

  .pl-sm-8,.px-sm-8 {
      padding-left: .5rem!important
  }

  .pr-sm-8 {
      padding-right: .5rem!important
  }

  .pb-sm-8 {
      padding-bottom: .5rem!important
  }

  .pt-sm-8 {
      padding-top: .5rem!important
  }

  .ma-sm-8 {
      margin: .5rem!important
  }

  .my-sm-8 {
      margin-bottom: .5rem!important;
      margin-top: .5rem!important
  }

  .mx-sm-8 {
      margin-left: .5rem!important;
      margin-right: .5rem!important
  }

  .mt-sm-8 {
      margin-top: .5rem!important
  }

  .mb-sm-8 {
      margin-bottom: .5rem!important
  }

  .ml-sm-8 {
      margin-left: .5rem!important
  }

  .mr-sm-8 {
      margin-right: .5rem!important
  }

  .pa-sm-10 {
      padding: .625rem!important
  }

  .py-sm-10 {
      padding-bottom: .625rem!important;
      padding-top: .625rem!important
  }

  .px-sm-10 {
      padding-right: .625rem!important
  }

  .pl-sm-10,.px-sm-10 {
      padding-left: .625rem!important
  }

  .pr-sm-10 {
      padding-right: .625rem!important
  }

  .pb-sm-10 {
      padding-bottom: .625rem!important
  }

  .pt-sm-10 {
      padding-top: .625rem!important
  }

  .ma-sm-10 {
      margin: .625rem!important
  }

  .my-sm-10 {
      margin-bottom: .625rem!important;
      margin-top: .625rem!important
  }

  .mx-sm-10 {
      margin-left: .625rem!important;
      margin-right: .625rem!important
  }

  .mt-sm-10 {
      margin-top: .625rem!important
  }

  .mb-sm-10 {
      margin-bottom: .625rem!important
  }

  .ml-sm-10 {
      margin-left: .625rem!important
  }

  .mr-sm-10 {
      margin-right: .625rem!important
  }

  .pa-sm-12 {
      padding: .75rem!important
  }

  .py-sm-12 {
      padding-bottom: .75rem!important;
      padding-top: .75rem!important
  }

  .px-sm-12 {
      padding-right: .75rem!important
  }

  .pl-sm-12,.px-sm-12 {
      padding-left: .75rem!important
  }

  .pr-sm-12 {
      padding-right: .75rem!important
  }

  .pb-sm-12 {
      padding-bottom: .75rem!important
  }

  .pt-sm-12 {
      padding-top: .75rem!important
  }

  .ma-sm-12 {
      margin: .75rem!important
  }

  .my-sm-12 {
      margin-bottom: .75rem!important;
      margin-top: .75rem!important
  }

  .mx-sm-12 {
      margin-left: .75rem!important;
      margin-right: .75rem!important
  }

  .mt-sm-12 {
      margin-top: .75rem!important
  }

  .mb-sm-12 {
      margin-bottom: .75rem!important
  }

  .ml-sm-12 {
      margin-left: .75rem!important
  }

  .mr-sm-12 {
      margin-right: .75rem!important
  }

  .pa-sm-16 {
      padding: 1rem!important
  }

  .py-sm-16 {
      padding-bottom: 1rem!important;
      padding-top: 1rem!important
  }

  .px-sm-16 {
      padding-right: 1rem!important
  }

  .pl-sm-16,.px-sm-16 {
      padding-left: 1rem!important
  }

  .pr-sm-16 {
      padding-right: 1rem!important
  }

  .pb-sm-16 {
      padding-bottom: 1rem!important
  }

  .pt-sm-16 {
      padding-top: 1rem!important
  }

  .ma-sm-16 {
      margin: 1rem!important
  }

  .my-sm-16 {
      margin-bottom: 1rem!important;
      margin-top: 1rem!important
  }

  .mx-sm-16 {
      margin-left: 1rem!important;
      margin-right: 1rem!important
  }

  .mt-sm-16 {
      margin-top: 1rem!important
  }

  .mb-sm-16 {
      margin-bottom: 1rem!important
  }

  .ml-sm-16 {
      margin-left: 1rem!important
  }

  .mr-sm-16 {
      margin-right: 1rem!important
  }

  .pa-sm-20 {
      padding: 1.25rem!important
  }

  .py-sm-20 {
      padding-bottom: 1.25rem!important;
      padding-top: 1.25rem!important
  }

  .px-sm-20 {
      padding-right: 1.25rem!important
  }

  .pl-sm-20,.px-sm-20 {
      padding-left: 1.25rem!important
  }

  .pr-sm-20 {
      padding-right: 1.25rem!important
  }

  .pb-sm-20 {
      padding-bottom: 1.25rem!important
  }

  .pt-sm-20 {
      padding-top: 1.25rem!important
  }

  .ma-sm-20 {
      margin: 1.25rem!important
  }

  .my-sm-20 {
      margin-bottom: 1.25rem!important;
      margin-top: 1.25rem!important
  }

  .mx-sm-20 {
      margin-left: 1.25rem!important;
      margin-right: 1.25rem!important
  }

  .mt-sm-20 {
      margin-top: 1.25rem!important
  }

  .mb-sm-20 {
      margin-bottom: 1.25rem!important
  }

  .ml-sm-20 {
      margin-left: 1.25rem!important
  }

  .mr-sm-20 {
      margin-right: 1.25rem!important
  }

  .pa-sm-24 {
      padding: 1.5rem!important
  }

  .py-sm-24 {
      padding-bottom: 1.5rem!important;
      padding-top: 1.5rem!important
  }

  .px-sm-24 {
      padding-right: 1.5rem!important
  }

  .pl-sm-24,.px-sm-24 {
      padding-left: 1.5rem!important
  }

  .pr-sm-24 {
      padding-right: 1.5rem!important
  }

  .pb-sm-24 {
      padding-bottom: 1.5rem!important
  }

  .pt-sm-24 {
      padding-top: 1.5rem!important
  }

  .ma-sm-24 {
      margin: 1.5rem!important
  }

  .my-sm-24 {
      margin-bottom: 1.5rem!important;
      margin-top: 1.5rem!important
  }

  .mx-sm-24 {
      margin-left: 1.5rem!important;
      margin-right: 1.5rem!important
  }

  .mt-sm-24 {
      margin-top: 1.5rem!important
  }

  .mb-sm-24 {
      margin-bottom: 1.5rem!important
  }

  .ml-sm-24 {
      margin-left: 1.5rem!important
  }

  .mr-sm-24 {
      margin-right: 1.5rem!important
  }

  .pa-sm-30 {
      padding: 1.875rem!important
  }

  .py-sm-30 {
      padding-bottom: 1.875rem!important;
      padding-top: 1.875rem!important
  }

  .px-sm-30 {
      padding-right: 1.875rem!important
  }

  .pl-sm-30,.px-sm-30 {
      padding-left: 1.875rem!important
  }

  .pr-sm-30 {
      padding-right: 1.875rem!important
  }

  .pb-sm-30 {
      padding-bottom: 1.875rem!important
  }

  .pt-sm-30 {
      padding-top: 1.875rem!important
  }

  .ma-sm-30 {
      margin: 1.875rem!important
  }

  .my-sm-30 {
      margin-bottom: 1.875rem!important;
      margin-top: 1.875rem!important
  }

  .mx-sm-30 {
      margin-left: 1.875rem!important;
      margin-right: 1.875rem!important
  }

  .mt-sm-30 {
      margin-top: 1.875rem!important
  }

  .mb-sm-30 {
      margin-bottom: 1.875rem!important
  }

  .ml-sm-30 {
      margin-left: 1.875rem!important
  }

  .mr-sm-30 {
      margin-right: 1.875rem!important
  }

  .pa-sm-32 {
      padding: 2rem!important
  }

  .py-sm-32 {
      padding-bottom: 2rem!important;
      padding-top: 2rem!important
  }

  .px-sm-32 {
      padding-right: 2rem!important
  }

  .pl-sm-32,.px-sm-32 {
      padding-left: 2rem!important
  }

  .pr-sm-32 {
      padding-right: 2rem!important
  }

  .pb-sm-32 {
      padding-bottom: 2rem!important
  }

  .pt-sm-32 {
      padding-top: 2rem!important
  }

  .ma-sm-32 {
      margin: 2rem!important
  }

  .my-sm-32 {
      margin-bottom: 2rem!important;
      margin-top: 2rem!important
  }

  .mx-sm-32 {
      margin-left: 2rem!important;
      margin-right: 2rem!important
  }

  .mt-sm-32 {
      margin-top: 2rem!important
  }

  .mb-sm-32 {
      margin-bottom: 2rem!important
  }

  .ml-sm-32 {
      margin-left: 2rem!important
  }

  .mr-sm-32 {
      margin-right: 2rem!important
  }

  .pa-sm-40 {
      padding: 2.5rem!important
  }

  .py-sm-40 {
      padding-bottom: 2.5rem!important;
      padding-top: 2.5rem!important
  }

  .px-sm-40 {
      padding-right: 2.5rem!important
  }

  .pl-sm-40,.px-sm-40 {
      padding-left: 2.5rem!important
  }

  .pr-sm-40 {
      padding-right: 2.5rem!important
  }

  .pb-sm-40 {
      padding-bottom: 2.5rem!important
  }

  .pt-sm-40 {
      padding-top: 2.5rem!important
  }

  .ma-sm-40 {
      margin: 2.5rem!important
  }

  .my-sm-40 {
      margin-bottom: 2.5rem!important;
      margin-top: 2.5rem!important
  }

  .mx-sm-40 {
      margin-left: 2.5rem!important;
      margin-right: 2.5rem!important
  }

  .mt-sm-40 {
      margin-top: 2.5rem!important
  }

  .mb-sm-40 {
      margin-bottom: 2.5rem!important
  }

  .ml-sm-40 {
      margin-left: 2.5rem!important
  }

  .mr-sm-40 {
      margin-right: 2.5rem!important
  }

  .pa-sm-48 {
      padding: 3rem!important
  }

  .py-sm-48 {
      padding-bottom: 3rem!important;
      padding-top: 3rem!important
  }

  .px-sm-48 {
      padding-right: 3rem!important
  }

  .pl-sm-48,.px-sm-48 {
      padding-left: 3rem!important
  }

  .pr-sm-48 {
      padding-right: 3rem!important
  }

  .pb-sm-48 {
      padding-bottom: 3rem!important
  }

  .pt-sm-48 {
      padding-top: 3rem!important
  }

  .ma-sm-48 {
      margin: 3rem!important
  }

  .my-sm-48 {
      margin-bottom: 3rem!important;
      margin-top: 3rem!important
  }

  .mx-sm-48 {
      margin-left: 3rem!important;
      margin-right: 3rem!important
  }

  .mt-sm-48 {
      margin-top: 3rem!important
  }

  .mb-sm-48 {
      margin-bottom: 3rem!important
  }

  .ml-sm-48 {
      margin-left: 3rem!important
  }

  .mr-sm-48 {
      margin-right: 3rem!important
  }

  .pa-sm-50 {
      padding: 3.125rem!important
  }

  .py-sm-50 {
      padding-bottom: 3.125rem!important;
      padding-top: 3.125rem!important
  }

  .px-sm-50 {
      padding-right: 3.125rem!important
  }

  .pl-sm-50,.px-sm-50 {
      padding-left: 3.125rem!important
  }

  .pr-sm-50 {
      padding-right: 3.125rem!important
  }

  .pb-sm-50 {
      padding-bottom: 3.125rem!important
  }

  .pt-sm-50 {
      padding-top: 3.125rem!important
  }

  .ma-sm-50 {
      margin: 3.125rem!important
  }

  .my-sm-50 {
      margin-bottom: 3.125rem!important;
      margin-top: 3.125rem!important
  }

  .mx-sm-50 {
      margin-left: 3.125rem!important;
      margin-right: 3.125rem!important
  }

  .mt-sm-50 {
      margin-top: 3.125rem!important
  }

  .mb-sm-50 {
      margin-bottom: 3.125rem!important
  }

  .ml-sm-50 {
      margin-left: 3.125rem!important
  }

  .mr-sm-50 {
      margin-right: 3.125rem!important
  }

  .pa-sm-60 {
      padding: 3.75rem!important
  }

  .py-sm-60 {
      padding-bottom: 3.75rem!important;
      padding-top: 3.75rem!important
  }

  .px-sm-60 {
      padding-right: 3.75rem!important
  }

  .pl-sm-60,.px-sm-60 {
      padding-left: 3.75rem!important
  }

  .pr-sm-60 {
      padding-right: 3.75rem!important
  }

  .pb-sm-60 {
      padding-bottom: 3.75rem!important
  }

  .pt-sm-60 {
      padding-top: 3.75rem!important
  }

  .ma-sm-60 {
      margin: 3.75rem!important
  }

  .my-sm-60 {
      margin-bottom: 3.75rem!important;
      margin-top: 3.75rem!important
  }

  .mx-sm-60 {
      margin-left: 3.75rem!important;
      margin-right: 3.75rem!important
  }

  .mt-sm-60 {
      margin-top: 3.75rem!important
  }

  .mb-sm-60 {
      margin-bottom: 3.75rem!important
  }

  .ml-sm-60 {
      margin-left: 3.75rem!important
  }

  .mr-sm-60 {
      margin-right: 3.75rem!important
  }
}

@media (min-width: 768px) {
  .pa-md-0 {
      padding:0!important
  }

  .py-md-0 {
      padding-bottom: 0!important;
      padding-top: 0!important
  }

  .px-md-0 {
      padding-right: 0!important
  }

  .pl-md-0,.px-md-0 {
      padding-left: 0!important
  }

  .pr-md-0 {
      padding-right: 0!important
  }

  .pb-md-0 {
      padding-bottom: 0!important
  }

  .pt-md-0 {
      padding-top: 0!important
  }

  .ma-md-0 {
      margin: 0!important
  }

  .my-md-0 {
      margin-bottom: 0!important;
      margin-top: 0!important
  }

  .mx-md-0 {
      margin-left: 0!important;
      margin-right: 0!important
  }

  .mt-md-0 {
      margin-top: 0!important
  }

  .mb-md-0 {
      margin-bottom: 0!important
  }

  .ml-md-0 {
      margin-left: 0!important
  }

  .mr-md-0 {
      margin-right: 0!important
  }

  .pa-md-1 {
      padding: .0625rem!important
  }

  .py-md-1 {
      padding-bottom: .0625rem!important;
      padding-top: .0625rem!important
  }

  .px-md-1 {
      padding-right: .0625rem!important
  }

  .pl-md-1,.px-md-1 {
      padding-left: .0625rem!important
  }

  .pr-md-1 {
      padding-right: .0625rem!important
  }

  .pb-md-1 {
      padding-bottom: .0625rem!important
  }

  .pt-md-1 {
      padding-top: .0625rem!important
  }

  .ma-md-1 {
      margin: .0625rem!important
  }

  .my-md-1 {
      margin-bottom: .0625rem!important;
      margin-top: .0625rem!important
  }

  .mx-md-1 {
      margin-left: .0625rem!important;
      margin-right: .0625rem!important
  }

  .mt-md-1 {
      margin-top: .0625rem!important
  }

  .mb-md-1 {
      margin-bottom: .0625rem!important
  }

  .ml-md-1 {
      margin-left: .0625rem!important
  }

  .mr-md-1 {
      margin-right: .0625rem!important
  }

  .pa-md-2 {
      padding: .125rem!important
  }

  .py-md-2 {
      padding-bottom: .125rem!important;
      padding-top: .125rem!important
  }

  .px-md-2 {
      padding-right: .125rem!important
  }

  .pl-md-2,.px-md-2 {
      padding-left: .125rem!important
  }

  .pr-md-2 {
      padding-right: .125rem!important
  }

  .pb-md-2 {
      padding-bottom: .125rem!important
  }

  .pt-md-2 {
      padding-top: .125rem!important
  }

  .ma-md-2 {
      margin: .125rem!important
  }

  .my-md-2 {
      margin-bottom: .125rem!important;
      margin-top: .125rem!important
  }

  .mx-md-2 {
      margin-left: .125rem!important;
      margin-right: .125rem!important
  }

  .mt-md-2 {
      margin-top: .125rem!important
  }

  .mb-md-2 {
      margin-bottom: .125rem!important
  }

  .ml-md-2 {
      margin-left: .125rem!important
  }

  .mr-md-2 {
      margin-right: .125rem!important
  }

  .pa-md-4 {
      padding: .25rem!important
  }

  .py-md-4 {
      padding-bottom: .25rem!important;
      padding-top: .25rem!important
  }

  .px-md-4 {
      padding-right: .25rem!important
  }

  .pl-md-4,.px-md-4 {
      padding-left: .25rem!important
  }

  .pr-md-4 {
      padding-right: .25rem!important
  }

  .pb-md-4 {
      padding-bottom: .25rem!important
  }

  .pt-md-4 {
      padding-top: .25rem!important
  }

  .ma-md-4 {
      margin: .25rem!important
  }

  .my-md-4 {
      margin-bottom: .25rem!important;
      margin-top: .25rem!important
  }

  .mx-md-4 {
      margin-left: .25rem!important;
      margin-right: .25rem!important
  }

  .mt-md-4 {
      margin-top: .25rem!important
  }

  .mb-md-4 {
      margin-bottom: .25rem!important
  }

  .ml-md-4 {
      margin-left: .25rem!important
  }

  .mr-md-4 {
      margin-right: .25rem!important
  }

  .pa-md-5 {
      padding: .3125rem!important
  }

  .py-md-5 {
      padding-bottom: .3125rem!important;
      padding-top: .3125rem!important
  }

  .px-md-5 {
      padding-right: .3125rem!important
  }

  .pl-md-5,.px-md-5 {
      padding-left: .3125rem!important
  }

  .pr-md-5 {
      padding-right: .3125rem!important
  }

  .pb-md-5 {
      padding-bottom: .3125rem!important
  }

  .pt-md-5 {
      padding-top: .3125rem!important
  }

  .ma-md-5 {
      margin: .3125rem!important
  }

  .my-md-5 {
      margin-bottom: .3125rem!important;
      margin-top: .3125rem!important
  }

  .mx-md-5 {
      margin-left: .3125rem!important;
      margin-right: .3125rem!important
  }

  .mt-md-5 {
      margin-top: .3125rem!important
  }

  .mb-md-5 {
      margin-bottom: .3125rem!important
  }

  .ml-md-5 {
      margin-left: .3125rem!important
  }

  .mr-md-5 {
      margin-right: .3125rem!important
  }

  .pa-md-8 {
      padding: .5rem!important
  }

  .py-md-8 {
      padding-bottom: .5rem!important;
      padding-top: .5rem!important
  }

  .px-md-8 {
      padding-right: .5rem!important
  }

  .pl-md-8,.px-md-8 {
      padding-left: .5rem!important
  }

  .pr-md-8 {
      padding-right: .5rem!important
  }

  .pb-md-8 {
      padding-bottom: .5rem!important
  }

  .pt-md-8 {
      padding-top: .5rem!important
  }

  .ma-md-8 {
      margin: .5rem!important
  }

  .my-md-8 {
      margin-bottom: .5rem!important;
      margin-top: .5rem!important
  }

  .mx-md-8 {
      margin-left: .5rem!important;
      margin-right: .5rem!important
  }

  .mt-md-8 {
      margin-top: .5rem!important
  }

  .mb-md-8 {
      margin-bottom: .5rem!important
  }

  .ml-md-8 {
      margin-left: .5rem!important
  }

  .mr-md-8 {
      margin-right: .5rem!important
  }

  .pa-md-10 {
      padding: .625rem!important
  }

  .py-md-10 {
      padding-bottom: .625rem!important;
      padding-top: .625rem!important
  }

  .px-md-10 {
      padding-right: .625rem!important
  }

  .pl-md-10,.px-md-10 {
      padding-left: .625rem!important
  }

  .pr-md-10 {
      padding-right: .625rem!important
  }

  .pb-md-10 {
      padding-bottom: .625rem!important
  }

  .pt-md-10 {
      padding-top: .625rem!important
  }

  .ma-md-10 {
      margin: .625rem!important
  }

  .my-md-10 {
      margin-bottom: .625rem!important;
      margin-top: .625rem!important
  }

  .mx-md-10 {
      margin-left: .625rem!important;
      margin-right: .625rem!important
  }

  .mt-md-10 {
      margin-top: .625rem!important
  }

  .mb-md-10 {
      margin-bottom: .625rem!important
  }

  .ml-md-10 {
      margin-left: .625rem!important
  }

  .mr-md-10 {
      margin-right: .625rem!important
  }

  .pa-md-12 {
      padding: .75rem!important
  }

  .py-md-12 {
      padding-bottom: .75rem!important;
      padding-top: .75rem!important
  }

  .px-md-12 {
      padding-right: .75rem!important
  }

  .pl-md-12,.px-md-12 {
      padding-left: .75rem!important
  }

  .pr-md-12 {
      padding-right: .75rem!important
  }

  .pb-md-12 {
      padding-bottom: .75rem!important
  }

  .pt-md-12 {
      padding-top: .75rem!important
  }

  .ma-md-12 {
      margin: .75rem!important
  }

  .my-md-12 {
      margin-bottom: .75rem!important;
      margin-top: .75rem!important
  }

  .mx-md-12 {
      margin-left: .75rem!important;
      margin-right: .75rem!important
  }

  .mt-md-12 {
      margin-top: .75rem!important
  }

  .mb-md-12 {
      margin-bottom: .75rem!important
  }

  .ml-md-12 {
      margin-left: .75rem!important
  }

  .mr-md-12 {
      margin-right: .75rem!important
  }

  .pa-md-16 {
      padding: 1rem!important
  }

  .py-md-16 {
      padding-bottom: 1rem!important;
      padding-top: 1rem!important
  }

  .px-md-16 {
      padding-right: 1rem!important
  }

  .pl-md-16,.px-md-16 {
      padding-left: 1rem!important
  }

  .pr-md-16 {
      padding-right: 1rem!important
  }

  .pb-md-16 {
      padding-bottom: 1rem!important
  }

  .pt-md-16 {
      padding-top: 1rem!important
  }

  .ma-md-16 {
      margin: 1rem!important
  }

  .my-md-16 {
      margin-bottom: 1rem!important;
      margin-top: 1rem!important
  }

  .mx-md-16 {
      margin-left: 1rem!important;
      margin-right: 1rem!important
  }

  .mt-md-16 {
      margin-top: 1rem!important
  }

  .mb-md-16 {
      margin-bottom: 1rem!important
  }

  .ml-md-16 {
      margin-left: 1rem!important
  }

  .mr-md-16 {
      margin-right: 1rem!important
  }

  .pa-md-20 {
      padding: 1.25rem!important
  }

  .py-md-20 {
      padding-bottom: 1.25rem!important;
      padding-top: 1.25rem!important
  }

  .px-md-20 {
      padding-right: 1.25rem!important
  }

  .pl-md-20,.px-md-20 {
      padding-left: 1.25rem!important
  }

  .pr-md-20 {
      padding-right: 1.25rem!important
  }

  .pb-md-20 {
      padding-bottom: 1.25rem!important
  }

  .pt-md-20 {
      padding-top: 1.25rem!important
  }

  .ma-md-20 {
      margin: 1.25rem!important
  }

  .my-md-20 {
      margin-bottom: 1.25rem!important;
      margin-top: 1.25rem!important
  }

  .mx-md-20 {
      margin-left: 1.25rem!important;
      margin-right: 1.25rem!important
  }

  .mt-md-20 {
      margin-top: 1.25rem!important
  }

  .mb-md-20 {
      margin-bottom: 1.25rem!important
  }

  .ml-md-20 {
      margin-left: 1.25rem!important
  }

  .mr-md-20 {
      margin-right: 1.25rem!important
  }

  .pa-md-24 {
      padding: 1.5rem!important
  }

  .py-md-24 {
      padding-bottom: 1.5rem!important;
      padding-top: 1.5rem!important
  }

  .px-md-24 {
      padding-right: 1.5rem!important
  }

  .pl-md-24,.px-md-24 {
      padding-left: 1.5rem!important
  }

  .pr-md-24 {
      padding-right: 1.5rem!important
  }

  .pb-md-24 {
      padding-bottom: 1.5rem!important
  }

  .pt-md-24 {
      padding-top: 1.5rem!important
  }

  .ma-md-24 {
      margin: 1.5rem!important
  }

  .my-md-24 {
      margin-bottom: 1.5rem!important;
      margin-top: 1.5rem!important
  }

  .mx-md-24 {
      margin-left: 1.5rem!important;
      margin-right: 1.5rem!important
  }

  .mt-md-24 {
      margin-top: 1.5rem!important
  }

  .mb-md-24 {
      margin-bottom: 1.5rem!important
  }

  .ml-md-24 {
      margin-left: 1.5rem!important
  }

  .mr-md-24 {
      margin-right: 1.5rem!important
  }

  .pa-md-30 {
      padding: 1.875rem!important
  }

  .py-md-30 {
      padding-bottom: 1.875rem!important;
      padding-top: 1.875rem!important
  }

  .px-md-30 {
      padding-right: 1.875rem!important
  }

  .pl-md-30,.px-md-30 {
      padding-left: 1.875rem!important
  }

  .pr-md-30 {
      padding-right: 1.875rem!important
  }

  .pb-md-30 {
      padding-bottom: 1.875rem!important
  }

  .pt-md-30 {
      padding-top: 1.875rem!important
  }

  .ma-md-30 {
      margin: 1.875rem!important
  }

  .my-md-30 {
      margin-bottom: 1.875rem!important;
      margin-top: 1.875rem!important
  }

  .mx-md-30 {
      margin-left: 1.875rem!important;
      margin-right: 1.875rem!important
  }

  .mt-md-30 {
      margin-top: 1.875rem!important
  }

  .mb-md-30 {
      margin-bottom: 1.875rem!important
  }

  .ml-md-30 {
      margin-left: 1.875rem!important
  }

  .mr-md-30 {
      margin-right: 1.875rem!important
  }

  .pa-md-32 {
      padding: 2rem!important
  }

  .py-md-32 {
      padding-bottom: 2rem!important;
      padding-top: 2rem!important
  }

  .px-md-32 {
      padding-right: 2rem!important
  }

  .pl-md-32,.px-md-32 {
      padding-left: 2rem!important
  }

  .pr-md-32 {
      padding-right: 2rem!important
  }

  .pb-md-32 {
      padding-bottom: 2rem!important
  }

  .pt-md-32 {
      padding-top: 2rem!important
  }

  .ma-md-32 {
      margin: 2rem!important
  }

  .my-md-32 {
      margin-bottom: 2rem!important;
      margin-top: 2rem!important
  }

  .mx-md-32 {
      margin-left: 2rem!important;
      margin-right: 2rem!important
  }

  .mt-md-32 {
      margin-top: 2rem!important
  }

  .mb-md-32 {
      margin-bottom: 2rem!important
  }

  .ml-md-32 {
      margin-left: 2rem!important
  }

  .mr-md-32 {
      margin-right: 2rem!important
  }

  .pa-md-40 {
      padding: 2.5rem!important
  }

  .py-md-40 {
      padding-bottom: 2.5rem!important;
      padding-top: 2.5rem!important
  }

  .px-md-40 {
      padding-right: 2.5rem!important
  }

  .pl-md-40,.px-md-40 {
      padding-left: 2.5rem!important
  }

  .pr-md-40 {
      padding-right: 2.5rem!important
  }

  .pb-md-40 {
      padding-bottom: 2.5rem!important
  }

  .pt-md-40 {
      padding-top: 2.5rem!important
  }

  .ma-md-40 {
      margin: 2.5rem!important
  }

  .my-md-40 {
      margin-bottom: 2.5rem!important;
      margin-top: 2.5rem!important
  }

  .mx-md-40 {
      margin-left: 2.5rem!important;
      margin-right: 2.5rem!important
  }

  .mt-md-40 {
      margin-top: 2.5rem!important
  }

  .mb-md-40 {
      margin-bottom: 2.5rem!important
  }

  .ml-md-40 {
      margin-left: 2.5rem!important
  }

  .mr-md-40 {
      margin-right: 2.5rem!important
  }

  .pa-md-48 {
      padding: 3rem!important
  }

  .py-md-48 {
      padding-bottom: 3rem!important;
      padding-top: 3rem!important
  }

  .px-md-48 {
      padding-right: 3rem!important
  }

  .pl-md-48,.px-md-48 {
      padding-left: 3rem!important
  }

  .pr-md-48 {
      padding-right: 3rem!important
  }

  .pb-md-48 {
      padding-bottom: 3rem!important
  }

  .pt-md-48 {
      padding-top: 3rem!important
  }

  .ma-md-48 {
      margin: 3rem!important
  }

  .my-md-48 {
      margin-bottom: 3rem!important;
      margin-top: 3rem!important
  }

  .mx-md-48 {
      margin-left: 3rem!important;
      margin-right: 3rem!important
  }

  .mt-md-48 {
      margin-top: 3rem!important
  }

  .mb-md-48 {
      margin-bottom: 3rem!important
  }

  .ml-md-48 {
      margin-left: 3rem!important
  }

  .mr-md-48 {
      margin-right: 3rem!important
  }

  .pa-md-50 {
      padding: 3.125rem!important
  }

  .py-md-50 {
      padding-bottom: 3.125rem!important;
      padding-top: 3.125rem!important
  }

  .px-md-50 {
      padding-right: 3.125rem!important
  }

  .pl-md-50,.px-md-50 {
      padding-left: 3.125rem!important
  }

  .pr-md-50 {
      padding-right: 3.125rem!important
  }

  .pb-md-50 {
      padding-bottom: 3.125rem!important
  }

  .pt-md-50 {
      padding-top: 3.125rem!important
  }

  .ma-md-50 {
      margin: 3.125rem!important
  }

  .my-md-50 {
      margin-bottom: 3.125rem!important;
      margin-top: 3.125rem!important
  }

  .mx-md-50 {
      margin-left: 3.125rem!important;
      margin-right: 3.125rem!important
  }

  .mt-md-50 {
      margin-top: 3.125rem!important
  }

  .mb-md-50 {
      margin-bottom: 3.125rem!important
  }

  .ml-md-50 {
      margin-left: 3.125rem!important
  }

  .mr-md-50 {
      margin-right: 3.125rem!important
  }

  .pa-md-60 {
      padding: 3.75rem!important
  }

  .py-md-60 {
      padding-bottom: 3.75rem!important;
      padding-top: 3.75rem!important
  }

  .px-md-60 {
      padding-right: 3.75rem!important
  }

  .pl-md-60,.px-md-60 {
      padding-left: 3.75rem!important
  }

  .pr-md-60 {
      padding-right: 3.75rem!important
  }

  .pb-md-60 {
      padding-bottom: 3.75rem!important
  }

  .pt-md-60 {
      padding-top: 3.75rem!important
  }

  .ma-md-60 {
      margin: 3.75rem!important
  }

  .my-md-60 {
      margin-bottom: 3.75rem!important;
      margin-top: 3.75rem!important
  }

  .mx-md-60 {
      margin-left: 3.75rem!important;
      margin-right: 3.75rem!important
  }

  .mt-md-60 {
      margin-top: 3.75rem!important
  }

  .mb-md-60 {
      margin-bottom: 3.75rem!important
  }

  .ml-md-60 {
      margin-left: 3.75rem!important
  }

  .mr-md-60 {
      margin-right: 3.75rem!important
  }
}

@media (min-width: 992px) {
  .pa-lg-0 {
      padding:0!important
  }

  .py-lg-0 {
      padding-bottom: 0!important;
      padding-top: 0!important
  }

  .px-lg-0 {
      padding-right: 0!important
  }

  .pl-lg-0,.px-lg-0 {
      padding-left: 0!important
  }

  .pr-lg-0 {
      padding-right: 0!important
  }

  .pb-lg-0 {
      padding-bottom: 0!important
  }

  .pt-lg-0 {
      padding-top: 0!important
  }

  .ma-lg-0 {
      margin: 0!important
  }

  .my-lg-0 {
      margin-bottom: 0!important;
      margin-top: 0!important
  }

  .mx-lg-0 {
      margin-left: 0!important;
      margin-right: 0!important
  }

  .mt-lg-0 {
      margin-top: 0!important
  }

  .mb-lg-0 {
      margin-bottom: 0!important
  }

  .ml-lg-0 {
      margin-left: 0!important
  }

  .mr-lg-0 {
      margin-right: 0!important
  }

  .pa-lg-1 {
      padding: .0625rem!important
  }

  .py-lg-1 {
      padding-bottom: .0625rem!important;
      padding-top: .0625rem!important
  }

  .px-lg-1 {
      padding-right: .0625rem!important
  }

  .pl-lg-1,.px-lg-1 {
      padding-left: .0625rem!important
  }

  .pr-lg-1 {
      padding-right: .0625rem!important
  }

  .pb-lg-1 {
      padding-bottom: .0625rem!important
  }

  .pt-lg-1 {
      padding-top: .0625rem!important
  }

  .ma-lg-1 {
      margin: .0625rem!important
  }

  .my-lg-1 {
      margin-bottom: .0625rem!important;
      margin-top: .0625rem!important
  }

  .mx-lg-1 {
      margin-left: .0625rem!important;
      margin-right: .0625rem!important
  }

  .mt-lg-1 {
      margin-top: .0625rem!important
  }

  .mb-lg-1 {
      margin-bottom: .0625rem!important
  }

  .ml-lg-1 {
      margin-left: .0625rem!important
  }

  .mr-lg-1 {
      margin-right: .0625rem!important
  }

  .pa-lg-2 {
      padding: .125rem!important
  }

  .py-lg-2 {
      padding-bottom: .125rem!important;
      padding-top: .125rem!important
  }

  .px-lg-2 {
      padding-right: .125rem!important
  }

  .pl-lg-2,.px-lg-2 {
      padding-left: .125rem!important
  }

  .pr-lg-2 {
      padding-right: .125rem!important
  }

  .pb-lg-2 {
      padding-bottom: .125rem!important
  }

  .pt-lg-2 {
      padding-top: .125rem!important
  }

  .ma-lg-2 {
      margin: .125rem!important
  }

  .my-lg-2 {
      margin-bottom: .125rem!important;
      margin-top: .125rem!important
  }

  .mx-lg-2 {
      margin-left: .125rem!important;
      margin-right: .125rem!important
  }

  .mt-lg-2 {
      margin-top: .125rem!important
  }

  .mb-lg-2 {
      margin-bottom: .125rem!important
  }

  .ml-lg-2 {
      margin-left: .125rem!important
  }

  .mr-lg-2 {
      margin-right: .125rem!important
  }

  .pa-lg-4 {
      padding: .25rem!important
  }

  .py-lg-4 {
      padding-bottom: .25rem!important;
      padding-top: .25rem!important
  }

  .px-lg-4 {
      padding-right: .25rem!important
  }

  .pl-lg-4,.px-lg-4 {
      padding-left: .25rem!important
  }

  .pr-lg-4 {
      padding-right: .25rem!important
  }

  .pb-lg-4 {
      padding-bottom: .25rem!important
  }

  .pt-lg-4 {
      padding-top: .25rem!important
  }

  .ma-lg-4 {
      margin: .25rem!important
  }

  .my-lg-4 {
      margin-bottom: .25rem!important;
      margin-top: .25rem!important
  }

  .mx-lg-4 {
      margin-left: .25rem!important;
      margin-right: .25rem!important
  }

  .mt-lg-4 {
      margin-top: .25rem!important
  }

  .mb-lg-4 {
      margin-bottom: .25rem!important
  }

  .ml-lg-4 {
      margin-left: .25rem!important
  }

  .mr-lg-4 {
      margin-right: .25rem!important
  }

  .pa-lg-5 {
      padding: .3125rem!important
  }

  .py-lg-5 {
      padding-bottom: .3125rem!important;
      padding-top: .3125rem!important
  }

  .px-lg-5 {
      padding-right: .3125rem!important
  }

  .pl-lg-5,.px-lg-5 {
      padding-left: .3125rem!important
  }

  .pr-lg-5 {
      padding-right: .3125rem!important
  }

  .pb-lg-5 {
      padding-bottom: .3125rem!important
  }

  .pt-lg-5 {
      padding-top: .3125rem!important
  }

  .ma-lg-5 {
      margin: .3125rem!important
  }

  .my-lg-5 {
      margin-bottom: .3125rem!important;
      margin-top: .3125rem!important
  }

  .mx-lg-5 {
      margin-left: .3125rem!important;
      margin-right: .3125rem!important
  }

  .mt-lg-5 {
      margin-top: .3125rem!important
  }

  .mb-lg-5 {
      margin-bottom: .3125rem!important
  }

  .ml-lg-5 {
      margin-left: .3125rem!important
  }

  .mr-lg-5 {
      margin-right: .3125rem!important
  }

  .pa-lg-8 {
      padding: .5rem!important
  }

  .py-lg-8 {
      padding-bottom: .5rem!important;
      padding-top: .5rem!important
  }

  .px-lg-8 {
      padding-right: .5rem!important
  }

  .pl-lg-8,.px-lg-8 {
      padding-left: .5rem!important
  }

  .pr-lg-8 {
      padding-right: .5rem!important
  }

  .pb-lg-8 {
      padding-bottom: .5rem!important
  }

  .pt-lg-8 {
      padding-top: .5rem!important
  }

  .ma-lg-8 {
      margin: .5rem!important
  }

  .my-lg-8 {
      margin-bottom: .5rem!important;
      margin-top: .5rem!important
  }

  .mx-lg-8 {
      margin-left: .5rem!important;
      margin-right: .5rem!important
  }

  .mt-lg-8 {
      margin-top: .5rem!important
  }

  .mb-lg-8 {
      margin-bottom: .5rem!important
  }

  .ml-lg-8 {
      margin-left: .5rem!important
  }

  .mr-lg-8 {
      margin-right: .5rem!important
  }

  .pa-lg-10 {
      padding: .625rem!important
  }

  .py-lg-10 {
      padding-bottom: .625rem!important;
      padding-top: .625rem!important
  }

  .px-lg-10 {
      padding-right: .625rem!important
  }

  .pl-lg-10,.px-lg-10 {
      padding-left: .625rem!important
  }

  .pr-lg-10 {
      padding-right: .625rem!important
  }

  .pb-lg-10 {
      padding-bottom: .625rem!important
  }

  .pt-lg-10 {
      padding-top: .625rem!important
  }

  .ma-lg-10 {
      margin: .625rem!important
  }

  .my-lg-10 {
      margin-bottom: .625rem!important;
      margin-top: .625rem!important
  }

  .mx-lg-10 {
      margin-left: .625rem!important;
      margin-right: .625rem!important
  }

  .mt-lg-10 {
      margin-top: .625rem!important
  }

  .mb-lg-10 {
      margin-bottom: .625rem!important
  }

  .ml-lg-10 {
      margin-left: .625rem!important
  }

  .mr-lg-10 {
      margin-right: .625rem!important
  }

  .pa-lg-12 {
      padding: .75rem!important
  }

  .py-lg-12 {
      padding-bottom: .75rem!important;
      padding-top: .75rem!important
  }

  .px-lg-12 {
      padding-right: .75rem!important
  }

  .pl-lg-12,.px-lg-12 {
      padding-left: .75rem!important
  }

  .pr-lg-12 {
      padding-right: .75rem!important
  }

  .pb-lg-12 {
      padding-bottom: .75rem!important
  }

  .pt-lg-12 {
      padding-top: .75rem!important
  }

  .ma-lg-12 {
      margin: .75rem!important
  }

  .my-lg-12 {
      margin-bottom: .75rem!important;
      margin-top: .75rem!important
  }

  .mx-lg-12 {
      margin-left: .75rem!important;
      margin-right: .75rem!important
  }

  .mt-lg-12 {
      margin-top: .75rem!important
  }

  .mb-lg-12 {
      margin-bottom: .75rem!important
  }

  .ml-lg-12 {
      margin-left: .75rem!important
  }

  .mr-lg-12 {
      margin-right: .75rem!important
  }

  .pa-lg-16 {
      padding: 1rem!important
  }

  .py-lg-16 {
      padding-bottom: 1rem!important;
      padding-top: 1rem!important
  }

  .px-lg-16 {
      padding-right: 1rem!important
  }

  .pl-lg-16,.px-lg-16 {
      padding-left: 1rem!important
  }

  .pr-lg-16 {
      padding-right: 1rem!important
  }

  .pb-lg-16 {
      padding-bottom: 1rem!important
  }

  .pt-lg-16 {
      padding-top: 1rem!important
  }

  .ma-lg-16 {
      margin: 1rem!important
  }

  .my-lg-16 {
      margin-bottom: 1rem!important;
      margin-top: 1rem!important
  }

  .mx-lg-16 {
      margin-left: 1rem!important;
      margin-right: 1rem!important
  }

  .mt-lg-16 {
      margin-top: 1rem!important
  }

  .mb-lg-16 {
      margin-bottom: 1rem!important
  }

  .ml-lg-16 {
      margin-left: 1rem!important
  }

  .mr-lg-16 {
      margin-right: 1rem!important
  }

  .pa-lg-20 {
      padding: 1.25rem!important
  }

  .py-lg-20 {
      padding-bottom: 1.25rem!important;
      padding-top: 1.25rem!important
  }

  .px-lg-20 {
      padding-right: 1.25rem!important
  }

  .pl-lg-20,.px-lg-20 {
      padding-left: 1.25rem!important
  }

  .pr-lg-20 {
      padding-right: 1.25rem!important
  }

  .pb-lg-20 {
      padding-bottom: 1.25rem!important
  }

  .pt-lg-20 {
      padding-top: 1.25rem!important
  }

  .ma-lg-20 {
      margin: 1.25rem!important
  }

  .my-lg-20 {
      margin-bottom: 1.25rem!important;
      margin-top: 1.25rem!important
  }

  .mx-lg-20 {
      margin-left: 1.25rem!important;
      margin-right: 1.25rem!important
  }

  .mt-lg-20 {
      margin-top: 1.25rem!important
  }

  .mb-lg-20 {
      margin-bottom: 1.25rem!important
  }

  .ml-lg-20 {
      margin-left: 1.25rem!important
  }

  .mr-lg-20 {
      margin-right: 1.25rem!important
  }

  .pa-lg-24 {
      padding: 1.5rem!important
  }

  .py-lg-24 {
      padding-bottom: 1.5rem!important;
      padding-top: 1.5rem!important
  }

  .px-lg-24 {
      padding-right: 1.5rem!important
  }

  .pl-lg-24,.px-lg-24 {
      padding-left: 1.5rem!important
  }

  .pr-lg-24 {
      padding-right: 1.5rem!important
  }

  .pb-lg-24 {
      padding-bottom: 1.5rem!important
  }

  .pt-lg-24 {
      padding-top: 1.5rem!important
  }

  .ma-lg-24 {
      margin: 1.5rem!important
  }

  .my-lg-24 {
      margin-bottom: 1.5rem!important;
      margin-top: 1.5rem!important
  }

  .mx-lg-24 {
      margin-left: 1.5rem!important;
      margin-right: 1.5rem!important
  }

  .mt-lg-24 {
      margin-top: 1.5rem!important
  }

  .mb-lg-24 {
      margin-bottom: 1.5rem!important
  }

  .ml-lg-24 {
      margin-left: 1.5rem!important
  }

  .mr-lg-24 {
      margin-right: 1.5rem!important
  }

  .pa-lg-30 {
      padding: 1.875rem!important
  }

  .py-lg-30 {
      padding-bottom: 1.875rem!important;
      padding-top: 1.875rem!important
  }

  .px-lg-30 {
      padding-right: 1.875rem!important
  }

  .pl-lg-30,.px-lg-30 {
      padding-left: 1.875rem!important
  }

  .pr-lg-30 {
      padding-right: 1.875rem!important
  }

  .pb-lg-30 {
      padding-bottom: 1.875rem!important
  }

  .pt-lg-30 {
      padding-top: 1.875rem!important
  }

  .ma-lg-30 {
      margin: 1.875rem!important
  }

  .my-lg-30 {
      margin-bottom: 1.875rem!important;
      margin-top: 1.875rem!important
  }

  .mx-lg-30 {
      margin-left: 1.875rem!important;
      margin-right: 1.875rem!important
  }

  .mt-lg-30 {
      margin-top: 1.875rem!important
  }

  .mb-lg-30 {
      margin-bottom: 1.875rem!important
  }

  .ml-lg-30 {
      margin-left: 1.875rem!important
  }

  .mr-lg-30 {
      margin-right: 1.875rem!important
  }

  .pa-lg-32 {
      padding: 2rem!important
  }

  .py-lg-32 {
      padding-bottom: 2rem!important;
      padding-top: 2rem!important
  }

  .px-lg-32 {
      padding-right: 2rem!important
  }

  .pl-lg-32,.px-lg-32 {
      padding-left: 2rem!important
  }

  .pr-lg-32 {
      padding-right: 2rem!important
  }

  .pb-lg-32 {
      padding-bottom: 2rem!important
  }

  .pt-lg-32 {
      padding-top: 2rem!important
  }

  .ma-lg-32 {
      margin: 2rem!important
  }

  .my-lg-32 {
      margin-bottom: 2rem!important;
      margin-top: 2rem!important
  }

  .mx-lg-32 {
      margin-left: 2rem!important;
      margin-right: 2rem!important
  }

  .mt-lg-32 {
      margin-top: 2rem!important
  }

  .mb-lg-32 {
      margin-bottom: 2rem!important
  }

  .ml-lg-32 {
      margin-left: 2rem!important
  }

  .mr-lg-32 {
      margin-right: 2rem!important
  }

  .pa-lg-40 {
      padding: 2.5rem!important
  }

  .py-lg-40 {
      padding-bottom: 2.5rem!important;
      padding-top: 2.5rem!important
  }

  .px-lg-40 {
      padding-right: 2.5rem!important
  }

  .pl-lg-40,.px-lg-40 {
      padding-left: 2.5rem!important
  }

  .pr-lg-40 {
      padding-right: 2.5rem!important
  }

  .pb-lg-40 {
      padding-bottom: 2.5rem!important
  }

  .pt-lg-40 {
      padding-top: 2.5rem!important
  }

  .ma-lg-40 {
      margin: 2.5rem!important
  }

  .my-lg-40 {
      margin-bottom: 2.5rem!important;
      margin-top: 2.5rem!important
  }

  .mx-lg-40 {
      margin-left: 2.5rem!important;
      margin-right: 2.5rem!important
  }

  .mt-lg-40 {
      margin-top: 2.5rem!important
  }

  .mb-lg-40 {
      margin-bottom: 2.5rem!important
  }

  .ml-lg-40 {
      margin-left: 2.5rem!important
  }

  .mr-lg-40 {
      margin-right: 2.5rem!important
  }

  .pa-lg-48 {
      padding: 3rem!important
  }

  .py-lg-48 {
      padding-bottom: 3rem!important;
      padding-top: 3rem!important
  }

  .px-lg-48 {
      padding-right: 3rem!important
  }

  .pl-lg-48,.px-lg-48 {
      padding-left: 3rem!important
  }

  .pr-lg-48 {
      padding-right: 3rem!important
  }

  .pb-lg-48 {
      padding-bottom: 3rem!important
  }

  .pt-lg-48 {
      padding-top: 3rem!important
  }

  .ma-lg-48 {
      margin: 3rem!important
  }

  .my-lg-48 {
      margin-bottom: 3rem!important;
      margin-top: 3rem!important
  }

  .mx-lg-48 {
      margin-left: 3rem!important;
      margin-right: 3rem!important
  }

  .mt-lg-48 {
      margin-top: 3rem!important
  }

  .mb-lg-48 {
      margin-bottom: 3rem!important
  }

  .ml-lg-48 {
      margin-left: 3rem!important
  }

  .mr-lg-48 {
      margin-right: 3rem!important
  }

  .pa-lg-50 {
      padding: 3.125rem!important
  }

  .py-lg-50 {
      padding-bottom: 3.125rem!important;
      padding-top: 3.125rem!important
  }

  .px-lg-50 {
      padding-right: 3.125rem!important
  }

  .pl-lg-50,.px-lg-50 {
      padding-left: 3.125rem!important
  }

  .pr-lg-50 {
      padding-right: 3.125rem!important
  }

  .pb-lg-50 {
      padding-bottom: 3.125rem!important
  }

  .pt-lg-50 {
      padding-top: 3.125rem!important
  }

  .ma-lg-50 {
      margin: 3.125rem!important
  }

  .my-lg-50 {
      margin-bottom: 3.125rem!important;
      margin-top: 3.125rem!important
  }

  .mx-lg-50 {
      margin-left: 3.125rem!important;
      margin-right: 3.125rem!important
  }

  .mt-lg-50 {
      margin-top: 3.125rem!important
  }

  .mb-lg-50 {
      margin-bottom: 3.125rem!important
  }

  .ml-lg-50 {
      margin-left: 3.125rem!important
  }

  .mr-lg-50 {
      margin-right: 3.125rem!important
  }

  .pa-lg-60 {
      padding: 3.75rem!important
  }

  .py-lg-60 {
      padding-bottom: 3.75rem!important;
      padding-top: 3.75rem!important
  }

  .px-lg-60 {
      padding-right: 3.75rem!important
  }

  .pl-lg-60,.px-lg-60 {
      padding-left: 3.75rem!important
  }

  .pr-lg-60 {
      padding-right: 3.75rem!important
  }

  .pb-lg-60 {
      padding-bottom: 3.75rem!important
  }

  .pt-lg-60 {
      padding-top: 3.75rem!important
  }

  .ma-lg-60 {
      margin: 3.75rem!important
  }

  .my-lg-60 {
      margin-bottom: 3.75rem!important;
      margin-top: 3.75rem!important
  }

  .mx-lg-60 {
      margin-left: 3.75rem!important;
      margin-right: 3.75rem!important
  }

  .mt-lg-60 {
      margin-top: 3.75rem!important
  }

  .mb-lg-60 {
      margin-bottom: 3.75rem!important
  }

  .ml-lg-60 {
      margin-left: 3.75rem!important
  }

  .mr-lg-60 {
      margin-right: 3.75rem!important
  }
}

@media (min-width: 1200px) {
  .pa-xl-0 {
      padding:0!important
  }

  .py-xl-0 {
      padding-bottom: 0!important;
      padding-top: 0!important
  }

  .px-xl-0 {
      padding-right: 0!important
  }

  .pl-xl-0,.px-xl-0 {
      padding-left: 0!important
  }

  .pr-xl-0 {
      padding-right: 0!important
  }

  .pb-xl-0 {
      padding-bottom: 0!important
  }

  .pt-xl-0 {
      padding-top: 0!important
  }

  .ma-xl-0 {
      margin: 0!important
  }

  .my-xl-0 {
      margin-bottom: 0!important;
      margin-top: 0!important
  }

  .mx-xl-0 {
      margin-left: 0!important;
      margin-right: 0!important
  }

  .mt-xl-0 {
      margin-top: 0!important
  }

  .mb-xl-0 {
      margin-bottom: 0!important
  }

  .ml-xl-0 {
      margin-left: 0!important
  }

  .mr-xl-0 {
      margin-right: 0!important
  }

  .pa-xl-1 {
      padding: .0625rem!important
  }

  .py-xl-1 {
      padding-bottom: .0625rem!important;
      padding-top: .0625rem!important
  }

  .px-xl-1 {
      padding-right: .0625rem!important
  }

  .pl-xl-1,.px-xl-1 {
      padding-left: .0625rem!important
  }

  .pr-xl-1 {
      padding-right: .0625rem!important
  }

  .pb-xl-1 {
      padding-bottom: .0625rem!important
  }

  .pt-xl-1 {
      padding-top: .0625rem!important
  }

  .ma-xl-1 {
      margin: .0625rem!important
  }

  .my-xl-1 {
      margin-bottom: .0625rem!important;
      margin-top: .0625rem!important
  }

  .mx-xl-1 {
      margin-left: .0625rem!important;
      margin-right: .0625rem!important
  }

  .mt-xl-1 {
      margin-top: .0625rem!important
  }

  .mb-xl-1 {
      margin-bottom: .0625rem!important
  }

  .ml-xl-1 {
      margin-left: .0625rem!important
  }

  .mr-xl-1 {
      margin-right: .0625rem!important
  }

  .pa-xl-2 {
      padding: .125rem!important
  }

  .py-xl-2 {
      padding-bottom: .125rem!important;
      padding-top: .125rem!important
  }

  .px-xl-2 {
      padding-right: .125rem!important
  }

  .pl-xl-2,.px-xl-2 {
      padding-left: .125rem!important
  }

  .pr-xl-2 {
      padding-right: .125rem!important
  }

  .pb-xl-2 {
      padding-bottom: .125rem!important
  }

  .pt-xl-2 {
      padding-top: .125rem!important
  }

  .ma-xl-2 {
      margin: .125rem!important
  }

  .my-xl-2 {
      margin-bottom: .125rem!important;
      margin-top: .125rem!important
  }

  .mx-xl-2 {
      margin-left: .125rem!important;
      margin-right: .125rem!important
  }

  .mt-xl-2 {
      margin-top: .125rem!important
  }

  .mb-xl-2 {
      margin-bottom: .125rem!important
  }

  .ml-xl-2 {
      margin-left: .125rem!important
  }

  .mr-xl-2 {
      margin-right: .125rem!important
  }

  .pa-xl-4 {
      padding: .25rem!important
  }

  .py-xl-4 {
      padding-bottom: .25rem!important;
      padding-top: .25rem!important
  }

  .px-xl-4 {
      padding-right: .25rem!important
  }

  .pl-xl-4,.px-xl-4 {
      padding-left: .25rem!important
  }

  .pr-xl-4 {
      padding-right: .25rem!important
  }

  .pb-xl-4 {
      padding-bottom: .25rem!important
  }

  .pt-xl-4 {
      padding-top: .25rem!important
  }

  .ma-xl-4 {
      margin: .25rem!important
  }

  .my-xl-4 {
      margin-bottom: .25rem!important;
      margin-top: .25rem!important
  }

  .mx-xl-4 {
      margin-left: .25rem!important;
      margin-right: .25rem!important
  }

  .mt-xl-4 {
      margin-top: .25rem!important
  }

  .mb-xl-4 {
      margin-bottom: .25rem!important
  }

  .ml-xl-4 {
      margin-left: .25rem!important
  }

  .mr-xl-4 {
      margin-right: .25rem!important
  }

  .pa-xl-5 {
      padding: .3125rem!important
  }

  .py-xl-5 {
      padding-bottom: .3125rem!important;
      padding-top: .3125rem!important
  }

  .px-xl-5 {
      padding-right: .3125rem!important
  }

  .pl-xl-5,.px-xl-5 {
      padding-left: .3125rem!important
  }

  .pr-xl-5 {
      padding-right: .3125rem!important
  }

  .pb-xl-5 {
      padding-bottom: .3125rem!important
  }

  .pt-xl-5 {
      padding-top: .3125rem!important
  }

  .ma-xl-5 {
      margin: .3125rem!important
  }

  .my-xl-5 {
      margin-bottom: .3125rem!important;
      margin-top: .3125rem!important
  }

  .mx-xl-5 {
      margin-left: .3125rem!important;
      margin-right: .3125rem!important
  }

  .mt-xl-5 {
      margin-top: .3125rem!important
  }

  .mb-xl-5 {
      margin-bottom: .3125rem!important
  }

  .ml-xl-5 {
      margin-left: .3125rem!important
  }

  .mr-xl-5 {
      margin-right: .3125rem!important
  }

  .pa-xl-8 {
      padding: .5rem!important
  }

  .py-xl-8 {
      padding-bottom: .5rem!important;
      padding-top: .5rem!important
  }

  .px-xl-8 {
      padding-right: .5rem!important
  }

  .pl-xl-8,.px-xl-8 {
      padding-left: .5rem!important
  }

  .pr-xl-8 {
      padding-right: .5rem!important
  }

  .pb-xl-8 {
      padding-bottom: .5rem!important
  }

  .pt-xl-8 {
      padding-top: .5rem!important
  }

  .ma-xl-8 {
      margin: .5rem!important
  }

  .my-xl-8 {
      margin-bottom: .5rem!important;
      margin-top: .5rem!important
  }

  .mx-xl-8 {
      margin-left: .5rem!important;
      margin-right: .5rem!important
  }

  .mt-xl-8 {
      margin-top: .5rem!important
  }

  .mb-xl-8 {
      margin-bottom: .5rem!important
  }

  .ml-xl-8 {
      margin-left: .5rem!important
  }

  .mr-xl-8 {
      margin-right: .5rem!important
  }

  .pa-xl-10 {
      padding: .625rem!important
  }

  .py-xl-10 {
      padding-bottom: .625rem!important;
      padding-top: .625rem!important
  }

  .px-xl-10 {
      padding-right: .625rem!important
  }

  .pl-xl-10,.px-xl-10 {
      padding-left: .625rem!important
  }

  .pr-xl-10 {
      padding-right: .625rem!important
  }

  .pb-xl-10 {
      padding-bottom: .625rem!important
  }

  .pt-xl-10 {
      padding-top: .625rem!important
  }

  .ma-xl-10 {
      margin: .625rem!important
  }

  .my-xl-10 {
      margin-bottom: .625rem!important;
      margin-top: .625rem!important
  }

  .mx-xl-10 {
      margin-left: .625rem!important;
      margin-right: .625rem!important
  }

  .mt-xl-10 {
      margin-top: .625rem!important
  }

  .mb-xl-10 {
      margin-bottom: .625rem!important
  }

  .ml-xl-10 {
      margin-left: .625rem!important
  }

  .mr-xl-10 {
      margin-right: .625rem!important
  }

  .pa-xl-12 {
      padding: .75rem!important
  }

  .py-xl-12 {
      padding-bottom: .75rem!important;
      padding-top: .75rem!important
  }

  .px-xl-12 {
      padding-right: .75rem!important
  }

  .pl-xl-12,.px-xl-12 {
      padding-left: .75rem!important
  }

  .pr-xl-12 {
      padding-right: .75rem!important
  }

  .pb-xl-12 {
      padding-bottom: .75rem!important
  }

  .pt-xl-12 {
      padding-top: .75rem!important
  }

  .ma-xl-12 {
      margin: .75rem!important
  }

  .my-xl-12 {
      margin-bottom: .75rem!important;
      margin-top: .75rem!important
  }

  .mx-xl-12 {
      margin-left: .75rem!important;
      margin-right: .75rem!important
  }

  .mt-xl-12 {
      margin-top: .75rem!important
  }

  .mb-xl-12 {
      margin-bottom: .75rem!important
  }

  .ml-xl-12 {
      margin-left: .75rem!important
  }

  .mr-xl-12 {
      margin-right: .75rem!important
  }

  .pa-xl-16 {
      padding: 1rem!important
  }

  .py-xl-16 {
      padding-bottom: 1rem!important;
      padding-top: 1rem!important
  }

  .px-xl-16 {
      padding-right: 1rem!important
  }

  .pl-xl-16,.px-xl-16 {
      padding-left: 1rem!important
  }

  .pr-xl-16 {
      padding-right: 1rem!important
  }

  .pb-xl-16 {
      padding-bottom: 1rem!important
  }

  .pt-xl-16 {
      padding-top: 1rem!important
  }

  .ma-xl-16 {
      margin: 1rem!important
  }

  .my-xl-16 {
      margin-bottom: 1rem!important;
      margin-top: 1rem!important
  }

  .mx-xl-16 {
      margin-left: 1rem!important;
      margin-right: 1rem!important
  }

  .mt-xl-16 {
      margin-top: 1rem!important
  }

  .mb-xl-16 {
      margin-bottom: 1rem!important
  }

  .ml-xl-16 {
      margin-left: 1rem!important
  }

  .mr-xl-16 {
      margin-right: 1rem!important
  }

  .pa-xl-20 {
      padding: 1.25rem!important
  }

  .py-xl-20 {
      padding-bottom: 1.25rem!important;
      padding-top: 1.25rem!important
  }

  .px-xl-20 {
      padding-right: 1.25rem!important
  }

  .pl-xl-20,.px-xl-20 {
      padding-left: 1.25rem!important
  }

  .pr-xl-20 {
      padding-right: 1.25rem!important
  }

  .pb-xl-20 {
      padding-bottom: 1.25rem!important
  }

  .pt-xl-20 {
      padding-top: 1.25rem!important
  }

  .ma-xl-20 {
      margin: 1.25rem!important
  }

  .my-xl-20 {
      margin-bottom: 1.25rem!important;
      margin-top: 1.25rem!important
  }

  .mx-xl-20 {
      margin-left: 1.25rem!important;
      margin-right: 1.25rem!important
  }

  .mt-xl-20 {
      margin-top: 1.25rem!important
  }

  .mb-xl-20 {
      margin-bottom: 1.25rem!important
  }

  .ml-xl-20 {
      margin-left: 1.25rem!important
  }

  .mr-xl-20 {
      margin-right: 1.25rem!important
  }

  .pa-xl-24 {
      padding: 1.5rem!important
  }

  .py-xl-24 {
      padding-bottom: 1.5rem!important;
      padding-top: 1.5rem!important
  }

  .px-xl-24 {
      padding-right: 1.5rem!important
  }

  .pl-xl-24,.px-xl-24 {
      padding-left: 1.5rem!important
  }

  .pr-xl-24 {
      padding-right: 1.5rem!important
  }

  .pb-xl-24 {
      padding-bottom: 1.5rem!important
  }

  .pt-xl-24 {
      padding-top: 1.5rem!important
  }

  .ma-xl-24 {
      margin: 1.5rem!important
  }

  .my-xl-24 {
      margin-bottom: 1.5rem!important;
      margin-top: 1.5rem!important
  }

  .mx-xl-24 {
      margin-left: 1.5rem!important;
      margin-right: 1.5rem!important
  }

  .mt-xl-24 {
      margin-top: 1.5rem!important
  }

  .mb-xl-24 {
      margin-bottom: 1.5rem!important
  }

  .ml-xl-24 {
      margin-left: 1.5rem!important
  }

  .mr-xl-24 {
      margin-right: 1.5rem!important
  }

  .pa-xl-30 {
      padding: 1.875rem!important
  }

  .py-xl-30 {
      padding-bottom: 1.875rem!important;
      padding-top: 1.875rem!important
  }

  .px-xl-30 {
      padding-right: 1.875rem!important
  }

  .pl-xl-30,.px-xl-30 {
      padding-left: 1.875rem!important
  }

  .pr-xl-30 {
      padding-right: 1.875rem!important
  }

  .pb-xl-30 {
      padding-bottom: 1.875rem!important
  }

  .pt-xl-30 {
      padding-top: 1.875rem!important
  }

  .ma-xl-30 {
      margin: 1.875rem!important
  }

  .my-xl-30 {
      margin-bottom: 1.875rem!important;
      margin-top: 1.875rem!important
  }

  .mx-xl-30 {
      margin-left: 1.875rem!important;
      margin-right: 1.875rem!important
  }

  .mt-xl-30 {
      margin-top: 1.875rem!important
  }

  .mb-xl-30 {
      margin-bottom: 1.875rem!important
  }

  .ml-xl-30 {
      margin-left: 1.875rem!important
  }

  .mr-xl-30 {
      margin-right: 1.875rem!important
  }

  .pa-xl-32 {
      padding: 2rem!important
  }

  .py-xl-32 {
      padding-bottom: 2rem!important;
      padding-top: 2rem!important
  }

  .px-xl-32 {
      padding-right: 2rem!important
  }

  .pl-xl-32,.px-xl-32 {
      padding-left: 2rem!important
  }

  .pr-xl-32 {
      padding-right: 2rem!important
  }

  .pb-xl-32 {
      padding-bottom: 2rem!important
  }

  .pt-xl-32 {
      padding-top: 2rem!important
  }

  .ma-xl-32 {
      margin: 2rem!important
  }

  .my-xl-32 {
      margin-bottom: 2rem!important;
      margin-top: 2rem!important
  }

  .mx-xl-32 {
      margin-left: 2rem!important;
      margin-right: 2rem!important
  }

  .mt-xl-32 {
      margin-top: 2rem!important
  }

  .mb-xl-32 {
      margin-bottom: 2rem!important
  }

  .ml-xl-32 {
      margin-left: 2rem!important
  }

  .mr-xl-32 {
      margin-right: 2rem!important
  }

  .pa-xl-40 {
      padding: 2.5rem!important
  }

  .py-xl-40 {
      padding-bottom: 2.5rem!important;
      padding-top: 2.5rem!important
  }

  .px-xl-40 {
      padding-right: 2.5rem!important
  }

  .pl-xl-40,.px-xl-40 {
      padding-left: 2.5rem!important
  }

  .pr-xl-40 {
      padding-right: 2.5rem!important
  }

  .pb-xl-40 {
      padding-bottom: 2.5rem!important
  }

  .pt-xl-40 {
      padding-top: 2.5rem!important
  }

  .ma-xl-40 {
      margin: 2.5rem!important
  }

  .my-xl-40 {
      margin-bottom: 2.5rem!important;
      margin-top: 2.5rem!important
  }

  .mx-xl-40 {
      margin-left: 2.5rem!important;
      margin-right: 2.5rem!important
  }

  .mt-xl-40 {
      margin-top: 2.5rem!important
  }

  .mb-xl-40 {
      margin-bottom: 2.5rem!important
  }

  .ml-xl-40 {
      margin-left: 2.5rem!important
  }

  .mr-xl-40 {
      margin-right: 2.5rem!important
  }

  .pa-xl-48 {
      padding: 3rem!important
  }

  .py-xl-48 {
      padding-bottom: 3rem!important;
      padding-top: 3rem!important
  }

  .px-xl-48 {
      padding-right: 3rem!important
  }

  .pl-xl-48,.px-xl-48 {
      padding-left: 3rem!important
  }

  .pr-xl-48 {
      padding-right: 3rem!important
  }

  .pb-xl-48 {
      padding-bottom: 3rem!important
  }

  .pt-xl-48 {
      padding-top: 3rem!important
  }

  .ma-xl-48 {
      margin: 3rem!important
  }

  .my-xl-48 {
      margin-bottom: 3rem!important;
      margin-top: 3rem!important
  }

  .mx-xl-48 {
      margin-left: 3rem!important;
      margin-right: 3rem!important
  }

  .mt-xl-48 {
      margin-top: 3rem!important
  }

  .mb-xl-48 {
      margin-bottom: 3rem!important
  }

  .ml-xl-48 {
      margin-left: 3rem!important
  }

  .mr-xl-48 {
      margin-right: 3rem!important
  }

  .pa-xl-50 {
      padding: 3.125rem!important
  }

  .py-xl-50 {
      padding-bottom: 3.125rem!important;
      padding-top: 3.125rem!important
  }

  .px-xl-50 {
      padding-right: 3.125rem!important
  }

  .pl-xl-50,.px-xl-50 {
      padding-left: 3.125rem!important
  }

  .pr-xl-50 {
      padding-right: 3.125rem!important
  }

  .pb-xl-50 {
      padding-bottom: 3.125rem!important
  }

  .pt-xl-50 {
      padding-top: 3.125rem!important
  }

  .ma-xl-50 {
      margin: 3.125rem!important
  }

  .my-xl-50 {
      margin-bottom: 3.125rem!important;
      margin-top: 3.125rem!important
  }

  .mx-xl-50 {
      margin-left: 3.125rem!important;
      margin-right: 3.125rem!important
  }

  .mt-xl-50 {
      margin-top: 3.125rem!important
  }

  .mb-xl-50 {
      margin-bottom: 3.125rem!important
  }

  .ml-xl-50 {
      margin-left: 3.125rem!important
  }

  .mr-xl-50 {
      margin-right: 3.125rem!important
  }

  .pa-xl-60 {
      padding: 3.75rem!important
  }

  .py-xl-60 {
      padding-bottom: 3.75rem!important;
      padding-top: 3.75rem!important
  }

  .px-xl-60 {
      padding-right: 3.75rem!important
  }

  .pl-xl-60,.px-xl-60 {
      padding-left: 3.75rem!important
  }

  .pr-xl-60 {
      padding-right: 3.75rem!important
  }

  .pb-xl-60 {
      padding-bottom: 3.75rem!important
  }

  .pt-xl-60 {
      padding-top: 3.75rem!important
  }

  .ma-xl-60 {
      margin: 3.75rem!important
  }

  .my-xl-60 {
      margin-bottom: 3.75rem!important;
      margin-top: 3.75rem!important
  }

  .mx-xl-60 {
      margin-left: 3.75rem!important;
      margin-right: 3.75rem!important
  }

  .mt-xl-60 {
      margin-top: 3.75rem!important
  }

  .mb-xl-60 {
      margin-bottom: 3.75rem!important
  }

  .ml-xl-60 {
      margin-left: 3.75rem!important
  }

  .mr-xl-60 {
      margin-right: 3.75rem!important
  }
}

@media (min-width: 1400px) {
  .pa-xxl-0 {
      padding:0!important
  }

  .py-xxl-0 {
      padding-bottom: 0!important;
      padding-top: 0!important
  }

  .px-xxl-0 {
      padding-right: 0!important
  }

  .pl-xxl-0,.px-xxl-0 {
      padding-left: 0!important
  }

  .pr-xxl-0 {
      padding-right: 0!important
  }

  .pb-xxl-0 {
      padding-bottom: 0!important
  }

  .pt-xxl-0 {
      padding-top: 0!important
  }

  .ma-xxl-0 {
      margin: 0!important
  }

  .my-xxl-0 {
      margin-bottom: 0!important;
      margin-top: 0!important
  }

  .mx-xxl-0 {
      margin-left: 0!important;
      margin-right: 0!important
  }

  .mt-xxl-0 {
      margin-top: 0!important
  }

  .mb-xxl-0 {
      margin-bottom: 0!important
  }

  .ml-xxl-0 {
      margin-left: 0!important
  }

  .mr-xxl-0 {
      margin-right: 0!important
  }

  .pa-xxl-1 {
      padding: .0625rem!important
  }

  .py-xxl-1 {
      padding-bottom: .0625rem!important;
      padding-top: .0625rem!important
  }

  .px-xxl-1 {
      padding-right: .0625rem!important
  }

  .pl-xxl-1,.px-xxl-1 {
      padding-left: .0625rem!important
  }

  .pr-xxl-1 {
      padding-right: .0625rem!important
  }

  .pb-xxl-1 {
      padding-bottom: .0625rem!important
  }

  .pt-xxl-1 {
      padding-top: .0625rem!important
  }

  .ma-xxl-1 {
      margin: .0625rem!important
  }

  .my-xxl-1 {
      margin-bottom: .0625rem!important;
      margin-top: .0625rem!important
  }

  .mx-xxl-1 {
      margin-left: .0625rem!important;
      margin-right: .0625rem!important
  }

  .mt-xxl-1 {
      margin-top: .0625rem!important
  }

  .mb-xxl-1 {
      margin-bottom: .0625rem!important
  }

  .ml-xxl-1 {
      margin-left: .0625rem!important
  }

  .mr-xxl-1 {
      margin-right: .0625rem!important
  }

  .pa-xxl-2 {
      padding: .125rem!important
  }

  .py-xxl-2 {
      padding-bottom: .125rem!important;
      padding-top: .125rem!important
  }

  .px-xxl-2 {
      padding-right: .125rem!important
  }

  .pl-xxl-2,.px-xxl-2 {
      padding-left: .125rem!important
  }

  .pr-xxl-2 {
      padding-right: .125rem!important
  }

  .pb-xxl-2 {
      padding-bottom: .125rem!important
  }

  .pt-xxl-2 {
      padding-top: .125rem!important
  }

  .ma-xxl-2 {
      margin: .125rem!important
  }

  .my-xxl-2 {
      margin-bottom: .125rem!important;
      margin-top: .125rem!important
  }

  .mx-xxl-2 {
      margin-left: .125rem!important;
      margin-right: .125rem!important
  }

  .mt-xxl-2 {
      margin-top: .125rem!important
  }

  .mb-xxl-2 {
      margin-bottom: .125rem!important
  }

  .ml-xxl-2 {
      margin-left: .125rem!important
  }

  .mr-xxl-2 {
      margin-right: .125rem!important
  }

  .pa-xxl-4 {
      padding: .25rem!important
  }

  .py-xxl-4 {
      padding-bottom: .25rem!important;
      padding-top: .25rem!important
  }

  .px-xxl-4 {
      padding-right: .25rem!important
  }

  .pl-xxl-4,.px-xxl-4 {
      padding-left: .25rem!important
  }

  .pr-xxl-4 {
      padding-right: .25rem!important
  }

  .pb-xxl-4 {
      padding-bottom: .25rem!important
  }

  .pt-xxl-4 {
      padding-top: .25rem!important
  }

  .ma-xxl-4 {
      margin: .25rem!important
  }

  .my-xxl-4 {
      margin-bottom: .25rem!important;
      margin-top: .25rem!important
  }

  .mx-xxl-4 {
      margin-left: .25rem!important;
      margin-right: .25rem!important
  }

  .mt-xxl-4 {
      margin-top: .25rem!important
  }

  .mb-xxl-4 {
      margin-bottom: .25rem!important
  }

  .ml-xxl-4 {
      margin-left: .25rem!important
  }

  .mr-xxl-4 {
      margin-right: .25rem!important
  }

  .pa-xxl-5 {
      padding: .3125rem!important
  }

  .py-xxl-5 {
      padding-bottom: .3125rem!important;
      padding-top: .3125rem!important
  }

  .px-xxl-5 {
      padding-right: .3125rem!important
  }

  .pl-xxl-5,.px-xxl-5 {
      padding-left: .3125rem!important
  }

  .pr-xxl-5 {
      padding-right: .3125rem!important
  }

  .pb-xxl-5 {
      padding-bottom: .3125rem!important
  }

  .pt-xxl-5 {
      padding-top: .3125rem!important
  }

  .ma-xxl-5 {
      margin: .3125rem!important
  }

  .my-xxl-5 {
      margin-bottom: .3125rem!important;
      margin-top: .3125rem!important
  }

  .mx-xxl-5 {
      margin-left: .3125rem!important;
      margin-right: .3125rem!important
  }

  .mt-xxl-5 {
      margin-top: .3125rem!important
  }

  .mb-xxl-5 {
      margin-bottom: .3125rem!important
  }

  .ml-xxl-5 {
      margin-left: .3125rem!important
  }

  .mr-xxl-5 {
      margin-right: .3125rem!important
  }

  .pa-xxl-8 {
      padding: .5rem!important
  }

  .py-xxl-8 {
      padding-bottom: .5rem!important;
      padding-top: .5rem!important
  }

  .px-xxl-8 {
      padding-right: .5rem!important
  }

  .pl-xxl-8,.px-xxl-8 {
      padding-left: .5rem!important
  }

  .pr-xxl-8 {
      padding-right: .5rem!important
  }

  .pb-xxl-8 {
      padding-bottom: .5rem!important
  }

  .pt-xxl-8 {
      padding-top: .5rem!important
  }

  .ma-xxl-8 {
      margin: .5rem!important
  }

  .my-xxl-8 {
      margin-bottom: .5rem!important;
      margin-top: .5rem!important
  }

  .mx-xxl-8 {
      margin-left: .5rem!important;
      margin-right: .5rem!important
  }

  .mt-xxl-8 {
      margin-top: .5rem!important
  }

  .mb-xxl-8 {
      margin-bottom: .5rem!important
  }

  .ml-xxl-8 {
      margin-left: .5rem!important
  }

  .mr-xxl-8 {
      margin-right: .5rem!important
  }

  .pa-xxl-10 {
      padding: .625rem!important
  }

  .py-xxl-10 {
      padding-bottom: .625rem!important;
      padding-top: .625rem!important
  }

  .px-xxl-10 {
      padding-right: .625rem!important
  }

  .pl-xxl-10,.px-xxl-10 {
      padding-left: .625rem!important
  }

  .pr-xxl-10 {
      padding-right: .625rem!important
  }

  .pb-xxl-10 {
      padding-bottom: .625rem!important
  }

  .pt-xxl-10 {
      padding-top: .625rem!important
  }

  .ma-xxl-10 {
      margin: .625rem!important
  }

  .my-xxl-10 {
      margin-bottom: .625rem!important;
      margin-top: .625rem!important
  }

  .mx-xxl-10 {
      margin-left: .625rem!important;
      margin-right: .625rem!important
  }

  .mt-xxl-10 {
      margin-top: .625rem!important
  }

  .mb-xxl-10 {
      margin-bottom: .625rem!important
  }

  .ml-xxl-10 {
      margin-left: .625rem!important
  }

  .mr-xxl-10 {
      margin-right: .625rem!important
  }

  .pa-xxl-12 {
      padding: .75rem!important
  }

  .py-xxl-12 {
      padding-bottom: .75rem!important;
      padding-top: .75rem!important
  }

  .px-xxl-12 {
      padding-right: .75rem!important
  }

  .pl-xxl-12,.px-xxl-12 {
      padding-left: .75rem!important
  }

  .pr-xxl-12 {
      padding-right: .75rem!important
  }

  .pb-xxl-12 {
      padding-bottom: .75rem!important
  }

  .pt-xxl-12 {
      padding-top: .75rem!important
  }

  .ma-xxl-12 {
      margin: .75rem!important
  }

  .my-xxl-12 {
      margin-bottom: .75rem!important;
      margin-top: .75rem!important
  }

  .mx-xxl-12 {
      margin-left: .75rem!important;
      margin-right: .75rem!important
  }

  .mt-xxl-12 {
      margin-top: .75rem!important
  }

  .mb-xxl-12 {
      margin-bottom: .75rem!important
  }

  .ml-xxl-12 {
      margin-left: .75rem!important
  }

  .mr-xxl-12 {
      margin-right: .75rem!important
  }

  .pa-xxl-16 {
      padding: 1rem!important
  }

  .py-xxl-16 {
      padding-bottom: 1rem!important;
      padding-top: 1rem!important
  }

  .px-xxl-16 {
      padding-right: 1rem!important
  }

  .pl-xxl-16,.px-xxl-16 {
      padding-left: 1rem!important
  }

  .pr-xxl-16 {
      padding-right: 1rem!important
  }

  .pb-xxl-16 {
      padding-bottom: 1rem!important
  }

  .pt-xxl-16 {
      padding-top: 1rem!important
  }

  .ma-xxl-16 {
      margin: 1rem!important
  }

  .my-xxl-16 {
      margin-bottom: 1rem!important;
      margin-top: 1rem!important
  }

  .mx-xxl-16 {
      margin-left: 1rem!important;
      margin-right: 1rem!important
  }

  .mt-xxl-16 {
      margin-top: 1rem!important
  }

  .mb-xxl-16 {
      margin-bottom: 1rem!important
  }

  .ml-xxl-16 {
      margin-left: 1rem!important
  }

  .mr-xxl-16 {
      margin-right: 1rem!important
  }

  .pa-xxl-20 {
      padding: 1.25rem!important
  }

  .py-xxl-20 {
      padding-bottom: 1.25rem!important;
      padding-top: 1.25rem!important
  }

  .px-xxl-20 {
      padding-right: 1.25rem!important
  }

  .pl-xxl-20,.px-xxl-20 {
      padding-left: 1.25rem!important
  }

  .pr-xxl-20 {
      padding-right: 1.25rem!important
  }

  .pb-xxl-20 {
      padding-bottom: 1.25rem!important
  }

  .pt-xxl-20 {
      padding-top: 1.25rem!important
  }

  .ma-xxl-20 {
      margin: 1.25rem!important
  }

  .my-xxl-20 {
      margin-bottom: 1.25rem!important;
      margin-top: 1.25rem!important
  }

  .mx-xxl-20 {
      margin-left: 1.25rem!important;
      margin-right: 1.25rem!important
  }

  .mt-xxl-20 {
      margin-top: 1.25rem!important
  }

  .mb-xxl-20 {
      margin-bottom: 1.25rem!important
  }

  .ml-xxl-20 {
      margin-left: 1.25rem!important
  }

  .mr-xxl-20 {
      margin-right: 1.25rem!important
  }

  .pa-xxl-24 {
      padding: 1.5rem!important
  }

  .py-xxl-24 {
      padding-bottom: 1.5rem!important;
      padding-top: 1.5rem!important
  }

  .px-xxl-24 {
      padding-right: 1.5rem!important
  }

  .pl-xxl-24,.px-xxl-24 {
      padding-left: 1.5rem!important
  }

  .pr-xxl-24 {
      padding-right: 1.5rem!important
  }

  .pb-xxl-24 {
      padding-bottom: 1.5rem!important
  }

  .pt-xxl-24 {
      padding-top: 1.5rem!important
  }

  .ma-xxl-24 {
      margin: 1.5rem!important
  }

  .my-xxl-24 {
      margin-bottom: 1.5rem!important;
      margin-top: 1.5rem!important
  }

  .mx-xxl-24 {
      margin-left: 1.5rem!important;
      margin-right: 1.5rem!important
  }

  .mt-xxl-24 {
      margin-top: 1.5rem!important
  }

  .mb-xxl-24 {
      margin-bottom: 1.5rem!important
  }

  .ml-xxl-24 {
      margin-left: 1.5rem!important
  }

  .mr-xxl-24 {
      margin-right: 1.5rem!important
  }

  .pa-xxl-30 {
      padding: 1.875rem!important
  }

  .py-xxl-30 {
      padding-bottom: 1.875rem!important;
      padding-top: 1.875rem!important
  }

  .px-xxl-30 {
      padding-right: 1.875rem!important
  }

  .pl-xxl-30,.px-xxl-30 {
      padding-left: 1.875rem!important
  }

  .pr-xxl-30 {
      padding-right: 1.875rem!important
  }

  .pb-xxl-30 {
      padding-bottom: 1.875rem!important
  }

  .pt-xxl-30 {
      padding-top: 1.875rem!important
  }

  .ma-xxl-30 {
      margin: 1.875rem!important
  }

  .my-xxl-30 {
      margin-bottom: 1.875rem!important;
      margin-top: 1.875rem!important
  }

  .mx-xxl-30 {
      margin-left: 1.875rem!important;
      margin-right: 1.875rem!important
  }

  .mt-xxl-30 {
      margin-top: 1.875rem!important
  }

  .mb-xxl-30 {
      margin-bottom: 1.875rem!important
  }

  .ml-xxl-30 {
      margin-left: 1.875rem!important
  }

  .mr-xxl-30 {
      margin-right: 1.875rem!important
  }

  .pa-xxl-32 {
      padding: 2rem!important
  }

  .py-xxl-32 {
      padding-bottom: 2rem!important;
      padding-top: 2rem!important
  }

  .px-xxl-32 {
      padding-right: 2rem!important
  }

  .pl-xxl-32,.px-xxl-32 {
      padding-left: 2rem!important
  }

  .pr-xxl-32 {
      padding-right: 2rem!important
  }

  .pb-xxl-32 {
      padding-bottom: 2rem!important
  }

  .pt-xxl-32 {
      padding-top: 2rem!important
  }

  .ma-xxl-32 {
      margin: 2rem!important
  }

  .my-xxl-32 {
      margin-bottom: 2rem!important;
      margin-top: 2rem!important
  }

  .mx-xxl-32 {
      margin-left: 2rem!important;
      margin-right: 2rem!important
  }

  .mt-xxl-32 {
      margin-top: 2rem!important
  }

  .mb-xxl-32 {
      margin-bottom: 2rem!important
  }

  .ml-xxl-32 {
      margin-left: 2rem!important
  }

  .mr-xxl-32 {
      margin-right: 2rem!important
  }

  .pa-xxl-40 {
      padding: 2.5rem!important
  }

  .py-xxl-40 {
      padding-bottom: 2.5rem!important;
      padding-top: 2.5rem!important
  }

  .px-xxl-40 {
      padding-right: 2.5rem!important
  }

  .pl-xxl-40,.px-xxl-40 {
      padding-left: 2.5rem!important
  }

  .pr-xxl-40 {
      padding-right: 2.5rem!important
  }

  .pb-xxl-40 {
      padding-bottom: 2.5rem!important
  }

  .pt-xxl-40 {
      padding-top: 2.5rem!important
  }

  .ma-xxl-40 {
      margin: 2.5rem!important
  }

  .my-xxl-40 {
      margin-bottom: 2.5rem!important;
      margin-top: 2.5rem!important
  }

  .mx-xxl-40 {
      margin-left: 2.5rem!important;
      margin-right: 2.5rem!important
  }

  .mt-xxl-40 {
      margin-top: 2.5rem!important
  }

  .mb-xxl-40 {
      margin-bottom: 2.5rem!important
  }

  .ml-xxl-40 {
      margin-left: 2.5rem!important
  }

  .mr-xxl-40 {
      margin-right: 2.5rem!important
  }

  .pa-xxl-48 {
      padding: 3rem!important
  }

  .py-xxl-48 {
      padding-bottom: 3rem!important;
      padding-top: 3rem!important
  }

  .px-xxl-48 {
      padding-right: 3rem!important
  }

  .pl-xxl-48,.px-xxl-48 {
      padding-left: 3rem!important
  }

  .pr-xxl-48 {
      padding-right: 3rem!important
  }

  .pb-xxl-48 {
      padding-bottom: 3rem!important
  }

  .pt-xxl-48 {
      padding-top: 3rem!important
  }

  .ma-xxl-48 {
      margin: 3rem!important
  }

  .my-xxl-48 {
      margin-bottom: 3rem!important;
      margin-top: 3rem!important
  }

  .mx-xxl-48 {
      margin-left: 3rem!important;
      margin-right: 3rem!important
  }

  .mt-xxl-48 {
      margin-top: 3rem!important
  }

  .mb-xxl-48 {
      margin-bottom: 3rem!important
  }

  .ml-xxl-48 {
      margin-left: 3rem!important
  }

  .mr-xxl-48 {
      margin-right: 3rem!important
  }

  .pa-xxl-50 {
      padding: 3.125rem!important
  }

  .py-xxl-50 {
      padding-bottom: 3.125rem!important;
      padding-top: 3.125rem!important
  }

  .px-xxl-50 {
      padding-right: 3.125rem!important
  }

  .pl-xxl-50,.px-xxl-50 {
      padding-left: 3.125rem!important
  }

  .pr-xxl-50 {
      padding-right: 3.125rem!important
  }

  .pb-xxl-50 {
      padding-bottom: 3.125rem!important
  }

  .pt-xxl-50 {
      padding-top: 3.125rem!important
  }

  .ma-xxl-50 {
      margin: 3.125rem!important
  }

  .my-xxl-50 {
      margin-bottom: 3.125rem!important;
      margin-top: 3.125rem!important
  }

  .mx-xxl-50 {
      margin-left: 3.125rem!important;
      margin-right: 3.125rem!important
  }

  .mt-xxl-50 {
      margin-top: 3.125rem!important
  }

  .mb-xxl-50 {
      margin-bottom: 3.125rem!important
  }

  .ml-xxl-50 {
      margin-left: 3.125rem!important
  }

  .mr-xxl-50 {
      margin-right: 3.125rem!important
  }

  .pa-xxl-60 {
      padding: 3.75rem!important
  }

  .py-xxl-60 {
      padding-bottom: 3.75rem!important;
      padding-top: 3.75rem!important
  }

  .px-xxl-60 {
      padding-right: 3.75rem!important
  }

  .pl-xxl-60,.px-xxl-60 {
      padding-left: 3.75rem!important
  }

  .pr-xxl-60 {
      padding-right: 3.75rem!important
  }

  .pb-xxl-60 {
      padding-bottom: 3.75rem!important
  }

  .pt-xxl-60 {
      padding-top: 3.75rem!important
  }

  .ma-xxl-60 {
      margin: 3.75rem!important
  }

  .my-xxl-60 {
      margin-bottom: 3.75rem!important;
      margin-top: 3.75rem!important
  }

  .mx-xxl-60 {
      margin-left: 3.75rem!important;
      margin-right: 3.75rem!important
  }

  .mt-xxl-60 {
      margin-top: 3.75rem!important
  }

  .mb-xxl-60 {
      margin-bottom: 3.75rem!important
  }

  .ml-xxl-60 {
      margin-left: 3.75rem!important
  }

  .mr-xxl-60 {
      margin-right: 3.75rem!important
  }
}

.icon.ic-16 {
  height: 1rem;
  width: 1rem
}

.icon.ic-20 {
  height: 1.25rem;
  width: 1.25rem
}

.icon.ic-24 {
  height: 1.5rem;
  width: 1.5rem
}

.icon.ic-32 {
  height: 2rem;
  width: 2rem
}

.icon.ic-48 {
  height: 3rem;
  width: 3rem
}

.over-fill,.over-link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.over-link {
  background: none;
  z-index: 6
}

.fill-height {
  height: 100%
}

.overflow {
  overflow: hidden
}

.o-button[data-v-f001e3b6] {
  display: inline-flex
}



body {
  --image-bg: rgba(70,70,70,.1);
  --bg-color: #fff;
  --bg-color-inverted: #181818;
  --text-color: #181818;
  --text-color-inverted: #fff;
  --inverted: "none";
  background-color: #fff;
  width: 100%
}

body.theme-dark:not(.is-bg-light) {
  --image-bg: #464646;
  --bg-color: #181818;
  --bg-color-inverted: #fff;
  --text-color: #fff;
  --text-color-inverted: #181818
}

body.theme-dark:not(.is-bg-light) .section.bg-light {
  --inverted: invert(1) hue-rotate(-190deg)
}

body.is-fixed {
  left: 0;
  overflow-y: scroll;
  position: fixed
}

.pin-spacer {
  max-width: 100%!important
}

.block,img,svg,video {
  display: block
}

.inline-block {
  display: inline-block
}

button:not(:disabled) {
  cursor: pointer
}

.container {
  --o-gutter: 2.5rem;
  
}

.container.size-sm {
  max-width: 50.625rem
}

.container.size-md {
  max-width: 62.5rem
}

.container.size-xl {
  --o-gutter: 0;
  max-width: 90rem
}

.container.size-wide {
  --o-gutter: 0;
  max-width: 120rem
}

.container.size-fluid {
  max-width: none
}

@media (max-width: 767.98px) {
  .container {
      --o-gutter:1.5rem
  }
}

.row.spacing-xs {
  --o-gutter: .5rem
}

.row.spacing-xxs {
  --o-gutter: .25rem
}

.fullscreen {
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh)*100)
}

.fullscreen.is-center {
  justify-content: center
}

.invertable {
  filter: var(--inverted)
}

.relative {
  position: relative
}

.overflow-hidden {
  overflow: hidden
}

.column {
  display: flex;
  flex-direction: column
}

.spacer-80 {
  height: 5rem
}

.divider {
  background-color: currentColor;
  height: 1px
}

.hidden {

}

@media (max-width: 767.98px) {
  .spacer-80 {
      height:2.5rem
  }
}

:root {
  --slnt: 0;
  --wght: 340;
  --wdth: 150;
  --ital: 0;
  --letter-spacing: 0
}

html {
  color: #181818;

  /* /* font-family: 'Urbanist'; */
  line-height: 130%;
  word-break: break-word
}

@media screen and (min-width: 1380px) {
  html {
      font-size:calc(100% + .5vw - 6.9px)
  }
}

* {
  font-variation-settings: "wght" var(--wght),"wdth" var(--wdth),"ital" var(--ital);
  letter-spacing: var(--letter-spacing)
}

.fs-12,.fs-xs {
  font-size: .75rem
}

.fs-14,.fs-sm {
  font-size: .875rem;
  line-height: 130%
}

.fs-16,.fs-md {
  font-size: 1rem
}

.fs-18,.fs-lg {
  font-size: 1.125rem;
  line-height: 133%
}

.fs-24,.fs-xl {
  font-size: 1.5rem;
  line-height: 167%
}

.fw-620,.fw-b {
  --wght: 620
}

.fw-470,.fw-md {
  --wght: 470
}

.fw-200 {
  --wght: 200
}

.fw-300 {
  --wght: 300
}

.fw-400 {
  --wght: 400
}

.fw-500 {
  --wght: 500
}

.fw-600 {
  --wght: 600
}

.fw-700 {
  --wght: 700
}

.fw-800 {
  --wght: 800
}

.h,h1,h2,h3,h4,h5,h6 {
  --wght: 400;
  line-height: 80%
}

.h1,h1 {
  font-size: 9.375rem
}

.h2,h2 {
  font-size: 6.25rem
}

.h3,h3 {
  font-size: 5rem;
  line-height: 116%
}

.h4,h4 {
  font-size: 4rem
}

.h5,h5 {
  font-size: 2.75rem;
  line-height: 112%
}

.h6,h6 {
  --wght: 400;
  font-size: 1.5rem
}

.text-center {
  text-align: center
}

button {
  font-size: inherit
}

button,input {

  /* font-family: 'Urbanist'; */
}

.color-black {
  color: #181818!important
}

.bg-black {
  background-color: #181818!important
}

.color-white {
  color: #fff!important
}

.bg-white {
  background-color: #fff!important
}

.color-white-20 {
  color: #464646!important
}

.bg-white-20 {
  background-color: #464646!important
}

.color-white-40 {
  color: #747474!important
}

.bg-white-40 {
  background-color: #747474!important
}

.color-white-60 {
  color: #a3a3a3!important
}

.bg-white-60 {
  background-color: #a3a3a3!important
}

.color-white-80 {
  color: #d1d1d1!important
}

.bg-white-80 {
  background-color: #d1d1d1!important
}

.color-lilac {
  color: #bf79d8!important
}

.bg-lilac {
  background-color: #bf79d8!important
}

.color-magenta {
  color: #ad3d93!important
}

.bg-magenta {
  background-color: #ad3d93!important
}

.color-pink {
  color: #da4c88!important
}

.bg-pink {
  background-color: #da4c88!important
}

.color-salmon {
  color: #f77a69!important
}

.bg-salmon {
  background-color: #f77a69!important
}

.color-orange {
  color: #ff4b38!important
}

.bg-orange {
  background-color: #ff4b38!important
}

.color-red {
  color: #a8012c!important
}

.bg-red {
  background-color: #a8012c!important
}

.color-yellow {
  color: #fdeb45!important
}

.bg-yellow {
  background-color: #fdeb45!important
}

.color-green {
  color: #60aa42!important
}

.bg-green {
  background-color: #60aa42!important
}

.color-blue {
  color: #1c119f!important
}

.bg-blue {
  background-color: #1c119f!important
}

.color-blue-20 {
  color: #f6f9fc!important
}

.bg-blue-20 {
  background-color: #f6f9fc!important
}

.color-light-blue {
  color: #4983f1!important
}

.bg-light-blue {
  background-color: #4983f1!important
}

@media (max-width: 991.98px) {
  .h1,h1 {
      font-size:6.25rem
  }

  .h2,h2 {
      font-size: 5rem
  }

  .h3,h3 {
      font-size: 4rem
  }

  .h4,h4 {
      font-size: 2.75rem
  }

  .h5,h5 {
      font-size: 2.25rem
  }
}

@media (max-width: 767.98px) {
  .h1,h1 {
      font-size:3.4375rem
  }

  .h2,h2 {
      font-size: 3rem
  }

  .h3,h3 {
      font-size: 2.625rem
  }

  .h4,h4 {
      font-size: 2.25rem
  }

  h5 {
      font-size: 1.5rem
  }

  .h5 {
      font-size: 2.25rem
  }

  .fs-24,.h6,h6 {
      font-size: 1.25rem
  }

  .fs-18 {
      font-size: 1rem;
      line-height: 150%
  }

  .fs-16 {
      font-size: .875rem
  }
}

.u-link {
  font-weight: 700
}

.n-link:hover,.u-link:hover {
  text-decoration: underline
}

.page-leave-active {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.page-enter-active {
  position: relative;
  z-index: 2
}

.page-enter-active,.page-leave-active {
  transition: opacity .5s cubic-bezier(.25,.85,.57,1),transform .5s cubic-bezier(.25,.85,.57,1)
}

.page-enter-from,.page-leave-to {
  opacity: 0
}

.page-enter-from {
  transform: translate3d(0,100px,0)
}

.page-leave-to {
  transform: translate3d(0,-100px,0)
}

.fade-enter-active,.fade-leave-active {
  transition: opacity .4s
}

.fade-enter-from,.fade-leave-to {
  opacity: 0
}



:root {
  --swiper-theme-color: #007aff
}

.swiper {
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: 1
}

.swiper-vertical>.swiper-wrapper {
  flex-direction: column
}

.swiper-wrapper {
  box-sizing: content-box;
  display: flex;
  height: 100%;
  position: relative;
  transition-property: transform;
  width: 100%;
  z-index: 1
}

.swiper-android .swiper-slide,.swiper-wrapper {
  transform: translateZ(0)
}

.swiper-pointer-events {
  touch-action: pan-y
}

.swiper-pointer-events.swiper-vertical {
  touch-action: pan-x
}

.swiper-slide {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  transition-property: transform;
  width: 100%
}

.swiper-slide-invisible-blank {
  visibility: hidden
}

.swiper-autoheight,.swiper-autoheight .swiper-slide {
  height: auto
}

.swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform,height
}

.swiper-backface-hidden .swiper-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0)
}

.swiper-3d,.swiper-3d.swiper-css-mode .swiper-wrapper {
  perspective: 1200px
}

.swiper-3d .swiper-cube-shadow,.swiper-3d .swiper-slide,.swiper-3d .swiper-slide-shadow,.swiper-3d .swiper-slide-shadow-bottom,.swiper-3d .swiper-slide-shadow-left,.swiper-3d .swiper-slide-shadow-right,.swiper-3d .swiper-slide-shadow-top,.swiper-3d .swiper-wrapper {
  transform-style: preserve-3d
}

.swiper-3d .swiper-slide-shadow,.swiper-3d .swiper-slide-shadow-bottom,.swiper-3d .swiper-slide-shadow-left,.swiper-3d .swiper-slide-shadow-right,.swiper-3d .swiper-slide-shadow-top {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10
}

.swiper-3d .swiper-slide-shadow {
  background: rgba(0,0,0,.15)
}

.swiper-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(270deg,rgba(0,0,0,.5),transparent)
}

.swiper-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(90deg,rgba(0,0,0,.5),transparent)
}

.swiper-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(0deg,rgba(0,0,0,.5),transparent)
}

.swiper-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(180deg,rgba(0,0,0,.5),transparent)
}

.swiper-css-mode>.swiper-wrapper {
  -ms-overflow-style: none;
  overflow: auto;
  scrollbar-width: none
}

.swiper-css-mode>.swiper-wrapper::-webkit-scrollbar {
  display: none
}

.swiper-css-mode>.swiper-wrapper>.swiper-slide {
  scroll-snap-align: start start
}

.swiper-horizontal.swiper-css-mode>.swiper-wrapper {
  scroll-snap-type: x mandatory
}

.swiper-vertical.swiper-css-mode>.swiper-wrapper {
  scroll-snap-type: y mandatory
}

.swiper-centered>.swiper-wrapper:before {
  content: "";
  flex-shrink: 0;
  order: 9999
}

.swiper-centered.swiper-horizontal>.swiper-wrapper>.swiper-slide:first-child {
  -webkit-margin-start: var(--swiper-centered-offset-before);
  margin-inline-start:var(--swiper-centered-offset-before)}


.swiper-centered.swiper-vertical>.swiper-wrapper>.swiper-slide:first-child {
  -webkit-margin-before: var(--swiper-centered-offset-before);
  margin-block-start:var(--swiper-centered-offset-before)}


.swiper-centered>.swiper-wrapper>.swiper-slide {
  scroll-snap-align: center center
}

.input-errors[data-v-2ed8fe22] {
  padding-top: .375rem
}

.input-errors .error[data-v-2ed8fe22] {
  color: #a8012c;
  font-size: .875rem;
  padding: .125rem 0
}

.input-group .input[data-v-5f6363dd] {
  --wght: 620;
  background-color: #181818;
  border-bottom: 2px solid #fff;
  color: #fff;
  font-size: 1.5rem;
  line-height: 112%;
  padding: 1.5rem 0;
  transition: color .3s,border-color .3s;
  width: 100%
}

.input-group .input[data-v-5f6363dd]::-moz-placeholder {
  color: #ffffff80;
  -moz-transition: color .3s;
  transition: color .3s
}

.input-group .input[data-v-5f6363dd]::placeholder {
  color: #ffffff80;
  transition: color .3s
}

.input-group.has-error .input[data-v-5f6363dd] {
  border-color: #a8012c
}

.bg[data-v-7f6da924] {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%
}

.bg-match {
  background-color: var(--section-color)
}

.break[data-v-0417037e] {
  height: 0;
  padding: 0;
  width: 100%
}

.grid-item[data-v-0417037e] {
  margin-bottom: 9.375rem
}

.grid-item .column[data-v-0417037e],.grid-item .image[data-v-0417037e] {
  overflow: hidden
}

.grid-item .cover[data-v-0417037e],.grid-item .video[data-v-0417037e] {
  transition: transform .5s,opacity .5s
}

.grid-item.is-big[data-v-0417037e] {
  margin-left: auto;
  margin-right: auto
}

.grid-item.is-big .cover[data-v-0417037e] {
  aspect-ratio: 4/3
}

.grid-item.is-thick[data-v-0417037e]:not(.is-big) {
  margin-top: 8.75rem
}

.grid-item.is-thick:not(.is-big) .cover[data-v-0417037e] {
  aspect-ratio: 4/3
}

.grid-item.is-slim .cover[data-v-0417037e] {
  aspect-ratio: 3/4
}

.grid-item .category[data-v-0417037e]:not(:last-child) {
  margin-right: .75rem
}

.grid-item .summary[data-v-0417037e] {
  line-height: 120%
}


.grid-item .cover[data-v-0417037e],
.grid-item .video[data-v-0417037e] {
transition: transform 2.3s ease;
}

.grid-item:hover .cover[data-v-0417037e],
.grid-item:hover .video[data-v-0417037e] {
transform: scale(1.05);
}


@media (max-width: 767.98px) {
  .grid-item[data-v-0417037e] {
      margin-bottom:3.125rem;
      margin-top: 0!important
  }
}

.grid-items[data-v-2d68d3f5] {
  overflow: hidden
}

.grid-items .textBreak[data-v-2d68d3f5] {
  margin-bottom: 9.375rem;
  max-width: 90%;
  width: 100%
}

@media (max-width: 767.98px) {
  .grid-items .textBreak[data-v-2d68d3f5] {
      margin-bottom:5rem;
      max-width: unset
  }
}

.list-cursor[data-v-7097439e] {
  overflow: hidden
}

.list-cursor .image[data-v-7097439e] {
  margin-left: 1.25rem;
  margin-top: 1.25rem;
  max-height: 25rem;
  max-width: 25rem;
  transition: transform .3s
}

.list-item[data-v-434c5e14] {
  border-bottom: 1px solid;
  flex-wrap: wrap;
  padding: 3.125rem 0;
  z-index: 1
}

.list-item.has-date .summary[data-v-434c5e14] {
  display: none
}

.list-item[data-v-434c5e14]:first-child {
  padding-top: 0
}

.list-item .title-handler[data-v-434c5e14] {
  flex: 1 1 0%;
  max-width: 80%;
  position: relative
}

.list-item .title[data-v-434c5e14] {
  line-height: 112%;
  max-width: 100%;
  position: absolute
}

.list-item .title-clone[data-v-434c5e14] {
  --wdth: 25;
  --wght: 340;
  font-size: 4rem;
  line-height: 100%;
  visibility: hidden
}

.list-item .bottom[data-v-434c5e14] {
  flex-basis: 100%
}

.list-item .summary[data-v-434c5e14] {
  line-height: 120%
}

.list-item .categories[data-v-434c5e14] {
  flex-shrink: 0;
  margin-left: 1.875rem
}

.list-item .category[data-v-434c5e14]:not(:last-child) {
  margin-right: .75rem
}

@media (max-width: 767.98px) {
  .list-item[data-v-434c5e14] {
      padding:1.875rem 0
  }

  .list-item.has-date .date[data-v-434c5e14] {
      display: none
  }

  .list-item.has-date .summary[data-v-434c5e14] {
      display: block
  }

  .list-item .title-handler[data-v-434c5e14] {
      flex-basis: 100%;
      max-width: unset
  }

  .list-item .title-clone[data-v-434c5e14] {
      font-size: 3.6rem
  }

  .list-item .categories[data-v-434c5e14] {
      margin-left: 0;
      margin-top: 2rem;
      order: 1
  }
}

.news-item[data-v-99a81568] {
  border-bottom: 1px solid;
  padding: 2.5rem 0;
  z-index: 1
}

.news-item .row[data-v-99a81568] {
  --o-gutter: 0
}

.news-item .date[data-v-99a81568],.news-item .publication[data-v-99a81568] {
  font-size: 1.125rem;
  line-height: 133%
}

.news-item .read-more[data-v-99a81568] {
  font-size: .875rem
}

.news-item .read-more.mobile[data-v-99a81568] {
  display: none
}

.news-item .title[data-v-99a81568] {
  pointer-events: none;
  position: absolute
}

.news-item .title-clone[data-v-99a81568] {
  --wdth: 5;
  --wght: 300;
  flex: 1 1 0%;
  font-size: 4rem;
  line-height: 100%;
  visibility: hidden
}

@media (max-width: 767.98px) {
  .news-item .read-more[data-v-99a81568] {
      display:none
  }

  .news-item .read-more.mobile[data-v-99a81568] {
      display: block
  }

  .news-item .title-clone[data-v-99a81568] {
      font-size: 3rem
  }
}

.wrapper[data-v-24411b43] {
  display: flex;
  flex-direction: column;
  margin: 5rem 0
}

.wrapper .next-btn[data-v-24411b43] {
  align-self: flex-end;
  margin-top: 3.25rem
}

.wrapper .next-btn .arrow[data-v-24411b43],.wrapper .next-btn .label[data-v-24411b43] {
  transition: transform .3s,opacity .3s
}

.wrapper .next-btn:hover .label[data-v-24411b43] {
  transform: translate3d(4px,0,0)
}

.wrapper .next-btn:hover .arrow[data-v-24411b43] {
  opacity: 0;
  transform: translate3d(100%,0,0)
}

.toggle[data-v-0cb7b945] {
  background-color: #181818;
  border-radius: 1.25rem;
  cursor: pointer;
  height: 2.125rem;
  padding: .125rem .0625rem;
  width: 6.875rem
}

.toggle.is-outlined[data-v-0cb7b945] {
  border: 2px solid #fff
}

.toggle.is-inactive[data-v-0cb7b945] {
  background-color: #fff;
  color: #181818
}

.toggle.is-inactive .thumb[data-v-0cb7b945] {
  background-color: #181818
}

.toggle.is-inactive .labels .is-on[data-v-0cb7b945] {
  color: #fff
}

.toggle.variant-navigation[data-v-0cb7b945] {
  background-color: transparent
}

.toggle.variant-navigation .thumb[data-v-0cb7b945] {
  background-color: var(--bg-color);
  transition: transform .2s,background .5s
}

.toggle.variant-navigation .labels .is-on[data-v-0cb7b945] {
  color: var(--text-color)
}

.toggle .thumb[data-v-0cb7b945] {
  background-color: #fff;
  border-radius: 20px;
  height: 100%;
  transition: transform .2s;
  width: 50%
}

.toggle .labels[data-v-0cb7b945] {
  --wght: 470;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  text-transform: capitalize;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 100%
}

.toggle .labels>div[data-v-0cb7b945] {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center
}

.toggle .labels .is-on[data-v-0cb7b945] {
  color: #181818
}

.toggle.is-toggled .thumb[data-v-0cb7b945] {
  transform: translate(100%)
}

.navigation .navigation-swiper[data-v-0d9c401e] {
  margin: 0;
  overflow: visible
}

.navigation .navigation-swiper .slide[data-v-0d9c401e] {
  display: flex;
  flex: 0 0 0;
  padding: 1px
}

.navigation .navigation-swiper .btn[data-v-0d9c401e] {
  --wght: 400;
  justify-content: space-between;
  letter-spacing: .03em;
  white-space: nowrap
}

.navigation .navigation-swiper .btn.is-grid-allowed[data-v-0d9c401e] .btn-inner {
  overflow: visible
}

.navigation .navigation-swiper .btn .toggle[data-v-0d9c401e] {
  margin-right: -.9375rem
}

.navigation .navigation-swiper .movable[data-v-0d9c401e] {
  
  border-radius: 1.75rem;
  height: 40px;
  position: absolute;
  top: 0;
  transition: transform,height,width,background-color;
  transition-duration: .5s;
  width: 100%;
  z-index: -1
}

.section.hero[data-v-488ab396] {
  min-height: 31.25rem;
  padding-bottom: 5.4375rem
}

.section.hero .hero-block[data-v-488ab396] {
  height: 100%;
  margin-top: auto
}

.rich-text[data-v-1525e654] h1,.rich-text[data-v-1525e654] h2,.rich-text[data-v-1525e654] h3,.rich-text[data-v-1525e654] h4,.rich-text[data-v-1525e654] h5 {
  color: var(--heading-color,inherit)
}

.rich-text[data-v-1525e654] table {
  width: 100%
}

.rich-text[data-v-1525e654]>:not(:last-child) {
  padding-bottom: 1rem
}

.rich-text[data-v-1525e654] ol,.rich-text[data-v-1525e654] ul {
  padding-left: 1.875rem
}

.rich-text[data-v-1525e654] ol>li,.rich-text[data-v-1525e654] ul>li {
  padding: .1875rem 0
}

.rich-text[data-v-1525e654] a {
  color: inherit;
  text-decoration: underline
}

.rich-text[data-v-1525e654] p {
  font-size: inherit;
  line-height: inherit;
  min-height: 1.75rem
}

.rich-text[data-v-1525e654] h2:not(:first-child) {
  padding-top: 1.875rem
}

.rich-text[data-v-1525e654] h3:not(:first-child) {
  padding-top: 1.25rem
}

.rich-text[data-v-1525e654] h4:not(:first-child) {
  padding-top: 1rem
}

.rich-text[data-v-1525e654] img {
  max-width: 100%
}

.rich-text[data-v-1525e654] b {
  --wght: 470
}

.rich-text[data-v-1525e654] i {
  --wght: 200
}

.rich-text.is-general[data-v-1525e654] b {
  --wght: 620
}

.rich-text.is-general[data-v-1525e654] h1,.rich-text.is-general[data-v-1525e654] h2,.rich-text.is-general[data-v-1525e654] h3,.rich-text.is-general[data-v-1525e654] h4 {
  --wght: 470
}

.rich-text.is-general[data-v-1525e654] h5 {
  --wght: 200
}

.marquee-list[data-v-595bf31e] {
  counter-reset: item
}

.marquee-list .number[data-v-595bf31e] {
  line-height: 100%
}

.marquee-list .number[data-v-595bf31e]:before {
  content: counter(item) " ";
  counter-increment: item
}

@media (max-width: 767.98px) {
  .marquee-list .number[data-v-595bf31e]:before {
      font-feature-settings:"ss03" on;
      font-size: 1.25rem;
      line-height: 130%
  }
}

.section.marquee.is-hero[data-v-7d0096e6]:not(.is-fullscreen) {
  min-height: 42.5rem
}

.section.marquee .title[data-v-7d0096e6] {
  --wght: 620;
  align-items: center;
  color: var(--title-color,--text-color);
  display: flex;
  flex-wrap: wrap;
  position: relative
}

.section.marquee .title .text[data-v-7d0096e6] {
  display: inline-block;
  position: relative
}

.section.marquee .title .text.anchor-top .animated-text[data-v-7d0096e6],.section.marquee .title .text.anchor-top .image[data-v-7d0096e6] {
  top: 0
}

.section.marquee .title .text.anchor-bottom .animated-text[data-v-7d0096e6],.section.marquee .title .text.anchor-bottom .image[data-v-7d0096e6] {
  bottom: 0
}

.section.marquee .title .ghost-text[data-v-7d0096e6] {
  display: inline-block;
  opacity: 0
}

.section.marquee .title .animated-text[data-v-7d0096e6] {
  --wdth: 150;
  --wght: 620;
  --ital: 0;
  display: inline-block;
  left: 0;
  
  overflow: visible;
  padding-left: 5.25rem;
  position: absolute;
  white-space: nowrap;
  z-index: 2
}

.section.marquee .title .animated-text .letter[data-v-7d0096e6]:nth-child(odd) {
  font-size: 50.1%;

  
}

.section.marquee .title .animated-text .letter[data-v-7d0096e6]:last-child {
  margin-right: -1.25rem;
  padding-right: 1.25rem
}

.section.marquee .title .animated-text.align-right[data-v-7d0096e6] {
  left: unset;
  right: 0
}

.section.marquee .title .break[data-v-7d0096e6] {
  flex: 1 0 100%;
  margin: 0
}

.section.marquee .title .image[data-v-7d0096e6] {
  align-self: flex-start;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1
}

.section.marquee .title .image.align-left[data-v-7d0096e6] {
  justify-content: flex-start;
  left: 0;
  right: unset
}

.section.marquee .title .image .image-img[data-v-7d0096e6] {
  max-height: 100%
}

.icon.ic-16[data-v-679ceb4e] {
  height: 1rem;
  width: 1rem
}

.icon.ic-18[data-v-679ceb4e] {
  height: 1.125rem;
  width: 1.125rem
}

.icon.ic-24[data-v-679ceb4e] {
  height: 1.5rem;
  width: 1.5rem
}

.icon.ic-42[data-v-679ceb4e] {
  height: 2.625rem;
  width: 2.625rem
}

.section[data-v-8cd27dc4] {
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  padding: 3.875rem 0;
  position: relative;
  text-align: left
}

.section.bg-black[data-v-8cd27dc4] {
  color: #fff
}

.section.is-overflow[data-v-8cd27dc4] {
  
}

.section.is-fullscreen[data-v-8cd27dc4] {
  justify-content: center;
  min-height: calc(var(--vh, 1vh)*100)
}

.section.no-padding[data-v-8cd27dc4] {
  padding: 0
}

.section.padding-xs[data-v-8cd27dc4] {
  padding: 1.875rem 0
}

.section.padding-sm[data-v-8cd27dc4] {
  padding: 4.25rem 0
}

.section.padding-md[data-v-8cd27dc4] {
  padding: 5rem 0
}

.section.padding-lg[data-v-8cd27dc4] {
  padding: 7.8125rem 0
}

.section.padding-xl[data-v-8cd27dc4] {
  padding: 13.75rem 0
}

.section.is-hero[data-v-8cd27dc4] {
  
}

.section .section-inner[data-v-8cd27dc4] {
  display: flex;
  
  flex-direction: column
}

.section.align-center .section-inner[data-v-8cd27dc4] {
  justify-content: center
}

.section.align-end .section-inner[data-v-8cd27dc4] {
  justify-content: flex-end
}

@media (max-width: 991.98px) {
  .section[data-v-8cd27dc4] {
      padding:3.75rem 0
  }
}

@media (max-width: 767.98px) {
  .section[data-v-8cd27dc4] {
      padding:2.5rem 0;
      text-align: center;
  }
  .col-6{
      width: 100%;
  }
  .heading span{
      font-size: 10vw;
  }

  .section.padding-lg[data-v-8cd27dc4],.section.padding-xl[data-v-8cd27dc4] {
      padding: 5rem 0
  }

  .section.padding-sm[data-v-8cd27dc4] {
      padding: 2.25rem 0
  }

  .section.is-hero[data-v-8cd27dc4] {
      padding-top: 2.5rem
  }

  .section.is-hero.is-fullscreen[data-v-8cd27dc4] {
      min-height: calc(var(--vh, 1vh)*100 - 4.375rem)
  }
}

.button[data-v-e3901db4] {
  --wght: 470;
  align-items: center;
  background-color: transparent;
  border-radius: 1.75rem;
  display: inline-flex;
  font-size: 1.125rem;
  height: 3.5rem;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  padding: .5rem 1.5rem;
  position: relative;
  text-align: center
}

.button[data-v-e3901db4]:not(:disabled) {
  cursor: pointer
}

.button[data-v-e3901db4]:disabled {
  opacity: .6
}

.button.is-icon[data-v-e3901db4] {
  height: 3.5rem;
  padding: 0!important;
  width: 3.5rem
}

.button.is-rounded[data-v-e3901db4] {
  border-radius: 50%
}

.button.size-sm[data-v-e3901db4] {
  height: 2.5rem;
  padding: .375rem 1.25rem
}

.button.size-sm.is-icon[data-v-e3901db4] {
  width: 2.5rem
}

.button.size-lg[data-v-e3901db4] {
  height: 3.75rem;
  padding: .75rem 2rem
}

.button.size-lg.is-icon[data-v-e3901db4] {
  width: 3.75rem
}

.button.width-lg[data-v-e3901db4] {
  min-width: 15rem
}

.button.width-full[data-v-e3901db4] {
  width: 100%
}

.button .btn-flex[data-v-e3901db4] {
  align-items: center;
  display: flex;
  min-width: 0;
  position: relative;
  
  z-index: 2
}

.button .btn-inner[data-v-e3901db4] {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.button.is-loading .btn-flex[data-v-e3901db4] {
  opacity: 0
}

.button .btn-hover[data-v-e3901db4] {
  background-color: var(--color);
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  transform: scale(1.1,2);
  width: 100%;
  z-index: 0
}

.button.variant-default[data-v-e3901db4] {
  color: var(--color);
  transition: color .5s
}

.button.variant-default[data-v-e3901db4]:before {
  border: 2px solid var(--color);
  border-radius: inherit;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: border-color .3s
}

.button.variant-default.is-active[data-v-e3901db4],.button.variant-default[data-v-e3901db4]:not(:disabled):not(.is-only-hover):hover {
  border-color: transparent;
  color: var(--active-color)!important
}

@media (hover: hover) {
  .button.variant-default.is-only-hover[data-v-e3901db4]:hover {
      border-color:transparent;
      color: var(--active-color)!important
  }
}

.button.variant-default.is-active .btn-hover[data-v-e3901db4] {
  opacity: 1
}

.button.variant-default.color-default[data-v-e3901db4],.button.variant-default.color-transparent[data-v-e3901db4] {
  --color: var(--text-color);
  --active-color: var(--text-color-inverted)
}

.button.variant-default.color-transparent[data-v-e3901db4]:before {
  border-color: transparent
}

.button.variant-default.color-white[data-v-e3901db4] {
  --color: #fff;
  --active-color: #181818
}

.button.variant-default.color-black[data-v-e3901db4] {
  --color: #181818;
  --active-color: #fff
}

.button.variant-default.color-title[data-v-e3901db4] {
  --color: var(--title-color,#181818)
}

.button.variant-inverted[data-v-e3901db4] {
  
}

.button.variant-inverted.is-outlined[data-v-e3901db4] {
  border: 2px solid
}

.button.variant-inverted.color-default[data-v-e3901db4] {
  border-color: #fff
}

.button.variant-inverted.color-default.is-active[data-v-e3901db4],.button.variant-inverted.color-default[data-v-e3901db4]:not(:disabled):hover {
  background-color: #fff;
  border-color: #fff0;
  color: #181818
}

.button.variant-text[data-v-e3901db4] {

}

.button.variant-text.color-default[data-v-e3901db4] {
  color: #181818
}

.button.variant-navigation[data-v-e3901db4] {
  color: var(--text-color);
 
}

.button.variant-navigation[data-v-e3901db4]:before {
  border: 2px solid transparent;
  border-radius: inherit;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
 
}

.button.variant-navigation[data-v-e3901db4]:hover:not(.is-active):before {

}

.button.variant-navigation.is-active[data-v-e3901db4] {

  color: black;
  font-weight: 600;
}

.button.variant-navigation.is-active[data-v-e3901db4]:before {
 
}

.button .spinner[data-v-e3901db4] {
  -webkit-animation: spinner-e3901db4 1s cubic-bezier(.66,.32,.39,.72) infinite;
  animation: spinner-e3901db4 1s cubic-bezier(.66,.32,.39,.72) infinite;
  border: 2px solid hsla(0,0%,100%,.3);
  border-radius: 50%;
  border-top-color: #fff;
  display: block;
  height: 1.5rem;
  left: 50%;
  margin: -.75rem 0 0 -.75rem;
  position: absolute;
  top: 50%;
  width: 1.5rem;
  z-index: 3
}

.button .spinner.v-enter-from[data-v-e3901db4],.button .spinner.v-leave-to[data-v-e3901db4] {
  opacity: 0
}

.button .spinner.v-enter-active[data-v-e3901db4],.button .spinner.v-leave-active[data-v-e3901db4] {
  transition: opacity .2s
}

@-webkit-keyframes spinner-e3901db4 {
  0% {
      transform: rotate(0)
  }

  to {
      transform: rotate(1turn)
  }
}

@keyframes spinner-e3901db4 {
  0% {
      transform: rotate(0)
  }

  to {
      transform: rotate(1turn)
  }
}

.play-button[data-v-6ef5f53e] {
  align-items: center;
  background-color: var(--bg-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: opacity .3s;
  z-index: 1
}

.play-button.is-disabled[data-v-6ef5f53e] {
  opacity: 0
}

.play-button.size-lg[data-v-6ef5f53e] {
  --arrow-size: 47px;
  height: 10rem;
  width: 10rem
}

.play-button.size-lg .arrow[data-v-6ef5f53e] {
  height: 2.9375rem;
  width: 2.9375rem
}

.play-button.size-sm[data-v-6ef5f53e] {
  --arrow-size: 27px;
  height: 5.6875rem;
  width: 5.6875rem
}

.play-button.size-sm .arrow[data-v-6ef5f53e] {
  height: 1.6875rem;
  width: 1.6875rem
}

.play-button.is-inverted[data-v-6ef5f53e] {
  background-color: var(--bg-color-inverted);
  color: var(--text-color-inverted)
}

.play-button .arrow[data-v-6ef5f53e] {
  align-items: center;
  border-right: 1px solid;
  border-top: 1px solid;
  display: flex;
  justify-content: center;
  margin-left: calc(var(--arrow-size)*-1/1.5);
  transform: rotate(45deg);
  transition: transform .3s,opacity .3s
}

.play-button .label[data-v-6ef5f53e] {
  transition: transform .3s
}

.play-button.dir-left[data-v-6ef5f53e] {
  flex-direction: row-reverse
}

.play-button.dir-left .arrow[data-v-6ef5f53e] {
  margin-left: unset;
  margin-right: calc(var(--arrow-size)*-1/1.5);
  transform: rotate(-135deg)
}

.play-button:hover.dir-left .label[data-v-6ef5f53e] {
  transform: translate3d(-4px,0,0)
}

.play-button:hover.dir-left .arrow[data-v-6ef5f53e] {
  opacity: 0;
  transform: translate3d(-100%,0,0) rotate(-135deg)
}

.play-button:hover.dir-right .label[data-v-6ef5f53e] {
  transform: translate3d(4px,0,0)
}

.play-button:hover.dir-right .arrow[data-v-6ef5f53e] {
  opacity: 0;
  transform: translate3d(100%,0,0) rotate(45deg)
}

.lazy-image[data-v-bbd0ab72] {
  left: 0;
  top: 0;
  transition: opacity .5s .1s
}

.lazy-image.is-invertable[data-v-bbd0ab72] {
  filter: var(--inverted)
}

.lazy-image.fit-cover[data-v-bbd0ab72] {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%!important
}

.lazy-image.fit-contain[data-v-bbd0ab72] {
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%
}

.lazy-image.fit-max[data-v-bbd0ab72] {
  max-height: 100%;
  max-width: 100%
}

.lazy-image.fit-fill[data-v-bbd0ab72] {
  width: 100%!important
}

.lazy-image.is-absolute[data-v-bbd0ab72] {
  position: absolute
}

.lazy-image.is-visible[data-v-bbd0ab72]:not(.is-loaded) {
  will-change: opacity,transform
}

.lazy-image.animation-scale[data-v-bbd0ab72],.lazy-image.animation-scale-slide[data-v-bbd0ab72] {
  transition: opacity .7s .1s,transform .7s .1s
}

.lazy-image[data-v-bbd0ab72]:not(.is-loaded) {
  opacity: 1
}

.lazy-image:not(.is-loaded).animation-scale[data-v-bbd0ab72] {
  transform: scale(1.05)
}

.lazy-image:not(.is-loaded).animation-scale-slide[data-v-bbd0ab72] {
  transform: translate3d(0,90px,0) scale(.9)
}

.lazy-video[data-v-3374f262] {
  left: 0;
  top: 0;
  transition: opacity .5s .1s
}

.lazy-video.fit-cover[data-v-3374f262] {
  height: calc(100% + 1px);
  -o-object-fit: cover;
  object-fit: cover;
  width: calc(100% + 1px)
}

.lazy-video.fit-contain[data-v-3374f262] {
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%
}

.lazy-video.fit-max[data-v-3374f262] {
  max-height: 100%;
  max-width: 100%
}

.lazy-video.fit-fill[data-v-3374f262] {
  width: 100%!important
}

.lazy-video.is-absolute[data-v-3374f262] {
  position: absolute
}

.lazy-video.is-visible[data-v-3374f262]:not(.is-loaded) {
  will-change: opacity,transform
}

.lazy-video.animation-scale[data-v-3374f262],.lazy-video.animation-scale-slide[data-v-3374f262] {
  transition: opacity .7s .1s,transform .7s .1s
}

.lazy-video[data-v-3374f262]:not(.is-loaded) {
  opacity: 0
}

.lazy-video:not(.is-loaded).animation-scale[data-v-3374f262] {
  transform: scale(1.05)
}

.lazy-video:not(.is-loaded).animation-scale-slide[data-v-3374f262] {
  transform: translate3d(0,90px,0) scale(.9)
}

.link[data-v-2b61a8ea] {
  display: inline-block;
  overflow: hidden;
  position: relative
}

.link .hover[data-v-2b61a8ea] {
  background-color: currentColor;
  bottom: 0;
  height: 2px;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 100%
}

.link.router-link-exact-active .hover[data-v-2b61a8ea] {
  opacity: 1;
  transform: none!important
}

.heading[data-v-cafda048] {
  position: relative
}

.section.footerbar .footer-text[data-v-94baf298] {
  letter-spacing: .01em;
  line-height: 133%
}

.section.footerbar .toggle[data-v-94baf298] {
  margin-top: auto
}

.section.footerbar section.left[data-v-94baf298],.section.footerbar section.right[data-v-94baf298] {
  display: flex;
  flex-direction: column;

}

@media (max-width: 767.98px) {
  .section.footerbar .section-wrapper[data-v-94baf298] {
      flex-direction:column-reverse
  }
}
.bg[data-v-23f22e4b] {
  height: 5rem;
  opacity: 0;
  position: fixed;
  top: 0;
  transform: translateZ(0);
  transition: transform .4s,height .4s,color .4s,opacity .4s;
  width: 100%;
  z-index: 20
}

.bg.is-scrolled[data-v-23f22e4b] {
  opacity: 1
}

.bg.is-hidden[data-v-23f22e4b]:not(.is-toggled) {
  transform: translate3d(0,-100%,0)
}

.headerbar[data-v-23f22e4b] {
  display: flex;
  height: 6.8rem;
  left: 0;
  position: fixed;
  top: 0px;
  transition: transform .4s,height .4s,color .4s;
  width: 100%;
  z-index: 120;
  background-color: white;
}

.headerbar.is-toggled[data-v-23f22e4b] {
  color: #181818
}

.headerbar.is-bg-dark[data-v-23f22e4b]:not(.is-toggled),.headerbar.is-dark[data-v-23f22e4b] {
  color: #fff
}

.headerbar.is-bg-dark:not(.is-toggled).is-bg-light[data-v-23f22e4b]:not(.is-toggled),.headerbar.is-dark.is-bg-light[data-v-23f22e4b]:not(.is-toggled) {
  color: #181818
}

.headerbar .inner[data-v-23f22e4b] {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  margin-top: 1.2rem;
}

.headerbar .spacer[data-v-23f22e4b] {
  flex: 1 1 auto
}

.headerbar .link[data-v-23f22e4b] {
  --wght: 400;
  font-size: 1.125rem;
  padding: .375rem 0;
  white-space: nowrap;
  text-decoration: none;
}

.headerbar .right-section[data-v-23f22e4b] {
  margin-left: auto
}

.headerbar .toggle[data-v-23f22e4b] {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem
}

.headerbar .toggle .line[data-v-23f22e4b] {
  background: currentColor;
  display: block;
  height: .125rem;
  margin: .25rem 0;
  transition: transform .4s cubic-bezier(.68,-.6,.32,1.6);
  width: 100%
}

.headerbar .toggle .line[data-v-23f22e4b]:nth-child(2) {
  transform: scaleX(.8);
  transform-origin: right
}

.headerbar .toggle.is-toggled .line[data-v-23f22e4b] {
  transform: rotate(-45deg) translate3d(-.25rem,.25rem,0)
}

.headerbar .toggle.is-toggled .line[data-v-23f22e4b]:nth-child(2) {
  transform: scaleX(1) rotate(45deg) translate3d(.25rem,.625rem,0)
}

.headerbar.is-hidden[data-v-23f22e4b]:not(.is-toggled) {
  transform: translate3d(0,-100%,0)
}

.headerbar.is-scrolled[data-v-23f22e4b]:not(.is-toggled) {
  height: 5rem
}

@media (max-width: 575.98px) {
  .headerbar.is-toggled .logo[data-v-23f22e4b] {
      display:none
  }

  .headerbar.is-toggled .btn-contact[data-v-23f22e4b] {
      display: inline-flex!important;
      margin-right: auto
  }

  .headerbar.is-toggled .right-section[data-v-23f22e4b] {
      width: 100%
  }
}

.mobile-menu[data-v-c2a9ec5a] {
  background-color: #fff;
  color: #181818;
  height: 100%;
  left: 0;
  overflow-y: auto;
  padding: 6.25rem 2.5rem 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99
}

.mobile-menu.theme-dark[data-v-c2a9ec5a] {
  background-color: #181818;
  color: #fff
}

.mobile-menu .link[data-v-c2a9ec5a] {
  --wght: 400;
  font-size: 1.875rem;
  letter-spacing: .03em;
  line-height: 133%
}

.mobile-menu .link[data-v-c2a9ec5a]:not(:last-child) {
  margin-bottom: 1.25rem
}

.mobile-menu .text[data-v-c2a9ec5a] {
  --wght: 300;
  font-size: 1rem;
  letter-spacing: .02em;
  line-height: 130%;
  max-width: 17.1875rem
}

.mobile-menu .text span[data-v-c2a9ec5a] {
  --wght: 500;
  margin-top: .875rem
}

.mobile-menu .mt-auto[data-v-c2a9ec5a] {
  margin-top: auto
}

@media (max-width: 575.98px) {
  .mobile-menu[data-v-c2a9ec5a] {
      padding-bottom:1.25rem;
      padding-left: 1.875rem;
      padding-right: 1.875rem
  }

  .mobile-menu .link[data-v-c2a9ec5a]:not(:last-child) {
      margin-bottom: 1rem
  }
}

.loader[data-v-6a425be9] {
  height: 4px;
  overflow: hidden;
  position: relative;
  width: 100%
}

.loader[data-v-6a425be9]:after {
  -webkit-animation: loading-6a425be9 3s cubic-bezier(.37,.63,.68,.27) infinite;
  animation: loading-6a425be9 3s cubic-bezier(.37,.63,.68,.27) infinite;
  background-color: #181818;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

@-webkit-keyframes loading-6a425be9 {
  0% {
      transform: translate3d(-100%,0,0);
      -webkit-transform: translate3d(-100%,0,0);
      -moz-transform: translate3d(-100%,0,0)
  }

  to {
      transform: translate3d(100%,0,0);
      -webkit-transform: translate3d(100%,0,0);
      -moz-transform: translate3d(100%,0,0)
  }
}

@keyframes loading-6a425be9 {
  0% {
      transform: translate3d(-100%,0,0);
      -webkit-transform: translate3d(-100%,0,0);
      -moz-transform: translate3d(-100%,0,0)
  }

  to {
      transform: translate3d(100%,0,0);
      -webkit-transform: translate3d(100%,0,0);
      -moz-transform: translate3d(100%,0,0)
  }
}

.loader.is-absolute[data-v-6a425be9] {
  left: 0;
  position: absolute;
  top: 0
}

.cursor[data-v-6e6eae46] {
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0
}

@media (hover: none) {
  .cursor[data-v-6e6eae46] {
      display:none
  }
}

.cursor .cursor-style.is-center[data-v-6e6eae46] {
  transform: translate(-50%,-50%)
}

.cursor .cursor-style.default-enter-active[data-v-6e6eae46],.cursor .cursor-style.default-leave-active[data-v-6e6eae46] {
  transition: opacity .2s,transform .2s
}

.cursor .cursor-style.default-enter-from[data-v-6e6eae46],.cursor .cursor-style.default-leave-to[data-v-6e6eae46] {
  opacity: 0;
  transform: scale(.5)
}

.cursor .cursor-style.default-enter-from.is-center[data-v-6e6eae46],.cursor .cursor-style.default-leave-to.is-center[data-v-6e6eae46] {
  transform: translate(-50%,-50%) scale(.5)
}

.cursor .cursor-style.news-enter-active[data-v-6e6eae46],.cursor .cursor-style.news-leave-active[data-v-6e6eae46] {
  transition: opacity .5s ease
}

.cursor .cursor-style.news-enter-from[data-v-6e6eae46],.cursor .cursor-style.news-leave-to[data-v-6e6eae46] {
  opacity: 0
}

.cursor .cursor-style.news-enter-from[data-v-6e6eae46] .image,.cursor .cursor-style.news-leave-to[data-v-6e6eae46] .image {
  transform: translate(100%)
}
.break{
  

  
  /* font-family: 'Urbanist'; */
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  
  color: #000000;
}
.break p{

  margin-top: 2rem;
  /* font-family: 'Urbanist'; */
}.full-screen-image {

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
 
  margin-top: 3rem;
}
.full-screen-image img{
    width: 100%;
}
.breaka p{
  margin-left: 3rem;
  margin-top: 2rem;
  font-size: 40px;
  width: 45%;
  line-height: 120.02%;
}

#container {  margin: 0;
  }

#circle { position: relative; width: 100%; padding-bottom: 20%; overflow: hidden;word-spacing: 10px; right: 0;}

#circle text { font-family: 'Helvetica Neue', Arial; font-size: 16px; font-weight: bold; }

#circle svg { position: absolute;right: 7rem;

animation-name: rotate;
animation-duration: 11s;
animation-iteration-count: infinite;
animation-timing-function: linear;
}

@keyframes rotate {
from { transform: rotate(360deg); }
to { transform: rotate(0); }
}
.heading span{
  color: white;
 
/* 
font-family: 'PT Serif'; */
font-style: normal;
font-weight: 400;
font-size: 8vw;


text-align: center;
letter-spacing: 0.05em;

background: linear-gradient(180deg, #000000 51.04%, #BFBFBF 93.23%);
/* -webkit-background-clip: text; */
/* -webkit-text-fill-color: transparent; */
background-clip: text;
text-fill-color: transparent;
display: block;
text-align: center;

}
.section.footerbar section.right[data-v-94baf298]{
  margin-top: 2rem;
}
.section.footerbar section.right[data-v-94baf298] h6{
  /* font-family: 'Urbanist'; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  
  
  
  color: #000000;
}
.textBreak span{
  /* font-family: 'Urbanist'; */
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  
  width: 75%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  
  color: #000000;
}
.section.footerbar section.right[data-v-94baf298],.section.footerbar section.left[data-v-94baf298] p{
  text-decoration: underline;
}
.social-icons-container {
  display: flex;
  
  margin-top: 5rem;
}
.social-icons-container img{
    width: 2rem;
    padding-left: 10px;
    
}

.footerdivider{
  position: absolute;
  width: 93%;
  margin-left: 2rem;
 
  
  border: 1px solid #000000;
  margin-top: -3rem;

}
.text-container {
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
  margin-right: 2rem;
}

.text-container p {
  margin: 0;
  
  text-align: left;
  
  font-size: 12px;
}

.text-container div {
  margin: 0;
  text-align: right;
  font-size: 12px;
}
.logo img{
    width: 20.2rem;
    /* margin-left: 2rem; */

}
.mainhomebody{
    margin-top: 8rem;
}.section-wrapper {
display: flex;
justify-content: center;
}

@media screen and (max-width: 750px) {

}


@media screen and (max-width: 750px) {
  .social-icons-container {
      display: flex;
      justify-content: center;
    }
.social-icons-container {
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.social-icons-container img {
  margin: 5px;
}
.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


  .text-container p {
    flex-direction: row;
    justify-content: space-between;
  }
  .text-container div{
      text-align: left;
  }
.footerdivider{
    width: 94%;
  
    margin-left: calc((100% - 93%) / 2);
}
.heading span{
    font-size: 15vw;
}
.breaka p{
  font-size: 10vw;
  width: 80%;
}
#circle{
    padding-bottom: 40%;
}
.section.marquee.is-hero[data-v-7d0096e6]:not(.is-fullscreen){
    min-height: 0;
}
.full-screen-image{

}
.section.marquee .title .text.anchor-bottom .animated-text[data-v-7d0096e6], .section.marquee .title .text.anchor-bottom .image[data-v-7d0096e6]{
    position: absolute;
 
    left: 0;
}
.textBreak span{
  width: 100%;
  font-size: 2rem;

}
}

@media screen and (max-width: 550px) {
  #circle{
      padding-bottom: 60%;
    
  }
  .logo img{
    /* margin-left: -1rem; */
  }
}
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;

}

*{
  /* font-family: 'Urbanist'; */
}.text-center {
  text-align: center;
}


.App{
    /* font-family: aeonik; */
}

/*  */

@media screen and (max-width: 770px) {
    .logo img{
width: 15rem;
margin-left: -1rem;
    }
    .footer_credits__0Tj7b{
        display: none;
    }
    .nav-emaile{
        display: none;
    }
   
}
@media screen and (max-width: 670px) {
    .tiles{
        margin-top: 6.5rem;
        margin-bottom: 6rem;
    }
     .hhm-p01 .kxFcfd{
        padding: 0px 0 0px 0;
    }
    .ht-hser-01 .process-layout_content-left{
        display: none;
    }
    .helicamainhome-container .bSqfQs{
margin-top: 20px;
    }
    .mainaboutbody{
        margin-top: 4rem;
    }

    .aboutheic img

{
    /* width: 20px;
    height: 20px; */

}}

/* new animation */
/* new transoii */
.slide-inn{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: white;
    /* background: black; */
    transform-origin: bottom;
    z-index: 1002;
}

.slide-outt{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: black;
    transform-origin: top; 
}

html{
    scroll-behavior: initial;
}

html,body{
    /* min-height: 100%; */
    /* height: auto; */
    font-family: Helveticaneue,sans-serif;
    
}


/* <style> */

#preloader{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: black;
    flex-direction: column;

}
#percent{
    color: white;
    font-family: 'Neutral Face';
    font-size: 8rem;
}
#bar{
    width: 60%;
    margin-top: 20px;
}

/* </style> */

/* .main p {
    position: absolute;
    transform: rotate(-90deg);
    top: 400px;
    font-size: 20px;
    font-weight: 700;
    font-family: "Avenir";
    letter-spacing: 10px;
}
.main .container {
    width: 1440px;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    align-items: center;
    visibility: hidden;
}
.img-container {
    width: 100%;
    height: 960px;
    position: relative;
    overflow: hidden;
}
.img-container:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    right: 0;
}
.img-container img {
    width: 1440px;
    margin: 0 auto;
} */



