.case-study__introduction {
    padding: 8.5% 0 4.25%;
  }
  
  @media (min-width: 1600px) {
    .case-study__introduction {
      padding: 136px 0 68px;
    }
  }
  
  .case-study__introduction a {
    color: inherit;
    transition: ease 0.3s opacity;
  }
  
  .case-study__introduction a:hover {
    opacity: 0.7;
  }
  
  .case-study__introduction-inner {
    @media (min-width: 768px) {
      display: flex;
      grid-template-columns: 17.1875vw 82.8125vw;
    }
  
    .case-study__introduction-features {
      @media (min-width: 768px) {
        width: 31.9759%;
      }
    }
  
    .case-study__introduction-description {
      @media (min-width: 768px) {
        width: 68.0241%;
      }
    }
  }
  
  .case-study__introduction-features {
    @media (min-width: 768px) {
      padding-top: 11px;
    }
  
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-weight: 300;
      font-size: 0.8889em;
      margin-bottom: 3.8889em;
    }
  
    @media (min-width: 768px) {
      font-size: 1em;
    }
  
    li {
      margin-bottom: 1.0625em;
    }
  
    @media (min-width: 768px) {
      margin-bottom: 1.6111em;
    }
  
    [data-waay="step-up"] > [data-waay-step] {
      @media (max-width: 767px) {
        opacity: 1;
        transform: translateY(0);
      }
    }
  
    .case-study__feature-blurb {
      font-size: 1em;
  
      @media (min-width: 768px) {
        font-size: 1em;
      }
  
      [data-waay="slide-up"] {
        @media (max-width: 767px) {
          opacity: 1;
          transform: none;
        }
      }
  
      p {
        line-height: 1.9444;
      }
  
      @media (min-width: 768px) {
        max-width: 250px;
      }
  
      h2 {
        font-weight: 700;
        font-size: 1em;
        margin-bottom: 1.6667em;
      }
    }
  }
  
  .case-study__introduction-description {
    display: flex;
    flex-direction: column;
  }
  
  .case-study__introduction-description:not(.waay-animate) [data-waay-step] {
    @media (max-width: 767px) {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .case-study__introduction-copy {
    font-size: 1em;
    line-height: 1.8182;
    flex-grow: 1;
  }
  
  @media (min-width: 768px) {
    .case-study__introduction-copy {
      font-size: 1.6em;
      line-height: 1.7188;
    }
  }
  
  .case-study__introduction--alt {
    padding: 27px 0 11.25%;
  
    @media (min-width: 768px) {
      padding: 11.25% 0;
    }
  
    @media (min-width: 1600px) {
      padding: 180px 0;
    }
  
    .case-study__introduction-features ul li {
      @media (min-width: 768px) {
        margin-bottom: 0.7778em;
      }
    }
  
    .case-study__introduction-description {
      @media (min-width: 768px) {
        /* margin-bottom: 3.8889em; */
      }
    }
  }
  .card--work {
    position: relative;
    color: text-color;
  }
  
  .slider--services .card--work {
    color: inherit;
    cursor: none;
  }
  
  .slider--services .card--work a {
    cursor: none;
  }
  
  .card__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .card__caption {
    opacity: 1;
    transition: opacity ease-std timing;
    padding-top: 1.5rem;
  }
  
  .card__title {
    font-weight: fw-medium;
    line-height: calc(74 / 55);
    margin: 0;
    font-family: font-alt;
    font-size: calc(18 / base-font-sm)em;
  }
  
  @media (min-width: 0) {
    .card--work .card__title {
      font-size: calc(24 / base-font)em;
    }
  }
  
  .card__title-wrap {
    display: block;
    transition: transform ease-std timing;
    will-change: transform;
    -webkit-transform-style: preserve-3d;
    display: flex;
    flex-direction: column-reverse;
  }
  
  .card__subtitle {
    display: block;
    opacity: 0.75;
    font-weight: fw-book;
    margin-top: calc(1 / 20)em;
    line-height: calc(30 / 20);
    font-size: calc(16 / 18)em;
    -webkit-backface-visibility: hidden;
  }
  
  @media (min-width: 0) {
    .card--work .card__subtitle {
      font-size: calc(16 / 24)em;
    }
  }
  
  .card__link {
    color: inherit;
    text-decoration: none;
    display: block;
  }
  
  .card__link:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .card__image {
    position: relative;
    overflow: hidden;
    order: -1;
  }
  
  .card__image .card__object {
    display: block;
    width: 100%;
    height: auto;
    /* position: absolute; */
    top: 0;
    left: 0;
    transition: ease-std 0.5s transform, ease-std 0.5s object-position;
  }
  
  @supports (object-fit: cover) {
    .card__image .card__object {
      height: 100%;
      object-fit: cover;
    }
  }
  
  .card--work-hover .card__title .card__title-wrap {
    @media (prefers-reduced-motion: no-preference) {
      transform: translateY(0.4rem);
    }
  }
  
  .card--work-hover .card__image .card__object {
    @media (prefers-reduced-motion: no-preference) {
      transition: ease-std 3s transform;
      transform: scale(1.05, 1.05);
    }
  }
  
  html.is-transitioning .card--work-hover .card__image .card__object {
    transition: ease-std timing transform;
    transform: scale(1, 1);
  }
  
  .card--has-link:hover {
    @extend .card--work-hover;
  }
  .case-study__50-50 {
  background-color: var(--block-background-color);
  color: var(--block-color);
}

.case-study__50-50 .block__inner {
  @media (min-width: 0) {
    display: flex;
    flex-wrap: wrap;
  }
}

.case-study__50-50 .block__inner > div {
  &:nth-child(2n + 2) {
    @media (min-width: 0) {
      align-self: center;
    }
  }
}

.case-study__50-50 .block__image {
  position: relative;
  min-height: 100%;
}

.case-study__50-50 .block__image img {
  width: 100%;
  height: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@supports (object-fit: cover) {
  .case-study__50-50 .block__image img {
    object-fit: cover;
    height: 100%;
  }
}

.case-study__50-50 .block__content {
  font-size: 1.5em;
}

.case-study__50-50 .block__content .block__title {
  font-size: 1em;
}

.case-study__50-50 .block__content p {
  opacity: 0.75;
}

.case-study__50-50 .block__content-inner {
  padding: 30px 0;
}

@media (min-width: 0) {
  .case-study__50-50 .block__content-inner {
    padding: 30px 0 30px calc((100 / 1920) * 100vw);
  }
}

@media (min-width: 1920px) {
  .case-study__50-50 .block__content-inner {
    padding: 30px 0 30px 100px;
  }
}

.case-study__50-50 .block__content p:last-child {
  margin: 0;
  font-size: 0.8em;
}

.case-study__50-50.case-study__50-50--flipped .block__inner {
  @media (min-width: 0) {
    flex-direction: row-reverse;
  }
}

.case-study__50-50.case-study__50-50--flipped .block__content {
  @media (min-width: 0) {
    padding: 30px calc((100 / 1920) * 100vw) 30px 0;
  }
}

.case-study__50-50.case-study__50-50--flipped .block__content-inner {
  @media (min-width: 0) {
    margin-left: auto;
  }
}

.case-study__50-50 .block__content {
  padding: 0 30px;
}

@media (min-width: 0) {
  .case-study__50-50 .block__content {
    padding: 0 30px 0 0;
  }
}

.case-study__50-50 .block__content-inner {
  @media (min-width: 0) {
    max-width: 698px;
  }
}

.button-group {
  display: flex;
  margin-left: -20px;
  flex-wrap: wrap;
}

.button-group > div {
  padding-left: 20px;
}
.case-study__image {
  /* background-color: var(--block-background-color); */
}

.case-study__image .block__image {
  /* position: relative;
  margin: 0 auto;
  width: 100%;
  overflow: hidden; */
}

.case-study__image .block__image img {
  width: 100%;
  height: auto;
  display: block;
}

.case-study__image .block__image .block__image-spacer {
  /* display: none; */
}

.case-study__image .block__image .block__image-spacer,
.case-study__image .block__image:not(.parallax) .block__image-spacer {
  display: block;
}

.case-study__image .block__image img {
  /* position: absolute; */
  top: 0;
  left: 0;
}

@supports (object-fit: contain) {
  .case-study__image .block__image img {
    /* height: 100%; */
    /* object-fit: cover; */
  }
}

.section--floating-cta .section__inner a{
  border-radius: 1px;
  background-color: red;
  height: 6rem;
}
.banner__image {
  /* position: relative; */
  /* overflow: hidden; */
}

.banner__image img {
  width: 100%;
  height: auto;
  transition: transform 1s ease; /* You can adjust the duration and easing as needed */
}
.banner__image.animate img {
  transform: translateX(100%);
}
.attachment-full {
  width: 80%;
}
@media only screen and (max-width: 600px) {
  .attachment-full {
    width: 100%;
  }
}/* Add this CSS to your stylesheet */
.animate {
  opacity: 1;
  transform: translateY(10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.animate.in-viewport {
  opacity: 1;
  transform: translateY(0);
}
p{
  margin: 0 0 1.46666667em;
}